import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate, Outlet, useMatch } from 'react-router-dom'
import { ListGroup, Nav, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminCourseUtilities.scss'
import { getGolfCourseAudit } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import Spinner from '@severed-links/common.spinner'

const GlobalAdminCourseUtilities = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const match = useMatch(`/global-admin/courses/utilities/:menuTab/*`)
    const menuTab = ((match || {}).params || {}).menuTab || ''
    const playerId = useSelector(state => state.account._id)
    const isAuditLoading = useSelector(state => state.globalAdmin.golfCourses.isAuditLoading)
    const _audit = useSelector(state => state.globalAdmin.golfCourses.audit || {})
    const { badgeCount, missingGeocodingCount, missingHolesCount, missingTeeBoxesCount } = _audit
    const missingGeocoding = _audit.missingGeocoding || []
    const missingTeeBoxes = _audit.missingTeeBoxes || []
    const missingHoles = _audit.missingHoles || []
    const _menuTabs = [
        { path: 'missing-geocoding', icon: 'map-marker', name: 'Courses missing geocoding', fieldName: 'missingGeocoding', badgeCount: missingGeocodingCount },
        { path: 'missing-tee-boxes', icon: 'golf-ball', name: 'Courses missing tee boxes', fieldName: 'missingTeeBoxes', badgeCount: missingTeeBoxesCount },
        { path: 'hole-errors', icon: 'flag', name: 'Courses with errors', fieldName: 'missingHoles', badgeCount: missingHolesCount },
        { path: 'golf-api-io-importer', icon: 'sync', name: 'Golf API Io data importer', fieldName: null, badgeCount: 0 },
        { path: 'us-golf-importer', icon: 'upload', name: 'US Golf data importer', fieldName: null, badgeCount: 0 },
    ].map(i => ({ ...i, list: i.fieldName ? (_audit[i.fieldName] || []) : [] }))
    const _menuTab = _menuTabs.find(i => i.path === menuTab.toLowerCase()) || {}

    useEffect(() => {
        dispatch(getGolfCourseAudit())
    }, [])

    const handleSelect = e => {
        navigate(`/global-admin/courses/utilities${e ? `/${e}` : ''}`)
    }


    return (
        <div className={s.container}>
            <h4>Course Utilities {isAuditLoading ? <Spinner /> : null}</h4>
            <div className={s.contentContainer}>
                <Nav className={s.nav} activeKey={menuTab} 
                    variant='pills'
                    onSelect={e => handleSelect(e)}>
                {_menuTabs && _menuTabs.map(i =>
                    <Nav.Item className={s.item} key={`trip-admin-main-nav-${i.path}`}>
                        <Nav.Link className={s.link + (i.path === menuTab.toLowerCase() ? ` ${s.active}` : ``)} eventKey={i.path}>
                            {i.icon ? <FontAwesomeIcon name={i.icon} className={s.icon} fixedWidth /> : null}
                            <span>{i.name}</span>
                            {i.badgeCount ? <Badge className={s.badge}>{i.badgeCount}</Badge> : null}
                        </Nav.Link>
                    </Nav.Item>
                )}
                </Nav>
                <div className={s.content}>
                    <Outlet context={{ menuTab, ..._menuTab }} />
                </div>
            </div>
        </div>
    )
}

export default GlobalAdminCourseUtilities
