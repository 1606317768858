import React from 'react'
import { Route } from 'react-router-dom'
import * as s from './MenuPanel.scss'
import { MenuRoutes } from '../../routes'

const MenuPanel = () => {

  return (
    <div className={s.menuPanel}>
      <MenuRoutes />
    </div>
  )
}

export default MenuPanel
