import React, { useRef, useImperativeHandle } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminPricingPlanItem.scss'
import numeral from 'numeral'

const GlobalAdminPricingPlanItem = ({ _id, variant, code, title, icon, isActive, entryFee, features, price, onEdit }) => (
    <ListGroup.Item className={s.planItem} variant={variant} action onClick={() => onEdit(_id)}>
        <div className={s.iconContainer}>
            {icon ? <FontAwesomeIcon name={icon} size='2x' /> : null}
        </div>
        <div className={s.titleContainer}>
            <div className={s.title}>{title}</div>
            <div className={s.code}>{code}</div>
        </div>
        <div className={s.pricingContainer + ' ' + s.price}>
            <div className={s.heading}>Price</div>
            <div className={s.value}>{numeral(price).format('$0')}</div>
        </div>
        <div className={s.pricingContainer + ' ' + s.price}>
            <div className={s.heading}>Entry Fee</div>
            <div className={s.value}>{numeral(entryFee).format('$0')}</div>
        </div>
        <div className={s.pricingContainer}>
            <div className={s.heading}>Features</div>
            <div className={s.value}>{(features || []).length}</div>
        </div>
        <div className={s.pricingContainer}>
            <div className={s.heading}>Active?</div>
            <div className={s.value}>{isActive ? 'Yes' : 'No'}</div>
        </div>
    </ListGroup.Item>
)

export default GlobalAdminPricingPlanItem
