import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Row, Col, Card, ListGroup, Image, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { range, sumBy } from 'lodash'
import { img, commonImageUrl } from '@severed-links/common.severedlinks-constants'
import * as s from './TripRoundMatchAdmin.scss'
import PlayerListItem from '../../Player/PlayerListItem'
import PlayerPickerModal from '../../Player/PlayerPickerModal'
import { setTripValue, setPlayerForMatch, getMedalistScoreboard, getMatchesScoreboard, getAdminMatches } from '@severed-links/common.severedlinks-reducers/groups'
import TripRoundMatchAdminRemovePlayer from './TripRoundMatchAdminRemovePlayer'
import MatchPairingChecker from './MatchPairingChecker'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import pluralize from 'pluralize'

const TeamHandicapFooter = ({ usaTeamName = 'USA', eurTeamName = 'EUR', usaTeamHandicap = 0, eurTeamHandicap = 0 }) => {
    const _diff = Math.abs(usaTeamHandicap - eurTeamHandicap)
    var _msg = 'Straight up'
    if (_diff > 0) {
        if (usaTeamHandicap > eurTeamHandicap) {
            _msg = `USA getting`
        } else {
            _msg = `EUR getting`
        }
    }
    return (
        <div>{_msg} {_diff ? <span className={s.hcp}>{_diff}</span> : null}{_diff ? ` ${pluralize('stroke', _diff, false)}` : null}</div>
    )
}


const TripRoundMatchAdmin = () => {

    const dispatch = useDispatch()
    const [pickTeam, setPickTeam] = useState('')
    const { group, trip, groupId, tripId, round, roundSubTab, roundParam, roundNumber, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const matchBuilderGrid = trip.matchBuilderGrid || {}

    const launchPlayerSelect = p => {
        if (p.isBlank) {
            const selectedPlayers = (trip.adminMatches || []).filter(m => m.round === round.round).map(m => m.playerId)
            const availableTripPlayers = [...(p.team === 'USA' ? trip.usaPlayers || [] : trip.eurPlayers || [])]
                .map(p1 => ({ ...p1, team: p.team, groupId: p.groupId, tripId: p.tripId, match: p.match, row: p.row, round: round.round, playerId: p1.playerID }))
                .filter(i => selectedPlayers.indexOf(i.playerId) === -1)
            setPickTeam(p.team === 'USA' ? 'USA' : 'European')
            dispatch(setTripValue(groupId, tripId, 'editMatchPlayers', availableTripPlayers))
            dispatch(setTripValue(groupId, tripId, 'showEditMatchPlayerModal', true))
        } else {
            dispatch(setTripValue(groupId, tripId, 'editMatchPlayerToRemove', p))
            dispatch(setTripValue(groupId, tripId, 'showEditMatchPlayerRemoveModal', true))
        }
    }

    const addPlayerToMatch = player => {
        dispatch(setTripValue(groupId, tripId, 'showEditMatchPlayerModal', false))
        dispatch(setPlayerForMatch(groupId, tripId, player.round, player.match, player.row, player.tripPlayerID))
        .then(action => {
            dispatch(createNotification(action.payload))
        })
    }

    const removePlayerFromMatch = player => {
        dispatch(setTripValue(groupId, tripId, 'showEditMatchPlayerRemoveModal', false))
        dispatch(setTripValue(groupId, tripId, 'editMatchPlayerToRemove', {}))
        dispatch(setPlayerForMatch(groupId, tripId, player.round, player.match, player.row, 0))
        .then(action => {
            dispatch(createNotification(action.payload))
        })
    }

    const closeRemovePlayerModal = () => {
        dispatch(setTripValue(groupId, tripId, 'showEditMatchPlayerRemoveModal', false))
        dispatch(setTripValue(groupId, tripId, 'editMatchPlayerToRemove', {}))
    }

    if (!trip || !round || !matchBuilderGrid.updatedAt) return null
    const roundMatches = (matchBuilderGrid.matches || []).filter(rm => rm.round === round.round)
    const medalist = (trip.scoreboard || {}).medalist || []
    const chatIsOpen = trip.showChat
    const isPlayingOwnBall = round.isPlayingOwnBall || false
    const _isTeamFormat = !isPlayingOwnBall
    return (
        <div className={s.container}>
            <h4>Matches</h4>
            <Row>
        {roundMatches && roundMatches.map((m, index) => 
            <Col md={chatIsOpen ? 6 : 4} sm={chatIsOpen ? 12 : 6} xs={12} 
                key={`trip-${trip._id}-round-${round.round}-match-${m.matchIndex}-${index}`}>
            <Card className={s.match}>
                <Card.Header className={s.matchTitle}>
                    <div>Match #{m.matchIndex}</div>
                    <MatchPairingChecker matchValidations={m.matchValidations} />
                    {m.teeTimeDisplay ? <div>{m.teeTimeDisplay}</div> : null}
                </Card.Header>
                <ListGroup variant='flush'>
                {['usa','eur'].map(t => 
                    <ListGroup.Item key={`trip-${trip._id}-round-${m.round}-match-${m.matchIndex}-team-list-${t}`} 
                    variant={t === 'usa' ? 'danger' : 'info'} className={s.matchTeamHeading}>
                        <div className={s.hcpHeader}>
                            <div className={s.teamName}>{trip[`${t}TeamName`] || t.toUpperCase()}</div>
                            {_isTeamFormat ? <Badge className={s.teamHandicap} bg={t === 'usa' ? 'danger' : 'info'} text='light'>{m[`${t}TeamHandicap`] || '0'}</Badge> : null}
                        </div>
                        <ListGroup variant='flush'>
                            {(m[`${t}Players`] || []).map((p, index) => 
                            p.isLoading ? 
                            <ListGroup.Item variant={t === 'usa' ? 'danger' : 'info'} 
                                className={s.loadingMatchContainer} 
                                key={`trip-${trip._id}-round-${m.round}-match-${m.matchIndex}-team-list-${t}-item-${p.row}-loading`}>
                                <FontAwesomeIcon name='circle-notch' style={{ fontSize: '32px' }} spin />
                            </ListGroup.Item>
                            :
                            <PlayerListItem variant={t === 'usa' ? 'danger' : 'info'} noWrap
                                key={`trip-${trip._id}-round-${m.round}-match-${m.matchIndex}-team-list-${t}-item-${p.row}`}
                                player={p.player} showHcp={!p.isBlank} round={m.round} showRole={false}
                                onClick={() => launchPlayerSelect(p)} />
                            )}
                        </ListGroup>
                    </ListGroup.Item>
                )}
                </ListGroup>
                {_isTeamFormat ?
                <Card.Footer className={s.matchFooter}><TeamHandicapFooter usaTeamHandicap={m.usaTeamHandicap} eurTeamHandicap={m.eurTeamHandicap} usaTeamName={trip.usaTeamName} eurTeamName={trip.eurTeamName} /></Card.Footer>
                : null}
            </Card>
            </Col>
        )}
            </Row>
            <PlayerPickerModal players={trip.editMatchPlayers || []} 
                showHcp showInfo groupId={groupId} tripId={tripId}
                showMedalistPosition medalist={medalist}
                header={<div className={s.playerModalHeader}>
                    <div className={s.flag}><Image src={trip[`${(pickTeam || '').toLowerCase()}FullTeamImageUrl`]} /></div>
                    <div className={s.title}>Select an available {trip[`${(pickTeam || '').toLowerCase()}TeamName`] || pickTeam} player...</div>
                </div>}
                show={trip.showEditMatchPlayerModal}
                onSelect={p => addPlayerToMatch(p)}
                onClose={() => dispatch(setTripValue(groupId, tripId, 'showEditMatchPlayerModal', false))} />
            <TripRoundMatchAdminRemovePlayer show={trip.showEditMatchPlayerRemoveModal}
                player={trip.editMatchPlayerToRemove || {}}
                onDelete={p => removePlayerFromMatch(p)}
                onClose={() => closeRemovePlayerModal()} />
        </div>
    )
}

export default TripRoundMatchAdmin