import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { Alert, Button, ListGroup, FormControl, FormGroup, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Confirm from '@severed-links/common.confirm'
import * as s from './RsvpTokenPurchaseListItem.scss'
import * as s1 from './BuyAddOns.scss'
import {  } from '@severed-links/common.severedlinks-reducers/groups'
import Modal from '@severed-links/common.modal'
import numeral from 'numeral'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import CreditCardPaymentForm from '../../CreditCard/CreditCardPaymentForm'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { getRsvpTokenPaymentKey, updateTrip } from '@severed-links/common.severedlinks-reducers/groups'
import { updateTripTokenCount } from '@severed-links/common.severedlinks-reducers/globalAdmin'

const TokenCountOffsetButton = ({ offset = 1, right = false, onChange }) => {
    const [timerId, setTimerId] = useState(null)

    useEffect(() => {
        return () => {
            if (timerId) {
                clearInterval(timerId)
            }
        }
    }, [])

    const handleChange = () => {
        if (onChange) {
            onChange(offset)
        }
    }

    const handleMouseDown = () => {
        handleChange()
        setTimerId(setInterval(() => handleChange(), 200))
    }

    const handleMouseUp = () => {
        clearInterval(timerId)
        setTimerId(null)
    }

    return (
        <Button className={s.button + ' ' + (right ? s.right : s.left)}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}>
            <FontAwesomeIcon name={right ? 'plus' : 'minus'} size='2x' />
        </Button>
    )
}

const RsvpTokenPurchaseListItem = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { _id: playerId, isGlobalAdmin } = useSelector(state => state.account)
    const { rsvpTokensPurchased, rsvpTokensUsed, registrationFee, rsvpTokenPaymentKey } = trip || {}
    const [tokenCount, setTokenCount] = useState(0)
    const [tokenUpdate, setTokenUpdate] = useState(null)
    const [showRsvpTokenCart, setShowRsvpTokenCart] = useState(false)
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [paymentResetter, setPaymentResetter] = useState()
    const balanceDue = tokenCount * (registrationFee || 0)
    const newRsvpTokensPurchased = (rsvpTokensPurchased || 0) + tokenCount

    useEffect(() => {
        if (group._id && trip._id) {
            dispatch(getRsvpTokenPaymentKey(group._id, trip._id))
        }
    }, [group._id, trip._id])

    useEffect(() => {
        if (tokenUpdate?.timestamp) {
            if (tokenUpdate.offset !== 0 && tokenCount + tokenUpdate.offset >= (!isGlobalAdmin ? 0 : -rsvpTokensPurchased)) {
                setTokenCount(tokenCount + tokenUpdate.offset)
            }
    
        }
    }, [tokenUpdate])

    useEffect(() => {
        if (paymentResetter) {
            setShowPaymentModal(false)
            setShowRsvpTokenCart(false)
            setTokenCount(0)
        }
    }, [paymentResetter])

    const handleTokenCountChange = _offset => {
        setTokenUpdate({ offset: _offset, timestamp: moment().toISOString() })
    }

    const handleConfirmPurchase = () => {
        setShowRsvpTokenCart(false)
        setShowPaymentModal(true)
    }

    const handleCancel = () => {
        setShowRsvpTokenCart(false)
        setShowPaymentModal(false)
        setTokenCount(0)
    }

    const onErrorPayment = _response => {
        alert(`Error completing payment: ${JSON.stringify(_response)}`)
    }

    const onSuccessfulPayment = _response => {
        setPaymentResetter(moment().toISOString())
    }

    const handleAwardTokens = () => {
        dispatch(updateTripTokenCount(group._id, trip._id, tokenCount))
        .then(action => {
            dispatch(createNotification(action.payload))
            dispatch(updateTrip({ groupId: group._id, tripId: trip._id, rsvpTokensPurchased: action.payload.rsvpTokensPurchased }))
        })
        setPaymentResetter(moment().toISOString())
    }

    if (!rsvpTokenPaymentKey) return null
    return (
        <>
        <ListGroup.Item className={s1.item} variant={rsvpTokensPurchased ? 'primary' : 'secondary'}>
            <div className={s1.icon}>
                <FontAwesomeIcon name='coins' size='2x' />
            </div>
            <div className={s1.content}>
                <div className={s1.title}>{rsvpTokensPurchased ? 'Manage your' : 'Buy'} RSVP tokens</div>
                {!rsvpTokensPurchased ? 
                <div className={s1.subtitle}>Pay for your players' registration in advance (we'll load your account up with a preset number of free trip registration fees and we won't charge your users a registration fee if there are tokens left to use)</div>
                : 
                <div className={s1.subtitle}>{pluralize('token', rsvpTokensPurchased, true)} purchased, {pluralize('token', rsvpTokensPurchased - rsvpTokensUsed, true)} left</div>}
            </div>
            <div className={s1.controls}>
                <Button size='sm' variant={rsvpTokensPurchased ? 'primary' : 'secondary'} onClick={() => setShowRsvpTokenCart(true)}>
                    <FontAwesomeIcon name='shopping-cart' />
                    {' '}
                    purchase tokens
                </Button>
            </div>
        </ListGroup.Item>

        <Modal show={showRsvpTokenCart}
            heading={`Purchase RSVP Tokens at ${numeral(registrationFee).format('$0.00')}/each`}
            onClose={handleCancel}
            actionButtonDisabled={tokenCount <= 0}
            actionButtonIcon='coins'
            actionButtonText={`purchase ${pluralize('token', Math.max(tokenCount, 0), true)}`}
            actionButtonStyle='primary'
            actionButtonClassName={s.purchaseRsvpTokensButton}
            actionButtonOnClick={handleConfirmPurchase}>
            <div className={s.rsvpTokenCartContainer}>
                <p><small><i>Purchase only what you need: RSVP tokens are nonrefundable and cannot be moved between trips.</i></small></p>
                <FormGroup className={s.formGroup}>
                    <InputGroup className={s.inputGroup}>
                        <TokenCountOffsetButton offset={-1} onChange={handleTokenCountChange} />
                        <FormControl className={s.input} value={`${tokenCount}`} readOnly />
                        <TokenCountOffsetButton right offset={1} onChange={handleTokenCountChange} />
                        {isGlobalAdmin ?
                            <Confirm variant='secondary' onConfirm={() => handleAwardTokens()}
                                title='Award RSVP Tokens as Global Admin' enforceFocus={false}
                                confirmText={`award ${pluralize('token', tokenCount, true)}`}
                                body={`Are you sure you want to update this trip's token count by ${pluralize('token', tokenCount, true)}?`}
                                size='sm' 
                                buttonIcon='gift' 
                                disabled={!tokenCount} />
                        : null}
                    </InputGroup>
                </FormGroup>
            </div>
        </Modal>

        <Modal show={showPaymentModal && tokenCount > 0} 
            showFooter={false}
            heading={`Pay RSVP Token Balance Due: ${numeral(balanceDue).format('$0.00')}`}
            onClose={handleCancel}>
            <div>
                <CreditCardPaymentForm 
                    amount={balanceDue}
                    chargeType={rsvpTokenPaymentKey} 
                    groupId={group._id} tripId={trip._id} playerId={playerId}
                    data={{ rsvpTokensPurchased: newRsvpTokensPurchased }}
                    header={`Purchase your ${pluralize('token', tokenCount, false)} here.`}
                    onError={onErrorPayment}
                    onSuccess={onSuccessfulPayment} />
            </div>
        </Modal>


        </>
    )
}

export default RsvpTokenPurchaseListItem
