// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripRecap__container___BtrVQ{margin-bottom:1rem}.TripRecap__container___BtrVQ .TripRecap__sideCardsContainer___lUPkg .card{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/TripRecap.scss"],"names":[],"mappings":"AAAA,8BACI,kBAAA,CAGI,2EACI,kBAAA","sourcesContent":[".container {\n    margin-bottom: 1rem;\n\n    .sideCardsContainer {\n        :global(.card) {\n            margin-bottom: 1rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripRecap__container___BtrVQ`;
export var sideCardsContainer = `TripRecap__sideCardsContainer___lUPkg`;
export default ___CSS_LOADER_EXPORT___;
