import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { } from 'react-router'
import { Image, FormControl, FormGroup, FormLabel, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripPhotoGallery.scss'
import Modal from '@severed-links/common.modal'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { getTripPhotos, photoSaveCaption } from '@severed-links/common.severedlinks-reducers/groups'
import { isEqual, trim } from 'lodash'

const TripPhotoCaptionEditor = ({ groupId = null, tripId = null, photo = {}, show = false, onChange, onClose, className = null }) => {

    if (!photo || !groupId || !tripId) return null

    const dispatch = useDispatch()
    const [editCaption, setEditCaption] = useState('')
    const _photoCaption = photo.caption || ''

    useEffect(() => setEditCaption(_photoCaption), [_photoCaption])

    const save = () => {
        dispatch(photoSaveCaption(groupId, tripId, photo._id, trim(editCaption)))
        .then(action => { 
            setEditCaption('')
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Edit Caption', timeout: 4000 }))
            if (onClose) onClose()
        })
    }

    const handleKeyPress = e => {
    }

    return (
        <Modal show={show}
            className={s.photoGalleryModal}
            heading='Edit Photo Caption' 
            enforceFocus={false}
            actionButtonOnClick={() => save()}
            actionButtonText='save caption'
            actionButtonIcon='check'
            onClose={() => onClose()}>
            {show ?
            <div>
                <FormGroup>
                    <FormLabel>Caption</FormLabel>
                    <FormControl autoFocus value={editCaption || ''} 
                        onChange={e => setEditCaption(e.target.value)} />
                </FormGroup>
                <p style={{ textAlign: 'center' }}>
                    {photo ? 
                        <Image src={photo.src} style={{ maxHeight: '200px', maxWidth: '200px' }} /> 
                    : null}
                </p>
            </div>
            : null}
            </Modal>
    )
}

export default TripPhotoCaptionEditor