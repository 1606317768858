import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventViewer.scss'
import TripEventListItem from './TripEventListItem'
import { recordEventResponse } from '@severed-links/common.severedlinks-reducers/groups'

const TripEventViewer = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const groupId = params.groupId || null
    const tripId = params.tripId || null
    const eventId = params.eventId || null
    const group = useSelector(state => (state.groups.groups || []).find(g => g._id === groupId))
    const trip = (group.trips || []).find(t => t._id === tripId) || {}
    const event = (trip.events || []).find(e => e._id === eventId) || {}

    useEffect(() => {
    }, [])

    const sendResponseWithEvent = (_event, _eventResponse) => {
        dispatch(recordEventResponse(group._id, trip._id, _event._id, _eventResponse))
    }

    return (
        <div className={s.container}>

        <ListGroup variant='flush'>
            {eventId && event._id === eventId ?
            <TripEventListItem event={event} 
                group={group} trip={trip} playerId={playerId}
                onTaskResponse={sendResponseWithEvent}
                key={`next-event-${tripId}-${event._id}`} />
            : null}
        </ListGroup>

        </div>
    )
}

export default TripEventViewer
