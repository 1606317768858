import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormGroup, FormControl, ListGroup, 
    FormLabel, Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PlayerListSearcher.scss'
import PlayerListItem from './PlayerListItem'

const PlayerListSearcher = ({ players = [], playerProps = {}, maxLength = 20, onSelect = void(0), PlayerComponent = PlayerListItem, emptyPhrase = 'No players located.' }) => {

    const dispatch = useDispatch()
    const [results, setResults] = useState([])
    const [searchText, setSearchText] = useState('')
    const showAll = players.length <= maxLength
    const playerList = showAll ? players : results

    useEffect(() => {
        setResults([])
        setSearchText('')
    }, [])

    const searchForPlayers = e => {
        const _searchText = e.target.value
        setSearchText(_searchText)
        if (_searchText) {
            setResults((players || []).filter(p => p.firstName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || p.lastName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || (p.firstName + ' ' + p.lastName).toLowerCase().indexOf(searchText.toLowerCase()) > -1))
        } else {
            setResults([])
        }
    }

    const clearSearcher = () => {
        setResults([])
        setSearchText('')
    }

    return (
        <div className={s.container}>
        {!showAll ?
        <FormGroup style={{ margin: '0px' }} className={s.searchGroup}>
            <FormControl className={s.searchBox} autoFocus
                placeholder='Search for players...' value={searchText} 
                onChange={e => searchForPlayers(e)} />
            {playerList && playerList.length ? 
                <span className={s.clearIcon} 
                onClick={() => clearSearcher()}>
                    <FontAwesomeIcon name='times' />
                </span> 
            : null}
        </FormGroup>
        : null}
        {playerList.length ?
        <ListGroup className={s.list}>
        {playerList && playerList.map(i => <PlayerComponent {...playerProps} player={i} onClick={() => onSelect(i)} key={`player-list-searcher-${i._id}`} />)}
        </ListGroup>
        : emptyPhrase ? 
            <Alert variant='light' style={{ marginTop: '20px' }}>{emptyPhrase}</Alert>
        : null}
        </div>                
    )
}

export default PlayerListSearcher