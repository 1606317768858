// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupInactiveAlert__container___yI3YK{margin:.5rem 0px;font-size:80%;display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center;padding:.25rem .5rem;margin:.25rem 0rem 0rem 0rem}.GroupInactiveAlert__container___yI3YK .GroupInactiveAlert__message___tCwFX{flex:1 1 auto}.GroupInactiveAlert__container___yI3YK .GroupInactiveAlert__groupActionButton___HFmU6{margin-left:1rem;display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center;font-size:inherit}.GroupInactiveAlert__container___yI3YK .GroupInactiveAlert__groupActionButton___HFmU6 .GroupInactiveAlert__icon___UXUWr{margin-right:.5rem}`, "",{"version":3,"sources":["webpack://./src/routes/Groups/GroupInactiveAlert.scss"],"names":[],"mappings":"AAGA,uCAEI,gBAAA,CACA,aAAA,CACA,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,oBAAA,CACA,4BAAA,CAEA,4EACI,aAAA,CAGJ,sFACI,gBAAA,CACA,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CAEA,wHACI,kBAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n@use '../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n\n    margin: 0.5rem 0px;\n    font-size: 80%;\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0.25rem 0.5rem;\n    margin: 0.25rem 0rem 0rem 0rem;\n\n    .message {\n        flex: 1 1 auto;\n    }\n\n    .groupActionButton {\n        margin-left: 1rem;\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n        font-size: inherit;\n\n        .icon {\n            margin-right: 0.5rem;\n        }\n\n        .label {\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `GroupInactiveAlert__container___yI3YK`;
export var message = `GroupInactiveAlert__message___tCwFX`;
export var groupActionButton = `GroupInactiveAlert__groupActionButton___HFmU6`;
export var icon = `GroupInactiveAlert__icon___UXUWr`;
export default ___CSS_LOADER_EXPORT___;
