import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, ListGroup, Image, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventListItem.scss'
import moment from 'moment-timezone'
import { getMyNextTeeTime } from '@severed-links/common.severedlinks-reducers/groups'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import DrivingDirectionsModal from './DrivingDirectionsModal'
import * as ddm from './DrivingDirectionsModal.scss'
import TripEventIcon from './TripEventIcon'
import TripEventCalendarDate from './TripEventCalendarDate'
import TripEventTitle from './TripEventTitle'
import TripEventLocation from './TripEventLocation'

const MyNextTeeTime = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userName = useSelector(state => state.account.userName)
    const teeTime = trip.myNextTeeTime || {}
    const timeZoneId = teeTime.timeZoneId
    const start = moment.tz(teeTime.teeTime, timeZoneId)
    const end = moment.tz(teeTime.teeTime, timeZoneId).add(5, 'hours')

    const [showDrivingDirections, setShowDrivingDirections] = useState(false)

    useEffect(() => {
        if (group._id && trip._id) {
            dispatch(getMyNextTeeTime(group._id, trip._id))
        }
    }, [group._id, trip._id])

    const onCloseDrivingDirections = () => setShowDrivingDirections(false)

    const launchScorecard = (e, roundNumber, foursome) => {
        e.stopPropagation()
        if (roundNumber && foursome) {
            navigate(`/group/${group._id}/trip/${trip._id}/scorecard/round/${roundNumber}/foursome/${foursome}`)
        }
    }

    if (!group._id || !trip._id || !teeTime.hasUpcomingTeeTime) return null
    const _title = `${teeTime.golfCourseName} (${teeTime.tees} tees${teeTime.isShotgun ? `, shotgun start on #${teeTime.startingHole}` : ''})`
    const _description = ``
    const _matches = teeTime.matches || []
    return (
        <Card className={s.container + ' ' + s.teeTime}>
            <Card.Header className={s.header}>
                <div className={s.icon}><FontAwesomeIcon name='flag' /></div>
                <div className={s.title}>Your Next Tee Time</div>
            </Card.Header>
            <ListGroup variant='flush'>
            <ListGroup.Item>
                <div className={s.item}>
                    <TripEventIcon isTeeTime />
                    <TripEventCalendarDate start={start} end={end} timeZoneId={timeZoneId}
                        tripName={trip.tripName} eventTitle={_title} eventDescription={_description}
                        locationAddress={`${teeTime.geocodedAddress || ''}`} />
                    <div className={s.content}>
                        <div className={s.details}>
                            <TripEventTitle start={start} end={end} 
                                timeZoneId={timeZoneId} isVisible
                                title={_title} />
                            <TripEventLocation locationAddress={teeTime.geocodedAddress} geocodedAddress={teeTime.geocodedAddress} onShowDrivingDirections={() => setShowDrivingDirections(true)} />
                            <div className={s.detailContainer}>
                                <div className={s.mainDetail}>
                                    <div className={s.teeTimeMatchContainer}>
                                    {_matches && _matches.map(m =>
                                    <div className={s.matchContainer} key={`next-tee-time-${trip._id}-match-${m.match}`}>
                                    {['usa','eur'].map(t => 
                                    <div className={s.teamContainer + ' ' + s[`${t}`]} key={`next-tee-time-${trip._id}-match-${m.match}-team-${t}`}>
                                    {m[`${t}Players`] && m[`${t}Players`].map(p =>
                                    <div className={s.playerContainer} key={`next-tee-time-${trip._id}-match-${m.match}-team-${t}-player-${p.playerId}`}>
                                        <div className={s.avatar}>
                                            <Image src={playerAvatarUrl(p.imageUrl, true)} />
                                            <div className={s.handicap + ' ' + s[`${t}`]}>{p.handicap}</div>
                                        </div>
                                        <div className={s.firstName}>{p.firstName}</div>
                                        <div className={s.lastName}>{p.lastName}</div>
                                    </div>
                                    )}
                                    </div>
                                    )}
                                    </div>
                                    )}
                                    {teeTime.isOnlineScorecardEnabled ?
                                    <Button className={s.scorecardButton}
                                        onClick={e => launchScorecard(e, teeTime.round, teeTime.foursome)}>
                                        <FontAwesomeIcon name='clipboard-list' className={s.icon} />
                                        <div className={s.label}>scorecard</div>
                                    </Button>
                                    : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DrivingDirectionsModal tripId={trip._id} isGolfCourse
                    show={showDrivingDirections} 
                    onClose={onCloseDrivingDirections} 
                    destination={{ _id: teeTime.golfCourseLocationId, locName: teeTime.golfCourseName }} />
            </ListGroup.Item>
            </ListGroup>
        </Card>
    )
}

export default MyNextTeeTime