import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ListGroup, Badge, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripRounds.scss'
import { FRIENDLY_DATE_FORMAT, FRIENDLY_SHORT_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import AddToCalendar from '../AddToCalendar/AddToCalendar'
import DrivingDirectionsModal from './DrivingDirectionsModal'
import * as ddm from './DrivingDirectionsModal.scss'
import numeral from 'numeral'
import Modal from '@severed-links/common.modal'

const TripRoundListItem = ({ groupId, tripId, tripName = '', round = 0, roundName, myMatch, isAdmin = false, onClick = null, showScorecardLaunchButton = false, proxyCount = 0 }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showDrivingDirections, setShowDrivingDirections] = useState(false)
    const [showFormatDescription, setShowFormatDescription] = useState(false)
    const group = useSelector(state => state.groups.groups.find(g => g._id === groupId) || {})
    const trip = (group.trips || []).find(t => t._id === tripId) || {}
    const currentTimeZoneId = moment.tz.guess()
    const playerTimeZoneId = useSelector(state => state.account.time_zone)
    const tripTimeZoneId = (trip.timezone || {}).timeZoneId
    const roundTimeZoneId = (round.timezone || {}).timeZoneId
    const timeZoneId = roundTimeZoneId || tripTimeZoneId || playerTimeZoneId || currentTimeZoneId || 'America/New_York'
    var timezoneAbbr = moment.tz(timeZoneId).zoneAbbr() 
    const notes = (round || {}).notes || []

    const onCloseDrivingDirections = () => setShowDrivingDirections(false)

    const getMyMatch = () => myMatch && myMatch.teeTime && moment(myMatch.teeTime).isValid() ? `Your tee time: ${moment(myMatch.teeTime).format('h:mm a')}` : null

    const launchScorecard = (e, roundNumber, foursome) => {
        e.stopPropagation()
        if (roundNumber && foursome) {
            navigate(`/group/${groupId}/trip/${tripId}/scorecard/round/${roundNumber}/foursome/${foursome}`)
        }
    }

    const getAddToCalendarTeeTime = _round => {
        if (_round && _round.roundDate && myMatch && myMatch.teeTime) {
            const roundDay = moment.tz(_round.roundDate, timeZoneId).startOf('day')
            return moment(roundDay.format(FRIENDLY_SHORT_DATE_FORMAT) + ' ' + myMatch.teeTime, FRIENDLY_DATE_FORMAT)
        } else if (_round && _round.roundDate && _round.teeTime) {
            const roundDay = moment.tz(_round.roundDate, timeZoneId).startOf('day')
            return moment(roundDay.format(FRIENDLY_SHORT_DATE_FORMAT) + ' ' + _round.teeTime, FRIENDLY_DATE_FORMAT)
        } else {
            return null
        }
    }

    if (!round) return null
    const i = round
    const myMatchDisplay = getMyMatch()
    const _teeTime = getAddToCalendarTeeTime(round)
    return (
        <ListGroup.Item className={s.roundListItem} action={onClick !== undefined && onClick !== null}
            onClick={onClick ? () => onClick() : undefined}>
            <div className={!i.courseName ? ' ' + s.tbd : undefined}>
                {`${i.courseName || (isAdmin ? 'Click here to setup...' : 'To be determined')}`}
                {' '}
                {i.golfCourseLocationId ? <FontAwesomeIcon name='map-marker' style={{ cursor: 'pointer' }} onClick={() => setShowDrivingDirections(true)} /> : null}
            </div>
            <h1 className={s.roundNumber}>{i.round}</h1>
            {i.courseName ?
            <div className={s.roundInfoContainer}>
                <FontAwesomeIcon className={s.icon} name='flag' />
                <div className={s.roundInfo}>
                    {i.tees ? <Badge bg={null} text={null} variant={null} className={s.teeBadge} style={{ color: i.teeBoxColor.fontColor, backgroundColor: i.teeBoxColor.backgroundColor }}>{i.tees} tees{['front9','back9'].includes(i.holesPlayedCode) ? ` (${i.holesPlayedCode === 'front9' ? 'Front 9' : i.holesPlayedCode === 'back9' ? 'Back 9' : ''} only)` : ''}</Badge> : null}
                    <div className={s.roundDescription}>
                        {i.par ? <div className={s.courseInfo}> Par {i.par}</div> : null}
                        {i.yards ? <div className={s.courseInfo}>{i.yards} yards</div> : null}
                        {i.slope ? <div className={s.courseInfo}>Slope {i.slope}</div> : null}
                        {i.rating ? <div className={s.courseInfo}>Rating {numeral(i.rating).format('0.0')}</div> : null}
                    </div>
                </div>
            </div>
            : null}
            {i.matchPlayFormatID ? 
            <div className={s.roundInfoContainer}>
                <FontAwesomeIcon className={s.icon} name='play-circle' /> 
                <div className={s.roundInfo + ' ' + s.formatInfo}>
                    <div className={s.roundDescription}>{i.formatName}</div>
                    {!i.isAdmin ?
                    <>
                    <Button className={s.formatButton} variant='light' size='sm' onClick={() => setShowFormatDescription(true)}><FontAwesomeIcon name='question-circle' /></Button>
                    <Modal show={showFormatDescription} onClose={() => setShowFormatDescription(false)}
                        heading={`${i.formatName}`} showFooter={false}>
                        <div>{i.formatDescription}</div>
                    </Modal>
                    </>
                    : null}
                </div>
            </div>
            : null}
            {i.roundDate ? 
            <div className={s.roundInfoContainer}>
                <FontAwesomeIcon className={s.icon} name='calendar' />
                <div className={s.roundInfo}>
                    <div className={s.roundDescription}>
                        {moment(i.roundDate).format('dddd, MMMM D, YYYY')}
                        {!i.isAdmin && i.courseName && i.tees && _teeTime.isValid() ? 
                        <div className={s.addToCalendarContainer}>
                            <AddToCalendar event={{ 
                                title: `${tripName}: ${roundName}`, 
                                location: `${i.courseName} (${i.tees} tees)`, 
                                start: _teeTime.local().toDate(), 
                                end: _teeTime.add(5, 'hours').local().toDate(), 
                                description: '',
                            }} />
                        </div>
                        : null}
                    </div>
                </div>
            </div>
            : null}
            {i.teeTime ? 
            <div className={s.roundInfoContainer}>
                <FontAwesomeIcon className={s.icon} name='clock' />
                <div className={s.roundInfo + (i.isShotgun ? ' ' + s.isShotgun : myMatchDisplay ? ' ' + s.hasTeeTime : '')}>
                    <div className={s.roundDescription}>{i.isShotgun ? `Shotgun start at ${i.teeTime}${(myMatch && myMatch.startingHole ? ` (starting on hole #${myMatch.startingHole})` : '')}` : `${myMatchDisplay || `Tee times begin at ${i.teeTime}`}`}{timeZoneId !== currentTimeZoneId ? ` ${timezoneAbbr}` : ``}</div>
                </div>
            </div>
            : null}
            {isAdmin ? 
            <div className={s.roundInfoContainer}>
                <FontAwesomeIcon className={s.icon} name='flag' />
                <div className={s.roundInfo + (!proxyCount ? ` ${s.dangerText}` : ``)}>
                    <div className={s.roundDescription}>{pluralize('proxy', proxyCount, true)}</div>
                </div>
            </div>
            : null}
            {notes && notes.map(i => ( 
            <div className={s.roundInfoContainer} key={`round-note-${i._id}`}>
                <FontAwesomeIcon className={s.icon} name='sticky-note' />
                <div className={`${s.roundInfo} ${s.note}`}>
                    <div className={s.roundDescription}>{i.note}</div>
                </div>
            </div>
            ))}
            {i.isAdmin ? 
            <div className={s.adminPane}>
                <h5>Administrators only</h5>
            </div>
            : null}
            {i.golfCourseLocationId ?
            <DrivingDirectionsModal tripId={i.tripID} 
                show={showDrivingDirections} isGolfCourse
                onClose={onCloseDrivingDirections} 
                destination={{ _id: i.golfCourseLocationId, locName: i.courseName }} />
            : null}
        </ListGroup.Item>
    )
}

export default TripRoundListItem