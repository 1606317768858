import React from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TripListItem from '../Trips/TripListItem'

const GroupShortTripList = ({ className, title, trips }) => (
    trips && trips.length > 0 ?
    <div className={className}>
        <h3><FontAwesomeIcon name='suitcase' /> {title}</h3>
        <ListGroup>
            {trips && trips.map(t =>
                <TripListItem key={`${title}-${t._id}`} trip={t} />
            )}
        </ListGroup>
    </div>
    : null
)

export default GroupShortTripList
