import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import CoreLayout from '../layouts/CoreLayout/CoreLayout'
import * as Sentry from '@sentry/react'

import { DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faCcVisa, faCcAmazonPay, faCcAmex, faCcApplePay, faCcDiscover, 
  faCcDinersClub, faCcJcb, faCcMastercard, faCcPaypal, faCcStripe, faApplePay } from '@fortawesome/free-brands-svg-icons'
import { faCheck, faTimes, faSyncAlt, faTrash, faLock, faUnlock, faSignOutAlt, faUser, faUsers, faDollarSign,
  faHome, faClock, faPlus, faPencilAlt, faPenSquare, faBan, faExclamationTriangle, faDownload,
  faUniversity, faEnvelope, faPhone, faBriefcase, faGraduationCap, faGavel, 
  faBalanceScale, faBook, faBookmark, faTrophy, faSearch, faChevronLeft, faChevronRight,
  faFastBackward, faFastForward, faCircleNotch, faChevronDown, faChevronUp,
  faCalendar, faPlay, faBirthdayCake, faMobile, faGlobe, faQuestion, faQuestionCircle, faCamera, faUpload, faFrown,
  faCog, faAddressBook, faInfoCircle, faList, faBolt, faFilter, faExclamation, faCalendarAlt,
  faStar, faSort, faLink, faArrowRight, faArrowLeft, faInfo, faComment, faBold, faUnderline, faItalic,
  faListOl, faListUl, faParagraph, faHeading, faFolder, faFile, faCopy, faExchangeAlt, faArrowCircleLeft, faArrowCircleRight,
  faDesktop, faHdd, faArchive, faThumbsUp, faThumbsDown, faBars, faFax, faEye, faEyeSlash, faPercent, faUndo,
  faCreditCard, faKey, faAngleDoubleRight, faAngleDoubleLeft, faAngleRight, faAngleLeft, faFlag, faCircle,
  faArrowsAltH, faArrowsAltV, faPlusCircle, faMap, faMapPin, faMapMarker, faMapMarkerAlt, faLeaf,
  faMoneyBill, faMoneyBillAlt, faUserPlus, faICursor, faSortAlphaUp, faSortAlphaDown, faBug, faSuitcase,
  faSmile, faSmileWink, faFilm, faBuilding, faPaperclip, faCameraRetro, faPlayCircle, faVideo, faSun, faCheckCircle,
  faMinus, faMinusCircle, faMinusSquare, faRocket, faUserClock, faBed, faSms, faBell, faBellSlash,
  faVoteYea, faExpand, faCompress, faCloudRain, faGolfBall, faMoneyBillWave, faStickyNote, faAward, faExclamationCircle,
  faShoppingCart, faSquare, faCheckSquare, faSignInAlt, faEllipsisV, faRectangleList, 
  faFaceLaugh, faFaceAngry, faPrint, faPersonBooth, faClipboardList, faEnvelopeOpenText,
  faCoins, 
} from '@fortawesome/free-solid-svg-icons'

import { 
  faSquare as faSquareRegular,
  faThumbsUp as faRegularThumbsUp, 
  faThumbsDown as faRegularThumbsDown, 
  faFaceLaugh as faRegularFaceLaugh, 
  faFaceAngry as faRegularFaceAngry, 
  faComment as faRegularComment, 
  faUser as faRegularUser,
  faCircle as faRegularCircle, 
  faClipboard, 
  faBell as faRegularBell, 
  faBellSlash as faRegularBellSlash,
} from '@fortawesome/free-regular-svg-icons'

library.add(fab, faCheck, faTimes, faSyncAlt, faTrash, faLock, faUnlock, faSignOutAlt, faUser, faUsers, faDollarSign,
  faHome, faClock, faPlus, faPencilAlt, faPenSquare, faBan, faExclamationTriangle, faDownload, 
  faUniversity, faEnvelope, faPhone, faBriefcase, faGraduationCap, faGavel, 
  faBalanceScale, faBook, faBookmark, faTrophy, faSearch, faChevronLeft, faChevronRight,
  faFastBackward, faFastForward, faCircleNotch, faChevronDown, faChevronUp,
  faCalendar, faPlay, faBirthdayCake, faMobile, faGlobe, faQuestion, faQuestionCircle, faCamera, faUpload, 
  faFrown, faCog, faAddressBook, faInfoCircle, faList, faBolt, faFilter, faExclamation, faCalendarAlt,
  faStar, faSort, faLink, faArrowRight, faArrowLeft, faInfo, faComment, faBold, faUnderline, faItalic,
  faListOl, faListUl, faParagraph, faHeading, faFolder, faFile, faCopy, faExchangeAlt, faArrowCircleLeft, faArrowCircleRight,
  faDesktop, faHdd, faArchive, faThumbsUp, faThumbsDown, faBars, faFax, faEye, faEyeSlash, faPercent, faUndo,
  faCreditCard, faKey, faAngleDoubleRight, faAngleDoubleLeft, faAngleRight, faAngleLeft, faFlag, faCircle,
  faArrowsAltH, faArrowsAltV, faPlusCircle, faMap, faMapPin, faMapMarker, faMapMarkerAlt, faLeaf,
  faMoneyBill, faMoneyBillAlt, faUserPlus, faICursor, faSortAlphaUp, faSortAlphaDown, faBug, faSuitcase,
  faSmile, faSmileWink, faFilm, faBuilding, faPaperclip, faCameraRetro, faPlayCircle, faVideo, faSun, faCheckCircle,
  faMinus, faMinusCircle, faMinusSquare, faRocket, faUserClock, faBed, faSms, faBell, faBellSlash,
  faVoteYea, faExpand, faCompress, faCloudRain, faGolfBall, faMoneyBillWave, faStickyNote, faAward, faExclamationCircle, 
  faShoppingCart, faSquare, faCheckSquare, faSquareRegular, faSignInAlt, faEllipsisV, faPrint, faPersonBooth, 
  faClipboardList, faPersonBooth, faRectangleList, faEnvelopeOpenText,
  faCoins, 

  faCcVisa, faCcAmazonPay, faCcAmex, faCcApplePay, faCcDiscover, faCcDinersClub, faCcJcb, faCcMastercard, faCcPaypal, faCcStripe,

  faRegularCircle, faClipboard, faRegularBell, faRegularBellSlash, faRegularUser,

  faFaceLaugh, faFaceAngry, faRegularThumbsUp, faRegularThumbsDown, faRegularFaceLaugh, faRegularFaceAngry, faRegularComment, 
)

Sentry.init({
  dsn: 'https://b22bb0210ef174379cb369f8035d1eb7@o1333782.ingest.us.sentry.io/4508710677643264',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/v2\.severedlinks\.com\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const AppContainer = ({ store, ...props }) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <DndProvider options={HTML5toTouch}>
          <CoreLayout {...props} />
        </DndProvider>
      </BrowserRouter>
    </Provider>
  )
}

export default AppContainer
