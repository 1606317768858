import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TextInput from '@severed-links/common.text-input'
import * as s from './TripRoundNotesAdminEditNote.scss'
import Modal from '@severed-links/common.modal'
import Button from '@severed-links/common.button'
import { updateTripRoundNote } from '@severed-links/common.severedlinks-reducers/groups'

const TripRoundNotesAdminEditNote = ({ show = false, showAddButton = true, groupId = null, tripId = null, round = 0, noteId = null, note = '', onCancel = null }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const [_show, setShow] = useState(show)
    const [_note, setNote] = useState(note)

    useEffect(() => {
        if (!showAddButton) {
            setShow(true)
        }
    }, [showAddButton])

    useEffect(() => {
        setShow(show)
        setNote(note)
    }, [show])

    const handleSave = () => {
        if (_note) {
            dispatch(updateTripRoundNote(groupId, tripId, round, noteId, _note))
            .then(action => {
                handleCancel()
            })
        }
    }

    const handleCancel = () => {
        setNote('')
        setShow(false)
        if (onCancel) onCancel()
    }

    return (
        <>
        {showAddButton ?
        <div className={s.container}>
            <Button variant='primary' title='add note...' icon='plus' onClick={() => setShow(true)} />
        </div>
        : null}

        <Modal show={_show} heading='Add a note...'
            onClose={() => handleCancel()}
            actionButtonText='save note'
            actionButtonStyle='primary'
            actionButtonDisabled={!_note}
            actionButtonOnClick={handleSave}>
            <div className={s.addNoteModalContainer}>
                <TextInput value={_note} multiple label='Note'
                    useFloatingLabels={false} autoFocus
                    onChange={_value => setNote(_value)}
                    buttonProps={{ onClick: handleSave }} />
            </div>
        </Modal>
        </>
    )
}

export default TripRoundNotesAdminEditNote
