import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, FormGroup, FormControl, Button, FormLabel } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import FormCheck from 'react-bootstrap/FormCheck'
import { getAllConfigs, getConfig, saveConfig, deleteConfig } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import { keys } from 'lodash'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './GlobalAdminSettings.scss'
import Confirm from '@severed-links/common.confirm'
import Modal from '@severed-links/common.modal'

const GlobalAdminSettings = () => {

    const dispatch = useDispatch()
    const [showAdd,setShowAdd] = useState(false)
    const [config,setConfig] = useState({})
    const [newSettingName,setNewSettingName] = useState('')
    const [newSettingValue,setNewSettingValue] = useState('')

    useEffect(() => {
        dispatch(getAllConfigs()).then(action => setConfig(action.payload))
    }, [])

    const handleChange = (key, value) => {
        setConfig({ ...config, [key]: value })
        if (value === 'Yes' || value === 'No') {
            dispatch(saveConfig(key, value))
        }
    }

    const saveTextOnBlur = (key, value) => {
        dispatch(saveConfig(key, value))
    }

    const deleteSetting = variable => {
        dispatch(deleteConfig(variable))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Delete Setting', timeout: 3000 }))
            dispatch(getAllConfigs())
            .then(action2 => setConfig(action2.payload))
        })
    }

    const saveNewConfigSetting = () => {
        setShowAdd(false)
        dispatch(saveConfig(newSettingName, newSettingValue))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Save Setting', timeout: 3000 }))
            getAllConfigs().then(action2 => setConfig(action2.payload))
        })
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h3 className={s.title}>Global Administrator Settings</h3>
                <div className={s.controls}>
                    <Button onClick={() => { setShowAdd(true); setNewSettingName(''); setNewSettingValue(''); }}><FontAwesomeIcon name='plus' /></Button>
                </div>
            </div>
            <Row>

                {config && keys(config).map(key => 
                    <Col md={3} sm={4} xs={12} key={key}>
                        <div className={s.setting}>
                        <FormGroup className={s.formGroup}>
                            <Confirm title={'Delete Setting'}
                                onConfirm={() => deleteSetting(key)}
                                confirmText='delete setting'
                                body={<div><p>Are you sure you want to delete this setting?</p></div>}
                                variant='danger' size='sm' buttonClassName={s.deleteButton} buttonIcon='times' />

                            <FormLabel className={s.label} title={key}>{key}</FormLabel>
                            {config[key] === 'Yes' || config[key] === 'No' ? 
                            <FormCheck type='switch' 
                                checked={config[key] === 'Yes'} 
                                onChange={e => handleChange(key, e.target.checked ? 'Yes' : 'No')} />
                            :
                            <FormControl value={config[key] || ''} 
                                className={s.textField}
                                onBlur={e => saveTextOnBlur(key, e.target.value)}
                                onChange={e => handleChange(key, e.target.value)} />}
                        </FormGroup>
                        </div>
                    </Col>
                )}
                    <Modal show={showAdd} showFooter={true}
                        heading={`Add new setting`}
                        actionButtonOnClick={() => saveNewConfigSetting()} 
                        actionButtonDisabled={!newSettingName || !newSettingValue || keys(config).some(key => key === newSettingName)}
                        actionButtonIcon='check' actionButtonText='save setting'
                        onClose={() => setShowAdd(false)}>
                        <div>
                            <FormGroup>
                                <FormLabel>Setting name (no spaces)</FormLabel>
                                <FormControl value={newSettingName} onChange={e => setNewSettingName(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Setting value (no spaces)</FormLabel>
                                <FormControl value={newSettingValue} onChange={e => setNewSettingValue(e.target.value)} />
                            </FormGroup>
                        </div>
                    </Modal>
            </Row>
        </div>
    )
}

export default GlobalAdminSettings