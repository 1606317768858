import React, { useRef } from 'react'
import { ListGroup, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './MiniMatchItem.scss'
import { ITEM_TYPES } from '../DragAndDropItemTypes'
import { useDrag } from 'react-dnd'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'

const MiniMatchItem = ({ foursome = 0, match = {}, onDrop = () => void(0) }) => {

    const [{ isDragging }, _dragRef] = useDrag({
        type: ITEM_TYPES.MATCH,
        item: () => ({ foursome, match }),
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    return (
        <div ref={_dragRef} className={s.container}>
            <ListGroup.Item className={s.miniMatchContainer}>
            {[...(match.usaPlayerList || []), ...(match.eurPlayerList || [])].map(p =>
                <div className={s.playerBox + ' ' + s[p.team.toString().toLowerCase()]}
                    key={`mini-match-player-${p._id}`}>
                    <div className={s.avatar} style={{ backgroundImage: `url(${playerAvatarUrl(p.imageUrl, true)})` }} />
                    <div className={s.name + ' ' + s.firstName}>{p.firstName}</div>
                    <div className={s.name + ' ' + s.lastName}>{p.lastName}</div>
                </div>
            )}
            </ListGroup.Item>
        </div>
    )
}

export default MiniMatchItem