// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NearByPlayersListItem__container___mPwin{margin-bottom:1rem}.NearByPlayersListItem__container___mPwin.NearByPlayersListItem__hasPlayers___NjoSy{cursor:pointer}.NearByPlayersListItem__container___mPwin .NearByPlayersListItem__header___L9M2j{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.NearByPlayersListItem__container___mPwin .NearByPlayersListItem__header___L9M2j .NearByPlayersListItem__icon___UtYPO{flex:0 0 0;margin-right:1rem}.NearByPlayersListItem__container___mPwin .NearByPlayersListItem__header___L9M2j .NearByPlayersListItem__title___m7Je7{flex:1 1 auto}.NearByPlayersListItem__container___mPwin .NearByPlayersListItem__header___L9M2j .NearByPlayersListItem__count___V94G4{flex:0 0 0;margin:-4px 2rem 0px 1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/NearByPlayersListItem.scss"],"names":[],"mappings":"AAGA,0CACI,kBAAA,CAEA,oFACI,cAAA,CAGJ,iFACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,sHACI,UAAA,CACA,iBAAA,CAGJ,uHACI,aAAA,CAGJ,uHACI,UAAA,CACA,yBAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n@use '../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n    margin-bottom: 1rem;\n\n    &.hasPlayers {\n        cursor: pointer;\n    }\n\n    .header {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n\n        .icon {\n            flex: 0 0 0;\n            margin-right: 1rem;\n        }\n\n        .title {\n            flex: 1 1 auto;\n        }\n\n        .count {\n            flex: 0 0 0;\n            margin: -4px 2rem 0px 1rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `NearByPlayersListItem__container___mPwin`;
export var hasPlayers = `NearByPlayersListItem__hasPlayers___NjoSy`;
export var header = `NearByPlayersListItem__header___L9M2j`;
export var icon = `NearByPlayersListItem__icon___UtYPO`;
export var title = `NearByPlayersListItem__title___m7Je7`;
export var count = `NearByPlayersListItem__count___V94G4`;
export default ___CSS_LOADER_EXPORT___;
