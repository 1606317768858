import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { FormGroup, FormLabel, FormControl, InputGroup, 
    Button, FormText, ButtonGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import validator from 'validator'
import { updateGroupSetting, checkNewGroupNameAvailability } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { isEqual } from 'lodash'
import * as s from './GroupAdminBasicSettings.scss'
import * as _editorCss from '../../Trips/Admin/TripMessaging.scss'
import DeleteGroup from './DeleteGroup'
import { Editor, EditorState, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'

const FieldValidIndicator = ({ isValid }) => (
    <InputGroup.Text className={s.indicator + ' ' + (isValid ? s.isValid : s.isInvalid)}>
        <FontAwesomeIcon name={isValid ? 'check' : 'times'} fixedWidth />
    </InputGroup.Text>
)

const FieldHtmlEditor = ({ editorState = null, placeholder = '[Edit description here...]', onChange, handleEditorButton }) => (
    <div className={_editorCss.editorContainer + ' ' + s.editorContainer} style={{ marginBottom: '1rem' }}>
        <div className={_editorCss.editorTools}>
        <ButtonGroup tabIndex={-1}>
            <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('BOLD')}><FontAwesomeIcon name='bold' /></Button>
            <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('UNDERLINE')}><FontAwesomeIcon name='underline' /></Button>
            <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('ITALIC')}><FontAwesomeIcon name='italic' /></Button>
        </ButtonGroup>
        </div>
        <Editor className={_editorCss.editor} 
            editorState={editorState} 
            placeholder={placeholder}
            blockStyleFn={contentBlock => _editorCss.block}
            onChange={e => onChange(e)} />
    </div>
)

const GroupAdminBasicSettings = () => {

    const dispatch = useDispatch()
    const [settings, setSettings] = useState({ name: '', mvpAwardName: '', mockAwardName: '', mvpAwardDescription: EditorState.createEmpty(), mockAwardDescription: EditorState.createEmpty(), minLength: 0, maxLength: 0, isAvailable: false })
    const { name, mvpAwardName, mockAwardName, mvpAwardDescription, mockAwardDescription, minLength, maxLength, isAvailable } = settings
    const isMvpValid = !validator.isEmpty(mvpAwardName) && validator.isLength(mvpAwardName, { min: 5, max: 100 })
    const isMockValid = !validator.isEmpty(mockAwardName) && validator.isLength(mockAwardName, { min: 5, max: 100 })
    const { group, groupId } = useOutletContext()

    useEffect(() => initializeState(), [])
    useEffect(() => {
        setSettings({ 
            ...settings, 
            name: group.name, 
            mvpAwardName: group.mvpAwardName, 
            mockAwardName: group.mockAwardName,
            mvpAwardDescription: group.mvpAwardDescription ? EditorState.createWithContent(stateFromHTML(group.mvpAwardDescription)) : EditorState.createEmpty(),
            mockAwardDescription: group.mockAwardDescription ? EditorState.createWithContent(stateFromHTML(group.mockAwardDescription)) : EditorState.createEmpty(),
        })
    }, [group.name, group.mvpAwardName, group.mockAwardName, group.mvpAwardDescription, group.mockAwardDescription])
    useEffect(() => {
        if (name) {
            dispatch(checkNewGroupNameAvailability(name))
            .then(action => groupNameCheckCallback(action.payload))
        }
    }, [name])

    const initializeState = () => {
        setSettings({ 
            ...settings, 
            name: group.name || '', 
            mvpAwardName: group.mvpAwardName || '', 
            mockAwardName: group.mockAwardName || '',
            mvpAwardDescription: group.mvpAwardDescription ? EditorState.createWithContent(stateFromHTML(group.mvpAwardDescription)) : EditorState.createEmpty(),
            mockAwardDescription: group.mockAwardDescription ? EditorState.createWithContent(stateFromHTML(group.mockAwardDescription)) : EditorState.createEmpty(),
        })
    }

    const groupNameCheckCallback = o => {
        if (o.name === name) {
            setSettings({ ...settings, ...o, isAvailable: group.name === o.name || o.isAvailable })
        }
    }

    const saveSetting = (fieldName, value) => {
        const _editorSaveOptions = {
            blockStyleFn: block => {
                return {
                    style: {
                        marginBottom: '1rem'
                    }
                }
            }        
        }
        if (fieldName === 'mvpAwardDescription' || fieldName === 'mockAwardDescription') {
            value = value.getCurrentContent().hasText() ? stateToHTML(value.getCurrentContent(), _editorSaveOptions) : ''            
            dispatch(updateGroupSetting(group._id, fieldName, value))
            .then(action => dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Update Group Setting', timeout: 5000 })))
        } else {
            if (value) {
                dispatch(updateGroupSetting(group._id, fieldName, value))
                .then(action => dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Update Group Setting', timeout: 5000 })))
            }
    
        }
    }

    const handleGroupNameChange = e => {
        const _name = e.target.value
        setSettings({ ...settings, name: _name })
    }

    const handleEditorChange = (fieldName, _editorState) => setSettings({ ...settings, [fieldName]: _editorState })

    const handleEditorButton = command => handleEditorChange(RichUtils.toggleInlineStyle(editorState, command))

    return (
        <div className={s.container}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Group name</FormLabel>
                <InputGroup>
                    <FormControl name='name' value={name} 
                        onChange={e => handleGroupNameChange(e)} />
                    <FieldValidIndicator isValid={isAvailable} />
                    <Button variant={isAvailable ? 'primary' : 'light'} 
                        disabled={validator.isEmpty(name) || !validator.isLength(name, { min: minLength, max: maxLength })} 
                        onClick={() => saveSetting('name', name)}><FontAwesomeIcon name='check' /> save</Button>
                </InputGroup>
                <FormText>This is the common name by which your group will be known.</FormText>
            </FormGroup>
            <FormGroup className={s.formGroup}>
                <FormLabel>MVP award name</FormLabel>
                <InputGroup>
                    <FormControl name='mvpAwardName' value={mvpAwardName}
                        onChange={e => setSettings({ ...settings, mvpAwardName: e.target.value })} />
                    <FieldValidIndicator isValid={isMvpValid} />
                    <Button variant={isMvpValid ? 'primary' : 'light'} disabled={!isMvpValid} onClick={() => saveSetting('mvpAwardName', mvpAwardName)}><FontAwesomeIcon name='check' /> save</Button>
                </InputGroup>
                <FormText>The MVP award is voted on at the conclusion of each trip.</FormText>
            </FormGroup>
            <FormGroup className={s.formGroup}>
                <FormLabel>MVP award description</FormLabel>
                <FieldHtmlEditor editorState={settings.mvpAwardDescription} 
                    handleEditorButton={handleEditorButton}
                    onChange={e => handleEditorChange('mvpAwardDescription', e)} />
                <div className={s.editorSaveContainer}>
                    <FormText className={s.formText}>Provide a custom description of your MVP award.</FormText>
                    <Button className={s.saveButton} 
                        variant={isMvpValid ? 'primary' : 'light'}
                        onClick={() => saveSetting('mvpAwardDescription', mvpAwardDescription)}><FontAwesomeIcon name='check' /> save description</Button>
                </div>
            </FormGroup>
            <FormGroup className={s.formGroup}>
                <FormLabel>Mock award name</FormLabel>
                <InputGroup>
                    <FormControl name='mockAwardName' value={mockAwardName} 
                        onChange={e => setSettings({ ...settings, mockAwardName: e.target.value })} />
                    <FieldValidIndicator isValid={isMockValid} />
                    <Button variant={isMockValid ? 'primary' : 'light'}
                        disabled={!isMockValid} 
                        onClick={() => saveSetting('mockAwardName', mockAwardName)}><FontAwesomeIcon name='check' /> save</Button>
                </InputGroup>
                <FormText>Mock awards are fun awards to recognize famous (or imfamous) achivements during a trip.</FormText>
            </FormGroup>
            <FormGroup className={s.formGroup}>
                <FormLabel>Mock award description</FormLabel>
                <FieldHtmlEditor editorState={settings.mockAwardDescription} 
                    handleEditorButton={handleEditorButton}
                    onChange={e => handleEditorChange('mockAwardDescription', e)} />
                <div className={s.editorSaveContainer}>
                    <FormText className={s.formText}>Provide a custom description of your mock award.</FormText>
                    <Button className={s.saveButton} 
                        variant={isMvpValid ? 'primary' : 'light'}
                        onClick={() => saveSetting('mockAwardDescription', mockAwardDescription)}><FontAwesomeIcon name='check' /> save description</Button>
                </div>
            </FormGroup>
            <DeleteGroup group={group} />
        </div>
    )
}

export default GroupAdminBasicSettings