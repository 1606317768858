import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Table, Button, ListGroup, FormControl, FormGroup, 
    InputGroup, Alert, Card, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { sumBy, trim, range, uniq, first, last, takeRight, take, flatten } from 'lodash'
import { getGolfCourse, getGolfCourseHoles, setScorecardData, 
    getHoleDataForFoursome, saveHoleData, createScorecardKey } from '@severed-links/common.severedlinks-reducers/scorecard'
import { getMatchesScoreboard, getSkinsAndProxiesScoreboard, saveProxy } from '@severed-links/common.severedlinks-reducers/groups'
import * as s from './Scorecard.scss'
import ScorecardGolfHole from './ScorecardGolfHole'
import { playerAvatarUrl, commonImageUrl, FRIENDLY_DATE_FORMAT, 
    FRIENDLY_SHORT_DATE_FORMAT, FRIENDLY_SHORT_TIME_FORMAT } from '@severed-links/common.severedlinks-constants'
import ScorecardMiniMatchSummary from './ScorecardMiniMatchSummary'
import Modal from '@severed-links/common.modal'
import PlayerListItem from '../Player/PlayerListItem'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import Spinner from '@severed-links/common.spinner'
import moment from 'moment-timezone'
import Confirm from '@severed-links/common.confirm'
import ProxyDistanceDisplay from '../Trips/ProxyDistanceDisplay'
import ProxyDistanceEditor from '../Trips/ProxyDistanceEditor'

const ProxyAlert = ({ 
    hole, roundNumber, isCurrentHole, isNextHole, 
    showConfirmProxyWinnerHole, proxies = [], editPlayers = [], 
    onUpdateWinnerClick, onCloseWinnerModalClick, updateProxyWinner, 
}) => {
    const [proxyPlayer, setProxyPlayer] = useState(null)
    const [proxyDistance, setProxyDistance] = useState()
    const [proxyInches, setProxyInches] = useState(0)
    const [showConfirmProxyWinner, setShowConfirmProxyWinner] = useState(false)
    const proxy = proxies.find(i => i.hole === hole)
    const winner = proxy?.list?.length ? first(proxy.list) : { firstName: '', lastName: '', playerName: 'No Winner', _id: null, playerID: null, imageUrl: null, list: [] }

    useEffect(() => {
        setProxyPlayer(winner)
        setProxyDistance(winner?.distance || 0)
        setProxyInches(winner?.inches || 0)
    }, [roundNumber, hole, winner?.playerID, proxy?.updatedAt])

    if (!hole) return null
    if (!proxy) return null
    const displayProxyDate = jsonDate => {
        if (!jsonDate) return ''
        const date = moment(jsonDate)
        if (!date.isValid()) return ''
        return date.isSame(moment(), 'day') ? date.format(FRIENDLY_SHORT_TIME_FORMAT) : date.format(FRIENDLY_DATE_FORMAT)
    }

    const confirmProxyWinner = p => {
        setProxyPlayer(p)
        setShowConfirmProxyWinner(true)
    }

    const saveProxyWinner = () => {
        updateProxyWinner(proxy, proxyPlayer, proxyDistance, proxyInches)
        setShowConfirmProxyWinner(false)
    }

    return (
        <Card variant='warning' className={s.proxyAlert}>
            <Card.Header className={s.content + ' ' + s.proxyHeader}>
                <h5 className={s.proxyAlertTitle}>
                    <FontAwesomeIcon name='star' />
                    {' '}
                    <b>Proxy alert {isNextHole ? ' (next hole)' : ''}</b>
                    <br />
                    {`#${hole} ${proxy.proxyName}`}
                </h5>
                {isCurrentHole ? 
                <div>
                    <Button variant='warning' onClick={() => onUpdateWinnerClick(hole)} size='sm'><FontAwesomeIcon name='star' /> update winner</Button>
                </div>
                : null}
            </Card.Header>
            <ListGroup className={s.proxyList} variant='flush'>
                {proxy.list && proxy.list.length > 0 ?
                <PlayerListItem variant='warning' showRole={false} 
                    className={s.proxyListPlayerItem}
                    showHcp={false} style={{ backgroundColor: 'transparent' }} clickDisabled
                    rightDetail={<div className={s.proxyPlayerRightDetail}>
                        <div className={s.proxyDistance}>
                        {proxy?.units && (winner?.distance || winner?.inches) ?
                        <ProxyDistanceDisplay {...(winner || {})} units={proxy.units} />
                        : null}
                        </div>
                        <div>{displayProxyDate(winner.dateOnList)}</div>
                    </div>}
                    player={winner} key={`proxy-alert-player-winner-item`} />
                : null}
                {proxy.list && proxy.list.length > 1 && takeRight(proxy.list, proxy.list.length - 1).map(i =>
                <ListGroup.Item className={s.proxyItem} key={`scorecard-proxy-${i._id}`}>
                    <div className={s.avatarContainer}><Image src={playerAvatarUrl(i.imageUrl, true)} /></div>
                    <div className={s.nameContainer}>
                        <div className={s.firstName}>{i.firstName}</div>
                        <div className={s.lastName}>{i.lastName}</div>
                    </div>
                    <div className={s.time}>
                        <ProxyDistanceDisplay className={s.proxyDistance} units={proxy?.units} distance={i.distance} inches={i.inches} />
                        {displayProxyDate(i.dateOnList)}
                    </div>    
                </ListGroup.Item>
                )}
                {!proxy.list || proxy.list.length === 0 ?
                <ListGroup.Item className={s.proxyItem}>{'No Winner'}</ListGroup.Item>
                : null}
            </ListGroup>
            <Modal show={showConfirmProxyWinnerHole === hole} showFooter={false}
                heading={`Set proxy winner: Hole #${hole} ${proxy.proxyName}`}
                onClose={() => onCloseWinnerModalClick()}>
                <div className={s.editScores}>
                    <ProxyDistanceEditor units={proxy.units} distance={proxyDistance} inches={proxyInches} onDistanceChange={_text => setProxyDistance(!isNaN(_text) && parseInt(_text) >= 0 ? parseInt(_text) : 0)} onInchesChange={_text => setProxyInches(!isNaN(_text) && parseInt(_text) >= 0 ? parseInt(_text) : 0)} />
                    <ListGroup className={s.proxyList}>
                    {editPlayers && editPlayers.map((p, index) =>
                        <PlayerListItem player={p} showRole={false} 
                            className={s.proxyItem}
                            onClick={() => confirmProxyWinner(p)}
                            rightDetail={proxyPlayer?.playerID === p.playerID ? <FontAwesomeIcon variant='primary' className={s.isSelected} name='check-circle' size='2x' /> : null}
                            key={`scorecard-confirm-winner-${proxy._id}-player-${p.playerID}`} />
                    )}
                    </ListGroup>
                    <Modal show={showConfirmProxyWinner} 
                        onClose={() => setShowConfirmProxyWinner(false)}
                        header={`Confirm Proxy Winner: Hole #${hole} ${proxy.proxyName}`}
                        actionButtonText='confirm winner'
                        actionButtonIcon='flag'
                        actionButtonOnClick={saveProxyWinner}>
                        <div className={s.confirmProxyWinnerContainer}>
                            <p>Are you sure you want to set the new winner for this proxy on hole #{hole} to {proxyPlayer?.firstName} {proxyPlayer?.lastName}?</p>
                            {proxy.units ? <h4 className={s.proxyDistance}><ProxyDistanceDisplay units={proxy.units} distance={proxyDistance} inches={proxyInches} /></h4> : null}
                        </div>
                    </Modal>
                </div>
            </Modal>
        </Card>
    )
}

const ScorecardTable = ({ group = {}, trip = {}, roundNumber = 0, foursome = 0, onClose = () => void(0), isEditable = false }) => {

    const groupId = group._id || null
    const tripId = trip._id || null
    if (!groupId || !tripId) return null

    const dispatch = useDispatch()
    const _key = createScorecardKey(groupId, tripId, roundNumber, foursome)
    const _scorecard = useSelector(state => state.scorecard.scorecards[_key]) || {}
    const course = _scorecard.course || {}
    const holes = _scorecard.holes || []
    const holeData = _scorecard.holeData || []
    const round = (trip.rounds || []).find(r => r.round === roundNumber)
    const _holesPlayed = (round || {}).holesPlayed || []
    const _numHolesPlayed = _holesPlayed.length
    const _firstHole = first(_holesPlayed) || 1
    const matches = ((trip.scoreboard || {}).matches || []).filter(i => i.round === roundNumber && i.foursome === foursome)
    const front9 = holes.filter(h => _holesPlayed.includes(h.hole) && h.hole >= 1 && h.hole <= 9)
    const back9 = holes.filter(h => _holesPlayed.includes(h.hole) && h.hole >= 10 && h.hole <= 18)
    const hasFront9 = front9 && front9.length > 0
    const hasBack9 = back9 && back9.length > 0
    const players = flatten(matches.map(m => [...m.usaPlayerList.map((p, _index) => ({ ...p, team: 'USA', playerTeam: 'USA', teamHandicap: m.usaTeamHandicap, isFirst: _index === 0 })), ...m.eurPlayerList.map((p, _index) => ({ ...p, team: 'EUR', playerTeam: 'EUR', teamHandicap: m.eurTeamHandicap, isFirst: _index === 0 }))]))
    const proxies = ((trip.scoreboard || {}).proxies || []).filter(i => i.round === roundNumber)
    const [editHole, setEditHole] = useState({})
    const [editPlayers, setEditPlayers] = useState([])
    const [showEdit, setShowEdit] = useState(false)
    const [proxyDistance, setProxyDistance] = useState(0)
    const [proxyInches, setProxyInches] = useState(0)
    const [isSaving, setSaving] = useState(false)
    const [showConfirmProxyWinnerHole, setShowConfirmProxyWinnerHole] = useState(0)

    const isPlayingOwnBall = (round || {}).isPlayingOwnBall || false
    const teamSize = (round || {}).teamSize || 1
    const isStableford = (round || {}).isStableford || false
    const _isTeamFormat = !isPlayingOwnBall 

    useEffect(() => {
        let timerId = setInterval(() => tick(), 60 * 1000)
        return () => clearInterval(timerId)
    }, [])

    useEffect(() => {
        if (groupId && tripId) {
            dispatch(getMatchesScoreboard(groupId, tripId))

            dispatch(getSkinsAndProxiesScoreboard(groupId, tripId))
    
            setShowConfirmProxyWinnerHole(false)    
        }
    }, [groupId, tripId])

    useEffect(() => {
        if (groupId && tripId && roundNumber && foursome && _firstHole && _numHolesPlayed) {
            fetchCourseData()

            dispatch(getHoleDataForFoursome(groupId, tripId, roundNumber, foursome))
            .then(action => {
                dispatch(setScorecardData(groupId, tripId, roundNumber, foursome, { holeData: action.payload }))
            })
        }
    }, [groupId, tripId, roundNumber, foursome, _numHolesPlayed, _firstHole])

    useEffect(() => {
        fetchCourseData()
    }, [groupId, tripId, roundNumber])


    const tick = () => {
        dispatch(getMatchesScoreboard(groupId, tripId))
        if (roundNumber && foursome) {
            dispatch(getHoleDataForFoursome(groupId, tripId, roundNumber, foursome))
            .then(action => {
                dispatch(setScorecardData(groupId, tripId, roundNumber, foursome, { holeData: action.payload }))
            })
        }
    }

    const fetchCourseData = () => {
        if (!round) {
            onClose()
        } else {
            dispatch(getGolfCourse(round.golfCourseID))
            .then(action => {
                dispatch(setScorecardData(groupId, tripId, roundNumber, foursome, { course: action.payload }))
            })

            
            dispatch(getGolfCourseHoles(round.golfCourseID))
            .then(action => {
                dispatch(setScorecardData(groupId, tripId, roundNumber, foursome, { holes: action.payload }))
            })

        }
    }

    const getInitials = (firstName, lastName) => trim((firstName || ' ')[0] + (lastName || ' ')[0])

    const doEditHole = (hole, players) => {
        if (isEditable) {
            const playerScores = players.map(p => ({ ...p, grossScore: ((holeData || []).find(i => i.hole === hole.hole && i.playerID === p.playerID) || {}).grossScore || hole.par, strokes: ((holeData || []).find(i => i.hole === hole.hole && i.playerID === p.playerID) || {}).strokes || 0, teamStrokes: ((holeData || []).find(i => i.hole === hole.hole && i.playerID === p.playerID) || {}).teamStrokes || 0 }))
            setEditHole(hole)
            setEditPlayers([...playerScores])
            setShowEdit(true)
        }
    }

    const cancelEdit = () => {
        setShowEdit(false)
        setEditHole({})
        setEditPlayers([])
        setShowConfirmProxyWinnerHole(0)
    }

    const save = () => {
        const { hole } = editHole
        const postData = {
            groupId, tripId, tripId, round: roundNumber, foursome,
            scores: editPlayers.map(p => ({ hole, playerID: p.playerID, tripPlayerID: p.tripPlayerID, grossScore: p.grossScore }))
        } 
        setSaving(true)
        dispatch(saveHoleData(postData))
        .then(action => {
            if (action.payload.messageType === 'success') {
                setSaving(false)
                setTimeout(() => tick(), 1500)
                cancelEdit()
            } else {
                dispatch(createNotification({ ...action.payload, headline: 'Error Saving Hole Data', timeout: 6000 }))
            }
        })
    }

    const handleScoreChange = (e, index, offset) => {
        e.stopPropagation()
        var _editPlayers = [...editPlayers]
        _editPlayers[index].grossScore += offset
        if (_editPlayers[index].grossScore < 0) _editPlayers[index].grossScore = 0
        if (_isTeamFormat) {
            _editPlayers.forEach((_otherPlayer, _playerIndex) => {
                if (_otherPlayer.team === _editPlayers[index].team && index !== _playerIndex) {
                    _editPlayers[_playerIndex].grossScore = _editPlayers[index].grossScore
                }
            })
        }
        setEditPlayers(_editPlayers)
    }

    const clearScores = () => setEditPlayers([...editPlayers].map(i => ({ ...i, grossScore: 0 })))

    const resetField = (e, index) => {
        e.stopPropagation()
        var _editPlayers = [...editPlayers]
        _editPlayers[index].grossScore = 0
        setEditPlayers(_editPlayers)
    }

    const updateProxyWinner = (proxy, player, distance = 0, inches = 0) => {
        setShowConfirmProxyWinnerHole(0)
        dispatch(saveProxy(groupId, tripId, proxy.round, proxy.hole, proxy.proxyID, player.playerID, distance, inches))
        .then(action => {
            dispatch(createNotification({ message: 'Set proxy winner to ' + player.playerName + '.', type: 'success', headline: `'Set Hole #${proxy.hole} Proxy Winner`, timeout: 4000 }))
        })
    }

    const scorecardRow = (h, players = [], showEditButton = false, trip = {}) => {
        const holeNumbers = uniq((holes || []).map(i => i.hole))
        const holesPlayed = uniq((holeData || []).filter(hdi => hdi.grossScore > 0).map(i => i.hole))
        const holesNotPlayed = holeNumbers.filter(hn => holesPlayed.indexOf(hn) === -1)
        var nextHole = 1
        if (holesNotPlayed.some(hnp => hnp > last(holesPlayed))) {
            nextHole = holesNotPlayed.find(hnp => hnp > last(holesPlayed))
        } else if (holesNotPlayed.length > 0) {
            nextHole = first(holesNotPlayed)
        } else {
            nextHole = last(holeNumbers)
        }
        const proxies = (trip.scoreboard || {}).proxies || []
        const isProxy = proxies.some(i => i.hole === h.hole && i.round === roundNumber)
        const isHcpRow = h.hole === 'Hcp'
        if (isHcpRow) {
            const _totalHandicaps = sumBy(players, h.isTeam ? 'teamHandicap' : 'hcp')
            if (!_totalHandicaps) return null
        }
        return (
            <tr className={(h.isBold ? s.bold : '') + (['Hcp','Team'].includes(h.hole) ? ' ' + s.hcpRow : '')} key={`scorecard-hole-${tripId}-${roundNumber}-${h.hole}`}>
                <td className={s.holeNumber + (['Hcp','Stableford'].includes(h.hole) ? ` ${s.left}` : ``)} colSpan={['Hcp','Stableford'].includes(h.hole) ? 5 : 1}>
                    {isEditable && showEditButton ?
                        <Button onClick={() => doEditHole(h, players)} 
                            className={s.editHoleButton + (h.hole !== nextHole ? ' ' + s.notActive : '')} 
                            size='sm' 
                            variant={h.hole === nextHole ? 'primary' : 'light'}>
                            <FontAwesomeIcon className={s.icon} name='pencil-alt' />
                            {' '}
                            {h.hole}
                        </Button>
                    : h.hole}
                </td>
                {!['Hcp','Stableford'].includes(h.hole) ?
                <>
                <td className={s.proxyIcon}>{isProxy ? <FontAwesomeIcon name='star' /> : null}</td>
                <td className={s.par}>{h.par}</td>
                <td className={s.yards}>{h.yards}</td>
                <td className={s.hcp}>{h.hcp}</td>
                </>
                : null}
                {players && players.map(p => {
                    var holeDataItem = { ...p, hole: h.hole, grossScore: 0 }
                    switch(h.hole) {
                        case 'Out': 
                            holeDataItem = { ...p, hole: h.hole, grossScore: sumBy(holeData.filter(hdi => hdi.hole >= 1 && hdi.hole <= 9 && hdi.playerID === p.playerID), 'grossScore') }
                            break
                        case 'In': 
                            holeDataItem = { ...p, hole: h.hole, grossScore: sumBy(holeData.filter(hdi => hdi.hole >= 10 && hdi.hole <= 18 && hdi.playerID === p.playerID), 'grossScore') }
                            break
                        case 'Total': 
                            holeDataItem = { ...p, hole: h.hole, grossScore: sumBy(holeData.filter(hdi => hdi.hole >= 1 && hdi.hole <= 18 && hdi.playerID === p.playerID), 'grossScore') }
                            break
                        case 'Hcp': 
                            holeDataItem = { ...p, hole: h.hole, grossScore: !h.isTeam ? p.hcp : p.teamHandicap }
                            break
                        case 'Stableford': 
                            holeDataItem = { ...holeDataItem, grossScore: sumBy(holeData.filter(hdi => hdi.hole >= 1 && hdi.hole <= 18 && hdi.playerID === p.playerID), 'stablefordPoints') }
                            break
                        default: 
                            holeDataItem = (holeData || []).find(i => i.hole === h.hole && i.playerID === p.playerID)
                            break
                    }
                    return (
                        !_isTeamFormat || p.isFirst ?
                        <ScorecardGolfHole isTeam={_isTeamFormat} teamSize={teamSize} hole={h} player={p} 
                            holeData={holeDataItem} trip={trip} // pass trip for theming
                            isStableford={isStableford}
                            key={`scorecard-hole-${tripId}-${roundNumber}-${h.hole}-player-${p.playerID}`} />
                        : null
                    )
                })}
            </tr>
        )
    }

    return (
        <div className={s.container}>
            <Table striped>
                <thead>
                    <tr>
                        <th className={s.headerCell + ' ' + s.matchesSummary} colSpan={5} rowSpan={2}>
                            {!isSaving && matches && matches.map(m => <ScorecardMiniMatchSummary match={m} trip={trip} key={`header-match-summary-${m._id}`} />)}
                            {isSaving ? <Spinner /> : null}
                        </th>
                        {players && players.map(p => <th className={s.headerCell + ' ' + s.flagIcon} style={{ color: trip[`${(p.team || '').toLowerCase()}TextColor`], backgroundColor: trip[`${(p.team || '').toLowerCase()}BackgroundColor`], backgroundImage: `url(${trip[`${(p.team || '').toLowerCase()}FullTeamImageUrl`]})` }} key={`header-player-flag-${p.playerID}`}>&nbsp;</th>)}
                    </tr>
                    <tr>
                        {players && players.map(p => <th className={s.headerCell + ' ' + s.playerIcon} style={{ color: trip[`${(p.team || '').toLowerCase()}TextColor`], backgroundColor: trip[`${(p.team || '').toLowerCase()}BackgroundColor`], backgroundImage: `url(${playerAvatarUrl(p.imageUrl, true)})` }} key={`header-player-icon-${p.playerID}`}>&nbsp;</th>)}
                    </tr>
                    <tr>
                        <th className={s.headerCell}>#</th>
                        <th className={s.headerCell}></th>
                        <th className={s.headerCell}>Par</th>
                        <th className={s.headerCell}>Yds</th>
                        <th className={s.headerCell}>Hcp</th>
                        {players && players.map(p => <th className={s.headerCell + ' ' + s.playerName} style={{ color: trip[`${(p.team || '').toLowerCase()}TextColor`], backgroundColor: trip[`${(p.team || '').toLowerCase()}BackgroundColor`] }} key={`header-player-name-${p.playerID}`}>{getInitials(p.firstName, p.lastName)}</th>)}
                    </tr>
                </thead>
                <tbody>
                {hasFront9 && front9.map(h => scorecardRow(h, players, true, trip))}
                {hasFront9 ? scorecardRow({ isBold: true, hole: 'Out', yards: sumBy(front9, 'yards'), hcp: null, par: sumBy(front9, 'par') }, players, false, trip) : null}
                {hasBack9 && back9.map(h => scorecardRow(h, players, true, trip))}
                {hasBack9 ? scorecardRow({ isBold: true, hole: 'In', yards: sumBy(back9, 'yards'), hcp: null, par: sumBy(back9, 'par') }, players, false, trip) : null}
                {hasFront9 || hasBack9 ? scorecardRow({ isBold: true, hole: 'Total', yards: sumBy(holes, 'yards'), hcp: null, par: sumBy(holes, 'par') }, players, false, trip) : null}
                {isStableford && (hasFront9 || hasBack9) ? scorecardRow({ isBold: true, hole: 'Stableford', yards: null, hcp: null, par: null }, players, false, trip) : null}
                {scorecardRow({ isBold: true, hole: `Hcp`, yards: null, hcp: null, par: null, isTeam: _isTeamFormat }, players, false, trip)}
                </tbody>
            </Table>
            <Modal show={showEdit} showFooter={false}
                heading={editHole ? `#${editHole.hole} Par ${editHole.par} ${editHole.yards} Yards` : null}
                onClose={() => cancelEdit()}>
                <div className={s.editScores}>
                    <ProxyAlert hole={(editHole || {}).hole || 0} 
                        roundNumber={roundNumber} 
                        isCurrentHole isNextHole={false} 
                        showConfirmProxyWinnerHole={showConfirmProxyWinnerHole}
                        onUpdateWinnerClick={_hole => setShowConfirmProxyWinnerHole(_hole)}
                        onCloseWinnerModalClick={() => setShowConfirmProxyWinnerHole(0)}
                        updateProxyWinner={updateProxyWinner}
                        proxies={proxies}
                        editPlayers={editPlayers} />
                    <Card>
                        <Card.Header>Enter hole scores</Card.Header>
                        <ListGroup className={s.editScoresList} variant='flush'>
                        {editPlayers && editPlayers.map((p, index) =>
                            <PlayerListItem className={s.editScoresListItem} 
                                style={{ color: trip[`${(p.team || '').toLowerCase()}TextColor`], backgroundColor: trip[`${(p.team || '').toLowerCase()}BackgroundColor`] }} 
                                player={p} 
                                strokes={!_isTeamFormat ? p.strokes : p.isFirst ? p.teamStrokes : 0} avatarStrokeBadgeStyle={{ color: trip[`${(p.team || '').toLowerCase()}BadgeTextColor`], backgroundColor: trip[`${(p.team || '').toLowerCase()}BadgeBackgroundColor`] }}
                                clickDisabled showRole={false} onClick={void(0)}
                                showFlag flagUrl={trip[`${(p.team || '').toLowerCase()}FullTeamImageUrl`]}
                                key={`hole-score-edit-player-${p.playerID}`}
                                rightDetail={!_isTeamFormat || p.isFirst ? <FormGroup className={s.scoreEntry}>
                                    <InputGroup className={s.inputGroup} style={{ color: trip[`${(p.team || '').toLowerCase()}BadgeTextColor`], backgroundColor: trip[`${(p.team || '').toLowerCase()}BadgeBackgroundColor`], borderColor: trip[`${(p.team || '').toLowerCase()}BadgeBackgroundColor`] }}>
                                        <Button disabled={isSaving} size='lg' className={s.offsetButton} onClick={e => handleScoreChange(e, index, -1)}><FontAwesomeIcon name='minus' /></Button>
                                        <FormControl disabled={isSaving} readOnly value={p.grossScore} size='lg' className={s.textBox} />
                                        <Button disabled={isSaving} size='lg' className={s.offsetButton} onClick={e => handleScoreChange(e, index, 1)}><FontAwesomeIcon name='plus' /></Button>
                                    </InputGroup>
                                </FormGroup> : null}>
                                <div className={s.zeroOut}>
                                    <Button size='sm' className={s.button} onClick={e => resetField(e, index)}><FontAwesomeIcon name='times' /></Button>
                                </div>
                            </PlayerListItem>
                        )}
                        </ListGroup>
                        <FormGroup className={s.editButtonContainer}>
                            <Button className={s.button + ' ' + s.clearButton} size='lg' variant={'danger'} onClick={() => clearScores()}><FontAwesomeIcon name='trash' /> clear</Button>
                            <Button className={s.button} size='lg' variant={'light'} onClick={() => cancelEdit()}><FontAwesomeIcon name='times' /> cancel</Button>
                            <Button className={s.button} disabled={isSaving} size='lg' variant='primary' onClick={() => save()}><FontAwesomeIcon name={!isSaving ? 'check' : 'sync-alt'} spin={isSaving} /> {!isSaving ? 'save' : 'saving...'}</Button>
                        </FormGroup>
                    </Card>
                    {(editHole || {}).hole ?
                    <ProxyAlert hole={(((editHole || {}).hole || 0) + 1)} 
                        roundNumber={roundNumber} 
                        isCurrentHole={false} isNextHole 
                        showConfirmProxyWinnerHole={showConfirmProxyWinnerHole}
                        onUpdateWinnerClick={_hole => setShowConfirmProxyWinnerHole(_hole)}
                        onCloseWinnerModalClick={() => setShowConfirmProxyWinnerHole(0)}
                        updateProxyWinner={updateProxyWinner}
                        proxies={proxies} 
                        editPlayers={editPlayers} />
                    : null}
                </div>
            </Modal>
        </div>
    )
}

export default ScorecardTable