// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripContacts__container___UZC9Z{margin-bottom:1rem}.TripContacts__container___UZC9Z .TripContacts__heading___lHzMF{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center;margin-bottom:1rem}.TripContacts__container___UZC9Z .TripContacts__heading___lHzMF .TripContacts__title___OCqNX{margin:0px;flex:1 1 auto}.TripContacts__container___UZC9Z .TripContacts__heading___lHzMF .TripContacts__controls___Gfk5h{flex:0 0 0;margin-left:1rem}.TripContacts__container___UZC9Z .TripContacts__list___GPB33{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Admin/TripContacts.scss"],"names":[],"mappings":"AAEA,iCACI,kBAAA,CAEA,gEACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAEA,6FACI,UAAA,CACA,aAAA,CAGJ,gGACI,UAAA,CACA,gBAAA,CAIR,6DACI,kBAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n\n.container {\n    margin-bottom: 1rem;\n\n    .heading {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n        margin-bottom: 1rem;\n\n        .title {\n            margin: 0px;\n            flex: 1 1 auto;\n        }\n\n        .controls {\n            flex: 0 0 0;\n            margin-left: 1rem;\n        }\n    }\n\n    .list {\n        margin-bottom: 1rem;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripContacts__container___UZC9Z`;
export var heading = `TripContacts__heading___lHzMF`;
export var title = `TripContacts__title___OCqNX`;
export var controls = `TripContacts__controls___Gfk5h`;
export var list = `TripContacts__list___GPB33`;
export default ___CSS_LOADER_EXPORT___;
