import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import Image from 'react-bootstrap/Image'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripRoundNotesAdminNoteItem.scss'
import Confirm from '@severed-links/common.confirm'
import TripRoundNotesAdminEditNote from './TripRoundNotesAdminEditNote'
import { playerAvatarUrl, coolTime } from '@severed-links/common.severedlinks-constants'
import moment from 'moment-timezone'
import { useDrag, useDrop } from 'react-dnd'
import { ITEM_TYPES } from './DragAndDropItemTypes'

const TripRoundNotesAdminNoteItem = ({ groupId, tripId, round = 0, _id = null, index = 0, playerId = null, player = {}, sortOrder = 0, updatedAt = null, note = '', onDelete = null, onMove = null, showHandle = true }) => {

    const dispatch = useDispatch()
    const currentPlayerId = useSelector(state => state.account._id)
    const [showEditor, setShowEditor] = useState(false)
    const _dragRef = useRef(null)
    const _previewRef = useRef(null)

    useEffect(() => {
    }, [])

    const handleDelete = () => {
        if (onDelete) onDelete(_id)
    }

    const [{ handlerId }, drop] = useDrop({
        accept: ITEM_TYPES.TRIP_ADMIN_ROUND_NOTE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!_previewRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = _previewRef.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            if (onMove) onMove(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        type: ITEM_TYPES.TRIP_ADMIN_ROUND_NOTE,
        item: () => ({ _id, index, note, playerId, updatedAt }),
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(_dragRef)
    drop(preview(_previewRef))

    if (!note || !groupId || !tripId || !round) return null
    return (
        <>
        <ListGroup.Item className={s.container} ref={_previewRef}
            action onClick={() => setShowEditor(true)}>
            {showHandle ? <div ref={_dragRef} className={s.dragHandle} onClick={e => e.stopPropagation()} title='drag here to reorder notes'><FontAwesomeIcon name='bars' /></div> : null}
            <FontAwesomeIcon name='sticky-note' className={s.icon} />
            {player?._id ? 
            <Image className={s.avatar} src={playerAvatarUrl(player.imageUrl, true)} />
            : null}
            <div className={s.content}>
                <div className={s.note}>{note}</div>
                {player._id ? <div className={s.author}>Updated by {player.firstName} {player.lastName} {updatedAt && moment(updatedAt).isValid() ? moment(updatedAt).fromNow() : null}</div> : null}
            </div>
            {_id && onDelete ?
            <div onClick={e => e.stopPropagation()}>
            <Confirm title={'Delete Note'}
                onConfirm={handleDelete} enforceFocus={false}
                confirmText='delete note'
                body={<div><p>Are you sure you want to delete this note?</p></div>}
                variant='danger' buttonClassName={s.button + ' ' + s.deleteButton}
                buttonIcon='trash' />
            </div>
        : null}
        </ListGroup.Item>
        <TripRoundNotesAdminEditNote groupId={groupId} tripId={tripId} round={round} 
            showAddButton={false} show={showEditor} note={note} noteId={_id}
            onCancel={() => setShowEditor(false)} />
        </>
    )
}

export default TripRoundNotesAdminNoteItem
