import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ListGroup, Form, Row, Col, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CopyPaymentSetup.scss'
import { getPaymentSetupNoUpdate } from '@severed-links/common.severedlinks-reducers/groups'
import { orderBy, maxBy } from 'lodash'
import Modal from '@severed-links/common.modal'
import { getOrdinalForNumber } from '@severed-links/common.severedlinks-constants'
import numeral from 'numeral'

const CopyPaymentItem = ({ label, value }) => (
    <ListGroup.Item>
        <Row>
            <Col xs={8}>{label}</Col>
            <Col xs={4} style={{ textAlign: 'right' }}>${value}</Col>
        </Row>
    </ListGroup.Item>
)

const CopyPaymentSetup = ({ group = {}, trip = {}, onCopy = null }) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [copyPaymentSetup, setCopyPaymentSetup] = useState({})
    const [showConfirm, setShowConfirm] = useState(false)
    const trips = orderBy((group.trips || []).filter(t => t._id !== trip._id), ['beginDate'], ['desc'])
    if (!trips || trips.length === 0) return null

    const showCopyPaymentModal = () => {
        setShow(true)
        setShowConfirm(false)
        setCopyPaymentSetup({})
    }

    const closeCopyPaymentModal = () => setShow(false)

    const selectTrip = _trip => {
        dispatch(getPaymentSetupNoUpdate(group._id, _trip._id))
        .then(action => showConfirmModal(action.payload, _trip.tripName))
    }

    const showConfirmModal = (copyPaymentSetup, tripName) => {
        setShow(false)
        setShowConfirm(true)
        setCopyPaymentSetup({ ...copyPaymentSetup, skinAmount: (maxBy(copyPaymentSetup.skinPayments || [], 'amount').amount || 0).toString(), medalistAmounts: [...copyPaymentSetup.medalistPayments], tripName, skinAmounts: undefined, skinPayments: undefined })
    }

    const closeConfirmModal = () => {
        setShowConfirm(false)
        setCopyPaymentSetup({})
    }

    const copy = () => {
        const postData = {
            entryFeePerPlayer: parseFloat(copyPaymentSetup.entryFeePerPlayer),
            ryderCupAwardPerPlayer: parseFloat(copyPaymentSetup.ryderCupAwardPerPlayer),
            proxyAmount: parseFloat(copyPaymentSetup.proxyAmount),
            miscellaneousAdditionalFeePerPlayer: parseFloat(copyPaymentSetup.miscellaneousAdditionalFeePerPlayer || 0.00),
            skinAmount: parseFloat(copyPaymentSetup.skinAmount),
            medalistAmounts: [...(copyPaymentSetup.medalistAmounts || [])],
        }
        if (onCopy) { 
            onCopy(postData)
        }
        closeConfirmModal()
    }

    return ([
        <Button variant='light' 
            key={`copy-button-setup-0`}
            onClick={() => showCopyPaymentModal()}>
            <FontAwesomeIcon name='copy' />
            {' '}
            <span className={'d-none d-sm-inline d-md-none'}>copy...</span>
            <span className={'d-none d-md-inline d-lg-none'}>copy from...</span>
            <span className={'d-none d-lg-inline'}>copy from previous trip...</span>
        </Button>,
        <Modal show={show} showFooter 
                key={`copy-button-setup-1`}
                heading={`Select a trip to copy from`}
                onClose={() => closeCopyPaymentModal()}>
                <div className={s.modalContainer}>
                <ListGroup>
                {trips && trips.map(t =>
                    <ListGroup.Item key={`copy-from-trip-${t._id}`} action onClick={() => selectTrip(t)}>{t.tripName}</ListGroup.Item>
                )}
                </ListGroup>
                </div>
            </Modal>,
            <Modal show={showConfirm} showFooter
                key={`copy-button-setup-2`}
                heading={`Confirm copy from ${copyPaymentSetup.tripName}`}
                actionButtonText='copy'
                actionButtonIcon='copy'
                actionButtonOnClick={() => copy()}
                onClose={() => closeConfirmModal()}>
                <div className={s.modalConfirmContainer}>
                <ListGroup>
                    <CopyPaymentItem label={'Entry fee'} value={copyPaymentSetup.entryFeePerPlayer} />
                    <CopyPaymentItem label={'Ryder Cup winners'} value={copyPaymentSetup.ryderCupAwardPerPlayer} />
                    <CopyPaymentItem label={'Prize per proxy'} value={copyPaymentSetup.proxyAmount} />
                    <CopyPaymentItem label={'Miscellaneous additional fee'} value={copyPaymentSetup.miscellaneousAdditionalFeePerPlayer || 0.00} />
                    <CopyPaymentItem label={'Skin entry per round'} value={copyPaymentSetup.skinAmount} />
                    <ListGroup.Item className={s.medalist} key={`medalist-setup-${group._id}-${trip._id}`}>
                        <div className={s.title}>Medalist Levels</div>
                        <div className={s.list}>
                        {copyPaymentSetup.medalistAmounts && copyPaymentSetup.medalistAmounts.map(i =>
                            <div className={s.item} key={`medalist-copy-${group._id}-${trip._id}-${i.rank}`}>
                                <div className={s.rank}>{getOrdinalForNumber(i.rank)}</div>
                                <div className={s.amount}>{numeral(i.amount).format('($0.00)')}</div>
                            </div>
                        )}
                        </div>
                    </ListGroup.Item>
                </ListGroup>
                </div>
            </Modal>
    ])
}

export default CopyPaymentSetup