import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Button, Tooltip, Overlay } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripNotes.scss'
import linkifyHtml from 'linkify-html'
import Modal from '@severed-links/common.modal'
import striptags from 'striptags'

const MAX_LENGTH = 250

const TripNotes = ({ notes = '' }) => {

    const [showMore, setShowMore] = useState(false)
    const [firstShowMore, setFirstShowMore] = useState(false)
    const [isMounted, setMounted] = useState(false)
    const _tooltipButton = useRef(null)
    const _containerRef = useRef(null)

    useEffect(() => {
        let _timeoutId = setTimeout(() => {
            setFirstShowMore(true)
        }, 12 * 1000)
        let _mountedTimeoutId = setTimeout(() => {
            setMounted(true)
        }, 2 * 1000)
        return () => {
            clearTimeout(_timeoutId)
            clearTimeout(_mountedTimeoutId)
        }
    }, [])

    const handleToggleShowMore = () => {
        setFirstShowMore(true)
        setShowMore(!showMore)
    }

    if (!notes) return null
    const _textNotes = striptags(notes)
    const hasMore = _textNotes.length > MAX_LENGTH
    return (
        <Card className={s.container} ref={_containerRef}>
            <Card.Header className={s.header}>
                <div className={s.icon}><FontAwesomeIcon name='sticky-note' /></div>
                <div className={s.title}>Trip Notes</div>
                {hasMore ?
                <div className={s.controls}>
                    <Button className={s.showButton} size='sm'
                        ref={_tooltipButton}
                        onClick={() => handleToggleShowMore()}>
                        <FontAwesomeIcon name={`chevron-${showMore ? 'up' : 'down'}`} />
                    </Button>
                    <Overlay container={_containerRef}
                        target={_tooltipButton} 
                        show={isMounted && !firstShowMore} 
                        placement='left'>
                    {props => (
                        <Tooltip id={`show-trip-notes-tooltip`} className={s.showMoreTooltip} {...props}>
                            More...
                        </Tooltip>
                    )}
                    </Overlay> 
                </div>
                : null}
            </Card.Header>
            <Card.Body className={s.content + (hasMore ? ` ${s.hasMore}` : '') + (showMore ? ` ${s.showMore}` : '')}>
                <div dangerouslySetInnerHTML={{ __html: linkifyHtml(notes) }} />
            </Card.Body>
        </Card>
    )
}

export default TripNotes