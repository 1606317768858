import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormControl, Form, FormLabel, FormGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import validator from 'validator'
import FormCheck from 'react-bootstrap/FormCheck'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { changePassword } from '@severed-links/common.severedlinks-reducers/profile'
import Confirm from '@severed-links/common.confirm'
import passwordPolicy from 'password-policy'
import * as s from './ChangePassword.scss'

const NewPasswordPolicy = ({ hasUpperCase, hasLowerCase, hasNumber, hasSpecialCharacter, isLongEnough }) => (
    <div className={s.passwordPolicy}>
    {[
        { title: 'Has uppercase', value: hasUpperCase }, 
        { title: 'Has lowercase', value: hasLowerCase }, 
        { title: 'Has number', value: hasNumber }, 
        { title: 'Has special character', value: hasSpecialCharacter }, 
        { title: 'Is long enough', value: isLongEnough }, 
    ].map(i => 
        <div key={`new-password-policy-${i.title}`} className={`${s.policyItem} ${i.value ? s.isValid : s.isInvalid}`}>{i.title}</div>
    )}
    </div>
)

const ChangePassword = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isSaving, setSaving] = useState(false)
    const [showPasswords, setShowPasswords] = useState(false)

    useEffect(() => initializeState(), [])
    useEffect(() => initializeState(), [playerId])

    const initializeState = () => {
        setCurrentPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setShowPasswords(false)
        setSaving(false)
    }

    const handleChange = e => {
        const _fieldName = e.target.name
        const _value = e.target.value || ''
        if (_fieldName === 'currentPassword') setCurrentPassword(_value)
        else if (_fieldName === 'newPassword') setNewPassword(_value)
        else if (_fieldName === 'confirmPassword') setConfirmPassword(_value)
    }

    const isValid = () => !validator.isEmpty(currentPassword) && !validator.isEmpty(newPassword) && 
        !validator.isEmpty(confirmPassword) && validator.equals(newPassword, confirmPassword)

    const doChangePassword = () => {
        const postData = { oldPassword: currentPassword, newPassword, confirmPassword }
        dispatch(changePassword(postData))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: "Change account password", timeout: 5000 }))
            if (action.payload.messageType === 'success') {
                setCurrentPassword('')
                setNewPassword('')
                setConfirmPassword('')
            }
        })
    }

    const showPasswordToggle = checkedState => setShowPasswords(checkedState)

    const isNewPasswordValid = () => {
        var _isValid = {
            hasUpperCase: passwordPolicy.hasUpperCase(newPassword),
            hasLowerCase: passwordPolicy.hasLowerCase(newPassword),
            hasNumber: passwordPolicy.hasNumber(newPassword),
            hasSpecialCharacter: passwordPolicy.hasSpecialCharacter(newPassword),
            length: (newPassword || '').length,
            isLongEnough: (newPassword || '').length >= 8,
            isValid: false,
        }
        _isValid.isValid = _isValid.isLongEnough && _isValid.hasUpperCase && _isValid.hasLowerCase && _isValid.hasNumber && _isValid.hasSpecialCharacter
        return _isValid
    }

    const _v = isNewPasswordValid()
    const _confirmIsValid = confirmPassword && newPassword === confirmPassword
    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>Change Password</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={6} xs={12}>
                    <Row>
                        <Col xs={12}>
                            <FormGroup size='lg' className={s.formGroup}>
                                <FormLabel style={{ display: 'block' }}>Current password <span style={{ marginTop: '-10px' }}><FormCheck type='switch' label={<FontAwesomeIcon name='eye' tag='i' />} checked={showPasswords} onChange={e => showPasswordToggle(e.target.checked)} /></span></FormLabel>
                                <FormControl type={showPasswords ? 'text' : 'password'} 
                                    name='currentPassword' value={currentPassword} 
                                    required isValid={!validator.isEmpty(currentPassword)}
                                    isInvalid={!!validator.isEmpty(currentPassword)}
                                    onChange={e => handleChange(e)} />
                                <FormControl.Feedback />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup size='lg' className={s.formGroup}>
                                <FormLabel>New password</FormLabel>
                                <FormControl type={showPasswords ? 'text' : 'password'} 
                                    name='newPassword' value={newPassword} 
                                    required isValid={_v.isValid}
                                    isInvalid={!_v.isValid}
                                    onChange={e => handleChange(e)} />
                                <FormControl.Feedback />
                                <NewPasswordPolicy {..._v} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup size='lg' className={s.formGroup}>
                                <FormLabel>Confirm new password</FormLabel>
                                <FormControl type={showPasswords ? 'text' : 'password'} 
                                    name='confirmPassword' value={confirmPassword} 
                                    required isValid={_confirmIsValid}
                                    isInvalid={!_confirmIsValid}
                                    onChange={e => handleChange(e)} />
                                <FormControl.Feedback />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup size='lg' className={s.formGroup}>
                                <Confirm title={'Confirm Password Change'} 
                                    onConfirm={() => doChangePassword()}
                                    confirmText='change password'
                                    body={<div><p>Are you sure you want to change your account password?</p></div>}
                                    variant='primary' size='lg' disabled={!isValid()} buttonIcon='lock' buttonText='change password' />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default ChangePassword