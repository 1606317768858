import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import ScorecardTable from './ScorecardTable'
import ScorecardModal from './ScorecardModal'

const ScorecardModalFromRoute = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { groupId, group, trip, tripId, round, isGroupAdmin, isGlobalAdmin, myFoursomeNumbers } = useOutletContext()
    const roundNumber = round.round || 0
    const isAdmin = isGroupAdmin || isGlobalAdmin
    const foursome = parseInt(params.foursome || '0')
    const roundName = (((trip || {}).rounds || []).find(r => r.round === roundNumber) || {}).roundName
    const isOpen = !!groupId && !!tripId && !!round && !!foursome
    const isOnlineScorecardEnabled = (round || {}).isOnlineScorecardEnabled || false
    const _leaveRoute = `${location.pathname}`.replace(`/scorecard/foursome/${foursome}`,'')
    const isEditable = isOnlineScorecardEnabled && (myFoursomeNumbers.includes(foursome) || isAdmin)

    const closeScorecard = () => {
        navigate(_leaveRoute)
    }

    return (
        <ScorecardModal group={group} trip={trip}
            isOpen={isOpen} isEditable={isEditable}
            roundNumber={roundNumber} roundName={roundName}
            foursome={foursome}
            onClose={closeScorecard} />
    )
}

export default ScorecardModalFromRoute