import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminDeviceList.scss'
import PlayerListItem from '../Player/PlayerListItem'
import moment from 'moment-timezone'

const DateTimeUpdater = ({ dateTime = null }) => {
    const [currentTime, setCurrentTime] = useState(null)

    useEffect(() => {
        const _timerId = setInterval(() => setCurrentTime(moment().local().toISOString(true)), 1000)
        return () => clearInterval(_timerId)
    }, [])

    if (!dateTime || !moment(dateTime).isValid()) return null
    else return moment(dateTime).fromNow()
}

const GlobalAdminDeviceList = ({ list = [] }) => {

    return (
        <ListGroup className={s.container}>
        {list && list.map(i => <PlayerListItem player={i} icon='mobile' key={`device-${i.uniqueId}-${i.playerId}`}
            rightDetail={<div className={s.deviceDetails}>
                <div className={s.deviceDetailsSection}>
                    {i.version ? <div className={s.bigVersion}>{i.version}</div> : null}
                    {i.osName ? <div><FontAwesomeIcon name={i.osName === 'iOS' ? 'apple' : i.osName === 'Android' ? 'android' : 'question-circle'} isBrand={i.osName === 'iOS' || i.osName === 'Android'} /> {i.osVersion}{i.version ? <div className={s.smallVersion}>({i.version})</div> : null}</div> : null}
                </div>
                <div className={s.deviceDetailsSection}>
                    {i.modelName ? <div>{i.modelName}</div> : null}
                    {i.updatedAt ? <div className={s.updatedAt}><DateTimeUpdater dateTime={i.updatedAt} /></div> : null}
                </div>
            </div>} clickDisabled showRole={false} showHcp={false} />)}
        {!list.length ? <ListGroup.Item>No devices located.</ListGroup.Item> : null}
        </ListGroup>
    )
}

export default GlobalAdminDeviceList
