import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ListGroup, Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './RequestForHelpAlert.scss'

const RequestForHelpAlert = ({ variant = 'info', icon = 'question-circle', message = 'If you need assistance, please open a ticket in our help center.' }) => {

    const navigate = useNavigate()

    return (
        <Alert className={s.container} variant={variant}>
            <FontAwesomeIcon name={icon} className={s.icon} />
            <div className={s.message}>{message}</div>
            <Button variant={variant} className={s.button} size='sm'
                onClick={() => navigate('/profile/help-center')}>
                <FontAwesomeIcon name='chevron-right' />
            </Button>
        </Alert>
    )
}

export default RequestForHelpAlert
