// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestForHelpAlert__container___ZtqZO{margin:1rem 0px;padding-top:.25rem;padding-bottom:.25rem;display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.RequestForHelpAlert__container___ZtqZO .RequestForHelpAlert__icon___EMvPJ{flex:0 0 0;margin-right:1rem}.RequestForHelpAlert__container___ZtqZO .RequestForHelpAlert__message___uRjvK{flex:1 1 auto}.RequestForHelpAlert__container___ZtqZO .RequestForHelpAlert__button___p8FtQ{flex:0 0 0;white-space:nowrap;margin-left:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Admin/RequestForHelpAlert.scss"],"names":[],"mappings":"AAEA,wCACI,eAAA,CACA,kBAAA,CACA,qBAAA,CACA,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,2EACI,UAAA,CACA,iBAAA,CAGJ,8EACI,aAAA,CAGJ,6EACI,UAAA,CACA,kBAAA,CACA,gBAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n\n.container {\n    margin: 1rem 0px;\n    padding-top: 0.25rem;\n    padding-bottom: 0.25rem;\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: space-between;\n    align-items: center;\n\n    .icon {\n        flex: 0 0 0;\n        margin-right: 1rem;\n    }\n\n    .message {\n        flex: 1 1 auto;\n    }\n\n    .button {\n        flex: 0 0 0;\n        white-space: nowrap;\n        margin-left: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `RequestForHelpAlert__container___ZtqZO`;
export var icon = `RequestForHelpAlert__icon___EMvPJ`;
export var message = `RequestForHelpAlert__message___uRjvK`;
export var button = `RequestForHelpAlert__button___p8FtQ`;
export default ___CSS_LOADER_EXPORT___;
