import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Image, Tooltip, Overlay, OverlayTrigger, Button, Popover } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { img, playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import * as s from './TripPlayerCaptainSetter.scss'
import Modal from '@severed-links/common.modal'
import PlayerListSearcher from '../../Player/PlayerListSearcher'
import PlayerListItem from '../../Player/PlayerListItem'
import { getTripSettings, setTripCaptain, clearTripCaptain, getPlayers } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import Confirm from '@severed-links/common.confirm'
import { isEqual } from 'lodash'

const TripPlayerCaptainSetter = ({ group = {}, trip = {}, player, team = '', teamName = '', isAdmin = false, isGlobalAdmin = false }) => {

    const dispatch = useDispatch()
    const [showPleaseSet, setShowPleaseSet] = useState(false)
    const [showPlayerPicker, setShowPlayerPicker] = useState(false)
    const [showCaptainPopover, setShowCaptainPopover] = useState(false)
    const _captainTarget = useRef()
    const _pleaseSet = useRef()
    const _players = group.players || []

    useEffect(() => {
        setPleaseSetVisibility()
    }, [])

    useEffect(() => {
        if (group._id && !_players.length) {
            dispatch(getPlayers(group._id))
        }
    }, [_players.length, group._id])

    const setPleaseSetVisibility = () => setTimeout(setShowPleaseSet(!!(!player || !player._id)), 5000)

    const selectCaptain = player => {
        dispatch(setTripCaptain(group._id, trip._id, team, player._id))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
            dispatch(getTripSettings(group._id, trip._id))
        })
        setShowPlayerPicker(false)
        closeCaptainPopover()
    }

    const closeCaptainPopover = () => {
        setShowCaptainPopover(false)
        setPleaseSetVisibility()
    }

    const clearCaptain = () => {
        dispatch(clearTripCaptain(group._id, trip._id, team))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
            dispatch(getTripSettings(group._id, trip._id))
        })
        setShowPlayerPicker(false)
        closeCaptainPopover()
    }

    return (
        <div className={s.container}>
        {player && player._id ? 
            <>
            <Image src={playerAvatarUrl(player.imageUrl, true)} 
                ref={_captainTarget} 
                onClick={() => setShowCaptainPopover(!showCaptainPopover)}
                roundedCircle className={s.avatar + ' ' + s[(team || '').toString().toLowerCase()]} />
            <Overlay target={_captainTarget.current} placement='top' show={showCaptainPopover}>
            {props => (
                <Tooltip id={`trip-captain-player-${team}`} className={s.captainSetterTooltip + (team ? ` ${s[team.toLowerCase()]}` : '')} {...props}>
                    <div className={s.tooltipTitle}>Captain {player.firstName} {player.lastName}</div>
                    {isAdmin || isGlobalAdmin ? 
                    <div className={s.captainTooltipContainer} onClick={e => e.stopPropagation()}>
                        <Button className={s.captainTooltipButton + ' ' + s.edit} type='button' onClick={() => { setShowPlayerPicker(true); setShowCaptainPopover(false) }}><FontAwesomeIcon name='pencil-alt' /></Button>
                        <Confirm title={'Clear Captain'}
                            onConfirm={() => clearCaptain()}
                            onClose={() => closeCaptainPopover()}
                            onButtonClick={() => closeCaptainPopover()}
                            confirmText={`clear ${teamName} captain`}
                            body={<div><p>Are you sure you want to clear the captain for team {teamName}?</p></div>}
                            variant='danger' buttonClassName={s.captainTooltipButton + ' ' + s.delete}
                            buttonIcon='trash' clear />
                    </div>
                    : null}
                </Tooltip>
            )}
            </Overlay>
            </>
        : isAdmin || isGlobalAdmin ? 
            <>
            <Button size='sm' ref={_pleaseSet} 
                onClick={() => setShowPlayerPicker(true)}
                className={s.button + ' ' + s[(team || '').toString().toLowerCase()]}>
                <FontAwesomeIcon name='question' className={s.icon} 
                    title={`${teamName} captain not set`} />
            </Button>
            <Overlay target={_pleaseSet.current} placement='top' show={showCaptainPopover}>
            {props => (
                <Tooltip id={`trip-captain-player-${team}`} style={{ whiteSpace: 'nowrap' }} {...props}>
                    Captain not set.
                </Tooltip>
            )}
            </Overlay>
            </>
        : null}
        {isAdmin || isGlobalAdmin ? 
        <Modal show={showPlayerPicker} showFooter={false}
            heading={`Select a player for ${teamName} captain`}
            onClose={() => { setShowPlayerPicker(false); setShowCaptainPopover(false) }}>
            <div>
                <PlayerListSearcher players={_players} 
                    PlayerComponent={PlayerListItem}
                    onSelect={selectCaptain} />
            </div>
        </Modal>
        : null}
        {isAdmin || isGlobalAdmin ? 
        <Overlay show={showPleaseSet} target={_pleaseSet.current} placement='top' container={this}>
        {props => (
            <Tooltip id={`trip-captain-setter-${team}`} style={{ zIndex: 99 }} {...props}>
                Captain not set.
            </Tooltip>
        )}
        </Overlay>
        : null}
        </div>
    )
}

export default TripPlayerCaptainSetter