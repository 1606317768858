// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoUserAvatar__container____vZ8v{margin:0px 0px 20px 0px}.NoUserAvatar__container____vZ8v .NoUserAvatar__alert___hSUjl .NoUserAvatar__content___QVUPt{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center;margin:0px}.NoUserAvatar__container____vZ8v .NoUserAvatar__alert___hSUjl .NoUserAvatar__content___QVUPt .NoUserAvatar__icon___kj5YD{flex:0 0 0;margin-right:8px}.NoUserAvatar__container____vZ8v .NoUserAvatar__alert___hSUjl .NoUserAvatar__content___QVUPt .NoUserAvatar__text____bdG1{flex:1 1 auto}.NoUserAvatar__container____vZ8v .NoUserAvatar__alert___hSUjl .NoUserAvatar__content___QVUPt .NoUserAvatar__button___CVU6I{flex:0 0 0;margin-left:20px;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/routes/Groups/NoUserAvatar.scss"],"names":[],"mappings":"AAAA,iCACI,uBAAA,CAGI,6FACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CACA,yHACI,UAAA,CACA,gBAAA,CAEJ,yHACI,aAAA,CAEJ,2HACI,UAAA,CACA,gBAAA,CACA,kBAAA","sourcesContent":[".container {\n    margin: 0px 0px 20px 0px;\n\n    .alert {\n        .content {\n            display: flex;\n            flex-flow: row nowrap;\n            justify-content: space-between;\n            align-items: center;\n            margin: 0px;\n            .icon {\n                flex: 0 0 0;\n                margin-right: 8px;\n            }\n            .text {\n                flex: 1 1 auto;\n            }\n            .button {\n                flex: 0 0 0;\n                margin-left: 20px;\n                white-space: nowrap;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `NoUserAvatar__container____vZ8v`;
export var alert = `NoUserAvatar__alert___hSUjl`;
export var content = `NoUserAvatar__content___QVUPt`;
export var icon = `NoUserAvatar__icon___kj5YD`;
export var text = `NoUserAvatar__text____bdG1`;
export var button = `NoUserAvatar__button___CVU6I`;
export default ___CSS_LOADER_EXPORT___;
