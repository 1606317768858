// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MvpAwardVoter__container___kdg8v .MvpAwardVoter__content___pra9I{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:flex-start}.MvpAwardVoter__container___kdg8v .MvpAwardVoter__content___pra9I .MvpAwardVoter__text___YPIhM{flex:1 1 auto}.MvpAwardVoter__container___kdg8v .MvpAwardVoter__content___pra9I .MvpAwardVoter__button___YVqL_{flex:0 0 0;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/MvpAwardVoter.scss"],"names":[],"mappings":"AACI,kEACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,sBAAA,CACA,+FACI,aAAA,CAEJ,iGACI,UAAA,CACA,kBAAA","sourcesContent":[".container {\n    .content {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: flex-start;\n        .text {\n            flex: 1 1 auto;\n        }\n        .button {\n            flex: 0 0 0;\n            white-space: nowrap;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `MvpAwardVoter__container___kdg8v`;
export var content = `MvpAwardVoter__content___pra9I`;
export var text = `MvpAwardVoter__text___YPIhM`;
export var button = `MvpAwardVoter__button___YVqL_`;
export default ___CSS_LOADER_EXPORT___;
