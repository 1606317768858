import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom'
import { FormGroup, FormLabel, FormControl, DropdownButton, 
    Dropdown, Alert, InputGroup, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventEditorDetails.scss'
import TextareaAutosize from 'react-autosize-textarea'
import Button from '@severed-links/common.button'
import FormCheck from 'react-bootstrap/FormCheck'
import validator from 'validator'
import { saveEvent } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { isValidHttpUrl } from '@severed-links/common.severedlinks-constants'

const TripEventEditorDetails = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [_isVisible, setVisible] = useState(false)
    const [_title, setTitle] = useState('')
    const [_description, setDescription] = useState('')
    const [_locationAddress, setLocationAddress] = useState('')
    const [_externalUrl, setExternalUrl] = useState('')
    const { onChange, isVisible, title, description, isTaskEvent, isPollEvent,
        locationAddress, geocodedAddress, externalUrl, eventType,
        showGeoMarker, isNew, groupId, tripId, eventTypeCapital } = useOutletContext()
    const _data = { 
        ...(isNew ? { eventType, isTaskEvent: (eventType || '').toLowerCase() === 'task', isPollEvent: (eventType || '').toLowerCase() === 'poll' } : {}), 
        isVisible: _isVisible, title: _title, description: _description, locationAddress: _locationAddress, externalUrl: _externalUrl
    }
    const showUrlValidState = !validator.isEmpty(_externalUrl)
    const isExternalUrlValid = !showUrlValidState || isValidHttpUrl(_externalUrl)
    const _isValid = !validator.isEmpty(_title) && isExternalUrlValid

    useEffect(() => {
        setVisible(isVisible || false)
        setTitle(title || '')
        setDescription(description || '')
        setLocationAddress(locationAddress || '')
        setExternalUrl(externalUrl || '')
    }, [isVisible, title, description, locationAddress, externalUrl, geocodedAddress])

    const handleUpdate = () => {
        if (onChange) {
            onChange(_data)
        }
        handleCancel()
    }
    
    const handleCancel = () => {
        const _path = isNew ? location.pathname.replace(`/new/${eventType}/details`, '') : location.pathname.replace(/\/details$/gi, '')
        navigate(_path)
    }

    const handleSaveNewEvent = () => {
        dispatch(saveEvent(groupId, tripId, _data))
        .then(action => {
            createNotification(action.payload)
            if (action.payload.messageType === 'success' && action.payload.event?._id) {
                navigate(location.pathname.replace(`/new/${eventType}/details`, `/edit/${action.payload.event?._id}`))
            }
        })
    }

    return (
        <div className={s.container}>
            <FormGroup className={s.formGroup}>
                <FormLabel className={s.titleHeading}>
                    <div>{eventTypeCapital} visible?</div>
                    <div>
                        <FormCheck type='switch' checked={_isVisible} onChange={e => setVisible(e.target.checked)} />
                    </div>
                </FormLabel>
            </FormGroup>
            <FormGroup className={s.formGroup}>
                <FormLabel className={s.titleHeading}>
                    <div>{isTaskEvent ? 'Task' : isPollEvent ? 'Poll' : 'Event'} Title</div>
                </FormLabel>
                <FormControl value={_title || ''} placeholder={`[${eventType} title...]`}
                    isValid={!validator.isEmpty(_title || '')}
                    isInvalid={validator.isEmpty(_title || '')}
                    onChange={e => setTitle(e.target.value)} />
            </FormGroup>
            <FormGroup className={s.formGroup}>
                <FormLabel>Description</FormLabel>
                <TextareaAutosize className='form-control' rows={2} 
                    placeholder={`[${eventType} description...]`} 
                    value={_description} 
                    onChange={e => setDescription(e.target.value)} />
            </FormGroup>
            {!isTaskEvent && !isPollEvent ?
            <FormGroup className={s.formGroup}>
                <FormLabel>Address {showGeoMarker ? <span title={geocodedAddress}><FontAwesomeIcon name='map-marker' /></span> : null}</FormLabel>
                <FormControl value={_locationAddress || ''} 
                    placeholder={'[event address...]'}
                    onChange={e => setLocationAddress(e.target.value)} />
            </FormGroup>
            : null}
            <FormGroup className={s.formGroup}>
                <FormLabel>Link (optional)</FormLabel>
                <FormControl value={_externalUrl || ''} 
                    placeholder={'[web (https) link address...]'}
                    {...(showUrlValidState ? { isValid: isExternalUrlValid, isInvalid: !isExternalUrlValid } : {})}
                    onChange={e => setExternalUrl((e.target.value || '').toLowerCase())} />
            </FormGroup>
            <div className={s.buttonsContainer}>
                <Button variant='light' title='cancel' icon='times' onClick={() => handleCancel()} />
                <Button className={s.saveButton} variant='primary' title={isNew ? `save new ${eventType}` : `update ${eventType}`} icon='check' onClick={() => (isNew ? handleSaveNewEvent : handleUpdate)()} disabled={!_isValid} />
            </div>
        </div>
    )
}

export default TripEventEditorDetails
