import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Alert from '@severed-links/common.alert'
import * as s from './TripRoundMiniNotes.scss'

const TripRoundMiniNotes = ({ notes = [] }) => {

    const dispatch = useDispatch()
    const userId = useSelector(state => state.account._id)

    useEffect(() => {
    }, [])

    if (!notes?.length) return null
    return (
        <div className={s.container}>
        {notes && notes.map(i =>
        <Alert message={i.note} icon='sticky-note' 
            variant='warning' small
            key={`trip-round-mini-note-${i._id}`} />
        )}
        </div>
    )
}

export default TripRoundMiniNotes
