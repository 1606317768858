import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ListGroup, Button, Table } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GroupPlayerRoundScorecard.scss'
import moment from 'moment-timezone'
import { uniqBy, sumBy, last, first } from 'lodash'
import { getPlayerRoundScorecard } from '@severed-links/common.severedlinks-reducers/player'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const TitleListItem = ({ title, value, subtitle }) => (
    <ListGroup.Item className={s.item + ' ' + s.titleItem}>
        {title ? <div className={s.title}>{title}</div> : null}
        {value ? <div className={s.value}>{value}</div> : null}
        {subtitle ? <div className={s.subtitle}>{subtitle}</div> : null}
    </ListGroup.Item>
)

const BoxItem = ({ title, value }) => (
    <div className={s.boxContainer}>
        {title ? <div className={s.title}>{title}</div> : null}
        {value ? <div className={s.value}>{value}</div> : null}
    </div>
)

const SmallScorecardHeaderItem = () => (
    <ListGroup.Item className={s.item + ' ' + s.headerItem}>
        <div className={s.headerText + ' ' + s.hole}>Hole</div>
        <div className={s.headerText + ' ' + s.par}>Par</div>
        <div className={s.headerText + ' ' + s.yards}>Yards</div>
        <div className={s.headerText + ' ' + s.score}>Score</div>
        <div className={s.headerText + ' ' + s.hcp}>Hcp</div>
    </ListGroup.Item>
)

const ScorecardCell = ({ value = null, type = 'hole', isHeader = false, isTotal = false }) => (
    <div className={s.scorecardCell + ' ' + s[`${type}`] + ' ' + s[`${type}Container`] + (isHeader ? ` ${s.isHeaderContainer}` : '') + (isTotal ? ` ${s.isTotalContainer}` : '')}>
        <div className={s[`${type}Text`] + (isHeader ? ` ${s.isHeaderText}` : '') + (isTotal ? ` ${s.isTotalText}` : '')}>{value || '\u00a0'}</div>
    </div>
)

const GroupPlayerRoundScorecard = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { groupId, tripId, round, playerId } = params
    const roundName = (((trip || {}).rounds || []).find(r => r.round === round) || {}).roundName
    const [_scorecard, setScorecard] = useState({})
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (groupId && tripId && !isNaN(round) && playerId) {
            setLoading(true)
            dispatch(getPlayerRoundScorecard(groupId, tripId, round, playerId))
            .then(action => {
                setScorecard(action.payload)
                setLoading(false)
            })
        }
    }, [groupId, tripId, round, playerId])

    const handleSendEmail = () => {
        dispatch(getPlayerRoundScorecard(groupId, tripId, round, playerId, true))
        .then(action => dispatch(createNotification(action.payload)))
    }

    const _titleItems = [
        { title: `Player`, value: `${_scorecard.firstName} ${_scorecard.lastName}` }, 
        { title: `Group`, value: _scorecard.groupName }, 
        { title: `Trip`, value: `${_scorecard.tripName} ${roundName}`, subtitle: moment(_scorecard.teeTime).isValid ? `${moment.tz(_scorecard.teeTime, _scorecard.timeZoneId).format('MMMM D, YYYY h:mm A')}` : '' }, 
        { title: `Course`, value: `${_scorecard.courseName}${_scorecard.tees ? ` (${_scorecard.tees} tees)` : ''}`, subtitle: `${_scorecard.city}, ${_scorecard.state}` }, 
    ].filter(i => i.title)

    const _boxes = [
        _scorecard.grossScore ? { title: `Score`, value:  `${_scorecard.grossScore}` } : null,
        _scorecard.par ? { title: `Par`, value:  `${_scorecard.par}` } : null,
        _scorecard.yards ? { title: `Yards`, value: `${_scorecard.yards}` } : null,
        _scorecard.rating ? { title: `Rating`, value: `${(_scorecard.rating || 0).toFixed(1)}` } : null,
        _scorecard.slope ? { title: `Slope`, value: `${(_scorecard.slope || 0).toFixed(1)}` } : null,
    ].filter(i => i && i.title)

    const _playedFront9 = (_scorecard.holeScores || []).some(h => h.grossScore > 0 && h.hole >= 1 && h.hole <= 9)
    const _playedBack9 = (_scorecard.holeScores || []).some(h => h.grossScore > 0 && h.hole >= 10 && h.hole <= 18)
    const _selectedTees = ((_scorecard.golfCourse || {}).tees || []).find(t => t.tees === _scorecard.tees) || {}
    const _holeData = (_selectedTees.holes || []).map(h => ({ ...h, grossScore: (_scorecard.holeScores.find(x => x.hole === h.hole) || {}).grossScore || 0 }))

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}><FontAwesomeIcon name='clipboard-list' /> {_scorecard.tripName} {roundName}: {_scorecard.firstName} {_scorecard.lastName}</h2>
                <div className={s.controls}>
                    <Confirm variant='primary' onConfirm={() => handleSendEmail()}
                        title={'Send Scorecard'} enforceFocus={false}
                        confirmText='send'
                        body={'Send this scorecard to your email address?'}
                        buttonIcon='envelope' />
                </div>
            </div>
            <ListGroup className={s.list}>
                {!isLoading && _titleItems && _titleItems.map(i =>
                <TitleListItem {...i} key={`title-item-${i.title}`} />
                )}

                {!isLoading && _boxes && _boxes.length ? 
                <ListGroup.Item className={s.item}>
                {!isLoading && _boxes && _boxes.map(i => 
                <BoxItem {...i} key={`box-item-${i.title}`} />
                )}
                </ListGroup.Item>
                : null}

            </ListGroup>

            {_scorecard.golfCourse && _scorecard.golfCourse.tees && _scorecard.golfCourse.tees.length && _scorecard.golfCourse.tees[0].holes ?
            <div className={s.largeContainer}>
                <Table className={s.scorecardTable} responsive>
                    <thead>
                        <tr><th className={s.hole + ' ' + s.left}>Hole</th>{_scorecard.golfCourse.tees[0].holes.map(h => <><th className={s.hole}>{h.hole}</th>{h.hole === 9 ? <th className={s.hole}>Out</th> : null}{h.hole === 18 ? <th className={s.hole}>In</th> : null}{h.hole === last(_scorecard.golfCourse.tees[0].holes).hole ? <th className={s.hole}>Total</th> : null}</>)}</tr>
                        {_scorecard.golfCourse.tees.map(_tee =>
                        <tr key={`tee-${_tee.tees}`}>
                            <th className={s.left} style={{ backgroundColor: _tee.teeBoxColors.backgroundColor, color: _tee.teeBoxColors.fontColor }}>{_tee.tees}</th>
                            {_tee.holes.map(h => <><th style={{ backgroundColor: _tee.teeBoxColors.backgroundColor, color: _tee.teeBoxColors.fontColor }}>{h.yards}</th>{h.hole === 9 ? <th style={{ backgroundColor: _tee.teeBoxColors.backgroundColor, color: _tee.teeBoxColors.fontColor }}>{_tee.outYards}</th> : null}{h.hole === 18 ? <th style={{ backgroundColor: _tee.teeBoxColors.backgroundColor, color: _tee.teeBoxColors.fontColor }}>{_tee.inYards}</th> : null}{h.hole === last(_tee.holes).hole ? <th style={{ backgroundColor: _tee.teeBoxColors.backgroundColor, color: _tee.teeBoxColors.fontColor }}>{_tee.totalYards}</th> : null}</>)}
                        </tr>
                        )}
                        <tr><th className={s.par + ' ' + s.left}>Par</th>{_scorecard.golfCourse.tees[0].holes.map(h => <><th className={s.par}>{h.par}</th>{h.hole === 9 ? <th className={s.par}>{_scorecard.golfCourse.tees[0].outPar}</th> : null}{h.hole === 18 ? <th className={s.par}>{_scorecard.golfCourse.tees[0].inPar}</th> : null}{h.hole === last(_scorecard.golfCourse.tees[0].holes).hole ? <th className={s.par}>{_scorecard.golfCourse.tees[0].totalPar}</th> : null}</>)}</tr>
                    </thead>
                    <tbody>
                    <tr><td className={s.score + ' ' + s.left}><span className={s.firstName}>{_scorecard.firstName}</span><br /><span className={s.lastName}>{_scorecard.lastName}</span></td>{_holeData.map(h => <><td className={s.score}>{h.grossScore}</td>{h.hole === 9 ? <td className={s.score + ' ' + s.isTotal}>{sumBy(_holeData.filter(x => x.hole >= 1 && x.hole <= 9), 'grossScore')}</td> : null}{h.hole === 18 ? <td className={s.score + ' ' + s.isTotal}>{sumBy(_holeData.filter(x => x.hole >= 10 && x.hole <= 18), 'grossScore')}</td> : null}{h.hole === last(_scorecard.golfCourse.tees[0].holes).hole ? <td className={s.score + ' ' + s.isTotal}>{sumBy(_holeData, 'grossScore')}</td> : null}</>)}</tr>
                    </tbody>
                    <tfoot>
                    <tr><td className={s.hcp + ' ' + s.left}>Hole</td>{_scorecard.golfCourse.tees[0].holes.map(h => <><td className={s.hcp}>{h.hcp}</td>{h.hole === 9 ? <td className={s.hcp}>&nbsp;</td> : null}{h.hole === 18 ? <td className={s.hcp}>&nbsp;</td> : null}{h.hole === last(_scorecard.golfCourse.tees[0].holes).hole ? <td className={s.hcp}>&nbsp;</td> : null}</>)}</tr>
                    </tfoot>
                </Table>
            </div>
            : null}

            <div className={s.smallContainer}>
                <ListGroup className={s.list}>
                    <SmallScorecardHeaderItem />
                    {_holeData && _holeData.map((item, index) => <><ListGroup.Item className={s.item + ' ' + s.isScorecardCellContainer}>
                    <ScorecardCell value={item.hole} type='hole' />
                    <ScorecardCell value={item.par} type='par' />
                    <ScorecardCell value={item.yards} type='yards' />
                    <ScorecardCell value={item.grossScore} type='score' />
                    <ScorecardCell value={item.hcp} type='hcp' />
                </ListGroup.Item>
                {item.hole === 9 ? <ListGroup.Item className={s.item + ' ' + s.isScorecardCellContainer}>
                    <ScorecardCell value={'Out'} type='hole' isTotal />
                    <ScorecardCell value={sumBy(_holeData.filter(h => h.hole >= 1 && h.hole <= 9), 'par')} type='par' isTotal />
                    <ScorecardCell value={sumBy(_holeData.filter(h => h.hole >= 1 && h.hole <= 9), 'yards')} type='yards' isTotal />
                    <ScorecardCell value={sumBy(_holeData.filter(h => h.hole >= 1 && h.hole <= 9), 'grossScore')} type='score' isTotal />
                    <ScorecardCell value={null} type='hcp' isTotal />
                </ListGroup.Item> : null}
                {item.hole === 18 ? <ListGroup.Item className={s.item + ' ' + s.isScorecardCellContainer}>
                    <ScorecardCell value={'In'} type='hole' isTotal />
                    <ScorecardCell value={sumBy(_holeData.filter(h => h.hole >= 10 && h.hole <= 18), 'par')} type='par' isTotal />
                    <ScorecardCell value={sumBy(_holeData.filter(h => h.hole >= 10 && h.hole <= 18), 'yards')} type='yards' isTotal />
                    <ScorecardCell value={sumBy(_holeData.filter(h => h.hole >= 10 && h.hole <= 18), 'grossScore')} type='score' isTotal />
                    <ScorecardCell value={null} type='hcp' isTotal />
                </ListGroup.Item> : null}
                {item.hole === last(_holeData).hole ? <ListGroup.Item className={s.item + ' ' + s.isScorecardCellContainer}>
                    <ScorecardCell value={'Total'} type='hole' isTotal />
                    <ScorecardCell value={sumBy(_holeData, 'par')} type='par' isTotal />
                    <ScorecardCell value={sumBy(_holeData, 'yards')} type='yards' isTotal />
                    <ScorecardCell value={sumBy(_holeData, 'grossScore')} type='score' isTotal />
                    <ScorecardCell value={null} type='hcp' isTotal />
                </ListGroup.Item> : null}
                </>)}
                </ListGroup>
            </div>
        </div>
    )
}

export default GroupPlayerRoundScorecard
