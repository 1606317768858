import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router-dom'
import { groupEntered } from '@severed-links/common.severedlinks-reducers/groups'

const GroupEntryWatcher = () => {

    const dispatch = useDispatch()
    const { params } = useMatch('/group/:groupId/*') || {}
    const groupId = (params || {}).groupId || null

    useEffect(() => {
        if (groupId) {
            dispatch(groupEntered(groupId))
        }
    }, [groupId])

    return null
}

export default GroupEntryWatcher
