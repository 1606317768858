// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GlobalAdminJobs__container___P6BhW .GlobalAdminJobs__header___FLkWa{margin:1rem 0px 2rem 0px}.GlobalAdminJobs__container___P6BhW .GlobalAdminJobs__list___nznMG{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/GlobalAdmin/GlobalAdminJobs.scss"],"names":[],"mappings":"AAGI,qEACI,wBAAA,CAEJ,mEACI,kBAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n\n.container {\n    .header {\n        margin: 1rem 0px 2rem 0px;\n    }\n    .list {\n        margin-bottom: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `GlobalAdminJobs__container___P6BhW`;
export var header = `GlobalAdminJobs__header___FLkWa`;
export var list = `GlobalAdminJobs__list___nznMG`;
export default ___CSS_LOADER_EXPORT___;
