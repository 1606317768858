import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useMatch } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripMenuBar.scss'
import TripInactiveLabel from './TripInactiveLabel'
import { setTripValue } from '@severed-links/common.severedlinks-reducers/groups'
import TripChatUnreadCommentIndicator from './TripChatUnreadCommentIndicator'
import { routingPath } from '@severed-links/common.severedlinks-constants'

const TripMenuBar = ({ group = {}, trip = {}, showScorecardMenuItem = false, isGlobalAdmin = false, isGlobalAdminPath = false }) => {

    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const match = useMatch(`/group/:groupId/trip/:tripId/:tripTab/*`)
    const activeTab = match && match.params.tripTab || null
    const isTripActive = trip.settings && trip.settings.isActive
    const canPlayersComment = trip.settings && trip.settings.canPlayersComment
    const menuItems = [
        { eventKey: 'details', icon: 'info-circle', text: 'Details' },
        isTripActive && trip.isBefore ? { eventKey: 'teams', icon: 'users', text: 'Teams' } : null,
        isTripActive && showScorecardMenuItem ? { eventKey: 'scorecard', icon: 'clipboard-list', text: 'Scorecard' } : null,
        isTripActive ? { eventKey: 'scoreboard', icon: 'star', text: 'Scoreboard' } : null,
        isTripActive && trip.isAfter ? { eventKey: 'recap', icon: 'file', text: 'Recap' } : null,
        isTripActive ? { eventKey: 'photos', icon: 'camera', text: 'Gallery' } : null,
        isTripActive && !trip.isBefore ? { eventKey: 'teams', icon: 'users', text: 'Teams' } : null,
        (group.role || {}).isAdmin || isGlobalAdmin ? { eventKey: 'admin', icon: 'cog', text: 'Admin' } : null,
        canPlayersComment ? { eventKey: 'comment', icon: 'comment', text: 'Comments' } : null,
    ].filter(i => i)

    const handleSelect = e => {
        if (e !== 'comment') {
            navigate(`${routingPath(group._id, isGlobalAdminPath, (group.name || '').substring(0,1).toUpperCase())}trip/${trip._id}/${e}`)
        } else {
            dispatch(setTripValue(group._id, trip._id, 'showChat', !trip.showChat))
        }
    }

    return (
        <Nav className={s.container} variant='pills' activeKey={activeTab} 
            onSelect={e => handleSelect(e)}>
            {menuItems && menuItems.filter(i => i).map(i => 
                <Nav.Item className={s.item} key={`trip-${trip._id}-menu-bar-${i.eventKey}`}>
                    <Nav.Link eventKey={i.eventKey} className={s.link + (i.eventKey === activeTab ? ` ${s.active}` : ``)}>
                        <FontAwesomeIcon name={i.icon} />
                        <span className={s.menuName + ' d-none d-md-inline-block'}>{i.text}</span>
                    </Nav.Link>
                    {i.eventKey === 'comment' ? <TripChatUnreadCommentIndicator groupId={group._id} tripId={trip._id} /> : null}
                </Nav.Item>
            )}
            {!isTripActive && ((group.role || {}).isAdmin || isGlobalAdmin) ? <TripInactiveLabel trip={trip} /> : null}
        </Nav>
    )
}

export default TripMenuBar