import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, ListGroup, Button, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../Player/PlayerListItem'
import PlayerSlot from './Admin/RoomAssignmentComponents/PlayerSlot'
import UnassignedRoomPanelDropZone from './Admin/RoomAssignmentComponents/UnassignedRoomPanelDropZone'
import { getRoomAssignments, saveRoomAssignment, setTripValue } from '@severed-links/common.severedlinks-reducers/groups'
import Confirm from '@severed-links/common.confirm'
import * as s from './TripRoomAssignmentPanel.scss'
import { range } from 'lodash'

const TripRoomAssignmentPanel = ({ groupId, tripId, roomAssignment, roomPlayers = [], onDelete, isAdmin = false }) => {

    if (!groupId || !tripId) return null

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const isMyRoom = roomPlayers.some(p => p.playerId === playerId)

    const onAssignPlayer = (player, room) => {
        dispatch(saveRoomAssignment(groupId, tripId, room._id, player.playerId))
        .then(() => dispatch(getRoomAssignments(groupId, tripId)))
    }

    const editRoom = room => {
        dispatch(setTripValue(groupId, tripId, 'showEditRoom', true))
        dispatch(setTripValue(groupId, tripId, 'editRoom', { ...room, roomID: room._id }))
    }

    return (
        <Card className={s.card}>
            <Card.Header className={s.roomTitle}>
                <div className={s.title}>{roomAssignment.roomName || 'Room'}</div>
                {isMyRoom ? <Badge style={{ marginLeft: '12px' }} bg='primary'>Your room!</Badge> : null}
                {isAdmin ? 
                <div className={s.controls}>
                    <Button size='sm' variant='info' onClick={() => editRoom(roomAssignment)}><FontAwesomeIcon name='pencil-alt' /></Button>
                    <Confirm title={'Remove From Group'}
                        onConfirm={() => onDelete(roomAssignment)}
                        confirmText='delete room'
                        body={<div><p>Are you sure you want to delete this room?</p><p><small>You will need to reassign any players in this room to other rooms.</small></p></div>}
                        variant='danger' buttonIcon='trash' size='sm' />
                </div>
            : null}
            </Card.Header>
            <ListGroup variant='flush'>
            {roomPlayers && roomPlayers.map(p => 
                <div key={`room-assignment-${roomAssignment.roomID}-player-${p._id}`}>
                {!isAdmin ?
                <PlayerListItem player={p} showRole={false} />
                :
                <PlayerSlot player={p} key={`room-assignment-${roomAssignment.roomID}-admin-player-${p._id}`} />
                }
                </div>
            )}
            {roomPlayers.length < roomAssignment.capacity && range(1, roomAssignment.capacity - roomPlayers.length + 1, 1).map(i =>
                !isAdmin ?
                <ListGroup.Item style={{ minHeight: '53px' }} key={`room-assignment-${roomAssignment.roomID}-unassigned-${i}`}/>
                :
                <UnassignedRoomPanelDropZone room={roomAssignment} 
                    onDrop={(player, room) => onAssignPlayer(player, room)}
                    key={`room-assignment-${roomAssignment.roomID}-unassigned-admin-${i}`}/>
            )}
            </ListGroup>
            {roomAssignment.accessCode ? <Card.Footer className={s.accessCode}>Access code:  {roomAssignment.accessCode}</Card.Footer>: null}
        </Card>
    )
}

export default TripRoomAssignmentPanel