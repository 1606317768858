// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Trips__container___KiOvL{margin-bottom:30px}.Trips__container___KiOvL .Trips__heading___RJp1j{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center;margin-bottom:1rem}.Trips__container___KiOvL .Trips__heading___RJp1j .Trips__title___gQFMM{flex:1 1 auto}.Trips__container___KiOvL .Trips__heading___RJp1j .Trips__controls___r6esF{flex:0 0 0;white-space:nowrap;margin-left:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Trips.scss"],"names":[],"mappings":"AAEA,0BACI,kBAAA,CAEA,kDACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAEA,wEACI,aAAA,CAGJ,2EACI,UAAA,CACA,kBAAA,CACA,gBAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n\n.container {\n    margin-bottom: 30px;\n\n    .heading {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n        margin-bottom: 1rem;\n        \n        .title {\n            flex: 1 1 auto;\n        }\n\n        .controls {\n            flex: 0 0 0;\n            white-space: nowrap;\n            margin-left: 1rem;\n        }\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export var container = `Trips__container___KiOvL`;
export var heading = `Trips__heading___RJp1j`;
export var title = `Trips__title___gQFMM`;
export var controls = `Trips__controls___r6esF`;
export default ___CSS_LOADER_EXPORT___;
