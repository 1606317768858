import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventTitle.scss'
import { FRIENDLY_SHORT_TIME_FORMAT } from '@severed-links/common.severedlinks-constants'
import moment from 'moment-timezone'
import Badge from '@severed-links/common.badge'
import pluralize from 'pluralize'

const TripEventTitle = ({ start, end, timeZoneId = 'America/New_York', isTaskEvent, showRsvpControls, isPollEvent, isAllDayEvent, title, isToday, isTomorrow, isOverdue, showTaskResponse, taskResponseCode, taskResponse, differentStartAndEnd, sendReminders, reminderMinutes, isAdmin = false, isVisible = false, rsvpCounts = [], taskResponses = [], eventId = null, pollVoteCount = 0 }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)

    useEffect(() => {
    }, [])

    return (
        <h4 className={s.container}>
            <div className={s.titleName}>{`${isTaskEvent ? 'Task: ' : ''}${isPollEvent ? 'Poll: ' : ''}${title}`}</div>
            {!isAllDayEvent && !isTaskEvent ?
            <div className={s.time}>
                <FontAwesomeIcon className={s.icon} name='clock' />
                {moment.tz(start, timeZoneId).format(FRIENDLY_SHORT_TIME_FORMAT)}
                {differentStartAndEnd ? `-${moment.tz(end, timeZoneId).format(FRIENDLY_SHORT_TIME_FORMAT)}` : null}
            </div>
            : null}
            {isAdmin && eventId && !isTaskEvent && !isPollEvent && showRsvpControls ?
            <>
            {rsvpCounts && rsvpCounts.filter(i => i.count).map(i => 
            <Badge className={s.responseSummaryBadge} variant={`${i.variant}`} key={`event-${eventId}-rsvp-summary-item-${i.title}`}><FontAwesomeIcon name={i.icon} className={s.icon} /> {i.count}</Badge>
            )}
            </>
            : null}
            {isAdmin && eventId && isTaskEvent ?
            <>
            {taskResponses && taskResponses.filter(i => i.count).map(i => 
            <Badge className={s.responseSummaryBadge} variant={`${i.variant}`} key={`event-${eventId}-rsvp-summary-item-${i.title}`}><FontAwesomeIcon name={i.icon} className={s.icon} />{i.count}</Badge>
            )}
            </>
            : null}
            {isAdmin && isPollEvent && pollVoteCount ? <Badge variant='info' className={s.responseSummaryBadge}>{pluralize('vote', pollVoteCount, true)}</Badge> : null}
            {(!isAdmin && !isTaskEvent && isVisible && isToday) || (!isAdmin && isTaskEvent && isToday && taskResponse !== 10) ? <Badge className={s.badge} bg='danger'>Today!</Badge> : null}
            {(!isAdmin && !isTaskEvent && isVisible && isTomorrow) || (!isAdmin && isTaskEvent && isTomorrow && taskResponse !== 10) ? <Badge className={s.badge} bg='warning' text='dark'>Tomorrow!</Badge> : null}
            {!isAdmin && isVisible && isOverdue ? <Badge className={s.badge} bg={'danger'}>Overdue!</Badge> : null}
        </h4>
    )
}

export default TripEventTitle
