import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate, useLocation, useParams, useOutletContext, useMatch } from 'react-router'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getTripPermissions, getTripRecap, getTripSettings, getTripRounds, 
    getMatchesScoreboard, getPlayerLists, getEvents, getTripPlayerSettings } from '@severed-links/common.severedlinks-reducers/groups'
import { startsWith, endsWith } from 'lodash'
import TripMenuBar from './TripMenuBar'
import TripChat from './TripChat'
import GeolocationUpdater from '../GeoLocation/GeolocationUpdater'
import * as s from './Trip.scss'
import { routingPath } from '@severed-links/common.severedlinks-constants'

const Trip = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const match = useMatch('/group/:groupId/trip/:tripId/:tripTab/*')
    const matchSub = useMatch('/group/:groupId/trip/:tripId/:tripTab/:tripSubTab/*')
    const _groupContext = useOutletContext()
    const { group, groupId, isGlobalAdmin, isGroupAdmin, isGlobalAdminPath } = _groupContext
    const tripId = params.tripId || null
    const activeTab = match && match.params.tripTab || null
    const tripSubTab = matchSub && matchSub.params.tripSubTab || null

    const trip = (group.trips || []).find(t => t._id === tripId) || {}
    const playerId = useSelector(state => state.account._id)
    const isTripActive = trip.settings && trip.settings.isActive
    const groupPath = routingPath(groupId, isGlobalAdminPath, (group.name || ' ').substring(0,1).toUpperCase())
    const tripPath = `${groupPath}trip/${tripId}`
    const tripAdminPath = `${tripPath}/admin${(tripSubTab ? `/${tripSubTab}` : '')}`
    const isAdminPath = startsWith(location.pathname, tripAdminPath)
    const isRootAdminPath = location.pathname === `${tripPath}/admin`
    const tripDetailsPath = `${tripPath}/details`
    const tripRecapPath = `${tripPath}/recap`
    const tripScoreboardPath = `${tripPath}/scoreboard`
    const showScorecardMenuItem = trip.rounds && Array.isArray(trip.rounds) && trip.rounds.some(r => r.isOnlineScorecardEnabled)
    const tripPlayers = [...(trip.usaPlayers || []), ...(trip.eurPlayers || []), ...(trip.unassignedPlayers || [])]
    const isTripPlayer = tripPlayers.some(p => p.playerID === playerId)

    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (groupId && tripId) {
            routeToTripTab()

            dispatch(getTripPermissions(tripId))
            .then(action => {
                const isAdmin = action.payload.isAdmin || isGlobalAdmin
                if (!action.payload.isGroupMember && !isGlobalAdmin) {
                    navigate('/groups', { replace: true })
                } else {
                    setLoading(true)
                    dispatch(getTripSettings(groupId, tripId))
                    dispatch(getEvents(groupId, tripId))
                    dispatch(getTripRounds(groupId, tripId))
                    .then(() => {
                        dispatch(getMatchesScoreboard(groupId, tripId))
                        dispatch(getTripPlayerSettings(groupId, tripId))

                        dispatch(getTripRecap(groupId, tripId))
                        .then(() => setLoading(false))

                        if (isAdmin) {
                            dispatch(getPlayerLists(groupId, tripId))
                        }                

                        //if (!trip.tripRecapLoaded) routeToTripTab(isAdmin)
                    })
                }
            })
        } else if (!group || !trip) {
            const redirectPath = `rdg=${groupId}&rd=${encodeURIComponent(location.pathname)}`
            navigate(`/groups` + (groupId ? `?${redirectPath}` : ``), { replace: true })
        }
    }, [groupId, tripId])

    const routeToTripTab = (isAdmin = false) => {
        isAdmin = isAdmin || isGlobalAdmin
        
        if (location.pathname === tripPath || startsWith(location.pathname, tripPath)) {
            if (isAdmin && isRootAdminPath) {
                navigate(`${tripAdminPath}${(tripSubTab ? '' : `/basics`)}`, { replace: true })
            } else if (!isTripActive && isAdmin) {
                navigate(`${tripAdminPath}/basics`, { replace: true })
            } else if (!isTripActive && !isAdmin) {
                navigate(tripDetailsPath, { replace: true })
            } else if (!activeTab) {
                if (trip.isAfter) {
                    navigate(tripRecapPath, { replace: true })
                } else {
                    navigate(tripDetailsPath, { replace: true })
                }
            }
        } else {
            navigate(groupPath, { replace: true })
        }
    }

    if (!trip || !group) return null
    return (
        <div className={s.container + (trip.showChat ? ' ' + s.chatIsOpen : '' )}>
            {isTripPlayer && trip.isDuring ? <GeolocationUpdater show={false} /> : null}
            <Row>
                <Col xs={12}>
                    <h2 className={s.tripName}><FontAwesomeIcon name='suitcase' spin={isLoading} /> {trip.tripName}</h2>
                    <h4 className={s.tripDates}>{trip.dates} {trip.location ? trip.location.locName : null}</h4>
                    <TripMenuBar trip={trip} group={group} showScorecardMenuItem={showScorecardMenuItem} isGlobalAdminPath={isGlobalAdminPath} isGlobalAdmin={isGlobalAdmin} />
                    <TripChat group={group} trip={trip} isGlobalAdmin={isGlobalAdmin} isGlobalAdminPath={isGlobalAdminPath} />
                    <Outlet context={{ ..._groupContext, trip, tripId }} />
                </Col>
            </Row>
        </div>
    )
}

export default Trip