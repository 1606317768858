import React, { act, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useOutletContext, Outlet } from 'react-router-dom'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Badge from '@severed-links/common.badge'
import * as s from './GolfApiIoDataImporter.scss'
import GolfApiIoDataApiRequestsLeft from './GolfApiIoDataApiRequestsLeft'
import { endsWith, first } from 'lodash'

const GolfApiIoDataImporter = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { _id: userId } = useSelector(state => state.account)
    const { apiRequestsLeft } = useSelector(state => state.golfApiIo)
    const _tabs = [
        { title: 'Load by City', icon: 'map-marker', path: 'load-by-city' },
    ]
    const activeTab = (_tabs.find(i => pathname.indexOf(`/${i.path}`) > -1) || {}).path || null

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (!activeTab) {
            _handleTabSelect(first(_tabs).path)
        }
    }, [activeTab])

    const _handleTabSelect = (_path) => {
        navigate(`${pathname.replace(`/${activeTab}`, '')}/${_path}`)
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h4 className={s.title}>Golf API Io Data Importer</h4>
                <div className={s.controls}>
                    <GolfApiIoDataApiRequestsLeft containerStyle={{ marginTop: '-0.5rem' }} />
                </div>
            </div>
            <Tabs id='golf-api-io-tabs' activeKey={activeTab} onSelect={_tab => _handleTabSelect(_tab)}
                className={s.tabContainer}>
            {_tabs && _tabs.map(i =>
                <Tab title={i.title} eventKey={i.path} key={`golf-api-io-tab-${i.title}`} />
            )}
            </Tabs>
            <Outlet />
        </div>
    )
}

export default GolfApiIoDataImporter
