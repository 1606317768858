import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ListGroup, Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getMyGroups, GROUPS_MY_COMPLETE, getAllGroupRoles, getTrips, 
    getPlayers, getGroupSettings } from '@severed-links/common.severedlinks-reducers/groups'
import { getMyUpcomingTrips, PLAYER_UPCOMING_TRIPS_COMPLETE } from '@severed-links/common.severedlinks-reducers/player'
import GroupListItem from './GroupListItem'
import queryString from 'query-string'
import { startsWith, flatten, orderBy } from 'lodash'
import NewGroup from './NewGroup'
import NoUserAvatar from './NoUserAvatar'
import CellPhoneNumberConfirmAlert from './CellPhoneNumberConfirmAlert'
import Modal from '@severed-links/common.modal'
import Spinner from '@severed-links/common.spinner'
import * as s from './Groups.scss'
import pluralize from 'pluralize'
import GroupShortTripList from './GroupShortTripList'
import NoGroupsOffer from './NoGroupsOffer'

const Groups = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [showNewGroupModal, setNewGroupModal] = useState(false)
    const [loadedGroupIds, setLoadedGroupIds] = useState([])
    const groups = useSelector(state => state.groups.groups)
    const isFetching = useSelector(state => state.groups.isFetching)
    const isGlobalAdmin = useSelector(state => state.account.isGlobalAdmin)
    const userIsLoaded = useSelector(state => state.account.userIsLoaded && state.account.isAuthenticated)
    const hasGroups = groups && groups.length > 0
    const upcomingTrips = useSelector(state => state.player.upcomingTrips)
    const currentTrips = useSelector(state => state.player.currentTrips)
    const recentTrips = useSelector(state => state.player.recentTrips)
    const _allTrips = flatten(groups.map(g => g.trips || []))
    const _upcomingTrips = _allTrips.filter(t => upcomingTrips.includes(t._id))
    const _currentTrips = _allTrips.filter(t => currentTrips.includes(t._id))
    const _recentTrips = _allTrips.filter(t => recentTrips.includes(t._id))
    const _upcomingTripsTitle = `Upcoming ${pluralize('Trip', upcomingTrips.length, false)}`
    const _currentTripsTitle = `Current ${pluralize('Trip', currentTrips.length, false)}`
    const _recentTripsTitle = `Recent ${pluralize('Trip', recentTrips.length, false)}`
    const qs = queryString.parse(location.search)

    useEffect(() => initialLoad(), [])
    useEffect(() => checkForGlobalAdminRedirectAfterGroupsLoad(), [groups.length, isGlobalAdmin, qs.redirect])

    const initialLoad = () => {
        dispatch(getAllGroupRoles())
        dispatch(getMyUpcomingTrips())
        dispatch(getMyGroups())
        .then(_groupsAction => onGroupsLoad(_groupsAction))
    }

    const onGroupsLoad = _groupsAction => {
        const _groupIds = (_groupsAction?.payload || []).map(i => i._id)
        _groupIds.map(_groupId => loadGroupDetails(_groupId))
    }

    const loadGroupDetails = _groupId => {
        Promise.all([
            dispatch(getTrips(_groupId)),
            dispatch(getPlayers(_groupId)),
            dispatch(getGroupSettings(_groupId)),    
        ]).then(_responses => {
            //re-route if group is redirected
            if (qs.rd && qs.rdg && qs.rdg === _groupId) {
                selectGroup(_groupId, true)
                navigate(qs.rd)
            }
        })
    }

    const checkForGlobalAdminRedirectAfterGroupsLoad = () => {
        const isGlobalAdminPath = startsWith((qs.redirect || '').toLowerCase(), '/global-admin')
        if (groups.length && isGlobalAdmin && qs.redirect && isGlobalAdminPath) {
            navigate(qs.redirect)
        }
    }

    const getBasicGroupData = () => dispatch(getAllGroupRoles()).then(action => dispatch(getMyGroups()))

    const selectGroup = (_groupId, dontRedirect = false) => {
        if (!dontRedirect) navigate(`/group/${_groupId}`)
    }
    
    const addNewGroup = () => setNewGroupModal(true)
    const closeNewGroupModal = () => setNewGroupModal(false)
    return (
        <div className={s.container}>
            <NoUserAvatar />
            <CellPhoneNumberConfirmAlert />

            <GroupShortTripList className={s.currentTripsContainer} 
                trips={_currentTrips} 
                title={_currentTripsTitle} />

            <GroupShortTripList className={s.upcomingTripsContainer}
                trips={_upcomingTrips} 
                title={_upcomingTripsTitle} />

            <GroupShortTripList className={s.recentTripsContainer}
                trips={_recentTrips} 
                title={_recentTripsTitle} />

            <div className={s.groupHeading}>
                <h2 className={s.title}>Groups {isFetching ? <Spinner /> : null}</h2>
                <div className={s.addNewGroupContainer}>
                    <Button onClick={() => addNewGroup()}
                        size={!hasGroups ? 'lg' : null} 
                        variant={hasGroups ? 'light' : 'primary'}>
                        <FontAwesomeIcon name='plus' /> {!hasGroups ? 'add a new group' : null}
                    </Button>
                </div>
            </div>

            <ListGroup>
            {hasGroups && orderBy(groups, ['sortOrder','name'], ['asc','asc']).map(i => 
                <GroupListItem key={`group-list-item-${i._id}`} 
                    group={i} onClick={selectGroup} />
            )}
            </ListGroup>

            <NoGroupsOffer show={!isFetching && !hasGroups} />

            <Modal show={showNewGroupModal} showFooter={false}
                heading={`Add a new Group`}
                onClose={() => closeNewGroupModal()}>
                <NewGroup onCancel={() => closeNewGroupModal()} />
            </Modal>

        </div>
    )
}

export default Groups