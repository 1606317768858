import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { setAccountDeletionState } from '@severed-links/common.severedlinks-reducers/accountDeletion'
import pluralize from 'pluralize'
import * as s from './AccountDeletionStep.scss'
import GroupListItem from '../Groups/GroupListItem'

const DeleteGroupsStep2 = forwardRef(({ jumpToStep, ...props }, _ref) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const _accountDeletion = useSelector(state => state.accountDeletion)
    const { 
        isFetching, groupsToDelete, groupsDeletionConfirmed, groupsToDeleteCount, 
        groupsToDeleteAdminCount, tripPlayerCount, tripMatchCount, wonLossRecordCount, 
        playerInfoSelections, playerInfoSelection, firstName, lastName, address, 
        city, state, zip, emailAddress, cellPhone, imageUrl, accountDeletionConfirmed, 
    } = _accountDeletion
    const _isValidated = !groupsToDelete.length || groupsDeletionConfirmed

    useEffect(() => {
    }, [])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValidated,
    }))

    const handleConfirmGroup = () => {
        dispatch(setAccountDeletionState({ groupsDeletionConfirmed: !groupsDeletionConfirmed }))
    }

    return (
        <div className={s.container}>
            <h4>Confirm Deletion of Groups</h4>
            {groupsToDelete.length ?
            <p>
                Please confirm that we can delete the following {pluralize('group', groupsToDelete.length, false)} that you are the only administrator of:
            </p>
            : 
            <p>
                You do not need to delete any groups.  Press the next button to continue.
            </p>}
            <ListGroup className={s.list}>
            {groupsToDelete && groupsToDelete.map(g =>
            <GroupListItem group={g} key={`group-to-delete-${g._id}`} />
            )}
            <ListGroup.Item className={s.confirmItem + (groupsDeletionConfirmed ? ` ${s.isSelected}` : '')} action onClick={() => handleConfirmGroup()}>
                <FontAwesomeIcon className={s.icon} isRegular={groupsDeletionConfirmed} name={groupsDeletionConfirmed ? 'check-circle' : 'circle'} />
                <div className={s.message}>I consent to the deletion of {groupsToDelete.length === 1 ? 'this group' : 'these groups'}.</div>
            </ListGroup.Item>
            </ListGroup>

        </div>
    )
})

export default DeleteGroupsStep2
