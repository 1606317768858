import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router'
import { Nav, NavDropdown, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Menu.scss'
import { adminCheck } from '@severed-links/common.severedlinks-reducers/account'
import CloudinaryImage from '../images/components/CloudinaryImage'
import { HEROKU_VERSION, playerAvatarUrl } from '@severed-links/common.severedlinks-constants'

const SettingsMenuTitle = ({ isAuthenticated, hasUser, user = {} }) => (
    isAuthenticated ? 
    <span>
        {' '}
        {hasUser ? 
        <Image src={playerAvatarUrl(user.imageUrl, true)} className={s.settingsMenuAvatar} />
        :
        <FontAwesomeIcon name='user' />
        }
        {' '}
        <span className={s.hiUser}>{user && user.firstName ? 'Hi ' + user.firstName + '!' : '...'}</span>
    </span> 
    : 
    <span>
        <FontAwesomeIcon name='check' /> 
        {' '}
        <span>Login</span>
    </span>
)

const SettingsMenu = ({ }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const user = useSelector(state => state.account) || { _id: null, first_name: '', last_name: '', userName: '', avatarUrl: null, roles: [], isAuthenticated: false, isGlobalAdmin: false }
    const isAuthenticated = user.isAuthenticated || false
    const isGlobalAdmin = user.isAuthenticated && user.isGlobalAdmin
    const hasUser = user && user.imageUrl
    const urlParts = location.pathname.split('/')
    const menuItems = [
        { icon: 'pencil-alt', eventKey: 9.1, text: 'Edit my profile' },
        { icon: 'lock', eventKey: 9.2, text: 'Change my password' },
        { icon: 'flag', eventKey: 9.19, text: 'Handicap tracker', addDivider: true },
        { icon: 'question', eventKey: 9.18, text: 'Help center', addDivider: true },
        isGlobalAdmin ? { icon: 'globe', eventKey: 9.99, text: 'Global admin', addDivider: true } : undefined,
        { icon: 'sign-out-alt', eventKey: 9.9, text: 'Logout' },
    ]

    useEffect(() => {
        runAdminCheck()
    }, [isAuthenticated])

    const runAdminCheck = () => { isAuthenticated ? dispatch(adminCheck()) : null }

    const doLogout = () =>  {
        navigate('/logout', { replace: true })
    }

    const handleSelect = e => {
        switch (e) {
            case '9.1': navigate(`/profile/edit`); break
            case '9.2': navigate(`/profile/change-password`); break
            case '9.18': navigate(`/profile/help-center`); break
            case '9.19': navigate(`/profile/handicap-tracker`); break
            case '9.99': navigate(`/global-admin`); break
            case '9.9': doLogout(); break
            default: break
        }
    }

    if (!isAuthenticated) return null

    return (
        <Nav className={`${s.nav + ' ' + s.settingsMenu} justify-content-end`} onSelect={handleSelect}>
            <NavDropdown className={`${s.item} ${(hasUser ? s.settingsMenuHasAvatar : s.settingsMenuHasNoAvatar)}`} align='end'
                id='sl-settings-nav-dropdown' 
                title={<SettingsMenuTitle isAuthenticated={isAuthenticated} hasUser={hasUser} user={user} />}>
                {menuItems && menuItems.filter(i => i).map(i => [
                    <NavDropdown.Item eventKey={i.eventKey} 
                        key={`settings-menu-dropdown-${i.eventKey}`}>
                        <FontAwesomeIcon name={i.icon} />
                        {' '}
                        {i.text}
                    </NavDropdown.Item>,
                    i.addDivider ? <NavDropdown.Divider key={`settings-menu-divider-${i.eventKey}`} /> : null
                ])}
                <div style={{ textAlign: 'right', fontSize: '80%', color: '#aaa', fontWeight: 'normal', marginRight: '10px', padding: '0px' }}>
                    {HEROKU_VERSION}
                </div>
            </NavDropdown>
        </Nav>
    )
}

export default SettingsMenu