import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useOutletContext, Outlet } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './AddressBookContactListItem.scss'
import Modal from '@severed-links/common.modal'
import Button from '@severed-links/common.button'
import { trim } from 'lodash'
import { saveAddressBookEntry, deleteAddressBookEntry, removeGroupAddressBookEntryFromTrip } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const IndicatorIcon = ({ icon = null, count = 0, hideCount = false }) => (
    count ? <div className={s.indicatorContainer}>{icon ? <FontAwesomeIcon name={icon} className={s.icon} /> : null}{!hideCount && count > 1 ? <div className={s.count}>{count}</div> : null}</div> : null
)

const AddressBookContactListItem = ({ _id = null, groupId = null, tripId = null, variant = null, firstName = '', lastName = '', company = '', notes = '', trips = [], emailAddresses = [], phoneNumbers = [], showIndicators = true, rightDetail = null }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const playerId = useSelector(state => state.account._id)
    const [showDetailEntry, setShowDetailEntry] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const _detailMenuItems = [
        notes ? { icon: 'sticky-note', variant: 'warning', title: notes } : null,
        ...(emailAddresses.map(i => ({ icon: 'envelope', variant: null, title: `Email ${i.value}`, onClick: () => openEmail(i.value) }))),
        ...(phoneNumbers.map(i => ({ icon: 'phone', variant: null, title: `Call ${i.value}`, onClick: () => openPhoneNumber(i.value) }))),
        { icon: 'pencil', variant: 'info', title: 'Edit', onClick: () => navigate(`/group/${groupId}/admin/address-book/edit/${_id}`) },
        groupId && tripId && _id ? { icon: 'times', variant: 'secondary', title: 'Remove trip association', onClick: () => handleRemoveTripAssociation() } : null,
        { icon: 'trash', variant: 'danger', title: 'Delete', onClick: () => handleBeginDeleteEntry() },
    ].filter(i => i)

    useEffect(() => {
    }, [])

    const handleRemoveTripAssociation = () => {
        dispatch(removeGroupAddressBookEntryFromTrip(groupId, tripId, _id))
    }

    const handleContactClick = () => {
        setShowDetailEntry(true)
    }

    const handleBeginDeleteEntry = () => {
        setShowDetailEntry(false)
        setShowConfirmDelete(true)
    }

    const handleContactDelete = () => {
        setShowDetailEntry(false)
        setShowConfirmDelete(false)
        dispatch(deleteAddressBookEntry(groupId, _id))
    }

    const handleDetailEntryMenuClick = _cb => {
        if (_cb) _cb()
        setShowDetailEntry(false)
    }

    const openEmail = _email => {
        window.location.href = `mailto:${_email}`
    }

    const openPhoneNumber = _phone => {
        window.location.href = `tel:+1${(_phone || '').replace(/\D/gi, '')}`
    }

    return (
        <>
        <ListGroup.Item className={s.container + (variant ? ` ${s[variant]}` : ``)}
            action onClick={handleContactClick}>
            <div className={s.icon}><FontAwesomeIcon name='user' /></div>
            <div className={s.content}>
                {firstName || lastName ? <div className={s.name}>{trim(`${firstName} ${lastName}`)}</div> : null}
                {company ? <div className={s.company}>{trim(`${company}`)}</div> : null}
            </div>
            {showIndicators ?
            <>
            <IndicatorIcon icon='sticky-note' count={notes ? 1 : 0} hideCount />
            <IndicatorIcon icon='briefcase' count={trips?.length} />
            <IndicatorIcon icon='phone' count={phoneNumbers?.length} />
            <IndicatorIcon icon='envelope' count={emailAddresses?.length} />
            </>
            : null}
            {rightDetail ? <div className={s.rightDetail}>{rightDetail}</div> : null}
            <div><FontAwesomeIcon name='chevron-right' /></div>
        </ListGroup.Item>

        <Modal show={showDetailEntry} heading={`Contact Menu`}
                onClose={() => setShowDetailEntry(false)} showFooter={false}>
            <div className={s.addressBookDetailMenuContainer}>
                <ListGroup className={s.list}>
                {_detailMenuItems && _detailMenuItems.map((i, index) =>
                <ListGroup.Item className={s.item + (i.variant ? ` ${s[i.variant]}` : ``)} 
                    action={!!i.onClick} onClick={() => handleDetailEntryMenuClick(i.onClick || null)}
                    key={`address-book-detail-entry-menu-${_id}-${i.icon}`}>
                    <div className={s.icon}><FontAwesomeIcon name={i.icon} /></div>
                    <div className={s.content}>
                        <div>{i.title}</div>
                    </div>
                </ListGroup.Item>
                )}
                </ListGroup>
            </div>
            </Modal>

            <Modal show={showConfirmDelete} heading={'Delete Contact'}
                onClose={() => setShowConfirmDelete(false)} showFooter
                actionButtonStyle='danger'
                actionButtonIcon='trash'
                actionButtonText='delete contact'
                actionButtonOnClick={() => handleContactDelete()}>
                <div>
                    <p>Are you sure you want to delete this contact?</p>
                    <p><strong>{firstName} {lastName}</strong></p>
                </div>
            </Modal>

        </>
    )
}

export default AddressBookContactListItem
