import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './FloatHeaderWithButtons.scss'

const FloatHeaderWithButtons = ({ header = null, headerTag = 'h4', buttons = [], style = {}, buttonStyle = {} }) => {

    const HeaderTag = headerTag
    return (
        <div className={s.container} style={{ ...style }}>
            {header ? <HeaderTag className={s.titleContainer}>{header}</HeaderTag> : null}
            <div className={s.buttonContainer}>
            {buttons && buttons.map((i, index) =>
                i ? 
                <Button style={{ ...buttonStyle }} 
                    className={s.button}
                    key={`float-header-button-${i.text}-${index}`}
                    onClick={i.onClick}
                    size={i.bsSize ? i.bsSize : i.size ? i.size : undefined}
                    title={i.title ? i.title : undefined}
                    variant={i.variant ? i.variant : undefined}>
                    {i.icon ? <FontAwesomeIcon name={i.icon} /> : null}
                    {' '}
                    {i.text ? i.text : null}
                </Button>
                : null
            )}
            </div>
        </div>
    )
}

export default FloatHeaderWithButtons
