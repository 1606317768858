import React, { useEffect } from 'react'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripPlayerPhotoCounts.scss'

const TripPlayerPhotoCounts = ({ photoCount = 0, tripCount = 0, playerCount = 0 }) => {

    return (
        <div className={s.container}>
            {tripCount ? <div title='Trips' className={s.item}><FontAwesomeIcon name='suitcase' /> {tripCount}</div> : null}
            {playerCount ? <div title='Players' className={s.item}><FontAwesomeIcon name='users' /> {playerCount}</div> : null}
            {photoCount ? <div title='Photos' className={s.item}><FontAwesomeIcon name='camera' /> {photoCount}</div> : null}
        </div>
    )
}

export default TripPlayerPhotoCounts