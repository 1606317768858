import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams, useMatch } from 'react-router-dom'
import { Nav, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getPlayers, getLocations, getGolfCourses, 
    getGroups, setGlobalAdminState } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import GlobalAdminGolfCourseSearcher from './GlobalAdminGolfCourseSearcher'
import GlobalAdminGroupSearcher from './GlobalAdminGroupSearcher'
import { first, isEqual, endsWith, startsWith } from 'lodash'
import pluralize from 'pluralize'
import FormCheck from 'react-bootstrap/FormCheck'
import * as s from './GlobalAdminLetterBar.scss'

const TAB_PREFIX = '/global-admin/'.toLowerCase()

const GlobalAdminLetterBar = ({ letters = [], type = null }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const match = useMatch('/global-admin/:globalAdminTab/*')
    const params = useParams()
    const hideDeletedGroups = useSelector(state => state.globalAdmin.groups.hideDeletedGroups)
    const golfCoursesBadgeCount = useSelector(state => state.globalAdmin.golfCourses.audit.badgeCount || 0)
    const globalAdminTab = match && match.params.globalAdminTab
    const letterTab = params.globalAdminLetter || null
    const playerId = params.playerId || null
    const locationId = params.locationId || null
    const golfCourseId = params.golfCourseId || null
    const groupId = params.groupId || null
    const firstItem = letters.find(l => !l.disabled)
    const isRootItem = endsWith(location.pathname.toLowerCase(), globalAdminTab)
    const ignoreRoute = ['/new-players'].some(i => location.pathname.toLowerCase().indexOf(i) > -1)

    useEffect(() => { checkForReRoute() }, [ignoreRoute, isRootItem, letters.length, firstItem, letterTab])

    useEffect(() => {
        loadData(letterTab)
    }, [letterTab])

    const checkForReRoute = () => {
        if (!ignoreRoute && isRootItem && !letterTab && firstItem && firstItem.letter && firstItem.letter.length === 1) {
            navigate(`${location.pathname}/filter/${firstItem.letter}`)
        }
    }

    const loadData = letter => {
        if (letter) {
            if (globalAdminTab === 'players') dispatch(getPlayers(letter))
            else if (globalAdminTab === 'groups') dispatch(getGroups(letter))
            else if (globalAdminTab === 'lodging') dispatch(getLocations(letter))
            else if (globalAdminTab === 'courses'){
                if (letter !== 'utilities') {
                    dispatch(getGolfCourses(letter))
                }
            } 
        }
    }

    const handleClick = e => {
        if (e === 'new-players') {
            navigate(`/global-admin/players/${e}`)
        } else {
            if (e === '+') {
                navigate(`/global-admin/${globalAdminTab}/edit/0`)
            } else if ((e || '').toLowerCase() === 'utilities') {
                navigate(`/global-admin/${globalAdminTab}/utilities`)
            } else {
                navigate(`/global-admin/${globalAdminTab}/filter/${e}`)
            }
        }
    }

    return (
        <div className={s.container}>
        <Nav className={s.nav} variant='pills' activeKey={letterTab} onSelect={handleClick}>
        {letters && letters.map(l =>
            <Nav.Item key={`global-admin-letter-bar-${l.letter}`}>
                <Nav.Link eventKey={l.letter} 
                title={`${pluralize(type, l.count, true)}`}
                disabled={l.disabled}>
                {l.letter}
                </Nav.Link>
            </Nav.Item>
        )}
        {type === 'location' ? <Nav.Item><Nav.Link onClick={() => navigate('/global-admin/lodging/edit/0')} eventKey='+'><FontAwesomeIcon name='plus' /></Nav.Link></Nav.Item> : null}
        {type === 'golf course' ? <Nav.Item><Nav.Link onClick={() => navigate('/global-admin/courses/edit/0')} eventKey='+'><FontAwesomeIcon name='plus' /></Nav.Link></Nav.Item> : null}
        {type === 'golf course' ? <GlobalAdminGolfCourseSearcher /> : null}
        {type === 'group' ? <GlobalAdminGroupSearcher /> : null}
        {type === 'group' ? <div className={s.hideDeletedContainer}><FormCheck type='switch' checked={hideDeletedGroups} onChange={e => dispatch(setGlobalAdminState('groups', { hideDeletedGroups: e.target.checked }))} label={<span className={s.switchLabel}><FontAwesomeIcon name='trash' className={s.smallIcon} /><small className={s.largeText}>hide deleted?</small></span>} /></div> : null}
        {type === 'golf course' ? <Nav.Item><Nav.Link onClick={() => handleClick('utilities')} eventKey='Utilities'>Utilities{golfCoursesBadgeCount ? <Badge className={s.badge} bg='danger' text='light'>{golfCoursesBadgeCount}</Badge> : null}</Nav.Link></Nav.Item> : null}
        {type === 'player' ? <Nav.Item><Nav.Link onClick={() => handleClick('new-players')} eventKey='new-players'>New</Nav.Link></Nav.Item> : null}
        </Nav>
        </div>
    )
}

export default GlobalAdminLetterBar