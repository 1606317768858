import React, { useState, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormGroup, InputGroup, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import { getGroupsByName, getGroups } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import * as s from './GlobalAdminGroupSearcher.scss'

const GlobalAdminGroupSearcher = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const _typeahead = useRef()

    const handleLookupChange = e => {
        const groupId = e[0]._id
        const letter = (e[0].name || ' ').substring(0,1).toUpperCase()
        dispatch(getGroups(letter))
        .then(() => {
            navigate(`/global-admin/groups/filter/${letter}/view/${groupId}`)
        })
    }

    const runQuery = useCallback(query => {
        setLoading(true)
        dispatch(getGroupsByName(query))
        .then(action => {
            setOptions(action.payload)
            setLoading(false)
        })
    }, [])

    return (
        <div className={s.container}>
            <FormGroup className={s.formGroup}>
            <InputGroup className={s.inputGroup}>
                <InputGroup.Text><FontAwesomeIcon name='search' /></InputGroup.Text>
                <AsyncTypeahead ref={_typeahead}
                    id={`global-admin-group-searcher-typeahead`}
                    onSearch={runQuery}
                    isLoading={isLoading}
                    multiple={false} autoFocus
                    labelKey={o => `${o.name} (ID: ${o._id})`}
                    renderMenu={(results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => (
                        <Menu {...menuProps}>
                            {results.map((o, index) => (
                            <MenuItem className={s.menuItem} option={o} position={index} key={`tpyeahead-group-search-item-${o._id}`}>
                                {o.name} {o.isDeletedGroup ? <Badge bg='danger'>Deleted</Badge> : null}
                            </MenuItem>
                            ))}
                        </Menu>
                    )}
                    placeholder={'[Lookup group by name...]'}
                    options={options}
                    onChange={e => handleLookupChange(e)} />
                </InputGroup>
            </FormGroup>
        </div>
    )
}

export default GlobalAdminGroupSearcher