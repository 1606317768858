import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card, ListGroup, Button, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './NearByPlayersListItem.scss'
import moment from 'moment-timezone'
import { getNearbyPlayers } from '@severed-links/common.severedlinks-reducers/geoLocation'
import GeolocationUpdater from '../GeoLocation/GeolocationUpdater'

const NearByPlayersListItem = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const playerId = useSelector(state => state.account._id)
    const nearbyPlayers = useSelector(state => state.geoLocation.nearbyPlayers) || []
    const _events = (trip.events || [])
    const _intervals = [
        { start: trip.beginDate, end: trip.endDate, timeZoneId: (trip.timezone || {}).timeZoneId || null },
        ..._events.filter(i => !i.isPollEvent && !i.isTaskEvent).map(i => ({ start: i.startTime, end: i.endTime, timeZoneId: (i.timezone || {}).timeZoneId })),
        __DEV__ ? { start: moment().startOf('day').toISOString(true), end: moment().endOf('day').toISOString(true), timeZoneId: (trip.timezone || {}).timeZoneId || null } : null,
    ].filter(i => i && moment(i.start).isValid() && moment(i.end).isValid())
    .map(i => ({ ...i, timeZoneId: i.timeZoneId || 'America/New_York' }))
    const _isWithinAnyInterval = _intervals.some(i => moment().isBetween(moment.tz(i.start, i.timeZoneId), moment.tz(i.end, i.timeZoneId)))

    
    useEffect(() => {
        if (group._id && trip._id && _isWithinAnyInterval) {
            dispatch(getNearbyPlayers(group._id, trip._id))
        }
    }, [group._id, trip._id, _isWithinAnyInterval])

    if (!group._id || !trip._id || !_isWithinAnyInterval) return null
    const _path = `/group/${group._id}/trip/${trip._id}/nearby-players`
    return (
        <>
        {nearbyPlayers.length > 1 ?
        <Card className={s.container + (nearbyPlayers.length ? ` ${s.hasPlayers}` : '')}
            onClick={nearbyPlayers.length ? () => navigate(_path) : null}>
            <Card.Header className={s.header}>
                <div className={s.icon}><FontAwesomeIcon name='map-marker' /></div>
                <div className={s.title}>Nearby Players</div>
                {nearbyPlayers.length ?
                <>
                <div className={s.count}><Badge bg='info' text='light'>{nearbyPlayers.length}</Badge></div>
                <div>
                    <Button size='sm' variant='light'>
                        <FontAwesomeIcon name='chevron-right' />
                    </Button>
                </div>
                </>
                : null}
            </Card.Header>
        </Card>
        : null}
        <GeolocationUpdater show={false} />
        </>
    )
}

export default NearByPlayersListItem
