import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Confirm from '@severed-links/common.confirm'
import { recoverTrip, getTrips, getDeletedTrips } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const RecoverTripButton = ({ group = {}, trip = {}, size = 'md', className = '', onRecover, isGlobalAdmin = false }) => {

    const dispatch = useDispatch()

    const onRecoverTrip = () => {
        dispatch(recoverTrip(group._id, trip._id))
        .then(action => {
            dispatch(createNotification({ ...action.payload, headline: 'Recover Trip', timeout: 4000 }))
            if (action.payload.messageType === 'success') {
                dispatch(getDeletedTrips(group._id))
                dispatch(getTrips(group._id))
                .then(action2 => {
                    if (onRecover) onRecover()
                    const path = isGlobalAdmin ? `/global-admin/groups/${group.name.substring(0,1).toUpperCase()}/view/${group._id}/` : `/group/${group._id}/`
                    navigate(`${path}trip/${action.payload.tripId}`) 
                })
            }
        })
    }

    return (
        <Confirm title={'Recover Trip'}
            onConfirm={() => onRecoverTrip()}
            confirmText='recover trip'
            body={<div><p>Are you sure you want to recover this trip?</p></div>}
            variant='info' size={size} buttonClassName={className} 
            buttonIcon='sync-alt' clear 
            buttonTitle={size !== 'xs' ? 'recover trip' : ''} />
    )
}

export default RecoverTripButton