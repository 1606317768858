import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nav, Navbar, FormGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminPaymentsNavBar.scss'
import Datetime from 'react-datetime'
import { getPaymentsForDate, setGlobalAdminState } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import { FRIENDLY_SHORT_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import moment from 'moment-timezone'
import SquareCreditCardInitializer from '../CreditCard/SquareCreditCardInitializer'
import CreditCardPaymentForm from '../CreditCard/CreditCardPaymentForm'
import Modal from '@severed-links/common.modal'

const GlobalAdminPaymentsNavBar = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const date = useSelector(state => state.globalAdmin.payments.date)
    const _date = moment(date).format(FRIENDLY_SHORT_DATE_FORMAT)
    const _dateKey = moment(date).format('YYYYMMDD')
    const paymentDate = useSelector(state => state.globalAdmin.payments.dates.find(i => i.dateKey === _dateKey)) || {}
    const isFetching = useSelector(state => state.globalAdmin.payments.isFetching)
    const [firstLoad, setFirstLoad] = useState(false)
    const [testCreditCardAmount, setTestCreditCardAmount] = useState(0.00)
    const [showPaymentTester, setShowPaymentTester] = useState(false)
    const paymentCount = ((paymentDate || {}).payments || []).length
    const _hasPayments = paymentCount > 0

    useEffect(() => {
        if (showPaymentTester) {
            setTestCreditCardAmount((Math.round(Math.random() * 40) + 10) / 100.0)
        } else {
            loadPayments()
        }
    }, [showPaymentTester, date])

    useEffect(() => {
        if (!isFetching && paymentDate.updatedAt && !firstLoad) {
            setFirstLoad(true)
            if (!_hasPayments && paymentDate.previousDate) {
                handleOffsetButton(paymentDate.previousDate)
            }
        }
    }, [isFetching, firstLoad, paymentDate.updatedAt || null])

    const loadPayments = () => {
        if (date && moment(date).isValid()) {
            dispatch(getPaymentsForDate(date))
        }
    }

    const handleDateChange = _dt => {
        dispatch(setGlobalAdminState('payments', { date: moment(_dt).startOf('day').toISOString(true) }))
    }

    const handleOffsetButton = _newDate => {
        if (_newDate && moment(_newDate).isValid()) {
            dispatch(setGlobalAdminState('payments', { date: moment(_newDate).startOf('day').toISOString(true) }))
        }
    }

    return (
        <Navbar className={s.container} bg='light'>
            <Button variant='light'
                className={s.offsetButton + ' ' + s.left} 
                onClick={() => handleOffsetButton(paymentDate.previousDate)}>
                {paymentDate.previousDate ? moment(paymentDate.previousDate).format('MMM D YYYY') : '--'}
            </Button>
            <FormGroup className={s.datePicker}>
                <Datetime value={_date}
                    timeFormat={false} dateFormat={FRIENDLY_SHORT_DATE_FORMAT}
                    onChange={_dt => handleDateChange(_dt)} />
            </FormGroup>
            <Button variant='light'
                className={s.offsetButton + ' ' + s.left + ' ' + s.right + ' ' + s.refresh} 
                onClick={() => loadPayments()}>
                <FontAwesomeIcon name='sync-alt' />
            </Button>
            <Button variant='light'
                className={s.offsetButton + ' ' + s.right} 
                onClick={() => handleOffsetButton(paymentDate.nextDate)}>
                {paymentDate.nextDate ? moment(paymentDate.nextDate).format('MMM D YYYY') : '--'}
            </Button>
            {isFetching ? 
            <div className={s.spinner}>
                <FontAwesomeIcon name='circle-notch' spin />
            </div>
            : null}
            <Button info='light' className={s.testButton}
                onClick={() => setShowPaymentTester(true)}>
                <FontAwesomeIcon name='credit-card' />
                {' '}
                test payment
            </Button>

            <Modal show={showPaymentTester} showFooter={false}
                onClose={() => setShowPaymentTester(false)}
                heading='Credit Card Payment Test'>
                <div>
                    <SquareCreditCardInitializer show />
                    {testCreditCardAmount ?
                    <CreditCardPaymentForm amount={testCreditCardAmount} groupId={null} tripId={null} 
                        onSuccess={response => console.log('success', response)} 
                        onError={response => console.log('fail', response)} />
                    : null}
                </div>
            </Modal>
        </Navbar>
    )
}

export default GlobalAdminPaymentsNavBar
