import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { FormGroup, FormLabel, FormControl, InputGroup, 
    Button, ButtonGroup, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GroupMessaging.scss'
import { Editor, EditorState, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import 'draft-js/dist/Draft.css'
import validator from 'validator'
import { sortBy, reject, uniqBy, uniq, isEqual } from 'lodash'
import PlayerListItem from '../../Player/PlayerListItem'
import { sendGroupMessage } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import TextareaAutosize from 'react-autosize-textarea'
import FormCheck from 'react-bootstrap/FormCheck'
import pluralize from 'pluralize'
import MessageTypeSelector from '../../Trips/Admin/MessageTypeSelector'
import PushTypeSelector from '../../Trips/Admin/PushTypeSelector'

const GroupMessaging = () => {

    const dispatch = useDispatch()
    const { group, groupId } = useOutletContext()
    const [subject, setSubject] = useState('')
    const [textMessage, setTextMessage] = useState('')
    const [emailMessage, setEmailMessage] = useState(EditorState.createEmpty())
    const [messageType, setMessageType] = useState('email')
    const [sendTo, setSendTo] = useState([])
    const [roles, setRoles] = useState([])
    const [textLimit, setTextLimit] = useState(140)
    const [isSaving, setIsSaving] = useState(false)
    const [pushType, setPushType] = useState('info')
    const [clearOnSend, setClearOnSend] = useState(false)
    const players = group.players || []
    const smsCharsRemaining = textLimit - (textMessage || '').length
    const smsWarning = smsCharsRemaining >= 20 ? 'success' : smsCharsRemaining >= 1 ? 'warning' : 'danger'
    const onlineUserCount = (group.onlineUsers || []).length
    const _roles = sortBy(uniqBy(players.filter(p => p.role).map(p => ({ _id: p.role._id, description: p.role.description, adminDescription: p.role.adminDescription, variant: p.role.variant })), '_id').map(r => ({ ...r, count: players.filter(p1 => p1.role._id === r._id).length })), 'adminDescription')

    useEffect(() => resetEditor(() => selectAllRoles()), [])

    useEffect(() => {
        if (group.players && group.players.length > 0) {
            selectAllRoles()
        }
    }, [group.players])

    const resetEditor = cb => {
        setSubject('')
        setTextMessage('')
        setEmailMessage(EditorState.createEmpty())
        setSendTo([])
        if (cb) cb()
    }

    const selectAllRoles = (onlyActive = true) => {
        const _sendTo = [...uniq(sortBy(_roles.filter(r => !onlyActive || (onlyActive && ['Admin','Active'].indexOf(r.adminDescription) > -1)), '_id').map(r => r._id))]
        setRoles(_roles)
        setSendTo(_sendTo)
    }

    const handleChange = e => {
        const _fieldName = e.target.name
        const _value = e.target.value
        if (_fieldName === 'subject') setSubject(_value)
        else if (_fieldName === 'textMessage') setTextMessage(_value)
    }

    const handleEditorChange = _emailMessage => setEmailMessage(_emailMessage)
    const handlePushTypeChange = _pushType => setPushType(_pushType)
    const handleEditorButton = _command => handleEditorChange(RichUtils.toggleInlineStyle(emailMessage, _command))
    const toggleRole = id => {
        var _sendTo = [...sendTo]
        if (_sendTo.indexOf(id) > -1) {
            _sendTo = reject(_sendTo, i => i === id)
        } else {
            _sendTo.push(id)
        }
        setSendTo(_sendTo)
    }

    const isValidToSend = () => {
        if (['email','sms'].indexOf(messageType) > -1 && (!sendTo || sendTo.length === 0)) {
            return false
        } else if (messageType === 'sms') {
            return textMessage && textMessage.length <= textLimit
        } else if (messageType === 'push') {
            return textMessage && textMessage.length <= textLimit && subject
        } else if (messageType === 'mobile') {
            return textMessage && textMessage.length > 0
        } else {
            return subject && emailMessage.getCurrentContent().getPlainText('').length > 0
        }
    }

    const send = () => {
        const htmlEmailOptions = {
            blockStyleFn: block => {
                return {
                    style: {
                        marginBottom: '12px'
                    }
                }
            }        
        }
        const _message = { 
            subject, textMessage, messageType, sendTo, pushType, groupId: group._id, 
            emailMessage: stateToHTML(emailMessage.getCurrentContent(), htmlEmailOptions),
        }
        setIsSaving(true)
        dispatch(sendGroupMessage(_message.groupId, _message))
        .then(action => {
            if (action.payload.data !== 'push') {
                dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Send Group Message', timeout: 4000 }))
            }
            if (clearOnSend) resetEditor()
            else setIsSaving(false)
        })
    }

    return (
        <div className={s.container}>

            <MessageTypeSelector selectedMessageType={messageType} 
                onlineUserCount={onlineUserCount}
                onSelect={_value => setMessageType(_value)} />

            {messageType === 'email' || messageType === 'push' ?
            <FormGroup className={s.formGroup}>
                <FormLabel>{messageType === 'email' ? 'Subject' : 'Headline'}</FormLabel>
                <FormControl placeholder={messageType === 'email' ? 'Subject' : 'Headline'} value={subject} name='subject' onChange={e => handleChange(e)} />
            </FormGroup>
            : null}

            {messageType === 'push' ?
            <PushTypeSelector selectedPushType={pushType} onSelect={_value => handlePushTypeChange(_value)} />
            : null}

            <FormGroup className={s.formGroup}>
                <FormLabel className={s.clearLabel}>
                    <div className={s.clearLabelText}>Message</div>
                    <div className={s.clearLabelControl}>
                        <FormCheck type='switch' label={<span>Clear on send</span>} checked={clearOnSend} onChange={e => setClearOnSend(e.target.checked)} tabIndex={-1} />
                    </div>
                </FormLabel>
                {messageType === 'sms' || messageType === 'push' || messageType === 'mobile' ?
                <div className={s.smsContainer} key={'textMessage'}>
                    <TextareaAutosize rows={2} className={s.textbox + ' form-control'} value={textMessage} name='textMessage' onChange={e => handleChange(e)} />
                    {messageType !== 'mobile' ? <div className={s.smsWarning + ' ' + s[smsWarning]}>{smsCharsRemaining}</div> : null}
                </div>
                :
                <div className={s.editorContainer} key={'emailMessage'}>
                    <div className={s.editorTools}>
                    <ButtonGroup tabIndex={-1}>
                        <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('BOLD')}><FontAwesomeIcon name='bold' /></Button>
                        <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('UNDERLINE')}><FontAwesomeIcon name='underline' /></Button>
                        <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('ITALIC')}><FontAwesomeIcon name='italic' /></Button>
                    </ButtonGroup>
                    </div>
                    <Editor className={s.editor} editorState={emailMessage} 
                        placeholder='[Email body...]' autoFocus 
                        blockStyleFn={contentBlock => s.block}
                        onChange={e => handleEditorChange(e)} />
                </div>}
            </FormGroup>
            {messageType === 'email' || messageType === 'sms' ?
            <FormGroup className={s.formGroup}>
                <FormLabel>Send to Roles</FormLabel>
                <div>
                    <ListGroup className={s.roleList}>
                    {roles && roles.map(r =>
                        <ListGroup.Item key={`role-message-send-to-${r._id}`} className={s.roleItem} 
                            onClick={() => toggleRole(r._id)}
                            variant={sendTo.indexOf(r._id) > -1 ? 'success' : undefined}>
                            <FontAwesomeIcon name={sendTo.indexOf(r._id) > -1 ? 'check-circle' : 'circle'} />
                            {' '}
                            <span className={s.desc}>{r.adminDescription} ({pluralize('player', r.count, true)})</span>
                        </ListGroup.Item>
                    )}
                    </ListGroup>
                </div>
            </FormGroup>
            : null}
            <FormGroup className={s.formGroup + ' ' + s.buttonsContainer}>
                <Button className={s.sendButton} variant='primary' onClick={() => send()} disabled={!isValidToSend() || isSaving}><FontAwesomeIcon name={isSaving ? 'sync-alt' : messageType === 'email' ? 'envelope' : 'mobile'} spin={isSaving} /> {!isSaving ? `send` : `sending...`}</Button>
            </FormGroup>
        </div>
    )
}

export default GroupMessaging