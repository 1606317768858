import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Button, InputGroup, FormControl, 
    FormGroup, FormLabel, Dropdown, DropdownButton } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminPricingPlans.scss'
import { getAllPricingPlans, savePricingPlanNote, 
    deletePricingPlanNote, setPricingPlanState, sortPricingPlanNotes,
    savePricingPlan } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import Modal from '@severed-links/common.modal'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { arrayMoveImmutable } from 'array-move'
import FormCheck from 'react-bootstrap/FormCheck'
import { variants } from '@severed-links/common.severedlinks-constants'
import GlobalAdminPricingPlanItem from './GlobalAdminPricingPlanItem'
import GlobalAdminPricingPlanFeatureItem from './GlobalAdminPricingPlanFeatureItem'
import GlobalAdminPricingPlanNoteItem from './GlobalAdminPricingPlanNoteItem'

const GlobalAdminPricingPlans = ({}) => {

    const dispatch = useDispatch()
    const isFetching = useSelector(state => state.globalAdmin.pricingPlans.isFetching || false)
    const pricing = useSelector(state => state.globalAdmin.pricingPlans.pricing || [])
    const notes = useSelector(state => state.globalAdmin.pricingPlans.notes || [])
    const [editPlan, setEditPlan] = useState({})
    const [showEditPlan, setShowEditPlan] = useState(false)
    const [editNote, setEditNote] = useState({})
    const [showEditNote, setShowEditNote] = useState(false)
    const [editFeature, setEditFeature] = useState({})
    const [editFeatureIndex, setEditFeatureIndex] = useState(-1)
    const [showEditFeature, setShowEditFeature] = useState(false)
    const [showSwitches, setShowSwitches] = useState(false)
    const _hasNotes = notes && notes.length > 0
    const _hasPricing = pricing && pricing.length > 0
    const _copyPlans = (pricing || []).filter(i => i._id !== (editPlan || {})._id)

    useEffect(() => {
        dispatch(getAllPricingPlans())
    }, [])

    const addNewNote = () => {
        const _newSortOrder = (Math.max(notes.map(i => i.sortOrder || 1)) || 0) + 1
        setShowEditNote(true)
        setEditNote({ isNew: true, sortOrder: _newSortOrder, icon: 'exclamation-circle', note: '', _id: null })
    }

    const closeEditNote = () => setShowEditNote(false)

    const addNewPlan = () => {
        setShowEditPlan(true)
        setEditPlan({ isNew: true, icon: '', code: '', title: '', isActive: false, variant: '', price: 0.00, entryFee: 10.00, features: [], _id: null })
        setTimeout(() => setShowSwitches(true), 100)
    }

    const closeEditPlan = () => {
        setShowEditPlan(false)
        setTimeout(() => setShowSwitches(false), 100)
    }

    const saveNote = () => {
        dispatch(savePricingPlanNote(editNote))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (action.payload.messageType === 'success') {
                setShowEditNote(false)
                dispatch(getAllPricingPlans())
            }
        })
    }

    const deleteNote = noteId => {
        dispatch(deletePricingPlanNote(noteId))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (action.payload.messageType === 'success') {
                dispatch(getAllPricingPlans())
            }
        })
    }

    const onMoveNote = (dragIndex, hoverIndex) => {
        var _notes = arrayMoveImmutable(notes || [], dragIndex, hoverIndex)
        dispatch(setPricingPlanState({ notes: _notes }))
        dispatch(sortPricingPlanNotes(_notes.map((i, _index) => ({ _id: i._id, sortOrder: _index + 1 }))))
    }

    const addNewFeature = () => {
        setEditFeatureIndex(-1)
        setEditFeature({ isNew: true, label: '', description: '' })
        setShowEditFeature(true)
    }

    const closeEditFeature = () => setShowEditFeature(false)

    const addFeature = () => {
        var features = [...(editPlan.features || [])]
        if (editFeature.isNew) {
            features.push(editFeature)
        } else {
            features[editFeatureIndex] = { ...editFeature }
        }
        setEditFeature({})
        setEditFeatureIndex(-1)
        setShowEditFeature(false)
        setEditPlan({ ...editPlan, features })
    }

    const onEditFeature = (_feature = {}, _editFeatureIndex = -1) => {
        setShowEditFeature(true)
        setEditFeatureIndex(_editFeatureIndex)
        setEditFeature({ ..._feature, isNew: false })
    }

    const deleteFeature = _index => {
        var features = editPlan.features || []
        features.splice(_index, 1)
        setEditPlan({ ...editPlan, features })
    }

    const onMoveFeature = (dragIndex, hoverIndex) => {
        var features = arrayMoveImmutable(editPlan.features || [], dragIndex, hoverIndex)
        setEditPlan({ ...editPlan, features })
    }

    const copyFeatures = planId => {
        var features = (pricing.find(i => i._id === planId) || {}).features || []
        setEditPlan({ ...editPlan, features })
    }

    const savePlan = () => {
        dispatch(savePricingPlan(editPlan))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (action.payload.messageType === 'success') {
                setShowEditPlan(false)
                dispatch(getAllPricingPlans())
            }
        })
    }

    const onEditPlan = _planId => {
        setShowEditPlan(true)
        setEditPlan({ ...(pricing.find(i => i._id === _planId) || {}), isNew: false })
        setTimeout(() => setShowSwitches(true), 100)
    }

    return (
        <div className={s.container}>
            <h3><FontAwesomeIcon name='money-bill-wave' /> Pricing Plans</h3>
            <div className={s.heading}>
                <h4>Plans</h4>
                <Button onClick={() => addNewPlan()}><FontAwesomeIcon name='plus' /></Button>
            </div>
            <ListGroup className={s.pricingList}>
            {pricing && pricing.map((i, _index) =>
            <GlobalAdminPricingPlanItem key={`pricing-plan-${i._id}`} {...i}
                onDelete={() => deletePlan(i._id)}
                onEdit={() => onEditPlan(i._id)} />
            )}
            {!_hasPricing ? <ListGroup.Item>No plans located.</ListGroup.Item> : null}
            </ListGroup>
            <div className={s.heading}>
                <h4>Notes</h4>
                <Button onClick={() => addNewNote()}><FontAwesomeIcon name='plus' /></Button>
            </div>
            <ListGroup className={s.notesList}>
            {notes && notes.map((i, _index) =>
            <GlobalAdminPricingPlanNoteItem key={`pricing-plan-note-${i._id}`} {...i}
                index={_index} 
                onMove={onMoveNote}
                onDelete={() => deleteNote(i._id)}
                onEdit={() => {
                    setEditNote({ ...i, isNew: false })
                    setShowEditNote(true)
                }} />
            )}
            {!_hasNotes ? <ListGroup.Item>No plan notes located.</ListGroup.Item> : null}
            </ListGroup>

            <Modal show={showEditPlan} 
                heading={`${editPlan && editPlan.isNew ? 'Add New' : 'Edit'} Plan`}
                actionButtonIcon={editPlan && editPlan.isNew ? 'plus' : 'check'}
                actionButtonText={editPlan && editPlan.isNew ? 'add new plan' : 'save plan'}
                actionButtonDisabled={!editPlan.code || !editPlan.title || !editPlan.icon}
                actionButtonOnClick={() => savePlan()}
                onClose={() => closeEditPlan()}>
                <div className={s.editPlanContainer}>
                    <div className={s.planFormGroupContainer}>
                        <FormGroup className={s.formGroup}>
                            <FormLabel>Code</FormLabel>
                            <FormControl value={editPlan.code || ''}
                                onChange={e => setEditPlan({ ...editPlan, code: e.target.value })} />
                        </FormGroup>
                        <FormGroup className={s.formGroup}>
                            <FormLabel style={{ display: 'block' }}>Active?</FormLabel>
                            {showSwitches ?
                            <FormCheck type='switch' checked={editPlan.isActive || false}
                                onChange={e => setEditPlan({ ...editPlan, isActive: e.target.checked })} />
                            : null}
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <FormLabel>Title</FormLabel>
                        <FormControl value={editPlan.title || ''}
                            onChange={e => setEditPlan({ ...editPlan, title: e.target.value })} />
                    </FormGroup>
                    <div className={s.planFormGroupContainer}>
                        <FormGroup className={s.formGroup}>
                            <FormLabel>Icon</FormLabel>
                            <InputGroup>
                                <FormControl value={editPlan.icon || ''}
                                    onChange={e => setEditPlan({ ...editPlan, icon: e.target.value })} />
                                <InputGroup.Text>
                                    {editPlan.icon ? <FontAwesomeIcon name={editPlan.icon} /> : null}
                                </InputGroup.Text>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className={s.formGroup}>
                            <FormLabel>Variant</FormLabel>
                            <DropdownButton title={editPlan.variant || '[None]'} variant={editPlan.variant || null}
                                align='end' className={s.variantButton + ' ' + s[editPlan.variant || 'none']}
                                onSelect={e => setEditPlan({ ...editPlan, variant: e })}>
                            {variants && variants.map(i =>
                            <Dropdown.Item className={s.variantDropdown + ' ' + s[i]}
                                key={`variant-dropdown-${i}`} 
                                eventKey={i}>
                                {i}
                            </Dropdown.Item>
                            )}
                            </DropdownButton>
                        </FormGroup>
                    </div>
                    <div className={s.planFormGroupContainer}>
                        <FormGroup className={s.formGroup}>
                            <FormLabel>Price</FormLabel>
                            <InputGroup>
                                <InputGroup.Text>$</InputGroup.Text>
                                <FormControl value={`${editPlan.price || 0.00}`}
                                    className={s.right} type='number'
                                    onChange={e => setEditPlan({ ...editPlan, price: parseFloat(e.target.value) })} />
                                <InputGroup.Text>.00</InputGroup.Text>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className={s.formGroup}>
                            <FormLabel>Entry Fee</FormLabel>
                            <InputGroup>
                                <InputGroup.Text>$</InputGroup.Text>
                                <FormControl value={`${editPlan.entryFee || 0.00}`}
                                    className={s.right} type='number'
                                    onChange={e => setEditPlan({ ...editPlan, entryFee: parseFloat(e.target.value) })} />
                                <InputGroup.Text>.00</InputGroup.Text>
                            </InputGroup>
                        </FormGroup>
                    </div>
                    <div className={s.planFormGroupContainer}>
                        <h4 className={s.title}>Features</h4>
                        {_copyPlans.length ? 
                        <DropdownButton title={<FontAwesomeIcon name='copy' />} variant='light'
                            align='end' 
                            onSelect={e => copyFeatures(e)}>
                        {_copyPlans.map(i =>
                            <Dropdown.Item key={`copy-plan-${i._id}`} eventKey={i._id}>{i.title} ({i.code})</Dropdown.Item>
                        )}
                        </DropdownButton>
                        : null}
                        <Button className={s.titleButton} onClick={() => addNewFeature()}><FontAwesomeIcon name='plus' /></Button>
                    </div>
                    <ListGroup>
                    {editPlan.features && editPlan.features.map((i, _featureIndex) =>
                    <GlobalAdminPricingPlanFeatureItem index={_featureIndex} {...i}
                        onDelete={() => deleteFeature(_featureIndex)}
                        onMove={onMoveFeature}
                        onEdit={() => onEditFeature(i, _featureIndex)}
                        key={`feature=item-${_featureIndex}`} />
                    )}
                    {!editPlan.features || !editPlan.features.length ?
                    <ListGroup.Item>No features have been set up for this plan.</ListGroup.Item>
                    : null}
                    </ListGroup>
                </div>
            </Modal>

            <Modal show={showEditNote} 
                heading={`${editNote && editNote.isNew ? 'Add New' : 'Edit'} Note`}
                actionButtonIcon={editNote && editNote.isNew ? 'plus' : 'check'}
                actionButtonText={editNote && editNote.isNew ? 'add new note' : 'save note'}
                actionButtonDisabled={!editNote.icon || !editNote.note}
                actionButtonOnClick={() => saveNote()}
                onClose={() => closeEditNote()}>
                <div>
                    <FormGroup>
                        <FormLabel>Icon</FormLabel>
                        <FormControl value={editNote.icon || ''}
                            onChange={e => setEditNote({ ...editNote, icon: e.target.value })} />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Note</FormLabel>
                        <FormControl value={editNote.note || ''} autoFocus
                            as='textarea' rows={3}
                            onChange={e => setEditNote({ ...editNote, note: e.target.value })} />
                    </FormGroup>
                </div>
            </Modal>

            <Modal show={showEditFeature} 
                heading={`${editFeature && editFeature.isNew ? 'Add New' : 'Edit'} Feature`}
                actionButtonIcon={editFeature && editFeature.isNew ? 'plus' : 'check'}
                actionButtonText={editFeature && editFeature.isNew ? 'add new feature' : 'save feature'}
                actionButtonDisabled={!editFeature.description}
                actionButtonOnClick={() => addFeature()}
                onClose={() => closeEditFeature()}>
                <div>
                    <FormGroup>
                        <FormLabel>Label</FormLabel>
                        <FormControl value={editFeature.label || ''} autoFocus
                            onChange={e => setEditFeature({ ...editFeature, label: e.target.value })} />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Description</FormLabel>
                        <FormControl value={editFeature.description || ''} 
                            as='textarea' rows={3}
                            onChange={e => setEditFeature({ ...editFeature, description: e.target.value })} />
                    </FormGroup>
                </div>
            </Modal>

        </div>
    )
}

export default GlobalAdminPricingPlans
