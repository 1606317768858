// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login__container___n_5Jr .Login__formContainer___NnDf1{position:relative;padding:2rem;border-radius:8px}.Login__container___n_5Jr .Login__formContainer___NnDf1 .Login__header___dMV_p{margin-bottom:2rem}.Login__container___n_5Jr .Login__formContainer___NnDf1 .Login__formGroup___q0ogN{margin-bottom:1rem}.Login__container___n_5Jr .Login__formContainer___NnDf1 .Login__formGroup___q0ogN.Login__submitButtonContainer___XpwJy{display:flex;flex-flow:column}@media screen and (max-width: 767px){.Login__container___n_5Jr .Login__formContainer___NnDf1{padding:1rem}.Login__container___n_5Jr .Login__formContainer___NnDf1 .Login__header___dMV_p{margin-bottom:1rem}}`, "",{"version":3,"sources":["webpack://./src/routes/Account/Login.scss"],"names":[],"mappings":"AAKI,wDACI,iBAAA,CACA,YAAA,CACA,iBAAA,CAEA,+EACI,kBAAA,CAGJ,kFACI,kBAAA,CAEA,uHACI,YAAA,CACA,gBAAA,CAIR,qCAlBJ,wDAmBQ,YAAA,CAEA,+EACI,kBAAA,CAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n@use '../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n\n    .formContainer {\n        position: relative;\n        padding: 2rem;\n        border-radius: 8px;\n\n        .header {\n            margin-bottom: 2rem;\n        }\n\n        .formGroup {\n            margin-bottom: 1rem;\n\n            &.submitButtonContainer {\n                display: flex;\n                flex-flow: column;\n            }\n        }\n\n        @media screen and (max-width: breakpoints.$screen-sm-max) {\n            padding: 1rem;\n\n            .header {\n                margin-bottom: 1rem;\n            }\n    \n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `Login__container___n_5Jr`;
export var formContainer = `Login__formContainer___NnDf1`;
export var header = `Login__header___dMV_p`;
export var formGroup = `Login__formGroup___q0ogN`;
export var submitButtonContainer = `Login__submitButtonContainer___XpwJy`;
export default ___CSS_LOADER_EXPORT___;
