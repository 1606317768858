import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router'
import { Row, Col, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TripListItem from './TripListItem'
import TripAddNew from './Admin/TripAddNew'
import DeletedTrips from './Admin/DeletedTrips'
import * as s from './Trips.scss'

const Trips = () => {

    const { group, isAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const trips = group.trips || []

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h3 className={s.title}>Trips</h3>
                <div className={s.controls}>
                <TripAddNew showExpanded={!trips || trips.length === 0} group={group} isGlobalAdmin={isGlobalAdmin} isGlobalAdminPath={isGlobalAdminPath} />
                </div>
            </div>
            <Row>
                <Col xs={12}>
                    <ListGroup>
                        {trips && trips.map(t =>
                        <TripListItem trip={t} group={group} isGlobalAdmin={isGlobalAdmin && isGlobalAdminPath} key={`trips-list-${t._id}`} />
                        )}
                        {!trips || trips.length === 0 ? <ListGroup.Item>No trips have been created for this group.</ListGroup.Item> : null}
                    </ListGroup>
                </Col>
            </Row>
            {((group || {}).role || {}).isAdmin || isGlobalAdmin ?
            <Row style={{ marginBottom: '40px' }}>
                <Col xs={12}>
                    <DeletedTrips group={group} isGlobalAdmin={isGlobalAdmin} />
                </Col>
            </Row>
            : null}
        </div>
    )
}

export default Trips