import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Button, Image, Carousel } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripSlideShow.scss'
import { commonImageUrl, tripPhotoUrl } from '@severed-links/common.severedlinks-constants'
import { setMedalistPageState, setTripValue } from '@severed-links/common.severedlinks-reducers/groups'
import RyderCupRoundScoreboard from '../RyderCupRoundScoreboard'
import TripMedalistScoreboard from '../TripMedalistScoreboard'
import TripMatchPanel from '../TripMatchPanel'
import PlayerListItem from '../../Player/PlayerListItem'
import moment from 'moment-timezone'
import { update } from 'lodash'

const INITIAL_SLIDE_SHOW_STATE = { currentSkin: -1, currentProxy: -1, currentMatch: -1, activePhotoIndex: 0, updatedAt: moment().toISOString() }

const TripSlideShow = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const medalistState = useSelector(state => state.groups.medalistState)
    const { group, groupId, trip, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const tripAdminSlideShowState = trip.tripAdminSlideShowState || {}
    const timerInterval = 15
    const { currentSkin, currentProxy, currentMatch, activePhotoIndex } = tripAdminSlideShowState || {}

    const matches = ((trip.scoreboard || {}).matches || []).filter(m => m.usaPlayerList && m.usaPlayerList.length > 0 && m.eurPlayerList && m.eurPlayerList.length > 0)
    const skins = (trip.scoreboard || {}).skins || []
    const proxies = ((trip.scoreboard || {}).proxies || []).filter(p => p.playerID)
    const medalist = (trip.scoreboard || {}).medalist || []

    const _currentSkin = currentSkin > -1 ? ((trip.scoreboard || {}).skins || [])[currentSkin] : null
    const _currentProxy = currentProxy > -1 ? ((trip.scoreboard || {}).proxies || []).filter(p => p.playerID)[currentProxy] : null
    const _currentMatch = currentMatch > -1 ? ((trip.scoreboard || {}).matches || [])[currentMatch] : null

    const adminSlideShowPhotos = trip.adminSlideShowPhotos || []
    const _nextPhoto = activePhotoIndex + 1 < adminSlideShowPhotos.length - 1 ? adminSlideShowPhotos[activePhotoIndex + 1] : adminSlideShowPhotos[0]
    const _nextPhotoImg = _nextPhoto && _nextPhoto.imageUrl ? <img src={tripPhotoUrl(_nextPhoto.tripID, _nextPhoto.imageUrl, false)} /> : null

    useEffect(() => {
        updateSlideShowState({ ...INITIAL_SLIDE_SHOW_STATE })
        let _timerId = setInterval(() => tick(), 1000 * timerInterval)
        return () => clearInterval(_timerId)
    }, [])
    useEffect(() => nextMedalist(), [medalist.length])
    useEffect(() => nextSkinsAndProxies(), [skins.length, proxies.length])
    useEffect(() => nextMatch(), [matches.length])
    useEffect(() => nextPhoto(), [adminSlideShowPhotos.length])
    useEffect(() => {
        nextPhoto()
        nextMatch()
        nextMedalist()
        nextSkinsAndProxies()
    }, [tripAdminSlideShowState.updatedAt])

    const updateSlideShowState = (data = {}) => dispatch(setTripValue(group._id, trip._id, 'tripAdminSlideShowState', data))

    const tick = () => updateSlideShowState({ updatedAt: moment().toISOString() })

    const nextMedalist = () => {
        const numPages = Math.ceil(medalist.length / (medalistState.pageLength || medalistState.pageLength))
        const newPage = medalistState.pageNumber < numPages ? medalistState.pageNumber + 1 : 1
        dispatch(setMedalistPageState(newPage, medalistState.pageLength, numPages))
    }

    const nextSkinsAndProxies = () => {
        var _currentSkinIndex = !isNaN(currentSkin) ? currentSkin : -1
        if (_currentSkinIndex === -1 && skins.length > 0) _currentSkinIndex = 0
        else if (_currentSkinIndex > -1 && skins.length > 0) _currentSkinIndex++
        if (_currentSkinIndex > skins.length - 1) _currentSkinIndex = 0

        var _currentProxyIndex = !isNaN(currentProxy) ? currentProxy : -1
        if (_currentProxyIndex === -1 && proxies.length > 0) _currentProxyIndex = 0
        else if (_currentProxyIndex > -1 && proxies.length > 0) _currentProxyIndex++
        if (_currentProxyIndex > proxies.length - 1) _currentProxyIndex = 0
        updateSlideShowState({ currentSkin: _currentSkinIndex, currentProxy: _currentProxyIndex })
    }

    const nextMatch = () => {
        var _currentMatch = !isNaN(currentMatch) ? currentMatch : -1
        if (_currentMatch === -1 && matches.length > 0) _currentMatch = 0
        else if (_currentMatch > -1 && matches.length > 0) _currentMatch++
        if (currentMatch > matches.length - 1) _currentMatch = 0
        updateSlideShowState({ currentMatch: _currentMatch })
    }

    const nextPhoto = () => {
        var _activePhotoIndex = !isNaN(activePhotoIndex) ? activePhotoIndex : -1
        if (_activePhotoIndex + 1 > adminSlideShowPhotos.length - 1) {
            updateSlideShowState({ activePhotoIndex: 0 })
        } else {
            updateSlideShowState({ activePhotoIndex: _activePhotoIndex + 1 })
        }
    }

    const onClose = () => navigate(location.pathname.replace('/slide-show', ''))

    if (!tripAdminSlideShowState) return null
    return (
        <div className={s.slideShow}>
            <div className={s.main}>
                <Carousel activeIndex={activePhotoIndex} className={s.carousel} controls={false} indicators={false} wrap>
                {adminSlideShowPhotos && adminSlideShowPhotos.map(i =>
                    <Carousel.Item key={`slide-show-photo-${i._id}`} 
                        style={{ backgroundImage: `url(${tripPhotoUrl(i.tripID, i.imageUrl, false)})` }}
                        className={s.carouselItem}>
                        <Carousel.Caption className={s.captionContainer}>
                            <div className={s.captionBackground} />
                            <div className={s.captionText}>
	                           <h1>{i.caption}</h1>
 	                           <h3>{i.tripName} · {i.locationName}</h3>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                )}
                </Carousel>
            </div>
            <div style={{ width: '0px', height: '0px' }}>{_nextPhotoImg}</div>
            <div className={s.side}>
                <div className={s.logo}>
                    <Image src={commonImageUrl('severed-links-logo-h50')} />
                    <h4 className={s.tripName}>{trip.tripName}</h4>
                    <h5 className={s.location}>{(trip.location || {}).locName}</h5>
                </div>
                <div className={s.ryderCup}>
                    <RyderCupRoundScoreboard {...(trip || {})} 
                        roundScores={(trip.scoreboard || {}).ryderCup} 
                        ryderCupRounds={(trip.scoreboard || {}).ryderCupRounds || []}
                        numRounds={(trip.rounds || []).length} 
                        showMini center size='3x'
                        showRounds={false} showRoundHeader={false} />
                </div>
                <div className={s.medalist}>
                    <TripMedalistScoreboard medalist={medalist} group={group} trip={trip}
                        showRounds={false} paginate showHcp={false} showPager={false} inverse isMini />
                </div>
                {_currentMatch ?
                <div className={s.playerContainer}>
                    <TripMatchPanel matchItem={{ ..._currentMatch, status: (_currentMatch.status || '').replace(/through\s+\d+\s+holes/g, '') }}
                        inverse isMini showHcp={false} trip={trip} />
                </div>
                : null}
                {_currentSkin ?
                    <div className={s.playerContainer}>
                        <h4 className={s.skinProxyHeader}>{_currentSkin.roundName} Skin</h4>
                        <PlayerListItem player={_currentSkin} isSkin clickDisabled 
                            showRole={false} showHcp={false} inverse isMini />
                    </div>
                : null}
                {_currentProxy ?
                <div className={s.playerContainer}>
                    <h4 className={s.skinProxyHeader}>{_currentProxy.roundName} Proxy</h4>
                    <PlayerListItem player={_currentProxy} isProxy clickDisabled 
                        showRole={false} showHcp={false} inverse isMini />
                </div>
                : null}
                <div className={s.close}><Button size='sm' onClick={() => onClose()}><FontAwesomeIcon name='times' /></Button></div>
            </div>
        </div>
    )
}

export default TripSlideShow