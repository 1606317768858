import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Row, Col, FormGroup, FormControl, FormLabel, InputGroup, Button, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getLocation, saveLocation } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import { getNearbyCourses } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import Confirm from '@severed-links/common.confirm'
import * as s from './GlobalAdminEditLocation.scss'
import numeral from 'numeral'

const GlobalAdminEditLocation = ({ }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const [_id, setId] = useState(null)
    const [locName, setName] = useState('')
    const [locAddress, setAddress] = useState('')
    const [locCity, setCity] = useState('')
    const [locState, setState] = useState('')
    const [stateAbbreviation, setStateAbbreviation] = useState('')
    const [locZip, setZip] = useState('')
    const [locPhone, setPhone] = useState('')
    const [geocodedAddress, setGeocodedAddress] = useState('')
    const [timezone, setTimezone] = useState('')
    const [latitude, setLatitude] = useState(0.0)
    const [longitude, setLongitude] = useState(0.0)
    const [nearbyCourses, setNearbyCourses] = useState([])
    const [nearbyCourseCount, setNearbyCourseCount] = useState(10)
    const [nearbyCourseRange, setNearbyCourseRange] = useState(10)
    const [lastUpdate, setLastUpdate] = useState(null)
    const locationId = params.locationId

    useEffect(() => {
        if (locationId && locationId !== 'new') {
            dispatch(getLocation(locationId))
            .then(action => {
                setGeocodedAddress(action.payload.geocodedAddress)
                setId(action.payload._id)
                setName(action.payload.locName)
                setAddress(action.payload.locAddress)
                setCity(action.payload.locCity)
                setState(action.payload.locState)
                setStateAbbreviation(action.payload.stateAbbreviation)
                setZip(action.payload.locZip)
                setPhone(action.payload.locPhone)
                setLastUpdate(action.payload.lastUpdate)
                setLatitude(action.payload.latitude)
                setLongitude(action.payload.longitude)
            })
        } else {
            setGeocodedAddress('')
            setId(null)
            setName('')
            setAddress('')
            setCity('')
            setState('')
            setStateAbbreviation('')
            setZip('')
            setPhone('')
            setLastUpdate(null)
            setLatitude(0)
            setLongitude(0)
    }
    }, [locationId])

    useEffect(() => {
        loadNearbyCourses()
    }, [nearbyCourseCount, nearbyCourseRange, locationId, latitude, longitude])

    const loadNearbyCourses = () => {
        if (_id && latitude && longitude) {
            dispatch(getNearbyCourses(0, 0, _id, nearbyCourseCount, nearbyCourseRange))
            .then(action => setNearbyCourses(action.payload))
        }   
    }

    const save = () => {
       if (locName) {
            dispatch(saveLocation({ _id, locName, locAddress, locCity, locState, locZip, locPhone }))
            .then(action => {
                dispatch(createNotification({ headline: 'Save Location', message: action.payload.message, type: action.payload.messageType, timeout: 3000 }))
                if (action.payload.messageType === 'success' && !_id) {
                    navigate(`/global-admin/lodging/edit/${action.payload._id}`)
                }
            })
        } else {
            dispatch(createNotification({ headline: 'Save Location', message: 'Location name cannot be blank.', type: 'error', timeout: 3000 }))
        }
    }

    return (
        <div className={s.container}>
            <h3 className={s.heading}><FontAwesomeIcon name='map-marker' className={s.icon} /> {_id ? 'Edit' : 'Add'} Lodging: {locName}</h3>
            <FormGroup className={s.formGroup}>
                <FormLabel>Name</FormLabel>
                <FormControl value={locName || ''} onChange={e => setName(e.target.value)} />
            </FormGroup>
            <FormGroup className={s.formGroup}>
                <FormLabel>Address</FormLabel>
                <FormControl value={locAddress || ''} onChange={e => setAddress(e.target.value)} />
            </FormGroup>
            <div className={s.cityStateRow}>
                <FormGroup className={s.x3 + ' ' + s.formGroup}>
                    <FormLabel>City</FormLabel>
                    <FormControl value={locCity || ''} onChange={e => setCity(e.target.value)} />
                </FormGroup>
                <FormGroup className={s.x1 + ' ' + s.formGroup}>
                    <FormLabel>State</FormLabel>
                    <FormControl value={locState || ''} onChange={e => setState(e.target.value)} />
                </FormGroup>
                <FormGroup className={s.x1 + ' ' + s.formGroup}>
                    <FormLabel>Zip</FormLabel>
                    <FormControl value={locZip || ''} onChange={e => setZip(e.target.value)} />
                </FormGroup>
            </div>
            <FormGroup className={s.formGroup}>
                <FormLabel>Phone (10 digits only)</FormLabel>
                <FormControl value={locPhone || ''} onChange={e => setPhone(e.target.value)} />
            </FormGroup>
            <FormGroup className={s.buttonFormGroup + ' ' + s.formGroup}>
                <Button variant='light' onClick={() => navigate(`/global-admin/lodging/filter/${(locName || ' ').substring(0,1).toUpperCase()}`)}><FontAwesomeIcon name='chevron-left' /> back to list</Button>
                <Button variant='primary' onClick={() => save()}><FontAwesomeIcon name='check' /> save</Button>
            </FormGroup>
            {_id ?
            <div>
                <div className={s.heading}>
                    <h4 className={s.title}>
                        Nearby Golf Courses
                    </h4>                        
                    <div className={s.controls}>
                        <FormControl className={[s.select,s.miles].join(' ')} 
                            as='select' 
                            value={nearbyCourseRange} 
                            onChange={e => setNearbyCourseRange(parseInt(e.target.value))}>
                            <option value={5}>within 5 miles</option>
                            <option value={10}>within 10 miles</option>
                            <option value={25}>within 25 miles</option>
                            <option value={50}>within 50 miles</option>
                            <option value={100}>within 100 miles</option>
                        </FormControl>
                        <FormControl className={[s.select,s.count].join(' ')}
                            as='select' 
                            value={nearbyCourseCount} 
                            onChange={e => setNearbyCourseCount(parseInt(e.target.value))}>
                            <option value={5}>show 5</option>
                            <option value={10}>show 10</option>
                            <option value={25}>show 25</option>
                        </FormControl>
                    </div>
                </div>
                <ListGroup>
                {nearbyCourses && nearbyCourses.map(i =>
                    <ListGroup.Item key={`global-admin-${i._id}`} 
                        onClick={() => navigate(`/global-admin/courses/edit/${i._id}`)}>
                        <h4 className={s.nearbyCourse}>
                            <div className={s.nearbyCourseName}>{i.courseName}</div>
                            <div className={s.nearbyCourseDistance}><small>{numeral(i.distance).format('0.0')} mi</small></div>
                        </h4>
                        <p className={s.location}>{i.city} {i.state}</p>
                    </ListGroup.Item>
                )}
                </ListGroup>
                {!nearbyCourses || nearbyCourses.length === 0 ? <p>No nearby courses located.</p> : null}
            </div>
            : null}
        </div>
    )
}

export default GlobalAdminEditLocation