import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormGroup, FormControl, FormLabel } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import ReactCodeInput from 'react-code-input'

const TestCodeEntry = () => {

    const [code, setCode] = useState('')

    return (
        <div>
            <FormGroup>
                <FormLabel style={{ display: 'block' }}>Testing code entry...</FormLabel>
                <ReactCodeInput type='text' 
                    pattern='^[0-9]*$' fields={5} 
                    autoFocus value={code}
                    onChange={_code => setCode(_code)} />
            </FormGroup>
        </div>
    )
}

export default TestCodeEntry