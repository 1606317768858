import React, { useEffect } from 'react'
import { IoProvider } from 'socket.io-react-hook'

import 'normalize.css/normalize.css'
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min.css'
import * as s from './CoreLayout.scss'
import '../../styles/core.scss'
import 'react-datetime/css/react-datetime.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'

import NotificationsContainer from '../../routes/Notifications/NotificationsContainer'

import ContentPanel from '../ContentPanel/ContentPanel'
import MenuPanel from '../MenuPanel/MenuPanel'
import SocketClient from '../../routes/Socket.io/SocketClient'
import ScrollToTop from '@severed-links/common.scroll-to-top'
import TokenWatcher from '../../routes/Account/TokenWatcher'

import Container from 'react-bootstrap/Container'


const CoreLayout = ({ menu, content, children, ...props }) => {

  return (
    <IoProvider>
      <TokenWatcher />
      <SocketClient />
      <Container className={s.appLayout} fluid>
        <ScrollToTop />
        <MenuPanel />
        <NotificationsContainer />
        <ContentPanel />
      </Container>
    </IoProvider>
  )
}

export default CoreLayout