import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useNavigate } from 'react-router'
import { Row, Col, FormGroup, FormControl, FormLabel, Button, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Datetime from 'react-datetime'
import { FRIENDLY_SHORT_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import moment from 'moment-timezone'
import { range, isEqual, keysIn } from 'lodash'
import validator from 'validator'
import SettingControl from './SettingControl'
import DeleteTripButton from './DeleteTripButton'
import TripBasicsSendDetails from './TripBasicsSendDetails'
import TripBasicsConfirmSendRsvpEmail from './TripBasicsConfirmSendRsvpEmail'
import TeamThemes from './TeamThemes'
import { sendRsvpMessage } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './TripBasicsEntry.scss'

const TripBasicsEntry = ({ group = {}, trip = {}, onSave, onCancel, isGlobalAdmin = false }) => {

    const [groupId, setGroupId] = useState(group._id)
    const [tripId, setTripId] = useState(trip._id)
    const [tripName, setTripName] = useState('')
    const [numberOfRounds, setNumberOfRounds] = useState(1)
    const [beginDate, setBeginDate] = useState(moment().format(FRIENDLY_SHORT_DATE_FORMAT))
    const [endDate, setEndDate] = useState(moment().format(FRIENDLY_SHORT_DATE_FORMAT))
    const [rsvpCapacityLimit, setRsvpCapacityLimit] = useState(0)
    const [showRsvpEmailModal, setShowRsvpEmailModal] = useState(false)
    const _tripSettings = trip.settings || {}
    const usaCount = (trip.usaPlayers || []).length
    const eurCount = (trip.eurPlayers || []).length
    const unassignedCount = (trip.unassignedPlayers || []).length
    const tripPlayerCapacityFloor = Math.ceil((usaCount + eurCount + unassignedCount) / 4.0) * 4.0
    const capacityRange = range(tripPlayerCapacityFloor, 104, 4)
    const timeZoneId = (trip?.timezone || {}).timeZoneId || 'America/New_York'

    useEffect(() => {
        setTripName(trip.tripName || '')
    }, [trip.tripName || ''])

    useEffect(() => {
        if (trip.beginDate && moment(trip.beginDate).isValid()) {
            setBeginDate(moment.tz(trip.beginDate, timeZoneId).format(FRIENDLY_SHORT_DATE_FORMAT))
        }
    }, [trip.beginDate])

    useEffect(() => {
        if (trip.endDate && moment(trip.endDate).isValid()) {
            setEndDate(moment.tz(trip.endDate, timeZoneId).format(FRIENDLY_SHORT_DATE_FORMAT))
        }
    }, [trip.endDate])

    useEffect(() => {
        setNumberOfRounds(trip.numberOfRounds || 1)
    }, [trip.numberOfRounds || 1])

    useEffect(() => {
        setRsvpCapacityLimit(trip.rsvpCapacityLimit || 0)
    }, [trip.rsvpCapacityLimit || 0])

    const isFormValid = () => !validator.isEmpty(tripName) && moment(beginDate, FRIENDLY_SHORT_DATE_FORMAT).isValid() &&
        moment(endDate, FRIENDLY_SHORT_DATE_FORMAT).isValid() && numberOfRounds > 0
        && !isTripNameUsed()
        && rsvpCapacityLimit > 0

    const isTripNameUsed = () => (group.trips || []).filter(ft => ft._id != trip._id).some(t => (t.tripName || '').toLowerCase() == (tripName || '').toLowerCase())

    const onRsvpChange = _show => setShowRsvpEmailModal(_show)

    const onRsvpModalClose = () => setShowRsvpEmailModal(false)

    const sendRsvpOpenEmail = () => {
        setShowRsvpEmailModal(false)
        dispatch(sendRsvpMessage(group._id, trip._id))
        .then(action => dispatch(createNotification({ ...action.payload })))
    }

    const onDateChange = (e, fieldName) => {
        const date = moment.tz(e, FRIENDLY_SHORT_DATE_FORMAT, timeZoneId)
        if (date.isValid()) {
            if (fieldName === 'beginDate') {
                setBeginDate(date.format(FRIENDLY_SHORT_DATE_FORMAT))
                if (date.isSameOrAfter(moment(endDate, FRIENDLY_SHORT_DATE_FORMAT))) {
                    setEndDate(date.format(FRIENDLY_SHORT_DATE_FORMAT))
                }
            } else if (fieldName === 'endDate') {
                setEndDate(date.format(FRIENDLY_SHORT_DATE_FORMAT))
            }
        }
    }

    const save = () => {
        if (onSave) {
            onSave({ groupId, tripId, tripName, numberOfRounds, beginDate, endDate, rsvpCapacityLimit })        
        }
    }

    if (!trip) return null

    const _switches = (trip._id ? [
        { variableType: 'bool', variable: 'isActive', title: 'Active?', value: _tripSettings.isActive || false, onChange: null },
        { variableType: 'bool', variable: 'canPlayersRsvp', title: 'RSVP Allowed?', value: _tripSettings.canPlayersRsvp || false, onChange: onRsvpChange },
        { variableType: 'bool', variable: 'autoShowScorecard', title: 'Auto show scorecard?', value: _tripSettings.autoShowScorecard || false, onChange: null },
    ] : []).filter(i => keysIn(_tripSettings).includes(i.variable))

    return (
        <div className={s.container}>
            <FormGroup className={s.formGroup + ' ' + s.fullWidth}>
                <FormLabel className={s.formLabel}>Trip name</FormLabel>
                <FormControl autoFocus value={tripName} 
                    required isValid={!validator.isEmpty(tripName) && !isTripNameUsed()}
                    isInvalid={validator.isEmpty(tripName) || isTripNameUsed()}
                    onChange={e => setTripName(e.target.value)} />
                <FormControl.Feedback />
            </FormGroup>
            <FormGroup className={s.formGroup}>
                <FormLabel className={s.formLabel + ' ' + s.fixedWidth}>Begin date</FormLabel>
                <Datetime value={beginDate} closeOnSelect
                    dateFormat={FRIENDLY_SHORT_DATE_FORMAT} 
                    timeFormat={false}
                    onChange={e => onDateChange(e, 'beginDate')} />
            </FormGroup>
            <FormGroup className={s.formGroup + ' ' + s.fixedWidth}>
                <FormLabel className={s.formLabel}>End date</FormLabel>
                <Datetime value={endDate} closeOnSelect
                    dateFormat={FRIENDLY_SHORT_DATE_FORMAT} 
                    timeFormat={false}
                    isValidDate={(currentDate, selectedDate) => currentDate.isSameOrAfter(moment.tz(beginDate, FRIENDLY_SHORT_DATE_FORMAT, timeZoneId))}
                    onChange={e => onDateChange(e, 'endDate')} />
            </FormGroup>
            <FormGroup className={s.formGroup + ' ' + s.fixedWidth}>
                <FormLabel className={s.formLabel}>Rounds</FormLabel>
                <FormControl value={numberOfRounds} as='select'
                    required isValid={numberOfRounds > 0}
                    isInvalid={!numberOfRounds}
                    onChange={e => setNumberOfRounds(parseInt(e.target.value))}>
                    {range(1, 11, 1).map(round => <option key={`round-select-${round}`} value={round}>{round}</option>)}
                </FormControl>
            </FormGroup>
            <FormGroup className={s.formGroup + ' ' + s.fixedWidth}>
                <FormLabel className={s.formLabel}>Trip Capacity</FormLabel>
                <FormControl value={rsvpCapacityLimit} as='select'
                    required isValid={rsvpCapacityLimit > 0}
                    isInvalid={!rsvpCapacityLimit}
                    onChange={e => setRsvpCapacityLimit(parseInt(e.target.value))}>
                    {capacityRange.map(num => <option key={`trip-capacity-select-${num}`} value={num}>{num} players</option>)}
                </FormControl>
            </FormGroup>
            <FormGroup className={s.formGroup + ' ' + s.fullWidth + ' ' + s.centered + ' ' + s.buttonsContainer}>
            {onCancel ?
                <Button variant='light' onClick={() => onCancel()} className={s.cancelButton}>
                    <FontAwesomeIcon name='times' /> cancel
                </Button>
            : null}
            {' '}
            {onSave ?
                <Button variant='primary' className={s.saveButton}
                    disabled={!isFormValid()}
                    onClick={() => save()}>
                    <FontAwesomeIcon name={trip._id ? 'check' : 'plus'} /> {trip._id ? `save trip details` : `create new trip`}
                </Button>
            : null}
            </FormGroup>
            {_switches && _switches.map(i =>
            <SettingControl variableType={i.variableType} 
                groupId={group._id} tripId={trip._id}
                title={i.title} onChange={i.onChange ? () => i.onChange() : null}
                variable={i.variable} value={i.value}
                formGroupClassName={s.formGroup + ' ' + s.centered + ' ' + s.fixedWidth}
                formLabelClassName={s.formLabel}
                key={`trip-settings-${trip._id}-${i.variable}`} />        
            )}
            
            {trip._id ?
                <>
                <TripBasicsConfirmSendRsvpEmail show={showRsvpEmailModal} 
                    onSend={sendRsvpOpenEmail}
                    onClose={onRsvpModalClose} />                
                <FormGroup className={s.formGroup + ' ' + s.centered + ' ' + s.fixedWidth}>
                    <FormLabel className={s.formLabel}>Send details</FormLabel>
                    <TripBasicsSendDetails group={group} trip={trip} />
                </FormGroup>
                <FormGroup className={s.formGroup + ' ' + s.centered + ' ' + s.fixedWidth}>
                    <FormLabel className={s.formLabel}>Delete trip?</FormLabel>
                    <DeleteTripButton group={group} trip={trip} isGlobalAdmin={isGlobalAdmin} />                
                </FormGroup>
            </>
            : null}

            {trip._id ? <TeamThemes group={group} trip={trip} /> : null}

        </div>
    )
}

export default TripBasicsEntry