import React from 'react'
import { useLocation, useParams, Navigate, Outlet, useOutletContext } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const AuthenticatedRoute = ({ groupAdminOnly = false, globalAdminOnly = false, doLogging = false, children = null, ...otherProps }) => {

    let location = useLocation()
    let params = useParams()
    let groupId = params.groupId || null
    let _context = useOutletContext() || {}

    let isAuthenticated = useSelector(state => state.account.isAuthenticated && !!state.account.access_token)
    let isGlobalAdmin = useSelector(state => state.account.isGlobalAdmin)
    let isGlobalAdminChecked = useSelector(state => state.account.isGlobalAdminChecked)

    if (doLogging) {
        console.log({ isAuthenticated, isGlobalAdmin, isGlobalAdminChecked, globalAdminOnly, groupAdminOnly })
    }

    if (!isAuthenticated && !isGlobalAdminChecked) {
        return <Navigate to='/login' state={{ from: location }} />
    } else if (isAuthenticated && isGlobalAdminChecked && globalAdminOnly && !isGlobalAdmin) {
        return <Navigate to='/groups' state={{ from: location }} />
    } else if (isAuthenticated && groupId && groupAdminOnly) {
        let _group = useSelector(state => state.groups.groups.find(i => groupId && i._id === groupId))
        let _groupAdminPathIsAllowed = (((_group || {}).role || {}).isAdmin || isGlobalAdmin) || false
        if (doLogging) {
            console.log({ _groupAdminPathIsAllowed })
        }
        if (!_groupAdminPathIsAllowed) {
            return <Navigate to='/groups' state={{ from: location }} />
        }    
    }
    if (doLogging) console.log('Got to outlet!')
    return (
        <Outlet context={_context} />
    )
  }

  export default AuthenticatedRoute
  