import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Nav, Navbar } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Menu.scss'
import { img, commonImageUrl } from '@severed-links/common.severedlinks-constants'
import CloudinaryImage from '../images/components/CloudinaryImage'

const PublicMenu = () => {

    const navigate = useNavigate()

    const handleSelect = (eventKey, e) => navigate(eventKey)

    return (
        <Navbar className={`${s.baseMenu}`} expand='md' 
            onSelect={handleSelect} collapseOnSelect>
            <Navbar.Brand className={s.navbarBrand}>
                <Link to={{ pathname: '/public' }}>
                    <CloudinaryImage className={s.logoImage} publicId={commonImageUrl('severed-links-logo-h50')} />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle className={s.navbarToggle}><FontAwesomeIcon name='bars' /></Navbar.Toggle>
            <Navbar.Collapse className={s.navbarCollapse} id={`public-menu-navbar`}>
                <Nav className={s.nav}>
                    <Nav.Item className={s.item}><Nav.Link eventKey={`/login`}>Login</Nav.Link></Nav.Item>
                    <Nav.Item className={s.item}><Nav.Link eventKey={`/forgot-password`}>Forgot Password</Nav.Link></Nav.Item>
                    <Nav.Item className={s.item}><Nav.Link eventKey={`/sign-up`}>Sign Up</Nav.Link></Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default PublicMenu