import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, FormGroup, FormLabel, FormControl, InputGroup, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { trim } from 'lodash'
import * as s from './ForgotPassword.scss'
import { resetForgotPassword, setUserInfo,
    checkEmail, checkCellPhone, requestResetCode, verifyResetCode,
    requestPasswordChange } from '@severed-links/common.severedlinks-reducers/forgotPassword'

const ForgotPasswordEnterCode = forwardRef(({ jumpToStep, ...props }, _ref) => {

    const dispatch = useDispatch()
    const fp = useSelector(state => state.forgotPassword)
    const [code, setCode] = useState('')
    const CODE_LENGTH = 5

    useEffect(() => {
        const { enteredToken, tokenRequested, sendBy, 
            email, cellPhone, 
            emailIsValid, cellPhoneIsValid } = fp
        setCode(enteredToken)
        if (!tokenRequested && 
            ((sendBy == 'Email' && emailIsValid) ||
            (sendBy == 'Text' && cellPhoneIsValid))) {
                dispatch(requestResetCode({ 
                    kind: 'password_reset', 
                    to: sendBy === 'Email' ? email : cellPhone, 
                    address: sendBy === 'Email' ? 'email' : 'text' 
                }))
        }
    }, [])

    const handleChange = e => {
        const _value = trim(e.target.value)
        setCode(_value)
        dispatch(setUserInfo({ enteredToken: _value, enteredTokenIsValid: _value.length === CODE_LENGTH }))
    }

    const _isValid = fp.tokenRequested && fp.tokenSent && !fp.tokenError && fp.enteredTokenIsValid

    useImperativeHandle(_ref, () => ({
        isValidated() { return _isValid }
    }))

    return (
        <div>
            <Row>
                <Col xs={12}>
                    {!fp.tokenRequested && !fp.tokenSent ? 
                    <Alert variant='info' style={{ marginTop: '40px' }}>Requesting reset token...</Alert>
                    : null}
                    {fp.tokenRequested && fp.tokenError ?
                    <Alert variant='danger' style={{ marginTop: '40px' }}>{fp.tokenErrorMessage}</Alert>
                    : null}
                    {fp.tokenRequested && fp.tokenSent ?
                    <Row>
                        <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3}}>
                            <FormGroup>
                                <FormLabel>Enter the code you received here</FormLabel>
                                <InputGroup size='lg'>
                                    <InputGroup.Text><FontAwesomeIcon name='list-ol' /></InputGroup.Text>
                                    <FormControl autoFocus autoComplete='off' name='code' 
                                        type='text' maxLength={CODE_LENGTH} 
                                        value={code} 
                                        required isValid={fp.enteredTokenIsValid}
                                        isInvalid={!fp.enteredTokenIsValid}
                                        onChange={e => handleChange(e)} />
                                    <FormControl.Feedback />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    : null}
                </Col>
            </Row>
        </div>
    )
})

export default ForgotPasswordEnterCode
