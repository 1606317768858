import React, { useState, useRef } from 'react'
import { Popover, Overlay, ListGroup, Image, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './MatchPairingChecker.scss'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import pluralize from 'pluralize'

const MatchPairingChecker = ({ matchValidations = [] }) => {

    const [showPopover, setShowPopover] = useState(false)
    const _warningIcon = useRef(null)

    if (!matchValidations || !matchValidations.length) return null
    return (
        <div className={s.container}>
            <Button className={s.warningIcon} ref={_warningIcon}
                onClick={() => setShowPopover(!showPopover)}>
                <FontAwesomeIcon name='exclamation-triangle' />
                {' '}
                {matchValidations.length > 1 ? matchValidations.length : null}
            </Button>
            <Overlay placement='top' show={showPopover} target={_warningIcon.current} 
                rootClose onHide={() => setShowPopover(false)}>
                {props => (
                <Popover className={s.popover} id={`match-validation-popover`} {...props}
                    title={`Duplicate pairing ${pluralize('warning', matchValidations.length, false)}`}>
                    <div className={s.content}>
                        <ListGroup className={s.list} variant='flush'>
                        {matchValidations && matchValidations.map(i => 
                            <ListGroup.Item className={s.item} 
                                key={`match-validation-item-${i.round}-${i.key}`}>
                                <div className={s.itemContent}>
                                    <div className={s.round}><div className={s.name}>Round</div><div className={s.number}>{i.round}</div></div>
                                    {i.players.map(p => 
                                        <div className={s.player} key={`match-validation-item-${i.round}-${i.key}-${p.playerID}`}>
                                            <Image roundedCircle src={playerAvatarUrl(p.imageUrl, true)} className={s.avatar} />
                                            <div className={s.firstName}>{p.firstName}</div>
                                            <div className={s.lastName}>{p.lastName}</div>
                                        </div>
                                    )}
                                </div>
                            </ListGroup.Item>
                        )}    
                        </ListGroup>
                    </div>
                </Popover>
                )}
            </Overlay>
        </div>
    )
}

export default MatchPairingChecker
