import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Alert, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Confirm from '@severed-links/common.confirm'
import { roleCommand, getMyGroups } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { CMD } from '@severed-links/common.severedlinks-constants'
import * as s from './GroupInactiveAlert.scss'

const GroupActionButton = ({ variant = 'light', title = '', label = '', confirmText = '', body = null, icon = 'question', onClick }) => (
    <Confirm title={title} 
        onConfirm={() => onClick()}
        confirmText={confirmText}
        body={<div>{body}</div>}
        variant={variant} buttonClassName={s.groupActionButton}
        buttonIcon={icon} buttonText={label} />
)

const GroupInactiveAlert = ({ role = {}, groupId = null, isGlobalAdmin = false }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const variant = role.isInvited ? 'info' : role.reactivationRequested ? 'warning' : !role.isActive ? 'danger' : 'warning'
    const deleteRole = () => sendRoleCommand(CMD.deleteRole)
    const acceptInvitation = () => sendRoleCommand(CMD.acceptInvite)
    const declineInvitation = () => sendRoleCommand(CMD.declineInvite)
    const submitReactivationRequest = () => sendRoleCommand(CMD.reactivate)
    const routeToGroup = () => navigate(`/group/${groupId}`)
    const sendRoleCommand = command => {
        dispatch(roleCommand(groupId, command))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Account Role Update', timeout: 5000 }))
            dispatch(getMyGroups())
            .then(action => {
                if (command === CMD.acceptInvite) routeToGroup()
            })
        })
    }

    if (!role.roleId || !role.playerId || role.isAdmin || role.isActive || isGlobalAdmin) return null
    return (
        <Alert variant={variant} onClick={e => e.stopPropagation()} className={s.container}>
            {role.isInvited ? 
                <>
                    <div className={s.message}>You have been invited to join this group.</div>
                    <GroupActionButton variant='danger' icon='times' label='decline' title='Decline Invitation'
                        confirmText='remove this group from my account'
                        body={<><p>Are you sure you want to decline this invitation?</p><p><small>You will need to ask the administrators of this group to extend another invitation to you if you wish to join it.</small></p></>}
                        onClick={() => declineInvitation()} />
                    <GroupActionButton variant='info' icon='check' label='accept' title='Accept Invitation'
                        confirmText='accept and join this group'
                        body={<><p>Please confirm your acceptance of this invitation and we will add you to the players in this group.</p></>}
                        onClick={() => acceptInvitation()} />
                </>
            : role.reactivationRequested ? 
                <>
                    <div className={s.message}>You have submitted a reactivation request to the administrators of this group.</div>
                    <GroupActionButton variant='danger' icon='trash' label='delete this group' title='Remove From Group'
                        confirmText='remove this group from my account'
                        body={<div><p>Are you sure you want to delete this group from your account?</p><p><small>You will need to ask the administrators of this group to extend another invitation to you if you wish to join it.</small></p></div>}
                        onClick={() => deleteRole()} />
                </>
            :
                <>
                    <div className={s.message}>Your participation in this group has been marked as inactive.</div>
                    <GroupActionButton variant='danger' icon='trash' label='remove this group' title='Remove From Group'
                        confirmText='remove this group from my account'
                        body={<><p>Please confirm that you want to request that the administrators reactivate your participation in this group.</p></>}
                        onClick={() => deleteRole()} />
                    <GroupActionButton variant='warning' icon='sync-alt' label='request reactivation' title='Request Group Reactivation'
                        confirmText='remove this group from my account'
                        body={<><p>Are you sure you want to delete this group from your account?</p><p><small>You will need to ask the administrators of this group to extend another invitation to you if you wish to join it.</small></p></>}
                        onClick={() => submitReactivationRequest()} />
                </>
            }                
        </Alert>
    )
}

export default GroupInactiveAlert