// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactivationRequests__container___tQOgV .ReactivationRequests__list___KIcxq .ReactivationRequests__controls___gxI6r{display:flex;flex-flow:row nowrap;justify-content:flex-end;align-items:center}.ReactivationRequests__container___tQOgV .ReactivationRequests__list___KIcxq .ReactivationRequests__controls___gxI6r .ReactivationRequests__controlButton___Hurvn{margin:.25rem 0px 0px 1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Groups/Admin/ReactivationRequests.scss"],"names":[],"mappings":"AAGQ,qHACI,YAAA,CACA,oBAAA,CACA,wBAAA,CACA,kBAAA,CAEA,kKACI,0BAAA","sourcesContent":[".container {\n    .list {\n\n        .controls {\n            display: flex;\n            flex-flow: row nowrap;\n            justify-content: flex-end;\n            align-items: center;\n\n            .controlButton {\n                margin: 0.25rem 0px 0px 1rem;\n            }\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `ReactivationRequests__container___tQOgV`;
export var list = `ReactivationRequests__list___KIcxq`;
export var controls = `ReactivationRequests__controls___gxI6r`;
export var controlButton = `ReactivationRequests__controlButton___Hurvn`;
export default ___CSS_LOADER_EXPORT___;
