import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Row, Col, FormGroup, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { playerAvatarUrl, FRIENDLY_DATE_FORMAT, 
    FRIENDLY_SHORT_DATE_FORMAT, FRIENDLY_SHORT_TIME_FORMAT } from '@severed-links/common.severedlinks-constants' 
import TextareaAutosize from 'react-autosize-textarea'
import { deleteMockAwardComment, postMockAwardComment } from '@severed-links/common.severedlinks-reducers/groups'
import CommentItem from '../Comment/CommentItem'
import TripRecapMockAwardItem from './TripRecapMockAwardItem'
import { orderBy } from 'lodash'

const TripRecapMockAwards = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const [newComment, setNewComment] = useState('')
    const mockAwards = trip.mockAwards || []
    const mockAwardComments = trip.mockAwardComments || []

    const deleteComment = commentId => dispatch(deleteMockAwardComment(group._id, trip._id, commentId))

    const handleKeyPress = e => {
        if (e.key === 'Enter' && newComment) {
            e.preventDefault()
            dispatch(postMockAwardComment(group._id, trip._id, newComment))
            setNewComment('')
        }
    }

    const canEditComment = authorId => (group.role && group.role.isAdmin) || (authorId === playerId)

    return (
        <div style={{ marginTop: '30px', marginBottom: '30px' }}>
            <Row>
                <Col md={6} sm={12} xs={12}>
                    <h4><FontAwesomeIcon name='smile-wink' /> {group.mockAwardName}s</h4>
                    {group.mockAwardDescription ?
                    <div style={{ marginBottom: '1rem' }} dangerouslySetInnerHTML={{ __html: group.mockAwardDescription }} />
                    : null}
                    <div>
                    {mockAwards && mockAwards.map((i, index) =>
                        <TripRecapMockAwardItem mockAward={i} isAdmin={false} group={group} trip={trip} 
                            key={`mock-award-item-${i._id}`} />
                    )}
                    {!mockAwards || mockAwards.length === 0 ? <p>No {group.mockAwardName}s have been awarded for this trip yet.</p> : null}
                    </div>
                </Col>
                <Col md={6} sm={12} xs={12}>
                    <h4><FontAwesomeIcon name='comment' /> Comments</h4>
                    <p>Who did something famous (or infamous) on the trip? Tell us all here!</p>
                    <div>
                        <FormGroup>
                            <TextareaAutosize className='form-control'
                                placeholder="[Add a comment...]" 
                                value={newComment} rows={2}
                                onKeyPress={e => handleKeyPress(e)}
                                onChange={e => setNewComment(e.target.value)}
                                onResize={e => {}} />
                        </FormGroup>
                        <ListGroup>
                        {mockAwardComments && mockAwardComments.map(c => 
                            <CommentItem key={`mock-award-comment-${c._id}`}
                                canDelete={canEditComment(c.playerID)}
                                id={c._id} text={c.comment} author={c}
                                date={c.dateEntered} onDelete={deleteComment} />
                        )}
                        </ListGroup>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TripRecapMockAwards