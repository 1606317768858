import React, { useEffect } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Badge from '@severed-links/common.badge'
import PlayerListItem from '../Player/PlayerListItem'
import * as s from './TripRoundLowPlayerScores.scss'
import ParScoreDisplay from './ParScoreDisplay'
import pluralize from 'pluralize'

const TripRoundLowPlayerScores = ({ trip = {}, round = 0 }) => {

    const list = ((trip.scoreboard || {}).lowScores || []).filter(r => r.round === round)
    const _round = (trip.rounds || []).find(r => r.round === round) || {}
    if (!list.length) return null
    return (
        <Card className={s.container}>
            <Card.Header>{_round.roundName || ''} Low {pluralize('Player', list.length, false)}</Card.Header>
            <ListGroup className={s.list} variant='flush'>
            {list && list.map(i => [
                i.isRoundMedalist ? <ListGroup.Item className={s.item + ' ' + s.roundMedalistItem} key={`trip-low-scores-trip-${trip._id}-round-${round}-${i.playerId}${i.isBestBall ? `-best-ball` : ``}-round-medalist-container`}><FontAwesomeIcon name='trophy' className={s.icon} /><div className={s.heading}>Round {round} Medalist</div></ListGroup.Item> : null,
                <PlayerListItem player={i} showFlag className={s.item}
                    flagUrl={trip[`${(i.team || '').toLowerCase()}FullTeamImageUrl`]} 
                    rightDetail={<div className={s.rightDetail}>
                        {i.isLowGross ?
                        <div className={s.infoContainer}>
                            <div className={s.heading}>Gross ({i.grossScore})</div>
                            <div className={s.value}><ParScoreDisplay score={i.grossDiff} /></div>
                        </div>
                        : null}
                        {i.isLowNet ?
                        <div className={s.infoContainer}>
                            <div className={s.heading}>Net ({i.netScore})</div>
                            <div className={s.value}><ParScoreDisplay score={i.netDiff} /></div>
                        </div>
                        : null}
                    </div>}
                    showRole={false} key={`trip-low-scores-trip-${trip._id}-round-${round}-${i.playerId}`} />
            ])}
            {!list || list.length === 0 ? <ListGroup.Item>No players located.</ListGroup.Item> : null}
            </ListGroup>
        </Card>
    )
}

export default TripRoundLowPlayerScores