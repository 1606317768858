import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './MessageTypeSelector.scss'

const MessageTypeSelector = ({ onlineUserCount = 0, selectedMessageType = null, onSelect = () => void(0) }) => {

    const _messageTypeButtons = [
        { label: 'Email', value: 'email', icon: 'envelope' },
        { label: 'SMS', value: 'sms', icon: 'sms' },
        { label: `Push (${onlineUserCount} online)`, value: 'push', icon: 'bolt' },
        { label: 'Mobile', value: 'mobile', icon: 'mobile' },
    ].map(i => ({ ...i, isActive: selectedMessageType && i.value === selectedMessageType }))

    return (
        <div className={s.container}>
        {_messageTypeButtons && _messageTypeButtons.map(i =>
        <Button className={s.messageTypeButton + (i.isActive ? ` ${s.isActive}` : '')}
            key={`message-type-button-${i.value}`}
            onClick={() => onSelect(i.value)}>
            <FontAwesomeIcon name={i.icon} />
            <div className={s.buttonLabel}>{i.value}</div>
        </Button>
        )}
        </div>
    )
}

export default MessageTypeSelector
