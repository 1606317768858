import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Alert, Button, ListGroup, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getMyMvpAwardVote, castMvpVote } from '@severed-links/common.severedlinks-reducers/groups'
import { orderBy } from 'lodash'
import Modal from '@severed-links/common.modal'
import PlayerListItem from '../Player/PlayerListItem';
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './MvpAwardVoter.scss'

const MvpAwardVoter = ({ group = {}, trip = {} }) => {

    if (!group._id || !trip._id) return null

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const [show, setShow] = useState(false)
    const tripPlayers = orderBy([...(trip.usaPlayers || []), ...(trip.eurPlayers || []), ...(trip.unassignedPlayers || [])], ['lastName', 'firstName'], ['asc', 'asc'])
    const isTripPlayer = tripPlayers.some(p => p.playerID === playerId)
    const hasVoted = !!((trip || {}).voteForPlayerId || null)
    const variant = hasVoted ? 'success' : 'info'
    const currentVote = hasVoted ? tripPlayers.find(p => p.playerID === (trip || {}).voteForPlayerId || null) : null

    useEffect(() => {
        dispatch(getMyMvpAwardVote(group._id, trip._id))
    }, [])

    const saveVote = player => {
        dispatch(castMvpVote(group._id, trip._id, player.playerID))
        .then(action => {
            dispatch(getMyMvpAwardVote(group._id, trip._id))            
            setTimeout(() => setShow(false), 1500)
        })
    }

    if (!group || !trip || !trip.isDuring || !isTripPlayer) return null

    return (
        <Alert variant={variant} className={s.container}>
            <div className={s.content}>
                <div className={s.text}>
                    <b>{group.mvpAwardName}</b>:
                    {' '}
                    {hasVoted ? `Thank you for casting your vote for ${trip.tripName}` : `Please cast your vote for ${trip.tripName}`}
                </div>
                <Button variant='light' onClick={() => setShow(true)} size='sm' variant={variant} className={s.button}><FontAwesomeIcon name='check' /> vote</Button>
            </div>
            <Modal show={show} enforceFocus={false} showFooter={false}
                heading={`${trip.tripName} ${group.mvpAwardName}`}                
                onClose={() => setShow(false)}>
                <div>
                    <ListGroup>
                        {currentVote ? <PlayerListItem player={currentVote} showRole={false} clickDisabled active disabled rightDetail={<Badge bg='primary'>Voted</Badge>} /> : null}
                        <Row>
                    {tripPlayers && tripPlayers.filter(p => p.playerID !== playerId && p.playerID !== (currentVote || {}).playerID).map(p =>
                        <Col sm={6} xs={12} key={`vote-player-${p.playerID}`}>
                            <PlayerListItem player={p} showRole={false} onClick={() => saveVote(p)} />
                        </Col>
                    )}
                        </Row>
                    </ListGroup>
                </div>
            </Modal>
        </Alert>
    )
}

export default MvpAwardVoter