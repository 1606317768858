import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import scriptLoader from 'react-async-script-loader'
import { setCreditCardState, getSquareConfig } from '@severed-links/common.severedlinks-reducers/creditCard'
import * as s from './SquareCreditCardInitializer.scss'

const SquareCreditCardInitializer = ({ show = false, isScriptLoadSucceed = false, isScriptLoaded = false }) => {

    const dispatch = useDispatch()
    const { isAuthenticated } = useSelector(state => state.account)
    const { isSquareScriptLoaded, squareIsLive, squareApplicationId, squareLocationId, isSquareInitialized } = useSelector(state => state.creditCard)

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getSquareConfig())
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (isScriptLoaded && isScriptLoadSucceed) {
            dispatch(setCreditCardState({ isSquareScriptLoaded: true }))
        }
    }, [isScriptLoaded, isScriptLoadSucceed, isSquareScriptLoaded])

    useEffect(() => {
        if (isSquareScriptLoaded && 
            squareApplicationId && 
            squareLocationId && 
            !isSquareInitialized) {
            dispatch(setCreditCardState({ isSquareInitialized: true }))
        }
    }, [isSquareScriptLoaded, squareApplicationId, squareLocationId, isSquareInitialized])

    if (!show) return null
    return (
        <Alert className={s.alert} variant={(isSquareInitialized ? 'success' : 'danger')}>
            <div className={s.content}>
                <div className={s.icon}><FontAwesomeIcon name={isSquareInitialized ? 'check' : 'times'} /></div>
                <div className={s.text}>
                    Square card processing {!isSquareInitialized ? 'not' : ''} initialized.
                    {' '}
                    {isSquareInitialized && !squareIsLive ? <Badge bg='danger'>Sandbox mode!</Badge> : null}
                </div>
            </div>
        </Alert>
    )
}

export default scriptLoader(`https://${process.env.NODE_ENV === 'development' ? 'sandbox.' : ''}web.squarecdn.com/v1/square.js`)(SquareCreditCardInitializer)
