import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Nav, Button, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import * as s from './DrivingDirectionsModal.scss'
import Spinner from '@severed-links/common.spinner'
import GeolocationUpdater from '../GeoLocation/GeolocationUpdater'
import { getDrivingDirectionsToLocation, getDrivingDistanceToLocation, 
    getDrivingDirectionsToAddress, getDrivingDistanceToAddress,
    sendDirections } from '@severed-links/common.severedlinks-reducers/geoLocation'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { GoogleMap, Marker, DirectionsRenderer, OverlayView, useJsApiLoader } from '@react-google-maps/api'
import { GOOGLE_MAPS_STATIC_MAP_API_KEY } from '@severed-links/common.severedlinks-constants'

function getBoundsZoomLevel(bounds, mapDim) {
    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 21;

    function latRad(lat) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
}


const RouteMap = ({ latitude, longitude, directions, ...props }) => (
    <GoogleMap defaultZoom={7} 
        defaultCenter={new google.maps.LatLng(latitude, longitude)}>
     {directions && <DirectionsRenderer directions={directions} />}
    </GoogleMap>
)
   
const DrivingDirectionsModal = ({ tripId = null, destination = {}, onClose, show = false, isGolfCourse = false, isAddress = false }) => {

    const dispatch = useDispatch()
    const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: GOOGLE_MAPS_STATIC_MAP_API_KEY })
    const geoLocationLocalState = useSelector(state => state.geoLocation)
    const [isDirectionsLoading, setDirectionsLoading] = useState(false)
    const [canLoadDirections, setCanLoadDirections] = useState(false)
    const [selectedRoute, setSelectedRoute] = useState(-1)
    const [googleMapsIsLoaded, setGoogleMapsIsLoaded] = useState(false)
    const [status, setStatus] = useState('')
    const [routes, setRoutes] = useState([])
    const [selectedLeg, setSelectedLeg] = useState({})
    const [geocoded_waypoints, setGeocodedWaypoints] = useState([])
    const _activeMap = useRef(null)
    const route = routes && routes.length > 0 && selectedRoute > -1 ? routes[selectedRoute] : null
    const _step = selectedLeg.legIndex > -1 && selectedLeg.stepIndex > -1 && route ? route.legs[selectedLeg.legIndex].steps[selectedLeg.stepIndex] : null
    const _bounds = googleMapsIsLoaded && _step ? new google.maps.LatLngBounds({ lat: Math.min(_step.start_location?.lat, _step.end_location?.lat), lng: Math.min(_step.start_location?.lng, _step.end_location?.lng) }, { lat: Math.max(_step.start_location?.lat, _step.end_location?.lat), lng: Math.max(_step.start_location?.lng, _step.end_location?.lng) }) : null
    const _center = _bounds ? _bounds.getCenter() : null
    const _zoom = _bounds ? getBoundsZoomLevel(_bounds, { width: 1600, height: 900 }) : 21
    const _googleMapUrl = googleMapsIsLoaded && _step && _center ? `https://maps.googleapis.com/maps/api/staticmap?center=${_center.lat()},${_center.lng()}&zoom=${_zoom}&size=1600x900&maptype=roadmap
    &key=${GOOGLE_MAPS_STATIC_MAP_API_KEY}` : null

    /*
&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318
    &markers=color:red%7Clabel:C%7C40.718217,-73.998284
    */

    useEffect(() => {
        if (isLoaded) {
            setGoogleMapsIsLoaded(true)
        }
    }, [isLoaded])

    useEffect(() => {
        if (geoLocationLocalState?.lastUpdate &&
            geoLocationLocalState?.isGeolocationAvailable && 
            geoLocationLocalState?.isGeolocationEnabled && 
            geoLocationLocalState?.latitude !== 0.000 && 
            geoLocationLocalState?.longitude !== 0.000 && 
            show && 
            geoLocationLocalState?.serverIsUpdated) {
                setCanLoadDirections(true)
        }

    }, [geoLocationLocalState?.lastUpdate, show])

    useEffect(() => {
        if (canLoadDirections && (destination?._id || typeof destination === 'string')) {
            getDirections()
        }
    }, [canLoadDirections, geoLocationLocalState.latitude, geoLocationLocalState.longitude, destination, isGolfCourse])

    const getDirectionsCallback = payload => {
        setSelectedRoute(payload.routes && payload.routes.length ? 0 : -1)
        setRoutes(payload.routes || [])
        setGeocodedWaypoints(payload.geocoded_waypoints || [])
        setStatus(payload.status || null)
        setDirectionsLoading(false)
    }

    const getDirections = () => {
        if (destination._id || typeof destination === 'string') {
            if (!isAddress) {
                setDirectionsLoading(true)
                dispatch(getDrivingDirectionsToLocation(destination._id, isGolfCourse))
                .then(action => getDirectionsCallback(action.payload))
            } else {
                setDirectionsLoading(true)
                dispatch(getDrivingDirectionsToAddress(destination))
                .then(action => getDirectionsCallback(action.payload))
            }
        }
    }

    const calcDrivingSummary = route => route && route.legs && route.legs.length > 0 ? route.legs[0].distance.text + ' (' + route.legs[0].duration.text + ')' : null

    const send = () => {
        dispatch(sendDirections(tripId, destination._id, selectedRoute > -1 ? routes[selectedRoute] : {}, isGolfCourse))
        .then(action => dispatch(createNotification({ ...action.payload, headline: 'Send Driving Directions', timeout: 4000 })))
    }

    const handleLegSelect = (legIndex, stepIndex) => {
        if (legIndex !== selectedLeg.legIndex || stepIndex !== selectedLeg.stepIndex) {
            setSelectedLeg({ legIndex, stepIndex })
        } else {
            setSelectedLeg({})
        }
    }

    return (
        <Modal show={show}
            heading={`Driving Directions: ${isAddress ? destination : destination.locName}`}
            enforceFocus={false}
            showFooter={false}
            onClose={() => onClose()}>
            <div className={s.container}>
                <Nav variant='pills' activeKey={selectedRoute} 
                    onSelect={e => setSelectedRoute(parseInt(e))}
                    className={s.nav}>
                {routes && routes.map((r, index) =>
                    <Nav.Item className={s.item} key={r.summary}>
                        <Nav.Link className={s.link} eventKey={index}>{r.summary} {calcDrivingSummary(r)}</Nav.Link>
                    </Nav.Item>
                )}
                    {route && !isAddress ? <Button className={s.sendButton} variant='light' onClick={() => send()}><FontAwesomeIcon name='envelope' /> send</Button> : null}
                </Nav>
                {!canLoadDirections || isDirectionsLoading ? 
                <div className={s.spinnerContainer}>
                    <Spinner />
                </div>
                : null}
                {route && route.legs && route.legs.length > 0 ?
                <>
                <ListGroup className={s.directionsList}>
                {route.legs.map((leg, legIndex) => 
                    leg && leg.steps && leg.steps.map((step, stepIndex) =>
                        <ListGroup.Item className={s.directionsItem}
                            action onClick={() => handleLegSelect(legIndex, stepIndex)}
                            key={`route-${selectedRoute}-leg-${legIndex}-step-${stepIndex}`}>
                            <div className={s.directionsItemContent}>
                                <div className={s.htmlDirections} dangerouslySetInnerHTML={{ __html: step.html_instructions }} />
                                <div className={s.timing}>
                                    <div className={s.distance}>{(step.distance || {}).text || ''}</div>
                                    <div className={s.time}>{(step.duration || {}).text || ''}</div>
                                </div>
                            </div>
                            {legIndex === selectedLeg.legIndex && stepIndex === selectedLeg.stepIndex ?
                            <div className={s.mapContainer}>
                                {_googleMapUrl ? <Image src={_googleMapUrl} className={s.mapImage} /> : null}
                            </div>
                            : null}
                        </ListGroup.Item>
                    )
                )}
                </ListGroup>
                {route ? <div className={s.copyright}>{route.copyrights}</div> : null}
                </>
                : null}
                <GeolocationUpdater show={false} />
            </div>
        </Modal>
    )
}

export default DrivingDirectionsModal