import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import SquareCreditCardInitializer from './SquareCreditCardInitializer'
import * as s from './CreditCardPaymentForm.scss'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { charge, setCreditCardState } from '@severed-links/common.severedlinks-reducers/creditCard'
import numeral from 'numeral'
import { CARD_BRANDS } from '@severed-links/common.severedlinks-constants'
import { trim } from 'lodash'

const CreditCardPaymentForm = ({
    groupId = null, tripId = null, playerIdOverride = null, 
    amount = 0.00, chargeType = '', data = {}, 
    header = null, message = null, footer = null, 
    onSuccess = () => void(0), onError = () => void(0), 
}) => {
    
    const dispatch = useDispatch()

    const isSquareInitialized = useSelector(state => state.creditCard.isSquareInitialized)
    const squareApplicationId = useSelector(state => state.creditCard.squareApplicationId)
    const squareLocationId = useSelector(state => state.creditCard.squareLocationId)
    const zip = useSelector(state => state.account.zip || '')
    const playerId = useSelector(state => state.account._id)
    const [isLoaded, setLoaded] = useState(false)
    const [isAuthorizing, setAuthorizing] = useState(false)
    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false)
    const [payments, setPayments] = useState(null)
    const _formId = `credit-card-payment-form`
    const _creditCardContainerId = `credit-card-container`
    const _creditCardPaymentButtonId = `credit-card-payment-button`
    const currency = 'USD'

    useEffect(() => {
        if (isSquareInitialized && squareApplicationId && squareLocationId) {
            setPayments(new Square.payments(squareApplicationId, squareLocationId))
        }
    }, [isSquareInitialized, squareApplicationId, squareLocationId])

    useEffect(() => {

        const _setUpPayments = async () => {
            
            if (payments) {
                const _card = await payments.card({
                    style: {
                        input: {
                            backgroundColor: 'transparent',
                            fontSize: '16px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                        },
                        '.message-text': {
                        },
                        '@media screen and (max-width: 576px)': {
                            'input': {
                               'fontSize': '14px',
                            }
                        }
                    }
                })
                if (document.getElementById(_creditCardContainerId)) {
                    await _card.attach(`#${_creditCardContainerId}`)
                }

                const _handlePaymentButtonClick = async e => {
                    e.preventDefault()
                    try {
                        setAuthorizing(true)
                        const _result = await _card.tokenize()
                        if (_result.status === 'OK') {
                            await handleSquareSuccessResponse(_result)
                        } else if (_result.errors) {
                            dispatch(createNotification({ headline: 'Credit Card Entry Error', message: _result.errors.map(i => i.message).join('.  '), messageType: 'error' }))
                            setAuthorizing(false)
                        }
                    } catch (e) {
                        setAuthorizing(false)
                    }    
                }

                const cardButton = document.getElementById(`${_creditCardPaymentButtonId}`)
                if (cardButton) {
                    cardButton.addEventListener('click', _handlePaymentButtonClick)
                }
                setLoaded(true)
            }
        }
        
        _setUpPayments()
    }, [payments])

    const handleSquareSuccessResponse = async (_result = {}) => {

        const { status, details, token } = _result
        const { card, billing, method } = details || {}
        const { brand, last4, expMonth, expYear } = card || {}

        const _postData = {
            token, groupId, tripId,
            playerId: playerIdOverride || playerId,  
            chargeType, card: brand && last4 ? trim(`${brand || ''} x${last4}`) : '',
            amount: !isNaN(amount) ? parseFloat(amount) : 0.00,
            lineItems: [], data, 
        }
        
        dispatch(charge(_postData))
        .then(action => {
            dispatch(createNotification(action.payload))
            setAuthorizing(false)
            setIsPaymentSuccess(action.payload.messageType === 'success')
            // iOS popping keyboard fix
            if (action.payload.messageType === 'success') {
                onSuccess(action.payload)
            } else {
                onError(action.payload)
            }
        })
    }

    // const [googlePay, setGooglePay] = useState(false)
    // const [applePay, setApplePay] = useState(false)
    // const [masterpass, setMasterpass] = useState(false)

    //         applePay: {
    //             elementId: 'sq-apple-pay'
    //         },
    //         masterpass: {
    //             elementId: 'sq-masterpass'
    //         },
    //         googlePay: {
    //             elementId: 'sq-google-pay'
    //         },
    //         callbacks: {
    //             createPaymentRequest: () => {
    //                 const _paymentRequest = {
    //                     requestShippingAddress: false,
    //                     requestBillingInfo: true,
    //                     currencyCode: 'USD',
    //                     countryCode: 'US',
    //                     total: {
    //                         label: 'Severed Links',
    //                         amount: `${amount}`,
    //                         pending: false
    //                     },
    //                     lineItems: [
    //                         {
    //                             label: 'Subtotal',
    //                             amount: `${amount}`,
    //                             pending: false
    //                         }
    //                     ]
    //                 }
    //                 return _paymentRequest
    //             },




    return (
        <div className={s.container}>
            <SquareCreditCardInitializer />
            {isSquareInitialized ?
            <div className={s.formContainer}>
                {isLoaded && header ? <h4 className={s.message}>{header}</h4> : <p>Pay with a credit card:</p>}
                {isLoaded && message ? <p className={s.message}>{message}</p> : null}
                {!isPaymentSuccess ?
                <form id={_formId} className={s.form}>
                    <div id={_creditCardContainerId}></div>
                    <Button id={_creditCardPaymentButtonId}
                        className={s.payButton + (isLoaded ? ` ${s.isLoaded}` : '')} 
                        disabled={isAuthorizing}>
                        {!isAuthorizing ? 'Pay' : 'Paying'}{' '}{numeral(amount).format('($0.00)')}{!isAuthorizing ? '' : '...'}
                    </Button>
                </form>
                : 
                <Alert variant='success'>Transaction was successful!</Alert>}
                {/* {!isPaymentSuccess && <div className={s.walletBox}>
                    {(applePay || masterpass || googlePay) && <h6>-or- pay with a digital wallet:</h6>}
                    <button className={s.walletButton + ' ' + s.applePay} style={{ display: applePay ? 'block' : 'none' }} id='sq-apple-pay'></button>
                    <button className={s.walletButton + ' ' + s.masterpass} style={{ display: masterpass ? 'block' : 'none' }} id='sq-masterpass'></button>
                    <button className={s.walletButton + ' ' + s.googlePay} style={{ display: googlePay ? 'block' : 'none' }} id='sq-google-pay'></button>
                </div>} */}
                {isLoaded && footer ? <div className={s.footer}>{footer}</div> : null}
            </div>
            : null}
        </div>
    )
}

export default CreditCardPaymentForm