import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import Image from 'react-bootstrap/Image'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Alert from '@severed-links/common.alert'
import * as s from './GroupPlayerEditorAvatarUpdate.scss'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import NewImageUploadModal from '../Profile/NewImageUploadModal'
import Confirm from '@severed-links/common.confirm'
import Button from '@severed-links/common.button'

const GroupPlayerEditorAvatarUpdate = ({ imageUrl = null, playerId = null, canUpdate = false, onUpdate = null }) => {

    const dispatch = useDispatch()
    const _imageUrl = playerAvatarUrl(imageUrl)
    const [showImageUploader, setShowImageUploader] = useState(false)

    useEffect(() => {
    }, [])

    const deleteImage = () => {
        if (onUpdate) onUpdate({ playerId, imageUrl: null })

    }

    const handleNewImageUpload = _response => {
        if (onUpdate) onUpdate(_response)
    }

    if (!playerId) return null
    return (
        <>
        <div className={s.container + ' ' + (canUpdate ? s.canUpdate : s.cannotUpdate)}>
            <div className={s.avatar}>
                <div className={s.img} style={{ backgroundImage: `url(${playerAvatarUrl(imageUrl, true)})` }} />
            </div>
            <div className={s.content}>
                {canUpdate ? `Edit the user's image here.` : `This user is a member of more than one group so we do not allow you to update their global image because it would affect other groups.`}
            </div>
            {canUpdate ? 
            <div className={s.controls}>
                <Button variant='info' onClick={() => setShowImageUploader(true)} icon='upload' />
                {imageUrl ? 
                <Confirm title={'Delete Player Profile Image'} 
                    onConfirm={() => deleteImage()}
                    confirmText='delete image'
                    body={<div><p>Are you sure you want to delete this image?</p><p style={{ textAlign: 'center' }}><Image rounded src={playerAvatarUrl(imageUrl)} style={{ width: '100px' }} /></p></div>}
                    variant='danger' buttonIcon='trash' />
                : null}
            </div>
            : null}
        </div>
        <NewImageUploadModal show={showImageUploader} 
            playerId={playerId} onSuccess={handleNewImageUpload}
            onClose={() => setShowImageUploader(false)} />
        </>
    )
}

export default GroupPlayerEditorAvatarUpdate
