import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation, Outlet, useOutletContext } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import Nav from 'react-bootstrap/Nav'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripNotesAdmin.scss'
import { first, endsWith } from 'lodash'

const TripNotesAdmin = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()
    const { _id: playerId } = useSelector(state => state.account)
    const { group, groupId, trip, tripId, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const _menuTabs = [
        { name: 'Private notebook', icon: 'bookmark', path: '/private' },
        { name: 'Public notes', icon: 'sticky-note', path: '/public' },
    ].map(i => ({ ...i, isActive: location.pathname.indexOf(i.path) > -1 }))
    const _activeKey = (_menuTabs.find(i => i.isActive) || {}).title || null

    useEffect(() => {
        if (endsWith(location.pathname.toLowerCase(), '/notes')) {
            navigate(`${location.pathname}${first(_menuTabs).path}`)
        }
    }, [location.pathname])

    const handleSelect = e => {
        navigate(`/group/${groupId}/trip/${tripId}/admin/notes${e}`)
    }

    return (
        <div className={s.container}>

            <h4>Trip Notes</h4>

            <Nav className={s.nav} variant='pills' activeKey={_activeKey} 
                onSelect={e => handleSelect(e)}>
            {_menuTabs && _menuTabs.map(i =>
                <Nav.Item className={s.item} key={`trip-admin-notes-nav-${i.path}`}>
                    <Nav.Link className={s.link + (i.isActive ? ` ${s.active}` : ``)} eventKey={i.path}>
                        {i.icon ? <FontAwesomeIcon className={s.icon} name={i.icon} /> : null}
                        {' '}
                        {i.name}
                    </Nav.Link>
                </Nav.Item>
            )}
            </Nav>

            <Outlet context={{ group, groupId, trip, tripId, isGlobalAdmin, isGlobalAdminPath }} />
        </div>
    )
}

export default TripNotesAdmin
