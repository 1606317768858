import React, { useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Faq.scss'
import Confirm from '@severed-links/common.confirm'

const FaqAdminButtons = ({ _id, onEdit, onDelete }) => (
    <ButtonGroup className={s.tools}>
        <Button variant='light' size='sm' onClick={() => onEdit(_id)}><FontAwesomeIcon name='pencil-alt' /></Button>
        <Confirm title={'Delete FAQ'}
            onConfirm={() => onDelete(_id)}
            confirmText='delete FAQ'
            body={<div><p>Are you sure you want to delete this question?</p></div>}
            variant='danger' size='sm' buttonIcon='trash' />
    </ButtonGroup>
)

export default FaqAdminButtons