import React, { isValidElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { endsWith } from 'lodash'

import * as s from './Profile.scss'

const Profile = () => {

    const navigate = useNavigate()

    const isActive = _key => endsWith(location.pathname, `/profile/${_key}`)

    const _menuTabs = [
        { key: 'edit', icon: 'user', title: 'Edit profile' },
        { key: 'change-password', icon: 'lock', title: 'Change password' },
        { key: 'help-center', icon: 'question', title: 'Help center' },
        { key: 'handicap-tracker', icon: 'flag', title: 'Handicap Tracker' },
    ].map(i => ({ ...i, isActive: isActive(i.key) }))

    const _activeKey = (_menuTabs.find(i => i.isActive) || {}).key || null

    const handleSelect = e => navigate(`/profile/${e}`)

    return (
        <div className={s.container}>
            <Nav variant='pills' activeKey={_activeKey} 
                className={s.profileNav}
                onSelect={e => handleSelect(e)}>
                {_menuTabs && _menuTabs.map(i => 
                    <Nav.Item key={`profile-${i.key}`}><Nav.Link eventKey={i.key}><FontAwesomeIcon name={i.icon} /> <span className='d-none d-md-inline'>{i.title}</span></Nav.Link></Nav.Item>
                )}
            </Nav>
            <Outlet />
        </div>
    )
}

export default Profile