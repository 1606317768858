import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Navbar, Nav, Dropdown, NavDropdown } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { routingPath } from '@severed-links/common.severedlinks-constants'
import * as s from './Top10ListSelector.scss'

const Top10ListSelector = ({ lists = [], groupId = null, isGlobalAdmin = false, isGlobalAdminPath = false, gaGroupNameKey = 'A' }) => {

    const navigate = useNavigate()
    const params = useParams()
    const top10ListKeys = useSelector(state => state.groups.top10ListKeys)
    const listName = params.listName || null
    const keys = top10ListKeys.map(i => ({ ...i, show: i.isInitialShow || ((lists.find(x => x.apiKey === i.apiKey) || {}).items || []).length > 0 }))
        .filter(i => i.show)
    const activeList = top10ListKeys.find(i => i.path === listName)

    const handleSelect = e => {
        const _path = `${routingPath(groupId, isGlobalAdminPath, gaGroupNameKey)}top-10-lists/${e}`
        navigate(_path)
    }

    return (
        <div className={s.container}>
            <Navbar className={s.smallNav}>
                <Nav onSelect={e => handleSelect(e)} activeKey={listName}>
                    <NavDropdown title={(activeList || {}).title} 
                        align='end'
                        id={`group-${groupId}-top-10-lists-xs-nav`}>
                        {keys && keys.map(i => 
                            <Dropdown.Item key={`nav-item-small-${i.apiKey}`} eventKey={i.apiKey}>{i.title}</Dropdown.Item >
                        )}
                    </NavDropdown>
                </Nav>
            </Navbar>
            <Nav variant='pills' 
                className={s.largeNav} 
                activeKey={listName} 
                onSelect={e => handleSelect(e)}>
                {keys && keys.map(i => 
                    <Nav.Item className={s.item} key={`nav-${i.apiKey}`}><Nav.Link className={s.link + (i.apiKey === listName ? ` ${s.active}` : ``)} eventKey={i.apiKey}>{i.title}</Nav.Link></Nav.Item>
                )}
            </Nav>
        </div>
    )
}

export default Top10ListSelector