import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import ScorecardTable from './ScorecardTable'

const ScorecardModal = ({ group = {}, trip = {}, isOpen = false, roundNumber = 0, roundName, foursome = 0, onClose = () => void(0), isEditable = false }) => {

    return (
        <Modal show={isOpen} showFooter={false}
            heading={`${roundName} Scorecard`} 
            onClose={onClose}>
            <ScorecardTable group={group} trip={trip} 
                roundNumber={roundNumber} foursome={foursome}
                onClose={onClose} isEditable={isEditable} />
        </Modal>
    )
}

export default ScorecardModal