import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom'
import { FormGroup, FormLabel, FormControl, DropdownButton, 
    Dropdown, Alert, InputGroup, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventEditorPollOptions.scss'
import Button from '@severed-links/common.button'
import Confirm from '@severed-links/common.confirm'
import validator from 'validator'
import pluralize, { plural } from 'pluralize'

const EditPollVoteSummary = ({ option }) => (
    <div>{Math.round((option || {}).pct || 0.0)}% ({pluralize('vote', option.count, true)})</div>
)

const EditPollOption = ({ option, index, onOptionChange, onSave, onDelete }) => (
<InputGroup className={s.inputGroup}>
    <InputGroup.Text>
        <FontAwesomeIcon name={index !== -1 ? 'check' : 'plus'} />
    </InputGroup.Text>
    <FormControl value={option.optionText || ''} 
        placeholder={index !== -1 ? '[Edit poll option...]' : '[Add a poll option...]'}
        onChange={e => onOptionChange({ ...option, optionText: e.target.value }, index)}
        onKeyPress={e => e.key === 'Enter' ? onSave(option, index) : void(0)} />
    {index !== -1 ? 
    <InputGroup.Text className={s.voteSummaryContainer}>
        <EditPollVoteSummary option={option} />
    </InputGroup.Text>
    : null}
    {index !== -1 ? 
    <Confirm title={`Delete poll option`}
        onConfirm={() => onDelete(option, index)} enforceFocus={false}
        confirmText='delete poll option'
        body={<div><p>Are you sure you want to delete this option?</p><p><small>Any player responses will be deleted as well.</small></p></div>}
        variant='danger' buttonIcon='times' clear size='sm' buttonClassName={s.deleteOptionButton} />
    : null}
</InputGroup>
)

const TripEventEditorPollOptions = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    var [_pollOptions, setPollOptions] = useState([])
    var [_addPollOption, setAddPollOption] = useState('')
    const { _id, onChange, pollOptions, pollVoteCount, eventType } = useOutletContext()

    useEffect(() => {
        if (pollOptions?.length) {
            setPollOptions(pollOptions)
        }
    }, [pollOptions?.length || 0])

    const handleUpdate = () => {
        if (onChange) {
            onChange({ pollOptions: _pollOptions })
        }
        handleCancel()
    }
    
    const handleCancel = () => {
        const _path = location.pathname.replace(/\/poll\-options$/gi, '')
        navigate(_path)
    }

    const changePollOption = (option, index) => {
        if (index > -1) {
            savePollOption(option, index)
        } else {
            setAddPollOption(option.optionText)
        }
    }

    const savePollOption = (option, index) => {
        if (index > -1) {
            _pollOptions[index] = { ..._pollOptions[index], optionText: option.optionText }
        } else {
            _pollOptions.push(option)
        }
        setPollOptions([..._pollOptions])
        setAddPollOption('')
    }

    const deletePollOption = (option, index) => {
        _pollOptions.splice(index, 1)
        setPollOptions([..._pollOptions])
    }



    return (
        <div className={s.container}>
            <p>Enter the options for your poll here.</p>

            <FormGroup className={s.formGroup}>
                <FormLabel>Poll choices</FormLabel>
                {_pollOptions && _pollOptions.map((i, index) =>
                <EditPollOption option={i} key={`trip-event-${_id}poll-option-${i._id}-index-${index}`}
                    index={index}
                    onOptionChange={changePollOption}
                    onDelete={deletePollOption}
                    onSave={savePollOption} />
                )}
                <EditPollOption option={{ _id: null, optionText: _addPollOption }}
                    index={-1}
                    onOptionChange={changePollOption}
                    onDelete={() => void(0)}
                    onSave={savePollOption} />
                <div>{pluralize('vote', pollVoteCount || 0, true)}</div>
            </FormGroup>

            <div className={s.buttonsContainer}>
                <Button variant='light' title='cancel' icon='times' onClick={() => handleCancel()} />
                <Button className={s.saveButton} variant='primary' title={`update ${eventType}`} icon='check' onClick={() => handleUpdate()} />
            </div>

        </div>
    )
}

export default TripEventEditorPollOptions
