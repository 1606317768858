import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useOutletContext, Outlet } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Button from '@severed-links/common.button'
import * as s from './AddressBook.scss'
import { trim, endsWith } from 'lodash'
import AddressBookContactListItem from './AddressBookContactListItem'

const AddressBook = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const playerId = useSelector(state => state.account._id)
    const _groupContext = useOutletContext()
    const { group, groupId } = _groupContext
    const addressBook = group?.addressBook || []
    const _isRoot = endsWith(location.pathname.toLowerCase(), '/address-book')

    useEffect(() => {
    }, [])

    const handleNewButtonClick = () => {
        navigate(`/group/${groupId}/admin/address-book/add-new`)
    }

    return (
        <div className={s.container}>
            {_isRoot ?
            <>
            <div className={s.heading}>
                <div className={s.paragraphContainer}>
                    <p>Manage all of your resort, lodging and other contacts here.</p>
                </div>
                <Button className={s.addNewButton} title='add new contact' icon='user-plus' onClick={handleNewButtonClick} />
            </div>

            <ListGroup className={s.list}>
            {addressBook && addressBook.map(i => 
            <AddressBookContactListItem {...i} onClick={() => handleContactClick(i)}
                key={`group-${groupId}-address-book-item-${i._id}`} />
            )}
            {!addressBook?.length ?
            <ListGroup.Item>
                No contacts located.
            </ListGroup.Item>
            : null}
            </ListGroup>
            </>
            : null}

            <Outlet context={_groupContext} />
        </div>
    )
}

export default AddressBook
