// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripRoundMiniNotes__container___FSOzC .TripRoundMiniNotes__miniNoteContainer___ssiIO{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/TripRoundMiniNotes.scss"],"names":[],"mappings":"AAKI,sFACI,kBAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n@use '../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n\n    .miniNoteContainer {\n        margin-bottom: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripRoundMiniNotes__container___FSOzC`;
export var miniNoteContainer = `TripRoundMiniNotes__miniNoteContainer___ssiIO`;
export default ___CSS_LOADER_EXPORT___;
