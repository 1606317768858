import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { setAccountDeletionState, deleteAccount } from '@severed-links/common.severedlinks-reducers/accountDeletion'
import pluralize from 'pluralize'
import * as s from './AccountDeletionStep.scss'
import Modal from '@severed-links/common.modal'

const EndStep5 = forwardRef(({ jumpToStep, ...props }, _ref) => {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const playerId = useSelector(state => state.account._id)
    const _accountDeletion = useSelector(state => state.accountDeletion)
    const { 
        isFetching, groupsToDelete, groupsDeletionConfirmed, groupsToDeleteCount, 
        groupsToDeleteAdminCount, tripPlayerCount, tripMatchCount, wonLossRecordCount, 
        playerInfoSelections, playerInfoSelection, firstName, lastName, address, 
        city, state, zip, emailAddress, cellPhone, imageUrl, accountDeletionConfirmed, 
    } = _accountDeletion
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const _isValidated = !groupsToDelete.length || groupsDeletionConfirmed

    useEffect(() => {
        if (!accountDeletionConfirmed) {
            setShowConfirmModal(true)
        }
    }, [accountDeletionConfirmed])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValidated,
    }))

    const handleModalClose = () => {
        setShowConfirmModal(false)
        dispatch(setAccountDeletionState({ accountDeletionConfirmed: false }))
        jumpToStep(3)
    }

    const handleAccountDeletionConfirmation = () => {
        setShowConfirmModal(false)
        dispatch(setAccountDeletionState({ accountDeletionConfirmed: true }))
        const _postData = {
            command: 'Delete Account',
            accountDeletionConfirmed: true,
            groupsDeletionConfirmed,
            groupsToDelete: groupsToDelete.map(i => i._id),
            playerInfoSelection: playerInfoSelections.find(i => i.value === playerInfoSelection),
            processingTime: Date.now(),
        }
        dispatch(deleteAccount(_postData))
        .then(action => {
            if (action.payload.messageType === 'success') {
                jumpToStep(5)
            }            
        })
    }

    return (
        <div className={s.container}>
            <h4>Account Deletion Confimed</h4>
            <p>
                {accountDeletionConfirmed ? `Your account has been deleted per your selections.` : `Press confirm to proceed with the deletion of your account.`}
            </p>
            <Modal show={showConfirmModal} showFooter
                heading={`Confirm Account Deletion`} onClose={() => handleModalClose()}
                actionButtonStyle='danger'
                actionButtonIcon='exclamation-triangle'
                actionButtonText='confirm account deletion'
                actionButtonOnClick={() => handleAccountDeletionConfirmation()}>
                <div>
                    <p>Please press the confirm button below to process your account deletion.</p>
                </div>
            </Modal>
        </div>
    )
})

export default EndStep5
