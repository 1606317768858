import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Button, Badge, Image, FormGroup, 
    FormLabel, FormControl, FloatingLabel } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TeamThemes.scss'
import Modal from '@severed-links/common.modal'
import Picker from '@severed-links/common.picker'
import { v4 as uuidv4 } from 'uuid'
import { getBase64, img } from '@severed-links/common.severedlinks-constants'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { keysIn } from 'lodash'
import { saveTripTeamTheme, resetTripTeamTheme, copyTripTeamTheme } from '@severed-links/common.severedlinks-reducers/groups'
import { SketchPicker } from 'react-color'
import hexRgb from 'hex-rgb'
import Confirm from '@severed-links/common.confirm'

const TeamThemes = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const [show, setShow] = useState(false)
    const [isUploading, setUploading] = useState(false)
    const [_theme, setThemeToEdit] = useState({})
    const [colorPickerFieldName, setColorPickerFieldName] = useState(null)
    const [showCopySelector, setShowCopySelector] = useState(false)
    const _trips = group.trips || []

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (_theme.teamAvatarFile) {
            handleFileContentChange()
        }
    }, [_theme.teamAvatarFile])

    useEffect(() => {
        if (_theme.teamAvatarBase64) {
            upload()
        }
    }, [_theme.teamAvatarBase64])

    const handleTeamEdit = (_teamId = '') => {
        _teamId = _teamId.toLowerCase()
        setThemeToEdit({
            teamId: _teamId,
            teamName: trip[`${_teamId}TeamName`] || '',
            shortTeamName: trip[`${_teamId}ShortTeamName`] || '',
            teamImageUrl: trip[`${_teamId}TeamImageUrl`] || '',
            fullTeamImageUrl: trip[`${_teamId}FullTeamImageUrl`] || '',
            defaultTeamImageUrl: `${_teamId}Flag`,
            defaultFullTeamImageUrl: `${(trip[`${_teamId}FullTeamImageUrl`] || '').substr(0, (trip[`${_teamId}FullTeamImageUrl`] || '').lastIndexOf('/') + 1)}${_teamId}Flag`,
            color: trip[`${_teamId}TextColor`] || '',
            backgroundColor: trip[`${_teamId}BackgroundColor`] || '',
            borderColor: trip[`${_teamId}BorderColor`] || '',
            badgeColor: trip[`${_teamId}BadgeTextColor`] || '',
            badgeBackgroundColor: trip[`${_teamId}BadgeBackgroundColor`] || '',
            badgeBorderColor: trip[`${_teamId}BadgeBorderColor`] || '',
            teamAvatarFileName: '',
            teamAvatarFile: null,
            teamAvatarBase64: '',
            teamAvatarFormData: new FormData(),
        })
        setShow(true)
    }

    const onUploadSuccess = e => {
        setUploading(false)
        if (e.target.response && e.target.status === 200) {
            const _response = JSON.parse(e.target.response) || {}
            const publicId = (_response.public_id || '').replace(_response.folder, '')
            setThemeToEdit({ ..._theme, teamImageUrl: publicId, fullTeamImageUrl: null })
        } else {
            const error = (JSON.parse(e.target.response) || {}).error
            dispatch(createNotification({ message: error.message, type: 'error', headline: 'Upload Error', timeout: 5000 }))
        }

    }

    const onUploadProgress = e => {
    }

    const onUploadError = e => {
        setUploading(false)
        dispatch(createNotification({ message: 'Upload error: ' + JSON.stringify(e), type: 'error', headline: 'Upload Error', timeout: 6000 }))
    }

    const upload = () => {
        setUploading(true)
        img.CloudinaryImageUploader(_theme.teamAvatarFormData, img.CloudinaryImageUploadUrl, onUploadSuccess, onUploadProgress, onUploadError)
    }

    const handleFileContentChange = () => {
        if (_theme.teamAvatarFile) {
            getBase64(_theme.teamAvatarFile)
            .then(data => setThemeToEdit({ ..._theme, teamAvatarBase64: data }))
        }
    }
    
    const handleFlagSelect = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            var formData = new FormData()
            formData.append('upload_preset', img.CloudinaryTeamAvatarUploadPreset)
            formData.append('file', file)
            setThemeToEdit({
                ..._theme,
                teamAvatarFileName: file.name,
                teamAvatarFile: file,
                teamAvatarFormData: formData,
            })
        }
    }

    const clearFlagSelection = () => {
        setThemeToEdit({ ..._theme, teamAvatarFileName: '', teamAvatarFile: null, teamAvatarBase64: '', teamAvatarFormData: new FormData() })
    }

    const setFlagSelectionToDefault = () => {
        setThemeToEdit({ ..._theme, teamAvatarFileName: '', teamAvatarFile: null, teamAvatarBase64: '', teamAvatarFormData: new FormData(), teamImageUrl: _theme.defaultTeamImageUrl, fullTeamImageUrl: _theme.defaultFullTeamImageUrl })
    }

    const handleThemeSave = () => {
        var _postData = {
            [`${_theme.teamId}TeamName`]: _theme.teamName,
            [`${_theme.teamId}ShortTeamName`]: _theme.shortTeamName,
            [`${_theme.teamId}TextColor`]: _theme.color,
            [`${_theme.teamId}BackgroundColor`]: _theme.backgroundColor,
            [`${_theme.teamId}BorderColor`]: _theme.borderColor,
            [`${_theme.teamId}BadgeTextColor`]: _theme.badgeColor,
            [`${_theme.teamId}BadgeBackgroundColor`]: _theme.badgeBackgroundColor,
            [`${_theme.teamId}BadgeBorderColor`]: _theme.badgeBorderColor,
            [`${_theme.teamId}TeamImageUrl`]: _theme.teamImageUrl,
        }
        /* remove empty values before post */
        keysIn(_postData).forEach(_key => {
            if (!_postData[_key]) {
                delete _postData[_key]
            }
        })
        dispatch(saveTripTeamTheme(group._id, trip._id, _postData))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (action.payload.messageType === 'success') {
                setShow(false)
            }
        })
    }

    const handleThemeReset = () => {
        if (_theme.teamId) {
            dispatch(resetTripTeamTheme(group._id, trip._id, _theme.teamId))
            .then(action => {
                dispatch(createNotification(action.payload))
                if (action.payload.messageType === 'success') {
                    setShow(false)
                }
            })
        }
    }

    const handleThemeCopy = _fromTripId => {
        if (_theme.teamId) {
            setShowCopySelector(false)
            dispatch(copyTripTeamTheme(group._id, trip._id, _theme.teamId, _fromTripId))
            .then(action => {
                dispatch(createNotification(action.payload))
                if (action.payload.messageType === 'success') {
                    setShow(false)
                }
            })
        }
    }

    const generateFontColorFromBackgroundColor = _color => {
        try {
            const rgb = hexRgb(_color) 
            const luma = ((0.299 * rgb.red) + (0.587 * rgb.green) + (0.114 * rgb.blue)) / 255.0
            return luma > 0.5 ? '#313131' : '#f3f3f3'
        } catch {
            return '#f3f3f3'
        }
    
    }

    const _fields = [
        { type: 'text', fieldName: 'teamName', label: 'Team name', value: _theme.teamName || '' },
        { type: 'text', fieldName: 'shortTeamName', label: 'Short team name', value: _theme.shortTeamName || '' },
        { type: 'image', fieldName: 'teamImageUrl', label: 'Flag image', url: _theme.fullTeamImageUrl },
        { type: 'color', fieldName: 'color', label: 'Text color', value: _theme.color },
        { type: 'color', fieldName: 'backgroundColor', label: 'Background color', value: _theme.backgroundColor },
        { type: 'color', fieldName: 'borderColor', label: 'Border color', value: _theme.borderColor },
        { type: 'color', fieldName: 'badgeColor', label: 'Button/badge text color', value: _theme.badgeColor },
        { type: 'color', fieldName: 'badgeBackgroundColor', label: 'Button/badge background color', value: _theme.badgeBackgroundColor },
        { type: 'color', fieldName: 'badgeBorderColor', label: 'Button/badge border color', value: _theme.badgeBorderColor },
    ].filter(i => i && i.fieldName)

    return (
        <div className={s.container}>
            
            <h4>Team Themes</h4>

            <ListGroup className={s.list}>
            {['usa','eur'].map(i =>
            <ListGroup.Item className={s.item} style={trip[`${i}Container`]} key={`trip-themes-${i}`}>
                <div className={s.flag}>
                    <Image src={trip[`${i}FullTeamImageUrl`]} />
                </div>
                <div className={s.content}>
                    <div className={s.teamName} style={trip[`${i}Text`]}>{trip[`${i}TeamName`]}</div>
                    <div className={s.shortTeamName} style={trip[`${i}Text`]}>Short name: <strong>{trip[`${i}ShortTeamName`]}</strong></div>
                </div>
                <div className={s.controls}>
                    <Button size='sm' 
                        style={{ ...(trip[`${i}BadgeContainer`]), ...(trip[`${i}BadgeText`]) }}
                        onClick={() => handleTeamEdit(i)}>
                        <FontAwesomeIcon name='pencil-alt' />
                    </Button>
                </div>
            </ListGroup.Item>
            )}
            </ListGroup>

            <Modal show={show} onClose={() => setShow(false)}
                heading={`Edit Team Theme: ${_theme.teamName}`}
                actionButtonIcon='check'
                actionButtonOnClick={() => handleThemeSave()}
                actionButtonText='save theme'>
                <div className={s.editContainer}>
                    {show ?
                    <ListGroup className={s.list}>
                    {_fields && _fields.map(i =>
                    <ListGroup.Item className={s.item} key={`edit-theme-field-${i.fieldName}`}>
                    {i.type === 'text' ?
                    <FloatingLabel className={s.content} controlId={`edit-theme-field-${i.fieldName}-control-id`} label={i.label}>
                        <FormControl type={i.type} placeholder={i.placeholder || ''} value={i.value}
                            onChange={e => setThemeToEdit({ ..._theme, [i.fieldName]: e.target.value })} />
                    </FloatingLabel>
                    : i.type === 'image' ? 
                    <>
                    <div className={s.noWrap}>
                        {_theme.teamAvatarBase64 ? <Badge className={s.badge} style={{ backgroundColor: `${_theme.badgeBackgroundColor} !important`, color: `${_theme.badgeColor} !important` }}>New!</Badge> : null}
                        <div className={s.avatar}>
                            <Image src={_theme.teamAvatarBase64 || i.url} />
                        </div> 
                        {_theme.teamAvatarBase64 ?
                        <div className={s.controls}>
                            <Button size='sm' variant='light' onClick={() => clearFlagSelection()}>
                                <FontAwesomeIcon name='times' />
                            </Button>
                            <Button className={s.defaultImageButton} size='sm' variant='light' onClick={() => setFlagSelectionToDefault()}>
                                <Image src={_theme.defaultFullTeamImageUrl} />
                            </Button>
                        </div>
                        : <FormControl className={s.content} type={'file'} 
                            onChange={e => handleFlagSelect(e)} />}
                    </div>
                    <div className={s.fileWarning}>Flag files should be small and have an aspect ratio of 1.5 (30x20, 90x60, 300x200, etc.) for best display.</div>
                    </>
                    : i.type === 'color' ?
                    <>
                        <div className={s.label}>{i.label}</div>
                        <div style={{ backgroundColor: i.value }} />
                        <div className={s.colorValue}>
                            <Button className={s.colorPickerButton} variant={null} style={{ backgroundColor: i.value || '#000000', color: generateFontColorFromBackgroundColor(i.value), borderColor: 'transparent' }}
                                onClick={() => setColorPickerFieldName(i.fieldName !== colorPickerFieldName ? i.fieldName : null)}>{i.value}</Button>
                            {i.fieldName === colorPickerFieldName ? 
                            <div className={s.colorPickerContainer}>
                                <SketchPicker color={i.value || '#000'} onChange={color => setThemeToEdit({ ..._theme, [i.fieldName]: color.hex })} />
                            </div>
                            : null}
                        </div>
                    </>
                    : null}
                    </ListGroup.Item>
                    )}
                    <ListGroup.Item>
                        Sample
                    </ListGroup.Item>
                    <ListGroup.Item className={s.item} style={{ color: _theme.color, backgroundColor: _theme.backgroundColor, borderColor: _theme.borderColor }}>
                        <div className={s.flag}>
                            <Image src={_theme.fullTeamImageUrl} />
                        </div>
                        <div className={s.content}>
                            <div className={s.teamName}>{_theme.teamName}</div>
                            <div className={s.shortTeamName}>Short name: <strong>{_theme.shortTeamName}</strong></div>
                        </div>
                        <div className={s.controls}>
                            <Button size='sm' 
                                style={{ color: _theme.badgeColor, backgroundColor: _theme.badgeBackgroundColor, borderColor: _theme.badgeBorderColor }}
                                onClick={() => void(0)}>
                                <FontAwesomeIcon name='pencil-alt' />
                            </Button>
                        </div>
                    </ListGroup.Item>
                    </ListGroup>
                    : null}
                    <div className={s.resetContainer}>
                        <Confirm title={'Reset Theme to Defaults'}
                            onConfirm={() => handleThemeReset()}
                            confirmText='reset theme'
                            body={<div><p>Are you sure you want to reset this theme to defaults?</p></div>}
                            variant='secondary' buttonClassName={s.resetButton} buttonIcon='sync-alt'
                            buttonText='reset theme to defaults' size='sm' />
                    </div>
                    {_trips.length > 1 ?
                    <div className={s.copyContainer}>
                        <Button className={s.copyButton} variant='dark' size='sm' onClick={() => setShowCopySelector(true)}><FontAwesomeIcon name='copy' /> copy theme from another trip</Button>
                    </div>
                    : null}
                </div>
            </Modal>
            <Modal show={showCopySelector} heading={`Select a trip theme to copy...`}
                showFooter={false} onClose={() => setShowCopySelector(false)} enforceFocus={false}>
                <div className={s.copyListContainer}>
                <ListGroup className={s.list}>
                {_trips && _trips.filter(i => i._id !== trip._id).map(i =>
                    <ListGroup.Item className={s.item}
                        style={i[`${_theme.teamId}Container`]}
                        action onClick={() => handleThemeCopy(i._id)}
                        key={`trip-copy-${i._id}`}>
                        <div className={s.flag}>
                            <Image src={i[`${_theme.teamId}FullTeamImageUrl`]} />
                        </div>
                        <div className={s.content}>
                            <div className={s.teamName} style={i[`${_theme.teamId}Text`]}>{i.tripName}</div>
                            <div className={s.shortTeamName} style={i[`${_theme.teamId}Text`]}>Team Name: {i[`${_theme.teamId}TeamName`]} Short name: <strong>{i[`${_theme.teamId}ShortTeamName`]}</strong></div>
                        </div>
                    </ListGroup.Item>
                )}
                </ListGroup>
                </div>
            </Modal>

        </div>
    )
}

export default TeamThemes
