import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Image, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CommentItem.scss'
import Confirm from '@severed-links/common.confirm'
import moment from 'moment-timezone'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'

const CommentItem = ({ author, text = '', id, date, canDelete = false, onDelete }) => {

    return (
        <ListGroup.Item className={s.commentItem}>
            <Image src={playerAvatarUrl((author || {}).imageUrl, true)} className={s.avatar} />
            <div className={s.content}>
                <div className={s.headingContainer}>
                    <h5 className={s.title}>{(author || {}).playerName}</h5>
                    <span className={s.date}>{moment(date).fromNow()}</span>
                    {canDelete ?
                    <span className={s.delete}>
                        <Confirm variant='danger' onConfirm={() => onDelete(id)}
                            title={"Delete Comment"} enforceFocus={false}
                            confirmText='delete comment'
                            body={'Are you sure you want to delete this comment?'}
                            size='sm' buttonClassName={s.deleteButton}
                            buttonIcon='trash' />
                    </span>
                    : null}
                </div>
                <p>{text}</p>
            </div>
        </ListGroup.Item>
    )
}

export default CommentItem