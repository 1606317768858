import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams, Outlet } from 'react-router-dom'
import { ListGroup, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminGroups.scss'
import GlobalAdminLetterBar from './GlobalAdminLetterBar'
import GroupListItem from '../Groups/GroupListItem'
import { getLocationCount, getGroups } from '@severed-links/common.severedlinks-reducers/globalAdmin'

const GlobalAdminGroups = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const letters = useSelector(state => state.globalAdmin.groups.letters || [])
    const list = useSelector(state => state.globalAdmin.groups.list || [])
    const hideDeletedGroups = useSelector(state => state.globalAdmin.groups.hideDeletedGroups)
    const letter = (params.globalAdminLetter || '').toUpperCase()
    const groupId = params.groupId || null
    const filteredList = hideDeletedGroups ? list.filter(i => !i.isDeletedGroup) : list

    useEffect(() => {
        dispatch(getLocationCount('Groups'))
    }, [])

    const reloadGlobalAdminGroupData = group => {
        dispatch(getLocationCount('Groups'))
        dispatch(getGroups((group.name || ' ').substring(0,1).toUpperCase()))
    }

    const handleClickedGlobalAdminGroup = groupId => navigate(`/global-admin/groups/filter/${letter}/view/${groupId}`)

    return (
        <div className={s.container}>
        <h3><FontAwesomeIcon name='users' /> Groups</h3>
        <GlobalAdminLetterBar letters={letters} type='group' />
        {!groupId ?
        <ListGroup>
        {filteredList && filteredList.map(p => 
            <GroupListItem key={`global-admin-${p._id}`} 
                group={p} showRole={false} isGlobalAdmin
                onClick={() => handleClickedGlobalAdminGroup(p._id)} 
                onRestore={reloadGlobalAdminGroupData} />
            )}
        {!filteredList || filteredList.length === 0 ? <ListGroup.Item>No groups located in this list.</ListGroup.Item> : null}
        </ListGroup>
        : null}
        <Outlet context={{ groupId, isGlobalAdmin: true, isGlobalAdminPath: true }} />
        </div>
    )
}

export default GlobalAdminGroups