import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Outlet, useOutletContext, useMatch } from 'react-router-dom'
import { Row, Col, Nav } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { routingPath } from '@severed-links/common.severedlinks-constants'
import * as s from './TripPayments.scss'

const TripPayments = () =>  {

    const navigate = useNavigate()
    const _tripContext = useOutletContext()
    const { groupId, group, tripId, trip, isGlobalAdmin, isGlobalAdminPath } = _tripContext
    const match = useMatch(`/group/:groupId/trip/:tripId/admin/payments/:paymentsSubTab`)
    const paymentsSubTab = match && match.params.paymentsSubTab || null
    const path = !isGlobalAdminPath ? `/group/:groupId/` : `/global-admin/groups/:globalAdminLetter/view/:groupId/`

    useEffect(() => {
        if (!paymentsSubTab) { 
            handleSelect('setup')
        }
    }, [])

    const handleSelect = e => navigate(`${routingPath(groupId, isGlobalAdminPath, (group.name || '').substring(0,1).toUpperCase())}trip/${tripId}/admin/payments/${e}`)

    if (!group || !trip) return null
    const _menuItems = [
        { icon: `cog`, title: `Setup`, eventKey: `setup` },
        { icon: `arrow-left`, title: `Payments`, eventKey: `player-payments` },
        { icon: `arrow-right`, title: `Payouts`, eventKey: `player-payouts` },
    ]
    return (
        <div className={s.container}>
            <h4>Trip Payments</h4>
            <Nav className={s.nav} variant='pills' activeKey={paymentsSubTab} onSelect={e => handleSelect(e)}>
            {_menuItems && _menuItems.map(i => 
                <Nav.Item className={s.item} key={`trip-payment-menu-item-${i.title}`}>
                    <Nav.Link className={s.link + (i.eventKey === paymentsSubTab ? ` ${s.active}` : ``)} eventKey={i.eventKey}>
                        <FontAwesomeIcon name={i.icon} className={s.icon} /> 
                        <div>{i.title}</div>
                    </Nav.Link>
                </Nav.Item>
            )}
            </Nav>
            <Outlet context={_tripContext} />
        </div>
    )
}

export default TripPayments