import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext, useMatch, useLocation, useNavigate, useParams, Outlet } from 'react-router-dom'
import { Row, Col, Card, Alert, ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { uniqBy, sortBy } from 'lodash'
import { getMatchesScoreboard } from '@severed-links/common.severedlinks-reducers/groups'
import PlayerListItem from '../Player/PlayerListItem'
import moment from 'moment-timezone'
import { getHoleDataForFoursome, setScorecardData } from '@severed-links/common.severedlinks-reducers/scorecard'
import ScorecardModal from './ScorecardModal'
import * as s from './Scorecard.scss'
import { API_URL } from '@severed-links/common.severedlinks-constants'
import { start } from '@popperjs/core'

const TeeTimeDisplay = ({ roundItem = {} }) => {
    if (!roundItem || !roundItem.matches || roundItem.matches.length === 0) return null
    const teeTimes = uniqBy(roundItem.matches.map(m => ({
        _id: m._id,
        foursome: m.foursome,
        teeTime: m.teeTime ? moment(m.teeTime || roundItem.roundDate) : null,
        formattedTeeTime: m.teeTime ? 
            (!moment(m.teeTime).isSame(moment(), 'day') ? 
                moment(m.teeTime).format('MMM D') + ' ' : ' ') + 
                moment(m.teeTime).format('h:mma')
            :
            moment(roundItem.roundDate).format('MMM D') + ' ' + roundItem.teeTime
    })), 'formattedTeeTime')
    return (
        <span key={`match-tee-time`} style={{ fontWeight: 'bold', marginLeft: '10px' }}>
        {teeTimes.map(ftt => ftt.formattedTeeTime).join(', ')}
        </span>
    )
}

const ScorecardHeader = ({ round, roundName, courseName, tees, foursome = 0, isShotgun = false, startingHole, teeTimeDisplay, onOpen = null, onDownload = null }) => (
    <Card.Header className={s.roundTitle}>
        <div className={s.roundInfo}>{roundName}: {courseName} {tees ? ` (${tees} Tees)` : null}</div>
        <div className={s.teeTime}>{teeTimeDisplay}{isShotgun && startingHole ? ` (starting on #${startingHole})` : ``}</div>
    </Card.Header>
)

const ScorecardFooter = ({ round, foursome = 0, onOpen = null, onDownload = null }) => (
    onOpen || onDownload ?
    <Card.Footer className={s.roundTitle}>
        {onOpen ? 
        <div className={s.openButton}>
            <Button onClick={() => onOpen(round, foursome)} variant='info'><FontAwesomeIcon name='clipboard-list' /> <span className={'d-none d-md-inline'}>open round {round} scorecard</span></Button>
        </div>
        : null}
        {onDownload ? 
        <div className={s.printButton}>
            <Button onClick={() => onDownload(round, foursome)} variant='light'><FontAwesomeIcon name='print' /></Button>
        </div>
        : null}
    </Card.Footer>
    : null
)

const Scorecard = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const match = useMatch(`/group/:groupId/trip/:tripId/scorecard/:showMapModal`)
    const { group, groupId, trip, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const playerId = useSelector(state => state.account._id)
    const _token = useSelector(state => state.account.access_token)
    const roundNumber = parseInt(params.round || '0')
    const foursome = parseInt(params.foursome || '0')
    const roundName = (((trip || {}).rounds || []).find(r => r.round === roundNumber) || {}).roundName
    const isOpen = roundNumber > 0 && foursome > 0
    const reloader = useSelector(state => state.scorecard.reloader)
    const [isLoading, setLoading] = useState(false)
    if (!groupId || !tripId) return null

    const matchesRaw = ((trip.scoreboard || {}).matches || []).filter(m => m.foursome && m.teeTime)
    const matches = matchesRaw.filter(m => m.usaPlayerList.some(p => p.playerID === playerId) || m.eurPlayerList.some(p => p.playerID === playerId))
    const activeRounds = (trip.rounds || []).filter(r => r.isOnlineScorecardEnabled)
        .map(i => ({ ...i, matches: matches.filter(m => m.round === i.round && m.foursome === (matches.find(m => m.round === i.round && (m.usaPlayerList.some(x => x.playerID === playerId) || m.eurPlayerList.some(x => x.playerID === playerId) )) || {}).foursome || -9999 ) }))
    const _showScorecards = activeRounds.length > 0 && activeRounds.map(i => (i.matches || []).length).some(i => i > 0)
    const tripPlayers = [...(trip.usaPlayers || []), ...(trip.eurPlayers || []), ...(trip.unassignedPlayers || [])]
    const isTripPlayer = tripPlayers.some(x => x.playerID === playerId)
    const activeRoundNumbers = sortBy(activeRounds, 'round').map(i => i.round).join(',')
    
    useEffect(() => {
        setLoading(true)
        dispatch(getMatchesScoreboard(groupId, tripId))
        .then(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (groupId === reloader.groupId && tripId === reloader.tripId && roundNumber === reloader.round && foursome === reloader.foursome) {
            dispatch(getHoleDataForFoursome(groupId, tripId, roundNumber, foursome))
            .then(action => {
                dispatch(setScorecardData(groupId, tripId, roundNumber, foursome, { holeData: action.payload }))
            })
        }
    }, [reloader.reloadTime])

    const openScorecard = (_roundNumber, _foursome) => {
        if (_roundNumber && _foursome) {
            navigate(`/group/${groupId}/trip/${tripId}/scorecard/round/${_roundNumber}/foursome/${_foursome}`)
        }
    }

    const downloadScorecard = (_roundNumber, _foursome) => {
        const _remoteUrl = `${API_URL}Scorecard/${groupId}/${tripId}/${_roundNumber}/${_foursome}/PrintableScorecard`
        fetch(_remoteUrl, { headers: { 'Authorization': `Bearer ${_token}` } }) 
        .then(response => response.blob())
        .then(blob => { 
            var _url = window.URL.createObjectURL(blob)
            const _opener = window.open(_url, '_blank')
            if (_opener?.focus) _opener.focus()

        }).catch((err) => {
            console.log(err)
        })
    }

    const closeScorecard = () => navigate(`/group/${groupId}/trip/${tripId}/scorecard`)

    return (
        <div className={s.mainContainer}>
            
            <h3>Scorecard {isLoading ? <FontAwesomeIcon name='circle-notch' spin /> : null}</h3>

            {_showScorecards ? <p>Select a scorecard below:</p>
            : !isLoading ? <Alert variant='info'>Scorecards will be available when foursomes and tee times are assigned by your trip administrators and they make your scorecards visible.</Alert>
            : null}

            {activeRounds && activeRounds.length > 0 ?
            <div>

                {activeRounds && activeRounds.map(i =>
                    <div key={`online-scorecard-${i.round}-${i._id}`}>
                        {i.matches && i.matches.filter(m => m.round === i.round).map(m =>
                            <Card className={s.matchCard} key={`match-${m.round}-${m._id}`}>
                                <ScorecardHeader {...i} {...m} />
                                <ListGroup variant='flush'>
                                {m.usaPlayerList && m.usaPlayerList.map(p =>
                                    <PlayerListItem showFlag flagUrl={trip.usaFullTeamImageUrl} showHcp player={p} showRole={false} key={`usa-match-avatar-${p.match}-${p.playerID}`} />
                                )}
                                {m.eurPlayerList && m.eurPlayerList.map(p =>
                                    <PlayerListItem showFlag flagUrl={trip.eurFullTeamImageUrl} showHcp player={p} showRole={false} key={`eur-match-avatar-${p.match}-${p.playerID}`}/>
                                )}
                                </ListGroup>
                                <ScorecardFooter {...i} {...m} onOpen={openScorecard} onDownload={downloadScorecard} />
                            </Card>
                        )}
                        {matches && matches.length ?
                        <Row key={`match-button-${i.round}`} style={{ marginLeft: '0px', marginRight: '0px' }}>
                            <Col xs={12} style={{ paddingLeft: '0px', paddingRight: '0px', paddingTop: '10px' }}>
                                
                            </Col>
                        </Row>
                        : null}
                    </div>
                )}

                <ScorecardModal group={group} trip={trip}
                    isOpen={isOpen} isEditable
                    roundNumber={roundNumber} roundName={roundName}
                    foursome={foursome}
                    onClose={closeScorecard} />

            </div>
            : 
            <Alert variant='info'>There are no rounds available that have their scorecards enabled.</Alert>}
        </div>
    )

}

export default Scorecard