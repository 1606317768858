import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Row, Col, Button, Card, ListGroup, FormGroup, 
    FormControl, FormLabel, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getRoomAssignments, saveRoomAssignment, setTripValue, saveRoom, deleteRoom } from '@severed-links/common.severedlinks-reducers/groups'
import TripLodging from '../TripLodging'
import TripRoomAssignmentPanel from '../TripRoomAssignmentPanel'
import * as s from './TripLodgingAdmin.scss'
import PlayerSlot from './RoomAssignmentComponents/PlayerSlot'
import UnassignedPlayerPanelDropZone from './RoomAssignmentComponents/UnassignedPlayerPanelDropZone'
import { flatten } from 'lodash'
import Modal from '@severed-links/common.modal'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import TripLodgingAdminToolbar from './TripLodgingAdminToolbar'
import FloatHeaderWithButtons from '../../../components/FloatHeaderWithButtons'
import * as roomCss from '../TripRoomAssignmentPanel.scss'
import RequestForHelpAlert from './RequestForHelpAlert'

const AddNewRoomButton = ({ onClick = () => void(0) }) => (
    <Button variant='light' onClick={() => onClick()}>
        <FontAwesomeIcon name='plus-circle' /> <span className='d-none d-sm-inline-block'>add room</span>
    </Button>
)

const TripLodgingAdmin = () => {

    const dispatch = useDispatch()
    const { groupId, group, tripId, trip, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const roomAssignments = (trip.roomAssignments || []).rooms || []
    const capacities = roomAssignments.map(r => r.roomAssignment.capacity)
    const assignedPlayers = flatten((roomAssignments || []).map(i => i.roomPlayers))
    const unassignedPlayers = (trip.roomAssignments || []).unassignedPlayers || []
    const showEditRoom = trip.showEditRoom
    const editRoom = trip.editRoom || {}
    const tripLocation = trip.location || {}

    useEffect(() => {
        if (group._id && trip._id) {
            dispatch(getRoomAssignments(group._id, trip._id))
            dispatch(setTripValue(group._id, trip._id, 'showEditRoom', false))
            dispatch(setTripValue(group._id, trip._id, 'editRoom', {}))
        }
    }, [group._id, trip._id])

    const onUnassignPlayer = player => {
        dispatch(saveRoomAssignment(group._id, trip._id, -1, player.playerId))
        .then(() => dispatch(getRoomAssignments(group._id, trip._id)))
    }

    const addNewRoom = () => {
        dispatch(setTripValue(group._id, trip._id, 'showEditRoom', true))
        dispatch(setTripValue(group._id, trip._id, 'editRoom', { roomID: null, roomName: '', accessCode: '', capacity: Math.max(...capacities, 1) }))
    }

    const closeRoomModal = () => {
        dispatch(setTripValue(group._id, trip._id, 'showEditRoom', false))
        dispatch(setTripValue(group._id, trip._id, 'editRoom', {}))
    }

    const doDeleteRoom = room => {
        dispatch(deleteRoom(group._id, trip._id, room._id))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Save Room', timeout: 3000 }))
            dispatch(getRoomAssignments(group._id, trip._id))
        })
    }

    const doSaveRoom = () => {
        const postData = { 
            groupId: group._id, tripId: trip._id, 
            roomName: editRoom.roomName, 
            roomId: editRoom.roomID, 
            accessCode: editRoom.accessCode || null,
            capacity: parseInt(editRoom.capacity || '0') 
        }
        dispatch(saveRoom(postData))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Save Room', timeout: 3000 }))
            dispatch(getRoomAssignments(group._id, trip._id))
            closeRoomModal()
        })
    }

    return (
        <div className={s.container}>
            <h4>Lodging</h4>
            <TripLodging groupId={group._id} tripId={trip._id} tripLocation={tripLocation}
                showRoomAssignmentModal={false} showMyRoom={false} isAdmin />
            {!tripLocation || !tripLocation._id ?
            <RequestForHelpAlert message='If you do not see the lodging you are looking for, please open a ticket with our help center.' />
            : null}
            <TripLodgingAdminToolbar group={group} trip={trip} />
            {tripLocation._id ? 
            <Row style={{ marginTop: '30px'}}>
                <Col sm={9} xs={12}>
                    <FloatHeaderWithButtons header='Room Assignments' 
                        buttons={[roomAssignments && roomAssignments.length > 0 ? { text: 'add room', icon: 'plus-circle', onClick: () => addNewRoom() } : null]}
                        style={{ marginBottom: '20px' }} key={`trip-room-assignment-admin-header`} />
                    <div className={roomCss.container}>
                    {roomAssignments && roomAssignments.map((r, index) =>
                        <TripRoomAssignmentPanel isAdmin 
                            className={roomCss.card}
                            key={`room-assignment-${r.roomAssignment._id}`}
                            groupId={group._id} tripId={trip._id} 
                            roomAssignment={r.roomAssignment} 
                            roomPlayers={r.roomPlayers}
                            onDelete={doDeleteRoom} />
                    )}
                    </div>
                    {!roomAssignments || roomAssignments.length === 0 ? 
                        <div key={`trip-room-assignment-admin-new-room-container`}>
                            <AddNewRoomButton onClick={() => addNewRoom()} />
                        </div>
                    : null}
                </Col>
                <Col sm={3} xs={12}>
                    <div className={s.unassignedPlayerZone}>
                        <h4>Unassigned</h4>
                        <Card>
                            <ListGroup>
                            <UnassignedPlayerPanelDropZone onDrop={player => onUnassignPlayer(player)} />
                            {unassignedPlayers && unassignedPlayers.map(p => 
                            <PlayerSlot player={p} 
                                variant={'light'} 
                                key={`room-assignment-unassigned-${p._id}`} />
                            )}
                            </ListGroup>
                        </Card>
                    </div>
                </Col>
            </Row>
            : null}
            <Modal show={showEditRoom}
                heading={`Edit Room: ${editRoom.roomName || ''}`}
                enforceFocus={false}
                actionButtonOnClick={() => doSaveRoom()}
                actionButtonText='save room'
                actionButtonIcon='check'
                actionButtonDisabled={!editRoom.roomName || !editRoom.capacity || isNaN(parseInt(editRoom.capacity)) || parseInt(editRoom.capacity) === 0}
                onClose={() => closeRoomModal()}>
                <div className={s.editRoomContainer}>
                <FormGroup className={s.roomFormGroup}>
                        <FormLabel className={s.formLabel}>Room</FormLabel>
                        <FormControl value={editRoom.roomName || ''} onChange={e => dispatch(setTripValue(group._id, trip._id, 'editRoom', { ...editRoom, roomName: e.target.value }))} />
                    </FormGroup>
                    <FormGroup className={s.roomFormGroup}>
                        <FormLabel className={s.formLabel}>Access Code</FormLabel>
                        <FormControl value={editRoom.accessCode || ''} onChange={e => dispatch(setTripValue(group._id, trip._id, 'editRoom', { ...editRoom, accessCode: e.target.value || '' }))} />
                    </FormGroup>
                    <FormGroup className={s.capacityFormGroup}>
                        <FormLabel className={s.formLabel}>Capacity</FormLabel>
                        <FormControl style={{ textAlign: 'right' }} value={`${editRoom.capacity || 0}`} onChange={e => dispatch(setTripValue(group._id, trip._id, 'editRoom', { ...editRoom, capacity: e.target.value }))} />
                    </FormGroup>
                </div>
            </Modal>
        </div>
    )
}

export default TripLodgingAdmin