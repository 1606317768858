import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Outlet, useOutletContext } from 'react-router-dom'
import GroupAdminMenuBar from './GroupAdminMenuBar'
import * as s from './GroupAdmin.scss'
import { routingPathTemplate } from '@severed-links/common.severedlinks-constants'

const GroupAdmin = () => {

    const _groupContext = useOutletContext()
    const pathTemplate = routingPathTemplate(_groupContext.isGlobalAdmin)

    return (
        <div className={s.container}>
            <div className={s.menu}>
                <GroupAdminMenuBar group={_groupContext.group} 
                    isGlobalAdmin={_groupContext.isGlobalAdmin} 
                    isGlobalAdminPath={_groupContext.isGlobalAdminPath} />
            </div>
            <div className={s.content}>
                <Outlet context={_groupContext} />
            </div>
        </div>
    )
}

export default GroupAdmin