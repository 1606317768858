// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripRoundScorecardAdminModal__scorecardModal___dQZUA{max-width:98vw}.TripRoundScorecardAdminModal__scorecardModal___dQZUA .modal-title{width:100%}.TripRoundScorecardAdminModal__scorecardModal___dQZUA .modal-body{padding:0px}.TripRoundScorecardAdminModal__scorecardModal___dQZUA .TripRoundScorecardAdminModal__scorecardHeader___Ngspx{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.TripRoundScorecardAdminModal__scorecardModal___dQZUA .TripRoundScorecardAdminModal__scorecardHeader___Ngspx .TripRoundScorecardAdminModal__scorecardTitle___joGIk{flex:1 1 auto;font-weight:bold;margin:0px}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Admin/TripRoundScorecardAdminModal.scss"],"names":[],"mappings":"AAEA,sDACI,cAAA,CAEA,mEACI,UAAA,CAEJ,kEACI,WAAA,CAEJ,6GACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,mKACI,aAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n\n.scorecardModal {\n    max-width: 98vw;\n\n    :global(.modal-title) {\n        width: 100%;\n    }\n    :global(.modal-body) {\n        padding: 0px;\n    }\n    .scorecardHeader {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n\n        .scorecardTitle {\n            flex: 1 1 auto;\n            font-weight: bold;\n            margin: 0px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var scorecardModal = `TripRoundScorecardAdminModal__scorecardModal___dQZUA`;
export var scorecardHeader = `TripRoundScorecardAdminModal__scorecardHeader___Ngspx`;
export var scorecardTitle = `TripRoundScorecardAdminModal__scorecardTitle___joGIk`;
export default ___CSS_LOADER_EXPORT___;
