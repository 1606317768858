// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GlobalAdminGolfCourseSearcher__container___JzMi0{width:300px;display:inline-block;margin:0px 10px}.GlobalAdminGolfCourseSearcher__container___JzMi0.GlobalAdminGolfCourseSearcher__full___X3U1d{width:100%;display:block;margin-bottom:20px;margin-right:10px}.GlobalAdminGolfCourseSearcher__container___JzMi0 .rbt{flex-grow:1;margin-right:20px}.GlobalAdminGolfCourseSearcher__container___JzMi0 .GlobalAdminGolfCourseSearcher__formGroup___qWMG9{margin:0px}.GlobalAdminGolfCourseSearcher__container___JzMi0 .GlobalAdminGolfCourseSearcher__formGroup___qWMG9 .GlobalAdminGolfCourseSearcher__menuItem___Kvse8 a{white-space:normal}.GlobalAdminGolfCourseSearcher__container___JzMi0 .GlobalAdminGolfCourseSearcher__formGroup___qWMG9 .GlobalAdminGolfCourseSearcher__menuItem___Kvse8 a:hover{color:#337ab7}`, "",{"version":3,"sources":["webpack://./src/routes/GlobalAdmin/GlobalAdminGolfCourseSearcher.scss"],"names":[],"mappings":"AAAA,kDACI,WAAA,CACA,oBAAA,CACA,eAAA,CACA,8FACI,UAAA,CACA,aAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,uDACI,WAAA,CACA,iBAAA,CAGJ,oGACI,UAAA,CAOI,uJACI,kBAAA,CACA,6JACI,aAAA","sourcesContent":[".container {\n    width: 300px;\n    display: inline-block;\n    margin: 0px 10px;\n    &.full {\n        width: 100%;\n        display: block;\n        margin-bottom: 20px;\n        margin-right: 10px;\n    }\n\n    :global(.rbt) {\n        flex-grow: 1;\n        margin-right: 20px;\n    }\n\n    .formGroup {\n        margin: 0px;\n\n\n        .inputGroup { \n        }\n\n        .menuItem {\n            a {\n                white-space: normal;\n                &:hover {\n                    color: #337ab7;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `GlobalAdminGolfCourseSearcher__container___JzMi0`;
export var full = `GlobalAdminGolfCourseSearcher__full___X3U1d`;
export var formGroup = `GlobalAdminGolfCourseSearcher__formGroup___qWMG9`;
export var menuItem = `GlobalAdminGolfCourseSearcher__menuItem___Kvse8`;
export default ___CSS_LOADER_EXPORT___;
