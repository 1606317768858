import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, FormControl, Button, InputGroup, FormLabel, FormCheck } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import Datetime from 'react-datetime'
import moment from 'moment-timezone'
import { FRIENDLY_SHORT_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import validator from 'validator'
import { AsyncTypeahead, Menu, MenuItem  } from 'react-bootstrap-typeahead'
import { golfCourseNameSearch, getGolfCourseById } from '@severed-links/common.severedlinks-reducers/player'
import { first } from 'lodash'
import * as s from './HandicapTrackerAddRound.scss'

const HandicapTrackerAddRound = ({ show = false, onClose = null, onSave = null }) => {

    const _typeaheadRef = useRef(null)
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [roundDate, setRoundDate] = useState(moment().format(FRIENDLY_SHORT_DATE_FORMAT))
    const [grossScore, setGrossScore] = useState('0')
    const [course, setCourse] = useState({ _id: null })
    const [selectedTeeBoxId, setSelectedTeeBoxId] = useState(null)

   const handleUsGolfLookupChange = e => {
        if (e && e.length > 0) {
            setSelectedTeeBoxId(null)
            setCourse({ _id: null })
            dispatch(getGolfCourseById(e[0]._id))
            .then(action => {
                const _course = action.payload
                setCourse(_course)
                if (_course && _course.tees && _course.tees.length) {
                    setSelectedTeeBoxId(first(course.tees)._id)
                }
            })
        }
    }

    const isValid = () => moment(roundDate, FRIENDLY_SHORT_DATE_FORMAT).isValid() && 
        !isNaN(grossScore) && parseInt(grossScore) >= 18 &&
        selectedTeeBoxId

    const saveRound = () => {
        if (onSave) {
            onSave({ score: grossScore, date: moment(roundDate, FRIENDLY_SHORT_DATE_FORMAT).format(FRIENDLY_SHORT_DATE_FORMAT), courseId: selectedTeeBoxId })
        }
        closeModal()
    }

    const closeModal = () => {
        setCourse({ _id: null })
        setSelectedTeeBoxId(null)
        setOptions([])
        setLoading(false)
        if (onClose) onClose()
    }

    const runQuery = useCallback(query => {
        setLoading(true)
        dispatch(golfCourseNameSearch(query))
        .then(action => {
            setOptions(action.payload)
            setLoading(false)
        })
    }, [])

    return (
        <Modal show={show} onClose={() => closeModal()} 
            actionButtonText='save round' actionButtonIcon='flag'
            actionButtonDisabled={!isValid()}
            actionButtonOnClick={() => saveRound()}>
        <div className={s.container}>
            <FormGroup>
                <FormLabel>Select Course</FormLabel>
                <InputGroup>
                <InputGroup.Text><FontAwesomeIcon name='search' /></InputGroup.Text>
                <AsyncTypeahead ref={_typeaheadRef}
                    id={`personal-hcp-course-selector`}
                    onSearch={runQuery}
                    className={s.typeahead}
                    isLoading={isLoading}
                    multiple={false} autoFocus
                    labelKey={o => `${o.courseName} (${o.city} ${o.state})`}
                    placeholder={'[Lookup course by name...]'}
                    options={options}
                    onChange={e => handleUsGolfLookupChange(e)} />
                </InputGroup>
            </FormGroup>
            {course && course._id && course.tees && course.tees.length > 0 ?
            <FormGroup>
                <FormLabel>Select Tees</FormLabel>
                {course.tees.map(t => <FormCheck key={`hcp-select-tees-${t.tees}`} type='radio' checked={selectedTeeBoxId === t._id} onClick={() => setSelectedTeeBoxId(t._id)} label={`${t.tees}: ${t.yards} yards, slope: ${t.slope}, rating: ${t.rating}`} />)}
            </FormGroup>
            : null}
            {selectedTeeBoxId ?
            <FormGroup>
                <FormLabel>Round date</FormLabel>
                <Datetime disabled={!course || !course._id} value={roundDate} rootClose closeOnSelect
                    onChange={e => setRoundDate(e.format(FRIENDLY_SHORT_DATE_FORMAT))}
                    dateFormat={FRIENDLY_SHORT_DATE_FORMAT} timeFormat={false} />
            </FormGroup>
            : null}                
            {selectedTeeBoxId ?
            <FormGroup>
                <FormLabel>Score</FormLabel>
                <FormControl disabled={!course || !course._id} value={grossScore} 
                    isValid={!validator.isEmpty(grossScore) && validator.isNumeric(grossScore) && parseInt(grossScore) >= 18}
                    onChange={e => setGrossScore(e.target.value)} />
                <FormControl.Feedback />
            </FormGroup>
            : null}                
        </div>
        </Modal>
    )
}

export default HandicapTrackerAddRound