import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Outlet } from 'react-router-dom'
import { Row, Col, ListGroup, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getLocationCount } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import GlobalAdminLetterBar from './GlobalAdminLetterBar'
import GlobalAdminUsGolfImporter from './GlobalAdminUsGolfImporter'
import * as s from './GlobalAdminGolfCourses.scss'
import GlobalAdminGolfCourseListItem from './GlobalAdminGolfCourseListItem'

const GlobalAdminGolfCourseList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const letterTab = (params.globalAdminLetter || '').toUpperCase()
    const letters = useSelector(state => state.globalAdmin.golfCourses.letters) || []
    const list = (useSelector(state => state.globalAdmin.golfCourses[`${letterTab || 'None'}`]) || {}).list || []

    return (
        <div className={s.container}>
            <ListGroup className={s.list}>
            {list && list.map(i => 
                <GlobalAdminGolfCourseListItem {...i} key={`global-admin-${i._id}`} />
            )}
            </ListGroup>
        </div>
    )
}
export default GlobalAdminGolfCourseList