import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useOutletContext } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Top10List from './Top10List'
import { getTop10Lists, getTop10ListKeys, getTop10ListByKey } from '@severed-links/common.severedlinks-reducers/groups'
import Top10ListSelector from './Top10ListSelector'
import { first, isEqual } from 'lodash'
import { routingPath } from '@severed-links/common.severedlinks-constants'
import * as s from './Top10Lists.scss'

const Top10Lists = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { group, groupId, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const top10ListKeys = useSelector(state => state.groups.top10ListKeys) || []
    const gaGroupNameKey = ((group || {}).name || ' ').toUpperCase().substring(0,1)
    const listName = params.listName || null
    const lists = group.lists || []

    useEffect(() => {
        if (!top10ListKeys || top10ListKeys.length === 0) {
            dispatch(getTop10ListKeys(groupId))
        }
        checkForRerouteToFirstList()
    }, [])

    useEffect(() => {
        if (top10ListKeys.length && listName && !lists.length) {
            // top10ListKeys are loaded now
            const _firstKey = top10ListKeys.find(i => i.path === listName) || first(top10ListKeys)
            if (_firstKey) {
                dispatch(getTop10ListByKey(groupId, _firstKey.apiKey))
                dispatch(getTop10Lists(groupId))
            }
        }
        checkForRerouteToFirstList()
    }, [top10ListKeys.length, listName, lists.length])

    const checkForRerouteToFirstList = () => {
        const _firstApiKey = first(top10ListKeys || [])
        if (groupId && !listName && _firstApiKey) {
            const _path = `${routingPath(groupId, isGlobalAdminPath, gaGroupNameKey || 'A')}top-10-lists/${_firstApiKey.path}`
            navigate(_path)
        }
    }

    return (
        <div className={s.container}>
            <h3 className={s.titleContainer}>Top 10 Lists</h3>
            <div className={s.selectorContainer}>
                <Top10ListSelector groupId={groupId}
                    lists={lists}
                    gaGroupNameKey={gaGroupNameKey} isGlobalAdmin={isGlobalAdmin}
                    isGlobalAdminPath={isGlobalAdminPath} />
            </div>
            <div className={s.listContainer}>
               <Top10List list={lists.find(i => i.apiKey === listName)} />
            </div>
        </div>
    )
}

export default Top10Lists