import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { Alert, Button, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PricingPlanListItem.scss'
import * as s1 from '../Trips/Admin/BuyAddOns.scss'
import { getPricingPlans, setTripPricingPlan } from '@severed-links/common.severedlinks-reducers/pricingPlans'
import { getTripPricingPayments, setMedalistPageState } from '@severed-links/common.severedlinks-reducers/groups'
import Modal from '@severed-links/common.modal'
import numeral from 'numeral'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import CreditCardPaymentForm from '../CreditCard/CreditCardPaymentForm'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const PricingPlanListItem = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const pricing = useSelector(state => state.pricingPlans.pricing)
    const notes = useSelector(state => state.pricingPlans.notes)
    const paymentKey = useSelector(state => state.pricingPlans.paymentKey)
    const userName = useSelector(state => state.account.userName)
    const [showPlanSelector, setShowPlanSelector] = useState(false)
    const [selectedPlanCode, setSelectedPlanCode] = useState(null)
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [codeForPayment, setCodeForPayment] = useState(null)
    const [balanceDue, setBalanceDue] = useState(0.00)
    const hasPricingPlan = !!(trip && trip.pricingPlan && trip.pricingPlan.code)
    const pricingPlan = trip.pricingPlan || {}
    const isPricingPlanPaid = trip.isPricingPlanPaid || false
    const pricingPlanTotal = trip.pricingPlanTotal || 0.00
    const _selectedPlan = pricing.find(i => i.code === selectedPlanCode) || {}
    const canUpgradePlan = pricing.filter(i => i.price > pricingPlanTotal).length > 0
    const isDevelopment = __DEV__

    useEffect(() => {
        dispatch(getPricingPlans())
        if (group._id && trip._id) {
            dispatch(getTripPricingPayments(group._id, trip._id))
        }
        setDefaultPlanCode()
    }, [])

    useEffect(() => {
        setBalanceDue(Math.max(0.00, (_selectedPlan.price || 0.00) - (pricingPlanTotal || 0.00)))
    }, [_selectedPlan.price || 0.00, pricingPlanTotal || 0.00])

    const setDefaultPlanCode = () => {
        if (trip.pricingPlan && trip.pricingPlan.code) {
            setSelectedPlanCode(trip.pricingPlan.code)
        }
    }

    const openPlanSelector = () => {
        const _selectedPlanCode = (trip.pricingPlan || {}).code || null
        setSelectedPlanCode(_selectedPlanCode)
        setShowPlanSelector(true)
    }

    const selectPlan = _selectedPlanCode => setSelectedPlanCode(_selectedPlanCode !== selectedPlanCode ? _selectedPlanCode : null)

    const closePlanSelector = _cb => {
        setShowPlanSelector(false)
        setDefaultPlanCode()
        if (_cb) {
            _cb()
        }
    }

    const saveSelectedPlan = () => {
        const _selectedPlan = pricing.find(i => i.code === selectedPlanCode) || {}
        const pricingPlanTotal = trip.pricingPlanTotal || 0.00
        closePlanSelector(() => {
            // check for payment needed
            const balanceDue = Math.max(0.00, _selectedPlan.price - pricingPlanTotal)
            if (balanceDue > 0.00) {
                setShowPaymentModal(true)
                setCodeForPayment(selectedPlanCode)
                setBalanceDue(0.00)
            } else {
                setBalanceDue(0.00)
                dispatch(setTripPricingPlan(group._id, trip._id, selectedPlanCode))
            }
        })
    }

    const onErrorPayment = _response => {
        alert(`Error completing payment: ${JSON.stringify(_response)}`)
    }

    const onSuccessfulPayment = _response => {
        showPaymentModal(false)
    }

    if (!pricing || !pricing.length) return null
    return (
        <>
            <ListGroup.Item className={s1.item} variant={hasPricingPlan && pricingPlan.variant ? pricingPlan.variant : 'secondary'}>
            {hasPricingPlan ? 
            <>
                <div className={s1.icon}>
                    {_selectedPlan.icon ? <FontAwesomeIcon name={_selectedPlan.icon} size='2x' /> : null}
                </div>
                <div className={s1.content}>
                    <div className={s1.title}>Active Plan: {_selectedPlan.title}</div>
                    <div className={s1.subtitle}>You can always open a ticket for assistance with our <Link to='/profile/help-center'>Help Center</Link>.</div>
                </div>
                {_selectedPlan.price ?
                <div className={s1.valueContainer}>
                    <div className={s1.heading}>{balanceDue ? 'Balance Due' : 'Paid'}</div>
                    <div className={s1.value}>{numeral(balanceDue || _selectedPlan.price).format('$0')}</div>
                </div>
                : null}
                {balanceDue || canUpgradePlan ? 
                <div className={s1.controls}>
                    {balanceDue ?
                    <Button className={s1.button} size='sm' onClick={() => setShowPaymentModal(true)} variant={_selectedPlan.variant || 'danger'}>pay balance</Button>
                    : null}
                    {canUpgradePlan ?
                    <Button className={s1.button} size='sm' onClick={() => openPlanSelector()} variant={_selectedPlan.variant || 'danger'}>upgrade plan</Button>
                    : null}
                </div>
                : null}
            </>
            :
            <>
                <div className={s1.icon}>
                    <FontAwesomeIcon name='question' size='2x' />
                </div>
                <div className={s1.content}>
                    <div className={s1.title}>Need help?</div>
                    <div className={s1.subtitle}>Purchase an assistance plan here.</div>
                </div>
                <div className={s1.controls}>
                    <Button className={s1.button} size='sm' variant='secondary' onClick={() => openPlanSelector()}>
                        <FontAwesomeIcon name='shopping-cart' />
                        {' '}
                        purchase plan
                    </Button>
                </div>
            </>}
            </ListGroup.Item>

            <Modal show={showPlanSelector} 
                heading={`Select an Assistance Plan`}
                actionButtonIcon={'check'}
                actionButtonText={'select plan'}
                actionButtonDisabled={!selectedPlanCode}
                actionButtonOnClick={() => saveSelectedPlan()}
                onClose={() => closePlanSelector()}>
                <div className={s.planSelectorContainer}>
                    <ListGroup className={s.planSelectorList}>
                    {pricing && pricing.filter(i => i.price >= pricingPlanTotal).map(i =>
                    <ListGroup.Item key={`select-pricing-plan-${i.code}`} 
                        variant={i.variant || 'light'} 
                        className={s.planSelectorItem} action
                        onClick={() => selectPlan(i.code)}>
                        <FontAwesomeIcon name={i.code === selectedPlanCode ? 'check-square' : 'square'} isRegular={i.code !== selectedPlanCode} size='2x' className={s.selector} />
                        {i.icon ? <div className={s.icon}><FontAwesomeIcon name={i.icon} size='2x' /></div> : null}
                        {i.title ? <h4 className={s.title}>{i.title}</h4> : null}
                        <h4 className={s.price}>{i.price ? numeral(i.price).format('$0') : 'Free'}</h4>
                    </ListGroup.Item>
                    )}
                    </ListGroup>
                    {_selectedPlan.code && _selectedPlan.features && _selectedPlan.features.length ?
                    <div className={s.selectedPlanContainer}>
                    <h4>Selected Plan Features:</h4>
                    <ListGroup>
                    {_selectedPlan.features.map((i, _featureIndex) =>
                    <ListGroup.Item variant={_selectedPlan.variant || 'light'}
                        key={`selected-plan-feature-${_selectedPlan.code}-${_featureIndex}`}>
                        <>{i.label ? <><b>{i.label}</b> </> : null}{i.description}</>
                    </ListGroup.Item>
                    )}
                    </ListGroup>
                    </div>
                    : null}
                    {balanceDue ?
                    <div className={s.balanceDueContainer}>
                        <Alert variant={_selectedPlan.variant || 'info'}>
                            Selection of this plan will result in a balance 
                            due of {numeral(balanceDue).format('$0.00')}. Once 
                            you press the "select plan" button below, 
                            you will be prompted for payment.
                        </Alert>
                    </div>
                    : null}
                </div>
            </Modal>

            <Modal show={showPaymentModal} 
                showFooter={false}
                heading={`Pay Plan Balance`}
                onClose={() => setShowPaymentModal(false)}>
                <div>
                    <CreditCardPaymentForm 
                        amount={isDevelopment ? 1 : balanceDue}
                        chargeType={paymentKey} 
                        groupId={group._id} tripId={trip._id} 
                        data={{ code: codeForPayment }}
                        header={<span>Pay your plan balance here.</span>}
                        onError={onErrorPayment}
                        onSuccess={onSuccessfulPayment} />
                </div>
            </Modal>

        </>
    )
}

export default PricingPlanListItem