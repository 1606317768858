import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Outlet } from 'react-router-dom'
import { Row, Col, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getLocationCount, getLocations } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import GlobalAdminLetterBar from './GlobalAdminLetterBar'
import * as s from './GlobalAdminLocations.scss'

const GlobalAdminLocationList = () => {

    const navigate = useNavigate()
    const params = useParams()
    const letterTab = (params.globalAdminLetter || '').toUpperCase()
    const list = (useSelector(state => state.globalAdmin.locations[`${letterTab || 'None'}`]) || {}).list || []

    return (
        <div>
            <ListGroup className={s.list}>
            {list && list.map(i => 
                <ListGroup.Item key={`global-admin-${i._id}`} action
                    className={s.item}
                    onClick={() => navigate(`/global-admin/lodging/edit/${i._id}`)}>
                    <h4 className={s.locationName}>
                        <div className={s.name}>{i.locName}</div>                        
                        {!i.longitude || !i.latitude ? <span className={'text-danger'} title='No geocoding!'><FontAwesomeIcon name='map-marker' /> <FontAwesomeIcon name='exclamation' /></span> : <span className={'text-success'} title='Geocoding OK'><FontAwesomeIcon name='map-marker' /></span>}
                    </h4>
                    {i.locCity || i.locState ? <p className={s.location}>{i.locCity} {i.locState}</p> : null}
                </ListGroup.Item>
            )}
            </ListGroup>
        </div>
    )
}

export default GlobalAdminLocationList