import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { PUBLIC_URL } from '@severed-links/common.severedlinks-constants'

/* bring in AuthenticatedRoute */
import AuthenticatedRoute from '../components/AuthenticatedRoute'

/* bring in menus */
import BlankMenu from '../menus/BlankMenu'
import PublicMenu from '../menus/PublicMenu'
import MainMenu from '../menus/MainMenu'

/* import components for routing */
import NotFound from '@severed-links/common.not-found'

import RedirectToPublicSite from './Public/RedirectToPublicSite'
import ForgotPassword from './Public/ForgotPassword'
import SignUp from './Public/SignUp'
import Groups from './Groups/Groups'
import Group from './Groups/Group'
import Trips from './Trips/Trips'
import Players from './Player/Players'
import GroupPlayerInviter from './Player/GroupPlayerInviter'
import GroupPlayerProfile from './Player/GroupPlayerProfile'
import GroupPlayerRoundScorecard from './Player/GroupPlayerRoundScorecard'
import GroupPlayerEditor from './Player/GroupPlayerEditor'
import Scorecard from '../routes/Scorecard/Scorecard'
import Trip from './Trips/Trip'
import TripDetails from './Trips/TripDetails'
import NearbyPlayers from './Scorecard/NearbyPlayers'
import TripRecap from './Trips/TripRecap'
import TripTeams from './Trips/TripTeams'
import TripEventViewer from './Trips/TripEventViewer'
import TripScoreboard from './Trips/TripScoreboard'
import TripMedalistScoreboard from './Trips/TripMedalistScoreboard'
import TripRoundScoreboard from './Trips/TripRoundScoreboard'
import TripPhotoGallery from './Trips/TripPhotoGallery'
import TripAdmin from './Trips/Admin/TripAdmin'
import TripPhotoAdmin from './Trips/Admin/TripPhotoAdmin'
import TripRoundsAdmin from './Trips/Admin/TripRoundsAdmin'
import TripRoundAdminEditRound from './Trips/Admin/TripRoundAdminEditRound'
import TripRoundMatchAdmin from './Trips/Admin/TripRoundMatchAdmin'
import TripFoursomesAdmin from './Trips/Admin/TripFoursomesAdmin'
import TripRoundScorecardAdmin from './Trips/Admin/TripRoundScorecardAdmin'
import TripRoundScorecardAdminModal from './Trips/Admin/TripRoundScorecardAdminModal'
import TripProxyAdmin from './Trips/Admin/TripProxyAdmin'
import TripSkinsAdmin from './Trips/Admin/TripSkinsAdmin'
import TripRoundNotesAdmin from './Trips/Admin/TripRoundNotesAdmin'
import TripMessaging from './Trips/Admin/TripMessaging'
import TripLodgingAdmin from './Trips/Admin/TripLodgingAdmin'
import TripPlayerAdmin from './Trips/Admin/TripPlayerAdmin'
import TripPlayerAdminPlayerEditor from './Trips/Admin/TripPlayerAdminPlayerEditor'
import TripBasics from './Trips/Admin/TripBasics'
import TripMvpAwardVoting from './Trips/Admin/TripMvpAwardVoting'
import TripMvpAwardVotingKiosk from './Trips/Admin/TripMvpAwardVotingKiosk'
import TripPresentations from './Trips/Admin/TripPresentations'
import TripSlideShowPresentation from './Trips/Admin/TripSlideShowPresentation'
import TripSlideShow from './Trips/Admin/TripSlideShow'
import TripPayments from './Trips/Admin/TripPayments'
import PaymentSetup from './Trips/Admin/PaymentsComponents/PaymentSetup'
import PlayerPayments from './Trips/Admin/PaymentsComponents/PlayerPayments'
import PlayerPayouts from './Trips/Admin/PaymentsComponents/PlayerPayouts'
import TripRecapEditor from './Trips/Admin/TripRecapEditor'
import TripContacts from './Trips/Admin/TripContacts'
import TripNotesAdmin from './Trips/Admin/TripNotesAdmin'
import TripNotesAdminPrivate from './Trips/Admin/TripNotesAdminPrivate'
import TripNotePrivateEditor from './Trips/Admin/TripNotePrivateEditor'
import TripNotesAdminPublic from './Trips/Admin/TripNotesAdminPublic'
import TripMockAwardsAdmin from './Trips/Admin/TripMockAwardsAdmin'
import TripAdminComments from './Trips/Admin/TripAdminComments'
import TripEventsAdmin from './Trips/Admin/TripEventsAdmin'
import TripEventEditor from './Trips/Admin/TripEventEditor'
import TripEventEditorDetails from './Trips/Admin/TripEventEditorDetails'
import TripEventEditorDate from './Trips/Admin/TripEventEditorDate'
import TripEventEditorMessaging from './Trips/Admin/TripEventEditorMessaging'
import TripEventEditorPollOptions from './Trips/Admin/TripEventEditorPollOptions'
import TripEventEditorRsvps from './Trips/Admin/TripEventEditorRsvps'
import TripEventEditorTaskResponses from './Trips/Admin/TripEventEditorTaskResponses'
import StewartAwardWinners from './Groups/StewartAwardWinners'
import Top10Lists from './Groups/Top10Lists'
import Faq from './Groups/Faq'
import ScorecardModalFromRoute from './Scorecard/ScorecardModalFromRoute'

import GroupAdmin from './Groups/Admin/GroupAdmin'
import LoginInfo from './Groups/Admin/LoginInfo'
import GroupAdminBasicSettings from './Groups/Admin/GroupAdminBasicSettings'
import GroupMessaging from './Groups/Admin/GroupMessaging'
import AddressBook from './Groups/Admin/AddressBook'
import AddressBookEditEntry from './Groups/Admin/AddressBookEditEntry'

import Profile from './Profile/Profile'
import EditProfile from './Profile/EditProfile'
import ChangePassword from './Profile/ChangePassword'
import HelpCenter from './Help/HelpCenter'
import HelpTicketModal from './Help/HelpTicketModal'
import HandicapTracker from './Player/HandicapTracker'
import AccountDeletionPortal from './AccountDeletion/AccountDeletionPortal'

import Login from './Account/Login'
import Logout from './Account/Logout'

import GlobalAdmin from './GlobalAdmin/GlobalAdmin'
import GlobalAdminStatus from './GlobalAdmin/GlobalAdminStatus'
import GlobalAdminLocations from './GlobalAdmin/GlobalAdminLocations'
import GlobalAdminLocationList from './GlobalAdmin/GlobalAdminLocationList'
import GlobalAdminGroups from './GlobalAdmin/GlobalAdminGroups'
import GlobalAdminGroupViewer from './GlobalAdmin/GlobalAdminGroupViewer'
import GlobalAdminPlayers from './GlobalAdmin/GlobalAdminPlayers'
import GlobalAdminPlayerList from './GlobalAdmin/GlobalAdminPlayerList'
import GlobalAdminNewPlayers from './GlobalAdmin/GlobalAdminNewPlayers'
import GlobalAdminGolfCourses from './GlobalAdmin/GlobalAdminGolfCourses'
import GlobalAdminGolfCourseList from './GlobalAdmin/GlobalAdminGolfCourseList'
import GlobalAdminEditGolfCourse from './GlobalAdmin/GlobalAdminEditGolfCourse'
import GlobalAdminEditLocation from './GlobalAdmin/GlobalAdminEditLocation'
import GlobalAdminJobs from './GlobalAdmin/GlobalAdminJobs'
import GlobalAdminJobQueueStatus from './GlobalAdmin/GlobalAdminJobQueueStatus'
import GlobalAdminEditPlayer from './GlobalAdmin/GlobalAdminEditPlayer'
import GlobalAdminSettings from './GlobalAdmin/GlobalAdminSettings'
import GlobalAdminPricingPlans from './GlobalAdmin/GlobalAdminPricingPlans'
import GlobalAdminPushStatus from './GlobalAdmin/GlobalAdminPushStatus'
import GlobalAdminMatchPlayFormats from './GlobalAdmin/GlobalAdminMatchPlayFormats'
import GlobalAdminMatchPlayFormatEditor from './GlobalAdmin/GlobalAdminMatchPlayFormatEditor'
import GlobalAdminTodos from './GlobalAdmin/GlobalAdminTodos'
import GlobalAdminCourseUtilities from './GlobalAdmin/GlobalAdminCourseUtilities'
import GlobalAdminPayments from './GlobalAdmin/GlobalAdminPayments'
import GlobalAdminCourseAuditDetail from './GlobalAdmin/GlobalAdminCourseAuditDetail'
import GlobalAdminUsGolfImporter from './GlobalAdmin/GlobalAdminUsGolfImporter'
import GlobalAdminDevices from './GlobalAdmin/GlobalAdminDevices'
import GlobalAdminDeviceList from './GlobalAdmin/GlobalAdminDeviceList'
import GolfApiIoDataImporter from './GlobalAdmin/GolfApiIoDataImporter'
import GolfApiIoLoadByCity from './GlobalAdmin/GolfApiIoLoadByCity'

export const Empty = () => <div />

export const MenuRoutes = () => {
  const qs = queryString.parse(window.location.search)
  if (qs.hideMenu) return null
  return (
    <Routes>
      <Route path='/groups/*' element={<MainMenu />} />
      <Route path='/group/*' element={<MainMenu />} />
      <Route path='/profile/*' element={<MainMenu />} />
      <Route path='/global-admin/*' element={<MainMenu />} />
      <Route path='*' element={<PublicMenu />} />
    </Routes>
  )
}

export const ContentRoutes = () => {

  return (
    <Routes>
      <Route exact path='/' element={<Empty />} />
      <Route exact path='/public' element={<RedirectToPublicSite />}/>
      <Route path='/login' element={<Login />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/sign-up/:signUpCode' element={<SignUp />} />
      <Route path='/sign-up' element={<SignUp />} />

      <Route path='/groups' element={<AuthenticatedRoute />}>
        <Route path='' element={<Groups />} />
      </Route>

      <Route path='/group/:groupId' element={<AuthenticatedRoute />}>
        <Route path='' element={<Group />}>
        {_groupRoutes}
        </Route>

      </Route>
      <Route path='/profile' element={<AuthenticatedRoute />}>
        <Route exact path='' element={<Profile />}>
          <Route path='edit' element={<EditProfile />} />
          <Route path='change-password' element={<ChangePassword />} />
          <Route path='help-center' element={<HelpCenter />}>
            <Route path='new-issue' element={<HelpTicketModal />} />
            <Route path='ticket/:ticketId' element={<HelpTicketModal />} />
          </Route>
          <Route path='handicap-tracker' element={<HandicapTracker />} />
          <Route path='account-deletion' element={<AccountDeletionPortal />} />
        </Route>
      </Route>
      <Route path='/global-admin' element={<AuthenticatedRoute globalAdminOnly />}>
        <Route exact path='' element={<GlobalAdmin />}>
          <Route path='status' element={<GlobalAdminStatus />} />
          <Route path='groups' element={<GlobalAdminGroups />}></Route>
          <Route path='groups/filter/:globalAdminLetter' element={<GlobalAdminGroups />}>
            <Route path='view/:groupId' element={<GlobalAdminGroupViewer />}>
            {_groupRoutes}
            </Route>
          </Route>

          <Route path='lodging' element={<GlobalAdminLocations />}>
            <Route path='filter/:globalAdminLetter' element={<GlobalAdminLocationList />} />
            <Route path='edit/:locationId' element={<GlobalAdminEditLocation />} />
          </Route>
          <Route path='players' element={<GlobalAdminPlayers />}>
            <Route path='filter/:globalAdminLetter' element={<GlobalAdminPlayerList />} />
            <Route path='edit/:playerId' element={<GlobalAdminEditPlayer />} />
            <Route path='new-players/:newPlayerId/details' element={<GlobalAdminNewPlayers />} />
            <Route path='new-players' element={<GlobalAdminNewPlayers />} />
          </Route>
          <Route path='courses' element={<GlobalAdminGolfCourses />}>
            <Route path='filter/:globalAdminLetter' element={<GlobalAdminGolfCourseList />} />
            <Route path='utilities' element={<GlobalAdminCourseUtilities />}>
              <Route path='' element={<Empty />} />
              <Route path='golf-api-io-importer' element={<GolfApiIoDataImporter />}>
                <Route path='load-by-city' element={<GolfApiIoLoadByCity />} />
              </Route>
              <Route path='us-golf-importer' element={<GlobalAdminUsGolfImporter />} />
              <Route path='missing-geocoding' element={<GlobalAdminCourseAuditDetail menuTab='missing-geocoding' />} />
              <Route path='missing-tee-boxes' element={<GlobalAdminCourseAuditDetail menuTab='missing-tee-boxes' />} />
              <Route path='hole-errors' element={<GlobalAdminCourseAuditDetail menuTab='hole-errors' />} />
            </Route>
            <Route path='edit/:golfCourseId' element={<GlobalAdminEditGolfCourse />} />
          </Route>
          <Route path='pricing-plans' element={<GlobalAdminPricingPlans />} />
          <Route path='jobs' element={<GlobalAdminJobs />} />
          <Route path='jobs/queue/:name' element={<GlobalAdminJobQueueStatus />} />
          <Route path='jobs/queue/:name/status/:status' element={<GlobalAdminJobQueueStatus />} />
          <Route path='push-data' element={<GlobalAdminPushStatus />} />
          <Route path='settings' element={<GlobalAdminSettings />} />
          <Route path='settings/:settingParam' element={<GlobalAdminSettings />} />
          <Route path='play-formats' element={<GlobalAdminMatchPlayFormats />} />
          <Route path='play-format/:formatId' element={<GlobalAdminMatchPlayFormatEditor />} />
          <Route path='todos' element={<GlobalAdminTodos />} />
          <Route path='payments' element={<GlobalAdminPayments />} />
          <Route path='devices' element={<GlobalAdminDevices />} />
          <Route path='devices/page/:currentPageParam' element={<GlobalAdminDevices />} />
        </Route>
      </Route>

      <Route path='*' element={<NotFound useLocation={useLocation} />} />

    </Routes>
  )
}

const _groupRoutes = (
  <>
  <Route path='players' element={<Players />} />
  <Route path='players/filter/:letter' element={<Players />} />
  <Route path='players/invite-players' element={<GroupPlayerInviter />} />
  <Route path='player/:playerId' element={<GroupPlayerProfile />}>
    <Route path='edit' element={<AuthenticatedRoute groupAdminOnly />}>
      <Route path='player-details' element={<GroupPlayerEditor />} />
    </Route>
  </Route>
  <Route path='trip/:tripId/player/:playerId/round/:round/scorecard' element={<GroupPlayerRoundScorecard />} />
  <Route path='trips' element={<Trips />} />
  <Route path='trip/:tripId' element={<Trip />}>
    <Route path='details' element={<TripDetails />} />
    <Route path='recap' element={<TripRecap />} />
    <Route path='teams' element={<TripTeams />} />
    <Route path='scoreboard' element={<TripScoreboard />}>
      <Route path='medalist' element={<TripMedalistScoreboard paginate />} />
      <Route path='round/:round' element={<TripRoundScoreboard />}>
        <Route path='scorecard/foursome/:foursome' element={<ScorecardModalFromRoute />} />
      </Route>
    </Route>
    <Route path='photos' element={<TripPhotoGallery />} />
    <Route path='photos/view/:tripPhotoId' element={<TripPhotoGallery />} />
    <Route path='scorecard' element={<Scorecard />} />
    <Route path='scorecard/round/:round/foursome/:foursome' element={<Scorecard />} />
    <Route path='nearby-players' element={<NearbyPlayers />} />
    <Route path='event/:eventId/view' element={<TripEventViewer />} />
    <Route path='admin' element={<AuthenticatedRoute groupAdminOnly />}>
      <Route path='' element={<TripAdmin />}>
        <Route path='basics' element={<TripBasics />} />
        <Route path='notes' element={<TripNotesAdmin />}>
          <Route path='private' element={<TripNotesAdminPrivate />}>
            <Route path='add-new' element={<TripNotePrivateEditor isNew />} />
            <Route path='edit/:noteId' element={<TripNotePrivateEditor />} />
          </Route>
          <Route path='public' element={<TripNotesAdminPublic />} />
        </Route>
        <Route path='contacts' element={<TripContacts />}>

        </Route>
        <Route path='lodging' element={<TripLodgingAdmin />} />
        <Route path='players' element={<TripPlayerAdmin />} />
        <Route path='player/edit/:playerId' element={<TripPlayerAdminPlayerEditor />} />
        <Route path='rounds' element={<TripRoundsAdmin />} />
        <Route path='rounds/edit/round/:round' element={<TripRoundAdminEditRound />}>
          <Route path='matches' element={<TripRoundMatchAdmin />} />
          <Route path='foursomes' element={<TripFoursomesAdmin />} />
          <Route path='scorecard' element={<TripRoundScorecardAdmin />}>
            <Route path='show' element={<TripRoundScorecardAdminModal />} />
          </Route>
          <Route path='proxies' element={<TripProxyAdmin />} />
          <Route path='notes' element={<TripRoundNotesAdmin />} />
        </Route>
        <Route path='skins' element={<TripSkinsAdmin />} />
        <Route path='payments' element={<TripPayments />}>
          <Route path='setup' element={<PaymentSetup />} />
          <Route path='player-payments' element={<PlayerPayments />} />
          <Route path='player-payouts' element={<PlayerPayouts />} />
        </Route>
        <Route path='events/new/:newEventType' element={<TripEventEditor />}>
          <Route path='' element={<Empty />} />
          <Route path='details' element={<TripEventEditorDetails />} />
        </Route>
        <Route path='events/edit/:editEventId/' element={<TripEventEditor />}>
          <Route path='' element={<Empty />} />
          <Route path='details' element={<TripEventEditorDetails />} />
          <Route path='date' element={<TripEventEditorDate />} />
          <Route path='messaging' element={<TripEventEditorMessaging />} />
          <Route path='poll-options' element={<TripEventEditorPollOptions />} />
          <Route path='rsvps' element={<TripEventEditorRsvps />} />
          <Route path='task-responses' element={<TripEventEditorTaskResponses />} />
        </Route>
        <Route path='events' element={<TripEventsAdmin />} />
        <Route path='photos' element={<TripPhotoAdmin />} />
        <Route path='messaging' element={<TripMessaging />} />
        <Route path='comments' element={<TripAdminComments />} />
        <Route path='presentation' element={<TripPresentations />}>
          <Route path='slide-show' element={<TripSlideShow />} />
          <Route path='award-presentation' element={<TripSlideShowPresentation />} />
        </Route>
        <Route path='mvp-award' element={<TripMvpAwardVoting />} />
        <Route path='mvp-award-kiosk' element={<TripMvpAwardVotingKiosk />} />
        <Route path='recap' element={<TripRecapEditor />} />
        <Route path='mock-awards' element={<TripMockAwardsAdmin />} />
      </Route>
    </Route>
  </Route>
  <Route path='mvp-award' element={<StewartAwardWinners />} />
  <Route path='top-10-lists' element={<Top10Lists />} />
  <Route path='top-10-lists/:listName' element={<Top10Lists />} />
  <Route path='faq' element={<Faq />} />,
  <Route path='admin' element={<AuthenticatedRoute groupAdminOnly />}>
    <Route path='' element={<GroupAdmin />}>
      <Route path='logins' element={<LoginInfo />} />
      <Route path='settings' element={<GroupAdminBasicSettings />} />
      <Route path='messaging' element={<GroupMessaging />} />
      <Route path='faq' element={<Faq isAdmin />} />
      <Route path='address-book' element={<AddressBook />}>
        <Route path='add-new' element={<AddressBookEditEntry isNew />} />
        <Route path='edit/:entryId' element={<AddressBookEditEntry />} />
      </Route>
    </Route>
  </Route>
  </>
)
