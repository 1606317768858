import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Image, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripSlideShowPickViewer.scss'
import { playerAvatarUrl, commonImageUrl } from '@severed-links/common.severedlinks-constants'
import { getComments } from '@severed-links/common.severedlinks-reducers/groups'
import { orderBy, first, isEqual } from 'lodash'

const PlayerIconList = ({ players }) => (
    <div className={s.playerIconList}>
    {players && players.map(p =>
        <div key={`player-icon-list-${p._id}`} className={s.item}>
            <div className={s.content}>
                <Image src={playerAvatarUrl(p.imageUrl, true)} className={s.avatar} />
                <div className={s.name}>{p.firstName} {p.lastName}</div>
            </div>
        </div>
    )}
    </div>
)

const PlayerPick = ({ pick, showPick, onClose }) => (
    <div className={s.playerPickContainer + ' ' + (showPick ? s.showPick : s.hidePick) + ' ' + s[`${pick.data.team.toLowerCase()}Pick`]}>
        <div className={s.titleContainer}>
            <Image src={playerAvatarUrl(pick.imageUrl, true)} className={s.captainAvatar} />
            <h4 className={s.title}>Player Pick by {pick.playerName}</h4>
            <Image src={commonImageUrl(`${pick.data.team.toLowerCase()}Flag`)} className={s.playerPickFlag} />
            <div className={s.closeContainer}><Button size='sm' onClick={() => onClose()} className={s.close}><FontAwesomeIcon name='times' /></Button></div>
        </div>
        <div className={s.playerPickItem}>
            <Image src={playerAvatarUrl(pick.data.imageUrl, false)} className={s.pickedPlayerAvatar} />
            <h3 className={s.pickedPlayerName}>{pick.data.name}</h3>
        </div>
    </div>
)

const MatchTeamPanel = ({ players, team, onClose }) => (
    <div className={s.matchPanel + ' ' + s[`${team.toLowerCase()}Panel`]}>
        <h4 className={s.teamNameContainer}>
            <Image src={commonImageUrl(`${team.toLowerCase()}Flag`)} className={s.matchTeamFlag} />
            <div className={s.teamName}>{team === 'EUR' ? 'Europe' : 'USA'}</div>
        </h4>
        <div className={s.matchContainer}>
            <div className={s.playerAvatars}>
            {players && players.map(p =>
                <Image className={s.playerAvatar} 
                    src={`${playerAvatarUrl(p.imageUrl, true)}`}
                    key={`player-avatar-${p.playerId}`} />
            )}
            </div>
            <div className={s.playerNames}>
            {players && players.map(p =>
                <h5 className={s.playerName} key={`player-match-${p.playerId}`}>{p.name}</h5>
            )}
            </div>
        </div>
    </div>
)

const MatchUpdate = ({ pick, showPick, onClose }) => {
    const players = (pick.data || {}).players || []
    const usa = players.filter(p => p.team === 'USA')
    const eur = players.filter(p => p.team === 'EUR')
    const _player = pick.player || {}
    return (
    <div className={s.matchUpdateContainer + ' ' + (showPick ? s.showPick : s.hidePick)}>
        <div className={s.titleContainer}>
            <Image src={playerAvatarUrl(_player.imageUrl, true)} className={s.captainAvatar} />
            <h4 className={s.title}>{pick.comment}</h4>
            <div className={s.closeContainer}><Button size='sm' onClick={() => onClose()} className={s.close}><FontAwesomeIcon name='times' /></Button></div>
        </div>
        <div className={s.matchPanelContainer}>
            <MatchTeamPanel players={usa} team='USA' />
            <MatchTeamPanel players={eur} team='EUR' />
        </div>
    </div>
    )
}

const TeamPanel = ({ team = 'usa', players = [] }) => (
    <Card className={s.teamPanel + ' ' + s[team]}>
        <Card.Header className={s.header}>
            <Image src={commonImageUrl(`${team}Flag`)} className={s.flag} />
            <div className={s.teamName}>{team === 'usa' ? 'USA' : 'Europe'}</div>
        </Card.Header>
        <Card.Body className={s.body}>
            <PlayerIconList players={players} />
        </Card.Body>
    </Card>
)

const sortComments = (_comments = []) => {
    const panelTypes = ['PickPlayer', 'MatchUpdate']
    return orderBy(_comments.filter(c => panelTypes.includes(c.type)) 
        .map(c => ({ ...c, data: c.data || null })),
        ['entered'], ['desc'])
}

const TripSlideShowPickViewer = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const [pick, setPick] = useState(null)
    const [showPick, setShowPick] = useState(false)
    const [pickTimerClose, setPickTimerClose] = useState(null)
    const usa = trip.usaPlayers || []
    const eur = trip.eurPlayers || []
    const unassigned = trip.unassignedPlayers || []
    const comments = sortComments(trip.comments || [])

    useEffect(() => {
        dispatch(getComments(group._id, trip._id))

        return () => closePanel(true)
    }, [])

    useEffect(() => {
        const newPick = first(comments) || {}
        if (newPick && newPick._id && !pick) {
            setLatestPick(newPick)
        } else if (newPick && newPick._id && !isEqual(newPick, pick)) {
            closePanel(false)
            setLatestPick(newPick)
        }
    }, [comments])

    const closePanel = (isUnmount = false) => {
        if (pickTimerClose) {
            clearTimeout(pickTimerClose)
        }
        if (!isUnmount) {
            setPickTimerClose(null)
            setShowPick(false)
        }
    }

    const setLatestPick = newPick => {

        if (pickTimerClose) {
            clearTimeout(pickTimerClose)
        }

        setPick(newPick)
        setShowPick(true)
        setPickTimerClose(setTimeout(() => closePanel(), 120 * 1000))
    }

    return (
        <div className={s.container}>
            <TeamPanel team='usa' players={usa} />
            <Card className={s.content}>
                <div className={s.pickContainer}>
                {showPick && pick && pick.type === 'PickPlayer' ? <PlayerPick pick={pick} showPick={showPick} onClose={closePanel} /> : null}
                {showPick && pick && pick.type === 'MatchUpdate' ? <MatchUpdate pick={pick} showPick={showPick} onClose={closePanel} /> : null}
                </div>
                {unassigned && unassigned.length > 0 ?
                <div className={s.unassignedPlayersContainer}>
                    <PlayerIconList players={unassigned} />
                </div>
                : null}
            </Card>
            <TeamPanel team='eur' players={eur} />
        </div>
    )
}

export default TripSlideShowPickViewer