import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminDevicePagination.scss'
import Pagination from '@severed-links/common.pagination'
import { setGlobalAdminState } from '@severed-links/common.severedlinks-reducers/globalAdmin'

const GlobalAdminDevicePagination = (props = {}) => {

    return (
        <div className={s.container}>
            <Pagination {...props} />
        </div>
    )
}

export default GlobalAdminDevicePagination
