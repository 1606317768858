import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Outlet } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Badge from '@severed-links/common.badge'
import * as s from './GlobalAdminGolfCourses.scss'
import { keysIn } from 'lodash'

const GlobalAdminGolfCourseListItem = ({ _id, golfCourseLocationId, courseName, longitude, latitude, city, state, isClosed = false, useLocationForLink = false, showErrors = false, ..._props }) => {

    const navigate = useNavigate()
    const linkField = useLocationForLink ? golfCourseLocationId : _id
    const errorFields = showErrors ? Object.assign({}, ...['hasRatingError','hasSlopeError','hasHoleError','hasParError','hasYardsError','hasHcpError'].map(_key => ({
        [_key]: _props[_key] || false,
    }))) : {}
    return (
        <ListGroup.Item key={`global-admin-${_id}`} action
            className={s.item}
            onClick={linkField ? () => navigate(`/global-admin/courses/edit/${linkField}`) : null}>
            <h4 className={s.courseName}>
                <div className={s.name}>{courseName}</div>
                {showErrors ?
                <>
                {keysIn(errorFields || {}).filter(_key => errorFields[_key]).map(_key =>
                    <Badge variant='danger' className={s.badge} key={`golf-course-error-badge-${_key}`}>{_key.replace('has', '').replace('Error', '')}</Badge>
                )}
                </>
                : null}
                {isClosed ? <Badge className={s.badge} bg='danger'>Course closed</Badge> : null}
                {!longitude || !latitude ?
                    <div className={[s.icon,s.hasNoGeo].join(' ')} title='No geocoding!'>
                        <FontAwesomeIcon name='map-marker' />
                        <FontAwesomeIcon name='exclamation' className={s.exclamation} />
                    </div>
                : 
                    <div className={[s.icon,s.hasGeo].join(' ')} title='Geocoding OK'>
                        <FontAwesomeIcon name='map-marker' />
                    </div>
                }
            </h4>
            <p className={s.location}>{city} {state}</p>
        </ListGroup.Item>
    )
}
export default GlobalAdminGolfCourseListItem