import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import Nav from 'react-bootstrap/Nav'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import Badge from '@severed-links/common.badge'
import Button from '@severed-links/common.button'
import Modal from '@severed-links/common.modal'
import TextInput from '@severed-links/common.text-input'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GolfApiIoLoadByCity.scss'
import { getCitiesToSync, runClubSearch } from '@severed-links/common.severedlinks-reducers/golfApiIo'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { first, pick } from 'lodash'

const GolfApiIoLoadByCity = () => {

    const dispatch = useDispatch()
    const { _id: userId } = useSelector(state => state.account)
    const { isFetching, citiesToSync, citiesToSyncStates } = useSelector(state => state.golfApiIo)
    const [activeState, setActiveState] = useState(null)
    const [citySearch, setCitySearch] = useState({ show: false, city: '', state: '', country: 'USA' })

    useEffect(() => {
        dispatch(getCitiesToSync())
    }, [])

    useEffect(() => {
        if (!activeState && citiesToSyncStates?.length) {
            setActiveState(first(citiesToSyncStates).abbreviation)
        }
    }, [activeState, citiesToSyncStates?.length])

    const _handleStateSelect = _key => {
        setActiveState(_key)
    }

    const _handleNewSearch = () => {
        setCitySearch({ city: '', state: '', country: 'USA', show: true })
    }

    const _handleRunSearch = () => {
        dispatch(runClubSearch(pick(citySearch, ['city', 'state', 'country'])))
        .then(action => {
            dispatch(createNotification(action.payload))
            console.log(action.payload)
            setCitySearch({ ...citySearch, show: false })
            if (citySearch.state) {
                setActiveState(citySearch.state.toUpperCase())
            }
        })

    }

    return (
        <div className={s.container}>

            <Nav className={s.nav} variant='pills' activeKey={activeState} onSelect={_handleStateSelect}>
            {citiesToSyncStates && citiesToSyncStates.map(i =>
                <Nav.Item className={s.item} key={`state-pill-${i.abbreviation}`}><Nav.Link className={s.link + (i.abbreviation === activeState ? ` ${s.active}` : ``)} eventKey={i.abbreviation}>{i.abbreviation}</Nav.Link></Nav.Item>
            )}
            <div>
                <Button clear icon='plus' size='sm' onClick={_handleNewSearch} />
            </div>
            </Nav>
            
            <ListGroup className={s.list}>
            {activeState && citiesToSync && citiesToSync.filter(i => i.state === activeState).map(i =>
                <ListGroup.Item className={s.item} key={`city-item-${i._id}`}>
                    <FontAwesomeIcon name='map-marker' className={s.icon} />
                    <div className={s.content}>
                        <div className={s.city}>{i.city}</div>
                        <div className={s.state}>{i.stateName || i.state}</div>
                    </div>
                    <Badge variant={i.golfApiCount ? 'primary' : 'secondary'}>{i.golfApiCount || 0}</Badge>
                    <FontAwesomeIcon name='chevron-right' className={s.clickIcon} />
                </ListGroup.Item>
            )}
            {!isFetching && !citiesToSync?.length ?
            <ListGroup.Item>No cites located</ListGroup.Item>
            : null}
            </ListGroup>

            <Modal show={citySearch.show} heading={'Run City Search'}
                onClose={() => setCitySearch({ ...citySearch, show: false })}
                actionButtonIcon='search'
                actionButtonText='run search'
                actionButtonDisabled={!citySearch.city || !citySearch.state || !citySearch.country}
                actionButtonStyle='primary'
                actionButtonOnClick={() => _handleRunSearch()}>
                <div className={s.citySearchContainer}>

                    <FormGroup className={s.formGroup}>
                        <TextInput value={citySearch.city || ''}
                            useFloatingLabels label='City'
                            onChange={_text => setCitySearch({ ...citySearch, city: _text })} />
                    </FormGroup>

                    <FormGroup className={s.formGroup}>
                        <TextInput value={citySearch.state || ''}
                            useFloatingLabels label='State (2-character abbreviation)'
                            onChange={_text => setCitySearch({ ...citySearch, state: _text })} />

                    </FormGroup>

                    <FormGroup className={s.formGroup}>
                        <TextInput value={citySearch.country || ''}
                            useFloatingLabels label='Country'
                            onChange={_text => setCitySearch({ ...citySearch, country: _text })} />

                    </FormGroup>
                </div>
            </Modal>
        </div>
    )
}

export default GolfApiIoLoadByCity
