import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormGroup, FormControl, InputGroup, ProgressBar, FormLabel, FloatingLabel } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import scriptLoader from 'react-async-script-loader'
import * as s from './PasswordFormControl.scss'
import { trim } from 'lodash'

const PasswordFormControl = ({ 
    isScriptLoaded, isScriptLoadSucceed, labelText = '', onChange, 
    autoFocus = false, size = null
}) => {

    const [password,setPassword] = useState('')
    const [passwordStrength,setPasswordStrength] = useState({})
    const [progressBar,setProgressBar] = useState({ length: 0, style: 'danger' })
    const [passwordIsValid,setPasswordIsValid] = useState(false)
    const _data = { password, passwordStrength, progressBar, passwordIsValid }

    useEffect(() => {
        onChange({ password, passwordStrength, progressBar, passwordIsValid })
    }, [password])

    const progressBarLength = (passwordLength, passwordStrengthScore) => 
        parseFloat(Math.min(passwordLength || 0, 15) +
            ((passwordStrengthScore || 0.00) * 25)) / 1.15

    const handleChange = e => {
        const _password = trim(e.target.value)
        const _passwordStrength = window.zxcvbn(_password)
        const _minPasswordScore = 40
        var style = ''
        const score = progressBarLength((_password || '').length, _passwordStrength.score || 0)
        const length = score
        switch (true) {
            case score >= _minPasswordScore: 
                style = 'success'
                break
            case score < _minPasswordScore && score > _minPasswordScore / 2.0: 
                style = 'warning'
                break
            default: 
                style = 'danger'
                break
        }
        setPasswordStrength(_passwordStrength)
        setProgressBar({ length, style })
        setPasswordIsValid(length >= _minPasswordScore)
        setPassword(_password)
    }

    if (!isScriptLoaded || !isScriptLoadSucceed) return null
    return (
        <FormGroup className={s.container}>
            {labelText ? <FormLabel className={s.label}>{labelText}</FormLabel> : null}
            <InputGroup className={s.inputGroup} size={size}>
                <InputGroup.Text><FontAwesomeIcon name='lock' /></InputGroup.Text>
                <FormControl autoFocus autoComplete='off' type='text' 
                    className={s.textbox}
                    name='password' 
                    value={password} 
                    autoFocus={autoFocus}
                    required isValid={passwordIsValid}
                    isInvalid={!passwordIsValid}
                    onChange={e => handleChange(e)} />
            </InputGroup>
            <ProgressBar className={s.progressBar} 
                now={progressBar.length} 
                variant={progressBar.style} />
        </FormGroup>
    )
}

export default (scriptLoader([
    'https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js',
])(PasswordFormControl))
