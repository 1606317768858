import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Breadcrumb } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './MiniGroupHeader.scss'

const MiniGroupHeader = ({ group, show = false }) => {

    const navigate = useNavigate()
    const hasFaq = (group.faq || []).length > 0

    const playersRedirect = () => {
        const _path = `/group/${group._id}/players${(group.players && group.players.length > 25 ? '/filter/' + group.playerFilterLetter : '')}`
        navigate(_path)            
    }

    const _menuItems = [
        { title: 'Home', icon: 'home', path: `/groups`, onClick: () => navigate('/groups') },
        group ? { title: 'Group name', icon: null, heading: group.name, onClick: null } : null,
        group ? { title: 'Players', icon: 'users', heading: null, onClick: () => playersRedirect() } : null,
        group ? { title: 'Trips', icon: 'suitcase', heading: null, onClick: () => navigate(`/group/${group._id}/trips`) } : null,
        group ? { title: group.mvpAwardName || 'MVP', icon: 'trophy', heading: null, onClick: () => navigate(`/group/${group._id}/mvp-award`) } : null,
        group ? { title: 'Lists', icon: 'list', heading: null, onClick: () => navigate(`/group/${group._id}/top-10-lists`) } : null,
        group ? { title: 'FAQ', icon: 'question', heading: null, onClick: () => navigate(`/group/${group._id}/faq`) } : null,
        group && group.role && group.role.isAdmin ? { title: 'Settings', icon: 'cog', heading: null, onClick: () => navigate(`/group/${group._id}/admin`) } : null,
    ].filter(i => i)

    return (
        <div className={s.container + (show ? ' ' + s.show : '')}>
            <Breadcrumb className={s.breadcrumbContainer}>
            {_menuItems && _menuItems.map(i =>
                <Breadcrumb.Item onClick={i.onClick} className={`${s.breadcrumbItem}${i.heading ? ' d-none d-sm-inline-block' : ''}`} key={`mini-group-header-${i.title}`}>{i.icon ? <FontAwesomeIcon name={i.icon} /> : null}{i.heading ? <span>{i.heading}</span> : null}</Breadcrumb.Item>
            )}
            </Breadcrumb>
        </div>
    )
}

export default MiniGroupHeader