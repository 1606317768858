// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FloatHeaderWithButtons__container___pa7EK{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.FloatHeaderWithButtons__container___pa7EK .FloatHeaderWithButtons__titleContainer___RwdqI{flex:1 1 auto;margin-left:0px;margin-top:0px;margin-bottom:0px}.FloatHeaderWithButtons__container___pa7EK .FloatHeaderWithButtons__buttonContainer___JN2LX .FloatHeaderWithButtons__button___y8WED{margin-left:20px}`, "",{"version":3,"sources":["webpack://./src/components/FloatHeaderWithButtons.scss"],"names":[],"mappings":"AAAA,2CACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,2FACI,aAAA,CACA,eAAA,CACA,cAAA,CACA,iBAAA,CAIA,oIACI,gBAAA","sourcesContent":[".container {\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: space-between;\n    align-items: center;\n\n    .titleContainer {\n        flex: 1 1 auto;\n        margin-left: 0px;\n        margin-top: 0px;\n        margin-bottom: 0px;\n    }\n\n    .buttonContainer {\n        .button {\n            margin-left: 20px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `FloatHeaderWithButtons__container___pa7EK`;
export var titleContainer = `FloatHeaderWithButtons__titleContainer___RwdqI`;
export var buttonContainer = `FloatHeaderWithButtons__buttonContainer___JN2LX`;
export var button = `FloatHeaderWithButtons__button___y8WED`;
export default ___CSS_LOADER_EXPORT___;
