import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { } from 'react-router'
import { Row, Col, Image, FormControl, FormGroup, FormLabel, 
    InputGroup, ListGroup, Button, Alert, Card } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripPhotoGallery.scss'
import Modal from '@severed-links/common.modal'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { createNewTripPhoto } from '@severed-links/common.severedlinks-reducers/groups'
import Dropzone from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import { img, getBase64 } from '@severed-links/common.severedlinks-constants'
import Spinner from '@severed-links/common.spinner'
import pluralize from 'pluralize'
import { updateUploadPhoto, setPhotoUploaderState } from '@severed-links/common.severedlinks-reducers/trips'
import { update } from 'lodash'

const UploaderInstructions = () => (
    <div>
        <h4><FontAwesomeIcon name='upload' /> Upload Photos</h4>
        <p>Drag and drop or tap this area to import photos for upload to this trip's gallery.</p>
    </div>
)

const RejectedFilesAlert = ({ rejectedCount = 0 }) => (
    <div>
    {rejectedCount ? 
        <Alert>
            <h4>Files rejected!</h4>
            <p>
                There {rejectedCount !== 1 ? 'were' : 'was'}
                {' '}
                {pluralize('file', rejectedCount, true)} rejected because of an unsupported file format (images only: jpeg, png).
            </p>
        </Alert>
    : null}
    </div>
)

const UploadFileCard = ({ file, index = -1, onChangeCaption = null }) => {
    const f = file || {}
    const _showSpinner = f.isStarted || f.isDone || f.uploadError
    const _disableCaption = f.isStarted || f.isDone
    const _variantClass = f.isStarted && !f.isComplete ? s.isUploading : f.uploadError ? s.uploadError : s.isDone
    const _spinnerIcon = f.isStarted && !f.isComplete ? 'circle-notch' : f.uploadError ? 'times' : 'check'
    return (
        <Card className={s.uploaderCard} style={{ textAlign: 'center' }}>
            <Card.Header>Photo #{index + 1}</Card.Header>
            {_showSpinner ? 
            <div className={s.spinnerContainer + ' ' + _variantClass}>
                <FontAwesomeIcon name={_spinnerIcon} size='3x' spin={f.isStarted && !f.isComplete} />
                {f.uploadError ? <div className={s.uploadResponse}>{f.uploadResponse}</div> : null}
            </div> 
            : null}
            {f.preview ? <div className={s.cardImgContainer} style={{ backgroundImage: `url(${f.preview})` }} /> : null}
            <Card.Body className={s.cardBody}>
                <FormControl as='textarea'
                    rows={2}
                    className={s.caption} 
                    name={`caption-index-${index}`}
                    disabled={_disableCaption} 
                    autoFocus={index === 0} 
                    placeholder={'[Enter a caption for the photo...]'} 
                    value={f.caption || ''} 
                    onChange={onChangeCaption ? e => onChangeCaption(e.target.value, index) : null} />
            </Card.Body>
        </Card>

    )
}

const UploadButton = ({ onClick = null, disabled = false, fileCount = 0 }) => (
    <FormGroup style={{ textAlign: 'right' }}>
        <Button variant='light' onClick={onClick ? () => onClick() : null} 
            disabled={disabled} 
            variant='primary'>
            <FontAwesomeIcon name='upload' />
            {' '}
            upload {pluralize('photo', fileCount, true)}</Button>
    </FormGroup>
)

const DroppedFileCounts = ({ acceptedCount = 0, rejectedCount = 0 }) => (
    <div>
        <h4>
            {acceptedCount ? <FontAwesomeIcon name='thumbs-up' /> : null}
            {' '} 
            {rejectedCount ? <FontAwesomeIcon name='thumbs-down' /> : null}
            {' '} 
            {pluralize('file', acceptedCount + rejectedCount, false)} dropped
        </h4>
        <p>{`Accepted: ${acceptedCount}, rejected: ${rejectedCount}.`}</p>
    </div>
)

const DropAcceptMessage = () => (
    <div>
        <h4><FontAwesomeIcon name='thumbs-up' /> File(s) Accepted</h4>
        <p>Drop your file(s) here!</p>
    </div>
)

const DropRejectErrorMessage = () => (
    <div>
        <h4><FontAwesomeIcon name='thumbs-down' /> File(s) Not Allowed</h4>
        <p>This error is occurring because you are probably trying to drop an unauthorized file type (perhaps not an image/photo?).</p>
    </div>
)

const TripPhotoUploader = ({ groupId = null, tripId = null, show = false, onClose = () => void(0) }) => {

    if (!groupId || !tripId) return null

    const dispatch = useDispatch()
    const files = useSelector(state => state.trips.photoGalleryUploader.files)
    const rejectedFiles = useSelector(state => state.trips.photoGalleryUploader.rejectedFiles)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        return () => disposeFiles(true)
    }, [])

    useEffect(() => onFileChanged(), [files])

    const disposeFiles = (isUnmount = false) => {
        var loadedFiles = [...files, ...rejectedFiles]
        loadedFiles.forEach(f => { window.URL.revokeObjectURL(f.preview) })
        dispatch(setPhotoUploaderState({ files: [], rejectedFiles: [] }))
        setLoading(false)
    }

    const onUploadSuccess = (e, uploadKey) => {
        const _fileIndex = files.findIndex(i => i.uploadKey === uploadKey)
        const status = e.target.status
        const response = JSON.parse(e.target.response)
        const uploadError = status !== 200
        const uploadResponse = ((response || {}).error || {}).message || null
        if (_fileIndex > -1) {
            dispatch(updateUploadPhoto(_fileIndex, { isComplete: true, isDone: !uploadError, uploadResponse, uploadError }))
            dispatch(createNewTripPhoto(groupId, tripId, files[_fileIndex].caption, files[_fileIndex].imageUrl))
            if (!files.some(i => i.isStarted && !i.isDone)) {
                setTimeout(() => onCloseUploader(), 2000)
            }
        }
    }

    const onUploadError = (e, uploadKey) => {
        const _fileIndex = files.findIndex(i => i.uploadKey === uploadKey)
        if (_fileIndex > -1) {
            dispatch(updateUploadPhoto(_fileIndex, { isComplete: true, isDone: false }))
        }
    }

    const onUploadProgress = e => {
    }

    const mapDroppedFile = (f = {}) => {
        const fileName = uuidv4().toString()
        const publicId = img.CloudinaryTripPhotoPublicId(tripId, fileName)
        var formData = new FormData()
        formData.append('public_id', publicId)
        formData.append('upload_preset', img.CloudinaryTripPhotoUploadPreset)
        formData.append('file', f)
        return { ...f, 
            name: f.name, imageUrl: fileName, size: f.size, caption: '', fileContent: '', 
            formData, progress: 0.0, tripId, publicId,
            isReady: false, isStarted: false, isComplete: false, isDone: false, preview: null, 
            uploadKey: fileName, rawFile: f, uploadError: false, uploadResponse: null,
        }
    }

    const loadNextPhotoPreview = () => {
        const _fileIndex = files.findIndex(f => !f.preview)
        getBase64(files[_fileIndex].rawFile)
        .then(data => dispatch(updateUploadPhoto(_fileIndex, { preview: data })))
    }

    const uploadNextPhoto = _uploadFile => {
        if (_uploadFile) {
            const { formData, uploadKey } = _uploadFile
            const _fileIndex = files.findIndex(i => i.uploadKey === uploadKey)
            if (_fileIndex > -1) {
                dispatch(updateUploadPhoto(_fileIndex, { isReady: true, isStarted: true }))
                img.CloudinaryImageUploader(formData, img.CloudinaryImageUploadUrl, e => onUploadSuccess(e, uploadKey), onUploadProgress, onUploadError)
            }
        }
    }

    const onFileChanged = () => {
        if (files.length) {
            // first check for previ{ews needed
            if (files.some(i => !i.preview)) {
                loadNextPhotoPreview()
            } else {
                // setLoading false once all are loading
                setLoading(false)
                // now check for some files isReady but not isStarted
                const _uploadFile = files.find(i => i.isReady && !i.isStarted)
                if (_uploadFile) {
                    uploadNextPhoto(_uploadFile)
                }
            }
        }
    }

    const onDrop = (acceptedFiles, rejectedFiles) => {
        setLoading(true)
        dispatch(setPhotoUploaderState({ files: acceptedFiles.map(f => mapDroppedFile(f)), rejectedFiles }))
    }

    const startUpload = () => dispatch(setPhotoUploaderState({ files: files.map(f => ({ ...f, isReady: true, isStarted: false, isComplete: false, isDone: false })) }))

    const onCloseUploader = () => {
        disposeFiles()
        if (onClose) onClose()
    }

    const updateCaption = (_newCaption, _index) => {
        dispatch(updateUploadPhoto(_index, { caption: _newCaption }))
    }

    return (
        <Modal show={show} showFooter={false}
            className={s.photoGalleryModal}
            heading='Upload Photos to Trip Photo Gallery' 
            enforceFocus={false}
            onClose={() => onCloseUploader()}>
            <div>
                {!files || files.length === 0 ?
                <div>
                    <FormGroup>
                        <Dropzone multiple 
                            accept={{ ['image/*']: ['image/jpeg','image/jpg','image/gif','image/png'] }}
                            className={s.dropzone}
                            activeClassName={s.dropzoneActive}
                            acceptClassName={s.dropzoneAccept}
                            rejectClassName={s.dropzoneReject}
                            disabledClassName={s.dropzoneDisabled}
                            onDrop={(acc, rej) => onDrop(acc, rej)}
                            inputProps={{ className: 'form-control' }}>
                            {({getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles, rejectedFiles}) => {
                                return (
                                    <div {...getRootProps()} className={s.content}>
                                        <input {...getInputProps()} />
                                        {isDragActive && !isDragReject ?
                                            <DropAcceptMessage />
                                        : 
                                        isDragActive && isDragReject ? 
                                            <DropRejectErrorMessage />
                                        :
                                        (acceptedFiles || []).length > 0 || (rejectedFiles || []).length > 0 ?
                                            <DroppedFileCounts acceptedCount={(acceptedFiles || []).length}
                                                rejectedCount={(rejectedFiles || []).length} />
                                        : 
                                        <UploaderInstructions />
                                        }
                                    </div>
                                )
                            }}
                        </Dropzone>
                    </FormGroup>
                </div>
                :
                <div>
                    <h4>Fill out the {pluralize('caption', files.length, false)} and press 'upload'</h4>
                    <div>{isLoading ? <Spinner size='2x' /> : null}</div>
                    <RejectedFilesAlert rejectedCount={rejectedFiles.length} />
                    <Row>
                    {!isLoading && files && files.map((f, index) => 
                        <Col sm={6} xs={12} key={`file-to-upload-${f.uploadKey}`} className={s.uploaderFileContainer}>
                            <UploadFileCard file={f} index={index} onChangeCaption={(_newCaption, _index) => updateCaption(_newCaption, _index)} />
                        </Col>
                    )}
                    </Row>
                    <UploadButton fileCount={(files || []).length}
                        disabled={files.length === 0 || files.some(f => !f.caption || f.uploadError) || files.every(f => f.isDone)}
                        onClick={() => startUpload()} />
                </div>
                }
            </div>
        </Modal>
    )
}

export default TripPhotoUploader