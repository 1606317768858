import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Scorecard.scss'
import { range } from 'lodash'

const ScorecardGolfHole = ({ hole = {}, isTeam = false, isStableford = false, teamSize = 1, holeData = {}, children = null, trip = {} }) => {

    const _strokes = (isTeam ? holeData.teamStrokes : holeData.strokes) || 0
    const _isStablefordRow = hole.hole === 'Stableford'
    const _isHcpRow = hole.hole === 'Hcp'
    return (
        <td className={s.hole} colSpan={isTeam ? teamSize : 1}>
            <div className={s.holeContainer}
                style={(holeData && holeData.won) || _isStablefordRow ? { color: trip[`${(holeData.playerTeam || '').toLowerCase()}TextColor`], backgroundColor: trip[`${(holeData.playerTeam || '').toLowerCase()}BackgroundColor`] } : null}>
                <div className={s.grossScore}>{holeData && holeData.grossScore > 0 ? holeData.grossScore : ' '}</div>
                {_strokes ? 
                    <div className={s.strokeIcon + (_strokes === 1 ? ' ' + s.singleStroke : '')}>
                        {range(1, _strokes + 1, 1).map(i => <FontAwesomeIcon name={isTeam ? 'square' : 'circle'} key={`stroke-${holeData._id}-${holeData.hole}-${holeData.playerID}-${i}`} />)}
                    </div>
                : null}
                {holeData && holeData.isSkin ? 
                    <div className={s.skin} title='Skin!'><FontAwesomeIcon name='flag' /></div>
                : null}
                {isStableford && holeData && holeData.stablefordPoints ? 
                    <div className={s.stablefordPoints + ' ' + s[(holeData.playerTeam || '').toLowerCase()]} title='Stableford points'>{holeData.stablefordPoints}</div>
                : null}
                {!holeData || holeData.grossScore === 0 ? '\u00a0' : null}
            </div>
        </td>
    )
}

export default ScorecardGolfHole