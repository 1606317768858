import React, { useEffect } from 'react'
import { Button, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import * as s from './TripRoundMatchAdminRemovePlayer.scss'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'

const TripRoundMatchAdminRemovePlayer = ({ player = {}, show = false, onDelete, onClose }) => {

    const _player = (player || {}).player || {}
    const _team = (player || {}).team
    const _match = (player || {}).match
    return (
        <Modal show={show}
            heading={`Remove ${_team} Player from match #${_match}`}
            enforceFocus={false} showFooter
            actionButtonStyle='danger'
            actionButtonIcon='trash'
            actionButtonText='remove player from match'
            actionButtonOnClick={() => onDelete(player)}
            onClose={() => onClose()}>
            {_player ? 
            <div className={s.container}>
                <Image src={playerAvatarUrl(_player.imageUrl)} roundedCircle className={s.avatar} />
                <h4 className={s.name}>{_player.playerName}</h4>
                <p className={s.warningText}>NOTE: this action will remove any saved hole scores for this player in this match.</p>
            </div>
            : null}
        </Modal>
    )
}

export default TripRoundMatchAdminRemovePlayer