import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminGolfCourses.scss'
import GlobalAdminGolfCourseListItem from './GlobalAdminGolfCourseListItem'

const GlobalAdminCourseAuditDetail = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const { icon, name, path, badgeCount, fieldName, list } = useOutletContext() || {}

    useEffect(() => {
    }, [])

    return (
        <div className={s.container}>
            <ListGroup className={s.list}>
            {list && list.map(i =>
            <GlobalAdminGolfCourseListItem {...i} useLocationForLink showErrors key={`global-admin-golf-course-list-item-${fieldName}-${i._id}`} />
            )}
            {!list || !list.length ?
            <ListGroup.Item className={s.item}>No courses located.</ListGroup.Item> : null}
            </ListGroup>
        </div>
    )
}

export default GlobalAdminCourseAuditDetail
