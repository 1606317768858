import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './DeleteGroup.scss'
import Confirm from '@severed-links/common.confirm'
import { deleteGroup } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const DeleteGroup = ({ group }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onDeleteGroup = () => {
        dispatch(deleteGroup(group._id))
        .then(action => {
            dispatch(createNotification(action.payload))
            navigate('/groups')
        })
    }

    return (
        <div className={s.container}>
            <Confirm title={'Delete Group'}
                onConfirm={() => onDeleteGroup()}
                confirmText='delete group'
                body={<div><p>Are you sure you want to delete this group?</p></div>}
                variant='danger' buttonIcon='trash' buttonText='delete group' />
        </div>
    )
}

export default DeleteGroup