// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripLodging__container___XqBrR{margin-bottom:20px;height:calc(100% - 20px)}@media screen and (max-width: 575px){.TripLodging__container___XqBrR{min-height:auto}}.TripLodging__container___XqBrR .TripLodging__header___do1Hh{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.TripLodging__container___XqBrR .TripLodging__header___do1Hh .TripLodging__icon___XVUCc{flex:0 0 0;margin-right:1rem}.TripLodging__container___XqBrR .TripLodging__header___do1Hh .TripLodging__title___ESuZT{flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/TripLodging.scss"],"names":[],"mappings":"AAEA,gCACI,kBAAA,CACA,wBAAA,CACA,qCAHJ,gCAIQ,eAAA,CAAA,CAGJ,6DACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,wFACI,UAAA,CACA,iBAAA,CAGJ,yFACI,aAAA","sourcesContent":["@use '../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n    margin-bottom: 20px;\n    height: calc(100% - 20px);\n    @media screen and (max-width: breakpoints.$screen-xs-max) {\n        min-height: auto;\n    }\n\n    .header {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n\n        .icon {\n            flex: 0 0 0;\n            margin-right: 1rem;\n        }\n\n        .title {\n            flex: 1 1 auto;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripLodging__container___XqBrR`;
export var header = `TripLodging__header___do1Hh`;
export var icon = `TripLodging__icon___XVUCc`;
export var title = `TripLodging__title___ESuZT`;
export default ___CSS_LOADER_EXPORT___;
