import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import StepZilla from 'react-stepzilla'
import { resetForgotPassword, setUserInfo,
    checkEmail, checkCellPhone, requestResetCode, verifyResetCode,
    requestPasswordChange } from '@severed-links/common.severedlinks-reducers/forgotPassword'
import { usePrevious } from '../../hooks/hooks'

import './ProgressTracker.scss'
import * as s from './ForgotPassword.scss'

import ForgotPasswordStep1 from './ForgotPasswordStep1'
import ForgotPasswordStep2 from './ForgotPasswordStep2'
import ForgotPasswordEnterCode from './ForgotPasswordEnterCode'
import ForgotPasswordEnterNew from './ForgotPasswordEnterNew'
import ForgotPasswordConfirmation from './ForgotPasswordConfirmation'

const ForgotPassword = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const fp = useSelector(state => state.forgotPassword)
    const previousStep = usePrevious(fp.currentStep)

    useEffect(() => {
        dispatch(resetForgotPassword())
    }, [])
    useEffect(() => {
        if (currentStep < previousStep) {
            switch (currentStep) {
                case 0:
                    dispatch(setUserInfo({ sendBy: '' }))
                case 1:
                    dispatch(setUserInfo({ email: '', cellPhone: '', emailIsValid: false, cellPhoneIsValid: false, tokenRequested: false, tokenSent: false }))
                case 2:
                    dispatch(setUserInfo({ enteredToken: '', enteredTokenIsValid: false, tokenError: false, tokenErrorMessage: '' }))
                case 3:
                    dispatch(setUserInfo({ verificationCode: null, passwordIsValid: false, verificationCodeError: false, verificationCodeErrorMessage: '' }))
                case 4:
                    dispatch(setUserInfo({ password: '', passwordStrength: {}, passwordIsValid: false, passwordIsReset: false, passwordResetError: false, passwordResetErrorMessage: null }))
                default:
                    break
            }
        }
    }, [fp.currentStep])

    const { sendBy, currentStep } = fp
    const steps = [
        { name: 'Send Method', component: <ForgotPasswordStep1 /> },
        { name: sendBy || 'Number', component: <ForgotPasswordStep2 checkEmail={checkEmail} checkCellPhone={checkCellPhone} /> },
        { name: 'Enter Code', component: <ForgotPasswordEnterCode requestResetCode={requestResetCode} setUserInfo={setUserInfo} /> },
        { name: 'Enter Password', component: <ForgotPasswordEnterNew verifyResetCode={verifyResetCode} setUserInfo={setUserInfo} />},
        { name: 'Confirmation', component: <ForgotPasswordConfirmation requestPasswordChange={requestPasswordChange} />}
    ]
    return (
        <Row>
            <Col xs={{ span: 12 }} sm={{ offset: 1, span: 10 }} md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
                <h2>Reset your password</h2>
                <div className={s.forgotPasswordContainer}>
                    <div className={`${s.stepProgress} step-progress`}>
                        <StepZilla
                            steps={steps}
                            preventEnterSubmission={false}
                            nextTextOnFinalActionStep={'Reset'}
                            onStepChange={currentStep => dispatch(setUserInfo({ currentStep }))}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default ForgotPassword
