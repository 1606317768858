import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation, Outlet, useOutletContext, Link } from 'react-router-dom'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Button from '@severed-links/common.button'
import Confirm from '@severed-links/common.confirm'
import TextInput from '@severed-links/common.text-input'
import FormCheck from 'react-bootstrap/FormCheck'
import Datetime from 'react-datetime'
import * as s from './TripNotePrivateEditor.scss'
import moment from 'moment-timezone'
import { create, maxBy, trim } from 'lodash'
import validator from 'validator'
import { saveTripAdminNote, deleteTripAdminNote } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { coolTime } from '@severed-links/common.severedlinks-constants'
import PlayerListItem from '../../Player/PlayerListItem'

const TripNotePrivateEditor = ({ isNew = false }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const _tripContext = useOutletContext()
    const { group, groupId, trip, tripId, isGlobalAdmin, isGlobalAdminPath } = _tripContext
    const playerId = useSelector(state => state.account._id)
    const adminNotes = trip?.adminNotes || []
    const noteId = params.noteId || null
    const _adminNote = adminNotes.find(i => i._id === noteId) || {}
    const [_note, setNote] = useState({})
    const _blankNote = { _id: null, groupId, tripId, playerId, note: '', hasReminder: false, reminderDate: moment().startOf('day').toDate() }
    const { _id, note, hasReminder, reminderDate, updatedBy, updatedAt } = _note
    const _isValid = !validator.isEmpty(note || '') && (!hasReminder || (reminderDate && moment(reminderDate).isValid()))

    useEffect(() => {
        setNote({ ..._blankNote, ...({ ..._adminNote, ...(_adminNote.hasReminder && _adminNote.reminderDate && moment(_adminNote.reminderDate).isValid() ? { reminderDate: moment(_adminNote.reminderDate).toDate() } : {}) }) })
    }, [isNew, _adminNote._id])

    const handleBackButtonClick = () => {
        navigate(`/group/${groupId}/trip/${tripId}/admin/notes/private`)
    }

    const handleNoteDelete = () => {
        dispatch(deleteTripAdminNote(groupId, tripId, _note._id))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (action.payload.messageType === 'success') {
                handleBackButtonClick()
            }
        })
    }

    const handleNoteKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault()
            handleSave(false)
            handleBackButtonClick()
        }
    }

    const handleFieldChange = (fieldName, value) => {
        setNote({ ..._note, [fieldName]: value })
    }

    const handleSave = (_redirect = true) => {
        dispatch(saveTripAdminNote({ ..._note, note: trim(_note.note || ''), reminderDate: reminderDate && moment(reminderDate).isValid() ? moment(reminderDate).toISOString(true) : null }))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (_redirect && action.payload.messageType === 'success') {
                handleBackButtonClick()
            }
        })
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <div className={s.goBack}>
                    <Button icon='chevron-left' variant='light' onClick={handleBackButtonClick} />
                </div>
                <h4 className={s.title}>{isNew ? 'Add New' : 'Edit'} Private Note</h4>
                
            </div>

            <FormGroup className={s.formGroup}>
                <TextInput value={note || ''} placeholder='Enter note here...'
                    label={'Note'} multiple
                    onKeyPress={e => handleNoteKeyPress(e)}
                    onChange={_text => handleFieldChange('note', _text)} />
            </FormGroup>

            <FormGroup className={s.formGroup + ' ' + s.reminderContainer}>
                <div className={s.hasReminderContainer}>
                    <FormLabel className={s.formLabel}>Reminder?</FormLabel>
                    <FormCheck type='switch' checked={hasReminder}
                        onChange={e => handleFieldChange('hasReminder', e.target.checked)} />
                </div>
                <div className={s.reminderDateContainer}>
                    {hasReminder ?
                    <>
                    <FormLabel className={s.formLabel}>Reminder date</FormLabel>
                    <Datetime value={reminderDate} timeFormat={false}
                        disabled={!hasReminder} closeOnSelect 
                        dateFormat={'M/D/YYYY'} onChange={_dt => handleFieldChange('reminderDate', moment(_dt).startOf('day').toDate())} />
                    </>
                    : null}
                </div>
            </FormGroup>

            {updatedBy && updatedAt && moment(updatedAt).isValid() ?
            <FormGroup className={s.formGroup}>
                <PlayerListItem player={updatedBy} rightDetail={<div className={s.updatedAt}>{coolTime(updatedAt)}</div>} />
            </FormGroup>
            : null}

            <FormGroup className={s.formGroup + ' ' + s.buttons}>
                {!isNew ?
                <Confirm variant='danger' onConfirm={() => handleNoteDelete()}
                    title={`Delete Contact`} enforceFocus={false}
                    confirmText={`delete note`}
                    body={`Are you sure you want to delete this note?`}
                    buttonClassName={s.button + ' ' + s.hideTitleOnSmall}
                    buttonText={<div className={s.title}>delete note</div>}
                    buttonIcon='trash' />
                : null}

                <Button className={s.button + ' ' + s.hideTitleOnSmall} variant='light' icon='times' title={<div className={s.title}>cancel</div>} onClick={handleBackButtonClick} />
                <Button className={s.button + ' ' + s.saveButton} variant='primary' icon='check' title='save note' onClick={() => handleSave()} disabled={!_isValid} />
            </FormGroup>
        </div>
    )
}

export default TripNotePrivateEditor
