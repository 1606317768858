import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TripRoundListItem from '../TripRoundListItem'
import * as s from '../TripRounds.scss'
import { routingPath } from '@severed-links/common.severedlinks-constants'

const TripRoundsAdmin = () => {

    const { groupId, group, tripId, trip, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const navigate = useNavigate()
    const rounds = trip.rounds || []
    const path = routingPath(groupId, isGlobalAdminPath, (group.name || ' ').substring(0,1).toUpperCase())
    return (
        <div className={s.tripRoundPanel}>
            <h4>Golf Course Rounds</h4>
            <ListGroup className={s.roundList}>
            {rounds && rounds.map(i => 
                <TripRoundListItem isAdmin isGlobalAdmin={isGlobalAdminPath} 
                    round={i} groupId={groupId} tripId={tripId}
                    onClick={() => navigate(`${path}trip/${tripId}/admin/rounds/edit/round/${i.round}`)}
                    proxyCount={((trip.scoreboard || {}).proxies || []).filter(x => x.round === i.round).length}
                    key={`trip-${trip._id}-round-${i.round}`} />)
            }
            </ListGroup>
        </div>
    )
}

export default TripRoundsAdmin