import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventCalendarDate.scss'
import moment from 'moment-timezone'
import { FRIENDLY_SHORT_DATE_FORMAT, FRIENDLY_SHORT_TIME_FORMAT, playerAvatarUrl,
    FRIENDLY_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import AddToCalendar from '../AddToCalendar/AddToCalendar'

const TripEventCalendarDate = ({ start, end, timeZoneId = 'America/New_York', isAdmin, tripName, eventTitle, eventDescription, locationAddress }) => {

    if (!start || !moment(start).isValid()) return null
    return (
        <div className={s.container}>
            <div className={s.calendar} title={moment.tz(start, timeZoneId).format(FRIENDLY_DATE_FORMAT)}>
                <div className={s.month}>{moment.tz(start, timeZoneId).format('MMM').toUpperCase()}</div>
                <div className={s.date}>{moment.tz(start, timeZoneId).format('D')}</div>
                {start.year() !== moment().year() ? <div className={s.year}>{start.format('YYYY')}</div> : null}
                {!isAdmin && moment.tz(start, timeZoneId).isValid() && moment.tz(end, timeZoneId).isValid() ? 
                <div>
                    <AddToCalendar event={{ 
                        title: `${tripName} Event: ${eventTitle}`, 
                        location: locationAddress,
                        start: moment.tz(start, timeZoneId).toISOString(true), 
                        end: moment.tz(end, timeZoneId).toISOString(true), 
                        description: eventDescription,
                    }} />
                </div>
                : null}
            </div>
        </div>
    )
}

export default TripEventCalendarDate
