import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripMvpAwardVotingKiosk.scss'
import PlayerListSearcher from '../../Player/PlayerListSearcher'
import Modal from '@severed-links/common.modal'
import PlayerListItem from '../../Player/PlayerListItem'
import { create, orderBy } from 'lodash'
import { mvpAwardVoteLookup, castMvpVoteOnKiosk } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const TripMvpAwardVotingKiosk = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const { group, trip, groupId, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [votingPlayerId, setVotingPlayerId] = useState(null)
    const [votedPlayerId, setVotedPlayerId] = useState(null)
    const [showPlayerPicker, setShowPlayerPicker] = useState(false)
    const [activeStep, setActiveStep] = useState(1)
    const _players = orderBy([
        ...(trip.usaPlayers || []),
        ...(trip.eurPlayers || []),
        ...(trip.unassignedPlayers || []),
    ], ['lastName','firstName'], ['asc','asc'])
    const _items = [
        { step: 1, heading: 'Select yourself', playerId: votingPlayerId },
        { step: 2, heading: 'Select the player you are voting for', playerId: votedPlayerId },
        { step: 3, heading: 'Submit your vote', isSubmit: true },
    ].map(i => ({ 
        ...i, 
        onClick: () => handleStepClick(i.step), 
        isActive: i.step === activeStep && _players.length > 0,
        player: _players.find(p => p.playerId === i.playerId) || { id: null, playerId: null, firstName: 'Choose', lastName: 'a player' },
    })).map(i => ({ 
        ...i, 
        isClickable: ((i.isActive && i.step !== 3) || i.step === 2) && _players.length > 0,
    }))
    const _activeStep = _items.find(i => i.step === activeStep) || {}
    const _isValid = !!votingPlayerId && !!votedPlayerId
    const _showCancel = !!votingPlayerId || !!votedPlayerId


    useEffect(() => {
    }, [])

    const handleStepClick = _step => {
        setActiveStep(_step)
        setShowPlayerPicker(true)
    }

    const onClosePicker = () => {
        setShowPlayerPicker(false)
    }

    const handlePlayerSelect = _player => {
        if (_player && _player.playerId) {
            if (activeStep === 1) {
                setVotingPlayerId(_player.playerId)
                dispatch(mvpAwardVoteLookup(groupId, tripId, _player.playerId))
                .then(action => {
                    setVotedPlayerId(action.payload.votedPlayerId || null)
                    setActiveStep(2)
                })
            } else if (activeStep === 2) {
                setVotedPlayerId(_player.playerId)
                setActiveStep(3)
            }    
        }
        setShowPlayerPicker(false)
    }

    const handleCancel = () => {
        setVotingPlayerId(null)
        setVotedPlayerId(null)
        setShowPlayerPicker(false)
        setActiveStep(1)
    }

    const handleSubmitVote = () => {
        if (votingPlayerId && votedPlayerId) {
            dispatch(castMvpVoteOnKiosk(groupId, tripId, votingPlayerId, votedPlayerId))
            .then(action => {
                dispatch(createNotification({ message: 'Vote cast.', messageType: 'success', headline: 'Cast Vote on Kiosk' }))
                handleCancel()
            })
        } else {
            dispatch(createNotification({ message: 'Invalid vote information.', messageType: 'warning', headline: 'Cast Vote on Kiosk' }))
        }
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <div className={s.goBack}>
                    <Button variant='light' onClick={() => navigate(`/group/${groupId}/trip/${tripId}/admin/mvp-award`)}><FontAwesomeIcon name='chevron-left' /></Button>
                </div>
                <h4 className={s.title}>{group.mvpAwardName} Voting Kiosk</h4>
            </div>
            <div className={s.voterContainer}>
                <ListGroup className={s.list}>
                {_items && _items.map(i =>
                <ListGroup.Item className={s.item} 
                    action={i.isClickable} onClick={i.isClickable ? () => i.onClick() : null}
                    key={`mvp-voting-kiosk-item-${i.step}`}>
                    <div className={s.step}>{i.step}</div>
                    <div className={s.content}>
                    {!i.isSubmit ?
                    <div className={s.votePlayerContainer + (i.isActive ? ` ${s.isActiveStep}` : '')}>
                        <h5>{i.heading}</h5>
                        <PlayerListItem player={i.player} clickDisabled variant={i.isActive ? 'primary' : 'light'} />
                    </div>
                    :
                    <div className={s.buttonsContainer}>
                        <Button variant='light' className={s.cancelButton + (_showCancel ? ` ${s.show}` : '')} onClick={() => handleCancel()}>
                            {_showCancel ?
                            <>
                            <FontAwesomeIcon name='times' /> cancel
                            </>
                            : null}
                        </Button>
                        <Button variant='primary' className={s.submitButton} disabled={!votingPlayerId || !votedPlayerId} onClick={() => handleSubmitVote()}><FontAwesomeIcon name='check' /> submit vote</Button>
                    </div>}
                    </div>
                </ListGroup.Item>
                )}
                </ListGroup>

            </div>
            
            <Modal show={showPlayerPicker} showFooter={false}
                heading={_activeStep.heading || ''}
                onClose={onClosePicker}>
                <div>
                    <PlayerListSearcher players={_players} 
                        PlayerComponent={PlayerListItem}
                        maxLength={_players.length}
                        onSelect={handlePlayerSelect} />
                </div>
            </Modal>

        </div>
    )
}

export default TripMvpAwardVotingKiosk
