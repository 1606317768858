import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, FormGroup, FormControl, FormLabel, 
    InputGroup, Button, Table, ButtonGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getGolfCourseById, getGolfCoursesByName, getUsGolfLookupData, 
    saveGolfCourseComplete, getGolfCourseDetails, deleteGolfCourseLocation, 
    deleteGolfCourseTeebox, getTeeBoxColor } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import Modal from '@severed-links/common.modal'
import { AsyncTypeahead, Menu, MenuItem  } from 'react-bootstrap-typeahead'
import { first, forOwn, range, reject, orderBy, sumBy, filter, update, keysIn, hasIn } from 'lodash'
import * as s from './GlobalAdminEditGolfCourse.scss'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import Confirm from '@severed-links/common.confirm'
import FormCheck from 'react-bootstrap/FormCheck'
import numeral from 'numeral'
import moment from 'moment-timezone'
import AccountDeletionPortal from '../AccountDeletion/AccountDeletionPortal'

const GlobalAdminEditGolfCourse = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const _typeahead = useRef()
    const golfCourseId = params.golfCourseId || null
    const [_course, setCourse] = useState({ 
        _id: null, courseName: '', address: '', geocodedAddress: '', 
        city: '', state: '', stateAbbreviation: '', zip: '', phone: '',
        longitude: 0, latitude: 0, usGolfCourseNumber: '', tees: [], isClosed: false,
        personalRounds: 0, tripRounds: 0,
    })
    const { _id, courseName, address, city, state, zip, phone, longitude, latitude, 
        tees, usGolfCourseNumber, isClosed } = _course
    const [showUsGolfLookup,setShowUsGolfLookup] = useState(false)
    const [showUsGolfTeeboxMap,setShowUsGolfTeeboxMap] = useState(false)
    const [usGolfOptions,setUsGolfOptions] = useState([])
    const [isUsGolfOptionsLoading,setUsGolfOptionsLoading] = useState(false)
    const [usGolfSync,setUsGolfSync] = useState([])
    const [usGolfTeeboxMapping,setUsGolfTeeboxMapping] = useState([])
    const [usGolfTeeBoxMappingOptions,setUsGolfTeeBoxMappingOptions] = useState([])
    const [usGolfTeeBoxMappingConfirmed,setUsGolfTeeBoxMappingConfirmed] = useState(false)
    const [teeUpdate, setTeeUpdate] = useState({ index: -1, data: {} })
    const [lastLoadTime, setLastLoadTime] = useState(null)
    const _teeBoxColorIndex = (tees || []).findIndex(t => !t.teeBoxColors || (t.teeBoxColors && t.teeBoxColors.needsUpdate))
    const _teeBoxRoundsIndex = tees.findIndex(t => !hasIn(t, 'personalRounds'))

    useEffect(() => {
        if (golfCourseId && golfCourseId !== '0') {
            loadGolfCourseById(golfCourseId)
        }
    }, [golfCourseId])

    useEffect(() => {
        if (_teeBoxRoundsIndex > -1) {
            updateTeeBoxUseCounts()
        }
    }, [_teeBoxRoundsIndex])

    useEffect(() => {
        if (_teeBoxColorIndex > -1) {
            var _tees = [...tees]
            _tees[_teeBoxColorIndex].teeBoxColors.needsUpdate = false
            setCourse({ ..._course, tees: _tees })
            dispatch(getTeeBoxColor(_tees[_teeBoxColorIndex].tees))
            .then(action => {
                if (action.payload.messageType === 'success' && action.payload.teeBoxColors) {
                    setTeeUpdate({ index: _teeBoxColorIndex, data: { teeBoxColors: action.payload.teeBoxColors } })
                }
            })

        }
    }, [_teeBoxColorIndex])

    useEffect(() => {
        if (golfCourseId && teeUpdate.index > -1 && teeUpdate.index <= tees.length - 1) {
            var _tees = [...tees]
            _tees[teeUpdate.index] = { ..._tees[teeUpdate.index], ...(teeUpdate.data || {}) }
            setCourse({ ..._course, tees: _tees })
        }
    }, [teeUpdate.index])

    useEffect(() => {
        if (tees?.length) {
            var _tees = [...tees].map(_tee => ({ ..._tee, rating: `${numeral(parseFloat(_tee.rating || '72')).format('0.0')}` }))
            setCourse({ ..._course, tees: _tees })
        }
    }, [tees?.length, lastLoadTime])

    useEffect(() => {
        if ((first(usGolfSync || []) || {}).courseNumber) {
            syncUsGolf()
        }
    }, [(first(usGolfSync || []) || {}).courseNumber || null])

    useEffect(() => {
        if (usGolfTeeBoxMappingConfirmed) {
            performUsGolfTeeboxMapping()
        }
    }, [usGolfTeeBoxMappingConfirmed])

    const loadGolfCourseById = golfCourseId => {
        dispatch(getGolfCourseById(golfCourseId))
        .then(action => {
            setCourse({ ..._course, ...(action.payload || {}) })
            setLastLoadTime(moment().toISOString())
        })
    }

    const updateTeeBoxUseCounts = () => {
        dispatch(getGolfCourseDetails(golfCourseId))
        .then(action => {
            var _tees = [...(tees || [])]
            if (_tees.length && _teeBoxRoundsIndex <= _tees.length - 1) {
                const _tee = _tees[_teeBoxRoundsIndex]
                setTeeUpdate({ index: _teeBoxRoundsIndex, data: {
                    personalRounds: ((action.payload.items || []).find(i => i._id === _tee._id) || {}).personalRounds || 0,
                    tripRounds: ((action.payload.items || []).find(i => i._id === _tee._id) || {}).tripRounds || 0,
                }})
            }
        })
    }

    const syncUsGolf = () => {

        const golfCourseMapping = {
            courseName: 'courseName', 
            courseNumber: 'usGolfCourseNumber',
            streetAddress: 'address',
            city: 'city',
            stateorRegion: 'state',
            zip: 'zip',
            latitude: 'latitude',
            longitude: 'longitude',
            phoneNumber: 'phone',
            unusedFields: ['url','faxNumber','emailAddress','county','totalHoles','architect','guestPolicy','statusPublicPrivateResort','yearBuiltFounded'],
        }
        const usGolfCourse = first(usGolfSync) || {}
        var _tempState = { courseName, address, city, state, zip, phone, longitude, latitude, 
            tees, usGolfCourseNumber }

        // map golf course location -- top level
        forOwn(golfCourseMapping, (value, key) => {
            _tempState[value] = usGolfCourse[key] || ''
        })
        setCourse({ ..._course, ..._tempState })

        // map tee boxes
        updateTeeBoxMapping()
    }

    const performUsGolfTeeboxMapping = () => {
        var _tees = [...tees]
        const teeboxMapping = {
            unusedFields: ['teeNumber','totInPar','totInYdg','totOutPar','totOutYdg'],
        }
        // perform linked options
        const linkedTees = usGolfTeeboxMapping.filter(o => o && o.linkedUsGolfOption !== 'none' && o.linkedUsGolfOption !== 'add')
        linkedTees.forEach(teeMap => {
            const usGolfTee = usGolfSync.find(i => i.courseTeeNumber === teeMap.linkedUsGolfOption)
            var teeIndex = _tees.findIndex(i => i._id === teeMap._id)
            if (teeIndex > -1) {
                var holes = [...(tees[teeIndex].holes || [])]
                for (var i = 1; i <= usGolfTee.holes; i++) {
                    const newHole = { hole: i, yards: usGolfTee[`hole${i}`], hcp: usGolfTee[`hdcp${i}`], par: usGolfTee[`par${i}`] }
                    const holeIndex = holes.findIndex(j => j.hole === i)
                    if (holeIndex === -1) {
                        holes.push({ ...newHole, _id: null, teeboxId: tees[teeIndex]._id })
                    } else {
                        holes[holeIndex] = { ...holes[holeIndex], ...newHole }
                    }
                }
                holes = orderBy(holes, ['hole'], ['asc'])
                _tees[teeIndex] = { 
                    ..._tees[teeIndex], 
                    ...(usGolfTee.teeName && { tees: usGolfTee.teeName }),
                    ...(usGolfTee.courseTeeNumber && { usGolfCourseTeeNumber: usGolfTee.courseTeeNumber }),
                    ...(usGolfTee.holes && { numHoles: usGolfTee.holes }),
                    ...(usGolfTee.lengthYdg && { yards: usGolfTee.lengthYdg }),
                    ...(usGolfTee.par && { par: usGolfTee.par }),
                    ...(usGolfTee.rating && { rating: usGolfTee.rating }),
                    ...(usGolfTee.slope && { slope: usGolfTee.slope }),
                    holes
                }
            }
        })

        // perform add options
        const addTees = usGolfTeeboxMapping.filter(o => o && o.linkedUsGolfOption === 'add')
        addTees.forEach(teeMap => {
            const usGolfTee = usGolfSync.find(i => i.courseTeeNumber === teeMap.usGolfCourseTeeNumber)
            var holes = range(1, usGolfTee.holes + 1, 1).map(i => ({ 
                _id: null, hole: i, teeboxId: null, 
                yards: usGolfTee[`hole${i}`], 
                hcp: usGolfTee[`hdcp${i}`], 
                par: usGolfTee[`par${i}`],
            }))
            _tees.push({ 
                ...(usGolfTee.teeName && { tees: usGolfTee.teeName }),
                ...(usGolfTee.courseTeeNumber && { usGolfCourseTeeNumber: usGolfTee.courseTeeNumber }),
                ...(usGolfTee.holes && { numHoles: usGolfTee.holes }),
                ...(usGolfTee.lengthYdg && { yards: usGolfTee.lengthYdg }),
                ...(usGolfTee.par && { par: usGolfTee.par }),
                ...(usGolfTee.rating && { rating: usGolfTee.rating }),
                ...(usGolfTee.slope && { slope: usGolfTee.slope }),
                teeBoxColors: { fontColor: '#ddd', backgroundColor: '#333', needsUpdate: true },
                _id: null, 
                golfCourseLocationId: golfCourseId, 
                holes
            })
        })

        _tees = orderBy(_tees, ['yards', '_id'], ['desc', 'asc'])

        setCourse({ ..._course, tees: _tees })
    }

    const manuallyAddTeeBox = () => {
        var _tees = [...tees]
        var _hasExistingTees = _tees.length > 0 && (_tees[_tees.length - 1].holes || []).length > 0
        var holes = range(1, 18 + 1, 1).map(i => ({ 
            _id: null,
            hole: i,
            teeboxId: 0,
            yards: 0,
            hcp: _hasExistingTees ? _tees[_tees.length - 1].holes[i - 1].hcp : 0,
            par: _hasExistingTees ? _tees[_tees.length - 1].holes[i - 1].par : 0,
        }))
        _tees.push({ 
            _id: null,
            tees: 'Tee color',
            usGolfCourseTeeNumber: null,
            numHoles: 18,
            yards: 0,
            par: _hasExistingTees ? _tees[_tees.length - 1].par : 72,
            rating: 72.5,
            slope: 113,
            teeBoxColors: { fontColor: '#ddd', backgroundColor: '#333', needsUpdate: true },
            golfCourseLocationId: golfCourseId, 
            holes
        })
        setCourse({ ..._course, tees: _tees })
    }

    const updateTeeBoxMapping = () => {

        const _usGolfTeeBoxMappingOptions = [{ value: 'add', text: 'Add as new tee box' }, ...usGolfSync.map(i => ({ value: i.courseTeeNumber, text: makeOptionSelectText(i.teeName, i.courseTeeNumber) })), { value: 'none', text: 'Do not sync' }]
        var _usGolfTeeboxMapping = tees.map(t => ({ _id: t._id, existingTees: t.tees, yards: t.yards, usGolfCourseTeeNumber: t.usGolfCourseTeeNumber, linkedUsGolfOption: t.usGolfCourseTeeNumber || null, usGolfTeeName: null }))
        _usGolfTeeboxMapping.filter(gt => !gt.linkedUsGolfOption).forEach(tee => {
            const usGolfTee = usGolfSync.find(i => i.teeName === tee.existingTees)
            tee.linkedUsGolfOption = usGolfTee ? usGolfTee.courseTeeNumber : 'none'
            tee.usGolfTeeName = usGolfTee ? usGolfTee.teeName : null
            tee.usGolfCourseTeeNumber = usGolfTee ? usGolfTee.courseTeeNumber : null
            tee.yards = usGolfTee ? usGolfTee.lengthYdg : 0
        })
        usGolfSync.filter(usg => !_usGolfTeeboxMapping.some(gt => gt.linkedUsGolfOption === usg.courseTeeNumber)).forEach(unmatched => {
            _usGolfTeeboxMapping.push({ _id: null, existingTees: '--', yards: unmatched.lengthYdg, linkedUsGolfOption: 'none', usGolfTeeName: unmatched.teeName, usGolfCourseTeeNumber: unmatched.courseTeeNumber })
        })
        _usGolfTeeboxMapping = orderBy(_usGolfTeeboxMapping, ['yards', 'usGolfCourseTeeNumber'], ['desc','asc'])
        setUsGolfTeeboxMapping(_usGolfTeeboxMapping)
        setUsGolfTeeBoxMappingOptions(_usGolfTeeBoxMappingOptions)
        setShowUsGolfTeeboxMap(true)
    }

    const handleTeeboxMappingChange = (e, index) => {
        var _usGolfTeeboxMapping = [...usGolfTeeboxMapping]
        _usGolfTeeboxMapping[index].linkedUsGolfOption = e.target.value
        setUsGolfTeeboxMapping(_usGolfTeeboxMapping)
    }

    const handleCourseChange = (field, value) => {
        setCourse({ ..._course, [field]: value })
    }

    const handleTeeboxChange = (index, field, value) => {
        var _tees = [...tees]
        if (!_tees) return
        _tees[index][field] = field === 'par' || field === 'yards' || field === 'numHoles' || field === 'slope' ? parseInt(value) : value
        setCourse({ ..._course, tees: _tees })
    }

    const makeOptionSelectText = (tees, courseTeeNumber) => `Link to US Golf ${tees} tees (${courseTeeNumber})`

    const handleUsGolfLookupChange = e => {
        if (e && e.length > 0) {
            dispatch(getUsGolfLookupData(first(e).courseNumber))
            .then(action => {
                setShowUsGolfLookup(false)
                setUsGolfSync(action.payload)
                setUsGolfTeeBoxMappingConfirmed(false)
            })
        }
    }

    const handleGolfHoleTextboxChange = (teeIndex, hole, field, value) => {
        if (!tees) return
        var _tees = [...(tees || [])].map(_tee => ({ 
            ..._tee,
            ...(!_tee.holes?.length ? {
                holes: range(1, _tee.numHoles + 1, 1).map(i => ({ _id: null, hole: i, teeboxId: 0, yards: 0, hcp: 0, par: 0 })),
                holeNumbers: range(1, _tee.numHoles + 1, 1),
            } : {}),
        }))
        const holeIndex = (_tees[teeIndex].holes || []).findIndex(i => i.hole === hole)
        if (holeIndex > -1) {
            _tees[teeIndex].holes[holeIndex][field] = parseInt(value)
            if (teeIndex === 0 && _tees.length > 0 && (field === 'par' || field === 'hcp')) {
                for(var i=1; i<=_tees.length - 1; i++) {
                    const subHoleIndex = (_tees[i].holes || []).findIndex(j => j.hole === hole)
                    if (subHoleIndex > -1 && !isNaN(value)) {
                        _tees[i].holes[subHoleIndex][field] = parseInt(value)
                    }
                }
            }
            setCourse({ ..._course, tees: _tees })
        }
    }

    const confirmTeeboxMapping = () => {
        setShowUsGolfTeeboxMap(false)
        setUsGolfTeeBoxMappingConfirmed(true)
    }

    const cancelTeeboxMapping = () => {
        setShowUsGolfTeeboxMap(false)
        setUsGolfTeeBoxMappingConfirmed(false)
    }

    const deleteGolfCourseLocation = () => {
        dispatch(deleteGolfCourseLocation(_id))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: "Delete Golf Course", timeout: 4000 }))
            if (action.payload.messageType === 'success') {
                navigate(`/global-admin/courses/filter/${(courseName || '').substring(0,1).toUpperCase()}`)
            }
        })
    }

    const doDeleteGolfCourseTeebox = teeboxId => {
        dispatch(deleteGolfCourseTeebox(_id, teeboxId))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: "Delete Golf Course Teebox", timeout: 4000 }))
            if (action.payload.messageType === 'success') {
                loadGolfCourseById(_id)
            }
        })
    }

    const save = () => {
        const postData = {
            _id, courseName, address, city, state, zip, 
            phone, longitude, latitude, usGolfCourseNumber, isClosed,
            tees: tees.map(_tee => ({
                ..._tee,
                ...(_tee.slope && !isNaN(_tee.slope) ? { slope: parseInt(_tee.slope)} : {}),
                ...(_tee.rating && !isNaN(_tee.rating) ? { rating: parseFloat(_tee.rating)} : {}),
            }))
        }
        dispatch(saveGolfCourseComplete(postData))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: "Save Golf Course", timeout: 4000 }))
            if (!_id && action.payload.messageType === 'success') {
                navigate(`/global-admin/courses/edit/${action.payload._id}`)
            } else if (_id) {
                loadGolfCourseById(_id)
            }
        })
    }

    const syncAdditionalTeeBoxes = () => {
        dispatch(getUsGolfLookupData(_course.usGolfCourseNumber))
        .then(action => {
            setUsGolfSync(action.payload)
            setUsGolfTeeBoxMappingConfirmed(false)
            updateTeeBoxMapping()
        })

    }

    const runQuery = useCallback(query => {
        setUsGolfOptionsLoading(true)
        dispatch(getGolfCoursesByName(query))
        .then(action => {
            setUsGolfOptions(action.payload)
            setUsGolfOptionsLoading(false)
        })
    }, [])

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: '0px' }}><FontAwesomeIcon name='map-marker' /> {_id ? 'Edit' : 'Add'} Golf Course: {courseName}</h3>
                </Col>
            </Row>
            <Row>
                <Col sm={8} xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Name</FormLabel>
                        <FormControl value={courseName || ''} onChange={e => handleCourseChange('courseName', e.target.value)} />
                    </FormGroup>
                </Col>
                <Col sm={2} xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>US Golf Id</FormLabel>
                        <InputGroup>
                            <FormControl value={usGolfCourseNumber || ''} 
                                required isValid={!!usGolfCourseNumber}
                                isInvalid={!usGolfCourseNumber}
                                onChange={e => handleCourseChange('usGolfCourseNumber', e.target.value)} />
                            <Button variant='light' onClick={() => {
                                setUsGolfTeeBoxMappingConfirmed(false)
                                setShowUsGolfLookup(true)
                                setUsGolfOptions([])
                                setUsGolfOptionsLoading(false)
                                }}><FontAwesomeIcon name='search' /></Button>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={8} xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Address</FormLabel>
                        <FormControl value={address || ''} onChange={e => handleCourseChange('address', e.target.value)} />
                    </FormGroup>
                </Col>
                <Col sm={2} xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Latitude</FormLabel>
                        <FormControl value={(latitude || 0).toString()} onChange={e => handleCourseChange('latitude', parseFloat(e.target.value || '0'))} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={5} xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>City</FormLabel>
                        <FormControl value={city || ''} onChange={e => handleCourseChange('city', e.target.value)} />
                    </FormGroup>
                </Col>
                <Col sm={1} xs={6}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>State</FormLabel>
                        <FormControl value={state || ''} onChange={e => handleCourseChange('state', e.target.value)} />
                    </FormGroup>
                </Col>
                <Col sm={2} xs={6}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Zip</FormLabel>
                        <FormControl value={zip || ''} onChange={e => handleCourseChange('zip', e.target.value)} />
                    </FormGroup>
                </Col>
                <Col sm={2} xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Longitude</FormLabel>
                        <FormControl value={(longitude || 0).toString()} onChange={e => handleCourseChange('longitude', parseFloat(e.target.value))} />
                    </FormGroup>
                </Col>
            </Row>
            <Row className={s.controlsFormGroupContainer}>
                <Col sm={6} xs={12}>
                    <FormGroup className={s.formGroup + ' ' + s.noBottomMargin}>
                        <FormLabel>Phone (10 digits only)</FormLabel>
                        <FormControl value={phone || ''} onChange={e => handleCourseChange('phone', e.target.value)} />
                    </FormGroup>
                </Col>
                <Col sm={4} xs={12}>
                    <FormGroup className={s.controlsFormGroup}>
                        <div className={s.checkBoxItem}>
                            <FormCheck type='switch' checked={isClosed} onChange={e => handleCourseChange('isClosed', e.target.checked)} label='Closed?' />
                        </div>
                        <div className={s.endItem}>
                            <Button className={s.button} variant='light' onClick={() => navigate(-1)}><FontAwesomeIcon name='chevron-left' /> back to list</Button>
                            <Button className={s.button} variant='primary' onClick={() => save()}><FontAwesomeIcon name='check' /> save</Button>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            {tees && tees.map((t, index) =>
            <div key={`tee-box-id-${t._id || index}`}>
                <Row style={{ marginTop: '20px' }}>
                    <Col xs={12}>
                    <div className={s.teeColorContainer} style={{ color: t.teeBoxColors.fontColor, backgroundColor: t.teeBoxColors.backgroundColor }}>
                        <Row>
                        <Col sm={2} xs={6}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Tees</FormLabel>
                                <FormControl value={t.tees || ''} className={s.teeBoxTextBox}
                                    onChange={e => handleTeeboxChange(index, 'tees', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col sm={1} xs={6}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Holes</FormLabel>
                                <FormControl value={(t.numHoles || 0).toString()} className={s.teeBoxTextBox}
                                    onChange={e => handleTeeboxChange(index, 'numHoles', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col sm={1} xs={6}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Par</FormLabel>
                                <FormControl value={(t.par || 0).toString()} className={s.teeBoxTextBox}
                                    onChange={e => handleTeeboxChange(index, 'par', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col sm={2} xs={6}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Yards</FormLabel>
                                <FormControl value={(t.yards || 0).toString()} className={s.teeBoxTextBox}
                                    onChange={e => handleTeeboxChange(index, 'yards', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col sm={2} xs={6}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Slope</FormLabel>
                                <FormControl value={(t.slope || 113).toString()} className={s.teeBoxTextBox}
                                    onChange={e => handleTeeboxChange(index, 'slope', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col sm={2} xs={6}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Rating</FormLabel>
                                <FormControl value={`${t.rating || 72.0}`} className={s.teeBoxTextBox}
                                    onChange={e => handleTeeboxChange(index, 'rating', e.target.value)}
                                    onBlur={e => handleTeeboxChange(index, 'rating', !isNaN(e.target.value) ? numeral(parseFloat(e.target.value)).format('0.0') : '')} />
                            </FormGroup>
                        </Col>
                        <Col sm={2} xs={6}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>US Golf Tee Id</FormLabel>
                                <FormControl value={t.usGolfCourseTeeNumber || ''} className={s.teeBoxTextBox}
                                    onChange={e => handleTeeboxChange(index, 'usGolfCourseTeeNumber', e.target.value)} />
                            </FormGroup>
                        </Col>
                        </Row>
                    </div>
                    </Col>
                    <Col xs={12}>
                        <Table striped responsive className={s.holeTable}>
                        <thead>
                            <tr title={`Golf Course ID: ${t._id} US Golf Course Number: ${t.usGolfCourseTeeNumber}`}>
                                <th style={{ textAlign: 'left' }}>Hole</th>
                                {range(1, Math.min(t.numHoles, 9) + 1, 1).map(h => <th key={`tee-box-${t._id}-header-${h}`}>{h}</th>)}
                                <th>Out</th>
                                {t.numHoles > 9 && range(10, Math.min(t.numHoles, 18) + 1, 1).map(h => <th key={`tee-box-${t._id}-header-${h}`}>{h}</th>)}
                                {t.numHoles > 9 ? <th>In</th> : null}
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ fontWeight: 'bold', textAlign: 'left', whiteSpace: 'nowrap' }}>Par {t.hasParError ? <FontAwesomeIcon name='exclamation-triangle' className={s.error} /> : null}</td>
                                {range(1, Math.min(t.numHoles, 9) + 1, 1).map(h => 
                                    <td title={`Hole ID: ${(t.holes.find(i => i.hole === h) || {})._id}`} 
                                        key={`tee-box-${t._id}-par-${h}`}>
                                        <FormControl className={s.textbox} 
                                            onChange={e => handleGolfHoleTextboxChange(index, h, 'par', e.target.value)}
                                            value={((t.holes.find(i => i.hole === h) || {}).par || 0).toString() || '0'} />
                                    </td>
                                )}
                                <td style={{ fontWeight: 'bold' }}>{sumBy(t.holes.filter(i => i.hole >= 1 && i.hole <= Math.min(t.numHoles, 9)), 'par')}</td>
                                {t.numHoles > 9 && range(10, Math.min(t.numHoles, 18) + 1, 1).map(h => 
                                    <td title={`Hole ID: ${(t.holes.find(i => i.hole === h) || {})._id}`} 
                                        key={`tee-box-${t._id}-par-${h}`}>
                                        <FormControl className={s.textbox} 
                                            onChange={e => handleGolfHoleTextboxChange(index, h, 'par', e.target.value)}
                                            value={((t.holes.find(i => i.hole === h) || {}).par || 0).toString() || '0'} />
                                    </td>
                                )}
                                {t.numHoles > 9 ? <td style={{ fontWeight: 'bold' }}>{sumBy(t.holes.filter(i => i.hole >= 10 && i.hole <= Math.min(t.numHoles, 18)), 'par')}</td> : null}
                                <td style={{ fontWeight: 'bold' }}>{sumBy(t.holes, 'par')}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold', textAlign: 'left', whiteSpace: 'nowrap' }}>Yards {t.hasYardsError ? <FontAwesomeIcon name='exclamation-triangle' className={s.error} /> : null}</td>
                                {range(1, Math.min(t.numHoles, 9) + 1, 1).map(h => 
                                    <td key={`tee-box-${t._id}-yards-${h}`}>
                                        <FormControl className={s.textbox} 
                                            onChange={e => handleGolfHoleTextboxChange(index, h, 'yards', e.target.value)}
                                            value={((t.holes.find(i => i.hole === h) || {}).yards || 0).toString() || '0'} />
                                    </td>
                                )}
                                <td style={{ fontWeight: 'bold' }}>{sumBy(t.holes.filter(i => i.hole >= 1 && i.hole <= Math.min(t.numHoles, 9)), 'yards')}</td>
                                {t.numHoles > 9 && range(10, Math.min(t.numHoles, 18) + 1, 1).map(h => 
                                    <td key={`tee-box-${t._id}-yards-${h}`}>
                                        <FormControl className={s.textbox} 
                                            onChange={e => handleGolfHoleTextboxChange(index, h, 'yards', e.target.value)}
                                            value={((t.holes.find(i => i.hole === h) || {}).yards || 0).toString() || '0'} />
                                    </td>
                                )}
                                {t.numHoles > 9 ? <td style={{ fontWeight: 'bold' }}>{sumBy(t.holes.filter(i => i.hole >= 10 && i.hole <= Math.min(t.numHoles, 18)), 'yards')}</td> : null}
                                <td style={{ fontWeight: 'bold' }}>{sumBy(t.holes, 'yards')}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold', textAlign: 'left', whiteSpace: 'nowrap' }}>Hcp {t.hasHcpError ? <FontAwesomeIcon name='exclamation-triangle' className={s.error} title={`Missing: ${(t.hcpMissing || []).join(',')} Duplicate: ${(t.hcpDuplicates || []).join(',')}`} /> : null}</td>
                                {range(1, Math.min(t.numHoles, 9) + 1, 1).map(h => 
                                    <td key={`tee-box-${t._id}-hcp-${h}`}>
                                        <FormControl className={s.textbox} 
                                            onChange={e => handleGolfHoleTextboxChange(index, h, 'hcp', e.target.value)}
                                            value={((t.holes.find(i => i.hole === h) || {}).hcp || 0).toString() || '0'} />
                                    </td>
                                )}
                                <td></td>
                                {t.numHoles > 9 && range(10, Math.min(t.numHoles, 18) + 1, 1).map(h => 
                                    <td key={`tee-box-${t._id}-hcp-${h}`}>
                                        <FormControl className={s.textbox} 
                                            onChange={e => handleGolfHoleTextboxChange(index, h, 'hcp', e.target.value)}
                                            value={((t.holes.find(i => i.hole === h) || {}).hcp || 0).toString() || '0'} />
                                    </td>
                                )}
                                {t.numHoles > 9 ? <td></td> : null}
                                <td></td>
                            </tr>
                        </tbody>
                        </Table>
                        <div className={s.teeBoxInfoContainer}>
                            <div className={s.spacer} />
                            {t.tripRounds ? <div className={s.roundInfo}>Trip rounds: {t.tripRounds}</div> : null}
                            {t.personalRounds ? <div className={s.roundInfo}>Personal rounds: {t.personalRounds}</div> : null}
                            {t._id && (t.tripRounds + t.personalRounds) === 0 ?
                            <div className={s.delete}>
                                <Confirm title={'Delete Golf Course Teebox'}
                                    onConfirm={() => doDeleteGolfCourseTeebox(t._id)}
                                    confirmText='delete golf course teebox'
                                    body={<div><p>Are you sure you want to delete this teebox and hole data?</p></div>}
                                    variant='danger' size='sm' buttonIcon='trash' />
                            </div>
                            : null}
                        </div>
                    </Col>
                </Row>
            </div>
            )}
            {_id ?
            <Row style={{ marginTop: '1rem' }}>
                <Col xs={12}>
                    <Button variant='light' onClick={() => manuallyAddTeeBox()}><FontAwesomeIcon name='plus' /> add manual tee box</Button>
                    <Button style={{ marginLeft: '1rem' }} variant='light' onClick={() => syncAdditionalTeeBoxes()}><FontAwesomeIcon name='sync-alt' /> sync tee box data</Button>
                </Col>
            </Row>
            : null}
            {_id ?
            <Row style={{ marginTop: '30px' }}>
                <Col xs={12}>
                    <Confirm title={'Delete Golf Course'}
                        onConfirm={() => deleteGolfCourseLocation()}
                        confirmText='delete golf course'
                        body={<div><p>Are you sure you want to delete this golf course (and its teeboxes and hole data)?</p></div>}
                        variant='danger' buttonIcon='trash' buttonText='delete golf course' />
                </Col>
            </Row>
            : null}

            <Modal show={showUsGolfLookup} showFooter={false}
                heading={`Look up US Golf ID`}
                onClose={() => setShowUsGolfLookup(false)}>
                <div>
                    <FormGroup className={s.formGroup}>
                        <AsyncTypeahead ref={_typeahead}
                        id='us-golf-lookup-by-id'
                        onSearch={runQuery}
                        isLoading={isUsGolfOptionsLoading}
                        multiple={false} autoFocus
                        labelKey={o => `${o.courseName} (${o.courseNumber})`}
                        renderMenu={(results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => (
                            <Menu {...menuProps}>
                                {results.map((o, index) => (
                                <MenuItem option={o} position={index} key={`tpyeahead-course-item-${o.courseNumber}`}>
                                    {o.courseName} ({o.courseNumber}) {o.isAdded ? <span className='text-danger'>* is already added</span> : null}
                                </MenuItem>
                                ))}
                            </Menu>
                        )}
                        placeholder={'[Lookup US Golf data by course name...]'}
                        options={usGolfOptions}
                        onChange={e => handleUsGolfLookupChange(e)} />
                    </FormGroup>
                </div>
            </Modal>
            <Modal show={showUsGolfTeeboxMap} showFooter={false}
                heading={`Map Tee Boxes to US Golf Data`}
                onClose={() => setShowUsGolfTeeboxMap(false)}>
                <div>
                    <Table>
                        <thead>
                            <tr>
                                <th>Existing Tees</th>
                                <th>Action</th>
                                <th>US Golf Tees</th>
                                <th>US Golf Tee Number</th>
                                <th style={{ textAlign: 'right' }}>Yards</th>
                            </tr>
                        </thead>
                        <tbody>
                        {usGolfTeeboxMapping && usGolfTeeboxMapping.map((i, index) =>
                            <tr key={`golf-course-tee-box-mapping-${_id}-${index}`}>
                                <td>{i.existingTees}</td>
                                <td>
                                    <FormControl style={{ fontSize: 'inherit' }} 
                                        as='select' 
                                        onChange={e => handleTeeboxMappingChange(e, index)}
                                        value={i.linkedUsGolfOption}>
                                    {usGolfTeeBoxMappingOptions && usGolfTeeBoxMappingOptions.map(o =>
                                        <option key={`golf-course-tee-box-mapping-${_id}-${index}-${o.value}`} value={o.value}>{o.text}</option>
                                    )}
                                    </FormControl>
                                </td>
                                <td>{i.usGolfTeeName}</td>
                                <td>{i.usGolfCourseTeeNumber}</td>
                                <td style={{ textAlign: 'right' }}>{i.yards}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    <FormGroup style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <Button variant='light' onClick={() => cancelTeeboxMapping()}><FontAwesomeIcon name='times' /> cancel</Button>
                        {' '}
                        <Button variant='primary' onClick={() => confirmTeeboxMapping()}><FontAwesomeIcon name='check' /> confirm mapping</Button>
                    </FormGroup>
                </div>
            </Modal>
        </div>
    )
}

export default GlobalAdminEditGolfCourse