import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { PUBLIC_URL } from '@severed-links/common.severedlinks-constants'

const RedirectToPublicSite = () => {

    useEffect(() => { window.location = PUBLIC_URL }, [])

    return (
        <div title='Redirecting to public site...' />
    )
}

export default RedirectToPublicSite
