import React, { useEffect } from 'react'
import Badge from 'react-bootstrap/Badge'
import pluralize from 'pluralize'

const TripRsvpLabel = ({ trip = {} }) => {

    if (!trip) return null
    const { rsvpCapacityLimit, rsvpCapacityPercent, rsvpSpotsLeft, settings } = trip
    if (!(settings || {}).canPlayersRsvp || (settings || {}).isTripAtCapacity) return null
    return (
        <Badge bg={rsvpSpotsLeft > 5 ? 'info' : 'danger'}>
            RSVP now 
            {' '}
            ({pluralize('spot', rsvpSpotsLeft, true)} left{rsvpSpotsLeft <= 5 ? '!' : ''})
        </Badge>
    )
}

export default TripRsvpLabel