import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useNavigate, Outlet } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminDevices.scss'
import GlobalAdminDevicePagination from './GlobalAdminDevicePagination'
import GlobalAdminDeviceList from './GlobalAdminDeviceList'
import { setGlobalAdminState, getDeviceList } from '@severed-links/common.severedlinks-reducers/globalAdmin'

const GlobalAdminDevices = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { _id: userId } = useSelector(state => state.account)
    const { totalPages, pageNumber, totalRecords, pageLength, list: _lists } = useSelector(state => state.globalAdmin.devices)
    const { currentPageParam } = useParams()
    const list = pageNumber ? ((_lists || {})[`page${pageNumber}`] || []) : []

    useEffect(() => {
        if (!isNaN(currentPageParam) && parseInt(currentPageParam) !== pageNumber) {
            dispatch(setGlobalAdminState('devices', { pageNumber: parseInt(currentPageParam) }))
        } else {
            navigate(`/global-admin/devices/page/1`)
        }
    }, [currentPageParam])

    useEffect(() => {
        if (pageNumber && pageLength) {
            dispatch(getDeviceList(pageNumber, pageLength))
        }
    }, [pageNumber, pageLength])

    const onChange = _page => {
        navigate(`/global-admin/devices/page/${_page}`)
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h3 className={s.title}><FontAwesomeIcon name='mobile' /> Devices</h3>
                <div className={s.pagination}>
                    <GlobalAdminDevicePagination currentPage={pageNumber || 1} totalPages={totalPages || 1} onChange={onChange} />
                </div>
            </div>
            <GlobalAdminDeviceList list={list} />
        </div>
    )
}

export default GlobalAdminDevices
