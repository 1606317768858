import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Button, Image, Nav, Carousel, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripSlideShow.scss'
import { commonImageUrl, playerAvatarJumboThumb } from '@severed-links/common.severedlinks-constants'
import { first } from 'lodash'
import ParScoreDisplay from '../ParScoreDisplay'
import RyderCupRoundScoreboard from '../RyderCupRoundScoreboard'
import TripSlideShowPickViewer from './TripSlideShowPickViewer'
import numeral from 'numeral'

const HoleResult = ({ player = {}, isSkin = false }) => (
    <Badge bg={player.result === 'Eagle' || player.result === 'Double Eagle' ? 'danger' : player.result === 'Birdie' ? 'success' : player.result === 'Par' ? 'info' : 'light'}
        text={player.result === 'Eagle' || player.result === 'Double Eagle' ? 'light' : player.result === 'Birdie' ? 'light' : player.result === 'Par' ? 'light' : 'dark'}>
        {player.result}
        {' '}
        {isSkin && !isNaN(player.amount) ? <span>{numeral(player.amount).format('($0.00)')}</span> : null}
    </Badge>
)

const TripSlideShowPresentation = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { group, groupId, trip, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [selectedRound, setSelectedRound] = useState(0)
    const [selectedType, setSelectedType] = useState('skins')
    const [activeIndex, setActiveIndex] = useState(0)
    const [direction, setDirection] = useState(null)
    const roundName = (((trip || {}).rounds || []).find(r => r.round === selectedRound) || {}).roundName

    useEffect(() => {
        const _round = first(trip.rounds) || {}
        setSelectedRound(_round.round || 0)
    }, [(trip.rounds || []).length])

    const selectRound = e => {
        setSelectedRound(parseInt(e))
        setActiveIndex(0)
    }

    const selectType = e => {
        setSelectedType(e)
        setActiveIndex(0)
    }

    const handleSelect = (selectedIndex, e) => {
        setActiveIndex(selectedIndex)
        setDirection(e.direction)
    }   
    
    const onClose = () => navigate(location.pathname.replace('/award-presentation', ''))

    const scoreboard = trip.scoreboard || {}
    const data = {
        round: selectedRound,
        activeIndex,
        matches: (scoreboard.matches || []).filter(i => i.round === selectedRound).map(i => ({ ...i, usaCount: [...i.usaPlayerList].length, eurCount: [...i.eurPlayerList].length, totalPlayers: [...i.usaPlayerList, ...i.eurPlayerList].length, allPlayers: [...i.usaPlayerList, ...i.eurPlayerList] })),
        skins: (scoreboard.skins || []).filter(i => i.round === selectedRound),
        proxies: (scoreboard.proxies || []).filter(p => p.round === selectedRound && p.playerID),
        medalist: (scoreboard.medalist || []).filter(i => i.rank === 1),
    }

    const _navItems = selectedRound ? [
        data.skins.length ? 'Skins' : null,
        data.proxies.length ? 'Proxies' : null,
        data.matches.length ? 'Matches' : null,
        data.medalist.length ? 'Medalist' : null,
        'Picks'
    ].filter(i => i) : []

    return (
        <div className={s.presentation}>
            <div className={s.side}>
                <div className={s.logo}>
                    <Image src={commonImageUrl('severed-links-logo-h50')} />
                    <h4 className={s.tripName}>{trip.tripName}</h4>
                    <h5 className={s.location}>{(trip.location || {}).locName}</h5>
                </div>
                <div className={s.ryderCup}>
                    <RyderCupRoundScoreboard {...(trip || {})} 
                        roundScores={(trip.scoreboard || {}).ryderCup} 
                        ryderCupRounds={(trip.scoreboard || {}).ryderCupRounds || []}
                        numRounds={(trip.rounds || []).length} 
                        showRounds={false} showRoundHeader={false} />
                </div>
                {selectedRound ?
                <div className={s.nav}>
                    <h4 className={s.white}>Round</h4>
                    <Nav className={s.nav} variant='pills' activeKey={selectedRound} onSelect={e => selectRound(e)}>
                    {trip.rounds && Array.isArray(trip.rounds) && trip.rounds.map(r =>
                        <Nav.Item key={`presentation-round-${trip._id}-${r.round}`}>
                            <Nav.Link eventKey={r.round}>{r.round}</Nav.Link>
                        </Nav.Item>
                    )}
                    </Nav>
                </div>
                : null}
                <div className={s.nav}>
                    <Nav variant='pills' className='flex-column' activeKey={selectedType}
                        onSelect={e => selectType(e)}>
                    {_navItems && _navItems.map(i =>
                    <Nav.Item key={`presentation-nav-${i}`}>
                        <Nav.Link eventKey={i.toLowerCase()} >{i}</Nav.Link>
                    </Nav.Item>
                    )}
                    </Nav>
                </div>
                <div className={s.close}><Button size='sm' onClick={() => onClose()}><FontAwesomeIcon name='times' /></Button></div>
            </div>
            {selectedRound ?
            <div className={s.main}>

                {selectedType === 'skins' ? 
                <Carousel wrap={false} className={s.carousel} interval={null} activeIndex={activeIndex} onSelect={handleSelect} direction={direction}>
                    <Carousel.Item className={s.carouselItem}>
                        <div className={s.carouselInnerContainer}>
                            <div className={s.image}>
                                &nbsp;
                            </div>
                            <div className={s.caption}>
                                <h1 className={s.white}>{roundName} Skins</h1>
                            </div>
                        </div>
                    </Carousel.Item>
                    {data.skins && data.skins.map(i => 
                    <Carousel.Item className={s.carouselItem} key={`skins-${trip._id}-${selectedRound}-${i.hole}`}>
                        <div className={s.carouselInnerContainer}>
                            <div className={s.image} style={{ backgroundImage: `url(${playerAvatarJumboThumb(i.imageUrl)})` }} />
                            <div className={s.caption}>
                                <h1 className={s.white}>{i.playerName}</h1>
                                <h3 className={s.white}><FontAwesomeIcon name='flag' /> #{i.hole} Par {i.par} {i.yards} Yards <HoleResult player={i} isSkin /></h3>
                            </div>
                        </div>
                    </Carousel.Item>
                )}
                </Carousel>
                : null}

                {selectedType === 'proxies' ? 
                <Carousel wrap={false} className={s.carousel} interval={null} activeIndex={activeIndex} onSelect={handleSelect} direction={direction}>
                    <Carousel.Item className={s.carouselItem}>
                        <div className={s.carouselInnerContainer}>
                            <div className={s.image}>
                                &nbsp;
                            </div>
                            <div className={s.caption}>
                                <h1 className={s.white}>{roundName} Proxies</h1>
                            </div>
                        </div>
                    </Carousel.Item>
                    {data.proxies && data.proxies.map(i => 
                    <Carousel.Item className={s.carouselItem} key={`proxies-${trip._id}-${selectedRound}-${i.hole}`}>
                        <div className={s.carouselInnerContainer}>
                            <div className={s.image} style={{ backgroundImage: `url(${playerAvatarJumboThumb(i.imageUrl)})` }} />
                            <div className={s.caption}>
                                <h1 className={s.white}>{i.playerName}</h1>
                                <h3 className={s.white}>{i.proxyName}</h3>
                                <h3 className={s.white}><FontAwesomeIcon name='flag' /> #{i.hole} Par {i.par} {i.yards} Yards <HoleResult player={i} isSkin /></h3>
                            </div>
                        </div>
                    </Carousel.Item>
                    )}
                </Carousel>
                : null}


                {selectedType === 'matches' ? 
                <Carousel wrap={false} className={s.carousel} interval={null} activeIndex={activeIndex} onSelect={handleSelect} direction={direction}>
                    <Carousel.Item className={s.carouselItem}>
                        <div className={s.carouselInnerContainer}>
                            <div className={s.image}>
                                &nbsp;
                            </div>
                            <div className={s.caption}>
                                <h1 className={s.white}>{roundName} Matches</h1>
                            </div>
                        </div>
                    </Carousel.Item>
                    {data.matches && data.matches.map(i => 
                        <Carousel.Item className={s.carouselItem} key={`matches-${trip._id}-${selectedRound}-${i.match}`}>
                            <div className={s.carouselInnerContainer}>
                                <div className={s.match}>
                                {['USA', 'EUR'].map(t =>
                                    <div className={s.team + ' ' + s[t.toLowerCase()]} 
                                        style={{ backgroundColor: trip[`${t.toLowerCase()}BackgroundColor`] }}
                                        key={`matches-${trip._id}-${selectedRound}-${i.match}-team-${t}`}>
                                        <h4 className={s.white}><Image src={commonImageUrl(t.toLowerCase() + 'Flag')} /></h4>
                                        <div className={s.playerContainer}>
                                            {i[t.toLowerCase() + 'PlayerList'] && i[t.toLowerCase() + 'PlayerList'].map(p =>
                                                <div className={s.player} key={`matches-${trip._id}-${selectedRound}-${i.match}-team-${t}-player${p.playerID}`}>
                                                    <Image src={playerAvatarJumboThumb(p.imageUrl)} />
                                                    <h3 className={s.playerName} style={{ color: trip[`${t.toLowerCase()}TextColor`] }}>{p.playerName}</h3>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                </div>
                                <div className={s.caption}>
                                    <h1 className={s.white}>{i.status}</h1>
                                </div>
                            </div>
                        </Carousel.Item>
                    )}
                </Carousel>
                : null}

                {selectedType === 'medalist' ? 
                <Carousel wrap={false} className={s.carousel} interval={null} activeIndex={activeIndex} onSelect={handleSelect} direction={direction}>
                    <Carousel.Item className={s.carouselItem}>
                        <div className={s.carouselInnerContainer}>
                            <div className={s.image}>
                                &nbsp;
                            </div>
                            <div className={s.caption}>
                                <h1 className={s.white}>Medalist</h1>
                            </div>
                        </div>
                    </Carousel.Item>
                    {data.medalist && data.medalist.map(i => 
                    <Carousel.Item className={s.carouselItem} key={`medalist-${trip._id}-${selectedRound}-${i.playerId}`}>
                        <div className={s.carouselInnerContainer}>
                            <div className={s.image} style={{ backgroundImage: `url(${playerAvatarJumboThumb(i.imageUrl)})` }} />
                            <div className={s.caption}>
                                <h1 className={s.white}>{i.playerName} <ParScoreDisplay score={i.diff} inverse /></h1>
                                <h3 className={s.white}>{trip.tripName} Medalist</h3>
                            </div>
                        </div>
                    </Carousel.Item>
                    )}
                </Carousel>
                : null}

                {selectedType === 'picks' ? 
                    <TripSlideShowPickViewer group={group} trip={trip} />
                : null}

            </div>
            : null}
        </div>
    )
}

export default TripSlideShowPresentation