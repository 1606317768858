import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminMatchPlayFormats.scss'
import { getMatchPlayFormats } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import { range } from 'lodash'

const PlayersPerTeamIcons = ({ count = 0, isTotalScore = false, isRyderCupCounted = false, isPlayingOwnBall = false }) => (
    <div className={s.playersPerTeamContainer}>
    {range(4, 0, -1).map(i => <FontAwesomeIcon name='user' isRegular={i <= count} className={s.icon + (i > count ? ` ${s.lightColor}` : '')} key={`per-team-icon-${i}`} />)}
    <FontAwesomeIcon name='trophy' className={s.icon + (!isRyderCupCounted ? ` ${s.lightColor}` : '')} />
    <FontAwesomeIcon name='circle' isRegular={!isPlayingOwnBall} className={s.icon + (!isPlayingOwnBall ? ` ${s.lightColor}` : '')} />
    <FontAwesomeIcon name='plus' className={s.icon + (!isTotalScore ? ` ${s.lightColor}` : '')} />
    </div>
)

const GlobalAdminMatchPlayFormats = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const playerId = useSelector(state => state.account._id)
    const matchPlayFormats = useSelector(state => state.globalAdmin.matchPlayFormats)

    useEffect(() => {
        dispatch(getMatchPlayFormats())
    }, [])

    return (
        <div className={s.container}>
            <div className={s.headingContainer}>
                <h3 className={s.title}><FontAwesomeIcon name='play-circle' /> Play Formats</h3>
                <div className={s.controls}>
                    <Button onClick={() => navigate(`/global-admin/play-format/new`)}><FontAwesomeIcon name='plus' /></Button>
                </div>
            </div>
            <ListGroup className={s.list}>
            {matchPlayFormats && matchPlayFormats.map(i =>
            <ListGroup.Item className={s.item} key={`ga-mpf-${i._id}`}
                action onClick={() => navigate(`/global-admin/play-format/${i._id}`)}>
                <div className={s.content}>
                    <div className={s.formatName}>{i.formatName}</div>
                    {i.description ? <div className={s.description}>{i.description}</div> : null}
                </div>
                <PlayersPerTeamIcons count={i.teamSize} isRyderCupCounted={i.isRyderCupCounted} isPlayingOwnBall={i.isPlayingOwnBall} isTotalScore={i.isTotalScore} />
                <div className={s.chevron}>
                    <FontAwesomeIcon name='chevron-right' />
                </div>
            </ListGroup.Item>
            )}
            </ListGroup>
        </div>
    )
}

export default GlobalAdminMatchPlayFormats
