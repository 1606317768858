import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ListGroup, FormGroup, Button, FormLabel, FormControl } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminMatchPlayFormatEditor.scss'
import { getMatchPlayFormatById, deleteMatchPlayFormat, 
    restoreMatchPlayFormat, saveMatchPlayFormat } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import { keysIn, pick, omit, isMatch } from 'lodash'
import Picker from '@severed-links/common.picker'
import Confirm from '@severed-links/common.confirm'
import FormCheck from 'react-bootstrap/FormCheck'
import pluralize from 'pluralize'
import moment from 'moment-timezone'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const GlobalAdminMatchPlayFormatEditor = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const formatId = (params.formatId || '').toLowerCase()
    const playerId = useSelector(state => state.account._id)
    const isNew = !formatId || formatId === 'new'
    const _blankFormat = { _id: null, formatName: '', description: '', canDelete: false, isMatchPlay: true, isAlternateShot: false, isScramble: false, isStableford: false, isPlayingOwnBall: false, isRyderCupCounted: false, isTotalScore: false, teamSize: 2, updatedAt: moment().toISOString() }
    const [_format, setFormat] = useState(_blankFormat)
    const [pickerItems, setPickerItems] = useState([])
    const [showPicker, setShowPicker] = useState(false)
    const [isDeleted, setDeleted] = useState(false)
    const [pickerHeading, setPickerHeading] = useState('')
    const [pickerFieldName, setPickerFieldName] = useState('')
    const [pickerValue, setPickerValue] = useState(null)
    const { 
        _id, formatName, description, teamSize, isDefault, isMatchPlay, 
        isScramble, isAlternateShot, isStableford, isDeletedFormat,
        isPlayingOwnBall, isRyderCupCounted, isTotalScore, 
        canDelete, updatedAt } = _format
    const _fields = [
        { fieldName: 'formatName', type: 'text', label: 'Title', value: formatName || '', show: true, isValid: !!formatName },
        { fieldName: 'description', type: 'textarea', label: 'Description', value: description || '', show: true, isValid: !!description },
        { fieldName: 'formatCategory', type: 'picker', label: 'Category', value: isMatchPlay ? 'Match play' : isScramble ? 'Scramble' : isStableford ? 'Stableford' : isAlternateShot ? 'Alternate shot' : '', show: true, items: [{ title: 'Match play', value: { isMatchPlay: true, isScramble: false, isStableford: false, isAlternateShot: false } }, { title: 'Scramble', value: { isMatchPlay: false, isScramble: true, isStableford: false, isAlternateShot: false } }, { title: 'Stableford', value: { isMatchPlay: false, isScramble: false, isStableford: true, isAlternateShot: false } }, { title: 'Alternate shot', value: { isMatchPlay: false, isScramble: false, isStableford: false, isAlternateShot: true } }].map(i => ({ ...i, icon: 'flag' })), isValid: true },
        { fieldName: 'teamSize', type: 'picker', label: 'Team size', value: teamSize || 0, show: true, items: [1,2,3,4].map(i => ({ title: `${pluralize('player', i, true)}`, value: i, icon: 'user' })), isValid: teamSize > 0 },
        { fieldName: 'isPlayingOwnBall', type: 'bool', label: 'Playing own ball?', value: isPlayingOwnBall || false, show: true, isValid: true },
        teamSize > 1 ? { fieldName: 'isTotalScore', type: 'bool', label: 'Use total score?', value: isMatchPlay ? isTotalScore : false, show: isMatchPlay, isValid: true } : null,
        { fieldName: 'isRyderCupCounted', type: 'bool', label: 'Counts in Ryder Cup?', value: isRyderCupCounted || false, show: true, isValid: true },
    ].filter(i => i)
    const _isValid = _fields.every(i => i.isValid)
    

    useEffect(() => {
        loadFormat()
    }, [formatId])

    useEffect(() => {
        setFormat({ ..._format, isTotalScore: false })
    }, [isMatchPlay])

    const loadFormat = () => {
        if (formatId && !isNew) {
            dispatch(getMatchPlayFormatById(formatId))
            .then(action => {
                setFormat({ ..._blankFormat, ...action.payload })
                setDeleted(action.payload.isDeletedFormat || false)
            })
        } else if (isNew) {
            setFormat(_blankFormat)
            setDeleted(false)
        }
    }
    
    const handleFieldChange = (fieldName, value) => {
        setFormat({ ..._format, [fieldName]: value })
    }

    const doSaveFormat = () => {
        if (_isValid) {
            const _postData = omit(_format, '_id','count','nonDeletedCount','canDelete', 'oldId','formatId')
            dispatch(saveMatchPlayFormat(!isNew ? formatId : 'New', _postData))
            .then(action => {
                dispatch(createNotification(action.payload))
                doCancel()
            })
        }
    }

    const doCancel = () => {
        navigate(`/global-admin/play-formats`)
    }

    const doDeleteFormat = () => {
        dispatch(deleteMatchPlayFormat(formatId))
        .then(() => {
            loadFormat()
            setDeleted(true)
        })
    }

    const doRestoreFormat = () => {
        dispatch(restoreMatchPlayFormat(formatId))
        .then(() => {
            loadFormat()
            setDeleted(false)
        })
    }

    const openPicker = (heading, fieldName, value) => {
        setShowPicker(true)
        setPickerHeading(heading)
        setPickerFieldName(fieldName)
        setPickerValue(value)
        setPickerItems(_fields.find(i => i.fieldName === fieldName).items || [])
    }

    const closePicker = () => {
        setShowPicker(false)
    }

    const handlePickerSelect = _item => {
        if (_item) {
            if (pickerFieldName === 'formatCategory' && typeof _item.value === 'object') {
                setFormat({ ..._format, ..._item.value })
            } else {
                setFormat({ ..._format, [pickerFieldName]: _item.value })
            }
        }
    }

    
    return (
        <div className={s.container}>

            <div className={s.headingContainer}>
                <div className={s.backIcon}>
                    <Button className={s.backIconButton} variant='light' onClick={() => navigate(`/global-admin/play-formats`)}><FontAwesomeIcon name='chevron-left' /></Button>
                </div>
                <h3 className={s.title}>{isNew ? 'Add' : 'Edit'} Format{!isNew && formatName ? `: ${formatName}` : ''}</h3>
                <div className={s.controls}>

                </div>
            </div>

            <div className={s.fieldContainer}>
                <ListGroup className={s.list}>
                {_fields && _fields.filter(i => i.show).map(i =>
                <ListGroup.Item className={s.item + (i.type === 'picker' || i.type === 'bool' ? ` ${s.stretch}` : '')} key={`mpf-field-${i.fieldName}`}>
                    <div className={s.label}>{i.label}</div>
                    <div className={s.value}>
                        {i.type === 'text' || i.type === 'textarea' ?
                        <FormControl value={i.value} {...(i.type === 'textarea' ? { as: 'textarea' } : {})} onChange={e => handleFieldChange(i.fieldName, e.target.value)} />
                        : i.type === 'picker' ? 
                        <Button variant='secondary' onClick={() => openPicker(i.title, i.fieldName, i.value)}>{i.value}</Button>
                        : i.type === 'bool' ?
                        <FormCheck type='switch' checked={i.value} onChange={e => handleFieldChange(i.fieldName, e.target.checked)} />
                        : null}
                    </div>
                </ListGroup.Item>
                )}
                <ListGroup.Item className={s.item + ' ' + s.miniItem}>
                    <div className={s.label}>Last updated</div>
                    <div className={s.value}>{moment(updatedAt).fromNow()}</div>
                </ListGroup.Item>
                <ListGroup.Item className={s.item + ' ' + s.isSaveItem}>
                    {canDelete && !isDeleted ?
                    <Confirm title={'Delete Format'}
                        onConfirm={() => doDeleteFormat()} enforceFocus={false}
                        confirmText='delete format'
                        body={<div><p>Are you sure you want to delete this format?</p></div>}
                        variant='danger' buttonClassName={s.button + ' ' + s.deleteButton}
                        buttonIcon='trash' />
                    : null}
                    {isDeleted ? <Button className={s.button + ' ' + s.deleteButton} variant='info' onClick={() => doRestoreFormat()}><FontAwesomeIcon name='undo' /></Button> : null}
                    <Button className={s.button} variant='light' onClick={() => doCancel()}><FontAwesomeIcon name='times' /> cancel</Button>
                    <Button className={s.button + ' ' + s.saveButton} variant={'primary'} disabled={!_isValid} onClick={() => doSaveFormat()}><FontAwesomeIcon name='check' /> save format</Button>
                </ListGroup.Item>
                </ListGroup>
            </div>

            <Picker show={showPicker} heading={pickerHeading}
                onClose={() => closePicker()}
                items={pickerItems}
                defaultValue={pickerValue}
                onSelect={_item => handlePickerSelect(_item)} />

        </div>
    )
}

export default GlobalAdminMatchPlayFormatEditor
