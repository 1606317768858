import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getGlobalAdminStatus, getTodos } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import { forOwn, keysIn, sortedLastIndex } from 'lodash'
import * as s from './GlobalAdminStatus.scss'
import { titleCase } from 'title-case'
import TestCodeEntry from './TestCodeEntry'

const GlobalAdminStatus = () => {

    const dispatch = useDispatch()
    const status = useSelector(state => state.globalAdmin.status)

    useEffect(() => {
        dispatch(getGlobalAdminStatus())
        dispatch(getTodos())
    }, [])

    return (
        <div className={s.container}>
            <Row>
                {status && keysIn(status).map(_key =>
                    <Col sm={4} xs={12} key={`status-key-${_key}`}>
                        <div className={s.status}>
                            <div className={s.title}>{titleCase(_key)}</div>
                            <div className={s.count}>{status[_key]}</div>
                        </div>
                    </Col>
                )}
            </Row>
            <TestCodeEntry />
        </div>
    )
}

export default GlobalAdminStatus