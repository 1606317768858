import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation, useOutletContext, Outlet } from 'react-router-dom'
import { Row, Col, FormControl, FormGroup, FormLabel, Button, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import FormCheck from 'react-bootstrap/FormCheck'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { saveSlideShowSetting, setMedalistPageState, getMedalistScoreboard, getRyderCupScoreboard, 
    getSkinsAndProxiesScoreboard, getSlideShowPhotos, getMatchesScoreboard } from '@severed-links/common.severedlinks-reducers/groups'
import * as s from './TripSlideShow.scss'

const TripPresentations = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const _tripContext = useOutletContext()
    const { group, groupId, trip, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = _tripContext
    const medalistState = useSelector(state => state.groups.medalistState)
    const [slideShowSlideDuration, setSlideShowSlideDuration] = useState('0')
    const [showAllTripsOnSlideShow, setShowAllTripsOnSlideShow] = useState(false)

    useEffect(() => {
        if (groupId && tripId) {
            setShowAllTripsOnSlideShow(trip.showAllTripsOnSlideShow || false)
            setSlideShowSlideDuration((trip.slideShowSlideDuration || 15).toString())
    
            dispatch(getMedalistScoreboard(groupId, tripId))
            dispatch(getRyderCupScoreboard(groupId, tripId))
            dispatch(getMatchesScoreboard(groupId, tripId))
            dispatch(getSkinsAndProxiesScoreboard(groupId, tripId))
            dispatch(getSlideShowPhotos(groupId, tripId))
        }
    }, [groupId, tripId])

    const setSlideShowSelector = checkedState => {
        setShowAllTripsOnSlideShow(checkedState)
        dispatch(saveSlideShowSetting(groupId, tripId, checkedState, slideShowSlideDuration))
        .then(action => dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Save Slide Show Setting', timeout: 3000 })))
    }

    const handleDurationChange = e => setSlideShowSlideDuration(e.target.value)

    const saveDurationChange = () => {
        dispatch(saveSlideShowSetting(groupId, tripId, showAllTripsOnSlideShow, slideShowSlideDuration))
        .then(action => dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Save Slide Show Setting', timeout: 3000 })))
    }

    const launchSlideShow = () => navigate(`${location.pathname}/slide-show`)
    const launchAwardPresentation = () => navigate(`${location.pathname}/award-presentation`)

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h4>Presentations</h4>
                    <p>Give out your awards and provide some entertainment during group gatherings.</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <FormLabel style={{ display: 'block' }}>Slide show photo selection</FormLabel>
                        <FormCheck type='switch' checked={showAllTripsOnSlideShow} 
                            label={<FontAwesomeIcon name='video' tag='i' />}
                            onChange={e => setSlideShowSelector(e.target.checked)} />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel style={{ display: 'block' }}>Slide show photo duration (seconds)</FormLabel>
                        <InputGroup style={{ maxWidth: '150px' }}>
                            <FormControl  style={{ textAlign: 'right' }}
                                value={slideShowSlideDuration} 
                                onChange={e => handleDurationChange(e)} />
                            <Button onClick={() => saveDurationChange()} variant='light'><FontAwesomeIcon name='check' /> save</Button>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className={s.controls}>
                        <FormLabel style={{ display: 'block' }}>Launch presentation</FormLabel>
                        <Row>
                            <Col sm={6} xs={12}>
                                <Button style={{ width: '100%' }} onClick={() => launchSlideShow()} variant='primary'><FontAwesomeIcon name='star' /> launch slide show</Button>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Button style={{ width: '100%' }} onClick={() => launchAwardPresentation()} variant='primary'><FontAwesomeIcon name='trophy' /> launch award presentation</Button>
                            </Col>
                        </Row>
                    </FormGroup>
                    <p className={s.noControls}>This module cannot be used on small screen sizes.</p>
                </Col>
            </Row>
            <Outlet context={_tripContext} />
        </div>
    )
}

export default TripPresentations