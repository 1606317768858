import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, ListGroup, Image, Button, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './MyTripPayout.scss'
import Modal from '@severed-links/common.modal'
import numeral from 'numeral'
import { getMyPayout } from '@severed-links/common.severedlinks-reducers/groups'
import { CURRENCY_FORMAT } from '@severed-links/common.severedlinks-constants'
import { List } from 'immutable'

const MyTripPayout = ({ groupId = null, tripId = null, payout = {}, isTripComplete = false }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const [show, setShow] = useState(false)
    const { isPaid, items, total } = payout

    useEffect(() => {
        if (groupId && tripId && playerId && isTripComplete) {
            dispatch(getMyPayout(groupId, tripId, playerId))
        }
    }, [, groupId, tripId, isTripComplete])

    const _variant = isPaid ? 'success' : 'danger'
    if (!payout || !items || !items.length || !total) return null
    return (
        <>
        <Card className={s.container + ' ' + s[_variant]}>
            <Card.Header className={s.header}>
                <div className={s.icon}><FontAwesomeIcon name='money-bill' /></div>
                <div className={s.title}>Your Winnings</div>
                <div className={s.badge}>
                    {!isPaid ? <Badge bg={_variant} text='light'>Unpaid!</Badge> : null}
                </div>
                <div className={s.amount}>{numeral(total).format(CURRENCY_FORMAT)}</div>
                {items && items.length ?
                <div className={s.controls}>
                    <Button className={s.showButton} size='sm'
                        onClick={() => setShow(!show)}>
                        <FontAwesomeIcon name={`chevron-${show ? 'up' : 'down'}`} />
                    </Button>
                </div>
                : null}
            </Card.Header>
            {items && items.length ?
            <div className={s.content + (show ? ` ${s.show}` : '')}>
            <ListGroup className={s.payoutList} variant='flush'>
            {items && items.map((i, _index) => 
            <ListGroup.Item className={s.item}
                key={`trip-payout-item-${tripId}-${_index}`}>
                <FontAwesomeIcon className={s.icon} name={i.type === 'Proxy' ? 'star' : i.type === 'Ryder' ? 'users' : i.type === 'Medalist' ? 'trophy' : 'flag'} fixedWidth />
                <div className={s.description}>{i.description}</div>
                <div className={s.amount}>{numeral(i.amount || 0.00).format(CURRENCY_FORMAT)}</div>
            </ListGroup.Item>
            )}
            <ListGroup.Item className={s.item + ' ' + s.total}>
                <FontAwesomeIcon className={s.icon} name={'money-bill'} fixedWidth />
                <div className={s.description}>Total</div>
                <div className={s.amount}>{numeral(total || 0.00).format(CURRENCY_FORMAT)}</div>
            </ListGroup.Item>
            </ListGroup>
            </div>
            : null}
        </Card>
                {/* 
                        <Icon type='font-awesome-5' name={item.type === 'Proxy' ? 'star' : item.type === 'Ryder' ? 'users' : item.type === 'Medalist' ? 'trophy' : 'flag'} color={Colors.infoTextColor} size={18} />
                    */}
        </>
    )
}

export default MyTripPayout
