// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyRoomAssignment__myRoomAssignment___VwBbM .MyRoomAssignment__link___VqtWo{font-size:inherit;padding:0px;line-height:inherit;border-width:0px;margin-left:5px;margin-top:-1px}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/MyRoomAssignment.scss"],"names":[],"mappings":"AACI,4EACI,iBAAA,CACA,WAAA,CACA,mBAAA,CACA,gBAAA,CACA,eAAA,CACA,eAAA","sourcesContent":[".myRoomAssignment {\n    .link {\n        font-size: inherit;\n        padding: 0px;\n        line-height: inherit;\n        border-width: 0px;\n        margin-left: 5px;\n        margin-top: -1px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var myRoomAssignment = `MyRoomAssignment__myRoomAssignment___VwBbM`;
export var link = `MyRoomAssignment__link___VqtWo`;
export default ___CSS_LOADER_EXPORT___;
