import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { FormGroup, Image, OverlayTrigger, Tooltip, ListGroup, FormControl, FormLabel } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripChat.scss'
import { setTripValue } from '@severed-links/common.severedlinks-reducers/groups'
import Button from '@severed-links/common.button'
import TextareaAutosize from 'react-autosize-textarea'
import moment from 'moment-timezone'
import { orderBy, trim, last, isEqual } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { FRIENDLY_LONG_DATE_FORMAT, playerAvatarUrl, commonImageUrl, FRIENDLY_SHORT_TIME_FORMAT_WITH_SECONDS } from '@severed-links/common.severedlinks-constants'
import { getComments, postComment, deleteComment, saveTripPlayerSetting, deleteTripComment } from '@severed-links/common.severedlinks-reducers/groups'
import { animateScroll } from 'react-scroll'
import Confirm from '@severed-links/common.confirm'
import Modal from '@severed-links/common.modal'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import OnlineUsers from '../Groups/OnlineUsers'
import validator from 'validator'
import { usePrevious } from '../../hooks/hooks'

const TripChatTextComment = ({ comment, onMenuPress, isLatestComment = false, trip = {} }) => (
    <div className={s.comment + ' ' + s.text + (comment.type === 'MatchUpdate' ? ' ' + s.matchUpdate : '') + (comment.type === 'PickPlayer' ? ' ' + s.pickPlayer : '') + (comment.isMe ? ' ' + s.isMe : '') + (isLatestComment ? ` ${s.isLatestComment}` : '') + (comment.isDeleting ? ` ${s.isDeleting}` : '')}>
        {comment.type !== 'MatchUpdate' ?
        <div className={s.avatar}>
            <Image src={playerAvatarUrl(comment.player.imageUrl, true)} />
        </div>
        : null}
        <div className={s.textContainer}>
            {comment.type !== 'MatchUpdate' ?
                <div className={s.name}>{comment.player.playerName}</div>
            : null}
            <OverlayTrigger placement='top' trigger={['hover','focus']} overlay={<Tooltip id={`tooltip-comment-date-${comment._id}`}>{moment(comment.entered).format(FRIENDLY_SHORT_TIME_FORMAT_WITH_SECONDS)}</Tooltip>}>
                <div className={s.text}
                    style={comment.type === 'PickPlayer' ? { color: trip[`${(comment.data.team || '').toLowerCase()}TextColor`], backgroundColor: trip[`${(comment.data.team || '').toLowerCase()}BackgroundColor`], borderColor: trip[`${(comment.data.team || '').toLowerCase()}BorderColor`] }
                : null}>
                {comment.type === 'PickPlayer' ? <div><Image className={s.flag} src={trip[`${(comment.data.team || '').toLowerCase()}FullTeamImageUrl`]} /><Image className={s.pickedPlayerAvatar} src={playerAvatarUrl(comment.data.imageUrl, true)} /></div> : null}
                {comment.type === 'PickPlayer' ? <div className={s.pickedPlayerName}>{comment.data.playerName}</div> : null}
                {comment.type === 'PickPlayer' ? <div className={s.pickIsIn}>The pick is in...</div> : null}
                {comment.type === 'Text' ? <div>{comment.comment}</div> : null}
                {comment.type === 'MatchUpdate' ? 
                <div className={s.matchUpdateContainer}>
                    <div className={s.matchTitle}>{comment.comment}</div>
                    <div className={s.matchAvatars}>
                    {comment.data && comment.data.players && comment.data.players.map((i, index) =>
                        <div className={s.matchAvatar} key={`avatar-${index}`}
                            style={{ backgroundColor: trip[`${(i.team || '').toLowerCase()}BackgroundColor`], borderColor: trip[`${(i.team || '').toLowerCase()}BorderColor`], color: trip[`${(i.team || '').toLowerCase()}TextColor`] }}>
                            <Image src={playerAvatarUrl(i.imageUrl, true)} />                            
                        </div>
                    )}
                    </div>
                    <div className={s.matchNames}>
                    {comment.data && comment.data.players && comment.data.players.map((i, index) =>
                        <div className={s.matchName} key={`name-${index}`}
                            style={{ backgroundColor: trip[`${(i.team || '').toLowerCase()}BackgroundColor`], borderColor: trip[`${(i.team || '').toLowerCase()}BorderColor`], color: trip[`${(i.team || '').toLowerCase()}TextColor`] }}>
                            <Image className={s.matchFlag} src={trip[`${(i.team || '').toLowerCase()}FullTeamImageUrl`]} />
                            <div className={s.nameSpan}>{i.name} &nbsp;</div>
                            <div className={s.hcp}>{i.hcp}</div>
                        </div>
                    )}
                    </div>
                </div>
                : null}
                    
                </div>
            </OverlayTrigger>
            <CommentMenuButton comment={comment} onClick={onMenuPress} />
        </div>
    </div>
)

const TripChatMiniComment = ({ comment, onMenuPress, isLatestComment = false }) => (
    <div className={s.comment + ' ' + s.mini + (isLatestComment ? ` ${s.isLatestComment}` : '') + (comment.isDeleting ? ` ${s.isDeleting}` : '')}>
        <div className={s.textContainer}>
            <OverlayTrigger placement='top' trigger={['hover','focus']} overlay={<Tooltip id={`tooltip-comment-date-${comment._id}`}>{moment(comment.entered).format(FRIENDLY_SHORT_TIME_FORMAT_WITH_SECONDS)}</Tooltip>}>
                <div className={s.text}>{comment.comment}</div>
            </OverlayTrigger>
        </div>
        <CommentMenuButton comment={comment} onClick={onMenuPress} />
    </div>
)

const TripChatDateHeader = ({ comment, index, comments, timeZoneId = 'America/New_York' }) => (
    index === 0 || !moment.tz(comment.entered, timeZoneId).isSame(moment.tz(comments[index - 1].entered, timeZoneId), 'day') ? 
        <div className={s.dateHeader}>{moment.tz(comment.entered, timeZoneId).format(FRIENDLY_LONG_DATE_FORMAT)}</div>
    : null
)

const CommentMenu = ({ commentId = null, onClose, onDelete }) => (
    <Modal show={!!commentId} showFooter={false}
        heading={`Comment Actions`}
        onClose={() => onClose()}>

    <ListGroup className={s.commentMenuList}>
        <ListGroup.Item action onClick={() => void(0)} className={s.commentMenuItem + ' ' + s.deleteComment}>
            <FontAwesomeIcon name='trash' className={s.icon} />
            <div className={s.title}>Delete comment</div>
            <div>
                <Confirm title={'Delete Comment'}
                    onConfirm={() => onDelete(commentId)}
                    confirmText='delete comment'
                    body={<div><p>Are you sure you want to delete this comment?</p></div>}
                    variant='danger' buttonIcon='times' />
            </div>
        </ListGroup.Item>

    </ListGroup>
    </Modal>
)

const CommentMenuButton = ({ comment = {}, onClick }) => (
    comment.canDelete ?
    <Button onClick={() => onClick(comment._id)} 
        className={s.commentMenuButton + (comment.isMe ? ` ${s.isLeft}` : '')}
        icon='ellipsis-v' />
    : null
)

const TripChat = ({ group = {}, trip = {}, isGlobalAdmin = false }) => {

    const dispatch = useDispatch()
    const groupId = group._id || null
    const tripId = trip._id || null
    const isAdmin = (group.role || {}).isAdmin || isGlobalAdmin
    const playerId = useSelector(state => state.account._id)
    const timeZoneId = useSelector(state => state.account.time_zone)

    const sortComments = (_comments = []) => orderBy(_comments, ['entered'], ['asc'])
        .map(c => ({ ...c, isMe: c.playerId === playerId, canDelete: c.playerId === playerId || isAdmin, data: c.data || null }))

    const lastCommentView = (trip.playerSettings || {}).lastCommentView || null
    const comments = sortComments(trip.comments || [])
    const commentCount = comments.length
    const previousCommentCount = usePrevious(commentCount) || 0
    const showChat = trip.showChat || false
    const [newComment, setNewComment] = useState('')
    const [imageUrl, setImageUrl] = useState(null)
    const [showImageAdd, setShowImageAdd] = useState(false)
    const [commentMenuId, setCommentMenuId] = useState(null)
    const _lastComment = commentCount ? last(comments) : null
    const lastCommentId = (_lastComment || {})._id || null
    const lastCommentTime = (_lastComment || {}).updatedAt || null
    const commentIdToDelete = (comments.find(i => i.isDeleting) || {})._id || null

    useEffect(() => {
        if (groupId && tripId) {
            dispatch(getComments(groupId, tripId))
        }
    }, [])

    useEffect(() => {
        if (showChat) {
            dispatch(getComments(groupId, tripId))
        }
    }, [showChat])

    useEffect(() => {
        document.body.style.overflow = showChat ? 'hidden' : 'unset'
    }, [showChat])

    useEffect(() => {
        if (showChat && commentCount && moment(lastCommentView).isBefore(moment(lastCommentTime))) {
            dispatch(saveTripPlayerSetting(groupId, tripId, 'lastCommentView', moment().toISOString()))
        }
    }, [showChat, commentCount, lastCommentTime, lastCommentView])

    useEffect(() => scrollToBottomOfComments(true), [commentCount, _lastComment])

    useEffect(() => {
        if (commentIdToDelete) {
            setTimeout(() => dispatch(deleteTripComment({ groupId, tripId, _id: commentIdToDelete })), 600)
        }
    }, [commentIdToDelete])

    const close = () => {
        dispatch(setTripValue(groupId, tripId, 'showChat', false))
    }

    const handleEnterKey = e => {
        if (e.key === 'Enter' && newComment) send()
    }

    const send = () => {
        const comment = trim(newComment)
        setNewComment('')
        setImageUrl(null)
        if (!validator.isEmpty(comment)) {
            setNewComment('')
            dispatch(postComment(groupId, tripId, comment, imageUrl))
        }
    }

    const scrollToBottomOfComments = (newListIsLonger = false) => {
        animateScroll.scrollToBottom({ containerId: `group-${groupId}-trip-${tripId}-chat-comment-container` })
    }

    const handleShowCommentMenu = _commentId => setCommentMenuId(_commentId)
    const handleHideCommentMenu = () => setCommentMenuId(null)

    const doDeleteComment = _commentId => {
        dispatch(deleteComment(groupId, tripId, _commentId))
        setCommentMenuId(null)
    }

    return (
        <>
        <div className={s.container + (showChat ? ` ${s.chatIsOpen}` : '')}>
            <div className={s.header}>
                <FontAwesomeIcon name='comment' className={s.headerIcon} />
                <h5 className={s.title}> Comments</h5>
                <div className={s.close} onClick={() => close()}><FontAwesomeIcon name='times' /></div>
            </div>
            <div className={s.onlineUsers}>
                <OnlineUsers group={group} showCommentButton={false} isContained />
            </div>
            <div className={s.content}>
                <div className={s.commentContainer} id={`group-${groupId}-trip-${tripId}-chat-comment-container`}>
                    {comments && comments.map((i, index) => 
                    <div key={`comments-${groupId}-${tripId}-id-${i._id}`}>
                    <TripChatDateHeader comment={i} index={index} comments={comments} timeZoneId={timeZoneId} />
                    {i.type === 'Text' || i.type === 'PickPlayer' || i.type === 'MatchUpdate' ? <TripChatTextComment comment={i} trip={trip} onMenuPress={handleShowCommentMenu} isLatestComment={i._id === lastCommentId} /> : null}
                    {i.type === 'Mini' || i.type === 'RemovePlayer' ? <TripChatMiniComment comment={i} onMenuPress={handleShowCommentMenu} isLatestComment={i._id === lastCommentId} /> : null}
                    </div>
                    )}
                </div>
            </div>
            <div className={s.new}>
                <FormGroup className={s.formGroup + ' ' + s.formGroupCommentBox}>
                    <TextareaAutosize value={newComment} rows={2}
                        placeholder='[Comment here...]'
                        className={'form-control ' + s.textbox}
                        onKeyUp={e => handleEnterKey(e)}
                        onChange={e => setNewComment(e.target.value)} />
                    {/* <Button clear variant='secondary' icon='image' className={s.imageButton} disabled={!!imageUrl} onClick={() => setShowImageAdd(true)} />
                    <Modal show={showImageAdd} onClose={() => setShowImageAdd(false)}
                        heading={'Add image to comment'}
                        actionButtonStyle='primary'
                        actionButtonText='add image'
                        >
                    <div>
                        <FormGroup>
                            <FormLabel>Enter an image URL (only https: images supported)</FormLabel>
                            <FormControl value={imageUrl || ''} onChange={e => setImageUrl(e.target.value)} />
                        </FormGroup>
                    </div>
                    </Modal> */}
                </FormGroup>
            </div>

        </div>
        <div className={s.tripChatOverlay + (showChat ? ` ${s.show}` : '')} onClick={() => close()} />
        <CommentMenu commentId={commentMenuId} onClose={handleHideCommentMenu} onDelete={doDeleteComment} />
        </>
    )
}

export default TripChat