import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useOutletContext, Outlet } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripRoundScorecardAdmin.scss'
import { getAdminScorecardForRound } from '@severed-links/common.severedlinks-reducers/scorecard'
import { runScorecardAndMatchUpdateAfterCourseOrScorecardChange } from '@severed-links/common.severedlinks-reducers/groups'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { API_URL } from '@severed-links/common.severedlinks-constants'

const TripRoundScorecardAdmin = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const _roundContext = useOutletContext()
    const { group, trip, groupId, tripId, round, roundSubTab, roundParam, roundNumber, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = _roundContext
    const [runningScorecardUpdate, setRunningScorecardUpdate] = useState(false)
    const _token = useSelector(state => state.account.access_token)

    const runScorecardUpdate = () => {
        setRunningScorecardUpdate(true)
        dispatch(runScorecardAndMatchUpdateAfterCourseOrScorecardChange(groupId, tripId, round.round))
        .then(action => {
            dispatch(createNotification(action.payload))
            dispatch(getAdminScorecardForRound(groupId, tripId, round.round))
            .then(() => setRunningScorecardUpdate(false))
        })
    }

    const doShowScoreboard = () => navigate(`${location.pathname}/show`, { replace: true })

    const downloadScorecardsForRound = () => {
        const _remoteUrl = `${API_URL}Scorecard/${groupId}/${tripId}/${round.round}/PrintableScorecardsForRound`
        fetch(_remoteUrl, { headers: { 'Authorization': `Bearer ${_token}` } }) 
        .then(response => response.blob())
        .then(blob => { 
            var _url = window.URL.createObjectURL(blob)
            const _opener = window.open(_url, '_blank')
            if (_opener?.focus) _opener.focus()
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div className={s.container}>
            <h4>Round Scorecard</h4>
            <p>Didn't have coverage on the course?  Enter your hole scores here.</p>
            <p><Button variant='light' onClick={() => doShowScoreboard()}><FontAwesomeIcon name='clipboard-list' /> open scorecard</Button></p>
            <p><Button variant='light' onClick={() => downloadScorecardsForRound()}><FontAwesomeIcon name='print' /> print scorecards</Button></p>            
            <p>
                <Confirm title={'Run Scorecard and Match Update'}
                    onConfirm={() => runScorecardUpdate()}
                    confirmText='run scorecard and match update'
                    body={<div><p>Are you sure you want to run the update for this round?</p></div>}
                    variant='warning' buttonIcon='sync-alt' buttonSpin={runningScorecardUpdate}
                    disabled={runningScorecardUpdate} buttonText={`${runningScorecardUpdate ? 'running' : 'run'} scorecard and match update${runningScorecardUpdate ? '...' : ''}`} />
            </p>
            <p>
                (this is rarely needed unless the course or scorecard data was updated)
            </p>
            <Outlet context={_roundContext} />
        </div>
    )
}

export default TripRoundScorecardAdmin