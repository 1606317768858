import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { setAccountDeletionState } from '@severed-links/common.severedlinks-reducers/accountDeletion'
import pluralize from 'pluralize'
import * as s from './AccountDeletionStep.scss'
import PlayerListItem from '../Player/PlayerListItem'

const PersonalInfoStep3 = forwardRef(({ jumpToStep, ...props }, _ref) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const _accountDeletion = useSelector(state => state.accountDeletion)
    const { 
        isFetching, groupsToDelete, groupsDeletionConfirmed, groupsToDeleteCount, 
        groupsToDeleteAdminCount, tripPlayerCount, tripMatchCount, wonLossRecordCount, 
        playerInfoSelections, playerInfoSelection, firstName, lastName, address, 
        city, state, zip, emailAddress, cellPhone, imageUrl, accountDeletionConfirmed, 
    } = _accountDeletion
    const _isValidated = (!groupsToDelete.length || groupsDeletionConfirmed)
        && playerInfoSelections.length && (playerInfoSelections.map(i => i.value).includes(playerInfoSelection))

    useEffect(() => {
    }, [])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValidated,
    }))

    const handlePersonalInfoSelect = _playerInfoSelection => {
        dispatch(setAccountDeletionState({ playerInfoSelection: _playerInfoSelection !== playerInfoSelection ? _playerInfoSelection : '' }))
    }

    return (
        <div className={s.container}>
            <h4>Consent to Remaining Personal Information</h4>
            <p>
                Your player information may be linked to other data in our database. 
                After deletion of your account, may we continue to link your first name, 
                last name, and avatar image to your account ID? Your remaining personal 
                information will be removed (cell phone, email, address, etc.).
            </p>
            <p>Please select one of the options for your information (a representation is provided about what future references to your user account will look like):</p>
            {playerInfoSelections && playerInfoSelections.map(i =>
            <Card className={s.card + (playerInfoSelection && playerInfoSelection === i.value ? ` ${s.isSelected}` : '')} key={`account-deletion-personal-info-card-${i.value}`}
                onClick={() => handlePersonalInfoSelect(i.value)}>
                <Card.Header className={s.cardHeader}>
                    <div className={s.icon}><FontAwesomeIcon name={playerInfoSelection && playerInfoSelection === i.value ? 'check-circle' : 'circle'} /></div>
                    <div className={s.title}>{i.title}</div>
                </Card.Header>
                <ListGroup variant='flush' className={s.list}>
                    <PlayerListItem player={i.model} clickDisabled />
                </ListGroup>
            </Card>
            )}
        </div>
    )
})

export default PersonalInfoStep3
