import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { ListGroup, Card, Image, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../Player/PlayerListItem'
import Player from '../Player/Player'
import * as s from './TripTeams.scss'
import { commonImageUrl } from '@severed-links/common.severedlinks-constants'
import { sumBy, flatten, orderBy, uniqBy } from 'lodash'
import TripPlayerCaptainSetter from './Admin/TripPlayerCaptainSetter'
import pluralize from 'pluralize'

const PanelItem = ({ group, trip, classKey, teamId = '', panelName, players, hasHandicaps = false, showPoints = false }) => (
<Card className={s.list + (classKey ? ' ' + classKey : '')}>
    <Card.Header className={s.header} style={teamId ? { backgroundColor: trip[`${teamId.toLowerCase()}BackgroundColor`], color: trip[`${teamId.toLowerCase()}TextColor`], borderColor: trip[`${teamId.toLowerCase()}BorderColor`] } : null}>
        {teamId === 'USA' || teamId === 'EUR' ? <Image className={s.flag} src={trip[`${teamId.toLowerCase()}FullTeamImageUrl`]} /> : null}
        <div>{panelName}</div>
        {teamId === 'USA' || teamId === 'EUR' ?
        <div className={s.captainSetter}>
            <TripPlayerCaptainSetter group={group} trip={trip} player={teamId === 'EUR' ? trip.eurCaptain : trip.usaCaptain} team={teamId} teamName={trip[`${teamId.toLowerCase()}TeamName`]} />
        </div>
        : null}
    </Card.Header>
    <ListGroup variant='flush'>
    {players.map(i =>
        <PlayerListItem player={i} key={i.playerID}
            showRole={false} showHcp={hasHandicaps}
            showPoints={showPoints} />
    )}
    </ListGroup>
    {hasHandicaps ?
    <Card.Footer className={s.strokeTotal}>
    {teamId === 'USA' || teamId === 'EUR' ? pluralize('stroke', sumBy(players, 'hcp'), true) : pluralize('player', (players || []).length, true)}
    </Card.Footer>
    : null}
</Card>
)

const TripTeams = () => {

    const { group, trip, groupId, tripId } = useOutletContext()
    if (!trip || !group) return null
    const usa = trip.usaPlayers || []
    const eur = trip.eurPlayers || []
    const unassignedPlayers = trip.unassignedPlayers || []
    const hasHandicaps = trip.hasHandicaps
    const hasTeams = usa.length + eur.length > 0
    const hasUsa = usa.length > 0
    const hasEur = eur.length > 0
    const hasUnassigned = unassignedPlayers.length > 0
    const teamCols = hasTeams && hasUnassigned ? 4 : 6
    const tablesShown = (hasUsa ? 1 : 0) + (hasEur ? 1 : 0) + (hasUnassigned ? 1 : 0)
    const _overrides = orderBy(uniqBy(flatten([...usa, ...eur].map(i => i.teeBoxOverrides || [])), 'round'), ['round'], ['asc'])
    const _showPoints = (trip.isDuring || trip.isAfter) && (trip.scoreboard?.matches || []).length > 0
    return (
        <div className={s.container}>
            <h3>Trip Teams</h3>
            {!hasUsa && !hasEur && !hasUnassigned ? 
            <p>No players located for this trip.</p>
            : null}
            <div className={s.tripTeamsContainer}>
            {hasUsa ?
                <PanelItem panelName={trip.usaTeamName} teamId='USA' classKey={s[`list${tablesShown}`]} players={usa} group={group} trip={trip} hasHandicaps={hasHandicaps} showPoints={_showPoints} />
            : null}
            {hasEur ?
                <PanelItem panelName={trip.eurTeamName} teamId='EUR' classKey={s[`list${tablesShown}`]} players={eur} group={group} trip={trip} hasHandicaps={hasHandicaps} showPoints={_showPoints} />
            : null}
            {_overrides && _overrides.length ? 
            <div className={s.teeBoxOverrideLegendContainer}>
                <div className={s.icons}>
                {_overrides && _overrides.map(x =>
                <Badge bg={null} text={null} 
                    style={{ minWidth: '20px', color: x.fontColor, backgroundColor: x.backgroundColor, borderColor: x.fontColor, borderWidth: 1, borderStyle: 'solid' }} 
                    key={`team-tee-box-legend-${x.round}`}>
                    {x.round}
                </Badge>
                )}
                </div>
                <div className={s.content}>
                    <h4>Legend:</h4>
                    <p>Different tee box is allowed</p>
                </div>
                
            </div>
            : null}
            {hasUnassigned ?
                <PanelItem panelName='Unassigned Players' classKey={s.unassigned + ' ' + s[`list${tablesShown}`]} players={unassignedPlayers} group={group} trip={trip} hasHandicaps={hasHandicaps} />
            : null}
            </div>
        </div>
    )
}

export default TripTeams