import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Outlet, Link, useOutletContext, useMatch } from 'react-router-dom'
import { Nav, Navbar, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { first, last, isEqual } from 'lodash'
import { getTripSettings, getSkinsAndProxiesScoreboard, 
    getMatchesScoreboard, getTripRounds, getAdminMatches, getPlayerLists,
    getPaymentSetup, getTripPayouts, getMockAwards, getMockAwardsForAdmin, getMatchPlayFormats,
    getEvents, getPlayerEntryFeePayments, getPlayers, getHandicapChecker, 
    getPlayerWonLossRecordsForTrip, getAdminNotesForTrip } from '@severed-links/common.severedlinks-reducers/groups'
import { getSkinTypes, getHolesPlayedOptions } from '@severed-links/common.severedlinks-reducers/trips'
import { routingPath } from '@severed-links/common.severedlinks-constants'
import * as s from './TripAdmin.scss'

import TripValidationAlertList from './TripValidationAlertList'

const TripAdmin = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const _tripContext = useOutletContext()
    const { group, groupId, trip, tripId, isGlobalAdmin, isGlobalAdminPath } = _tripContext
    const path = routingPath(groupId, isGlobalAdminPath, (group.name || ' ').substring(0,1))
    const match = useMatch(`${path}trip/:tripId/admin/:tripSubTab/*`)
    const tripSubTab = ((match || {}).params || {}).tripSubTab || null

    const _menuItems = [
        { name: 'Basics', icon: 'suitcase', path: `basics`, disabled: false },
        { name: 'Notes', icon: 'paperclip', path: `notes`, disabled: false },
        { name: 'Contacts', icon: 'address-book', path: `contacts`, disabled: false },
        { name: 'Lodging', icon: 'building', path: `lodging`, disabled: false },
        { name: 'Players', icon: 'users', path: `players`, disabled: false },
        { name: 'Rounds', icon: 'list', path: `rounds`, disabled: false },
        { name: 'Skins', icon: 'flag', path: `skins`, disabled: false },
        { name: 'Payments', icon: 'money-bill-alt', path: `payments`, disabled: false },
        { name: 'Events', icon: 'calendar', path: `events`, disabled: false },
        { name: 'Photos', icon: 'camera', path: `photos`, disabled: false },
        { name: 'Messaging', icon: 'envelope', path: `messaging`, disabled: false },
        { name: 'Comments', icon: 'comment', path: `comments`, disabled: false },
        { name: 'Presentation', icon: 'film', path: `presentation`, disabled: false },
        { name: group.mvpAwardName || 'MVP', icon: 'trophy', path: `mvp-award`, disabled: false },
        { name: 'Recap', icon: 'file', path: `recap`, disabled: false },
        { name: `${group.mockAwardName}s`, icon: 'smile-wink', path: `mock-awards`, disabled: false },
    ]

    const _activeMenuItem = _menuItems.find(i => i.path === tripSubTab)

    useEffect(() => {
        if (groupId && tripId) {
            dispatch(getPlayerLists(groupId, tripId))
            .then(() => {
                // need player lists loaded first for match builder grid
                dispatch(getAdminMatches(groupId, tripId))
            })
            dispatch(getTripRounds(groupId, tripId))
            dispatch(getSkinsAndProxiesScoreboard(groupId, tripId))
            dispatch(getMatchesScoreboard(groupId, tripId))
            dispatch(getPaymentSetup(groupId, tripId))
            dispatch(getPlayerEntryFeePayments(groupId, tripId))
            dispatch(getTripPayouts(groupId, tripId))
            dispatch(getMockAwards(groupId, tripId))
            dispatch(getMockAwardsForAdmin(groupId, tripId))
            dispatch(getMatchPlayFormats(groupId, tripId))
            dispatch(getEvents(groupId, tripId))
            dispatch(getSkinTypes())
            dispatch(getHolesPlayedOptions())
            dispatch(getPlayers(groupId))
            dispatch(getHandicapChecker(groupId, tripId))
            dispatch(getPlayerWonLossRecordsForTrip(groupId, tripId))
            dispatch(getAdminNotesForTrip(groupId, tripId))
        }
    }, [groupId, tripId])

    useEffect(() => {
        goToFirstMenuItem()
    }, [tripSubTab, group.name, _menuItems.length])

    const goToFirstMenuItem = () => {
        if (!tripSubTab && group.name && _menuItems.length) {
            const _path = `${pathPrefix()}/${first(_menuItems).path}`
            setTimeout(() => navigate(_path, { replace: true }), 10)
        } 
    }

    const pathPrefix = () => {
        if (!trip || !group) return null
        const path = routingPath(groupId, isGlobalAdminPath, (group.name || ' ').substring(0,1).toUpperCase())
        return `${path}trip/${tripId}/admin`
    }

    const getMenuEntryCount = adminPath => {
        const count = (trip.validationAlerts || []).filter(i => i.adminPath === adminPath).length
        return count ? <Badge bg='danger' className={s.badge}>{count}</Badge> : null
    }

    const handleSelect = e => navigate(`${pathPrefix()}/${e}`)

    return (
        <div className={s.container}>
            <Navbar className={s.smallMenu + (isGlobalAdminPath ? ' ' + s.isGlobalAdmin : '')} expand='md' collapseOnSelect bg='light' variant='light'>
                {_activeMenuItem ? <Navbar.Brand className={s.smallMenuNavbarBrand}><Link to={`${pathPrefix()}/${_activeMenuItem.path}`}><FontAwesomeIcon className={s.icon} name={_activeMenuItem.icon} /><span>{_activeMenuItem.name}</span></Link></Navbar.Brand> : null}
                <Navbar.Toggle className={s.smallMenuNavbarToggle} />
                <Navbar.Collapse>
                    <Nav className={s.smallMenuNav} activeKey={tripSubTab} onSelect={e => handleSelect(e)}>
                    {_menuItems && _menuItems.map(i =>
                        <Nav.Item className={s.smallMenuNavItem} key={`trip-admin-small-nav-${i.path}`}><Nav.Link eventKey={i.path} disabled={i.disabled}>{i.icon ? <FontAwesomeIcon fixedWidth name={i.icon} /> : null} {i.name} {getMenuEntryCount(i.path)}</Nav.Link></Nav.Item>
                    )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className={s.fixedMenu + (isGlobalAdminPath ? ' ' + s.isGlobalAdmin : '')}>
                <Nav variant='pills' className='flex-column' activeKey={tripSubTab} onSelect={e => handleSelect(e)}>
                {_menuItems && _menuItems.map(i =>
                    <Nav.Item key={`trip-admin-main-nav-${i.path}`} className={s.item}>
                        <Nav.Link eventKey={i.path} disabled={i.disabled} className={s.link + (i.path === tripSubTab ? ` ${s.active}` : ``)}>
                            {i.icon ? <div className={s.icon}><FontAwesomeIcon fixedWidth name={i.icon} /></div> : null}
                            <div className={s.name}>{i.name}</div>
                            {getMenuEntryCount(i.path)}
                        </Nav.Link>
                    </Nav.Item>
                )}
                </Nav>
            </div>
            <div className={s.content}>
                <TripValidationAlertList group={group} trip={trip} path={tripSubTab} isGlobalAdmin={isGlobalAdmin} isGlobalAdminPath={isGlobalAdminPath} />
                <Outlet context={_tripContext} />
            </div>
        </div>
    )
}

export default TripAdmin