import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

const RoleLabel = ({ role, size, className }) => (
    <Badge className={className} 
        bg={role.variant} 
        text={role.variant === 'light' || role.variant === 'warning' ? 'dark' : 'light'} 
        size={size}>
        {role.description === 'Joined' ? 'Active Group Member' : role.description}
    </Badge>
)

const WrapperTag = ({ size = 'xs', children, style = {} }) => React.createElement(size === 'xs' ? React.Fragment : `h${size === 'sm' ? '5' : size === 'md' ? '4' : size === 'lg' ? '3' : '2'}`, size !== 'xs' ? { style } : null, children)

const GroupRole = ({ role, size = 'xs', className = '', showJoined = false }) => {

    const _badgeProps = { role, size, className }
    const _style = { marginBottom: '0px', marginTop: '0px' }
    if (!role || (!role.isAdmin && !showJoined && role.isActive)) return null
    // Admin Joined Invited Inactive Reactivate
    return (
        <WrapperTag size={size} style={_style}><RoleLabel {..._badgeProps} /></WrapperTag> 
    )
}

export default GroupRole