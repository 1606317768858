import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { ListGroup, Button, Image, DropdownButton, Dropdown } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripProxyAdmin.scss'
import { orderBy, first, sumBy } from 'lodash'
import PlayerListItem from '../../Player/PlayerListItem'
import Confirm from '@severed-links/common.confirm'
import Modal from '@severed-links/common.modal'
import { getProxyTypes, getSkinsAndProxiesScoreboard, 
    saveProxy, deleteProxy, deleteProxyAuditItem } from '@severed-links/common.severedlinks-reducers/groups'
import { getGolfCourseHoles } from '@severed-links/common.severedlinks-reducers/scorecard'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import { FRIENDLY_SHORT_TIME_FORMAT, playerAvatarBigThumb } from '@severed-links/common.severedlinks-constants'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { animateScroll, Element } from 'react-scroll'
import ProxyDistanceEditor from '../ProxyDistanceEditor'
import ProxyDistanceDisplay from '../ProxyDistanceDisplay'

const HoleInfo = ({ proxy = {} }) => (
    <span> 
        {proxy.proxyID ? <span style={{ marginRight: '10px' }}><b>{proxy.proxyName}</b></span> : null}
        {' '}
        <span><FontAwesomeIcon name='flag' /></span>
        {' '}
        <span>#{proxy.hole}</span>
        {' '}
        {proxy && proxy.yards ? <span>Par {proxy.par}</span> : null}
        {' '}
        {proxy && proxy.yards ? <span>{proxy.yards} Yards</span> : null}
    </span> 
)

const ProxyControls = ({ onDeleteProxy, onShowAuditList, onShowProxyWinnerModal, round, proxy = {} }) => {
    if (!round || !proxy) return null
    return (
        <div className={s.proxyControlsContainer}>
            {proxy.list && proxy.list.length > 0 ? 
            <Button size='sm' variant='info' className={s.proxyControlButton}
                onClick={() => onShowAuditList(proxy)}>
                    <FontAwesomeIcon name='list' /> <span className='d-none d-sm-inline-block'>audit list</span>
            </Button>
            : null}
            <Confirm title={<span>Delete Proxy: <HoleInfo proxy={proxy} /></span>}
                onConfirm={() => onDeleteProxy(proxy)}
                confirmText='delete' size='sm' buttonClassName={s.proxyControlButton}
                body={<div><p>Are you sure you want to delete this proxy from round {round.round}?</p></div>}
                variant='danger' buttonIcon='trash' buttonText={<span className='d-none d-sm-inline-block'>remove</span>} />
            <Button variant='success' className={s.proxyControlButton} onClick={() => onShowProxyWinnerModal(proxy)} size='sm'>
                <FontAwesomeIcon name='trophy' /> <span className='d-none d-sm-inline-block'>set winner</span>
            </Button>
        </div>
    )
}

const TripProxyAdmin = () => {

    const dispatch = useDispatch()
    const proxyTypes = useSelector(state => state.groups.proxyTypes)
    const { group, trip, groupId, tripId, round, roundSubTab, roundParam, roundNumber, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [showUserPicker, setShowUserPicker] = useState(false)
    const [showEditProxy, setShowEditProxy] = useState(false)
    const [editProxy, setEditProxy] = useState({})
    const [winnerProxy, setWinnerProxy] = useState({})
    const [showAuditList, setShowAuditList] = useState(false)
    const [holes, setHoles] = useState([])
    const [golfCourseHoles, setGolfCourseHoles] = useState([])
    const [totalYards, setTotalYards] = useState(0)
    const holesPlayed = round.holesPlayed || []
    const _proxies = (trip.scoreboard || {}).proxies || []
    const list = _proxies.filter(i => i.round === round.round)
    const _holes = (holes || []).filter(i => holesPlayed.includes(i.hole)).map(i => ({ ...i, isProxy: list.some(x => x.hole === i.hole) }))
    const tripPlayers = orderBy([...(trip.usaPlayers || []), ...(trip.eurPlayers || [])], ['lastName', 'firstName'], ['asc', 'asc'])
    const scoreboardUpdated = (trip.scoreboard || {}).scoreboardUpdated || null

    useEffect(() => {
        dispatch(getProxyTypes(groupId, tripId))
        dispatch(getGolfCourseHoles(round.golfCourseID))
        .then(action => {
            setGolfCourseHoles(action.payload)
            setTotalYards(sumBy(action.payload, 'yards'))
            doEditProxy(1, false)
        })
    }, [round.courseID])

    useEffect(() => {
        setHoles(golfCourseHoles.filter(h => holesPlayed.includes(h.hole)))
    }, [totalYards, holesPlayed.length, Math.max(...holesPlayed), golfCourseHoles.length])

    useEffect(() => {
        if (scoreboardUpdated && (editProxy || {}).hole) {
            const _proxy = trip.scoreboard.proxies.find(i => i.round === round.round && i.hole === editProxy.hole)
            if (_proxy) {
                const _editProxy = { ...editProxy, list: _proxy.list || [] }
                setEditProxy(_editProxy)
            }
        }
    }, [scoreboardUpdated, (editProxy || {}).hole])

    const doDeleteProxy = proxyToDelete => {
        dispatch(deleteProxy(group._id, trip._id, proxyToDelete._id))
        .then(() => dispatch(getSkinsAndProxiesScoreboard(group._id, trip._id)))
    }

    const doEditProxy = (hole, showEditProxy = true) => {
        const _proxies = ((trip.scoreboard || {}).proxies || []).filter(i => i.round === round.round)
        const _existingProxy = _proxies.find(i => i.hole === hole) || {}
        const _proxyType = (proxyTypes || []).find(i => i._id === _existingProxy.proxyID) || first(proxyTypes) || {}
        var editProxy = { 
            tripID: trip._id, round: round.round, hole: hole, 
            tripProxyId: _existingProxy._id || null,
            proxyID: _proxyType._id || null, 
            proxyName: _proxyType.proxyName || ''
        }
        setShowEditProxy(showEditProxy)
        setEditProxy(editProxy)
        const containerId = 'edit-proxy-hole-container'
        const _scrollToId = `edit-proxy-hole-item-${editProxy.hole}`
        const ITEM_WIDTH = 104
        animateScroll.scrollTo((hole * 104) - 184, {
            duration: 1000,
            delay: 100,
            smooth: 'easeInOutQuint',
            containerId,
            offset: -50,
            horizontal: true,
          })
    }

    const showProxyWinnerModal = _winnerProxy => {
        setShowUserPicker(true)
        console.log(_winnerProxy)
        setWinnerProxy({ distance: 0, inches: 0, ...(_winnerProxy || {}) })
    }

    const addNewProxy = () => {
        const proxyType = first(proxyTypes)
        setShowEditProxy(true)
        setEditProxy({ tripID: trip._id, round: round.round, hole: 1, tripProxyId: 0, proxyID: proxyType._id, proxyName: proxyType.proxyName })
    }

    const setWinner = () => {
        dispatch(saveProxy(group._id, trip._id, round.round, winnerProxy.hole, winnerProxy.proxyID, winnerProxy.playerID, winnerProxy.distance || 0, winnerProxy.inches || 0))
        .then(() => dispatch(getSkinsAndProxiesScoreboard(group._id, trip._id)))
        setShowUserPicker(false)
        setWinnerProxy({})
    }

    const doSaveProxy = () => {
        dispatch(saveProxy(group._id, trip._id, round.round, editProxy.hole, editProxy.proxyID, 0, 0, 0))
        .then(() => dispatch(getSkinsAndProxiesScoreboard(group._id, trip._id)))
        setShowEditProxy(false)
        setEditProxy({})
    }

    const removeAuditItem = id => {
        dispatch(deleteProxyAuditItem(group._id, trip._id, id))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
            if (action.payload.messageType === 'success') {
                dispatch(getSkinsAndProxiesScoreboard(group._id, trip._id))
                .then(action2 => {
                    if (editProxy && editProxy.tripProxyId) {
                        const _editProxy = (action2.payload.proxies || []).find(p => p.tripProxyId === editProxy.tripProxyId)
                        setEditProxy(_editProxy)
                        if (_editProxy && (!_editProxy.list || _editProxy.list.length === 0)) {
                            setShowAuditList(false)
                            setEditProxy({})
                        }
                    }
                })
            }
        })

    }

    return (
        <div className={s.container}>
            <div className={s.headingContainer}>
                <h4 className={s.title}>Proxies</h4>
                <div className={s.controls}>
                    <Button onClick={() => addNewProxy()}>
                        <FontAwesomeIcon name='plus-circle' /> add
                    </Button>
                </div>
            </div>
            <ListGroup>
            {list && list.map(i =>
                <PlayerListItem clickDisabled player={i} showRole={false} isProxy 
                    rightDetail={<div className={s.proxyControlRightContainer}>
                    {i.playerID ? <ProxyDistanceDisplay className={s.proxyDistance} {...i} /> : null}
                    <ProxyControls round={round} proxy={i}
                        onDeleteProxy={doDeleteProxy}
                        onShowAuditList={_proxy => {
                            setShowAuditList(true)
                            setEditProxy(_proxy)
                        }}
                        onShowProxyWinnerModal={showProxyWinnerModal} />
                    </div>}
                    key={`trip-proxy-admin-${i.tripID}-${i.playerID}-${i.hole}`} />
            )}
            {!list || list.length === 0 ? <ListGroup.Item>No proxies located.</ListGroup.Item> : null}
            </ListGroup>

            <Modal show={showUserPicker}
                heading={<span>Set Proxy Winner: <HoleInfo proxy={winnerProxy} /></span>} 
                enforceFocus={false}
                actionButtonText='set winner'
                actionButtonIcon='trophy'
                actionButtonOnClick={setWinner}
                onClose={() => {
                    setShowUserPicker(false)
                    setWinnerProxy({})
                    }}>
                <div>
                    <ProxyDistanceEditor units={winnerProxy.units} distance={winnerProxy.distance} inches={winnerProxy.inches} onDistanceChange={_text => setWinnerProxy({ ...winnerProxy, distance: !isNaN(_text) && parseInt(_text) >= 0 ? parseInt(_text) : 0 })} onInchesChange={_text => setWinnerProxy({ ...winnerProxy, inches: !isNaN(_text) && parseInt(_text) >= 0 ? parseInt(_text) : 0 })} />
                    <div className={s.playerProxyWinnerLabel}>Select winner:</div>
                    <ListGroup className={s.setProxyWinnerContainer}>
                        <ListGroup.Item className={s.playerItem + ' ' + s.blankItem + (!winnerProxy.playerID ? ` ${s.selectedItem}` : ``)} 
                            onClick={() => setWinnerProxy({ ...(winnerProxy || {}), playerID: null, distance: 0, inches: 0 })}>
                            <div>No winner</div>
                        </ListGroup.Item>
                        {tripPlayers && tripPlayers.map(p =>
                            <PlayerListItem className={s.playerItem + (p.playerID === winnerProxy.playerID ? ` ${s.selectedItem}` : ``)} player={p} 
                                showRole={false} showHcp={false} 
                                onClick={() => setWinnerProxy({ ...(winnerProxy || {}), playerID: p.playerID })}
                                key={`edit-proxy-set-winner-${trip._id}-${round.round}-${p._id}`} />
                        )}
                    </ListGroup>
                </div>
            </Modal>

            <Modal show={showEditProxy}
                heading={<span>Edit Proxy: <HoleInfo proxy={editProxy} /></span>} 
                enforceFocus={false}
                showFooter
                actionButtonText='save'
                actionButtonOnClick={doSaveProxy}
                actionButtonDisabled={editProxy.proxyID === 0 || !editProxy.hole || editProxy.hole < 1 || editProxy.hole > 18}
                onClose={() => {
                    setShowEditProxy(false)
                    setEditProxy({})
                    }}>
                <div className={s.editProxyContent}>
                    <div className={s.holeContainer} id='edit-proxy-hole-container'>
                    {_holes && _holes.map(h =>
                    <Element name={`edit-proxy-hole-item-${h.hole}`} key={`edit-proxy-hole-item-${h.hole}`}>
                        <div className={s.holeItem + (`${editProxy.hole}` === `${h.hole}` ? ` ${s.isSelected}` : h.isProxy ? ` ${s.isProxy}` : ``)}                                
                            onClick={e => doEditProxy(h.hole)}>
                            <div className={s.holeNumber}>#{h.hole}</div>
                            <div className={s.par}>Par {h.par}</div>
                            <div className={s.yards}>{h.yards} yards</div>
                            <div className={s.hcp}>Hcp {h.hcp}</div>
                            {h.isProxy ? <div className={s.isProxy}><FontAwesomeIcon name='flag' /></div> : null}
                        </div>
                    </Element>
                    )}
                    </div>
                    <div className={s.proxyTypeContainer}>
                        <DropdownButton onSelect={e => setEditProxy({ ...editProxy, proxyID: e, proxyName: proxyTypes.find(pt => pt._id === e).proxyName })}
                            size='lg' 
                            title={editProxy.proxyName || ''} 
                            id={`dropdown-proxy-add-type`}>
                        {proxyTypes && proxyTypes.map(p =>
                            <Dropdown.Item  eventKey={p._id} key={`proxy-type-${p._id}`}>{p.proxyName}</Dropdown.Item>
                        )}
                        </DropdownButton>
                    </div>
                    <div className={s.proxyCountContainer}>{pluralize('proxy', list.length, true)}{list.length ? ` (${list.map(i => `#${i.hole}`).join(', ')})` : ``}</div>
                </div>
            </Modal>

            <Modal show={showAuditList}
                heading={<span>Proxy Audit List:<br /><HoleInfo proxy={editProxy} /></span>} 
                enforceFocus={false} showFooter={false}
                onClose={() => {
                        setShowAuditList(false)
                        setEditProxy({})
                    }}>
                <div className={s.auditListContent}>
                    <ListGroup className={s.auditList}>
                    {editProxy.list && editProxy.list.map(p => 
                        <PlayerListItem player={p || { _id: null }} 
                            className={s.playerItem}
                            key={`proxy-${trip.round}-${trip._id}-round-${round.round}-audit-${p._id}`} clickDisabled
                            showRole={false} showHcp={false}
                            rightDetail={<div className={s.dateOnListContainer}>
                                {editProxy.playerID === p.playerID ? <FontAwesomeIcon name='flag' className={s.winner} size='2x' /> : null}
                                <div className={s.proxyDetailsContainer + (editProxy.playerID === p.playerID ? ` ${s.isWinner}` : ``)}>
                                    {editProxy.units && (p.distance || p.inches) ?
                                    <ProxyDistanceDisplay className={s.proxyDistance} units={editProxy?.units} distance={p.distance} inches={p.inches} />
                                    : null}
                                    <div className={s.dateContainer}>
                                        <div className={s.date}>{moment(p.dateOnList).isSame(moment(), 'day') ? moment(p.dateOnList).format(FRIENDLY_SHORT_TIME_FORMAT) : `${moment(p.dateOnList).format('M/D h:mm a')}`}</div>
                                    </div>
                                </div>
                                <div className={s.delete}>
                                    <Confirm title={<span>Remove Proxy Audit Item:<br /><HoleInfo proxy={editProxy} /></span>}
                                        onConfirm={() => removeAuditItem(p._id)}
                                        confirmText='delete'
                                        body={<div>
                                            <p>Are you sure you want to delete this proxy audit item?</p>
                                            <p style={{ textAlign: 'center' }}><Image src={playerAvatarBigThumb(p.imageUrl)} roundedCircle style={{ height: '50px', width: '50px' }} /></p>
                                            <h4 style={{ textAlign: 'center' }}>{p.firstName} {p.lastName}</h4>
                                            <p style={{ textAlign: 'center' }}>{moment(p.dateOnList).format('M/D h:mm a')}</p>
                                        </div>}
                                            variant='danger' clear buttonIcon='times' size='sm' />
                                </div>
                            </div>} />
                    )}
                    </ListGroup>
                </div>
            </Modal>

        </div>
    )
}

export default TripProxyAdmin