import React, { useState } from 'react'
import * as s from './Faq.scss'
import FaqAdminButtons from './FaqAdminButtons'

const FaqContent = ({ _id = null, question, answer, isAdmin = false, onEdit, onDelete }) => (
    <div className={s.questionContainer}>
        <div className={s.question}>
            <div className={s.title}>{question}</div>
            {isAdmin ? <FaqAdminButtons _id={_id} onEdit={onEdit} onDelete={onDelete} /> : null}
        </div>
        <div className={s.answer} dangerouslySetInnerHTML={{ __html: answer }} />
    </div>
)

export default FaqContent