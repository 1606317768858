import React, { useEffect } from 'react'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'

const TripBasicsConfirmSendRsvpEmail = ({ show = false, onClose, onSend }) => {

    return (
        <Modal show={show} heading={`Send Email to Group`} 
            actionButtonIcon='envelope'
            actionButtonText='send RSVP email'
            actionButtonOnClick={() => onSend()}
            onClose={() => onClose()}>
        <div>
            <p>Send email to the group telling them to RSVP for this trip?</p>
        </div>
        </Modal>
    )
}

export default TripBasicsConfirmSendRsvpEmail