import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import Modal from '@severed-links/common.modal'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../../Player/PlayerListItem'
import numeral from 'numeral'
import pluralize from 'pluralize'
import * as s from './TripPlayerAdminPlayerWonLossRecords.scss'

const TripPlayerAdminPlayerWonLossRecords = ({ wonLossRecords = [] }) => {

    if (!wonLossRecords.length) return null
    return (
        <>
        {wonLossRecords && wonLossRecords.map((item, index) =>
        <ListGroup.Item className={s.item} key={`won-loss-record-item-${item.formatName}`}>
            <div className={s.content}>
                <div className={s.title}>{item.formatName}</div>
                <div className={s.subtitle}>{item.pctString}</div>
            </div>
            <div className={s.value}>{item.recordString}</div>
        </ListGroup.Item>
        )}

        {!wonLossRecords.length ?
        <ListGroup.Item className={s.item}>
            <div className={s.content}>
                <div className={s.title}>No won-loss records located.</div>
            </div>
        </ListGroup.Item>
        : null}
        </>
    )
}

export default TripPlayerAdminPlayerWonLossRecords
