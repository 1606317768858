import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ParScoreDisplay.scss'

const ParScoreDisplay = ({ score = 0, inverse = false }) => {

    return (
        <span className={s.container + ' ' + (score < 0 ? s.under : score > 0 ? s.over : s.even) + (inverse ? ' ' + s.inverse : '')}>
            {score < 0 ? '-' + Math.abs(score) : null}
            {score === 0 ? 'E' : null}
            {score > 0 ? '+' + Math.abs(score) : null}
        </span>
    )
}

export default ParScoreDisplay