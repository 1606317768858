import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../../../Player/PlayerListItem'
import Modal from '@severed-links/common.modal' 
import Button from '@severed-links/common.button'
import { sumBy } from 'lodash'
import pluralize from 'pluralize'
import numeral from 'numeral'
import * as s from './PlayerPayouts.scss'
import { updatePlayerPayout } from '@severed-links/common.severedlinks-reducers/groups'
import { setCreditCardState } from '@severed-links/common.severedlinks-reducers/creditCard'

const ShowDetailsButton = ({ onClick = () => void(0) }) => (
    <Button variant='light' onClick={() => onClick()} icon='search' />
)

const UpdatePaymentStatusButton = ({ player = {}, onClick = () => void(0), amount = 0, isPaid = false, isUpdating = false }) => (
    <Button className={s.paidButton} 
        variant={isUpdating ? 'light' : isPaid ? 'success' : 'danger'}
        title={numeral(amount).format('($0.00)')}
        icon={isPaid ? 'thumbs-up' : 'thumbs-down'}
        disabled={isUpdating}
        onClick={() => onClick(player)} />
)

const DetailsListItem = ({ type = 'Payout', icon = 'money-bill', description = '', amount = 0.00, isTotal = false, isPaid = false }) => (
    <ListGroup.Item className={s.payoutItem + (isTotal ? ' ' + s.isTotal : '') + (!isPaid ? ' ' + s.isNotPaid : '')}>
        <div className={s.icon}>
            <FontAwesomeIcon name={icon} />
        </div>
        <div className={s.detail}>{description}{!isPaid ? ` (Not paid out)` : ``}</div>
        <div>{numeral(amount).format('($0.00)')}</div>
    </ListGroup.Item>
)

const PlayerPayouts = () => {

    const dispatch = useDispatch()
    const { groupId, group, tripId, trip, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [showDetails, setShowDetails] = useState(false)
    const [details, setDetails] = useState({})
    const [updatingPlayerId, setUpdatingPlayerId] = useState(null)
    const payouts = trip.payouts || []
    const playersWinningMoney = payouts.filter(p => p.total > 0.00).length
    const totalPlayers = payouts.length

    const playerDetails = p => {
        setDetails(p)
        setShowDetails(true)
    }

    const closeDetailsModal = () => {
        setShowDetails(false)
        setDetails({})
    }

    const handlePaidButtonClick = p => {
        setUpdatingPlayerId(p.playerId)
        dispatch(updatePlayerPayout(groupId, tripId, p.playerId, !p.isPaid))
        .then(() => setUpdatingPlayerId(null))
    }

    return (
        <div className={s.container}>
            <h4>Player Payouts (Total {numeral(sumBy(payouts, 'total')).format('($0.00')}, {pluralize('player', totalPlayers, true)}, {playersWinningMoney} got something back)</h4>
            <ListGroup className={s.list}>
            {payouts && payouts.map(p =>
                <PlayerListItem player={p.player} showRole={false} clickDisabled
                    rightDetail={<div className={s.rightContainer}>
                        {p.total ?
                        <div className={s.controls}>
                            <ShowDetailsButton onClick={() => playerDetails(p)} />
                            <UpdatePaymentStatusButton player={p} isPaid={p.isPaid} 
                                onClick={handlePaidButtonClick} amount={p.total}
                                isUpdating={p.playerId === updatingPlayerId} />
                        </div>
                        : null}
                    </div>}
                    key={`payout-${p.playerId}`} showFlag={false} showHcp={false} />
            )}
            </ListGroup>
            <Modal show={showDetails} showFooter={false}
                heading={`Payout Details`}
                onClose={() => closeDetailsModal()}>
                <div>
                    <ListGroup className={s.payoutDetailList}>
                    <PlayerListItem player={details.player || {}} />
                    {details.items && details.items.map((i, index) =>
                    <DetailsListItem {...i} key={`payout-item-${(details.player || {})._id || 0}-${index}`} />
                    )}
                    <DetailsListItem description={'Total'} amount={details.total} isTotal isPaid={details.isPaid} />
                    </ListGroup>
                </div>
            </Modal>
        </div>
    )
}

export default PlayerPayouts