// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewImageUploadModal__container___mh9EB .NewImageUploadModal__formGroup____IHwO{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Profile/NewImageUploadModal.scss"],"names":[],"mappings":"AACI,gFACI,kBAAA","sourcesContent":[".container {\n    .formGroup {\n        margin-bottom: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `NewImageUploadModal__container___mh9EB`;
export var formGroup = `NewImageUploadModal__formGroup____IHwO`;
export default ___CSS_LOADER_EXPORT___;
