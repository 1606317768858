import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useMatch, useParams, Outlet } from 'react-router-dom'
import { Nav, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getAllConfigs, getGolfCourseAudit } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import * as s from './GlobalAdmin.scss'
import { startsWith, endsWith } from 'lodash'

const GlobalAdmin = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const match = useMatch('/global-admin/:globalAdminTab/*')
    const isGlobalAdmin = useSelector(state => state.account && state.account.isAuthenticated && state.account.isGlobalAdmin)
    const isGlobalAdminChecked = useSelector(state => state.account && state.account.isAuthenticated && state.account.isGlobalAdminChecked)
    const golfCoursesBadgeCount = useSelector(state => state.globalAdmin.golfCourses.audit.badgeCount || 0)
    const globalAdminTab = match && match.params.globalAdminTab
    const isActive = _key => _key === globalAdminTab

    const _menuTabs = [
        { name: 'Status', icon: 'list', path: `status`, disabled: false },
        { name: 'Groups', icon: 'users', path: `groups`, disabled: false },
        { name: 'Players', icon: 'user', path: `players`, disabled: false },
        { name: 'Lodging', icon: 'building', path: `lodging`, disabled: false },
        { name: 'Courses', icon: 'flag', path: `courses`, disabled: false, badgeCount: golfCoursesBadgeCount },
        { name: 'Play Formats', icon: 'play-circle', path: `play-formats`, disabled: false },
        { name: 'Pricing Plans', icon: 'money-bill-wave', path: `pricing-plans`, disabled: false },
        { name: 'Payments', icon: 'credit-card', path: `payments`, disabled: false },
        { name: 'Pushes', icon: 'mobile', path: `push-data`, disabled: false },
        { name: 'Jobs', icon: 'list-check', path: `jobs`, disabled: false },
        { name: 'Devices', icon: 'mobile', path: `devices`, disabled: false },
        { name: 'Todos', icon: 'clipboard', path: `todos`, disabled: false },
    ].map(i => ({ ...i, isActive: isActive(i.path) }))

    const _activeKey = (_menuTabs.find(i => i.isActive) || {}).path || null

    useEffect(() => {
        if (isGlobalAdminChecked && !isGlobalAdmin) {
            navigate('/login', { state: { from: location }, replace: true })
        } else {
            dispatch(getAllConfigs())
            dispatch(getGolfCourseAudit())
            if (!_activeKey && !startsWith(location.pathname.toLowerCase(), '/global-admin/new-players') && !startsWith(location.pathname.toLowerCase(), '/global-admin/play-format')) navigate(`/global-admin/status`, { replace: true })
        }
    }, [isGlobalAdmin, isGlobalAdminChecked])

    const handleSelect = e => navigate(e)

    return (
        <div className={s.container}>
            <h2><FontAwesomeIcon name='globe' /> Severed Links Global Administrator</h2>
            <Nav className={s.nav} variant='pills' 
                activeKey={_activeKey}
                onSelect={e => handleSelect(e)}>
            {_menuTabs && _menuTabs.map(i =>
                <Nav.Item className={s.item} key={`trip-admin-main-nav-${i.path}`}>
                    <Nav.Link className={s.link + (i.path === _activeKey ? ` ${s.active}` : ``)} eventKey={i.path} disabled={i.disabled}>
                        {i.icon ? <div className={s.icon}><FontAwesomeIcon name={i.icon} /></div> : null}
                        <div className={s.name}>{i.name}</div>
                        {i.badgeCount ? <Badge className={s.badge}>{i.badgeCount}</Badge> : null}
                    </Nav.Link>
                </Nav.Item>
            )}
            </Nav>
            {isGlobalAdmin && isGlobalAdminChecked ? <Outlet /> : null}
        </div>
    )
}

export default GlobalAdmin