// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print{.Profile__container___KiSGm .Profile__profileNav___OlEmC{display:none}}`, "",{"version":3,"sources":["webpack://./src/routes/Profile/Profile.scss"],"names":[],"mappings":"AAGQ,aADJ,yDAEQ,YAAA,CAAA","sourcesContent":[".container {\n\n    .profileNav {\n        @media print {\n            display: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `Profile__container___KiSGm`;
export var profileNav = `Profile__profileNav___OlEmC`;
export default ___CSS_LOADER_EXPORT___;
