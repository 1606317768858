import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventEditorMessaging.scss'
import FormCheck from 'react-bootstrap/FormCheck'
import Button from '@severed-links/common.button'
import validator from 'validator'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import { setTripValue } from '@severed-links/common.severedlinks-reducers/groups'
import { flatten } from 'lodash'

const TripEventEditorMessaging = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const userId = useSelector(state => state.account._id)
    const [_selectedItem, setSelectedItem] = useState(null)
    const { groupId, tripId, title, tripName, onChange, isTaskEvent, isPollEvent, showRsvpControls, eventType, responses, pollOptions, tripPlayerIds } = useOutletContext()
    const _playersWhoCompletedTask = isTaskEvent ? tripPlayerIds.filter(i => responses.filter(r => r.response === 10).map(p => p.playerId).includes(i)) : []
    const _playersWhoDidNotCompleteTask = isTaskEvent ? tripPlayerIds.filter(i => !responses.filter(r => r.response === 10).map(p => p.playerId).includes(i)) : []
    const _playersWhoAreGoing = showRsvpControls ? tripPlayerIds.filter(i => responses.filter(r => r.response === 1).map(p => p.playerId).includes(i)) : []
    const _playersWhoAreMaybe = showRsvpControls ? tripPlayerIds.filter(i => responses.filter(r => r.response === 2).map(p => p.playerId).includes(i)) : []
    const _playersWhoAreNotGoing = showRsvpControls ? tripPlayerIds.filter(i => responses.filter(r => r.response === 3).map(p => p.playerId).includes(i)) : []
    const _pollOptionResponsesPlayerIds = flatten((pollOptions || []).map(i => i.responses)).map(i => i.playerId)
    const _playersWhoVoted = isPollEvent ? tripPlayerIds.filter(i => _pollOptionResponsesPlayerIds.includes(i)) : []
    const _playersWhoDidNotVote = isPollEvent ? tripPlayerIds.filter(i => !_pollOptionResponsesPlayerIds.includes(i)) : []
    const _playersWhoDidNotRespond = showRsvpControls ? tripPlayerIds.filter(i => !responses.map(p => p.playerId).includes(i)) : []
    const _items = [
        { variant: 'info', value: tripPlayerIds, title: 'All trip players', subtitle: `${pluralize('player', tripPlayerIds.length, true)}`, icon: 'envelope' },
        isPollEvent ? { variant: 'success', value: _playersWhoVoted, title: 'Players who voted', subtitle: `${pluralize('player', _playersWhoVoted.length, true)}`, icon: 'thumbs-up' } : null,
        isPollEvent ? { variant: 'danger', value: _playersWhoDidNotVote, title: 'Players who did not vote', subtitle: `${pluralize('player', _playersWhoDidNotVote.length, true)}`, icon: 'thumbs-down' } : null,
        isTaskEvent ? { variant: 'success', value: _playersWhoCompletedTask, title: 'Players who completed task', subtitle: `${pluralize('player', _playersWhoCompletedTask.length, true)}`, icon: 'check' } : null,
        isTaskEvent ? { variant: 'danger', value: _playersWhoDidNotCompleteTask, title: 'Players who did not complete task', subtitle: `${pluralize('player', _playersWhoDidNotCompleteTask.length, true)}`, icon: 'times' } : null,
        showRsvpControls ? { variant: 'success', value: _playersWhoAreGoing, title: 'Players who are going', subtitle: `${pluralize('player', _playersWhoAreGoing.length, true)}`, icon: 'check' } : null,
        showRsvpControls ? { variant: 'warning', value: _playersWhoAreMaybe, title: 'Players who are a maybe', subtitle: `${pluralize('player', _playersWhoAreMaybe.length, true)}`, icon: 'question' } : null,
        showRsvpControls ? { variant: 'danger', value: _playersWhoAreNotGoing, title: 'Players who are not going', subtitle: `${pluralize('player', _playersWhoAreNotGoing.length, true)}`, icon: 'times' } : null,
        showRsvpControls ? { variant: 'light', value: _playersWhoDidNotRespond, title: 'Players who have not responded', subtitle: `${pluralize('player', _playersWhoDidNotRespond.length, true)}`, icon: 'ban' } : null,
    ].filter(i => i && i.value && i.value.length).map(i => ({ ...i, isSelected: i.title === _selectedItem?.title }))
    const _isValid = !!_selectedItem?.title

    useEffect(() => {
    }, [])

    const handleSelect = _item => {
        if (_selectedItem?.title !== _item.title) {
            setSelectedItem(_item)
        } else {
            setSelectedItem(null)
        }
    }

    const handleEventMessagingPreload = () => {
        dispatch(setTripValue(groupId, tripId, 'tripAdminMessagingPreload', { subject: `${tripName ? `${tripName} Event Update: ${title}` : ''}`, body: ``, updatedAt: moment().toISOString(true), recipients: _selectedItem?.value || [] }))
        navigate(`/group/${groupId}/trip/${tripId}/admin/messaging`)
    }

    const handleCancel = () => {
        const _path = location.pathname.replace(/\/messaging$/gi, '')
        navigate(_path)
    }

    return (
        <div className={s.container}>

            <p>Select your messaging recipient group...</p>

            <ListGroup className={s.list}>
            {_items && _items.map((i, _index) =>
            <ListGroup.Item className={s.item + (i.isSelected ? ` ${s.isSelected}` : ``)}
                action onClick={() => handleSelect(i)}
                key={`trip-event-messaging-key-${i.title}`}>
                <div className={s.icon}>
                    <FontAwesomeIcon size='2x' name={i.isSelected ? 'check-circle' : 'circle'} isRegular={!i.isSelected} />
                </div>
                <div className={s.icon}>
                    <FontAwesomeIcon size='2x' name={i.icon} />
                </div>
                <div className={s.content}>
                    <div className={s.title}>{i.title}</div>
                    <div className={s.subtitle}>{i.subtitle}</div>
                </div>
                {!i.isSelected ?
                <div className={s.clickIcon}>

                </div>
                : null}
            </ListGroup.Item>
            )}
            </ListGroup>

            <div className={s.buttonsContainer}>
                <Button variant='light' title='cancel' icon='times' onClick={() => handleCancel()} />
                <Button className={s.saveButton} variant='primary' title={`route to messaging`} icon='envelope' onClick={() => handleEventMessagingPreload()} disabled={!_isValid} />
            </div>

        </div>
    )
}

export default TripEventEditorMessaging
