// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GlobalAdminUsGolfImporter__container___QSyk9{margin-bottom:1rem}.GlobalAdminUsGolfImporter__container___QSyk9 .GlobalAdminUsGolfImporter__formGroup___Pvpt5{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/GlobalAdmin/GlobalAdminUsGolfImporter.scss"],"names":[],"mappings":"AAAA,8CACI,kBAAA,CAEA,4FACI,kBAAA","sourcesContent":[".container {\n    margin-bottom: 1rem;\n\n    .formGroup {\n        margin-bottom: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `GlobalAdminUsGolfImporter__container___QSyk9`;
export var formGroup = `GlobalAdminUsGolfImporter__formGroup___Pvpt5`;
export default ___CSS_LOADER_EXPORT___;
