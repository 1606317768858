import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Row, Col, Alert, Button, Badge, Form, FormGroup, 
    FormLabel, FormControl, ListGroup } from 'react-bootstrap'
import TextareaAutosize from 'react-autosize-textarea'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getMyTickets, getOthersTickets, toggleTicketModal, 
    setActiveTicket, updateActiveTicket, saveTicket, addComment, deleteComment, 
    loadActiveTicket } from '@severed-links/common.severedlinks-reducers/help'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { orderBy, startsWith, endsWith } from 'lodash'
import * as s from './HelpCenter.scss'
import Modal from '@severed-links/common.modal'
import moment from 'moment-timezone'
import { FRIENDLY_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import FormCheck from 'react-bootstrap/FormCheck'
import CommentItem from '../Comment/CommentItem'
import PlayerListItem from '../Player/PlayerListItem'

const EditTicket = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const { activeTicket, showActiveTicket, completedRetentionMinutes, deletedRetentionMinutes } = useSelector(state => state.help)
    const { isGlobalAdmin, _id: playerId, firstName, lastName, imageUrl } = useSelector(state => state.account)
    const [comment, setComment] = useState('')
    const newTicketItem = { _id: null, ticketType: '', description: '', createDate: null, updateDate: null, isCompletedTicket: false, isDeletedTicket: false, playerId, player: { _id: playerId, firstName, lastName, imageUrl }, sortOrder: 1, comments: [], commentCount: 0, commentUnreadCount: 0 }

    useEffect(() => {
        if (playerId && endsWith(location.pathname.toLowerCase(), '/new-issue')) {
            console.log('setting new ticket')
            console.log(newTicketItem)
            dispatch(setActiveTicket(newTicketItem))
        }
    }, [location.pathname, playerId])

    const loadTickets = () => {
        dispatch(getMyTickets())
        if (isGlobalAdmin) dispatch(getOthersTickets())
    }

    const handleTextChange = e => {
        dispatch(updateActiveTicket(e.target.name, e.target.value))
    }

    const handleSelectChange = e => {
        dispatch(updateActiveTicket(e.target.name, e.target.value))
    }

    const handleCommentChange = e => setComment(e.target.value)

    const handleKeyPress = e => {
        if (e.key === 'Enter') {            
            addCommentToTicket()
            e.preventDefault()
        }
    }

    const handleSwitch = (fieldName, isChecked) => dispatch(updateActiveTicket(fieldName, isChecked))

    const addCommentToTicket = () => {
        dispatch(addComment({ helpTicketID: activeTicket._id, comment }))
        setComment('')
    }

    const doDeleteComment = commentId => dispatch(deleteComment(commentId))

    const doSaveTicket = () => {
        const ticket = { 
            ...activeTicket, 
            playerId: (activeTicket.player || {})._id || null,
            createDate: activeTicket._id === 0 ? moment().toISOString() : moment(activeTicket.createDate).toISOString(),
            updateDate: moment().toISOString() 
        }
        dispatch(saveTicket(ticket))
        .then(action => {
            closeModal()
        })
    }

    const closeModal = () => {
        loadTickets()
        navigate(`/profile/help-center`)
    }

    return (
        activeTicket ? 
        <div className={s.container}>

            <Row>
                <Col xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Issue Type</FormLabel>
                        <FormControl as='select' 
                            required isValid={!!activeTicket.ticketType}
                            isInvalid={!activeTicket.ticketType}
                            onChange={e => handleSelectChange(e)}
                            name='ticketType'
                            value={activeTicket.ticketType}>
                            <option value=''>[Select a type...]</option>
                            <option value='Bug'>Something isn't working right</option>
                            <option value='Feature Request'>I have a feature request</option>
                            <option value='Comment'>I have a comment on the site</option>
                        </FormControl>
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>
            </Row>

            {activeTicket?.player?._id ?
            <Row>
                <Col xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Opened By</FormLabel>
                        <PlayerListItem player={activeTicket.player} />
                    </FormGroup>
                </Col>
            </Row>
            : null}

            <FormGroup className={s.formGroup}>
                <FormLabel>Description of issue</FormLabel>
                <TextareaAutosize name='description' className='form-control'
                    placeholder='[Describe your issue here...]' 
                    required value={activeTicket.description}
                    onChange={e => handleTextChange(e)} rows={2}
                    onResize={e => {}} />
                <FormControl.Feedback />
            </FormGroup>

            <Row>
            {activeTicket._id ?
                <Col sm={3} xs={6}>
                    <FormGroup className={s.formGroup} style={{ textAlign: 'center' }}>
                        <FormLabel style={{ width: '100%' }}>Completed?</FormLabel>
                        <FormCheck type='switch' onChange={e => handleSwitch(e.target.name, e.target.checked)}
                            name='isCompletedTicket' checked={activeTicket.isCompletedTicket} />
                    </FormGroup>
                </Col>
            : null}
            {activeTicket._id ?
                <Col sm={3} xs={6}>
                    <FormGroup className={s.formGroup} style={{ textAlign: 'center' }}>
                        <FormLabel style={{ width: '100%' }}>Delete?</FormLabel>
                        <FormCheck type='switch' onChange={e => handleSwitch(e.target.name, e.target.checked)}
                            name='isDeletedTicket' checked={activeTicket.isDeletedTicket} />
                    </FormGroup>
                </Col>
            : null}
                <Col sm={activeTicket._id ? 6 : 12} xs={12}>
                    <FormGroup style={{ textAlign: 'right' }}>
                        <FormLabel style={{ width: '100%' }}>&nbsp;</FormLabel>
                        <Button variant='secondary' onClick={() => closeModal()}><FontAwesomeIcon name='times' /> cancel</Button>
                        {' '}
                        <Button variant='primary' 
                            disabled={!activeTicket.description || !activeTicket.ticketType}
                            onClick={() => doSaveTicket()}>
                            <FontAwesomeIcon name='check' /> 
                            {' '} 
                            save ticket
                        </Button>
                    </FormGroup>
                </Col>
            </Row>


            {activeTicket._id ? 
            <div className={s.commentsContainer}>
                <h5>Comments</h5>
                <FormGroup className={s.formGroup}>
                    <TextareaAutosize name='Description' className='form-control'
                        placeholder='[Add a comment...]' 
                        value={comment}
                        onKeyPress={e => handleKeyPress(e)}
                        onChange={e => handleCommentChange(e)} rows={1}
                        onResize={(e) => {}} />
                </FormGroup>
                {activeTicket.comments.length === 0 ?
                    <Alert variant='info'>No comments on this ticket.</Alert>
                : null}
                {activeTicket.comments.length > 0 ? 
                <ListGroup>
                    {orderBy(activeTicket.comments, ['createDate'], ['desc']).map(c => 
                    <CommentItem canDelete={c.player._id === playerId || isGlobalAdmin}
                            id={c._id} author={c.player} text={c.comment} 
                            date={c.createDate} onDelete={doDeleteComment}
                            key={`edit-ticket-${activeTicket._id}-${c._id}`} />
                    )}
                </ListGroup>
                : null}
            </div>
            : null}
        </div>
        : null
    )
}

export default EditTicket