import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toast } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { removeNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './Notification.scss'

const Notification = ({ notification }) => {

    const dispatch = useDispatch()

    if (!notification) return null
    const i = notification || {}

    return (
            <Toast className={s.container}
                bg={i.type || 'info'} 
                delay={i.timeout || 4000} autohide
                onClose={() => dispatch(removeNotification(i))}>
            {i.headline || i.subject ? 
            <Toast.Header className={s.heading + ' ' + s[`${i.type}`]}>
                <div className={s.headingIcon}><FontAwesomeIcon name={i.icon} /></div>
                <div className={s.headingText}>{i.headline || i.subject}</div>
            </Toast.Header>
            : null}
            {i.message ?
            <Toast.Body className={!['light','warning'].includes(i.type) ? 'text-white' : null}>{i.message}</Toast.Body>
            : null}
          </Toast>
      
    )
}

export default Notification