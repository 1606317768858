import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Alert, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CellPhoneNumberConfirmAlert.scss'

const CellPhoneNumberConfirmAlert = () => {

    const navigate = useNavigate()
    const cellPhone = useSelector(state => state.account.cellPhone)
    const cellPhoneConfirmed = useSelector(state => state.account.cellPhoneConfirmed)
    const userIsLoaded = useSelector(state => state.account.userIsLoaded && state.account.isAuthenticated)

    const routeToEditProfile = () => navigate(`/profile/edit`)

    if (!userIsLoaded || (userIsLoaded && cellPhone && cellPhoneConfirmed)) return null
    return (
        <div className={s.container}>
            <Alert variant='warning' className={s.alert}>
                <div className={s.content}>
                    <div className={s.icon}><FontAwesomeIcon name='exclamation' size='2x' /></div>
                    <div className={s.text}>
                    {!cellPhone ? <span>Please enter your cell phone number in your profile so we can communuicate with you via text message.</span> : null}
                    {' '}
                    {!cellPhoneConfirmed ? <span>Please verify your cell phone number in your user profile so that we can confirm you are able to receive text messages from Severed Links.</span> : null}
                    </div>
                    <div className={s.button}>
                        <Button variant='warning' onClick={() => routeToEditProfile()}>
                            <FontAwesomeIcon name='mobile' />
                            {' '}
                            update profile
                        </Button>
                    </div>
                    </div>
            </Alert>
        </div>
    )
}

export default CellPhoneNumberConfirmAlert