import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Confirm from '@severed-links/common.confirm'
import { deleteTrip, getTrips, getDeletedTrips, setTripValue } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { startsWith } from 'lodash'

const DeleteTripButton = ({ group = {}, trip = {}, isGlobalAdmin = false }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isGlobalAdminPath = startsWith(location.pathname.toLowerCase(), '/global-admin/')


    const onDeleteTrip = () => {
        dispatch(setTripValue(group._id, trip._id, 'isDeleting', true))
        dispatch(deleteTrip(group._id, trip._id))
        .then(action => {
            dispatch(createNotification({ ...action.payload, headline: 'Delete Trip', timeout: 4000 }))
            if (action.payload.messageType === 'success') {
                dispatch(getTrips(group._id))
                dispatch(getDeletedTrips(group._id))
                const path = isGlobalAdminPath ? `/global-admin/groups/${group.name.substring(0,1).toUpperCase()}/view/${group._id}/` : `/group/${group._id}/`
                navigate(`${path}trips`)
            }
        })
    }

    return (
        <Confirm title={'Delete Trip'}
            onConfirm={() => onDeleteTrip()}
            confirmText='delete trip'
            body={<div><p>Are you sure you want to delete this trip?</p></div>}
            variant='danger' buttonIcon='trash' />
    )
}

export default DeleteTripButton