import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GroupMenuBar.scss'
import { routingPath } from '@severed-links/common.severedlinks-constants'

const GroupMenuBar = ({ group = {}, activeTab = null, show = false, isGlobalAdmin = false }) => {

    if (!group) return null

    const navigate = useNavigate()
    const hasFaq = (group.faq || []).length > 0
    const menuItems = [
        { key: 'players', icon: 'users', title: 'Players' },
        { key: 'trips', icon: 'suitcase', title: 'Trips' },
        { key: 'mvp-award', icon: 'trophy', title: group.mvpAwardName || 'MVPs' },
        { key: 'top-10-lists', icon: 'list', title: 'Lists' },
        hasFaq ? { key: 'faq', icon: 'question', title: 'FAQ' } : null,
        (group.role && group.role.isAdmin) || isGlobalAdmin ? { key: 'admin', icon: 'cog', title: 'Admin' } : null,
    ].filter(i => i)

    const handleSelect = e => {
        const path = routingPath(group._id, isGlobalAdmin, ((group || {}).name || ' ').substring(0,1).toUpperCase())
        navigate(`${path}${e}` + 
            (e === 'players' && group.players && group.players.length > 25 ? '/filter/' + group.playerFilterLetter : ''))
    }

    return (
        <Nav className={s.container + (show ? ' ' + s.show : '')} 
            variant='pills' activeKey={activeTab} 
            onSelect={e => handleSelect(e)}>
            {menuItems && menuItems.map(i =>
                <Nav.Item className={s.item} key={`group-menu-bar-item-${i.key}`}>
                    <Nav.Link className={s.link + (i.key === activeTab ? ` ${s.active}` : ``)} eventKey={i.key}>
                        <FontAwesomeIcon name={i.icon} />
                        {' '}
                        <span className='d-none d-md-inline-block'>{i.title}</span>
                    </Nav.Link>
                </Nav.Item>
            )}
        </Nav>
    )
}

export default GroupMenuBar