import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import { updateGroupState, getPendingInvites, cancelInvite, resendInvites } from '@severed-links/common.severedlinks-reducers/groups'
import PlayerListItem from '../../Player/PlayerListItem'
import moment from 'moment-timezone'
import { FRIENDLY_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './PendingInvitesList.scss'

const PendingInvitesList = ({ group = {} }) => {

    const dispatch = useDispatch()
    const _pendingInvites = group.pendingInvites || []

    useEffect(() => {
        reloadPendingInvites()
    }, [])

    useEffect(() => {
        if (!_pendingInvites.length) {
            dispatch(updateGroupState(group._id, { showPendingInvites: false }))
        }
    }, [_pendingInvites.length])

    const doCancel = inviteId => {
        dispatch(cancelInvite(group._id, inviteId))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
        })
    }

    const doResend = (inviteId = null) => {
        const inviteIds = !inviteId ? _pendingInvites.map(i => i._id) : [inviteId]
        dispatch(resendInvites(group._id, inviteIds))
        .then(action => {
            dispatch(createNotification(action.payload))
        })
    }

    const reloadPendingInvites = () => {
        if (group._id) {
            dispatch(getPendingInvites(group._id))        
        }
    }

    return (
        <Modal show={group.showPendingInvites} showFooter={false}
            heading={`Pending Invites`}
            right={<div className={s.pendingInvitesControls}>
                {_pendingInvites.length ?
                <Confirm title={'Resend Invites'} 
                    variant='info'
                    onConfirm={() => doResend()}
                    confirmText='resend invites'
                    cancelText='close'
                    body={<div><p>Are you sure you want to re-send these invites?</p></div>}
                    size='sm' clear buttonIcon='envelope' buttonClassName={s.button} />
                : null}
                <Button className={s.button} variant='light' size='sm' onClick={() => reloadPendingInvites()}><FontAwesomeIcon name='sync-alt' /></Button>
            </div>}
            onClose={() => dispatch(updateGroupState(group._id, { showPendingInvites: false }))}>
            <div>
                <ListGroup>
                {_pendingInvites && _pendingInvites.map(i =>
                    <PlayerListItem key={`pending-invite-${i.inviteId}`} player={{ ...i, playerName: `${i.firstName} ${i.lastName}` }}
                        rightDetail={<span style={{ fontSize: '80%', paddingRight: '15px' }}>{moment(i.dateSent).fromNow()}</span>} clickDisabled>
                        <div style={{ fontStyle: 'italic' }}>{i.emailAddress}</div>

                        <div className={s.pendingInvitesPlayerItemControlsContainer}>

                            {_pendingInvites.length ?
                            <Confirm title={'Resend Invite'} 
                                variant='info'
                                onConfirm={() => doResend(i.inviteId)}
                                confirmText='resend invite'
                                cancelText='close'
                                body={<div><p>Are you sure you want to re-send this invite?</p></div>}
                                size='sm' clear buttonIcon='envelope' buttonClassName={s.button} />
                            : null}

                            <Confirm title={'Cancel Invitation'} 
                                onConfirm={() => doCancel(i.inviteId)}
                                confirmText='cancel this invitation'
                                cancelText='close'
                                body={<div><p>Are you sure you want to cancel this invitation?</p><p><small>You will need to re-invite this player to join the group.</small></p></div>}
                                variant='warning' size='sm' clear buttonIcon='times' buttonClassName={s.button} />

                        </div>
                    </PlayerListItem>
                )}    
                </ListGroup>
            </div>
        </Modal>
    )
}

export default PendingInvitesList
