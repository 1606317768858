import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GroupRestorer.scss'
import Confirm from '@severed-links/common.confirm'
import { restoreGroup } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const GroupRestorer = ({ group, onRestore }) => {

    const dispatch = useDispatch()
    const isGlobalAdmin = useSelector(state => state.account.isGlobalAdmin)

    const restore = () => {
        dispatch(restoreGroup(group._id))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (onRestore) onRestore(group)
        })
    }

    if (!isGlobalAdmin || !group.isDeletedGroup) return null
    return (
        <div className={s.container} onClick={e => e.stopPropagation()}>
            <Confirm title={'Restore Deleted Group'}
                onConfirm={() => restore()}
                confirmText='restore group'
                body={<div><p>Are you sure you want to restore this group?</p></div>}
                variant='warning' buttonClassName={s.deletedButton} buttonIcon='trash' buttonText='Deleted' />
        </div>
    )
}

export default GroupRestorer