import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripRoundNotesAdmin.scss'
import TripRoundNotesAdminNoteItem from './TripRoundNotesAdminNoteItem'
import TripRoundNotesAdminEditNote from './TripRoundNotesAdminEditNote'
import { deleteTripRoundNote, sortTripRoundNotes } from '@severed-links/common.severedlinks-reducers/groups'

const TripRoundNotesAdmin = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const { group, trip, groupId, tripId, round, roundSubTab, roundParam, roundNumber, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const _round = round?.round || 0
    const _notes = round?.notes || []

    useEffect(() => {
    }, [])

    const deleteNote = noteId => {
        dispatch(deleteTripRoundNote(groupId, tripId, _round, noteId))
    }

    const handleMove = (oldIndex, newIndex) => {
        dispatch(sortTripRoundNotes(groupId, tripId, _round, oldIndex, newIndex))
    }

    if (!groupId || !tripId || !_round) return null
    return (
        <div className={s.container}>

            <div className={s.heading}>
                <h4 className={s.title}>Notes</h4>
                <div className={s.controls}>
                    <TripRoundNotesAdminEditNote groupId={groupId} 
                        tripId={tripId} round={_round} />
                </div>
            </div>

            <ListGroup className={s.list}>
            {_notes && _notes.map((i, index) =>
            <TripRoundNotesAdminNoteItem groupId={groupId}
                tripId={tripId} round={_round} index={index}
                {...i} 
                onDelete={deleteNote} onMove={handleMove}
                key={`trip-${tripId}-round-${round}-note-${i._id}`} />
            )}
            {!_notes.length ? <TripRoundNotesAdminNoteItem groupId={groupId}
                tripId={tripId} round={_round} index={-1} showHandle={false} note={`No notes located for round ${_round}.`} /> : null}
            </ListGroup>
        </div>
    )
}

export default TripRoundNotesAdmin
