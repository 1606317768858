import React from 'react'
import PropTypes from 'prop-types'
import * as s from './ContentPanel.scss'
import { ContentRoutes } from '../../routes'
import GroupEntryWatcher from '../../routes/Groups/GroupEntryWatcher'

const ContentPanel = () => {

  return (
    <div className={`container ${s.contentPanel}`}>
      <ContentRoutes />
      <GroupEntryWatcher />
    </div>
  )
}

export default ContentPanel; 
