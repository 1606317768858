import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Table } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { sumBy, range, orderBy, flatten } from 'lodash'
import { commonImageUrl } from '@severed-links/common.severedlinks-constants'
import * as s from './RyderCupRoundScoreboard.scss'

const RoundScore = ({ score = 0, showScore }) => {
    if (!showScore) return null
    return (
    <span>{score !== 0.5 ? Math.floor(score) : null}{score - Math.floor(score) === 0.5 ? String.fromCharCode(189) : ''}</span>
)}

const RyderCupRoundScoreboard = ({ 
    roundScores = [], ryderCupRounds = [], numRounds = 0,
    usaScore = 0, eurScore = 0,
    eurBackgroundColor, eurBadgeBackgroundColor, eurBadgeBorderColor, eurBadgeTextColor,
    eurBorderColor, eurShortTeamName, eurTeamImageUrl, eurTeamName, eurTextColor, eurFullTeamImageUrl,
    usaBackgroundColor, usaBadgeBackgroundColor, usaBadgeBorderColor, usaBadgeTextColor,
    usaBorderColor, usaShortTeamName, usaTeamImageUrl, usaTeamName, usaTextColor, usaFullTeamImageUrl,
    showMini = false, showRounds = false, showRoundHeader = true, center = false, size = '1x',
    ...props
}) => {

    const [fontSize, setFontSize] = useState(16)

    const createTeamScoreboardRow = (team = '') => {
        team = team.toUpperCase()
        const roundScoreMap = range(1, numRounds + 1, 1)
            .map(i => ({ round: i, score: ((roundScores || []).find(r => r.team === team && r.round === i) || {}).score || 0 }))
        const totalScore = showRounds && roundScoreMap && roundScoreMap.length > 0 ? sumBy(roundScoreMap, 'score') : team === 'USA' ? usaScore : team === 'EUR' ? eurScore : 0
        return { 
            teamId: team,
            team: team === 'USA' ? usaTeamName : eurTeamName, 
            shortTeam: team === 'USA' ? usaShortTeamName : eurShortTeamName, 
            teamFlagUrl: team === 'USA' ? usaFullTeamImageUrl : eurFullTeamImageUrl, 
            roundScores: roundScoreMap,
            totalScore,
        }
    }

    var rows = orderBy([createTeamScoreboardRow('USA'), createTeamScoreboardRow('EUR')], ['totalScore', 'team'], ['desc','desc'])
    const rounds = range(1, numRounds + 1, 1)
        .map(i => ({ 
            round: i, 
            totalPoints: sumBy(flatten(rows.map(x => x.roundScores))
                .filter(x => x.round === i), 'score'),
        })).map(i => ({ ...i, showScores: i.totalPoints > 0 }))

    return (
        showMini && rows && rows.length === 2 ? 
        <div className={s.container + ' ' + s.mini + ' ' + s[`size${size}`] + (center ? ' ' + s.center : '')}>
            <div className={s.flag} style={rows[0].teamId.toString().toLowerCase() && rows[0].teamId.toString().toLowerCase() !== 'none' ? { backgroundImage: `url(${rows[0].teamFlagUrl})` } : null} />
            <div className={s.score}><RoundScore score={rows[0].totalScore} showScore /></div>
            <div className={s.score}><RoundScore score={rows[1].totalScore} showScore /></div>
            <div className={s.flag} style={rows[1].teamId.toString().toLowerCase() && rows[1].teamId.toString().toLowerCase() !== 'none' ? { backgroundImage: `url(${rows[1].teamFlagUrl})` } : null} />
        </div>
        :
        <Card className={s.container + ' ' + s.notMini + ' ' + s[`size${size}`]}>
            <Table className={s.ryderTable}>
                {showRoundHeader ?
                <thead>
                    <tr>
                        <th style={{ textAlign: 'left', padding: '10px 15px' }} colSpan={showRounds ? 1 : 2}><span>Ryder Cup</span></th>
                        {showRounds && ryderCupRounds && ryderCupRounds.map(_round => <th style={{ textAlign: 'center', padding: '10px 15px' }} key={`ryder-cup-scoreboard-header-${_round}`}>{_round}</th>)}
                        {showRounds ? <th style={{ textAlign: 'center', padding: '10px 15px' }}></th> : null}
                    </tr>
                </thead>
                : null}
                <tbody>
                {rows && rows.map(i =>
                <tr key={`ryder-scoreboard-${i.team}`}>
                    <td className={s.teamCell} style={{ ...(i.teamFlagUrl ? { backgroundImage: `url(${i.teamFlagUrl})` } : {}), backgroundColor: 'transparent' }}><span className='d-none d-sm-inline-block'>{i.team}</span></td>
                    {showRounds && i.roundScores && i.roundScores.filter(r => ryderCupRounds.includes(r.round)).map(r => 
                        <td key={`ryder-scoreboard-${i.teamId}-${r.round}`}>
                            <RoundScore score={r.score} showScore={(rounds.find(x => x.round === r.round) || {}).showScores || false} />
                        </td>
                    )}
                    <td className={s.totalCell}><RoundScore score={i.totalScore} showScore /></td>
                </tr>
                )}
                </tbody>
            </Table>
        </Card>
    )
}

export default RyderCupRoundScoreboard