// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Group__container___v8Tsj .Group__headerContainer___zYYfa{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center;margin-bottom:20px}@media screen and (max-width: 991px){.Group__container___v8Tsj .Group__headerContainer___zYYfa{flex-flow:row wrap}}`, "",{"version":3,"sources":["webpack://./src/routes/Groups/Group.scss"],"names":[],"mappings":"AAKI,0DACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAEA,qCAPJ,0DAQQ,kBAAA,CAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n@use '../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n\n    .headerContainer {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n        margin-bottom: 20px;\n\n        @media screen and (max-width: breakpoints.$screen-md-max) {\n            flex-flow: row wrap;\n        }\n    \n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `Group__container___v8Tsj`;
export var headerContainer = `Group__headerContainer___zYYfa`;
export default ___CSS_LOADER_EXPORT___;
