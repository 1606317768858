import React from 'react'
import { createRoot } from 'react-dom/client'
import createStore from './store/createStore'
import AppContainer from './containers/AppContainer'
import RedBox from 'redbox-react'

import { ContentRoutes, MenuRoutes } from './routes' 

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__
export const store = createStore(initialState)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')
const root = createRoot(MOUNT_NODE)
var render = () => root.render(<AppContainer store={store} />)

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = (error) => root.render(<RedBox error={error} />)

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        console.error(error)
        renderError(error)
      }
    }

    // Setup hot module replacement
    module.hot.accept(AppContainer, () => setImmediate(() => root.unmount()))
  }
}

render()
