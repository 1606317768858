import { combineReducers } from 'redux'
import aboutUs from '@severed-links/common.severedlinks-reducers/aboutUs'
import account from '@severed-links/common.severedlinks-reducers/account'
import accountDeletion from '@severed-links/common.severedlinks-reducers/accountDeletion'
import contactUs from '@severed-links/common.severedlinks-reducers/contactUs'
import cookies from '@severed-links/common.severedlinks-reducers/cookies'
import creditCard from '@severed-links/common.severedlinks-reducers/creditCard'
import forgotPassword from '@severed-links/common.severedlinks-reducers/forgotPassword'
import geoLocation from '@severed-links/common.severedlinks-reducers/geoLocation'
import globalAdmin from '@severed-links/common.severedlinks-reducers/globalAdmin'
import golfApiIo from '@severed-links/common.severedlinks-reducers/golfApiIo'
import groups from '@severed-links/common.severedlinks-reducers/groups'
import help from '@severed-links/common.severedlinks-reducers/help'
import home from '@severed-links/common.severedlinks-reducers/home'
import layout from '@severed-links/common.severedlinks-reducers/layout'
import menus from '@severed-links/common.severedlinks-reducers/menus'
import mobileApps from '@severed-links/common.severedlinks-reducers/mobileApps'
import notifications from '@severed-links/common.severedlinks-reducers/notifications'
import player from '@severed-links/common.severedlinks-reducers/player'
import playerAdmin from '@severed-links/common.severedlinks-reducers/playerAdmin'
import policies from '@severed-links/common.severedlinks-reducers/policies'
import pricing from '@severed-links/common.severedlinks-reducers/pricing'
import pricingPlans from '@severed-links/common.severedlinks-reducers/pricingPlans'
import profile from '@severed-links/common.severedlinks-reducers/profile'
import publicReducer from '@severed-links/common.severedlinks-reducers/public'
import redirector from '@severed-links/common.severedlinks-reducers/redirector'
import scorecard from '@severed-links/common.severedlinks-reducers/scorecard'
import settings from '@severed-links/common.severedlinks-reducers/settings'
import siteRegistration from '@severed-links/common.severedlinks-reducers/siteRegistration'
import socket from '@severed-links/common.severedlinks-reducers/socket'
import trips from '@severed-links/common.severedlinks-reducers/trips'

export const createRootReducer = () => combineReducers({
  aboutUs,
  account,
  accountDeletion,
  contactUs,
  cookies,
  creditCard,
  forgotPassword,
  geoLocation,
  globalAdmin,
  golfApiIo,
  groups,
  help,
  home,
  layout,
  menus,
  mobileApps,
  notifications,
  player,
  playerAdmin,
  policies,
  pricing,
  pricingPlans,
  profile,
  public: publicReducer,
  redirector,
  scorecard,
  settings,
  siteRegistration,
  socket,
  trips,
})

export default createRootReducer
