import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Alert from '@severed-links/common.alert'
import * as s from './GlobalAdminJobQueueStatus.scss'
import { BULL_QUEUE_STATUS_FIELDS } from '@severed-links/common.severedlinks-constants'
import { getBullJobs, getBullJobsForQueue } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import { titleCase } from 'title-case'
import numeral from 'numeral'
import moment from 'moment-timezone'
import { omit, keys, keysIn } from 'lodash'

const GlobalAdminJobQueueStatus = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { jobs } = useSelector(state => state.globalAdmin)
    const { name, status } = params
    const { time_zone } = useSelector(state => state.account)
    const _job = jobs.find(i => i.name === name)

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (!_job) {
            dispatch(getBullJobs())
        }
    }, [_job])

    useEffect(() => {
        if (_job?.name && !status) {
            const _firstStatus = BULL_QUEUE_STATUS_FIELDS.find(i => !!_job[i.fieldName])
            navigate(`${location.pathname}/status/${_firstStatus.fieldName}`)
        } else if (_job?.name && status) {
            dispatch(getBullJobsForQueue(name, [status]))
        }
    }, [_job?.name, status])

    const handleCountClick = _fieldName => {
        navigate(`/global-admin/jobs/queue/${encodeURIComponent(name)}/status/${_fieldName}`)
    }

    return (
        <div className={s.container}>
            <h3 className={s.header}><FontAwesomeIcon name='list-check' /> {name}</h3>

            {_job ? 
            <>
            <div className={s.counts}>
            {BULL_QUEUE_STATUS_FIELDS.filter(i => !!_job[i.fieldName]).map(i => 
            <div className={s.countItem + (i.variant ? ` ${s[i.variant]}` : ``) + (i.fieldName === status ? ` ${s.isSelected}` : ``)} key={`bull-queue-count-${i.fieldName}`} onClick={() => handleCountClick(i.fieldName)}>
                <div className={s.heading}>{titleCase(i.fieldName)}</div>
                <div className={s.count}>{numeral(_job[i.fieldName] || 0).format('0,0')}</div>
            </div>
            )}
            </div>
            
            <ListGroup className={s.list}>
            {_job.jobs && _job.jobs.filter(i => i).map(i =>
            <ListGroup.Item className={s.item} key={`job-${name}-status-${status}-${i.id}`}>
                <div className={s.title}>{i.processedOn ? `Job processed at ${moment.tz(i.processedOn, time_zone).format('MMM D YYYY h:mm:ss A')}` : ``}{i.finishedOn ? ` (finished at ${moment.tz(i.finishedOn, time_zone).format('MMM D YYYY h:mm:ss A')})` : ``}</div>
                <div className={s.subtitle}>id: {i.id}{i.data && keys(i.data).length  ? <pre className={s.data}>{JSON.stringify(i.data)}</pre> : null}{i.failedReason ? <span className={s.failedReason}>{i.failedReason}</span> : null}</div>
            </ListGroup.Item>
            )}
            </ListGroup>

            {_job.isRepeatable ? <Alert variant='info' icon='sync' message={`Next repeating job: ${moment.tz(_job.nextScheduledJob, time_zone).format('MMM D h:mm:ss A')}`} /> : null}
            </>
            : null}
        </div>
    )
}

export default GlobalAdminJobQueueStatus
