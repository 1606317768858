import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ListGroup, Button, FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './NewGroup.scss'
import validator from 'validator'
import { checkNewGroupNameAvailability, getAllGroupRoles, 
    getMyGroups, createNewGroup, getDefaultEntryFeePerPlayer } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const NewGroup = ({ onCancel }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [minLength, setMinLength] = useState(0)
    const [maxLength, setMaxLength] = useState(0)
    const [isAvailable, setIsAvailable] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const defaultEntryFeePerPlayer = useSelector(state => state.groups.defaultEntryFeePerPlayer)

    useEffect(() => {
        dispatch(getDefaultEntryFeePerPlayer())
        dispatch(checkNewGroupNameAvailability(''))
            .then(action => newGroupNameCheckCallback(action.payload))
    }, [])

    useEffect(() => {
        if (!validator.isEmpty(name) && 
            validator.isLength(name, { min: minLength, max: maxLength }))
        {
            dispatch(checkNewGroupNameAvailability(name))
            .then(action => newGroupNameCheckCallback(action.payload)) 
        } else {
            setIsAvailable(false)
        }
    }, [name])

    const newGroupNameCheckCallback = (o = {}) => {
        if (o.name === name) {
            const { isAvailable: _isAvailable, maxLength: _maxLength, minLength: _minLength } = o
            setMaxLength(_maxLength)
            setMinLength(_minLength)
            setIsAvailable(_isAvailable)
        }
    }

    const handleNameChange = e => setName(e.target.value)

    const doCreateNewGroup = () => {
        if (name && isAvailable) {
            setIsSaving(true)
            dispatch(createNewGroup(name))
            .then(action => {
                dispatch(createNotification(action.payload))
                dispatch(getAllGroupRoles())
                .then(() => { 
                    dispatch(getMyGroups())
                    .then(() => {
                        setIsSaving(false)
                        onCancel() 
                        navigate(`/group/${action.payload.groupId}/admin/settings`)
                    })
                })
            })
        }
    }

    return (
        <div className={s.container}>
            <p>Adding a new group is a three step process:</p>
            <ListGroup className={s.list}>
                <ListGroup.Item className={s.item}>
                    <div className={s.content}>
                        <div className={s.number}>1</div>
                        <div className={s.main}>
                            <p>Give your group a unique name:</p>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroup.Text>Name</InputGroup.Text>
                                    <FormControl value={name} onChange={e => handleNameChange(e)} />
                                    <InputGroup.Text className={s.indicator + ' ' + (isAvailable ? s.isValid : s.isInvalid)}>
                                        <FontAwesomeIcon name={isAvailable ? 'check' : 'times'} fixedWidth />
                                    </InputGroup.Text>
                                </InputGroup>
                            </FormGroup>
                        </div>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className={s.item}>
                    <div className={s.content}>
                        <div className={s.number}>2</div>
                        <div className={s.main}>
                            <p>Invite your friends to join the group in the players tab (you just need a list of their emails).</p>
                        </div>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className={s.item}>
                    <div className={s.content}>
                        <div className={s.number}>3</div>
                        <div className={s.main}>
                            <p>Start planning your first trip! Setting up a trip is free for group administrators, we only charge a ${defaultEntryFeePerPlayer} fee to each player when they RSVP that they are going on one of your trips.  </p>
                        </div>
                    </div>
                </ListGroup.Item>
            </ListGroup>
            <div className={s.buttons}>
                <Button variant='light' onClick={() => onCancel()}><FontAwesomeIcon name='times' /> cancel</Button>
                <Button variant='primary' disabled={!isAvailable || isSaving} 
                    onClick={() => doCreateNewGroup()}><FontAwesomeIcon spin={isSaving} name='users' /> create my group</Button>
            </div>
        </div>
    )
}

export default NewGroup