import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { first } from 'lodash'
import arrayToSentence from 'array-to-sentence'
import Modal from '@severed-links/common.modal'
import TripRoomAssignmentPanel  from './TripRoomAssignmentPanel'
import { setTripValue } from '@severed-links/common.severedlinks-reducers/groups'
import * as s from './MyRoomAssignment.scss'
import * as roomCss from './TripRoomAssignmentPanel.scss'

const MyRoomAssignment = ({ groupId, tripId, roomAssignments = [], showRoomAssignmentModal = false }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const toggleModalVisibility = _bool => dispatch(setTripValue(groupId, tripId, 'showRoomAssignmentModal', _bool))
    const room = (roomAssignments || []).find(ra => (ra.roomPlayers || []).some(rp => rp.playerId === playerId))
    if (!roomAssignments || roomAssignments.length === 0 || !playerId) return null
    const roomName = ((room || {}).roomAssignment || {}).roomName
    const roommates = ((room || {}).roomPlayers || []).filter(p => p.playerId !== playerId)
    return (
        <span className={s.myRoomAssignment} title={`Roommates: ${arrayToSentence(roommates.map(p => p.playerName))}`}>
            <b>Room:</b>
            {' '}
            {roomName || 'Not assigned yet.'}
            {' '}
            {roommates.length >= 1 && roommates.length <= 3 ? `with ${arrayToSentence(roommates.map(p => p.playerName))}` : null}
            {roommates.length > 3 ? `with ${first(roommates).playerName} and ${roommates.length - 1} others...` : null}
            <Button variant='link' className={s.link} onClick={() => toggleModalVisibility(true)}>show all rooms</Button>

            <Modal show={showRoomAssignmentModal} size='lg'
                heading='Room assignments for trip' 
                onClose={() => toggleModalVisibility(false)}
                showFooter={false}>
                <div className={roomCss.container}>
                    {roomAssignments && roomAssignments.map(r =>
                        <TripRoomAssignmentPanel isAdmin={false} groupId={groupId} tripId={tripId}
                            key={`room-assignment-${r.roomAssignment.roomID}`}
                            roomAssignment={r.roomAssignment} roomPlayers={r.roomPlayers} />
                    )}
                </div>
            </Modal>
        </span>
    )
}

export default MyRoomAssignment