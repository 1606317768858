import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventIcon.scss'

const TripEventIcon = ({ icon = 'calendar', size = '2x', isTaskEvent = false, isPollEvent = false, isTeeTime = false }) => {

    return (
        <div className={s.container + (isTeeTime ? ` ${s.isTeeTime}` : ``)}>
            <FontAwesomeIcon size={size} name={icon} />
        </div>
    )
}

export default TripEventIcon
