import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import Modal from '@severed-links/common.modal'
import { FRIENDLY_SHORT_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import RecoverTripButton from './RecoverTripButton'
import * as s from './DeletedTrips.scss'

const DeletedTrips = ({ group = {}, deletedTrips = [], isGlobalAdmin = false }) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const isGroupAdmin = (group.role || {}).isAdmin || isGlobalAdmin

    const onRecover = () => {
        setShow(false)
    }

    return (
        <div className={s.container}>
        {isGroupAdmin && group.deletedTrips && group.deletedTrips.length > 0 ? 
            <div className={s.deletedTripsButtonContainer}>
                <Button variant='light' onClick={() => setShow(true)}><FontAwesomeIcon name='trash' /> show deleted trips</Button>
            </div>
        : null}
            <Modal show={show} showFooter={true}
                heading={`Deleted Trips`} enforceFocus={false}
                showFooter={false}
                onClose={() => setShow(false)}>
                <div>
                <ListGroup className={s.list}>
                {group && group.deletedTrips && group.deletedTrips.map(i =>
                    <ListGroup.Item key={`deleted-trip-${i._id}`} className={s.item}>
                        <h4>{i.tripName}</h4>
                        <p><small>Available for recovery until {moment(i.dateDeleted).add(30, 'days').format(FRIENDLY_SHORT_DATE_FORMAT)}</small></p>
                        <p><RecoverTripButton className={s.recoverButton} group={group} trip={i} onRecover={onRecover} isGlobalAdmin={isGlobalAdmin} /></p>
                    </ListGroup.Item>
                )}
                </ListGroup>
                </div>
            </Modal>
        </div>
    )
}

export default DeletedTrips