import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, FormLabel, Button, FormGroup, FormControl, 
    Image, ListGroup, InputGroup, ButtonGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { editGroupPlayer, updatePlayerNote, deletePlayerNote, 
    savePlayerProfileByAdmin, getPlayerInfo } from '@severed-links/common.severedlinks-reducers/player'
import moment from 'moment-timezone'
import { getPlayers, setPlayerRoleByRoleId } from '@severed-links/common.severedlinks-reducers/groups'
import { getPlayerGroups, deletePlayer } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import { checkIfUserNameAvailable, setUserInfo, setUsername } from '@severed-links/common.severedlinks-reducers/siteRegistration'
import { FRIENDLY_DATE_FORMAT, playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import TextareaAutosize from 'react-autosize-textarea'
import * as s from '../Player/GroupPlayerEditor.scss'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import GroupListItem from '../Groups/GroupListItem'
import NewImageUploadModal from './NewImageUploadModal'
import Spinner from '@severed-links/common.spinner'

const GlobalAdminEditPlayer = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const playerId = params.playerId || null
    const updatedByPlayerId = useSelector(state => state.account._id)
    const [userName, setUserName] = useState('')
    const [originalUserName, setOriginalUserName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [cellPhone, setCellPhone] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [notes, setNotes] = useState([])
    const [logins, setLogins] = useState(0)
    const [lastLogin, setLastLogin] = useState(null)
    const [role, setRole] = useState(null)
    const [userNameAvailable, setUserNameAvailable] = useState(false)
    const [cellPhoneConfirmed, setCellPhoneConfirmed] = useState(false)
    const [newNote, setNewNote] = useState('')
    const [editNoteId, setEditNoteId] = useState(null)
    const [editNote, setEditNote] = useState('')
    const [editRole, setEditRole] = useState(false)
    const [playerGroups, setPlayerGroups] = useState([])
    const [groupId, setGroupId] = useState(null)
    const [showNewImageModal, setShowNewImageModal] = useState(false)
    const [isLoaded, setLoaded] = useState(false)

    useEffect(() => {
        if (playerId)
        {
            dispatch(editGroupPlayer(0, playerId))
            .then(action => {
                setNewNote('')
                setOriginalUserName(action.payload.userName)
                setUserName(action.payload.userName)
                setFirstName(action.payload.firstName)
                setLastName(action.payload.lastName)
                setImageUrl(action.payload.imageUrl)
                setAddress(action.payload.address)
                setEmailAddress(action.payload.emailAddress)
                setCellPhone(action.payload.cellPhone)
                setCellPhoneConfirmed(action.payload.cellPhoneConfirmed)
                setCity(action.payload.city)
                setState(action.payload.state)
                setZip(action.payload.zip)
                setLastLogin(action.payload.lastLogin)
                setLogins(action.payload.logins)
                setNotes(action.payload.notes || [])
                setRole(null)
                dispatch(getPlayerGroups(playerId))
                .then(action2 => {
                    const _groups = action2.payload || []
                    setPlayerGroups(_groups)
                    if (_groups.length) {
                        setGroupId(_groups[0]._id)
                    }
                    setLoaded(true)
                })
            })
        } else {
            close()  
        }     
    }, [playerId])

    useEffect(() => checkUserNameAvailability(), [userName])

    const close = () => navigate(`/global-admin/players/filter/${(lastName || '').substring(0,1).toUpperCase()}`)

    const deleteNote = note => {
        dispatch(deletePlayerNote(0, note._id))
        .then(action => {
            setNotes(action.payload.data)
        })
    }

    const handleNoteKeyPress = e => {
        if (e.key === 'Enter' && newNote) {            
            addNewNote()
            e.preventDefault()
        }
    }

    const updateNote = () => {
        dispatch(updatePlayerNote(0, playerId, editNoteId, updatedByPlayerId, editNote))
        .then(action => {
            setNotes(action.payload.data)
            setEditNote('')
            setEditNoteId(null)
        })
    }

    const addNewNote = () => {
        dispatch(updatePlayerNote(0, playerId, 0, updatedByPlayerId, newNote))
        .then(action => {
            setNotes(action.payload.data)
            setNewNote('')
        })
    }

    const save = () => {
        const _postData = { userName, imageUrl, firstName, lastName, address, city, state, zip, cellPhone, emailAddress, cellPhoneConfirmed }
        dispatch(savePlayerProfileByAdmin(groupId || '0', playerId, _postData))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: "Save User Profile", timeout: 4000 }))
            dispatch(getPlayerInfo(groupId || '0', playerId))
            close()
        })
    }

    const deletePlayer = () => {
        const lastLetter = (lastName || '').substring(0,1).toUpperCase()
        dispatch(deletePlayer(playerId))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: "Delete Player Account", timeout: 4000 }))
            navigate(`/global-admin/players/filter/${lastLetter}`)
        })
    }

    const handleUserNameChange = e => setUserName((e.target.value || '').toUpperCase())

    const checkUserNameAvailability = () => {
        dispatch(checkIfUserNameAvailable(userName))
        .then(action => {
            setUserNameAvailable(userName && userName.length >= 3 && (userName === originalUserName || action.payload.isAvailable))
        })
    }

    const okToSave = () => firstName && lastName && cellPhone && emailAddress && userName && userNameAvailable

    return (
        isLoaded ?
        <div className={s.container}>
            <div className={s.gaFirstRowContainer}>
                <div className={s.gaAvatarContainer}>
                    <Image src={playerAvatarUrl(imageUrl)} />
                    <div className={s.gaButtonsContainer}>
                        <Button variant='info'
                            onClick={() => setShowNewImageModal(true)}>
                            <FontAwesomeIcon name='upload' />
                        </Button>
                        {imageUrl ? <Confirm title={'Delete Profile Image'} 
                            onConfirm={() => setImageUrl(null)}
                            confirmText='delete image'
                            body={<div><p>Are you sure you want to delete this image?</p><p style={{ textAlign: 'center' }}><Image rounded src={playerAvatarUrl(imageUrl)} style={{ width: '100px' }} /></p></div>}
                            variant='danger' buttonIcon='trash' />
                        : null}
                    </div>
                </div>
                <div className={s.gaFirstAndLastNameContainer}>
                    <FormGroup>
                        <FormLabel>First Name</FormLabel>
                        <FormControl value={firstName} onChange={e => setFirstName(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Last Name</FormLabel>
                        <FormControl value={lastName} onChange={e => setLastName(e.target.value)} />
                    </FormGroup>
                </div>
            </div>
            <Row>
                <Col sm={6} xs={12}>
                    <FormGroup>
                        <FormLabel>Address</FormLabel>
                        <FormControl value={address} onChange={e => setAddress(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <FormLabel>City</FormLabel>
                        <FormControl value={city} onChange={e => setCity(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <FormLabel>State</FormLabel>
                        <FormControl value={state} onChange={e => setState(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <FormLabel>Zip</FormLabel>
                        <FormControl value={zip} onChange={e => setZip(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col sm={6} xs={12}>
                    <FormGroup>
                        <FormLabel>Cell phone</FormLabel>
                        <InputGroup>
                        <FormControl value={cellPhone} onChange={e => setCellPhone(e.target.value)} />
                        <InputGroup.Text><FontAwesomeIcon name={cellPhoneConfirmed ? 'check' : 'times' } color={cellPhoneConfirmed ? 'green' : 'red'} /></InputGroup.Text>                            
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col sm={6} xs={12}>
                    <FormGroup>
                        <FormLabel>Email address</FormLabel>
                        <FormControl value={emailAddress} onChange={e => setEmailAddress(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <FormLabel>Logins</FormLabel>
                        <FormControl readOnly value={logins} />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <FormLabel>Last login</FormLabel>
                        <FormControl readOnly value={lastLogin ? moment(lastLogin).format(FRIENDLY_DATE_FORMAT) : '[Never logged in]'} />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <FormLabel>Username</FormLabel>
                        <InputGroup>
                            <FormControl value={userName} 
                                required isValid={userNameAvailable}
                                isInvalid={!userNameAvailable}
                                onChange={e => handleUserNameChange(e)} />
                            <InputGroup.Text><FontAwesomeIcon name={userNameAvailable ? 'check' : 'times'} /></InputGroup.Text>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup style={{ textAlign: 'right' }}>
                        <FormLabel style={{ display: 'block' }}>&nbsp;</FormLabel>
                        <Button variant='light' onClick={() => close()}><FontAwesomeIcon name='times' /> cancel</Button>
                        {' '}
                        <Button variant='primary' disabled={!okToSave()} onClick={() => save()}><FontAwesomeIcon name='check' /> save profile</Button>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <h4 style={{ marginTop: '20px' }}>Player Groups</h4>
                        <ListGroup>
                    {playerGroups && playerGroups.map(i =>
                        <GroupListItem group={i} onClick={() => void(0)} key={`group-0-player-${playerId}-note-${i._id}`} />
                    )}
                    </ListGroup>
                </Col>
                <Col xs={12}>
                    <h4 style={{ marginTop: '20px' }}>Notes</h4>
                    <ListGroup>
                    {notes && notes.map(i =>
                    <ListGroup.Item className={s.noteItem} key={`group-0-player-${playerId}-note-${i._id}`}>
                        <Image src={playerAvatarUrl(i.updatedByPlayerImageUrl, true)}
                            roundedCircle className={s.avatar} />
                            <div className={s.noteBody}>
                                <div className={s.headerContainer}>
                                    <div className={s.nameContainer}>
                                        <h5 className={s.name}>{i.updatedByPlayerName}</h5>
                                        <span className={s.date}>{moment(i.updatedAt).fromNow()}</span>
                                    </div>
                                    <span className={s.edit + (i._id === editNoteId ? ' ' + s.hide: '')}>
                                        <Button onClick={() => {
                                            setEditNoteId(i._id)
                                            setEditNote(i.notes || '')
                                        }}>
                                            <FontAwesomeIcon name='pencil-alt' />
                                        </Button>
                                    </span>
                                    <span className={s.delete + (i._id === editNoteId ? ' ' + s.hide: '')}>
                                        <Confirm title={'Delete Player Note'}
                                            onConfirm={() => deleteNote(i)} enforceFocus={false}
                                            confirmText='delete note'
                                            body={<div><p>Are you sure you want to delete this note?</p></div>}
                                            variant='danger' buttonIcon='times' />
                                    </span>
                                </div>
                                <div className={s.noteContainer}>
                                    <div className={s.note + (i._id === editNoteId ? ' ' + s.hide: '')}>
                                        {i.notes}
                                    </div>
                                    <div className={s.note + (i._id !== editNoteId ? ' ' + s.hide: '')}>
                                    <InputGroup>
                                        <TextareaAutosize className='form-control' rows={2} 
                                            placeholder={'[Add a note here...]'} value={editNote} 
                                            onChange={e => setEditNote(e.target.value)} />
                                        <div className={s.buttonContainer}>
                                        <Button onClick={() => {
                                            setEditNote('')
                                            setEditNoteId(null)
                                        }} variant='light'>
                                            <FontAwesomeIcon name='times' /> cancel
                                        </Button>
                                        <Button onClick={() => updateNote()} variant='primary'><FontAwesomeIcon name='pencil-alt' /> save note</Button>
                                        </div>
                                    </InputGroup>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>
                    )}
                    </ListGroup>
                    <FormGroup style={{ marginTop: '10px' }}>
                        <TextareaAutosize className='form-control' rows={2} 
                            placeholder={'[Add a note here...]'} value={newNote} 
                            onKeyPress={e => handleNoteKeyPress(e)}
                            onChange={e => setNewNote(e.target.value)} />
                    </FormGroup>
                    <FormGroup style={{ marginTop: '20px' }}>
                        <Confirm title={'Delete Player Account'}
                            onConfirm={() => deletePlayer()}
                            confirmText='delete player account'
                            body={<div><p>Are you sure you want to delete this player?</p></div>}
                            variant='danger' buttonIcon='trash' buttonText='delete player account' />
                    </FormGroup>
                </Col>
            </Row>
            <NewImageUploadModal show={showNewImageModal} playerId={playerId}
                onClose={() => setShowNewImageModal(false)}
                onUpload={_imageUrl => setImageUrl(_imageUrl || null)} />
        </div>
        : 
        <div className={s.spinnerContainer}>
            <Spinner size='3x' />
        </div>
    )
}

export default GlobalAdminEditPlayer