// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripRoundNotesAdminEditNote__container___o_2dO{margin-bottom:1rem}.TripRoundNotesAdminEditNote__addNoteModalContainer___W6xpM{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Admin/TripRoundNotesAdminEditNote.scss"],"names":[],"mappings":"AAEA,gDACI,kBAAA,CAGJ,4DACI,kBAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n\n.container {\n    margin-bottom: 1rem;\n}\n\n.addNoteModalContainer {\n    margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripRoundNotesAdminEditNote__container___o_2dO`;
export var addNoteModalContainer = `TripRoundNotesAdminEditNote__addNoteModalContainer___W6xpM`;
export default ___CSS_LOADER_EXPORT___;
