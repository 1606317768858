import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Alert, Button, Badge, Form, FormGroup, 
    FormLabel, FormControl, FormControlFeedback, ListGroup,
    Tooltip, OverlayTrigger } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
import { getMyTickets, getOthersTickets, toggleTicketModal, setActiveTicket, 
    updateActiveTicket, loadActiveTicket } from '@severed-links/common.severedlinks-reducers/help'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './HelpCenter.scss'
import { FRIENDLY_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import pluralize from 'pluralize'

const StatusTooltip = ({ id, variant = 'info', badgeText, message }) => (
    <OverlayTrigger placement='left' overlay={<Tooltip className={s[variant]} id={`tooltip-help-ticket-item-${id}-${variant}`}>{message}</Tooltip>}>
        <Badge size='sm' bg={variant} text={variant === 'light' || variant === 'warning' ? 'dark' : 'light'}>{badgeText}</Badge> 
    </OverlayTrigger>
)

const TicketListItem = ({ t = { player: {} } }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const completedRetentionMinutes = useSelector(state => state.help.completedRetentionMinutes)
    const deletedRetentionMinutes = useSelector(state => state.help.deletedRetentionMinutes)
    const [lastRender, setLastRender] = useState(null)
    const completedDuration = moment.duration ? moment.duration(completedRetentionMinutes, 'minutes') : null
    const deletedDuration = moment.duration ? moment.duration(deletedRetentionMinutes, 'minutes') : null
    const { imageUrl, firstName, lastName } = t.player || {}

    useEffect(() => {
        if (!moment.duration) momentDurationFormatSetup(moment)
        let timerId = setInterval(() => setLastRender(new Date()), 10 * 1000)
        return () => clearInterval(timerId)
    }, [])
    
    const openTicket = ticket => {
        navigate(`/profile/help-center/ticket/${ticket._id}`)
    }

    return (
        <ListGroup.Item key={`ticket-${t._id}`} className={s.ticketListItem} action
            onClick={() => openTicket(t)}>
            <div className={s.ticketContent}>
                <div title={t.ticketType} className={s.ticketTypeIcon + ' ' + s[(t.ticketType || 'Bug').replace(/\s+/g, '').toLowerCase()]}>
                    {t.ticketType === 'Bug' ? <FontAwesomeIcon name='bug' fixedWidth /> : null}
                    {t.ticketType === 'Feature Request' ? <FontAwesomeIcon name='star' fixedWidth /> : null}
                    {t.ticketType === 'Comment' ? <FontAwesomeIcon name='comment' fixedWidth /> : null}
                </div>
                <div className={s.avatar}>
                    <img src={playerAvatarUrl(imageUrl)} />
                </div>
                <div className={s.details}>
                    <div className={s.playerName}>{firstName} {lastName}</div>
                    <div className={s.description}>{t.description}</div>
                </div>
                {t.comments && t.comments.length > 0 ? 
                <div className={s.commentsContainer}>
                    <FontAwesomeIcon name='comment' className={s.commentIcon} />
                    {t.comments.length > 0 ? <div className={s.commentCount}>{t.comments.length}</div> : null}
                </div>
                : null}
                <div className={s.rightContainer}>
                    {!t.isCompletedTicket && !t.isDeletedTicket ? 
                        <Badge bg={t.createDate === t.updateDate ? 'light' : 'info'} 
                            text={t.createDate === t.updateDate ? 'dark' : 'light'} 
                            size='sm'>
                            {t.createDate === t.updateDate ? 'Created' : 'Updated'}
                        </Badge>
                    : null}
                    {completedDuration && t.isCompletedTicket ? <StatusTooltip id={t._id} variant='success' badgeText='Completed' message={`Completed tickets are permanently deleted every ${completedDuration.humanize()}`} /> : null}
                    {deletedDuration && t.isDeletedTicket ? <StatusTooltip id={t._id} variant='danger' badgeText='Deleted' message={`Deleted tickets are permanently deleted every ${deletedDuration.humanize()}`} /> : null}
                    <div className={s.date}>{moment(t.updateDate).fromNow()}</div>
                </div>

            </div>
            <div className={s.mobileDescription}>{t.description}</div>
            <div className={s.mobileDetails}>
                <div className={s.commentCountContainer}>{pluralize('comment', t.comments.length, true)}</div>
                <div>
                {!t.isCompletedTicket && !t.isDeletedTicket ? 
                    <Badge bg={t.createDate === t.updateDate ? 'light' : 'info'} text={t.createDate === t.updateDate ? 'dark' : 'light'} size='sm'>
                        {t.createDate === t.updateDate ? 'Created' : 'Updated'}
                    </Badge>
                : null}
                </div>
                <div className={s.updatedContainer}>{moment(t.updateDate).fromNow()}</div>
            </div>
        </ListGroup.Item>
    )
}

export default TicketListItem