import { RSAA } from 'redux-api-middleware';
import { endsWith } from 'lodash';

export default function ({ getState }) {
  return function getNext(next) {
    return function getAction(action) {
      const callApi = action[RSAA];
      if (callApi) {
        const { account } = getState();
        if (!callApi.endpoint.endsWith("token")) {
          var headersToInject = { 
            'Authorization': account.access_token ? "Bearer " + account.access_token : '',
            'Accept': 'application/json'
          }

          if (callApi.endpoint.indexOf("upload") == -1 && callApi.endpoint.indexOf("AddNewPoster") == -1) {
            headersToInject['Content-Type'] = 'application/json'
          } else {
            headersToInject['Content-Disposition'] = 'form-data'
            delete headersToInject['Content-Type']
          }
        }
        callApi.headers = Object.assign({}, callApi.headers, headersToInject);
      }
      return next(action);
    };
  };
}
