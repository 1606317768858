import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ProxyDistanceDisplay.scss'

const ProxyDistanceDisplay = ({ className = null, units = 'yds', distance = 0, inches = 0 }) => {

    
    return (
        <div className={s.container + (className ? ` ${className}` : '')}>
        {units === 'yds' ? `${distance} yds` : !inches ? `${distance} ft` : `${distance}' ${inches}"`}
        </div>
    )
}

export default ProxyDistanceDisplay
