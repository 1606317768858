import React, { useState } from 'react'
import { Card, ListGroup, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripWeather.scss'
import moment from 'moment-timezone'
import { titleCase } from 'change-case'

const TripWeather = ({ city, state, weather, beginDate }) => {

    if (!city || !weather) return null
    const { temp, weatherCode, forecasts, iconUrl, weatherDescription } = weather
    return (
        <Card className={s.container}>
            <Card.Header className={s.header}>
                <div className={s.icon}><FontAwesomeIcon name='sun' /></div>
                <div className={s.title}>Weather in {city}, {state}</div>
            </Card.Header>
            {weather.observationTime ?
            <ListGroup variant='flush'>
                <ListGroup.Item className={s.currentConditions}>
                    <div className={s.observation}>{weatherDescription}</div>
                    <div className={s.temp + ' ' + (temp >= 65 ? s.highTemp : temp >= 50 ? s.mediumTemp : s.lowTemp)}>
                        <span>{temp}&deg;</span>
                    </div>
                    {iconUrl ? <div className={s.icon}><Image src={iconUrl} /></div> : null}
                </ListGroup.Item>
            </ListGroup>
            : null}
            {forecasts && forecasts.length ?
            <ListGroup variant='flush' className={s.forecast}>
            {forecasts && forecasts.map(i =>
                <ListGroup.Item className={s.forecastItem} key={`forecast-lg-${city}-${state}-${i.num}`}>
                    <div className={s.day}>{i.shortDay}</div>
                    <div className={s.icon}>
                        {i.iconUrl ? <Image src={i.iconUrl} /> : null}
                    </div>
                    <div className={s.tempsContainer}>
                        <div className={s.temps}>
                            {i.hiTemp ? <div className={s.temp + ' ' + s.highTemp}>{i.hiTemp}&deg;</div> : null}
                            {i.lowTemp ? <div className={s.temp + ' ' + s.lowTemp}>{i.lowTemp}&deg;</div> : null}
                        </div>
                        <div className={s.precipitationContainer}>
                            {i.precipitationProbability ? <span><FontAwesomeIcon name='cloud-rain' /> {i.precipitationProbabilityDisplay}</span> : null}
                        </div>
                    </div>
                </ListGroup.Item>
            )}
            </ListGroup>
            : null}
        </Card>
    )
}

export default TripWeather