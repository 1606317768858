import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useMatch, useLocation, Outlet, useOutletContext } from 'react-router-dom'
import { Button, FormControl, FormGroup, FormLabel, 
    Nav, InputGroup, Badge, FormCheck } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { updateRoundBoolean, getTripRounds, updateRoundTeeTimeInterval, 
    updateRoundDay, updateRoundFirstTeeTime, setMatchPlayFormat, 
    setRoundGolfCourse, getAdminMatches, updateTeeTimeAdjusterForRound,
    updateRoundSkinType, updateRoundHolesPlayed } from '@severed-links/common.severedlinks-reducers/groups'
import * as s from '../TripRounds.scss'
import Datetime from 'react-datetime'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import moment from 'moment-timezone'
import { range, isEqual, difference } from 'lodash'
import { scroller, Element } from 'react-scroll'
import RequestForHelpAlert from './RequestForHelpAlert'

import { DEFAULT_TIME_ZONE, FRIENDLY_SHORT_TIME_FORMAT, FRIENDLY_LONG_DATE_FORMAT_WITH_DAY, FRIENDLY_SHORT_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import Confirm from '@severed-links/common.confirm'
import TripRoundGolfCourseSelector from './TripRoundGolfCourseSelector'
import * as adminCss from './TripRoundAdminEditRound.scss'
import { routingPath } from '@severed-links/common.severedlinks-constants'
import TripRoundTeeTimeAdjuster from './TripRoundTeeTimeAdjuster'
import Picker from '@severed-links/common.picker'

const CourseInfoItem = ({ title = '', value = '' }) => (
    <FormGroup className={adminCss.centeredFormGroup + ' ' + adminCss.fixedWidth}>
        <FormLabel className={adminCss.roundLabel}>{title}</FormLabel>
        <FormControl plaintext readOnly className={adminCss.roundInfo} value={value || '--'} />
    </FormGroup>
)

const EditCourseItem = ({ 
    type = 'text', label = '', showPicker = false, options = [], pickerText = '', viewMode = 'datetime', 
    dateFormat = false, timeFormat = false, value = '', icon = '', suffix = '', 
    alignRight = false, disabled = false, formGroupClass = null, onText = 'Yes', offText = 'No',
    onChange = null, onBlur = null, onClose = null, onClick = null
}) => (
    <FormGroup className={adminCss.centeredFormGroup + (formGroupClass ? ` ${formGroupClass}` : '')}>
        <FormLabel className={adminCss.roundLabel + ' ' + adminCss.isForm}>{label}</FormLabel>
        <InputGroup className={adminCss.inputGroup}>
            {icon ? <InputGroup.Text><FontAwesomeIcon name={icon} /></InputGroup.Text> : null}
            {type === 'text' ? 
            <FormControl disabled={disabled} value={value} 
                onChange={e => onChange(e)} 
                onBlur={e => onBlur(e)} 
                style={alignRight ? { textAlign: 'right' } : {}} />
            : type === 'select' ? 
            <FormControl as='select' value={value || ''}
                onChange={e => onChange(e)}>
                {options && options.map(i => <option value={i.value} key={`edit-round-${label}-option-${i.value}`}>{i.text}</option>)}
            </FormControl>
            : type === 'date' ? 
            <Datetime dateFormat={dateFormat} timeFormat={timeFormat} 
                value={value}
                onChange={e => onChange(e)}
                onClose={e => onClose(e)}
                viewMode={viewMode} />
            : type === 'switch' ? 
            <div className={adminCss.flipSwitchContainer}>
                <FormCheck type='switch' checked={value || false}
                    onChange={e => onChange(e.target.checked)} />
            </div>
            : type === 'picker' ?
            <>
                <Button className={adminCss.pickerButton} variant='light' onClick={onClick}>{pickerText}</Button>
                <Picker show={showPicker} items={options}
                    onClose={onClose}
                    heading={`Select ${(label || '').toLowerCase()} for round...`}
                    onSelect={_item => onChange(_item)}
                    defaultValue={value} />
            </>
            : null}
            {suffix ? <InputGroup.Text>{suffix}</InputGroup.Text> : null}
        </InputGroup>
    </FormGroup>
)

const TripRoundAdminEditRound = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const _tripContext = useOutletContext()
    const { groupId, group, tripId, trip, isGlobalAdmin, isGlobalAdminPath } = _tripContext
    const _skinTypes = useSelector(state => state.trips.skinTypes)
    const _holesPlayed = useSelector(state => state.trips.holesPlayed)

    const path = routingPath(groupId, isGlobalAdminPath, (group.name || ' ').substring(0,1).toUpperCase())
    const _pathPrefix = `${path}trip/:tripId/admin/rounds/edit/round/:round/:roundSubTab`
    const match = useMatch(`${_pathPrefix}/*`)
    const roundSubTab = ((match || {}).params || {}).roundSubTab || null
    const roundParam = params.round || '0'
    const roundNumber = parseInt(roundParam)
    const round = (trip.rounds || []).find(i => i.round === roundNumber) || {}
    const roundName = round.roundName
    const _timeZoneId = (round.timezone || {}).timeZoneId || (trip.timezone || {}).timeZoneId || DEFAULT_TIME_ZONE

    const [interval, setTeeTimeInterval] = useState('0')
    const [firstTeeTime, setFirstTeeTime] = useState(moment().tz(DEFAULT_TIME_ZONE))
    const [showGolfCourseSelector, setShowGolfCourseSelector] = useState(false)
    const [showSkinTypePicker, setShowSkinTypePicker] = useState(false)
    const [showHolesPlayedPicker, setShowHolesPlayedPicker] = useState(false)
    const [showRoundDayPicker, setShowRoundDayPicker] = useState(false)
    const [showMatchPlayFormatPicker, setShowMatchPlayFormatPicker] = useState(false)

    const begin = moment(trip.beginDate)
    const end = moment(trip.endDate)
    const dayRange = begin.isValid() || !end.isValid() ? range(1, end.diff(begin, 'days') + 2, 1) : []
    const dayRangeOptions = (dayRange || []).map(i => ({ value: i, text: moment(begin).add(i - 1, 'days').format(FRIENDLY_LONG_DATE_FORMAT_WITH_DAY) })).map(i => ({ ...i, title: i.text }))
    const matchPlayFormats = trip.matchPlayFormats || []
    const matchPlayFormatOptions = (matchPlayFormats || []).map(i => ({ value: i._id, text: i.formatName, title: i.formatName, subtitle: i.description || '' }))
    const noteCount = (round.notes || []).length

    useEffect(() => {
        if (!roundSubTab) {
            navigate(`${location.pathname}/matches`, { replace: true })
        }
    }, [roundSubTab])

    useEffect(() => {
        if (round.round) {
            setInitialState()
        }
    }, [round.updatedAt, round.round])

    const setInitialState = () => {
        if (round && round.round) {
            setTeeTimeInterval(`${round.teeTimeInterval || 10}`)
            const _newTeeTime = moment.tz(`${moment(begin).add(round.roundDay - 1, 'days').format(FRIENDLY_SHORT_DATE_FORMAT)} ${round.teeTime || '8:00 AM'}`, `${FRIENDLY_SHORT_DATE_FORMAT} ${FRIENDLY_SHORT_TIME_FORMAT}`, _timeZoneId)
            setFirstTeeTime(_newTeeTime)
        }
    }

    const updateBooleanSetting = (round, fieldName, checkedState) => {
        dispatch(updateRoundBoolean(groupId, tripId, round, fieldName, checkedState))
        .then(action => {
            dispatch(getTripRounds(groupId, tripId))
            if (action.payload.teeTimeAdjuster) {
                dispatch(updateTeeTimeAdjusterForRound(action.payload.teeTimeAdjuster))
            }
        })
    }

    const menuItems = [
        { name: 'Matches', icon: 'list', path: `matches`, disabled: false },
        { name: `Foursomes/${(round.isShotgun ? 'Starting holes' : 'Tee times')}`, icon: 'play-circle', path: `foursomes`, disabled: false },
        { name: 'Proxies', icon: 'flag', path: `proxies`, disabled: false },
        { name: 'Scorecard', icon: 'clipboard-list', path: `scorecard`, disabled: false },
        { name: 'Notes', icon: 'sticky-note', path: `notes`, disabled: false, badgeCount: noteCount },
    ]

    const handleSelect = e => {
        const _newPath = `${_pathPrefix.replace(`/:tripId/`, `/${tripId}/`).replace(`/round/:round/`, `/round/${roundNumber}/`).replace(`/:roundSubTab`, `/${e}`)}`
        navigate(`${_newPath}`)
        const options = {
            duration: 500,
            delay: 50,
            smooth: true,
            offset: -100,
        }
        scroller.scrollTo('roundNavMenu', options) 
    }

    const setRoundDay = e => {
        dispatch(updateRoundDay(groupId, tripId, round.round, parseInt(e.target.value)))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
            if (action.payload.teeTimeAdjuster) {
                dispatch(updateTeeTimeAdjusterForRound(action.payload.teeTimeAdjuster))
            }
        })
    }

    const saveFirstTeeTime = () => {
        if (firstTeeTime && firstTeeTime.isValid()) {
            dispatch(updateRoundFirstTeeTime(group._id, trip._id, round.round, firstTeeTime.format(FRIENDLY_SHORT_TIME_FORMAT)))
            .then(action => {
                dispatch(createNotification({ ...action.payload }))
                if (action.payload.teeTimeAdjuster) {
                    dispatch(updateTeeTimeAdjusterForRound(action.payload.teeTimeAdjuster))
                }    
            })
        }
    }

    const handleIntervalChange = e => setTeeTimeInterval(e.target.value)

    const saveIntervalValue = e => {
        if (!isNaN(e.target.value)) {
            dispatch(updateRoundTeeTimeInterval(group._id, trip._id, round.round, parseInt(e.target.value)))
            .then(action => {
                dispatch(createNotification({ ...action.payload }))
                if (action.payload.teeTimeAdjuster) {
                    dispatch(updateTeeTimeAdjusterForRound(action.payload.teeTimeAdjuster))
                }
            })
        }
    }

    const setRoundMatchPlayFormat = e => {
        const matchPlayFormats = trip.matchPlayFormats || []
        const matchPlayFormat = matchPlayFormats.find(i => i._id === e.target.value)
        dispatch(setMatchPlayFormat(group._id, trip._id, round.round, matchPlayFormat._id))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
        })
    }

    const handleSkinTypeChange = _selectedSkinType => {
        setShowSkinTypePicker(false)
        dispatch(updateRoundSkinType(group._id, trip._id, round.round, _selectedSkinType))
        .then(action => {
            dispatch(createNotification(action.payload))
        })

    }

    const handleHolesPlayedChange = _holesPlayedCode => {
        setShowHolesPlayedPicker(false)
        dispatch(updateRoundHolesPlayed(group._id, trip._id, round.round, _holesPlayedCode))
        .then(action => {
            dispatch(createNotification(action.payload))
        })

    }

    const showGolfCourseSelectorModal = () => setTimeout(() => setShowGolfCourseSelector(true), 500)

    const handleCloseGolfCourseSelector = () => setShowGolfCourseSelector(false)

    const handleSelectGolfCourse = golfCourseId => {
        handleCloseGolfCourseSelector()
        dispatch(setRoundGolfCourse(group._id, trip._id, round.round, golfCourseId))
        .then(action => dispatch(createNotification({ ...action.payload })))
    }

    if (!groupId || !tripId || !round.round || !group || !trip || !trip.rounds || trip.rounds.length === 0) return null
    return (
        <div className={adminCss.container}>
            <div className={adminCss.heading}>
                <div className={adminCss.controls}>
                    <Button variant='light' style={{ marginRight: '10px' }} onClick={() => navigate(`/group/${group._id}/trip/${trip._id}/admin/rounds`)}><FontAwesomeIcon name='chevron-left' /></Button>
                </div>
                <h4 className={adminCss.title}>Edit {round.roundName}: {`${round.courseName || 'Golf course not selected yet...'} ${round.tees ? ` (${round.tees} tees)` : ''}`}</h4>
            </div>
            {round.courseName ? 
            <div className={adminCss.courseInfo}>
                <CourseInfoItem title='Par' value={round.par} />
                <CourseInfoItem title='Yards' value={round.yards} />
                <CourseInfoItem title='Slope' value={round.slope} />
                <CourseInfoItem title='Rating' value={round.rating} />
            </div>
            : null}
            <div className={adminCss.courseInfo}>
                <EditCourseItem type='picker' label='Round day' 
                    name='calendar' suffix={(((round.timezone || {}).timeZoneName || '').match(/\b(\w)/g) || []).join('').toUpperCase()}
                    pickerText={(dayRangeOptions.find(i => i.value === round.roundDay) || {}).title || ''}
                    formGroupClass={adminCss.roundDay}
                    value={round.roundDay} options={dayRangeOptions} showPicker={showRoundDayPicker}
                    onClick={() => setShowRoundDayPicker(true)}
                    onClose={() => setShowRoundDayPicker(false)}
                    onChange={_item => setRoundDay({ target: { value: _item.value } })} />
                <EditCourseItem type='date' label={`${round.isShotgun ? 'Shotgun' : 'First'} tee time`} value={firstTeeTime}
                    formGroupClass={adminCss.teeTime}
                    onChange={e => setFirstTeeTime(moment(e))} 
                    onClose={e => saveFirstTeeTime(moment(e))}
                    name='clock' viewMode='time' dateFormat={false} timeFormat={FRIENDLY_SHORT_TIME_FORMAT} 
                    options={(dayRange || []).map(i => ({ value: i, text: moment(begin).add(i - 1, 'days').format(FRIENDLY_LONG_DATE_FORMAT_WITH_DAY) }))} />
                <EditCourseItem type='text' label='Interval' value={interval}
                    disabled={round.isShotgun} formGroupClass={adminCss.interval}
                    onChange={e => handleIntervalChange(e)} 
                    onBlur={e => saveIntervalValue(e)}
                    suffix={'min'} alignRight />
                <EditCourseItem type='picker' label='Format' 
                    name='play-circle'
                    pickerText={(matchPlayFormatOptions.find(i => i.value === round.matchPlayFormatID) || {}).title || ''}
                    formGroupClass={adminCss.matchPlayFormat}
                    value={round.matchPlayFormatID} options={matchPlayFormatOptions} showPicker={showMatchPlayFormatPicker}
                    onClick={() => setShowMatchPlayFormatPicker(true)}
                    onClose={() => setShowMatchPlayFormatPicker(false)}
                    onChange={_item => setRoundMatchPlayFormat({ target: { value: _item.value } })} />
                <EditCourseItem type='switch' label='Shotgun?' 
                    value={round.isShotgun}
                    onChange={_checkedState => updateBooleanSetting(round.round, 'isShotgun', _checkedState)} />
                <EditCourseItem type='picker' label='Skin Type' 
                    pickerText={(_skinTypes.find(i => i.value === round.skinType) || {}).title || ''}
                    formGroupClass={adminCss.skinType}
                    value={round.skinType} options={_skinTypes} showPicker={showSkinTypePicker}
                    onClick={() => setShowSkinTypePicker(true)}
                    onClose={() => setShowSkinTypePicker(false)}
                    onChange={_item => handleSkinTypeChange(_item.value)} />
                <EditCourseItem type='picker' label='Holes Played' 
                    pickerText={(_holesPlayed.find(i => i.value === round.holesPlayedCode) || {}).title || ''}
                    formGroupClass={adminCss.holesPlayed}
                    value={round.holesPlayedCode} options={_holesPlayed} showPicker={showHolesPlayedPicker}
                    onClick={() => setShowHolesPlayedPicker(true)}
                    onClose={() => setShowHolesPlayedPicker(false)}
                    onChange={_item => handleHolesPlayedChange(_item.value)} />
                <EditCourseItem type='switch' label='Show scorecard?' 
                    value={round.isOnlineScorecardEnabled}
                    onChange={_checkedState => updateBooleanSetting(round.round, 'isOnlineScorecardEnabled', _checkedState)} />
                <FormGroup className={adminCss.centeredFormGroup}>
                    <FormLabel className={adminCss.roundLabel + ' ' + adminCss.isForm}>&nbsp;</FormLabel>
                    {round.golfCourseID ?
                    <Confirm title={'Change Golf Course Round Teebox'} enforceFocus={false}
                        onConfirm={() => showGolfCourseSelectorModal()}
                        confirmText='proceed'
                        body={<div><p>Are you sure you want to proceed with changing the golf course for this round?</p><p>Changing the course after matches have been set up can have unexpected results and you will likely need to delete and re-enter your matches for this round.</p></div>}
                        variant='warning' buttonClassName={adminCss.golfCourseChangeButton}
                        buttonIcon='flag' buttonText='change golf course' />
                    :
                    <>
                    <Button variant='primary' onClick={() => showGolfCourseSelectorModal()} style={{ whiteSpace: 'nowrap' }}><FontAwesomeIcon name='flag' /> select golf course</Button>
                    <RequestForHelpAlert message='If you do not see the golf course you are looking for, please open a ticket with our help center.' />
                    </>
                }
                    <TripRoundGolfCourseSelector group={group} trip={trip} 
                        roundNumber={round.round} 
                        show={showGolfCourseSelector} 
                        onSelect={handleSelectGolfCourse}
                        onClose={handleCloseGolfCourseSelector} />
                </FormGroup>

            </div>
            <div>
                <Element name='roundNavMenu'>
                    <Nav className={adminCss.nav} variant='pills' activeKey={roundSubTab} onSelect={e => handleSelect(e)}>
                    {menuItems && menuItems.map(i =>
                        <Nav.Item className={adminCss.item} key={`trip-admin-main-nav-edit-round-${round.round}-${i.path}`}>
                            <Nav.Link className={adminCss.link + (i.path === roundSubTab ? ` ${adminCss.active}` : ``)} eventKey={i.path} disabled={i.disabled}>{i.icon ? <FontAwesomeIcon name={i.icon} /> : null} {i.name} {i.badgeCount ? <Badge text='light'>{i.badgeCount}</Badge> : null}</Nav.Link>
                        </Nav.Item>
                    )}
                    </Nav>
                </Element>
            </div>
            <Outlet context={{ ..._tripContext, round, roundSubTab, roundParam, roundNumber }} />
            <TripRoundTeeTimeAdjuster teeTimeAdjuster={(round || {}).teeTimeAdjuster} />
        </div>
    )
}

export default TripRoundAdminEditRound