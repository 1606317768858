import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Nav, Navbar } from 'react-bootstrap'
import * as s from './Menu.scss'

import SettingsMenu from './SettingsMenu'
import AuthTokenExpirationChecker from '@severed-links/common.auth-token-expiration-checker'
import { getMe, getTimeZone } from '@severed-links/common.severedlinks-reducers/account'

const BaseAuthenticatedMenu = ({ 
    menuClassName, navBarBrand, backgroundImageUrl = '', menuItems = [], 
}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isAuthenticated, _id, access_token, expirationDate } = useSelector(state => state.account) || { _id: null, first_name: '', last_name: '', userName: '', roles: [], isAuthenticated: false }
    const settingsMenuVisible = isAuthenticated && !!_id

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getTimeZone())
            dispatch(getMe())
        }
    }, [isAuthenticated])
    
    return (
        <Navbar collapseOnSelect fixed='top' 
            className={s.baseMenu}
            style={{ backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl}` : null }}>
            <Navbar.Brand className={s.navbarBrand}>{navBarBrand}</Navbar.Brand>
            <Navbar.Toggle className={s.navbarToggle} />
            <Navbar.Collapse className={s.navbarCollapse}>
                <Nav className={s.nav}>{menuItems}</Nav>
            </Navbar.Collapse>
            {settingsMenuVisible ? 
            <Navbar.Collapse className={s.navbarCollapse + ' justify-content-end'}>
                <SettingsMenu />
            </Navbar.Collapse>
            : null}

            <AuthTokenExpirationChecker expirationDate={expirationDate}
                onExpired={() => navigate('/logout', { state: { from: location }, replace: true })} />

        </Navbar>
    )
}

export default BaseAuthenticatedMenu
