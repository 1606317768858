import React, { useEffect } from 'react'
import { ListGroup, Image } from 'react-bootstrap'
import PlayerListItem from '../Player/PlayerListItem'
import * as s from './Top10List.scss'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'

const Top10List = ({ list = null }) => {

    if (!list) return null
    return (
        <div className={s.container}>
            <h4 className='d-none d-sm-inline-block'>{list.title}</h4>
            <ListGroup className={s.list}>
            {list.items && list.items.map((i, index) =>
                i.playerID !== -1 ? 
                <PlayerListItem player={i} 
                    rightDetail={<div className={s.wins + (!i.amount ? ` ${s.isZero}` : '')}>{i.amount}</div>}
                    showRole={false} showHcp={false} 
                    key={`top10list-${i.title}-${index}`} />
                : i.playerID === -1 && list.title === 'Captain Records' ? 
                <ListGroup.Item className={s.winningCaptainTie} 
                    key={`captain-record-tie-${index}`}>
                    <div className={s.content}>
                        <div className={s.avatars}>
                            {i.imageUrl.split(',').map((j, _imageIndex) =>
                                <Image src={playerAvatarUrl(j, true)} key={`captain-record-image-${_imageIndex}`} />
                            )}
                        </div>
                        <div className={s.names}>
                            <div className={s.firstName}>{i.firstName}</div>
                            <div className={s.lastName}>{i.lastName}</div>
                        </div>
                        <div className={s.wins + (!i.amount ? ` ${s.isZero}` : '')}>{i.amount}</div>
                    </div>
                </ListGroup.Item>
                : 
                <ListGroup.Item key={`top10list-${i.title}-${index}`}>{i.playerName}</ListGroup.Item>
            )}
            {!list.items || list.items.length === 0 ? <ListGroup.Item>No records located.</ListGroup.Item> : null}
            </ListGroup>
        </div>
    )
}

export default Top10List