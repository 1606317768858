import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav } from 'react-bootstrap'
import { img, commonImageUrl } from '@severed-links/common.severedlinks-constants'
import CloudinaryImage from '../images/components/CloudinaryImage'
import * as s from './Menu.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

const BrandLogoLink = () => (
    <Link to={{ pathname: '/groups' }}>
        <CloudinaryImage className={s.logoImage} publicId={commonImageUrl('severed-links-logo-h50')} />
    </Link>
)

const MainMenu = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const menuItems = [
        location.pathname !== '/groups' ? 
            <Nav.Item className={s.item} key={`main-menu-home`}>
                <Nav.Link key={1} eventKey={1} onClick={() => navigate('/groups')}>
                <FontAwesomeIcon name='home' />
                </Nav.Link>
            </Nav.Item> : null,
    ]
    return (
        <BaseAuthenticatedMenu menuClassName='mainMenu' 
            navBarBrand={<BrandLogoLink />} 
            menuItems={menuItems} />
    )
}

export default MainMenu
