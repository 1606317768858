// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Groups__container___kjdyk{position:relative}.Groups__container___kjdyk .Groups__groupHeading___TGFtC{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.Groups__container___kjdyk .Groups__groupHeading___TGFtC .Groups__title___Ck8Zy{flex:1 1 auto}.Groups__container___kjdyk .Groups__groupHeading___TGFtC .Groups__addNewGroupContainer___zHU0e{flex:0 0 0;margin-left:30px}.Groups__container___kjdyk .Groups__groupHeading___TGFtC .Groups__addNewGroupContainer___zHU0e button{white-space:nowrap}.Groups__container___kjdyk .Groups__roleLabel___JK344{position:absolute;right:2px;top:2px}.Groups__container___kjdyk .Groups__upcomingTripsContainer___ckPJU,.Groups__container___kjdyk .Groups__currentTripsContainer___D_Vst,.Groups__container___kjdyk .Groups__recentTripsContainer___WXMTq{margin:2rem 0px}`, "",{"version":3,"sources":["webpack://./src/routes/Groups/Groups.scss"],"names":[],"mappings":"AAGA,2BACI,iBAAA,CAEA,yDACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,gFACI,aAAA,CAGJ,+FACI,UAAA,CACA,gBAAA,CACA,sGACI,kBAAA,CAKZ,sDACI,iBAAA,CACA,SAAA,CACA,OAAA,CAGJ,sMACI,eAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n@use '../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n    position: relative;\n\n    .groupHeading {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n\n        .title {\n            flex: 1 1 auto;\n        }\n\n        .addNewGroupContainer {\n            flex: 0 0 0;\n            margin-left: 30px;\n            button {\n                white-space: nowrap;\n            }\n        }\n    }\n\n    .roleLabel {\n        position: absolute;\n        right: 2px;\n        top: 2px;\n    }\n\n    .upcomingTripsContainer, .currentTripsContainer, .recentTripsContainer {\n        margin: 2rem 0px;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
export var container = `Groups__container___kjdyk`;
export var groupHeading = `Groups__groupHeading___TGFtC`;
export var title = `Groups__title___Ck8Zy`;
export var addNewGroupContainer = `Groups__addNewGroupContainer___zHU0e`;
export var roleLabel = `Groups__roleLabel___JK344`;
export var upcomingTripsContainer = `Groups__upcomingTripsContainer___ckPJU`;
export var currentTripsContainer = `Groups__currentTripsContainer___D_Vst`;
export var recentTripsContainer = `Groups__recentTripsContainer___WXMTq`;
export default ___CSS_LOADER_EXPORT___;
