import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { setAccountDeletionState } from '@severed-links/common.severedlinks-reducers/accountDeletion'
import * as s from './AccountDeletionStep.scss'

const AccountDeletionStep1 = forwardRef(({ jumpToStep, ...props }, _ref) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const _accountDeletion = useSelector(state => state.accountDeletion)
    const { 
        isFetching, groupsToDelete, groupsDeletionConfirmed, groupsToDeleteCount, 
        groupsToDeleteAdminCount, tripPlayerCount, tripMatchCount, wonLossRecordCount, 
        playerInfoSelections, playerInfoSelection, firstName, lastName, address, 
        city, state, zip, emailAddress, cellPhone, imageUrl, accountDeletionConfirmed, 
    } = _accountDeletion
    const _isValidated = true

    useEffect(() => {
    }, [])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValidated,
    }))


    return (
        <div className={s.container}>
            <h4>We're sorry to see you go!</h4>
            <p>
                This portal will assist you in deleting your Severed Links 
                account and any associated personally-identifying information.  
                Deletion is permanent.
            </p>

        </div>
    )
})

export default AccountDeletionStep1
