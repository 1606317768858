import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Table, ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getPersonalRounds, getPersonalRoundSummary, savePersonalRound, deletePersonalRound } from '@severed-links/common.severedlinks-reducers/player'
import { first } from 'lodash'
import moment from 'moment-timezone'
import { FRIENDLY_DATE_FORMAT, FRIENDLY_SHORT_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import HandicapTrackerAddRound from './HandicapTrackerAddRound'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import Confirm from '@severed-links/common.confirm'
import * as s from './HandicapTracker.scss'
import numeral from 'numeral'

const HandicapTracker = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const personalRounds = useSelector(state => state.player.personalRounds)
    const personalRoundSummary = useSelector(state => state.player.personalRoundSummary)
    const [activeYear, setYear] = useState(0)
    const [showAdd, setShowAdd] = useState(false)
    const [firstLoad, setFirstLoad] = useState(false)
    const filteredRounds = activeYear === 0 ? personalRounds : (personalRounds || []).filter(r => moment(r.scoreDate).year() === activeYear)

    useEffect(() => loadData(), [playerId])

    const closeAddModal = () => setShowAdd(false)

    const loadData = () => {
        dispatch(getPersonalRounds())
        dispatch(getPersonalRoundSummary())
        .then(action => { 
            setYear((first(action.payload || []) || {}).roundYear || 0)
            setFirstLoad(true)
        })
    }

    const deleteRound = personalRoundId => {
        dispatch(deletePersonalRound(personalRoundId))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Delete Personal Round', timeout: 4000 }))
            if (action.payload.messageType === 'success') loadData()
        })
    }

    const saveRound = personalRound => {
        dispatch(savePersonalRound(personalRound))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Save Personal Round', timeout: 4000 }))
            if (action.payload.messageType === 'success') loadData()
        })
    }

    return (
        <div className={s.container}>
            <Row className={s.instructions}>
                <Col xs={12}>
                    <h2>Handicap Tracker</h2>
                    <p>Use this area to enter scores when you are not on a trip to track your personal handicap.  This data will automatically be supplied to the administrators of any trips you attend.</p>
                    <p>If you need a course entered into our database, let us know the course name, city, and state and open a help ticket <Link to='/profile/help-center'>here</Link> (a URL to the scorecard is also helpful!).</p>
                    <p>We currently only support 18-hole scores for handicaps.</p>
                </Col>
            </Row>
            <Row>
                <Col lg={5} md={6} sm={12} xs={12}>
                    <h3>Summary</h3>
                    <Table striped hover responsive={'md'} className={s.summaryTable}>
                        <thead>
                            <tr>
                            <th>Year</th>
                            <th>Count</th>
                            <th>High</th>
                            <th>Low</th>
                            <th>Average</th>
                            <th>Index</th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {personalRoundSummary && personalRoundSummary.map(i =>
                            <tr key={`personal-round-summary-${i._id}-${i.roundYear}`} 
                                className={i.roundYear === activeYear ? s.activeYear : null}
                                style={i.roundYear === 0 ? { fontWeight: 'bold' } : { cursor: 'pointer' }} 
                                onClick={() => setYear(i.roundYear)}>
                                <td>{i.roundYear || 'Total'}</td>
                                <td className={s.right}>{i.rounds}</td>
                                <td className={s.right}>{i.grossMax}</td>
                                <td className={s.right}>{i.grossMin}</td>
                                <td className={s.right}>{numeral(i.grossAvg || 0).format('0.0')}</td>
                                <td className={s.right}>{numeral(i.lastIndex || 0).format('0.0')}</td>
                                <td>{i.roundYear !== 0 && i.roundYear === activeYear ? <FontAwesomeIcon name='chevron-right' /> : null}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Col>
                <Col lg={7} md={6} sm={6} xs={12}>
                    <h3>
                        Personal Rounds
                        <Button onClick={() => setShowAdd(true)}><FontAwesomeIcon name='plus' /></Button>
                    </h3>
                    <ListGroup>
                    {firstLoad && filteredRounds && filteredRounds.map(i =>
                        <ListGroup.Item key={`personal-round-${i.personalRoundID}`} 
                            variant={i.scoreUsedInIndex ? 'info' : ''}
                            className={s.roundItem}>
                            <div className={s.content}>
                                <div className={s.course}>
                                    <h5>{i.courseName} ({moment(i.scoreDate).format('MMM D')})</h5>
                                    <div className={s.roundDetails}>
                                        <div className={s.course}>
                                            <p>{i.tees} tees, {i.yards} yards, slope: {i.slope}, rating: {numeral(i.rating).format('0.0')}</p>
                                            <p>{i.city} {i.state}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.score}>
                                    {i.grossScore}
                                </div>
                            </div>
                            <div className={s.currentIndex + (i.scoreUsedInIndex ? ' ' + s.hcpUsed : '')}>Current index: {numeral(i.currentIndex || 0).format('0.0')}</div>
                            <span className={s.deleteContainer}>
                            <Confirm title={'Delete Player Note'}
                                onConfirm={() => deleteRound(i.personalRoundID)}
                                confirmText='delete round'
                                body={<div><p>Are you sure you want to delete this round?</p></div>}
                                variant='danger' size='sm' buttonClassName={s.deleteButton + (i.scoreUsedInIndex ? ' ' + s.hcpUsed : '')}
                                buttonIcon='times' />
                            </span>
                        </ListGroup.Item>
                    )}
                    {!filteredRounds || filteredRounds.length === 0 ? 
                        <ListGroup.Item>No rounds for this year located.</ListGroup.Item>
                    : null}
                    </ListGroup>
                </Col>
            </Row>
            <HandicapTrackerAddRound show={showAdd} 
                onClose={closeAddModal} 
                onSave={saveRound} />
        </div>
    )
}

export default HandicapTracker