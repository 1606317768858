import React, { useState, useImperativeHandle, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { ListGroup, Button, ButtonGroup, FormLabel, FormGroup, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Faq.scss'
import { getFaq, updateGroupState, updateFaqSortOrder, deleteFaq, saveFaq } from '@severed-links/common.severedlinks-reducers/groups'
import Modal from '@severed-links/common.modal'
import { DragSource, DropTarget, useDrop, useDrag } from 'react-dnd'
import { arrayMoveImmutable } from 'array-move'
import { ITEM_TYPES } from '../Trips/Admin/DragAndDropItemTypes'
import { Editor, EditorState, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'
import TextareaAutosize from 'react-autosize-textarea'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import FaqItem from './FaqItem'

const Faq = ({ isAdmin = false }) => {

    const dispatch = useDispatch()
    const [faqLoaded, setFaqLoaded] = useState(false)
    const [_id, setId] = useState(null)
    const [showEditor, setShowEditor] = useState(false)
    const [question, setQuestion] = useState('')
    const [answer, setAnswer] = useState(EditorState.createEmpty())
    const { group, groupId, isGroupAdmin } = useOutletContext()
    const faq = group.faq || []

    useEffect(() => {
        dispatch(getFaq(groupId))
        .then(action => setFaqLoaded(true))
    }, [])

    const addNewFaq = () => {
        setId(null)
        setQuestion('')
        setAnswer(EditorState.createEmpty())
        setShowEditor(true)
    }

    const doDeleteFaq = _id => {
        dispatch(deleteFaq(groupId, _id))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (action.payload.messageType === 'success') {
                dispatch(getFaq(groupId))
            }
        })
    }

    const editFaq = _id => {
        const q = faq.find(i => i._id === _id)
        setId(_id)
        setQuestion(q.question)
        setAnswer(q.answer ? EditorState.createWithContent(stateFromHTML(q.answer)) : EditorState.createEmpty())
        setShowEditor(true)
    }

    const handleEditorChange = editorState => setAnswer(editorState)

    const handleEditorButton = command => handleEditorChange(RichUtils.toggleInlineStyle(answer, command))

    const doSaveFaq = () => {
        const postData = { _id, question, answer: stateToHTML(answer.getCurrentContent()) }
        dispatch(saveFaq(groupId, postData))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (action.payload.messageType === 'success') {
                dispatch(getFaq(groupId))
                setShowEditor(false)
            }
        })
    }

    const moveFaq = (dragIndex, hoverIndex) => {
        var _faq = arrayMoveImmutable(faq, dragIndex, hoverIndex)
        dispatch(updateGroupState(groupId, { faq: _faq }))
        saveSortOrder(_faq.map(i => `${i._id}`))
    }

    const saveSortOrder = (_ids = []) => {
        if (_ids.length) {
            dispatch(updateFaqSortOrder(groupId, _ids))
            .then(() => dispatch(getFaq(groupId)))
        }
    }

    return (
        <div className={s.container}>
            <div className={s.header + (isAdmin ? ` ${s.isAdmin}` : '')}>
                <h3 className={s.title}>Frequently Asked Questions</h3>
                {isAdmin ? <Button className={s.addNewButton} variant='primary' onClick={() => addNewFaq()}><FontAwesomeIcon name='plus' /><span className={s.addNewLabel}>add new</span></Button> : null}
            </div>
            <ListGroup className={s.list}>
            {faq && faq.map((i, index) =>
                <FaqItem key={`faq-${i._id}`} 
                    i={i} _id={i._id} index={index}
                    isAdmin={isAdmin} 
                    onEdit={editFaq} 
                    onDelete={doDeleteFaq} 
                    onMove={moveFaq} />
            )}
            {!faq || !faq.length ?
            <ListGroup.Item>No frequently-asked questions located.</ListGroup.Item>
            : null}
            </ListGroup>

            <Modal show={showEditor}
                heading={`Edit FAQ`}
                actionButtonText='save question'
                actionButtonIcon='check'
                actionButtonOnClick={doSaveFaq}
                enforceFocus={false} showFooter
                onClose={() => setShowEditor(false)}>
                <div>
                    <FormGroup>
                        <FormLabel>Question</FormLabel>
                        <TextareaAutosize className='form-control' 
                            placeholder={'[Question text...]'} value={question} 
                            onChange={e => setQuestion(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Answer</FormLabel>
                        <div className={s.faqEditorContainer} style={{ marginBottom: '20px' }}>
                            <div className={s.editorTools}>
                            <ButtonGroup tabIndex={-1}>
                                <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('BOLD')}><FontAwesomeIcon name='bold' /></Button>
                                <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('UNDERLINE')}><FontAwesomeIcon name='underline' /></Button>
                                <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('ITALIC')}><FontAwesomeIcon name='italic' /></Button>
                            </ButtonGroup>
                            </div>
                            <Editor className={s.editor} editorState={answer} 
                                placeholder='[Enter your answer here...]' 
                                blockStyleFn={contentBlock => s.block}
                                onChange={e => handleEditorChange(e)} />
                        </div>
                    </FormGroup>
                </div>
            </Modal>
        </div>
    )
}

export default Faq

  
