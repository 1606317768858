import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './BuyAddOns.scss'
import PricingPlanListItem from '../../PricingPlans/PricingPlanListItem'
import RsvpTokenPurchaseListItem from './RsvpTokenPurchaseListItem'

const BuyAddOns = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)

    useEffect(() => {
    }, [])

    return (
        <div className={s.container}>
            <h4>Trip Add-ons</h4>
            <ListGroup className={s.list}>
                <RsvpTokenPurchaseListItem group={group} trip={trip} />
                <PricingPlanListItem group={group} trip={trip} />
            </ListGroup>
        </div>
    )
}

export default BuyAddOns
