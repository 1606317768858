import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { checkRsvpStatus, updateRsvp, getPlayerLists } from '@severed-links/common.severedlinks-reducers/groups'
import * as s from './TripRsvpPanel.scss'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import TripRsvpLabel from './TripRsvpLabel'
import Button from '@severed-links/common.button'
import Modal from '@severed-links/common.modal'
import CreditCardPaymentForm from '../CreditCard/CreditCardPaymentForm'
import SquareCreditCardInitializer from '../CreditCard/SquareCreditCardInitializer'
import numeral from 'numeral'
import { CURRENCY_FORMAT } from '@severed-links/common.severedlinks-constants'
import { keysIn } from 'lodash'
import pluralize from 'pluralize'

const TripRsvpPanel = ({ group = {}, trip = {}, show = false }) => {

    if (!group._id || !trip._id) return null

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const rsvpStatus = (trip || {}).rsvpStatus || {}
    const showBoth = rsvpStatus.showNoButton && rsvpStatus.showYesButton

    const rsvpTokensPurchased = (trip || {}).rsvpTokensPurchased || 0
    const rsvpTokensUsed = (trip || {}).rsvpTokensUsed || 0
    const rsvpTokensUsedBy = (trip || {}).rsvpTokensUsedBy || []
    const hasAlreadyRsvpWithToken = rsvpTokensUsedBy.some(i => i === playerId)
    const rsvpTokensAvailable = rsvpTokensPurchased - rsvpTokensUsed
    const canPayWithRsvpToken = (rsvpTokensAvailable > 0) || hasAlreadyRsvpWithToken

    useEffect(() => {
        dispatch(checkRsvpStatus(group._id, trip._id))
    }, [])

    const doUpdateRsvp = (rsvp, useToken = false) => {
        const request = { tripId: trip._id, playerId, rsvp, useToken }
        setIsSaving(true)
        dispatch(updateRsvp(group._id, trip._id, request))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
            dispatch(getPlayerLists(group._id, trip._id))
            dispatch(checkRsvpStatus(group._id, trip._id))
            setIsSaving(false)
        })
    }

    const handleYesButtonClick = () => {
        if (trip.registrationFee && !rsvpStatus.isPaid) {
            setShowPaymentModal(true)
        } else {
            doUpdateRsvp(rsvpStatus.yesResponse)
        }
    }

    const rsvpWithToken = () => {
        doUpdateRsvp(rsvpStatus.yesResponse, true)
    }

    const onSuccessfulPayment = () => {
        dispatch(checkRsvpStatus(group._id, trip._id))
        doUpdateRsvp(rsvpStatus.yesResponse)
        setTimeout(() => showPaymentModal(false), 1000)
    }

    if (!show || !keysIn(rsvpStatus).length) return null
    return (
        <Card className={s.container}>
            <Card.Header className={s.header + ' ' + s[rsvpStatus.variant]}>
                <div className={s.icon}><FontAwesomeIcon name={rsvpStatus.icon} /></div>
                {rsvpStatus.title ? <div className={s.title}>{rsvpStatus.title}</div> : null}
                <div className={s.label}><TripRsvpLabel trip={trip} /></div>
            </Card.Header>
            <Card.Body className={s.content}>
                <p>{rsvpStatus.intro}</p>
                {showBoth && trip.registrationFee && !canPayWithRsvpToken ? <p><i>You will be charged a non-refundable RSVP fee of {numeral(trip.registrationFee).format('($0.00)')} if you click the "I will attend" button below.</i></p> : null}
            </Card.Body>
            {rsvpStatus.showYesButton || rsvpStatus.showNoButton ?
            <Card.Footer className={s.footer}>
                {rsvpStatus.showNoButton ? <Button disabled={isSaving} className={s.rsvpButton} variant='danger' onClick={() => doUpdateRsvp(rsvpStatus.noResponse)} icon='times' title={rsvpStatus.noButtonText} /> : null}
                {rsvpStatus.showYesButton ? <Button disabled={isSaving} className={s.rsvpButton} variant='success' onClick={() => handleYesButtonClick()} icon='check' iconSpin={isSaving} title={`${rsvpStatus.yesButtonText}${trip.registrationFee && !rsvpStatus.isPaid && !canPayWithRsvpToken ? ` (${numeral(trip.registrationFee).format('($0.00)')})` : ''}`} /> : null}
            </Card.Footer>
            : null}
            {trip.registrationFee ? 
            <>
            <SquareCreditCardInitializer />
            <Modal show={showPaymentModal} showFooter={false}
                heading={`Pay Trip RSVP Fee`}
                onClose={() => setShowPaymentModal(false)}>
                <div className={s.rsvpPaymentFormContainer}>
                    <p>The admins of your trip have elected to use this site to manage this trip. 
                        The registration fee for use of the site is {numeral(trip.registrationFee).format(CURRENCY_FORMAT)}. 
                    </p>
                    {!canPayWithRsvpToken ?
                    <>
                    <p>
                        Please enter your credit card information below to register.
                    </p>
                    <CreditCardPaymentForm amount={trip.registrationFee}
                        chargeType={rsvpStatus.registrationFeePaymentKey} 
                        groupId={group._id} tripId={trip._id}
                        onSuccess={onSuccessfulPayment} />
                    </>
                    :
                    <>
                        <p>Your trip administrators have pre-purchased {pluralize('registration fee', rsvpTokensPurchased, true)}{rsvpTokensAvailable ? `, of which ${pluralize('is', rsvpTokensAvailable, true)} still available.` : ``}</p>
                        <Button className={s.useTokenButton} variant='primary' onClick={() => rsvpWithToken()} icon='coins' title={`use token and register for free!`} />
                    </>}
                </div>
            </Modal>            
            </>
            : null}
        </Card>
    )
}

export default TripRsvpPanel