import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, FormControl, FormLabel, InputGroup, FormGroup, Popover, Overlay } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import { requestCellPhoneVerification, cancelCellPhoneVerification, confirmCellPhoneVerificationCode } from '@severed-links/common.severedlinks-reducers/profile'
import { getMe } from '@severed-links/common.severedlinks-reducers/account'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './ConfirmCellPhone.scss'
import ReactCodeInput from 'react-code-input'

const NotVerifiedPopover = ({ showPopover = false, target = null }) => {
    const isSmallViewport = window.matchMedia('(max-width: 767px)')
    const placement = isSmallViewport.matches ? 'top' : 'left'
    return (
        <Overlay placement={placement} show={showPopover} target={target}>
            <Popover title='Why Verify?' id='popover-cell-phone-not-verified-why-do-this'>
                <Popover.Header>Cell Phone Verification</Popover.Header>
                <Popover.Body>
                    <p>Verifying your cellphone number grants us your permission for our app and your trip administrators to send you text messages and lets us know that your number is valid.</p>
                    <p>You can cancel your verification at any time.</p>
                    <p>All standard text messaging rates by your provider will apply.</p>
                </Popover.Body>
            </Popover>
        </Overlay>
    )
}

const VerificationModal = ({ show = false, code = '', onClose, onChange = () => void(0), handleKeyPress = () => void(0), onConfirmVerification = () => void(0) }) => (
    <Modal show={show} showFooter={false} size='sm' 
        onClose={() => onClose()}>
        <FormGroup className={s.formGroup}>
            <FormLabel>Enter the code sent to your cell phone:</FormLabel>
            <div className={s.codeContainer}>
                <ReactCodeInput type='text' 
                    pattern='^[0-9]*$' fields={5} 
                    autoFocus value={code}
                    onKeyPress={e => handleKeyPress(e)}
                    onChange={_code => onChange(_code)} />
            </div>
        </FormGroup>
        <FormGroup className={s.formGroup + ' ' + s.saveButtonContainer}>
            <Button disabled={!code || code.length !== 5} 
                onClick={() => onConfirmVerification()}><FontAwesomeIcon name='check' /> verify</Button>
        </FormGroup>
    </Modal>
)

const ConfirmCellPhone = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const cellPhone = useSelector(state => state.account.cellPhone)
    const cellPhoneConfirmed = useSelector(state => state.account.cellPhoneConfirmed)
    const [show, setShow] = useState(false)
    const [showPopover, setShowPopover] = useState(false)
    const [code, setCode] = useState('')
    const _target = useRef(null)

    const requestVerification = () => {
        dispatch(requestCellPhoneVerification())
        setShow(true)
        setShowPopover(false)
        setCode('')
    }

    const confirmVerification = () => {
        dispatch(confirmCellPhoneVerificationCode(code))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Cell Phone Confirmation', timeout: 4000 }))
            dispatch(getMe())
            if (action.payload.messageType === 'success') {
                setShow(false)
                setShowPopover(false)
                setCode('')
            }
        })
    }

    const handleKeyPress = e => {
        if (e.charCode === 13) {
            e.preventDefault()
            e.stopPropagation()
            confirmVerification()
        }
    }

    const cancelVerification = () => {
        dispatch(cancelCellPhoneVerification())
        .then(() => dispatch(getMe()))
    }

    return (
        <div>
            <Alert className={s.container} variant={cellPhone && cellPhoneConfirmed ? 'success' : 'danger' }>
            {cellPhone ? 
                cellPhoneConfirmed ?
                <div className={s.msg}>
                    <p>Your cell phone number is verified.</p>
                    <div className={s.button}>
                        <Button variant='success' size='sm'
                            onClick={() => cancelVerification()}>
                            <FontAwesomeIcon name='times' /> <span className='d-none d-sm-inline'>cancel</span>
                        </Button>
                    </div>
                </div>
                :
                <div className={s.msg}>
                    <p>Your cell phone number is not verified.  </p>
                    <Alert.Link className={s.whyVerify} ref={_target} 
                        onClick={() => setShowPopover(!showPopover)}>
                        Why verify?
                    </Alert.Link>
                    <div className={s.button}>
                        <Button variant='success' size='sm'
                            onClick={() => requestVerification()}>
                            <FontAwesomeIcon name='check' /> <span className='d-none d-sm-inline'>verify</span>
                        </Button>
                    </div>
                </div>
            : 
                <div className={s.msg}>
                    <p>Please enter and save your cell phone number so we can verify it.</p>
                </div>
            }
            </Alert>
            <VerificationModal show={show} code={code} 
                onClose={() => { setShow(false); setCode('') }}
                onChange={_code => setCode(_code)}
                handleKeyPress={e => handleKeyPress(e)}
                onConfirmVerification={() => confirmVerification()} />
            <NotVerifiedPopover showPopover={showPopover} target={_target} />
        </div>
    )
}

export default ConfirmCellPhone