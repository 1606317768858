import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthenticatedSocket } from './useAuthenticatedSocket'
import { useLocation, useNavigate } from 'react-router-dom'
import { setAccountState } from '@severed-links/common.severedlinks-reducers/account'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { setOnlineUsers, setPlayerLists, updateTripCaptains, updateTripSettings,
    updateTripRoundUpdateTeeTimeInterval, updateTripRoundUpdateSkinType, updateTripRoundUpdateHolesPlayed, updateTripRoundSetRoundDay,
    updateTripRoundSetGolfCourse, updateTripRoundSetMatchPlayFormat,
    updateTripRoundBoolean, updateTripRoundSetFirstTeeTime, updateTrips, updateGroupSettings,
    updateTrip, updateTripPhotos, updateTripPhoto, updateLoginInfo, updateMockAwards, updateMockAwardsForAdmin, updateAdminMvpAwardVoting,
    updateSkinPlayers, updateRoomAssignments, addTripComment, removeTripComment,
    launchOnlineUsersCommentWindow, reloadComments, updateAdminMatches, updateMatchesScoreboard,
    updateTripEvents, updateFaq, updateSkinsAndProxiesScoreboard,
    getAllGroupRoles, getMyGroups, updatePlayers, updatePendingInvites, updateGroupState,
    setPlayerRsvpStatus, updateHandicapChecker, updatePlayerEntryFeePaymentItem, 
    updateAdminMatchLoading, updatePlayerPayoutPaidStatus, updateMvpAwards, 
    removeGroupFromList, updateTripPlayerSettings, updateAddressBook, updateTripAdminNotes, 
} from '@severed-links/common.severedlinks-reducers/groups'
import { updateSavedHelpTicket, updateHelpTicketComments, setForceTicketReload } from '@severed-links/common.severedlinks-reducers/help'
import { reloadScorecardSetter } from '@severed-links/common.severedlinks-reducers/scorecard'
import { startsWith } from 'lodash'
import { clearSocketCommand } from '@severed-links/common.severedlinks-reducers/socket'
import { updateBullJob, getDeviceList } from '@severed-links/common.severedlinks-reducers/globalAdmin'

const SocketClient = () => {

    const isDevelopment = __DEV__
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { socket, connected, error } = useAuthenticatedSocket()
    const { pageNumber: globalAdminDevicesPageNumber, pageLength: globalAdminDevicesPageLength } = useSelector(state => state.globalAdmin.devices)
    const command = useSelector(state => state.socket.command)
    const commandData = useSelector(state => state.socket.commandData)

    useEffect(() => () => disconnect(), [])
    useEffect(() => onCommandReceived(), [command, connected])
    useEffect(() => {

        socket
        .on('pushNotification', _response => dispatch(createNotification(_response)))
        .on('setAccountState', _response => dispatch(setAccountState(_response)))
        .on('listOnlineGroupUsers', _response => dispatch(setOnlineUsers(_response)))
        .on('setPlayerLists', _response => dispatch(setPlayerLists(_response)))
        .on('updateTripCaptains', _response => dispatch(updateTripCaptains(_response)))
        .on('updateLoginInfo', _response => dispatch(updateLoginInfo(_response)))
        .on('updateTripSettings', _response => dispatch(updateTripSettings(_response)))
        .on('updateGroupSettings', _response => dispatch(updateGroupSettings(_response)))
        .on('updateTripRoundUpdateTeeTimeInterval', _response => dispatch(updateTripRoundUpdateTeeTimeInterval(_response)))
        .on('updateTripRoundUpdateSkinType', _response => dispatch(updateTripRoundUpdateSkinType(_response)))
        .on('updateTripRoundUpdateHolesPlayed', _response => dispatch(updateTripRoundUpdateHolesPlayed(_response)))
        .on('updateTripRoundSetRoundDay', _response => dispatch(updateTripRoundSetRoundDay(_response)))
        .on('updateTripRoundSetGolfCourse', _response => dispatch(updateTripRoundSetGolfCourse(_response)))
        .on('updateTripRoundSetMatchPlayFormat', _response => dispatch(updateTripRoundSetMatchPlayFormat(_response)))
        .on('updateTripRoundBoolean', _response => dispatch(updateTripRoundBoolean(_response)))
        .on('updateTripRoundSetFirstTeeTime', _response => dispatch(updateTripRoundSetFirstTeeTime(_response)))
        .on('updateTrips', _response => dispatch(updateTrips(_response)))
        .on('updateTrip', _response => dispatch(updateTrip(_response)))
        .on('updateTripPhotos', _response => dispatch(updateTripPhotos(_response)))
        .on('updateTripPhoto', _response => dispatch(updateTripPhoto(_response)))
        .on('updateMockAwards', _response => dispatch(updateMockAwards(_response)))
        .on('updateMockAwardsForAdmin', _response => dispatch(updateMockAwardsForAdmin(_response)))
        .on('updateAdminMvpAwardVoting', _response => dispatch(updateAdminMvpAwardVoting(_response)))
        .on('updateSkinPlayers', _response => dispatch(updateSkinPlayers(_response)))
        .on('updateRoomAssignments', _response => dispatch(updateRoomAssignments(_response)))
        .on('addTripComment', _response => dispatch(addTripComment(_response)))
        .on('removeTripComment', _response => dispatch(removeTripComment(_response)))
        .on('launchOnlineUsersCommentWindow', _response => dispatch(launchOnlineUsersCommentWindow(_response)))
        .on('reloadComments', _response => dispatch(reloadComments(_response)))
        .on('updateAdminMatches', _response => dispatch(updateAdminMatches(_response)))
        .on('updateMatchesScoreboard', _response => dispatch(updateMatchesScoreboard(_response)))
        .on('updateTripEvents', _response => dispatch(updateTripEvents(_response)))
        .on('updateFaq', _response => dispatch(updateFaq(_response)))
        .on('updateSkinsAndProxiesScoreboard', _response => dispatch(updateSkinsAndProxiesScoreboard(_response)))
        .on('removeGroupFromList', _response => {
            dispatch(removeGroupFromList(_response))
            const _checkPath = `/group/${_response}/`
            const _needsReRoute = startsWith(location.pathname.toLowerCase(), _checkPath)
            if (_needsReRoute) {
                navigate('/groups')
            }
        })
        .on('reloadUserGroups', _response => dispatch(getAllGroupRoles()).then(() => dispatch(getMyGroups())))
        .on('updatePlayers', _response => dispatch(updatePlayers(_response)))
        .on('updatePendingInvites', _response => dispatch(updatePendingInvites(_response)))
        .on('updateSavedHelpTicket', _response => dispatch(updateSavedHelpTicket(_response)))
        .on('updateHelpTicketComments', _response => dispatch(updateHelpTicketComments(_response)))
        .on('forceHelpTicketReload', _response => dispatch(setForceTicketReload(true)))
        .on('updateGroupState', _response => dispatch(updateGroupState(_response.groupId, _response)))
        .on('setPlayerRsvpStatus', _response => dispatch(setPlayerRsvpStatus(_response)))
        .on('updateHandicapChecker', _response => dispatch(updateHandicapChecker(_response)))
        .on('updatePlayerEntryFeePaymentItem', _response => dispatch(updatePlayerEntryFeePaymentItem(_response)))
        .on('updatePlayerPayoutPaidStatus', _response => dispatch(updatePlayerPayoutPaidStatus(_response)))
        .on('updateAdminMatchLoading', _response => dispatch(updateAdminMatchLoading(_response)))
        .on('reloadScorecard', _response => dispatch(reloadScorecardSetter(_response)))
        .on('updateMvpAwards', _response => dispatch(updateMvpAwards(_response)))
        .on('updateTripPlayerSettings', _response => dispatch(updateTripPlayerSettings(_response)))
        .on('onPaymentReset', _response => console.log(_response))
        .on('onPaymentSuccess', _response => console.log(_response))
        .on('updateAddressBook', _response => dispatch(updateAddressBook(_response)))
        .on('updateTripAdminNotes', _response => dispatch(updateTripAdminNotes(_response)))
        .on('updateBullJob', _response => dispatch(updateBullJob(_response)))
        .on('updateGlobalAdminDeviceList', _response => dispatch(getDeviceList(globalAdminDevicesPageNumber, globalAdminDevicesPageLength)))
        .on('logger', _response => console.log(_response))

        return () => socket.removeAllListeners()
    
    }, [socket])

    const onCommandReceived = () => {
        if (command && connected) {
            dispatch(clearSocketCommand())
            socket.emit(command, commandData)
        }
    }

    const disconnect = () => {
        try {
            if (socket) {
                console.log('disconnecting socket...')
                socket.emit('manual-disconnect')
                socket.close()
                clearInterval()
            }
        } catch (e) {}
    }
    return null
}

export default SocketClient