import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, FormGroup, FormLabel, FormControl, InputGroup, Alert, ProgressBar } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './SignUp.scss'
import scriptLoader from 'react-async-script-loader'
import PasswordFormControl from './PasswordFormControl'
import { resetForgotPassword, setUserInfo,
    checkEmail, checkCellPhone, requestResetCode, verifyResetCode,
    requestPasswordChange } from '@severed-links/common.severedlinks-reducers/forgotPassword'

const ForgotPasswordEnterNew = forwardRef(({ jumpToStep, ...props }, _ref) => {

    const dispatch = useDispatch()
    const fp = useSelector(state => state.forgotPassword)
    const [password, setPassword] = useState('')

    useEffect(() => {
        const { password, verificationCode, enteredTokenIsValid, enteredToken } = fp
        setPassword(password)
        if (enteredTokenIsValid && !verificationCode) {
            var postData = { tokenType: 'password_reset', token: enteredToken }
            dispatch(verifyResetCode(postData))
        }
    }, [])

    const handleChange = data => {
        dispatch(setUserInfo(data))
    }

    useImperativeHandle(_ref, () => ({
        isValidated() { return fp.enteredTokenIsValid && fp.verificationCode && fp.passwordIsValid }
    }))

    return (
        <div>
            <Row>
                <Col xs={12}>
                    {!fp.tokenRequested && !fp.tokenSent ? 
                    <Alert variant="info" style={{ marginTop: "40px" }}>Requesting reset token...</Alert>
                    : null}
                    {fp.tokenRequested && fp.tokenError ?
                    <Alert variant="danger" style={{ marginTop: "40px" }}>{fp.tokenErrorMessage}</Alert>
                    : null}
                    {fp.verificationCodeError ?
                    <Alert variant="danger" style={{ marginTop: "40px" }}>{fp.verificationCodeErrorMessage}</Alert>
                    : null}
                    {fp.verificationCode && !fp.verificationCodeError ?
                    <Row>
                        <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3}}>
                            <PasswordFormControl onChange={handleChange} size='lg'
                                labelText='Enter your new password' />
                        </Col>
                    </Row>
                    : null}
                </Col>
            </Row>
        </div>
    )
})

export default (scriptLoader([
    'https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js',
])(ForgotPasswordEnterNew))
