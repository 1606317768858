import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, FormLabel, Button, ListGroup, InputGroup, Table } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import { getNearbyCourses } from '@severed-links/common.severedlinks-reducers/groups'
import { golfCourseNameSearch, getGolfCourseById } from '@severed-links/common.severedlinks-reducers/player'
import * as searcherCss from '../../GlobalAdmin/GlobalAdminGolfCourseSearcher.scss'
import { AsyncTypeahead, Menu, MenuItem  } from 'react-bootstrap-typeahead'
import { first, sumBy } from 'lodash'
import * as s from './TripRoundGolfCourseSelector.scss'
import numeral from 'numeral'

const TripRoundGolfCourseSelector = ({ group = {}, trip = {}, roundNumber = 0, onClose, onSelect, show = false }) => {

    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [nearby, setNearby] = useState([])
    const [selectedCourse, setSelectedCourse] = useState(null)
    const locationId = ((trip || {}).location || {})._id || null
    const _typeahead = useRef(null)

    useEffect(() => loadNearbyCourses(), [show])

    const loadNearbyCourses = () => {
        if (locationId && show)
        {
            dispatch(getNearbyCourses(group._id, trip._id, locationId, 20, 20))
            .then(action => {
                setNearby(action.payload)
                setSelectedCourse(null)
            })
        }        
    }

    const handleLookupChange = e => {
        if (e && e.length > 0) {
            handleGolfCourseSelect(first(e)._id)
        }
    }

    const handleGolfCourseSelect = _id => {
        dispatch(getGolfCourseById(_id))
        .then(action => setSelectedCourse(action.payload))
    }

    const handleCancelGolfCourseSelect = () => setSelectedCourse(null)

    const scorecardHoleRow = () => {
        const holes = first(selectedCourse.tees).holes.map(h => h.hole)
        return (
            <tr>
                <th>Hole</th>
                {holes && holes.filter(h => h >= 1 && h <= 9).map(h => <th key={`hole-${h}`}>{h}</th>)}
                <th>Out</th>
                {holes && holes.filter(h => h >= 10 && h <= 18).map(h => <th key={`hole-${h}`}>{h}</th>)}
                <th>In</th>
                <th>Total</th>
            </tr>
        )    
    }

    const teeBoxRows = tee => {
        const style = { color: tee.teeBoxColors.fontColor, backgroundColor: tee.teeBoxColors.backgroundColor }
        return [
            <tr key={`tee-${tee._id}-tee-header`}>
                <td className={s.hole + ' ' + s.bold + ' ' + s.header} colSpan={tee.holes.length + 4} style={{ ...style }}>{tee.tees} tees (Slope: {tee.slope} / Rating: {numeral(tee.rating).format('0.0')})</td>
            </tr>,
            <tr key={`tee-${tee._id}-par`}>
                <td className={s.hole + ' ' + s.bold} style={style}>Par</td>
                {tee.holes && tee.holes.filter(h => h.hole >= 1 && h.hole <= 9).map(h => <td className={s.hole} style={style} key={`tee-${tee._id}-par-hole-${h.hole}`}>{h.par}</td>)}
                <td className={s.hole + ' ' + s.bold} style={style}>{sumBy(tee.holes.filter(h => h.hole >= 1 && h.hole <= 9), 'par')}</td>
                {tee.holes && tee.holes.filter(h => h.hole >= 10 && h.hole <= 18).map(h => <td className={s.hole} style={style} key={`tee-${tee._id}-par-hole-${h.hole}`}>{h.par}</td>)}
                <td className={s.hole + ' ' + s.bold} style={style}>{sumBy(tee.holes.filter(h => h.hole >= 10 && h.hole <= 18), 'par')}</td>
                <td className={s.hole + ' ' + s.bold} style={style}>{sumBy(tee.holes, 'par')}</td>
            </tr>,
            <tr key={`tee-${tee._id}-yds`}>
                <td className={s.hole + ' ' + s.bold} style={style}>Yards</td>
                {tee.holes && tee.holes.filter(h => h.hole >= 1 && h.hole <= 9).map(h => <td className={s.hole} style={style} key={`tee-${tee._id}-yards-hole-${h.hole}`}>{h.yards}</td>)}
                <td className={s.hole + ' ' + s.bold} style={style}>{sumBy(tee.holes.filter(h => h.hole >= 1 && h.hole <= 9), 'yards')}</td>
                {tee.holes && tee.holes.filter(h => h.hole >= 10 && h.hole <= 18).map(h => <td className={s.hole} style={style} key={`tee-${tee._id}-yards-hole-${h.hole}`}>{h.yards}</td>)}
                <td className={s.hole + ' ' + s.bold} style={style}>{sumBy(tee.holes.filter(h => h.hole >= 10 && h.hole <= 18), 'yards')}</td>
                <td className={s.hole + ' ' + s.bold} style={style}>{sumBy(tee.holes, 'yards')}</td>
            </tr>,
            <tr key={`tee-${tee._id}-hcp`}>
                <td className={s.hole + ' ' + s.bold} style={style}>Hcp</td>
                {tee.holes && tee.holes.filter(h => h.hole >= 1 && h.hole <= 9).map(h => <td className={s.hole} style={style} key={`tee-${tee._id}-hcp-hole-${h.hole}`}>{h.hcp}</td>)}
                <td className={s.hole + ' ' + s.bold} style={style}></td>
                {tee.holes && tee.holes.filter(h => h.hole >= 10 && h.hole <= 18).map(h => <td className={s.hole} style={style} key={`tee-${tee._id}-hcp-hole-${h.hole}`}>{h.hcp}</td>)}
                <td className={s.hole + ' ' + s.bold} style={style}></td>
                <td className={s.hole + ' ' + s.bold} style={style}></td>
            </tr>,
            <tr className={s.spacer}><td colSpan={tee.holes.length + 4}>&nbsp;</td></tr>
        ]
    }

    const teeBoxSelectorButtons = () => {
        return selectedCourse.tees && selectedCourse.tees.map(i =>
            <Button className={s.teeBoxButton} 
                onClick={() => selectTeeBox(i._id)}
                style={{ color: i.teeBoxColors.fontColor, backgroundColor: i.teeBoxColors.backgroundColor }}>
                <b style={{ fontSize: '120%' }}>{i.tees} <span className={s.hideXs + ' ' + s.hideSm}> tees </span></b><br/>
                <span className={s.hideXs}><span className={s.hideSm}>Par: {i.par}</span> {i.yards} yards</span><br/>
                <span className={s.hideXs + ' ' + s.hideSm}>Slope: {i.slope} Rating: {numeral(i.rating).format('0.0')}</span>
            </Button>
        )
    }

    const selectTeeBox = _id => onSelect(_id)

    const runQuery = useCallback(query => {
        setLoading(true)
        dispatch(golfCourseNameSearch(query))
        .then(action => {
            setOptions(action.payload)
            setLoading(false)
        })
    }, [])

    return (
        <div>
            <Modal show={show} size={selectedCourse ? 'lg' : undefined}
                heading={`Select Golf Course`}
                onClose={() => onClose()} className={s.container}>
                {!selectedCourse ? 
                <div style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <div className={searcherCss.container + ' ' + searcherCss.full}>
                        <FormGroup className={searcherCss.formGroup}>
                            <FormLabel>Look up golf course by name{trip.location && trip.location._id ? '...' : ''}</FormLabel>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon name='search' /></InputGroup.Text>
                                <AsyncTypeahead ref={_typeahead}
                                    id={`trip-${trip._id}-round-${roundNumber}-admin-golf-course-selector-modal-body-typeahead`}
                                    onSearch={runQuery}
                                    isLoading={isLoading}
                                    multiple={false} autoFocus
                                    labelKey={o => `${o.courseName} (${o.city} ${o.state})`}
                                    renderMenu={(results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => (
                                        <Menu {...menuProps}>
                                            {results && results.map((o, index) => (
                                            <MenuItem option={o} position={index} key={`typeahead-course-search-item-${o._id}`}>
                                                {o.courseName} ({o.city} {o.state})
                                            </MenuItem >
                                            ))}
                                        </Menu>
                                    )} 
                                    inputProps={{}}
                                    placeholder={'[Look up course by name...]'}
                                    options={options}
                                    onChange={e => handleLookupChange(e)} />
                            </InputGroup>
                        </FormGroup>
                    </div>
                    {trip.location && trip.location._id ?
                    <FormGroup style={{ marginTop: '20px' }}>
                        <FormLabel>...or find courses near {trip.location.locName}</FormLabel>
                        <ListGroup>
                        {nearby && nearby.map(i =>
                            <ListGroup.Item action onClick={() => handleGolfCourseSelect(i._id)}
                                key={`trip-${trip._id}-round-${roundNumber}-golf-course-nearby-${i._id}`}>
                                <p style={{ marginBottom: '0px' }}>
                                    <b>{i.courseName}</b>
                                    <br />
                                    {i.city}, {i.state} <span style={{ fontStyle: 'italics' }}>{numeral(Math.round(i.distance * 10) / 10).format('0.0')} mi</span>
                                </p>
                            </ListGroup.Item>
                        )}
                        </ListGroup>
                    </FormGroup>
                    : null}
                </div>
                :
                <div className={s.teeboxSelectorContainer}>
                    <h4>Selected course: {selectedCourse.courseName}</h4>
                    <p>
                        {selectedCourse.address}<br />
                        {selectedCourse.city}, {selectedCourse.state} {selectedCourse.zip}<br />
                        {selectedCourse.phone}
                    </p>

                    <FormGroup>
                        {teeBoxSelectorButtons()}
                        <Button variant='light' onClick={() => handleCancelGolfCourseSelect()}><FontAwesomeIcon name='times' /> cancel</Button>
                    </FormGroup>

                    <div className={s.scoreboardTableContainer}>
                        <Table striped responsive className={s.scoreboardTable}>
                            <thead>
                                {scorecardHoleRow()}
                            </thead>
                            <tbody>
                            {selectedCourse.tees && selectedCourse.tees.map(tee => teeBoxRows(tee))}
                            </tbody>
                        </Table>
                    </div>
                </div>
                }
            </Modal>
        </div>
    )
}

export default TripRoundGolfCourseSelector