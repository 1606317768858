// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripRoundNotesAdmin__container___WrPJe .TripRoundNotesAdmin__heading___Dm10j{margin-bottom:1rem;display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.TripRoundNotesAdmin__container___WrPJe .TripRoundNotesAdmin__heading___Dm10j .TripRoundNotesAdmin__title___oJXgd{margin-bottom:0rem;flex:1 1 auto}.TripRoundNotesAdmin__container___WrPJe .TripRoundNotesAdmin__heading___Dm10j .TripRoundNotesAdmin__controls___zONKr{white-space:nowrap;margin-left:1rem;flex:0 0 0}.TripRoundNotesAdmin__container___WrPJe .TripRoundNotesAdmin__list___EZHyL{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Admin/TripRoundNotesAdmin.scss"],"names":[],"mappings":"AAII,8EACI,kBAAA,CACA,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,kHACI,kBAAA,CACA,aAAA,CAGJ,qHACI,kBAAA,CACA,gBAAA,CACA,UAAA,CAIR,2EACI,kBAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n\n.container {\n\n    .heading {\n        margin-bottom: 1rem;\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n\n        .title {\n            margin-bottom: 0rem;\n            flex: 1 1 auto;\n        }\n\n        .controls {\n            white-space: nowrap;\n            margin-left: 1rem;\n            flex: 0 0 0;\n        }\n    }\n\n    .list {\n        margin-bottom: 1rem;\n\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripRoundNotesAdmin__container___WrPJe`;
export var heading = `TripRoundNotesAdmin__heading___Dm10j`;
export var title = `TripRoundNotesAdmin__title___oJXgd`;
export var controls = `TripRoundNotesAdmin__controls___zONKr`;
export var list = `TripRoundNotesAdmin__list___EZHyL`;
export default ___CSS_LOADER_EXPORT___;
