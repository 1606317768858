import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { } from 'react-router'
import { Card, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripLodging.scss'
import phoneFormatter from 'phone-formatter'
import MyRoomAssignment from './MyRoomAssignment'
import DrivingDirectionsModal from './DrivingDirectionsModal'
import * as ddm from './DrivingDirectionsModal.scss'

const TripLodging = ({ 
    groupId = null, tripId = null, tripLocation = {}, showRoomAssignmentModal = false,
    roomAssignments = [], showMyRoom = true, isAdmin = false
}) => {


    const [showDrivingDirections, setShowDrivingDirections] = useState(false)
    const playerId = useSelector(state => state.account._id)

    const onCloseDrivingDirections = () => setShowDrivingDirections(false)

    if (!groupId || !tripId) return null
    return (
        <Card className={s.container}>
            <Card.Header className={s.header}>
                <div className={s.icon}><FontAwesomeIcon name='bed' /></div>
                <div className={s.title}>Accomodations</div>
            </Card.Header>
            <Card.Body>
                <div>
                    {tripLocation && tripLocation._id ? 
                    <div>
                        {tripLocation.locName} {tripLocation.geocodedAddress ? <Button variant='link' className={ddm.link} onClick={() => setShowDrivingDirections(true)}>directions</Button> : null}<br />
                        {tripLocation.locAddress}<br />
                        {tripLocation.locCity}, {tripLocation.locState} {tripLocation.locZip}<br />
                        {phoneFormatter.format(tripLocation.locPhone, '(NNN) NNN-NNNN')}
                        {showMyRoom ? <br /> : null}
                        {showMyRoom ? <br /> : null}
                        {showMyRoom ? <MyRoomAssignment roomAssignments={roomAssignments} showRoomAssignmentModal={showRoomAssignmentModal} groupId={groupId} tripId={tripId} /> : null}
                    </div> 
                    : 
                    <div>No lodging is defined for this trip.</div>}
                </div>
                {tripLocation ?
                <DrivingDirectionsModal tripId={tripId} 
                    show={showDrivingDirections} isAddress={false}
                    onClose={onCloseDrivingDirections} 
                    destination={tripLocation} />
                : null}
            </Card.Body>
        </Card>
    )
}

export default TripLodging