import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Card, ListGroup, Button, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import { orderBy } from 'lodash'
import PlayerListItem from '../Player/PlayerListItem'
import * as s from './TripRecapMockAwardItem.scss'

const TripRecapMockAwardItem = ({ group = {}, trip = {}, mockAward = {}, isAdmin = false, onEdit = null }) => {

    if (!mockAward) return null
    return (
        <Card className={s.container}>
            <Card.Body>
                <Card.Title className={s.heading}>
                    <h4 className={s.title}>{mockAward.awardName}</h4>
                    {isAdmin && !mockAward.isVisible ? <Badge className={s.isNotVisible} bg='warning' text='dark'>DRAFT</Badge> : null}
                    {isAdmin ?
                    <div className={s.controls}>
                        <Button variant='light' size='sm' onClick={() => onEdit({ awardId: mockAward._id, awardName: mockAward.awardName, awardText: mockAward.awardText, isVisible: mockAward.isVisible || false, recipients: mockAward.recipients })}><FontAwesomeIcon name='pencil-alt' /></Button>
                    </div>
                    : null}
                </Card.Title>
                <p>{mockAward.awardText}</p>
                <ListGroup style={{ marginBottom: '0px' }}>
                {mockAward.recipients && mockAward.recipients.map(p =>
                    <PlayerListItem player={{ ...p, _id: p.playerID }} showHcp={false} 
                        showRole={false} key={`mock-award-${mockAward._id}-recipient-${p.playerID}`} />
                )}
                </ListGroup>
            </Card.Body>
        </Card>
    )
}

export default TripRecapMockAwardItem