import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { useDrop } from 'react-dnd'
import { ITEM_TYPES } from '../DragAndDropItemTypes'
import * as s from './FoursomeBlankMatchSlot.scss'

const FoursomeBlankMatchSlot = ({ foursome = 0, onDrop = () => void(0) }) => {

    const [{ handlerId, isOver }, _dropRef] = useDrop({
        accept: ITEM_TYPES.MATCH,
        collect: (monitor, props) => ({ 
            handlerId: monitor.getHandlerId(),
            isOver: !!monitor.isOver(),
        }),
        drop: (item, monitor) => onDrop(item, foursome)
    })

    return (
        <div ref={_dropRef} className={s.container}>
            <ListGroup.Item className={s.blankItem + (isOver ? ' ' + s.dropActive : '')}>
                <div>[Drop a match here...]</div>
            </ListGroup.Item>
        </div>
    )
}

export default FoursomeBlankMatchSlot
