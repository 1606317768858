import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripChatUnreadCommentIndicator.scss'
import moment from 'moment-timezone'
import { last, maxBy } from 'lodash'

const TripChatUnreadCommentIndicator = ({ groupId = null, tripId = null }) => {

    const dispatch = useDispatch()
    const _trip = useSelector(state => ((state.groups.groups.find(g => g._id === groupId) || {}).trips || []).find(t => t._id === tripId) || {})
    const comments = _trip.comments || []
    const lastCommentView = (_trip.playerSettings || {}).lastCommentView || null
    const [show, setShow] = useState(false)
    const [classIndex, setClassIndex] = useState(0)
    const commentCount = comments.length
    const swapClassIndex = () => setClassIndex(1 - classIndex)
    const switchClasses = [s.pulse1, s.pulse2]
    const lastCommentDate = commentCount ? maxBy(comments, 'updatedAt').updatedAt : null
    const commentIndicatorCount = commentCount > 0 &&
        moment(lastCommentDate).isValid() && 
        moment(lastCommentView).isValid() ? 
        comments.filter(i => moment(lastCommentView).isBefore(moment(i.updatedAt))).length : 0

    useEffect(() => {
        setShow(commentIndicatorCount > 0)
    }, [commentIndicatorCount])

    useEffect(() => {
        if (show) {
            setClassIndex(0)
            setTimeout(() => setClassIndex(1), 250)
            setTimeout(() => setClassIndex(0), 750)
        }
    }, [show])

    return (
        show ?
        <div className={s.container}>
            <div className={s.innerContainer + (commentIndicatorCount > 9 ? ` ${s.largeCount}` : ` ${s.smallCount}`) + ' ' + (show ? switchClasses[classIndex] : s.pulse0)}>
                <div className={s.commentIndicatorCountContainer}>{commentIndicatorCount}</div>
            </div>
        </div>
        : null
    )
}

export default TripChatUnreadCommentIndicator
