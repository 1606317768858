import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PushTypeSelector.scss'
import { chunk } from 'lodash'

const PushTypeSelector = ({ selectedPushType = null, onSelect = () => void(0) }) => {

    const _pushTypes = ['info','secondary','primary','success','warning','danger','light','dark']
    return (
        <div className={s.container}>
            {_pushTypes.map(b =>
            <Button className={s.pushButton} variant={b} 
                onClick={() => onSelect(b)} 
                key={`push-type-${b}`}>
                <div className={s.label}>{b}</div>
                <FontAwesomeIcon name='check' className={s.icon + (b === selectedPushType ? ` ${s.isSelected}` : '')} />
            </Button>
            )}
        </div>
    )
}

export default PushTypeSelector
