import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Faq.scss'
import { useDrop, useDrag } from 'react-dnd'
import { arrayMoveImmutable } from 'array-move'
import { ITEM_TYPES } from '../Trips/Admin/DragAndDropItemTypes'
import FaqContent from './FaqContent'
import FaqEditedBy from './FaqEditedBy'

const FaqItem = ({ i = {}, _id, index, isAdmin = false, onEdit, onDelete, onMove }) => {

    const dragRef = useRef(null)
    const previewRef = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: ITEM_TYPES.FAQ,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!previewRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = previewRef.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            onMove(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        type: ITEM_TYPES.FAQ,
        item: () => {
            return { i, _id, index, isAdmin }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(dragRef)
    drop(preview(previewRef))
    
    return (
        <div ref={previewRef} className={'list-group-item ' + s.item + (isDragging ? ' ' + s.isDragging : '')}>
            <div className={s.content}>
                {isAdmin ? <div ref={dragRef} className={s.handle} title={'Drag me to reorder questions...'}><FontAwesomeIcon name='bars' /></div> : null}
                <FaqContent question={i.question} answer={i.answer} isAdmin={isAdmin} _id={_id} onEdit={onEdit} onDelete={onDelete} />
            </div>
            {isAdmin ? <FaqEditedBy editedBy={i.enteredBy} editedDate={i.enteredDate} isAdmin={isAdmin} /> : null}
        </div>      
    )
}

export default FaqItem
