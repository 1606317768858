import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Row, Col, Card, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getMockAwards } from '@severed-links/common.severedlinks-reducers/groups'
import PlayerListItem from '../Player/PlayerListItem'
import ParScoreDisplay from './ParScoreDisplay'
import RyderCupRoundScoreboard from './RyderCupRoundScoreboard'
import TripRecapMockAwards from './TripRecapMockAwards'
import pluralize from 'pluralize'
import * as s from './TripRecap.scss'

const TripRecap = () => {

    const dispatch = useDispatch()
    const { group, groupId, trip, tripId } = useOutletContext()
    useEffect(() => { tripId && groupId ? dispatch(getMockAwards(groupId, tripId)) : null }, [])

    if (!tripId || !groupId) return null
    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h3>{trip.headline || 'Trip Recap'}</h3>
                </Col>
            </Row>
            <Row>
                <Col md={9} sm={8} xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: trip.wrapUp }} />

                    <TripRecapMockAwards group={group} trip={trip} />

                </Col>
                <Col md={3} sm={4} xs={12} className={s.sideCardsContainer}>

                {trip.isDuring || trip.isAfter ? 
                    <RyderCupRoundScoreboard {...(trip || {})} showRounds={false} 
                        ryderCupRounds={(trip.scoreboard || {}).ryderCupRounds || []}
                        roundScores={(trip.scoreboard || {}).ryderCup || []} 
                        numRounds={(trip.rounds || []).length} /> 
                : null}

                {trip.stewartAwardWinner && trip.stewartAwardWinner.playerId ?
                <Card>
                    <Card.Header>{group.mvpAwardName} Winner</Card.Header>
                    <ListGroup variant='flush'>
                        <PlayerListItem player={trip.stewartAwardWinner} showRole={false} key={`stewart-award-winner-${trip.stewartAwardWinner._id}`} />
                    </ListGroup>
                </Card>
                : null}

                {trip.medalists && trip.medalists.length > 0 ?
                <Card>
                    <Card.Header>{pluralize('Medalist', trip.medalists.length, false)}</Card.Header>
                    <ListGroup variant='flush'>
                    {trip.medalists && trip.medalists.map(i =>
                        <PlayerListItem player={i} showRole={false} 
                            rightDetail={<ParScoreDisplay score={trip.medalistScore} />}
                            key={`recap-medalist-${i._id}`} />
                    )}
                    </ListGroup>
                </Card>
                : null}

                {trip.newcomers && trip.newcomers.length > 0 ?
                <Card>
                    <Card.Header>{pluralize('Newcomer', trip.newcomers.length, false)}</Card.Header>
                    <ListGroup variant='flush'>
                    {trip.newcomers && trip.newcomers.map(i =>
                        <PlayerListItem player={i} showRole={false} key={`trip-newcomer-${i._id}`} />
                    )}
                    </ListGroup>
                </Card>
                : null}

                </Col>
            </Row>
        </div>
    )
}

export default TripRecap