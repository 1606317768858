// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlayerTeeBoxOverrides__container___R_bXB{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;margin:0rem .75rem}.PlayerTeeBoxOverrides__container___R_bXB .PlayerTeeBoxOverrides__badge___Yzrd0{display:flex;flex-flow:column;justify-content:center;align-items:center;text-align:center;margin:.1rem .2rem;border-width:1px;border-style:"solid"}.PlayerTeeBoxOverrides__container___R_bXB .PlayerTeeBoxOverrides__badge___Yzrd0.PlayerTeeBoxOverrides__rounded___ZktQd{border-radius:50%;width:1.5rem;height:1.5rem}`, "",{"version":3,"sources":["webpack://./src/routes/Player/PlayerTeeBoxOverrides.scss"],"names":[],"mappings":"AAEA,0CACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,kBAAA,CAEA,gFACI,YAAA,CACA,gBAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,gBAAA,CACA,oBAAA,CAEA,uHACI,iBAAA,CACA,YAAA,CACA,aAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n\n.container {\n    display: flex;\n    flex-flow: row wrap;\n    justify-content: center;\n    align-items: center;\n    margin: 0rem 0.75rem;\n\n    .badge {\n        display: flex;\n        flex-flow: column;\n        justify-content: center;\n        align-items: center;\n        text-align: center;\n        margin: 0.1rem 0.2rem;\n        border-width: 1px;\n        border-style: 'solid';\n\n        &.rounded {\n            border-radius: 50%;\n            width: 1.5rem;\n            height: 1.5rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `PlayerTeeBoxOverrides__container___R_bXB`;
export var badge = `PlayerTeeBoxOverrides__badge___Yzrd0`;
export var rounded = `PlayerTeeBoxOverrides__rounded___ZktQd`;
export default ___CSS_LOADER_EXPORT___;
