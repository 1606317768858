import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventDetails.scss'

const TripEventDetails = ({ description = '', externalUrl = '', isAdmin = false }) => {

    const getDomainFromUrl = url => {
        try {
           return (new URL(url)).hostname
        } catch {
            return url
        }
    }

    return (
        <div className={s.container}>
        {description ? <div className={s.description}>{description}</div> : null}
        {externalUrl && !isAdmin ? 
            <div className={s.externalUrl} 
                onClick={e => e.stopPropagation()}>
                <a target='_blank' title={externalUrl} 
                    href={externalUrl}>
                    <div className={s.shortUrl}>{getDomainFromUrl(externalUrl)}</div>
                    <div className={s.longUrl}>{externalUrl}</div>
                </a>
            </div> 
        : null}
        </div>
    )
}

export default TripEventDetails
