import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Row, Col, FormGroup, FormControl, FormLabel, Button, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Datetime from 'react-datetime'
import { FRIENDLY_SHORT_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import moment from 'moment-timezone'
import { range } from 'lodash'
import TripBasicsEntry from './TripBasicsEntry'
import { saveTripBasics, getAdminMatches } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import BuyAddOns from './BuyAddOns'

const TripBasics = () => {

    const dispatch = useDispatch()
    const { group, groupId, trip, tripId, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()

    const save = request => {
        dispatch(saveTripBasics(groupId, tripId, request))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
            if (trip.rsvpCapacityLimit !== request.rsvpCapacityLimit) {
                dispatch(getAdminMatches(group._id, trip._id))
            }
        })
    }

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h4>Trip Basics</h4>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <p>Set the basic info about the trip. Trip name, dates, number of rounds, etc.</p>
                </Col>
            </Row>
            <TripBasicsEntry group={group} trip={trip} onSave={save} isGlobalAdmin={isGlobalAdmin} isGlobalAdminPath={isGlobalAdminPath} />
            <BuyAddOns group={group} trip={trip} />
        </div>
    )
}

export default TripBasics