// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripValidationAlertList__container___rokJZ{padding-bottom:0px;margin-bottom:0px}.TripValidationAlertList__container___rokJZ.TripValidationAlertList__hasAlerts___E6tBU{min-height:75px;height:auto;padding-bottom:0px;margin-bottom:1rem}.TripValidationAlertList__container___rokJZ.TripValidationAlertList__hasNoAlerts___SguPi{z-index:-1}.TripValidationAlertList__container___rokJZ.TripValidationAlertList__hasNoAlerts___SguPi .TripValidationAlertList__alert___zP8rg{z-index:-1}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Admin/TripValidationAlertList.scss"],"names":[],"mappings":"AAAA,4CACI,kBAAA,CACA,iBAAA,CAEA,uFACI,eAAA,CACA,WAAA,CACA,kBAAA,CACA,kBAAA,CAEJ,yFACI,UAAA,CACA,iIACI,UAAA","sourcesContent":[".container {\n    padding-bottom: 0px;\n    margin-bottom: 0px;\n\n    &.hasAlerts {\n        min-height: 75px;\n        height: auto;\n        padding-bottom: 0px;\n        margin-bottom: 1rem;\n    }\n    &.hasNoAlerts {\n        z-index: -1;\n        .alert {\n            z-index: -1;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripValidationAlertList__container___rokJZ`;
export var hasAlerts = `TripValidationAlertList__hasAlerts___E6tBU`;
export var hasNoAlerts = `TripValidationAlertList__hasNoAlerts___SguPi`;
export var alert = `TripValidationAlertList__alert___zP8rg`;
export default ___CSS_LOADER_EXPORT___;
