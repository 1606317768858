import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TripPlayerPhotoCounts from '../Groups/TripPlayerPhotoCounts'
import RyderCupRoundScoreboard from './RyderCupRoundScoreboard'
import { setMedalistPageState } from '@severed-links/common.severedlinks-reducers/groups'
import TripRsvpLabel from './TripRsvpLabel'
import * as s from './TripListItem.scss'
import { routingPath } from '@severed-links/common.severedlinks-constants'

const TripListItem = ({ group, trip, isGlobalAdmin = false }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const medalistState = useSelector(state => state.groups.medalistState)
    const isGroupAdmin = ((group || {}).role || {}).isAdmin || isGlobalAdmin
    const isDeleting = trip?.isDeleting || false

    const selectTrip = () => {
        dispatch(setMedalistPageState(1, medalistState.pageLength, medalistState.numPages))
        navigate(`${routingPath(trip.groupId, isGlobalAdmin, ((group || {}).name || '').substring(0,1).toUpperCase())}trip/${trip._id}`) 
    }

    return (
        <ListGroup.Item className={s.tripListItem + (isDeleting ? ` ${s.isDeleting}` : ``)} action
            onClick={(trip.settings && trip.settings.isActive) || isGroupAdmin ? () => selectTrip() : undefined}>
            <div className={s.tripListItemContainer}>
                <h4 className={s.header}>
                    <div className={s.tripName}>{trip.tripName}</div>
                    {!isDeleting ?
                    <div className={s.tripLabels}>
                        {trip.settings && !trip.settings.isActive ? <Badge bg='dark' className={s.tripLabel}>Trip is not active</Badge> : null}
                        <TripRsvpLabel className={s.tripLabel} trip={trip} />
                    </div>
                    : null}
                </h4>
                {!isDeleting ? 
                <>
                {trip.dates || trip.location ?
                <div className={s.tripDetails}>
                    <div className={s.dates}><FontAwesomeIcon className={s.icon} name='calendar' /> {trip.dates} </div>
                    {trip.location ? <div className={s.location}><FontAwesomeIcon className={s.icon} name='map-marker' /> {trip.location.locName}</div> : null}                    
                </div>
                : null}
                <div className={s.body}>
                    <div className={s.littleScoreboard}>{trip.isDuring || trip.isAfter ? <RyderCupRoundScoreboard {...(trip || {})} showMini /> : null}</div>
                    <TripPlayerPhotoCounts playerCount={trip.playerCount} photoCount={trip.photoCount} />
                </div>
                </> : null}
            </div>
        </ListGroup.Item>
    )
}

export default TripListItem