import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Logout.scss'
import { logout } from '@severed-links/common.severedlinks-reducers/account'

const Logout = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(logout())
        setTimeout(() => navigate('/public'), 500)
    }, [])

    return (
        <div className={s.container}>
            <FontAwesomeIcon name='circle-notch' spin className={s.icon} />
            <div className={s.message}>Logging out...</div>
        </div>
    )
}

export default Logout
