import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, ListGroup, Button, Tooltip, Overlay } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TripEventListItem from './TripEventListItem'
import * as s from './TripEventNextList.scss'
import { take, sortBy, isEqual } from 'lodash'
import moment from 'moment-timezone'
import { recordEventResponse, getTripPlayerSettings, saveTripPlayerSetting, getTripSettings } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import arrayToSentence from 'array-to-sentence'
import pluralize from 'pluralize'

const ShowHideTripEventNotificationsButton = ({ dontSend, onClick }) => (
    <Button className={s.notificationsButton + ' ' + (dontSend ? s.dontSend : s.send)}
        title={dontSend ? `Event and task notifications are hidden for this trip` : `Send me SMS/email reminders about events and tasks`}
        onClick={() => onClick(!dontSend)}>
        <FontAwesomeIcon name={dontSend ? 'bell-slash' : 'bell'} isRegular={dontSend} />
    </Button>
)

const showEvent = (_event = {}, playerId = null) => {
    const myResponse = _event.isTaskEvent ? (_event.responses || []).find(r => r.playerId === playerId) : null
    const isComplete = _event.isTaskEvent && myResponse && myResponse.response === 10
    return _event.isVisible && 
        (
            moment().isSameOrBefore(moment(_event.endTime), 'hour') || 
            (_event.isTaskEvent && (!isComplete || (myResponse && moment(myResponse.responseDate).add(1, 'minute').isSameOrAfter(moment(), 'minute'))))
        )
}

const TripEventNextList = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const filterEvents = () => [...sortBy((trip.events || []).filter(i => showEvent(i, playerId)), 'startTime')]
    const [showAll, setShowAll] = useState(false)
    const [showHint, setShowHint] = useState(false)
    const [timeout, setTimeoutId] = useState(null)
    const _events = filterEvents()
    var eventsToShow = !showAll ? [...take(_events, 1)] : [..._events]
    const hasMore = !showAll && _events && _events.length > 1
    const hasLess = showAll && eventsToShow && eventsToShow.length > 1
    const taskCount = _events.filter(i => i.isTaskEvent).length
    const pollCount = _events.filter(i => i.isPollEvent).length
    const eventCount = _events.length - taskCount - pollCount
    const panelTitleItems = [
        ...(taskCount > 0 ? [{ name: 'Tasks', icon: 'clipboard' }] : []),
        ...(pollCount > 0 ? [{ name: 'Polls', icon: 'vote-yea' }] : []),
        ...(eventCount > 0 ? [{ name: 'Events', icon: 'calendar' }] : []),
    ]
    const dontSendEventTaskNotifications = (trip.playerSettings || {}).dontSendEventTaskNotifications || false
    const _tooltipButton = useRef(null)
    const _containerRef = useRef(null)

    useEffect(() => {
        if (!showAll && _events.length > 1) {
            setShowHint(true)
            setTimeoutId(setTimeout(() => setShowHint(false), 10 * 1000))
        }
    }, [_events.length])

    const handleClickShowAll = () => {
        if (timeout) {
            clearTimeout(timeout)
        }
        setShowAll(!showAll)
        setTimeoutId(null)
        setShowHint(false)
    }

    const sendResponseWithEvent = (_event, _eventResponse) => {
        dispatch(recordEventResponse(group._id, trip._id, _event._id, _eventResponse))
    }

    const updateNotificationsSetting = dontSend => {
        dispatch(saveTripPlayerSetting(group._id, trip._id, 'dontSendEventTaskNotifications', dontSend ? 'true' : 'false'))
        .then(action => {
            dispatch(getTripPlayerSettings(group._id, trip._id))
        })
    }

    if (!eventsToShow || eventsToShow.length === 0) return null
    return (
        <Card className={s.container} ref={_containerRef}>
            <Card.Header className={s.header}>
                <div className={s.title}>
                    <div className={s.icons}>{panelTitleItems.map(i => <FontAwesomeIcon className={s.icon} name={i.icon} key={`icon-${i.icon}`} />)}</div>
                    <div className={s.items}>{arrayToSentence(panelTitleItems.map(i => i.name))}</div>
                </div>
                <div className={s.notificationsContainer}>
                    <ShowHideTripEventNotificationsButton dontSend={dontSendEventTaskNotifications} onClick={updateNotificationsSetting} />
                </div>
                {hasMore || hasLess ?
                <div className={s.moreContainer} onClick={e => e.stopPropagation()}>
                    <Button className={s.moreButton + (showHint && !showAll ? ' ' + s.highlightedButton : '')} size='sm' 
                        ref={_tooltipButton}
                        onClick={() => handleClickShowAll()}>
                        <FontAwesomeIcon name={ showAll ? 'chevron-up' : 'chevron-down'} />
                    </Button>
                    <Overlay container={_containerRef}
                        target={_tooltipButton}
                        show={showHint}
                        placement='left'>
                    {props => (
                        <Tooltip id={`show-more-events-tooltip`} className={s.showMoreTooltip} {...props}>
                            Show {pluralize('more item', _events.length - 1, true)}...
                        </Tooltip>
                    )}
                    </Overlay>
                </div>
                : null}
            </Card.Header>
            <ListGroup className={s.list} variant='flush'>
                {eventsToShow.map(i => 
                    <TripEventListItem event={i} 
                        group={group} trip={trip} playerId={playerId}
                        onTaskResponse={sendResponseWithEvent}
                        key={`next-event-${trip._id}-${i._id}`} />
                )}
            </ListGroup>
        </Card>
    )
}

export default TripEventNextList