import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import * as s from './Faq.scss'
import moment from 'moment-timezone'
import { FRIENDLY_DATE_FORMAT, playerAvatarUrl } from '@severed-links/common.severedlinks-constants'

const FaqEditedBy = ({ editedBy, editedDate, isAdmin }) => (
    editedBy && editedBy._id ?
    <div className={s.editedBy + (isAdmin ? ' ' + s.isAdmin : '')}>
        <div className={s.avatar}><Image roundedCircle src={`${playerAvatarUrl(editedBy.imageUrl)}`} /></div>
        <div className={s.nameContainer}>
            <div className={s.name}>{editedBy.firstName} {editedBy.lastName}</div>
            <div className={s.date}>{moment(editedDate).isSame(moment(), 'day') ? moment(editedDate).fromNow() : moment(editedDate).format(FRIENDLY_DATE_FORMAT)}</div>
        </div>
    </div>
    : null
)

export default FaqEditedBy