import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventEditorRsvps.scss'
import Button from '@severed-links/common.button'
import Modal from '@severed-links/common.modal'
import Badge from '@severed-links/common.badge'
import Confirm from '@severed-links/common.confirm'
import PlayerListItem from '../../Player/PlayerListItem'
import { omit } from 'lodash'
import { recordEventResponseByAdmin, deleteEventResponse } from '@severed-links/common.severedlinks-reducers/groups'
import PlayerListSearcher from '../../Player/PlayerListSearcher'

const TabTitle = ({ title, variant = 'info', icon = 'question-circle', isSelected = false, count = 0 }) => (
    <div className={s.tabTitle + ' ' + s[variant] + (isSelected ? ` ${s.isSelected}` : ``)}>
        {icon ? <FontAwesomeIcon name={icon} className={s.icon} /> : null}
        {title || count ? 
        <div className={s.titleContainer}>
        {title ? <div className={s.title}>{title}</div> : null}
        {count ? <Badge className={s.badge} variant={variant}>{count}</Badge> : null}
        </div>
        : null}
    </div>
)

const TripEventEditorRsvps = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(null)
    const [rsvpPlayerDetail, setRsvpPlayerDetail] = useState(null)
    const [rsvpPlayerToAdd, setRsvpPlayerToAdd] = useState(null)
    const showRsvpPlayerDetail = !!rsvpPlayerDetail
    const showRsvpPlayerToAdd = !!rsvpPlayerToAdd
    const { groupId, tripId, _id: eventId, onChange, trip, eventType, rsvpCounts, responses, addRsvpPlayers, eventsLastUpdated } = useOutletContext()
    const eventsAreLoaded = trip?.eventsAreLoaded || false
    const firstResponseDate = (responses || []).length ? responses[0].updatedAt : null

    useEffect(() => {
        if (rsvpCounts?.length) {
            setActiveTab(rsvpCounts[0].title)
        }
    }, [rsvpCounts?.length || 0])

    const handleUpdate = () => {
        if (onChange) {
            onChange({ title: _title, description: _description, locationAddress: _locationAddress, externalUrl: _externalUrl })
        }
        handleCancel()
    }
    
    const handleCancel = () => {
        const _path = location.pathname.replace(/\/rsvps$/gi, '')
        navigate(_path)
    }

    const saveRsvpUpdateResponse = (playerId, responseCode, _tabToRouteTo = null) => {
        setRsvpPlayerDetail(null)
        setRsvpPlayerToAdd(null)
        dispatch(recordEventResponseByAdmin(groupId, tripId, playerId, eventId, responseCode))
        if (_tabToRouteTo) {
            setActiveTab(_tabToRouteTo)
        }
    }

    const handleDeleteResponse = () => {
        dispatch(deleteEventResponse(groupId, tripId, rsvpPlayerDetail._id ))
        setRsvpPlayerDetail(null)
    }

    const handleRsvpAddUpdate = _item => {
        //dispatch(recordEventResponseByAdmin(groupId, tripId, rsvpPlayerToAdd.playerId, event._id, _item.responseCode))
    }

    const handleCancelRsvpAdd = () => {
    }

    return (
        <div className={s.container}>

            <Tabs id='event-rsvp-tabs' activeKey={activeTab} onSelect={_tab => setActiveTab(_tab)}
                className={s.tabContainer}>
            {rsvpCounts && rsvpCounts.map(i =>
            <Tab eventKey={i.title} title={<TabTitle {...i} 
                isSelected={activeTab === i.title} count={i.responses?.length} />}
                className={s.tab} key={`rsvp-tab-${i.title}`}>
                <h4 className={s.mobileTitle}>{i.title}</h4>
                <ListGroup>
                {i.responses && i.responses.map(p =>
                <PlayerListItem player={p} showHcp={false} showRole={false} 
                    onClick={() => setRsvpPlayerDetail({ ...omit(i, ['responses']), ...p })}
                    key={`rsvp-response-${p.playerId}`} />
                )}
                {eventsAreLoaded && !i.responses?.length ? 
                <ListGroup.Item>No RSVPs located.</ListGroup.Item>
                : null}
                </ListGroup>
            </Tab>
            )}
            <Tab className={s.tab} eventKey='add RSVP' title={<TabTitle variant='dark' title='add RSVP' icon='plus' isSelected={activeTab === 'add RSVP'} />}>
                <h4 className={s.mobileTitle}>Add an RSVP Response</h4>
                {showRsvpPlayerToAdd ?
                <>
                <PlayerListItem player={rsvpPlayerToAdd} showHcp={false} showRole={false} clickDisabled
                    rightDetail={<div><Button clear icon='times' onClick={() => setRsvpPlayerToAdd(null)} /></div>} />
                <div className={s.buttonsContainer + ' ' + s.addRsvpButtonsContainer}>
                {rsvpCounts && rsvpCounts.map(i =>
                <Button icon={i.icon} variant={i.variant}
                    className={s.rsvpButton} title={i.title}
                    onClick={() => saveRsvpUpdateResponse(rsvpPlayerToAdd.playerId, i.code, i.title)}
                    key={`rsvp-player-detail-button-${i.title}`} />
                )}
                </div>                
                </>
                :
                <PlayerListSearcher players={addRsvpPlayers} 
                    playerProps={{ showRole: false, showHcp: false }}
                    onSelect={p => setRsvpPlayerToAdd(p)} />}
            </Tab>
            </Tabs>

            <Modal show={showRsvpPlayerDetail} header={`Update Player Rsvp`}
                showFooter={false} onClose={() => setRsvpPlayerDetail(null)}>
                <div className={s.rsvpPlayerDetailModalContainer}>

                {rsvpPlayerDetail ? 
                <>
                    <PlayerListItem player={rsvpPlayerDetail} 
                        showRole={false} showHcp={false} clickDisabled
                        rightDetail={<div className={s.rightDetail}>
                            <div className={s.badge + (rsvpPlayerDetail.variant ? ` ${s[rsvpPlayerDetail.variant]}` : ``)}>
                                <FontAwesomeIcon name={rsvpPlayerDetail.icon} />
                            </div>
                        </div>} />
                    <h5 className={s.updateTitle}>Update Player RSVP</h5>
                    <div className={s.buttonsContainer}>
                    {rsvpCounts && rsvpCounts.filter(i => i.responseCode !== rsvpPlayerDetail.response).map(i =>
                    <Button icon={i.icon} variant={i.variant}
                        className={s.rsvpButton} title={i.title}
                        onClick={() => saveRsvpUpdateResponse(rsvpPlayerDetail.playerId, i.code, i.title)}
                        key={`rsvp-player-detail-button-${i.title}`} />
                    )}
                    </div>

                    <Confirm title={`Delete Event Response`}
                        onConfirm={() => handleDeleteResponse()} enforceFocus={false}
                        confirmText={`delete RSVP`}
                        body={'Are you sure you want to delete this response?'}
                        variant='danger' buttonIcon='trash' buttonText='Delete response'
                        buttonClassName={s.isDeleteResponseButton} />

                </>
                : null}                    
                </div>
            </Modal>
            <div className={s.buttonsContainer}>
                <Button variant='light' title='cancel' icon='times' onClick={() => handleCancel()} />
            </div>
        </div>
    )
}

export default TripEventEditorRsvps
