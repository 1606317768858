import { useSocket } from 'socket.io-react-hook'
import { useSelector } from 'react-redux'
import { SOCKET_URL } from '@severed-links/common.severedlinks-constants'

export const useAuthenticatedSocket = () => {

    const { isAuthenticated, access_token }  = useSelector(state => state.account)
    const enabled = isAuthenticated && !!access_token
    const _socket = useSocket(SOCKET_URL, { enabled, auth: { token: `Bearer ${access_token}` } })
    return _socket
}
