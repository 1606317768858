import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useOutletContext } from 'react-router-dom'
import { Row, Col, Button, ButtonGroup, ButtonToolbar, 
    FormControl, FormGroup, FormLabel, InputGroup, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getSkinPlayerAdmin, saveSkinParticipation, saveSkinRoundAmounts } from '@severed-links/common.severedlinks-reducers/groups'
import * as s from './TripSkinsAdmin.scss'
import PlayerListItem from '../../Player/PlayerListItem'
import { sumBy } from 'lodash'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const PlayerToolbar = ({ tripId, p, isSavingPlayerId, roundAmounts, participants, isMain, onSaveEntries }) => (
    <div className={s.playerToolbar + ' ' + (isMain ? s.mainBody : s.rightDetail)}>
        <ButtonToolbar>
            <ButtonGroup>
                <Button disabled={isSavingPlayerId === p._id} variant='danger' onClick={() => onSaveEntries(p._id, roundAmounts.map(i => ({ tripID: tripId, round: i.round, playerID: p._id })), false)}><FontAwesomeIcon name='times' /></Button>
            {roundAmounts && roundAmounts.map(ra => {
                const rd = participants.find(i => i.playerID === p._id && i.round === ra.round)
                return (
                    <Button disabled={isSavingPlayerId === p._id} variant={rd.inRound ? 'success' : 'danger'} 
                        onClick={() => onSaveEntries(p._id, roundAmounts.filter(ram => ram.round === ra.round).map(i => ({ tripID: tripId, round: i.round, playerID: p._id })), !rd.inRound)}
                        key={`skins-admin-${tripId}-${p._id}-${ra.round}`}>
                        <div style={{ textAlign: 'center', fontSize: '10px' }}>Round {ra.round}</div>
                        <div style={{ textAlign: 'center' }}>${(roundAmounts.find(ram => ram.round === ra.round) || {}).amount || '0'}</div>
                    </Button>
                )
            })}
                <Button disabled={isSavingPlayerId === p._id} variant='success' onClick={() => onSaveEntries(p._id, roundAmounts.map(i => ({ tripID: tripId, round: i.round, playerID: p._id })), true)}><FontAwesomeIcon name='check' /></Button>
            </ButtonGroup>
        </ButtonToolbar>
    </div>
)

const AmountPaid = ({ amountPaid }) => (
    <div className={s.amountPaid + ' ' + (amountPaid > 0.00 ? s.success : s.danger)}>
        ${amountPaid}
    </div>
)

const calculateAmountPaid = (players, roundAmounts, participants, playerId) => {
    const player = players.find(p => p._id === playerId)
    if (!player) return 0.00
    const amountsPledged = participants.filter(p => p.playerID === playerId && p.inRound)
    if (!amountsPledged || amountsPledged.length === 0) return 0.00
    const payments = amountsPledged.map(i => ({ round: i.round, roundAmount: (roundAmounts.find(ra => ra.round === i.round) || {}).amount || 0.00 }))
    return sumBy(payments, 'roundAmount')
}

const TripSkinsAdmin = () => {

    const dispatch = useDispatch()
    const { group, groupId, trip, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [isSavingPlayerId, setIsSavingPlayerId] = useState(null)
    const [isSavingEntryFees, setIsSavingEntryFees] = useState(false)
    const [amounts, setAmounts] = useState([])
    const participants = (trip.skinPlayerAdmin || {}).participants || []
    const roundAmounts = (trip.skinPlayerAdmin || {}).roundAmounts || []
    const players = ((trip.skinPlayerAdmin || {}).players || []).map(p => ({ ...p, amountPaid: calculateAmountPaid((trip.skinPlayerAdmin || {}).players || [], roundAmounts, participants, p._id) }))

    useEffect(() => loadSkinsData(), [groupId, tripId])

    const loadSkinsData = () => {
        dispatch(getSkinPlayerAdmin(groupId, tripId))
        .then(action => {
            setAmounts(action.payload.roundAmounts.map(i => ({ round: i.round, amount: (i.amount || 0).toString() })))
        })
    }

    const saveEntries = (playerId, items, state) => {
        const postData = items.map(i => ({ tripId: i.tripID, playerId: i.playerID, round: i.round, inRound: state })) 
        setIsSavingPlayerId(playerId)
        dispatch(saveSkinParticipation(groupId, tripId, postData))
        .then(() => setIsSavingPlayerId(null))
    }

    const saveEntryFees = () => {
        const postData = amounts.map(i => ({ tripId: trip._id, round: i.round, amount: !isNaN(i.amount) ? parseInt(i.amount) : 0 }))
        setIsSavingEntryFees(true)
        dispatch(saveSkinRoundAmounts(groupId, tripId, postData))
        .then(() => {
            setIsSavingEntryFees(false)
        })
    }

    const handleAmountChange = (e, round) => {
        const _amounts = [...amounts]
        const _index = _amounts.findIndex(i => i.round === round)
        if (index > -1) {
            _amounts[index].amount = e.target.value
        }
        setAmounts(_amounts)
    }

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h4>Skins Entry Fee per Round</h4>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <p>Enter Players Into Each Skins Round (set skins entry fees <Link to={`/group/${group._id}/trip/${trip._id}/admin/payments/setup`}>here</Link>)</p>
                    <ListGroup>
                    {players && players.map(p =>
                        <PlayerListItem player={p} showRole={false} clickDisabled 
                            rightDetail={<div className={s.skinControls}>
                                <PlayerToolbar tripId={trip._id} p={p} isSavingPlayerId={isSavingPlayerId} roundAmounts={roundAmounts} participants={participants} isMain={false} onSaveEntries={saveEntries} />
                                <AmountPaid amountPaid={p.amountPaid} />
                            </div>}
                            key={`skins-admin-${trip._id}-${p._id}`}>
                                <PlayerToolbar tripId={trip._id} p={p} isSavingPlayerId={isSavingPlayerId} roundAmounts={roundAmounts} participants={participants} isMain onSaveEntries={saveEntries} />
                            </PlayerListItem>
                    )}
                    </ListGroup>
                </Col>
            </Row>
        </div>
    )
}

export default TripSkinsAdmin