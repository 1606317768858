import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext, useLocation, useNavigate } from 'react-router-dom'
import { Card, ListGroup, Alert, Image, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../../Player/PlayerListItem'
import PlayerListSearcher from '../../Player/PlayerListSearcher'
import * as s from '../TripTeams.scss'
import { commonImageUrl } from '@severed-links/common.severedlinks-constants'
import { sumBy, flatten } from 'lodash'
import { sendWonLossRecordReport } from '@severed-links/common.severedlinks-reducers/groups'
import Modal from '@severed-links/common.modal'
import TripPlayerTeamAndHcpAssignment from './TripPlayerTeamAndHcpAssignment'
import TripPlayerCaptainSetter from './TripPlayerCaptainSetter'
import pluralize from 'pluralize'
import numeral from 'numeral'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const AdminPlayerListItem = ({ player, onClick, showHcp }) => <PlayerListItem key={`admin-player-list-item-${player.playerID}`} 
        player={player} variant='flush' isAdmin
        rightDetail={player.isAssignedToMatch ? <FontAwesomeIcon name='lock' /> : null}
        onClick={onClick ? () => onClick(player) : null}
        showRole={false} showHcp={showHcp} />

const TripAdminPanel = ({ group, trip, classKey, panelName, players, onSelect, isGlobalAdmin, listKey, imageUrl = null }) => (
    <Card className={s.list + (classKey ? ' ' + classKey : '')}>
        <Card.Header className={s.header}>
            {listKey === 'usa' || listKey === 'eur' ? <Image className={s.flag} src={imageUrl} /> : null}
            <div>{panelName}</div>
            {listKey === 'usa' || listKey === 'eur' ?
            <div className={s.captainSetter}>
                <TripPlayerCaptainSetter group={group} trip={trip} player={listKey === 'eur' ? trip.eurCaptain : trip.usaCaptain} team={listKey} teamName={panelName} isAdmin isGlobalAdmin={isGlobalAdmin} />
            </div>
            : null}
        </Card.Header>
        <ListGroup>
        {players.map(i => 
        <AdminPlayerListItem player={i} showHcp={panelName !== 'Not Attending Players'} onClick={onSelect} key={`trip-admin-${panelName}-player-${i._id}`} />
        )}
        {!players || players.length === 0 ? <ListGroup.Item variant='flush'>No players located.</ListGroup.Item> : null}
        </ListGroup>
        {listKey === 'usa' || listKey === 'eur' ?
        <Card.Footer className={s.strokeTotal}>
            {pluralize('stroke', sumBy(players, 'hcp'), true)}
        </Card.Footer>
        : null}
    </Card>
)

const TripPlayerAdmin = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { groupId, group, tripId, trip, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [showPlayerAssignment, setShowPlayerAssignment] = useState(false)
    const [player, setPlayer] = useState(null)

    const selectPlayer = _player => {
        navigate(location.pathname.replace('/admin/players', `/admin/player/edit/${_player.playerId}`))
    }

    const doWonLossSend = () => {
        dispatch(sendWonLossRecordReport(groupId, tripId))
        .then(action => dispatch(createNotification(action.payload)))
    }

    const mapPlayerData = (arr = []) => arr.map(i => ({ 
        ...i, 
        handicapChecker: (trip.handicapChecker || []).find(hc => hc.playerId === i.playerID), 
        wonLossRecords: ((trip.wonLossRecords || []).find(wl => wl.playerId === i.playerID) || {}).records || [], 
    })).map(i => ({ 
        ...i, 
        wonLossPct: i.wonLossRecords.length && i.wonLossRecords.some(i => i.isOverall) ? i.wonLossRecords.find(i => i.isOverall).pct : 0,
    })).map(i => ({ 
        ...i, 
        wonLossPctDisplay: i.wonLossPct === 1 ? '1.000' : numeral(i.wonLossPct).format('.000'),
    }))

    if (!trip || !group) return null
    const matchplayerIds = flatten(((trip.scoreboard || {}).matches || []).map(m => [...m.usaPlayerList, ...m.eurPlayerList])).map(m => m.playerID)
    const usa = mapPlayerData((trip.usaPlayers || []).map(p => ({ ...p, isAssignedToMatch: matchplayerIds.some(m => m === p.playerID) })))
    const eur = mapPlayerData((trip.eurPlayers || []).map(p => ({ ...p, isAssignedToMatch: matchplayerIds.some(m => m === p.playerID) })))
    const unassignedPlayers = mapPlayerData((trip.unassignedPlayers || []).map(p => ({ ...p, isAssignedToMatch: matchplayerIds.some(m => m === p.playerID) })))
    const notAttendingPlayers = (trip.notAttendingPlayers || []).map(p => ({ ...p, isAssignedToMatch: matchplayerIds.some(m => m === p.playerID) }))
    const lockedPlayers = [...usa, ...eur, ...unassignedPlayers, ...notAttendingPlayers].filter(p => p.isAssignedToMatch)
    const otherPlayers = (group.players || []).filter(p => ![...usa, ...eur, ...unassignedPlayers, ...notAttendingPlayers].some(p2 => p2.playerID === p.playerID))
    const chatIsOpen = trip.showChat
    const tablesShown = (usa.length > 0 ? 1 : 0) + (eur.length > 0 ? 1 : 0) + (unassignedPlayers.length > 0 ? 1 : 0)
    return (
        <div className={s.container + (chatIsOpen ? ' ' + s.chatIsOpen : '')}>
            <div className={s.heading}>
                <h4 className={s.title}>Trip Players</h4>
                <div className={s.controls}>
                    <Button variant='light' onClick={() => doWonLossSend()}><FontAwesomeIcon name='envelope' /> W/L</Button>
                </div>
            </div>
            <p>Pick your {trip.usaTeamName} and {trip.eurTeamName} teams, assign handicaps, and manage RSVPs.</p>
            {lockedPlayers && lockedPlayers.length > 0 ?
                <Alert variant='warning'>Adjusting the team of some players below is locked because they are assigned to matches already.  If you need to change a player's team, they will need to be removed from any matches they are assigned to first.</Alert>
            : null}
            <div className={s.tripTeamsContainer}>
                <TripAdminPanel classKey={s.usa + (chatIsOpen ? ' ' + s.chatIsOpen : '') + ' ' + s[`list${tablesShown}`]} listKey='usa' panelName={trip.usaTeamName} imageUrl={trip.usaFullTeamImageUrl} players={usa} onSelect={selectPlayer} group={group} trip={trip} isGlobalAdmin={isGlobalAdmin} />
                <TripAdminPanel classKey={s.eur + (chatIsOpen ? ' ' + s.chatIsOpen : '') + ' ' + s[`list${tablesShown}`]} listKey='eur' panelName={trip.eurTeamName} imageUrl={trip.eurFullTeamImageUrl} players={eur} onSelect={selectPlayer} group={group} trip={trip} isGlobalAdmin={isGlobalAdmin} />
                <TripAdminPanel classKey={s.unassigned + (chatIsOpen ? ' ' + s.chatIsOpen : '') + ' ' + s[`list${tablesShown}`]} listKey='unassigned' panelName='Unassigned Players' players={unassignedPlayers} onSelect={selectPlayer} group={group} trip={trip} isGlobalAdmin={isGlobalAdmin} />
                <Card className={s.list + (chatIsOpen ? ' ' + s.chatIsOpen : '') + ' ' + s.otherPlayers + ' ' + s[`list${tablesShown}`]}>
                    <Card.Header>Other Players ({otherPlayers.length})</Card.Header>
                    <Card.Body className={s.title}>
                        <PlayerListSearcher players={otherPlayers} 
                            PlayerComponent={AdminPlayerListItem}
                            isGlobalAdmin={isGlobalAdmin}
                            emptyPhrase='Add players to trip manually...'
                            onSelect={selectPlayer} />
                    </Card.Body>
                </Card>
                <TripAdminPanel panelName='Not Attending Players' classKey={s.notAttending + (chatIsOpen ? ' ' + s.chatIsOpen : '') + ' ' + s[`list${tablesShown}`]} players={notAttendingPlayers} onSelect={selectPlayer} group={group} trip={trip} isGlobalAdmin={isGlobalAdmin} />
            </div>

            <Modal show={showPlayerAssignment} showFooter={false}
                heading={`Trip Team and Handicap Assignment`}
                onClose={() => setShowPlayerAssignment(false)}>
                <TripPlayerTeamAndHcpAssignment group={group} trip={trip} player={player}
                        onClose={() => setShowPlayerAssignment(false)} 
                        isGlobalAdmin={isGlobalAdmin} />
            </Modal>

        </div>
    )
}

export default TripPlayerAdmin
