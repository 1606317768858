import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Card, ListGroup, Table } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GroupPlayerSummary.scss'
import { PatternFormat } from 'react-number-format'
import numeral from 'numeral'

const GroupPlayerSummary = ({ summary = {}, player = {}, isLoading = false }) => {

    if (!summary || !player || isLoading) return null
    const data = [
        ...(player.emailAddress && player.canDisplayEmailAddress ? [{ header: 'Email', info: player.emailAddress }] : []),
        ...(player.cellPhone && player.canDisplayCellPhone ? [{ header: 'Mobile', info: player.cellPhone }] : []),
        { header: 'Last appearance', info: summary.lastTrip },
        { header: 'Appearances', info: summary.appearances },
        { header: 'Best finish', info: summary.bestFinish },
        { header: 'Top 5 finishes', info: summary.top5 },
        { header: 'Total rounds', info: summary.totalRounds },
        ...(summary.totalRounds !== summary.ownBallRounds ? [{ header: 'Own-ball rounds', info: summary.ownBallRounds }] : []),
        { header: `Scoring Average${summary.showNet ? ` (Net)` : ``}`, info: `${numeral(summary.scoringAverage || 0).format('0.0')}${summary.showNet ? ` (${numeral(summary.netScoringAverage || 0).format('0.0')})` : ``}`, showDagger: true },
        ...(summary.lowGross ? [{ header: 'Low/High Gross', info: `${summary.lowGross} / ${summary.highGross}`, showDagger: true }] : []),
        ...(summary.lowNet && summary.showNet ? [{ header: 'Low/High Net', info: `${summary.lowNet} / ${summary.highNet}`, showDagger: true }] : []),
        ...(summary.holeInOnes ? [{ header: 'Hole-in-ones!', info: summary.holeInOnes }] : []),
        ...(summary.doubleEagles ? [{ header: 'Double eagles!', info: summary.doubleEagles }] : []),
        ...(summary.eagles ? [{ header: 'Eagles', info: summary.eagles }] : []),
        { header: 'Birdies', info: summary.birdies },
        { header: 'Pars', info: summary.pars },
        { header: 'Bogeys', info: summary.bogeys },
        { header: 'Double bogeys', info: summary.doubleBogeys },
        { header: 'Other...', info: summary.otherBogeys },
    ]
    return (
        <Card className={s.container}>
            <Card.Header>Player Information</Card.Header>
            <ListGroup variant='flush' className={s.list}>
            {data && data.map(i => 
                <ListGroup.Item className={s.item} key={`group-player-info-${i.header}`}>
                    <div className={s.header}>{i.header}{i.showDagger ? <sup>&dagger;</sup> : null}</div>
                    <div className={s.data}>
                    {i.header !== 'Mobile' ? i.info :
                    <PatternFormat value={i.info} format='(###) ###-####' displayType='text' mask='_'/>}
                    </div>
                </ListGroup.Item>
            )}
            <ListGroup.Item className={s.item + ' ' + s.miniNotice}>
                <div className={s.indicator}>&dagger;</div>
                <div className={s.message}>Scores are normalized to 18-hole scores for reporting purposes.</div>
            </ListGroup.Item>
            </ListGroup>
        </Card>
    )
}

export default GroupPlayerSummary