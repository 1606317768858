import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Row, Col, Image, FormGroup, FormLabel, FormControl, InputGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import { getBase64, img } from '@severed-links/common.severedlinks-constants'
import { getMe } from '@severed-links/common.severedlinks-reducers/account'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '@severed-links/common.spinner'

const NewImageUploadModal = ({ show = false, playerId = null, onClose, onUpload }) => {

    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState('')
    const [formData, setFormData] = useState(null)
    const [imageUrl, setImageUrl] = useState('')
    const [fileBase64, setFileBase64] = useState(null)
    const [isSaving, setIsSaving] = useState(false)

    const handleFileSelect = e => {
        if (e.target.files && e.target.files.length > 0) {
            const _file = e.target.files[0]
            const _imageUrl = uuidv4().toString()
            const _publicId = img.CloudinaryPlayerAvatarPublicId(_imageUrl)
            var _formData = new FormData()
            _formData.append('public_id', _)
            _formData.append('upload_preset', img.CloudinaryPlayerAvatarUploadPreset)
            _formData.append('file', _file)
            setFileName(e.target.value)
            setFile(_file)
            setImageUrl(_imageUrl)
            setFormData(_formData)
            getBase64(_file).then(data => setFileBase64(data))
        }
    }

    const conUploadSuccess = e => {
        if (e.target.response && e.target.status === 200) {
            onUpload(imageUrl)
            onClose()
        } else {
            setIsSaving(false)
            const error = (JSON.parse(e.target.response) || {}).error
            dispatch(createNotification({ message: error.message, type: 'error', headline: 'Upload Error', timeout: 5000 }))
            onClose()
        }
    }

    const onUploadProgress = e => {
    }

    const onUploadError = e => {
        setIsSaving(false)
        dispatch(createNotification({ message: 'Upload error: ' + JSON.stringify(e), type: 'error', headline: 'Upload Error', timeout: 6000 }))
    }

    const upload = () => {
        setIsSaving(true)
        img.CloudinaryImageUploader(formData, img.CloudinaryImageUploadUrl, onUploadSuccess, onUploadProgress, onUploadError)
    }

    return (
        <Modal show={show} 
            heading='Upload new player avatar'
            actionButtonText='upload new image'
            actionButtonIcon='upload'
            actionButtonIconSpin={isSaving}
            actionButtonDisabled={!fileBase64 || isSaving}
            actionButtonOnClick={() => upload()}
            onClose={onClose}>
            <div>
                <FormGroup>
                    <FormLabel>Select a new image...</FormLabel>
                    <FormControl type='file' value={fileName} onChange={e => handleFileSelect(e)} />
                </FormGroup>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {fileBase64 ? 
                        <div style={{ textAlign: 'center' }}>
                            {!isSaving ?
                            <Image src={fileBase64} style={{ width: '150px' }} />
                            :
                            <Spinner size='3x' />}
                        </div>
                    : null}
                </div>
            </div>
        </Modal>
    )
}

export default NewImageUploadModal