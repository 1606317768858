import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useMatch, Link } from 'react-router-dom'
import { Nav, Navbar } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GroupAdmin.scss'
import { first } from 'lodash'
import { routingPath } from '@severed-links/common.severedlinks-constants'

const GroupAdminMenuBar = ({ group = {}, isGlobalAdmin = false, isGlobalAdminPath = false }) => {

    const navigate = useNavigate()
    const params = useParams()
    const groupId = group._id || null
    const match = useMatch(`/group/:groupId/admin/:activeGroupAdminTab/*`)
    const activeGroupAdminTab = match && match.params.activeGroupAdminTab || null

    const _menuItems = [
        { name: 'Logins', icon: 'clock', path: `logins`, disabled: false },
        { name: 'Messaging', icon: 'envelope', path: `messaging`, disabled: false },
        { name: 'Settings', icon: 'cog', path: `settings`, disabled: false },
        { name: 'FAQ', icon: 'question', path: `faq`, disabled: false },
        { name: 'Contacts', icon: 'address-book', path: `address-book`, disabled: false },
    ].filter(i => i && i.name)

    useEffect(() => {
        if (!activeGroupAdminTab) {
            handleSelect(first(_menuItems).path)
        }
    }, [activeGroupAdminTab])

    const _pathPrefix = groupId ? `${routingPath(groupId, isGlobalAdminPath, ((group || {}).name || ' ').substring(0,1).toUpperCase())}admin` : null

    const handleSelect = e => {
        navigate(`${_pathPrefix}/${e}`)
    }

    const _activeMenuItem = _menuItems.find(i => i.path === activeGroupAdminTab)
    return (
        <div>
            <Navbar expand='md' collapseOnSelect className={s.smallMenu + (isGlobalAdmin ? ' ' + s.isGlobalAdmin : '')}>
                {_activeMenuItem ? 
                <h4 className={s.headingContainer}>
                    <FontAwesomeIcon name={_activeMenuItem.icon} className={s.icon} />
                    <div>{_activeMenuItem.name}</div>
                </h4>
                : null}
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav activeKey={activeGroupAdminTab} onSelect={e => handleSelect(e)}>
                    {_menuItems && _menuItems.filter(i => i.path !== activeGroupAdminTab).map(i =>
                        <Nav.Item key={`group-admin-small-nav-${i.path}`}><Nav.Link eventKey={i.path} disabled={i.disabled}>{i.icon ? <FontAwesomeIcon name={i.icon} /> : null} {i.name}</Nav.Link></Nav.Item>
                    )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className={s.largeMenu + (isGlobalAdminPath ? ' ' + s.isGlobalAdmin : '')}>
                <Nav className={s.nav} variant='pills' activeKey={activeGroupAdminTab} onSelect={e => handleSelect(e)}>
                {_menuItems && _menuItems.map(i =>
                    <Nav.Item className={s.item} key={`group-admin-main-nav-${i.path}`}>
                        <Nav.Link className={s.link + (i.path === activeGroupAdminTab ? ` ${s.active}` : ``)} eventKey={i.path} disabled={i.disabled}>{i.icon ? <FontAwesomeIcon name={i.icon} /> : null} {i.name}</Nav.Link>
                    </Nav.Item>
                )}
                </Nav>
            </div>
        </div>
    )
}

export default GroupAdminMenuBar