// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SquareCreditCardInitializer__alert___we4yz .SquareCreditCardInitializer__content___OA1o3{display:flex;flex-flow:row nowrap;justify-content:space-between}.SquareCreditCardInitializer__alert___we4yz .SquareCreditCardInitializer__content___OA1o3 .SquareCreditCardInitializer__icon___NQntX{flex:0 0 0;margin-right:10px}.SquareCreditCardInitializer__alert___we4yz .SquareCreditCardInitializer__content___OA1o3 .SquareCreditCardInitializer__text___qCUiz{flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./src/routes/CreditCard/SquareCreditCardInitializer.scss"],"names":[],"mappings":"AACI,0FACI,YAAA,CACA,oBAAA,CACA,6BAAA,CAEA,qIACI,UAAA,CACA,iBAAA,CAGJ,qIACI,aAAA","sourcesContent":[".alert {\n    .content {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n\n        .icon { \n            flex: 0 0 0;\n            margin-right: 10px;\n        }\n\n        .text {\n            flex: 1 1 auto;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var alert = `SquareCreditCardInitializer__alert___we4yz`;
export var content = `SquareCreditCardInitializer__content___OA1o3`;
export var icon = `SquareCreditCardInitializer__icon___NQntX`;
export var text = `SquareCreditCardInitializer__text___qCUiz`;
export default ___CSS_LOADER_EXPORT___;
