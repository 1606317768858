import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { FormGroup, FormControl, FormLabel, Button, ButtonGroup, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { Editor, EditorState, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'
import validator from 'validator'
import { sortBy, reject, initial } from 'lodash'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './TripMessaging.scss'
import { saveTripRecap } from '@severed-links/common.severedlinks-reducers/groups'

const TripRecapEditor = () => {

    const dispatch = useDispatch()
    const { group, trip, groupId, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [headline, setHeadline] = useState('')
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [isSaving, setSaving] = useState(false)

    useEffect(() => initializeEditor(), [])

    const initializeEditor = () => {
        setHeadline(trip.headline || '')
        setEditorState(trip.wrapUp ? EditorState.createWithContent(stateFromHTML(trip.wrapUp)) : EditorState.createEmpty())
    }

    const handleEditorChange = _editorState => setEditorState(_editorState)

    const handleEditorButton = command => handleEditorChange(RichUtils.toggleInlineStyle(editorState, command))

    
    const saveRecap = () => {
        const options = {
            blockStyleFn: block => {
                return {
                    style: {
                        marginBottom: '1rem'
                    }
                }
            }        
        }
        setSaving(true)
        dispatch(saveTripRecap(groupId, tripId, headline, editorState.getCurrentContent().hasText() ? stateToHTML(editorState.getCurrentContent(), options) : ''))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Save Trip Recap', timeout: 4000 }) )
            setSaving(false)
        })
    }

    return (
        <div className={s.container}>

            <h4>Trip Recap Editor</h4>

            <p>The 'Recap' tab will show up in the menu items above the day after a trip is complete.</p>

            <FormGroup>
                <FormLabel style={{ display: 'block' }}>Headline</FormLabel>
                <FormControl autoFocus value={headline} onChange={e => setHeadline(e.target.value)} />
            </FormGroup>

            <div className={s.editorContainer} style={{ marginBottom: '20px' }}>
                <div className={s.editorTools}>
                <ButtonGroup tabIndex={-1}>
                    <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('BOLD')}><FontAwesomeIcon name='bold' /></Button>
                    <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('UNDERLINE')}><FontAwesomeIcon name='underline' /></Button>
                    <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('ITALIC')}><FontAwesomeIcon name='italic' /></Button>
                </ButtonGroup>
                </div>
                <Editor className={s.editor} editorState={editorState} 
                    placeholder='[Recap your trip here...]' autoFocus 
                    blockStyleFn={contentBlock => s.block}
                    onChange={e => handleEditorChange(e)} />
            </div>

            <FormGroup>
                <Button variant='primary' disabled={isSaving} onClick={() => saveRecap()}><FontAwesomeIcon name={!isSaving ? 'check' : 'circle-notch'} spin={isSaving} /> save recap</Button>
            </FormGroup>

        </div>
    )
}

export default TripRecapEditor