import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { FormControl, FormLabel, FormGroup, Button, InputGroup, Table } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { v4 as uuidv4 } from 'uuid'
import { API_URL, getBase64 } from '@severed-links/common.severedlinks-constants'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { uploadUsGolfDataSpreadsheet } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import { uniq, range } from 'lodash'
import * as s from './GlobalAdminUsGolfImporter.scss'

const GlobalAdminUsGolfImporter = ({  }) => {

    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [file, setFile] = useState(null)
    const [key, setKey] = useState(uuidv4())
    const [size, setSize] = useState(0)
    const [progress, setProgress] = useState(0)
    const [type, setType] = useState('')
    const [results, setResults] = useState([])
    const [isUploading, setIsUploading] = useState(false)
    const [rowsImported, setRowsImported] = useState(0)
    const [fileContents, setFileContents] = useState(null)

    const upload = () => {
        setRowsImported(0)
        setIsUploading(true)
        dispatch(uploadUsGolfDataSpreadsheet(name, size, type, fileContents))
        .then(action => {
            dispatch(createNotification(action.payload))
            setResults(action.payload.data)
            setRowsImported(action.payload._id)
            setIsUploading(false)
            clearFileElement()
        })
    }

    const handleFileChange = e => {
        e.preventDefault()
        if (e.target.files && e.target.files.length > 0) {
            const _file = e.target.files[0]
            const { size, name, type } = _file
            getBase64(_file)
            .then(fileContents => {
                setFileContents(fileContents)
                setSize(size)
                setName(name)
                setType(type)
            })
        }
    }

    const clearFileElement = () => {
        setFileContents(null)
        setFile(null)
        setName('')
        setKey(uuidv4())
        setSize(0)
        setProgress(0)
        setType('')
        setIsUploading(false)
    }

    const mapResults = () => {
        var resultItems = []
        if (results && results.length > 0) {
            resultItems = uniq(results.map(i => i.courseNumber)).map(i => ({ courseNumber: i }))
            resultItems.forEach((result, index) => {
                const i = results.find(x => x.courseNumber === result.courseNumber)
                var newItem = { ...result, courseName: i.courseName, architect: i.architect, city: i.city, county: i.county, emailAddress: i.emailAddress, faxNumber: i.faxNumber, guestPolicy: i.guestPolicy, latitude: i.latitude, longitude: i.longitude, phoneNumber: i.phoneNumber, stateorRegion: i.stateorRegion, statusPublicPrivateResort: i.statusPublicPrivateResort, streetAddress: i.streetAddress, totalHoles: i.totalHoles, url: i.url, yearBuiltFounded: i.yearBuiltFounded, zip: i.zip, tees: results.filter(i => i.courseNumber === result.courseNumber).map(j => ({ _id: j._id, courseTeeNumber: j.courseTeeNumber, teeName: j.teeName, teeNumber: j.teeNumber, numHoles: j.holes, length: j.length, par: j.par, rating: j.rating, slope: j.slope, totInPar: j.totInPar, totOutPar: j.totOutPar, totInYdg: j.totInYdg, totOutYdg: j.totOutYdg, holes: [] })) }
                newItem.tees.forEach(tee => {
                    const holeData = results.find(x => x.courseTeeNumber === tee.courseTeeNumber)
                    range(1, tee.numHoles + 1, 1).forEach(h => {
                        tee.holes.push({ hole: h, par: holeData[`par${h}`], hcp: holeData[`hdcp${h}`], yards: holeData[`hole${h}`] })
                    })
                })
                resultItems[index] = newItem
            })
        }
        return resultItems
    }

    return (
        <div className={s.container}>
            <h4>Import US Golf Course Data File</h4>
            <FormGroup className={s.formGroup}>
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon name='file' /></InputGroup.Text>
                    <FormControl className='form-control' 
                        onChange={e => handleFileChange(e)} 
                        accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        key={key}
                        multiple={false} type='file' />
                    {fileContents && !isUploading ? <Button variant='light' onClick={() => clearFileElement()}><FontAwesomeIcon name='times' /></Button> : null}
                    <Button variant='primary' disabled={!fileContents || isUploading} onClick={() => upload()}><FontAwesomeIcon name={!isUploading ? 'upload' : 'circle-notch'} spin={isUploading} /></Button>
                </InputGroup>
                {results && results.length > 0 ? 
                <>
                <h3>Rows Imported: {rowsImported}</h3>
                <h3>Sample Imported Data ({results.length} record(s))</h3>
                </>
                : null}
                {mapResults().map(r => 
                    <div key={`results-${r.courseNumber}`}>
                        <h4>{r.courseName}, {r.streetAddress}, {r.city}, {r.stateorRegion}, {r.zip}</h4>
                        {r.tees && r.tees.map(t => 
                            <div key={`results-${r.courseNumber}-${r.courseTeeNumber}`}>
                                <h5>{t.teeName} Tees (slope: {t.slope}, rating: {t.rating}, {t.length} yards)</h5>
                                {t.holes && t.holes.length > 0 ? 
                                    <div key={`results-${r.courseNumber}-${r.courseTeeNumber}-noles`}>
                                    <Table striped>
                                    <thead>
                                        <tr>
                                            <th>Hole</th>
                                            {t.holes.map(h => <th key={`results-${r.courseNumber}-${r.courseTeeNumber}-nole-header-${h.hole}`} style={{ textAlign: 'center' }}>{h.hole}</th>)}
                                            <th style={{ textAlign: 'center' }}>Out</th>
                                            <th style={{ textAlign: 'center' }}>In</th>
                                            <th style={{ textAlign: 'center' }}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Par</td>
                                            {t.holes.map(h => <td key={`results-${r.courseNumber}-${r.courseTeeNumber}-nole-number-${h.hole}`} style={{ textAlign: 'center' }}>{h.par}</td>)}
                                            <td style={{ textAlign: 'center' }}>{t.totOutPar}</td>
                                            <td style={{ textAlign: 'center' }}>{t.totInPar}</td>
                                            <td style={{ textAlign: 'center' }}>{t.par}</td>
                                        </tr>
                                        <tr>
                                            <td>Yds</td>
                                            {t.holes.map(h => <td key={`results-${r.courseNumber}-${r.courseTeeNumber}-nole-yards-${h.hole}`} style={{ textAlign: 'center' }}>{h.yards}</td>)}
                                            <td style={{ textAlign: 'center' }}>{t.totOutYdg}</td>
                                            <td style={{ textAlign: 'center' }}>{t.totInYdg}</td>
                                            <td style={{ textAlign: 'center' }}>{t.length}</td>
                                        </tr>
                                        <tr>
                                            <td>Hcp</td>
                                            {t.holes.map(h => <td key={`results-${r.courseNumber}-${r.courseTeeNumber}-nole-hcp-${h.hole}`} style={{ textAlign: 'center' }}>{h.hcp}</td>)}
                                            <td colSpan={3}></td>
                                        </tr>
                                    </tbody>
                                    </Table>
                                    </div>
                                : null}

                            </div>
                        )}
                    </div>
                )}
            </FormGroup>
        </div>
    )
}

export default GlobalAdminUsGolfImporter