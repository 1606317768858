import React, { useEffect } from 'react'
import { } from 'react-redux'
import { Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import * as s from './TripInactiveLabel.scss'

const TripInactiveLabel = ({ trip = {} }) => {

    if (!trip || !trip.settings) return null
    const isTripActive = trip.settings.isActive || false
    const isBeforeTrip = trip.startDate && moment().isBefore(moment(trip.startDate), 'day')

    return (
        !isTripActive ?
        <Badge bg='secondary' className={s.label}>
            Inactive Trip
        </Badge>
        : null
    )
}

export default TripInactiveLabel