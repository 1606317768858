import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Image, Transformation } from 'cloudinary-react'
import { img } from '@severed-links/common.severedlinks-constants'
import { CLOUDINARY_CONFIG } from '../../../config/cloudinary.config'

const CloudinaryImage = ({ publicId = null, transformationName = null, ...props }) => {

    return (
        <Image {...props} 
            cloudName={CLOUDINARY_CONFIG.cloudName} 
            transformation={transformationName}
            publicId={publicId || img.NoImageAvailable} 
            secure /> 
    )
}

export default CloudinaryImage