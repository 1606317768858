// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PendingInvitesList__pendingInvitesControls___e8A76{display:flex;flex-flow:row nowrap;justify-content:flex-end;align-items:center}.PendingInvitesList__pendingInvitesControls___e8A76 .PendingInvitesList__button___iPdew{margin-left:.5rem}.PendingInvitesList__pendingInvitesPlayerItemControlsContainer___GgetH{position:absolute;top:.1rem;right:-0.2rem;padding:0rem;display:flex;flex-flow:row nowrap;justify-content:flex-end;align-items:center}.PendingInvitesList__pendingInvitesPlayerItemControlsContainer___GgetH .PendingInvitesList__button___iPdew{margin-left:.25rem}`, "",{"version":3,"sources":["webpack://./src/routes/Groups/Admin/PendingInvitesList.scss"],"names":[],"mappings":"AAAA,oDACI,YAAA,CACA,oBAAA,CACA,wBAAA,CACA,kBAAA,CAEA,wFACI,iBAAA,CAIR,uEACI,iBAAA,CACA,SAAA,CACA,aAAA,CACA,YAAA,CACA,YAAA,CACA,oBAAA,CACA,wBAAA,CACA,kBAAA,CAEA,2GACI,kBAAA","sourcesContent":[".pendingInvitesControls {\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: flex-end;\n    align-items: center;\n\n    .button {\n        margin-left: 0.5rem;\n    }\n}\n\n.pendingInvitesPlayerItemControlsContainer {\n    position: absolute;\n    top: 0.1rem;\n    right: -0.2rem;\n    padding: 0rem;\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: flex-end;\n    align-items: center;\n\n    .button {\n        margin-left: 0.25rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var pendingInvitesControls = `PendingInvitesList__pendingInvitesControls___e8A76`;
export var button = `PendingInvitesList__button___iPdew`;
export var pendingInvitesPlayerItemControlsContainer = `PendingInvitesList__pendingInvitesPlayerItemControlsContainer___GgetH`;
export default ___CSS_LOADER_EXPORT___;
