import React, { act, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useOutletContext, Outlet } from 'react-router-dom'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Badge from '@severed-links/common.badge'
import * as s from './GolfApiIoDataApiRequestsLeft.scss'
import { endsWith, first } from 'lodash'

const GolfApiIoDataApiRequestsLeft = ({ containerStyle = {} }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { _id: userId } = useSelector(state => state.account)
    const { apiRequestsLeft } = useSelector(state => state.golfApiIo)
    const _tabs = [
        { title: 'Load by City', icon: 'map-marker', path: 'load-by-city' },
    ]
    const activeTab = (_tabs.find(i => pathname.indexOf(`/${i.path}`) > -1) || {}).path || null

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (!activeTab) {
            _handleTabSelect(first(_tabs).path)
        }
    }, [activeTab])

    const _handleTabSelect = (_path) => {
        navigate(`${pathname.replace(`/${activeTab}`, '')}/${_path}`)
    }

    return (
        <div style={containerStyle} className={s.container + (apiRequestsLeft === -1 ? ` ${s.notLoaded}` : apiRequestsLeft ? ` ${s.hasRequests}` : '')}>
            <div className={s.text}>API Requests</div>
            <div className={s.count}>{apiRequestsLeft || 0}</div>
            <div className={s.text}>Left</div>
        </div>
    )
}

export default GolfApiIoDataApiRequestsLeft
