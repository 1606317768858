import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, FormControl, FormLabel, InputGroup, FormGroup, Button, Image, Card } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import validator from 'validator'
import { img, playerAvatarUrl, getBase64, API_AUTH_TOKEN_NAME } from '@severed-links/common.severedlinks-constants'
import { deleteAvatar, setProfileStateValue, saveProfile, checkEmailAvailability } from '@severed-links/common.severedlinks-reducers/profile'
import { getMe } from '@severed-links/common.severedlinks-reducers/account'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import Confirm from '@severed-links/common.confirm'
import NewImageUploadModal from './NewImageUploadModal'
import ConfirmCellPhone from './ConfirmCellPhone'
import FormCheck from 'react-bootstrap/FormCheck'
import Spinner from '@severed-links/common.spinner'
import * as s from './EditProfile.scss'
import { initial, isEqual, keysIn, pick } from 'lodash'

const EditProfile = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const account = useSelector(state => state.account || {})
    const showNewImageModal = useSelector(state => state.profile.showNewImageModal)
    const [_account, setAccount] = useState({ _id: null, firstName: '', lastName: '', address: '', city: '', state: '', zip: '', emailAddress: '', cellPhone: '', imageUrl: '', emailIsAvailable: false, canDisplayEmailAddress: false, canDisplayCellPhone: false })
    const { _id, firstName, lastName, address, city, state, zip, cellPhone, emailAddress, imageUrl, emailIsAvailable, canDisplayEmailAddress, canDisplayCellPhone } = _account
    const isLoading = !_id

    useEffect(() => {
        if (account._id) {
            initializeState()
        }
    }, [account._id])

    useEffect(() => {
        checkEmail()
    }, [emailAddress, _id])

    useEffect(() => {
        if (!isLoading && account._id && _id) {
            setAccount({ ..._account, imageUrl: account.imageUrl || null })
        }
    }, [isLoading, account._id, account.imageUrl || null])

    const initializeState = () => {
        const _accountData = { ..._account, ...(pick(account, keysIn(_account)) || {}) }
        setAccount(_accountData)
    }

    const checkEmail = () => {
        if (_account._id && !validator.isEmpty(emailAddress || '') && validator.isEmail(emailAddress || '')) {
            dispatch(checkEmailAvailability(_id, emailAddress))
            .then(action => {
                if (action.payload.emailAddress === emailAddress) {
                    setAccount({ ..._account, emailIsAvailable: action.payload.isAvailable })
                }
            })
        } else if (_account._id) {
            setAccount({ ..._account, emailIsAvailable: false })
        }
    }

    const handleChange = e => {
        const fieldName = e.target.name
        const value = e.target.value
        setAccount({ ..._account, [fieldName]: value })
    }

    const deleteImage = () => {
        dispatch(deleteAvatar(_id))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, timeout: 5000, headline: 'Delete Player Avatar Image' }))
            if (action.payload.messageType === 'success') dispatch(getMe())
        })
    }

    const save = () => {
        const postData = {
            _id, playerId: _id, firstName, lastName, address, city, state, zip,
            cellPhone, emailAddress, canDisplayEmailAddress, canDisplayCellPhone
        }
        dispatch(saveProfile(postData))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Save Profile', timeout: 5000 }))
            if (action.payload.messageType === 'success') dispatch(getMe())
        })
    }

    const profileIsValid = () => !validator.isEmpty(firstName || '') && !validator.isEmpty(lastName || '') && 
        !validator.isEmpty(cellPhone || '') && !validator.isEmpty(emailAddress || '') &&
        validator.isMobilePhone(cellPhone, 'en-US') && validator.isEmail(emailAddress) && emailIsAvailable

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>Edit Your Profile</h2>
                </Col>
            </Row>
            {isLoading ? 
            <div className={s.spinnerContainer}>
                <Spinner size='3x' />
            </div>
            :
            <Row>
                <Col md={3} xs={8}>
                    <Card variant='info'>
                        <Card.Img src={playerAvatarUrl(imageUrl)} />
                        <Card.Body>
                            <Card.Title className={s.avatarHeader}>Your Avatar</Card.Title>
                            <Card.Body className={s.avatarButtons}>
                                <Button className={s.avatarButton} variant='info' onClick={() => dispatch(setProfileStateValue({ showNewImageModal: true }))}><FontAwesomeIcon name='upload' /></Button>
                                {imageUrl ? 
                                <Confirm title={'Delete Your Profile Image'} 
                                    onConfirm={() => deleteImage()}
                                    confirmText='delete image'
                                    body={<div><p>Are you sure you want to delete this image?</p><p style={{ textAlign: 'center' }}><Image rounded src={playerAvatarUrl(imageUrl)} style={{ width: '100px' }} /></p></div>}
                                    variant='danger' buttonClassName={s.avatarButton} buttonIcon='trash' />
                                : null}
                            </Card.Body>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={9} xs={12}>
                    <Row>
                        <Col md={6} xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>First name</FormLabel>
                                <FormControl name='firstName' value={firstName || ''} 
                                    required isValid={!validator.isEmpty(firstName || '')}
                                    isInvalid={!!validator.isEmpty(firstName || '')}
                                    onChange={e => handleChange(e)} />
                                <FormControl.Feedback />
                            </FormGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Last name</FormLabel>
                                <FormControl name='lastName' value={lastName || ''} 
                                    required isValid={!validator.isEmpty(lastName || '')}
                                    isInvalid={!!validator.isEmpty(lastName || '')}
                                    onChange={e => handleChange(e)} />
                                <FormControl.Feedback />
                            </FormGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Address</FormLabel>
                                <FormControl name='address' value={address || ''} onChange={e => handleChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>City</FormLabel>
                                <FormControl name='city' value={city || ''} onChange={e => handleChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>State</FormLabel>
                                <FormControl name='state' value={state || ''} onChange={e => handleChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Zip</FormLabel>
                                <FormControl name='zip' value={zip || ''} onChange={e => handleChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Email address (also serves as your user name)</FormLabel>
                                <InputGroup className={s.switchGroup}>
                                    <FormControl name='emailAddress' value={emailAddress || ''} 
                                        required isValid={!validator.isEmpty(emailAddress || '') && validator.isEmail(emailAddress) && emailIsAvailable}
                                        isInvalid={validator.isEmpty(emailAddress || '') || !validator.isEmail(emailAddress) || !emailIsAvailable}
                                        onChange={e => handleChange(e)} />
                                    <InputGroup.Text className={s.switchContainer}>
                                    <FormCheck type='switch' label='Show?' 
                                        checked={canDisplayEmailAddress || false} 
                                        onChange={e => setAccount({ ..._account, canDisplayEmailAddress: e.target.checked })} />
                                    </InputGroup.Text>
                                </InputGroup>
                                <FormControl.Feedback />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Cell phone (10 digits only)</FormLabel>
                                <InputGroup className={s.switchGroup}>
                                    <FormControl name='cellPhone' value={cellPhone || ''} 
                                        required isValid={!validator.isEmpty(cellPhone || '') && validator.isMobilePhone(cellPhone, 'en-US')}
                                        isInvalid={validator.isEmpty(cellPhone || '') || !validator.isMobilePhone(cellPhone, 'en-US')}
                                        onChange={e => handleChange(e)} />
                                    <InputGroup.Text className={s.switchContainer}>
                                    <FormCheck type='switch' label='Show?' 
                                        checked={canDisplayCellPhone || false} 
                                        onChange={e => setAccount({ ..._account, canDisplayCellPhone: e.target.checked })} />
                                    </InputGroup.Text>
                                </InputGroup>
                                <FormControl.Feedback />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <ConfirmCellPhone />
                        </Col>
                        <Col xs={12}>
                            <FormGroup className={s.formGroup + ' ' + s.saveButtonContainer}>
                                <Button disabled={!profileIsValid()} variant='primary' onClick={() => save()}><FontAwesomeIcon name='check' /> save profile</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <p>Electing to show your email address and/or cell phone 
                                will display either on your profile page to allow other 
                                players in your group to get in touch with you.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className={s.deleteAccountContainer}>
                                <Confirm title={'Delete Your Severed Links Account'} 
                                    onConfirm={() => navigate(`/profile/account-deletion`)}
                                    confirmText='enter account deletion portal'
                                    body={<div><p>Are you sure you want to delete your account?</p><p><small>Please only enter this portal if you no longer want to use our products and services.</small></p></div>}
                                    variant='outline-danger' buttonIcon='exclamation-triangle' buttonText='Delete your account' />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            }
            <NewImageUploadModal show={showNewImageModal} 
                playerId={_id} 
                onClose={() => dispatch(setProfileStateValue({ showNewImageModal: false }))} />
        </div>
    )
}

export default EditProfile