import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Alert, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './NoUserAvatar.scss'

const NoUserAvatar = () => {

    const navigate = useNavigate()
    const imageUrl = useSelector(state => state.account.imageUrl)
    const accountIsFetching = useSelector(state => state.account.isFetching)
    const userIsLoaded = useSelector(state => state.account.userIsLoaded && state.account.isAuthenticated)

    const routeToEditProfile = () => navigate(`/profile/edit`)

    if (!userIsLoaded || (userIsLoaded && imageUrl)) return null
    return (
        <div className={s.container}>
            <Alert className={s.alert} variant='info'>
                <div className={s.content}>
                    <div className={s.icon}><FontAwesomeIcon name='info-circle' size='2x' /></div>
                    <div className={s.text}>You do not have a profile avatar loaded into the site.</div>
                    <div className={s.button}>
                        <Button variant='info' onClick={() => routeToEditProfile()}>
                            <FontAwesomeIcon name='camera' /> update avatar
                        </Button>
                    </div>
                </div>
            </Alert>
        </div>
    )
}

export default NoUserAvatar