import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PlayerTeeBoxOverrides.scss'
import Modal from '@severed-links/common.modal'
import Button from '@severed-links/common.button'
import Badge from '@severed-links/common.badge'

const PlayerTeeBoxOverrides = ({ teeBoxOverrides = [], showNone = false }) => {

    const calculateBadgeStyle = _badge => {
        var _style = {
            ...(_badge.fontColor ? {
                color: `${_badge.fontColor}`,
                color: `${_badge.fontColor}`,
                borderColor: `${_badge.fontColor}`,
                border: `solid 1px ${_badge.fontColor}`,
            } : {}),
            ...(_badge.backgroundColor ? {
                backgroundColor: `${_badge.backgroundColor}`,
            } : {}),     
        }
        return _style
    }

    if (!showNone && !teeBoxOverrides.length) return null
    return (
        <div className={s.container}>

        {teeBoxOverrides && teeBoxOverrides.map(i =>
        <div className={s.badge + ' ' + s.rounded}
            style={calculateBadgeStyle(i)}
            key={`override-round-${i.round}`}>
            <div>{i.round}</div>
        </div>
        )}

        {showNone && !teeBoxOverrides.length ?
        <Badge className={s.badge} variant='dark'>None</Badge>
        : null}

        </div>
    )
}

export default PlayerTeeBoxOverrides
