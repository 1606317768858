import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Outlet } from 'react-router-dom'
import { Row, Col, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getLocationCount, getLocations } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import GlobalAdminLetterBar from './GlobalAdminLetterBar'
import * as s from './GlobalAdminLocations.scss'

const GlobalAdminLocations = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const letterTab = (params.globalAdminLetter || '').toUpperCase()
    const letters = useSelector(state => state.globalAdmin.locations.letters || [])
    const list = (useSelector(state => state.globalAdmin.locations[`${letterTab || 'None'}`]) || {}).list || []

    useEffect(() => {
        dispatch(getLocationCount('Locations'))
    }, [])

    return (
        <div className={s.container}>
            <h3><FontAwesomeIcon name='map-marker' /> Locations</h3>
            <GlobalAdminLetterBar letters={letters} type='location' />
            <Outlet />
        </div>
    )
}

export default GlobalAdminLocations