// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripLodgingAdminToolbar__container___vUsAq{margin-bottom:1rem}.TripLodgingAdminToolbar__container___vUsAq .TripLodgingAdminToolbar__buttonsContainer___uyov2{display:flex;flex-flow:row nowrap;justify-content:center;align-items:center;margin-bottom:1rem}.TripLodgingAdminToolbar__container___vUsAq .TripLodgingAdminToolbar__buttonsContainer___uyov2 .TripLodgingAdminToolbar__button___zOUe0{margin:0rem .5rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Admin/TripLodgingAdminToolbar.scss"],"names":[],"mappings":"AAAA,4CACI,kBAAA,CAEA,+FACI,YAAA,CACA,oBAAA,CACA,sBAAA,CACA,kBAAA,CACA,kBAAA,CAEA,wIACI,iBAAA","sourcesContent":[".container {\n    margin-bottom: 1rem;\n\n    .buttonsContainer {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: center;\n        align-items: center;\n        margin-bottom: 1rem;\n\n        .button {\n            margin: 0rem 0.5rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripLodgingAdminToolbar__container___vUsAq`;
export var buttonsContainer = `TripLodgingAdminToolbar__buttonsContainer___uyov2`;
export var button = `TripLodgingAdminToolbar__button___zOUe0`;
export default ___CSS_LOADER_EXPORT___;
