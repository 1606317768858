import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminPaymentsList.scss'
import moment from 'moment-timezone'
import numeral from 'numeral'
import { FRIENDLY_SHORT_TIME_FORMAT, CURRENCY_FORMAT } from '@severed-links/common.severedlinks-constants'
import PlayerListItem from '../Player/PlayerListItem'
import Modal from '@severed-links/common.modal'
import { prettyPrintJson } from 'pretty-print-json'

const GlobalAdminPaymentsList = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const date = useSelector(state => state.globalAdmin.payments.date)
    const _dateKey = moment(date).format('YYYYMMDD')
    const paymentDate = useSelector(state => state.globalAdmin.payments.dates.find(i => i.dateKey === _dateKey))
    const [showDetail, setShowDetail] = useState(false)
    const [detail, setDetail] = useState(false)
    const payments = (paymentDate || {}).payments || []

    useEffect(() => {
    }, [])

    const doShowDetail = _detail => {
        setDetail(_detail)
        setShowDetail(true)
    }

    const doHideDetail = () => {
        setShowDetail(false)
    }

    return (
        <div className={s.container}>
            <h4>Payments for {moment(date).local().format('MMMM D, YYYY')}</h4>
            <ListGroup className={s.list}>
            {payments && payments.map(i =>
            <PlayerListItem player={i} key={`payment-item-${i.id}`}
                className={s.item}
                leftDetail={<div className={s.leftDetail}>
                    <div className={s.leftContainer}>
                        <FontAwesomeIcon name={i.isCompleted ? 'check' : 'times'} className={s.icon + ' ' + (i.isCompleted ? s.success : s.danger)} />
                    </div>
                    {i.cardIdentifier ?
                    <div className={s.leftContainer} title={i.cardIdentifier || null}>
                        <FontAwesomeIcon name={i.cardIcon} isBrand={i.cardIsBrand} className={s.icon} />
                        <div className={s.text}>{i.cardLast4}</div>
                    </div>
                    : null}
                    <div className={s.leftContainer}>
                        <FontAwesomeIcon name='clock' className={s.icon} />
                        <div className={s.text}>{moment(i.updatedAt).local().format(FRIENDLY_SHORT_TIME_FORMAT)}</div>
                    </div>
                </div>}
                rightDetail={<div className={s.rightDetail}>
                    <div className={s.rightContainer}>
                        <div className={s.heading}>Processing Fee</div>
                        <div className={s.value}>{numeral(i.processingFeeAmount).format(CURRENCY_FORMAT)}</div>
                    </div>
                    <div className={s.rightContainer}>
                        <div className={s.heading}>Amount</div>
                        <div className={s.value}>{numeral(i.amount).format(CURRENCY_FORMAT)}</div>
                    </div>
                </div>}
                onClick={() => doShowDetail(i)} />
            )}
            {!payments.length ?
            <ListGroup.Item className={s.item}>
                No payments located.
            </ListGroup.Item>
            : null}
            </ListGroup>

            <Modal heading='Payment Detail' show={showDetail}
                onClose={() => doHideDetail()} showFooter={false}>
                <div className={s.paymentDetailContainer}>
                    <pre className='json-container' dangerouslySetInnerHTML={{ __html: prettyPrintJson.toHtml(detail, { linkUrls: true, linksNewTab: true }) }} />
                </div>
            </Modal>
        </div>
    )
}

export default GlobalAdminPaymentsList
