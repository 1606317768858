import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Button from '@severed-links/common.button'
import * as s from './TripEventTaskResponder.scss'

const TripEventTaskResponder = ({ eventId = null, taskResponses = [], myResponse = {}, onTaskResponse = null }) => {

    const dispatch = useDispatch()
    const userId = useSelector(state => state.account._id)

    useEffect(() => {
    }, [])

    const handleTaskResponse = _response => {
        if (onTaskResponse) {
            onTaskResponse({ _id: eventId }, _response)
        }
    }

    if (!eventId || !taskResponses?.length) return null
    const _successResponse = taskResponses.find(i => i.variant === 'success')
    const _dangerResponse = taskResponses.find(i => i.variant === 'danger')
    return (
        <div className={s.container}>
        {taskResponses && taskResponses.map(i =>
        <div className={s[`${i.variant}Container`] + ((i.code === _successResponse.code && myResponse.response === _successResponse.code) || (i.code === _dangerResponse.code && myResponse.response === _dangerResponse.code) ? ` ${s.selected}` : ``)} key={`trip-event-${eventId}-responder-${i.variant}`}>
            {i.code !== myResponse.response ? <Button className={s.button + ' ' + s[i.variant]} size='sm' variant={i.variant} icon={i.icon} onClick={() => handleTaskResponse(i.code)} /> : null}
            {i.code === myResponse.response ? <div className={s.icon}><FontAwesomeIcon name={i.icon} /></div> : null}
            {i.code === myResponse.response ? <div className={s.message}>{i.code === _successResponse.code ? 'You have previously marked this task as complete.' : 'This task is incomplete.'}</div> : null}
            {i.code === myResponse.response ? <div className={s.mobileMessage}>{i.code === _successResponse.code ? 'Completed' : 'Incomplete'}</div> : null}
        </div>
        )}
        </div>
    )
}

export default TripEventTaskResponder
