import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { } from 'react-router'
import { Row, Col, Card, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GroupProfileMainHeader.scss'
import numeral from 'numeral'

const GroupProfileMainHeader = ({ city, state, index, skins = 0, proxies = 0, wonLossRecords = [], overallRecord = '0-0', isLoading = false }) => {

    const data = [
        { header: 'Index', info: numeral(index || 0).format('0.0') },
        { header: 'Skins', info: skins },
        { header: 'Proxies', info: proxies },
    ]
    return (
        <div className={s.container}>
            {city || state ?
            <Row>
                <Col xs={12}>
                    <h3>
                        <FontAwesomeIcon name='map-marker' /> {city ? city + (state ? ', ' : '') + (state || '') : (state || '')}
                    </h3>
                </Col>
            </Row>
            : null}
            <Row>
                {!isLoading && data && data.map(i =>
                <Col sm={4} xs={6} key={`group-player-header-${i.header}`}>
                    <div className={s.statTitle}>{i.header}</div>
                    <div className={s.statData}>{i.info}</div>
                </Col>
                )}
            </Row>
            <Row>
                <Col xs={12}>
                    <Card className={s.recordCard}>
                        <Card.Header className={s.recordCardHeader}>
                            <div className={s.title}>Won-Loss Records</div>
                            <div className={s.overallRecord}>{overallRecord}</div>
                        </Card.Header>
                        {wonLossRecords.length ?
                        <ListGroup variant='flush' className={s.recordList}>
                        {wonLossRecords && wonLossRecords.map(i =>
                        <ListGroup.Item className={s.recordItem} key={`record-${i.formatName}`}>
                            <div className={s.formatName}>{i.formatName}</div>
                            <div className={s.formatRecord}>{i.w}-{i.l}{i.t ? `-${i.t}` : ``}</div>
                        </ListGroup.Item>
                        )}
                        </ListGroup>
                        : null}
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default GroupProfileMainHeader