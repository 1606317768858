import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import * as s from './Geolocation.scss'
import { useGeolocated } from 'react-geolocated'
import { geoLocationSuccess, geoLocationError, updateGeoLocationSettings, postUserGeolocation } from '@severed-links/common.severedlinks-reducers/geoLocation'
import moment from 'moment-timezone'

const GeolocationUpdater = ({ 
    show = false, onSuccess, onError, 
}) => {

    const dispatch = useDispatch()
    const [isSaving, setIsSaving] = useState(false)
    const [saveIntervalSeconds, setIntervalSeconds] = useState(60)
    const geoLocationLocalState = useSelector(state => state.geoLocation)
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)

    const { coords, isGeolocationAvailable, isGeolocationEnabled, timestamp, positionError } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: Infinity,
        },
        userDecisionTimeout: 5000,
    });


    useEffect(() => {
        updateGeoSettings()
        updateLocationCheck()
    }, [])
    useEffect(() => updateLocationCheck(), [geoLocationLocalState.lastUpdate])
    useEffect(() => updateGeoSettings(), [isGeolocationAvailable, isGeolocationEnabled])
    useEffect(() => {
        if (coords){
            dispatch(geoLocationSuccess({ coords, timestamp })) 
        }
    }, [coords, timestamp])
    useEffect(() => {
        if (positionError) {
            dispatch(geoLocationError(positionError))
        }
    }, [positionError])

    const updateGeoSettings = () => {
        dispatch(updateGeoLocationSettings({ isGeolocationAvailable, isGeolocationEnabled }))
    }

    const updateLocationCheck = () => {
        if (isAuthenticated && isGeolocationAvailable && isGeolocationEnabled) {
            const lastUpdate = moment(geoLocationLocalState.lastUpdate)
            const lastServerUpdate = moment(geoLocationLocalState.lastServerUpdate)
            const elapsedTime = lastUpdate.diff(lastServerUpdate, 'seconds')
            const savePosition = { coords: { ...geoLocationLocalState, secondsSinceLastUpdate: elapsedTime }, timestamp }
            if (elapsedTime > saveIntervalSeconds && !isSaving && geoLocationLocalState.latitude !== 0.0000 && geoLocationLocalState.longitude !== 0.0000) {
                setIsSaving(true)
                dispatch(postUserGeolocation(savePosition))
                .then(() => setIsSaving(false))
            } else if (elapsedTime !== geoLocationLocalState.secondsSinceLastUpdate) {
                dispatch(updateGeoLocationSettings({ isGeolocationAvailable, isGeolocationEnabled, secondsSinceLastUpdate: elapsedTime }))
            }
        }
    }

    return (
        <div className={s.container} title='Geolocation module'>
            {show ? <pre style={{ whiteSpace: 'normal' }}>{JSON.stringify(geoLocationLocalState)}</pre> : null}
        </div>
    )
}

export default GeolocationUpdater