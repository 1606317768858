import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useNavigate } from 'react-router'
import { ListGroup, FormGroup, FormControl, FormLabel, 
    InputGroup, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Alert from '@severed-links/common.alert'
import PlayerListItem from '../../Player/PlayerListItem'
import * as s from './TripPlayerTeamAndHcpAssignment.scss'
import { commonImageUrl } from '@severed-links/common.severedlinks-constants'
import Confirm from '@severed-links/common.confirm'
import { getPlayerLists, removePlayerFromList, addPlayerToList, 
    updatePlayerHandicap, getHandicapChecker, checkRsvpStatusForPlayer,
    getMatchesScoreboard, getMedalistScoreboard, getAdminMatches } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import validator from 'validator'
import numeral from 'numeral'
import pluralize from 'pluralize'
import { CURRENCY_FORMAT } from '@severed-links/common.severedlinks-constants'
import Modal from '@severed-links/common.modal'
import Button from '@severed-links/common.button'
import Badge from '@severed-links/common.badge'
import CreditCardPaymentForm from '../../CreditCard/CreditCardPaymentForm'
import SquareCreditCardInitializer from '../../CreditCard/SquareCreditCardInitializer'
import PlayerHandicap from '../../Player/PlayerHandicap'

const AssignmentLabel = ({ isUsa, isEur, isUnassigned, isNotAttending, isNotListed, usaTeamName = 'USA', eurTeamName = 'Europe' }) => {
    const variant = isUsa ? 'danger' : isEur ? 'info' : isUnassigned ? 'secondary' : isNotAttending ? 'warning' : 'light'
    return (
        <Badge className={s.assignmentLabel} bg={variant} text={variant === 'light' || variant === 'warning' ? 'dark' : 'light'}>
            {isUsa ? usaTeamName : null}
            {isEur ? eurTeamName : null}
            {isUnassigned ? 'None' : null}
            {isNotAttending ? 'Not attending' : null}
            {isNotListed ? <FontAwesomeIcon name='question' /> : null}
        </Badge>
    )
}

const handicapCheckerVariant = (hcp = 0, hc = { calculatedTripHcp: 0 }) => {
    const _diff = Math.abs(hcp - hc.calculatedTripHcp)
    return _diff > 1 ? 'danger' : _diff === 1 ? 'warning' : null
}

const TripPlayerTeamAndHcpAssignment = ({ group = {}, trip = {}, player = {}, onClose }) => {

    // added comment for forced commit
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [hcp, setHcp] = useState('0')
    const [isCheckingRegistrationFeePriorPayment, setCheckingRegistrationFeePriorPayment] = useState(false)
    const [hasCheckedRegistrationFeePriorPayment, setHasCheckedRegistrationFeePriorPayment] = useState(false)
    const [isSavingHcp, setSavingHcp] = useState(false)
    const [hcpChanged, setHcpChanged] = useState(false)
    const [showEntryFeePayment, setShowEntryFeePayment] = useState(false)
    const [rsvpStatus, setRsvpStatus] = useState({})
    const [showHcpEditor, setShowHcpEditor] = useState(false)
    const [showTeamAssignment, setShowTeamAssignment] = useState(false)
    const [isSavingTeamAssignment, setSavingTeamAssignment] = useState(false)
    const isUsa = (trip.usaPlayers || []).some(i => i.playerID === player.playerID)
    const isEur = (trip.eurPlayers || []).some(i => i.playerID === player.playerID)
    const isUnassigned = (trip.unassignedPlayers || []).some(i => i.playerID === player.playerID)
    const isAssignable = isUsa || isEur || isUnassigned
    const isNotAttending = (trip.notAttendingPlayers || []).some(i => i.playerID === player.playerID)
    const isNotListed = !isUsa && !isEur && !isUnassigned && !isNotAttending

    const rsvpTokensPurchased = (trip || {}).rsvpTokensPurchased || 0
    const rsvpTokensUsed = (trip || {}).rsvpTokensUsed || 0
    const rsvpTokensUsedBy = (trip || {}).rsvpTokensUsedBy || []
    const hasAlreadyRsvpWithToken = rsvpTokensUsedBy.some(i => i === player.playerId)
    const rsvpTokensAvailable = rsvpTokensPurchased - rsvpTokensUsed
    const canPayWithRsvpToken = (rsvpTokensAvailable > 0) || hasAlreadyRsvpWithToken

    useEffect(() => {
        setHasCheckedRegistrationFeePriorPayment(false)
        getRsvpStatus()
    }, [])

    useEffect(() => getRsvpStatus(), [(player || {}).hcp || 0])

    const getRsvpStatus = () => {
        setHcp(`${player.hcp || 0}`)
        setCheckingRegistrationFeePriorPayment(true)
        dispatch(checkRsvpStatusForPlayer(group._id, trip._id, player.playerID))
        .then(action => {
            setRsvpStatus(action.payload)
            setHasCheckedRegistrationFeePriorPayment(true)
            setCheckingRegistrationFeePriorPayment(false)
        })
    }

    const deletePlayerFromTrip = id => {
    }

    const changeHcp = offset => {
        const newHcp = !isNaN(hcp) ? parseInt(hcp) + offset : parseInt(hcp)
        setHcp(`${newHcp}`)
        setHcpChanged(true)
    }

    const checkPaymentOfEntryFee = () => {
        if (hasCheckedRegistrationFeePriorPayment) {
            if (trip.registrationFee && !rsvpStatus.isPaid) {
                setShowEntryFeePayment(true)
            } else {
                doRsvpCommand('None')
            }
        }
    }

    const moveIntoTripUsingToken = () => {
        doRsvpCommand('None', true)
    }

    const onSuccessfulEntryFeePayment = () => {
        doRsvpCommand('None')
        setShowEntryFeePayment(false)
        checkPaymentOfEntryFee()
        setTimeout(() => checkPaymentOfEntryFee(), 1500)
    }

    const doRsvpCommand = (team, useToken = false) => {
        if (team === 'Delete RSVP' && (!player.team || player.team === 'None')) {
            setSavingTeamAssignment(true)
            dispatch(removePlayerFromList(group._id, trip._id, player.playerID, team))
            .then(() => setSavingTeamAssignment(false))
        } else if (team && team !== player.team) {
            setSavingTeamAssignment(true)
            dispatch(addPlayerToList(group._id, trip._id, player.playerID, team, useToken))
            .then(() => setSavingTeamAssignment(false))
        }
        setShowTeamAssignment(false)
        if (onClose) {
            onClose()
        }
    }

    const saveHcp = () => {
        const _hcp = parseInt(hcp)
        setShowHcpEditor(false)
        setSavingHcp(true)
        dispatch(updatePlayerHandicap(group._id, trip._id, player.playerID, _hcp))
        .then(action => {
            setHcpChanged(true)
            setTimeout(() => setSavingHcp(false), 1000)
            dispatch(createNotification({ headline: 'Save player handicap', type: 'success', message: `Set ${player.firstName} ${player.lastName} trip handicap to ${hcp}. Any matches that this player is assigned to may take 2-3 minutes to update before the strokes are reflected on the scorecard.`, timeout: 4000 }))
        })
    }

    const { wonLossRecords, wonLossPct, wonLossPctDisplay } = player
    const _teamAssignmentProps = !isSavingTeamAssignment ? {
        show: true, size: 36, rounded: true,
        source: isUsa ? { uri: trip.usaFullTeamImageUrl }
            : isEur ? { uri: trip.eurFullTeamImageUrl }
            : null,
        icon: { ...(isUnassigned ? { name: 'question-circle', variant: 'warning' }
            : isNotAttending ? { name: 'ban', variant: 'danger' }
            : isNotListed ? { name: 'users', variant: 'secondary' }
            : {}),
            size: 32,
        },
        size: 36,
        containerStyle: { variant: isUnassigned ? 'warning' : isNotAttending ? 'danger' : isNotListed ? 'info' : null },
    } : {}
    const _teamAssignment = isUsa ? trip.usaTeamName : isEur ? trip.eurTeamName : isUnassigned ? 'Unassigned' : isNotAttending ? 'Not attending' : ''
    const _hcpVariant = handicapCheckerVariant(player.hcp, player.handicapChecker)
    const _editorVariant = handicapCheckerVariant(hcp, player.handicapChecker) || 'success'
    return (
        <>

            {isUsa || isEur || isUnassigned ?
            <ListGroup.Item className={s.item + (_hcpVariant ? ` ${s[_hcpVariant]}` : ``)}
                action onClick={() => setShowHcpEditor(true)}>
                <div className={s.content}>
                    <div className={s.title}>Assigned trip handicap</div>
                    <div className={s.subtitle + (_hcpVariant ? ` ${s.inherit}` : ``)}>Current index: {(player.currentIndex || 0).toFixed(1)}</div>
                </div>
                <div className={s.value}>
                    {!isSavingHcp ? 
                    <PlayerHandicap {...player} variant={_hcpVariant} />
                    :
                    <FontAwesomeIcon name='circle-notch' className={s.spinner + (_hcpVariant ? ` ${s.inherit}` : ``)} spin />}
                </div>
                <div className={s.clickIcon + (_hcpVariant ? ` ${s.inherit}` : ``)}>
                    <FontAwesomeIcon name='chevron-right' />
                </div>
            </ListGroup.Item>
            : null}

            <ListGroup.Item className={s.item} action onClick={() => setShowTeamAssignment(true)}>
                <div className={s.content}>
                    <div className={s.title}>Team assignment</div>
                    {_teamAssignment ? <div className={s.subtitle}>{_teamAssignment}</div> : null}
                </div>
                <div className={s.value + ' ' + s.teamAssignmentContainer + (!isSavingTeamAssignment && _teamAssignmentProps.icon?.variant ? ` ${s[_teamAssignmentProps.icon.variant]}` : ``)} style={{ height: `${_teamAssignmentProps.size / 16}rem`, width: `${_teamAssignmentProps.size / 16}rem`, ...(!isSavingTeamAssignment && _teamAssignmentProps.source?.uri ? { backgroundImage: `url(${_teamAssignmentProps.source.uri})` } : {}) }}>
                    {!isSavingTeamAssignment && _teamAssignmentProps.icon?.name ? <FontAwesomeIcon name={_teamAssignmentProps.icon.name} style={{ color: _teamAssignmentProps.icon?.color || '#333' }} /> : null}
                    {isSavingTeamAssignment ? <FontAwesomeIcon name='circle-notch' className={s.spinner} spin /> : null}
                </div>
                <div className={s.clickIcon + (player.isAssignedToMatch ? ` ${s.locked}` : ``)}>
                    <FontAwesomeIcon name={player.isAssignedToMatch ? 'lock' : 'chevron-right'} />
                </div>
            </ListGroup.Item>

            <Modal show={showHcpEditor} onClose={() => setShowHcpEditor(false)}
                heading={'Update Player Handicap'}
                actionButtonText={!isSavingHcp ? 'save handicap' : 'saving...'}
                actionButtonIcon={!isSavingHcp ? 'flag' : 'circle-notch'}
                actionButtonIconSpin={isSavingHcp}
                actionButtonStyle={hcpChanged && !isSavingHcp ? 'primary' : 'light'}
                actionButtonDisabled={!hcpChanged || isSavingHcp}
                actionButtonOnClick={() => saveHcp()}>
            <div className={s.tripTeamAssignment}>
                <ListGroup>
                    <PlayerListItem player={player} clickDisabled showRole={false} showHcp={false} />
                    <div className={s.viewProfile}><Button size='sm' variant='link' icon='pencil-alt' title='view profile'
                        onClick={() => navigate(`/group/${group._id}/player/${player.playerID}/edit`)} />
                    </div>
                </ListGroup>
                
                {isAssignable ? 
                <FormGroup className={s.hcp} size='lg'>
                    <FormLabel className={s.label}>Trip Handicap</FormLabel>
                    <InputGroup className={s.group}>
                        <Button variant='light' size='lg' onClick={() => changeHcp(-1)} icon='minus' />
                        <FormControl value={hcp} size='lg' 
                            onChange={e => { setHcp(e.target.value); setHcpChanged(true) }}
                            className={s.textbox + (_editorVariant ? ` ${s[_editorVariant]}` : ``)} />
                        <Button variant='light' size='lg' onClick={() => changeHcp(1)} icon='plus' />
                    </InputGroup>
                </FormGroup>
                : null}
            </div>

            </Modal>

            <Modal show={showTeamAssignment} showFooter={false}
                heading={<div>Team Assignment <AssignmentLabel isUsa={isUsa} isEur={isEur} 
                isUnassigned={isUnassigned} isNotAttending={isNotAttending}
                usaTeamName={trip.usaTeamName} eurTeamName={trip.eurTeamName}
                isNotListed={isNotListed} /></div>} onClose={() => setShowTeamAssignment(false)}>
            <div className={s.tripTeamAssignment}>
            {!player.isAssignedToMatch ?
                <FormGroup className={s.assignment}>
                    <div className={s.buttonsContainer}>
                        {isAssignable ? 
                        <>
                            <Button size='lg' onClick={() => doRsvpCommand('USA')} disabled={isUsa} variant='danger' className={s.button} url={trip.usaFullTeamImageUrl} title={trip.usaTeamName} />
                            <Button size='lg' onClick={() => doRsvpCommand('EUR')} disabled={isEur} variant='info' className={s.button} url={trip.eurFullTeamImageUrl} title={trip.eurTeamName} />
                        </>
                        : null}
                        {!isUnassigned && isAssignable ? <Button size='lg' variant='warning' onClick={() => doRsvpCommand('None')} disabled={isUnassigned} className={s.button} icon='question-circle' title='Unassign' /> : null}
                        {!isUnassigned && !isAssignable && hasCheckedRegistrationFeePriorPayment ? 
                        <>
                            <Button size='lg' variant='primary' onClick={() => checkPaymentOfEntryFee()} disabled={isUnassigned} className={s.button} icon='arrow-right' title={`Move into trip${trip.registrationFee && !rsvpStatus.isPaid ? ` (pay ${numeral(trip.registrationFee).format(CURRENCY_FORMAT)})` : ''}`} />

                            {canPayWithRsvpToken && !hasAlreadyRsvpWithToken ?
                            <>
                            <Alert variant={'info'} small className={s.tokenAlert}
                                message={`You also have ${rsvpTokensAvailable} of ${rsvpTokensPurchased} pre-purchased ${pluralize('token', rsvpTokensPurchased, false)} available for use`}
                                controls={<Button size='sm' variant='primary' onClick={() => moveIntoTripUsingToken()} disabled={isUnassigned || !rsvpTokensAvailable} className={s.button} icon='arrow-right' title={`Use token`} />} />
                            </>
                            : null}
                        </>
                        : null}
                        {!isNotAttending ? <Button size='lg' variant='danger' onClick={() => doRsvpCommand('Not Coming')} className={s.button} icon='ban' title='Not attending' /> : null}
                        {isNotAttending || isUnassigned ?
                        <Confirm title={'Delete Player RSVP'} enforceFocus={false}
                            onConfirm={() => doRsvpCommand('Delete RSVP')}
                            confirmText='delete player from trip'
                            body={<div><p>Are you sure you want to delete this player's RSVP for this trip?</p></div>}
                            variant='danger' size='lg' buttonClassName={s.button}
                            buttonText='Delete RSVP'
                            buttonIcon='trash' />
                        : null}

                    </div>
                    {showEntryFeePayment ? <SquareCreditCardInitializer /> : null}
                    <Modal show={!isCheckingRegistrationFeePriorPayment && showEntryFeePayment} showFooter={false}
                        heading={`Pay Trip RSVP Fee`}
                        onClose={() => setShowEntryFeePayment(false)}>
                        <div>
                            <p>In order to move this player into this trip, you will need to pay the entry fee for use of 
                                the site of {numeral(trip.registrationFee).format(CURRENCY_FORMAT)}. 
                            </p>
                            <CreditCardPaymentForm amount={trip.registrationFee}
                                chargeType={rsvpStatus.registrationFeePaymentKey} 
                                playerIdOverride={player.playerID}
                                groupId={group._id} tripId={trip._id}
                                onSuccess={onSuccessfulEntryFeePayment} />
                        </div>
                    </Modal>            
                </FormGroup>
                : <Alert variant='warning' message='The team for this player cannot be changed until removed from any assigned matches.' />}

            </div>  
            </Modal>


        </>
    )
}

export default TripPlayerTeamAndHcpAssignment