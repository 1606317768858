import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Row, Col, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TripRounds from './TripRounds'
import TripWeather from './TripWeather'
import TripLodging from './TripLodging'
import { getMyMatches, getWeather, getRoomAssignments, setTripValue } from '@severed-links/common.severedlinks-reducers/groups'
import MvpAwardVoter from './MvpAwardVoter'
import * as s from './TripDetails.scss'
import TripInactiveAlert from './TripInactiveAlert'
import TripRsvpPanel from './TripRsvpPanel'
import TripNotes from './TripNotes'
import TripEventNextList from './TripEventNextList'
import MyNextTeeTime from './MyNextTeeTime'
import NearByPlayersListItem from './NearByPlayersListItem'
import MyTripPayout from './MyTripPayout'
import moment from 'moment-timezone'

const TripDetails = () => {

    const dispatch = useDispatch()
    const { group, groupId, trip, tripId } = useOutletContext()
    const [isFetchingWeather, setFetchingWeather] = useState(false)
    const [lastWeatherCheck, setLastWeatherCheck] = useState(null)
    const [currentTime, setCurrentTime] = useState(null)
    const _tripZip = (trip.location || {}).locZip || null
    const _weatherCheckMinutesThreshhold = 15
    const tripSettings = trip.settings || {}
    const showRsvpPanel = tripSettings.canPlayersRsvp && !tripSettings.isTripAtCapacity
    const chatIsOpen = trip.showChat

    useEffect(() => {
        if (group._id && trip._id) {
            dispatch(getMyMatches(group._id, trip._id))
            dispatch(getRoomAssignments(group._id, trip._id))
        }
        setCurrentTime(moment().toISOString(true))
        let timerId = setInterval(() => tick(), 10 * 1000)
        return () => clearInterval(timerId)
    }, [])

    useEffect(() => fetchWeather(), [_tripZip])

    useEffect(() => {
        const _elapsedMinutesSinceLastWeatherCheck = moment(currentTime).diff(lastWeatherCheck || moment().toISOString(true), 'minute', true)
        if (!isFetchingWeather && _elapsedMinutesSinceLastWeatherCheck > _weatherCheckMinutesThreshhold) {
            fetchWeather()
        }
    }, [currentTime])

    const tick = () => setCurrentTime(moment().toISOString(true))

    const fetchWeather = () => {
        if (!isFetchingWeather && trip._id && _tripZip) {
            setFetchingWeather(true)
            dispatch(getWeather(group._id, trip._id, _tripZip))
            .then(_action => {
                setFetchingWeather(false)
                setLastWeatherCheck(moment().toISOString(true))
            })
        }
    }

    if (!trip || !group) return null
    return (
        <div className={s.container}>
            <TripInactiveAlert trip={trip} />
            <TripRsvpPanel group={group} trip={trip} show={showRsvpPanel} />
            <MvpAwardVoter group={group} trip={trip} />
            <TripEventNextList group={group} trip={trip} />
            <MyNextTeeTime group={group} trip={trip} />
            <MyTripPayout groupId={groupId} tripId={tripId} payout={trip.payout} isTripComplete={trip.isComplete} />
            <NearByPlayersListItem group={group} trip={trip} />
            <TripNotes notes={trip.notes} />
            <Row>
                <Col md={chatIsOpen ? 12 : 4} sm={12}>
                    <TripLodging groupId={group._id} tripId={trip._id} isAdmin={false}
                        tripLocation={trip.location || {}} 
                        showRoomAssignmentModal={trip.showRoomAssignmentModal || false}
                        roomAssignments={(trip.roomAssignments || {}).rooms || []} />
                </Col>
                <Col md={chatIsOpen ? 12 : 8} sm={12}>
                    <TripWeather city={(trip.location || {}).locCity || ''} 
                        state={(trip.location || {}).locState || ''}
                        weather={trip.weather} 
                        beginDate={trip.beginDate} />
                </Col>
            </Row>
            <TripRounds groupId={group._id} tripId={trip._id} tripName={trip.tripName} 
                rounds={trip.rounds} myMatches={trip.myMatches} />
        </div>
    )
}

export default TripDetails