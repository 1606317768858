import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation, Outlet, useOutletContext } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { saveEvent, deleteEvent } from '@severed-links/common.severedlinks-reducers/groups'
import * as s from './TripEventEditor.scss'
import moment from 'moment-timezone'
import FormCheck from 'react-bootstrap/FormCheck'
import { FRIENDLY_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { countBy, sumBy, isEqual, startsWith, endsWith, orderBy, omit, pick } from 'lodash'
import validator from 'validator'
import Confirm from '@severed-links/common.confirm'
import pluralize from 'pluralize'
import TripEventIcon from '../TripEventIcon'
import Modal from '@severed-links/common.modal'
import Badge from '@severed-links/common.badge'
import Button from '@severed-links/common.button'
import Alert from '@severed-links/common.alert'
import Picker from '@severed-links/common.picker'
import { capitalCase } from 'change-case'
import TripEventEditorMiniBadge from './TripEventEditorMiniBadge'

const TripEventEditor = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const { group, groupId, trip, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const editEventId = params.editEventId || null
    const newEventType = params.newEventType || null
    const isNew = !!newEventType
    const _isRoot = endsWith(location.pathname, isNew ? `/${newEventType}` : `/${editEventId}`)
    const [isLoaded, setLoaded] = useState(false)
    const [isSaving, setSaving] = useState(false)
    const [_event, setEvent] = useState({ _id: null, timezone: { timeZoneId: 'America/New_York' } })
    const [_eventUpdate, setEventUpdate] = useState({})

    const event = (trip.events || []).find(i => i._id === editEventId) || {}
    const eventsAreLoaded = trip?.eventsAreLoaded || false
    const eventsLastUpdated = trip?.eventsLastUpdated || null
    const tripPlayerIds = [
        ...(trip.usaPlayers || []),
        ...(trip.eurPlayers || []),
        ...(trip.unassignedPlayers || []),
    ].map(i => `${i.playerId}`)

    const { _id, title, description, startDay, startTime, endTime, locationAddress, 
        geocodedAddress, showRsvpControls, limitRsvpToTripPlayers, sendReminders, isAllDayEvent, isPublishedPoll, reminderMinutes, 
        dateLastReminderSent, externalUrl, responses, taskVariant, taskIcon, taskResponses,
        pollOptions, isVisible, timezone, isTaskEvent, isPollEvent, timeZoneId, 
        reminderVariant, reminderIcon, reminderMessage, taskCompletionStatus, pollVoteCount,
        shortLink, isDirty, tripTimezone, tripName, rsvpCounts, eventType, totalResponses,
        hasIncompleteTasks, eventTypeCapital, eventIcon, 
    } = _event
    const _fieldsToOmitFromSave = ['isLoaded','isDirty','startDay','enteredBy','addPollOption']
    const showGeoMarker = !validator.isEmpty(geocodedAddress || '')

    const _groupPlayers = group.players || []
    const _tripPlayers = [...(trip.usaPlayers || []),...(trip.eurPlayers || []),...(trip.unassignedPlayers || [])]
    const addRsvpPlayers = (limitRsvpToTripPlayers ? _tripPlayers : _groupPlayers)
        .filter(i => !(responses || []).map(r => r.playerId).includes(i.playerId))
    
    useEffect(() => { initializeState() }, [eventsAreLoaded, isNew, event.isTaskEvent, event.isPollEvent, event.updatedAt, (event.responses || []).length, (event.responses || []).filter(r => r.response === 10).length, (event.responses || []).filter(r => r.response === 11).length, (event.responses || []).filter(r => r.response === 12).length, (event.responses || []).filter(r => r.response === 1).length, (event.responses || []).filter(r => r.response === 2).length, (event.responses || []).filter(r => r.response === 3).length])
   
    useEffect(() => {
        if (_eventUpdate) {
            setEvent({ ..._event, ..._eventUpdate })
        }
    }, [_eventUpdate])
    useEffect(() => {
        if (_isRoot && isNew) {
            navigate(`${location.pathname}/details`)
        }
    }, [isNew, _isRoot])

    const handleEventChange = (_update = {}, isDirty = true) => {
        setEventUpdate({ ..._update, isDirty })
    }

    const initializeState = () => {

        if (!eventsAreLoaded) return

        const _timeZone = event.timezone || tripTimezone || { timeZoneId: 'America/New_York' }

        const startDay = event.startTime && moment(event.startTime).isValid() ?
            moment.tz(event.startTime, _timeZone.timeZoneId).startOf('day').toISOString(true)
            :
            moment.tz(_timeZone.timeZoneId).startOf('day').toISOString(true)

        const startTime = event.startTime && moment(event.startTime).isValid() ?
            moment.tz(event.startTime, _timeZone.timeZoneId).toISOString(true)
            :
            moment.tz(_timeZone.timeZoneId).startOf('day').toISOString(true)

        const endTime = event.endTime && moment(event.endTime).isValid() ?
            moment.tz(event.endTime, _timeZone.timeZoneId).toISOString(true)
            :
            moment.tz(startTime, _timeZone.timeZoneId).endOf('day').toISOString(true)

        var _eventUpdate = { 
            _id: null, 
            eventType: isNew && newEventType ? newEventType.toLowerCase() : event.isTaskEvent ? 'task' : event.isPollEvent ? 'poll' : 'event',
            isAllDayEvent: event.isTaskEvent || event.isPollEvent || false, 
            isTaskEvent: (isNew && newEventType && newEventType.toLowerCase() === 'task') || (event.isTaskEvent || false), 
            isPollEvent: (isNew && newEventType && newEventType.toLowerCase() === 'poll') || (event.isPollEvent || false), 
            isPublishedPoll: false, pollOptions: [],
            responses: [], externalUrl: '', 
            sendReminders: false, reminderMinutes: 0,
            title: '', description: '', 
            locationAddress: '', 
            geocodedAddress: '',
            isVisible: false,
            showRsvpControls: false,
            limitRsvpToTripPlayers: true, 
            ...event, 
            timezone: _timeZone,
            startDay, 
            startTime, 
            endTime,
            isDirty: false,
        }
        _eventUpdate.eventTypeCapital = capitalCase(_eventUpdate.eventType)
        handleEventChange(_eventUpdate, false)
        setLoaded(true)
    }

    const closeEditor = () => {
        if (_isRoot || isNew) {
            navigate(`/group/${groupId}/trip/${tripId}/admin/events`)
        } else {
            navigate(`/group/${groupId}/trip/${tripId}/admin/events/edit/${_id}`)
        }
    }

    const doSaveEvent = () => {
        const postData = omit(_event, _fieldsToOmitFromSave)
        setSaving(true)
        dispatch(saveEvent(groupId, tripId, postData))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
            setSaving(false)
            if (action.payload.messageType === 'success') {
                closeEditor()
            }
        })
    }

    const doDeleteEvent = _id => {
        dispatch(deleteEvent(groupId, tripId, _id))
        closeEditor()
    }

    const eventIsValid = () => {
        const start = moment.tz(startTime, timeZoneId) 
        const end = moment.tz(endTime, timeZoneId)
        return !validator.isEmpty(title || '') 
            && start.isValid() && end.isValid()
            && end.isSameOrAfter(start, 'minute')
            && (!isPollEvent || (pollOptions.length >= 2))
    }

    const _items = (isLoaded && eventsAreLoaded ? [
        { key: `details`, icon: `info-circle`, title: <span><b>{eventTypeCapital} details: </b> {title}</span>, subtitle: <><p>{description}</p>{!isTaskEvent && !isPollEvent && locationAddress ? <p><b>{showGeoMarker ? <FontAwesomeIcon name='map-marker' className={s.geoMarkerIcon} /> : null}Address</b>: {locationAddress}</p> : null}{externalUrl ? <p className={s.externalUrlParagraph}><b>Link</b>: {externalUrl}</p> : null}<p className={s.visibilityParagraph + (!isVisible ? ` ${s.notVisible}` : ``)}>{eventTypeCapital} is {!isVisible ? 'not ' : ''}visible to players</p></>, path: 'details' },
        { key: `date`, icon: `calendar`, title: `${isTaskEvent || isPollEvent ? `Response deadline:  ` : ''}${startDay && moment(startDay).isValid() ? `${moment.tz(startDay, timeZoneId).format('MMMM D, YYYY')}` : `[Invalid Date]`}`, subtitle: !isTaskEvent && !isPollEvent ? `${isAllDayEvent ? `All day event` : `${moment.tz(startTime, timeZoneId).format('h:mm A')}-${moment.tz(endTime, timeZoneId).format('h:mm A')}`}` : null, path: `date` },
        !isTaskEvent && !isPollEvent ? { key: `rsvp`, icon: `pencil-alt`, title: `Allow RSVPs?`, subtitle: `Allow players to RSVP for this event`, rightComponent: <FormCheck type='switch' checked={showRsvpControls || false} name='showRsvpControls' onChange={e => handleEventChange({ showRsvpControls: e.target.checked })} /> } : null,
        !isTaskEvent && !isPollEvent && showRsvpControls ? { key: `rsvp-limit`, icon: `door-closed`, title: `Limit RSVP to trip players?`, subtitle: `Decide who can RSVP for this event`, rightComponent: <FormCheck type='switch' checked={limitRsvpToTripPlayers || false} name='limitRsvpToTripPlayers' onChange={e => handleEventChange({ limitRsvpToTripPlayers: e.target.checked })} /> } : null,
        !isTaskEvent && !isPollEvent && showRsvpControls ? { key: `rsvps`, icon: `envelope-open-text`, title: `RSVPs`, rightComponent: <div className={s.rsvpCountContainer}>{rsvpCounts && rsvpCounts.filter(i => i.count).map(i => <TripEventEditorMiniBadge {...i} key={`rsvp-badge-item-${i.title}`} />)}</div>, path: 'rsvps' } : null,
        isTaskEvent && !isPollEvent ? { key: `taskResponses`, icon: `clipboard-list`, title: `Task responses`, subtitle: reminderMessage, rightComponent: <div className={s.rsvpCountContainer}>{taskResponses && taskResponses.filter(i => i.count).map(i => <TripEventEditorMiniBadge {...i} key={`task-response-badge-item-${i.title}`} />)}</div>, path: 'task-responses' } : null,
        isPollEvent ? { key: 'pollOptions', icon: 'rectangle-list', title: `Poll options`, subtitle: `${pollOptions.length ? `${orderBy(pollOptions, ['pct','optionText'], ['desc','asc']).map(i => `${i.optionText}${i.pct ? ` ${i.count ? ` (${pluralize('vote', i.count, true)}, ${i.pct}%` : ``})` : ` (No votes)`}`).join(', ')}` : `No options created for this poll.`}`, rightComponent: pollVoteCount ? <div className={s.pollOptionsVoteCountContainer}><TripEventEditorMiniBadge count={pollVoteCount} suffix={pluralize('vote', pollVoteCount, false)} /></div> : null, mobileWrapRightContainer: true, path: 'poll-options' } : null,
        isPollEvent ? { key: 'publishPollResults', icon: 'person-booth', title: `Publish poll results?`, subtitle: `Make the results of the poll visible to your players`, rightComponent: <FormCheck type='switch' checked={isPublishedPoll || false} name='isPublishedPoll' onChange={e => handleEventChange({ isPublishedPoll: e.target.checked })} /> } : null,
        { key: `messaging`, variant: 'secondary', icon: `envelope`, title: `Send message`, subtitle: `Send a message about this ${eventType}`, path: 'messaging' },
        { key: `shortLink`, variant: 'secondary', icon: `link`, title: `Event short link`, subtitle: shortLink, rightComponent: <Button variant='secondary-outline' onClick={() => navigator.clipboard.writeText(shortLink)} icon='copy' /> },
    ] : []).filter(i => i)

    return (
        <div className={s.container}>

            <div className={s.headingContainer}>
                <div className={s.goBack}>
                    <Button variant='light' onClick={() => closeEditor()} icon='chevron-left' />
                </div>
                {eventType ? <TripEventIcon icon={eventIcon} isPollEvent={isPollEvent} isTaskEvent={isTaskEvent} /> : null}
                <h4 className={s.title}>{isNew && eventType ? 'Add New' : eventType ? 'Edit' : ''}{' '}{capitalCase(eventType || '')}</h4>
                {_isRoot && eventType ?
                <div className={s.controls}>
                    {!isNew ? 
                    <Confirm title={`Delete ${isTaskEvent ? 'task' : isPollEvent ? 'poll' : 'event'}`}
                        onConfirm={() => doDeleteEvent(_id)} enforceFocus={false}
                        confirmText={`delete ${eventType}`}
                        body={<div><p>Are you sure you want to delete this {eventType}?</p></div>}
                        variant='danger' buttonIcon='trash' buttonClassName={s.button} />
                    : null}
                    <Button className={s.button} variant='primary' disabled={!eventIsValid() || isSaving} icon={!isSaving ? 'check' : 'circle-notch'} iconSpin={isSaving} onClick={() => doSaveEvent()} title={!isSaving ? `save ${isTaskEvent ? 'task' : isPollEvent ? 'poll' : 'event'}` : 'saving...'} />
                </div>
                : null}
            </div>

            {_isRoot && eventType ? <Alert variant={isDirty ? 'warning' : 'info'} icon={isDirty ? 'exclamation-circle' : 'info-circle'} small message={isDirty ? `Make sure to hit the save ${eventType} button to save your changes.` : `Update your ${eventType} details here.`} /> : null}

            {_isRoot && eventType ?
            <ListGroup className={s.list}>
            {_items && _items.map(i =>
            <ListGroup.Item className={s.item + (i.variant ? ` ${s[i.variant]}` : '')}
                action={!!i.path} onClick={i.path ? () => navigate(`${location.pathname}/${i.path}`) : null}
                key={`trip-event-${_id}-item-${i.key}`}>
                <div>
                <FontAwesomeIcon className={s.icon} name={i.icon} isRegular={i.isRegular} fixedWidth size='2x' />
                </div>
                <div className={s.content}>
                    {i.title ? <div className={s.title}>{i.title}</div> : null}
                    {i.subtitle ? <div className={s.subtitle}>{i.subtitle}</div> : null}
                    {i.rightComponent ? <div className={s.rightContainer + ' ' + s.mobileRightContainer + (i.mobileWrapRightContainer ? ` ${s.mobileWrap}` : ``)}>{i.rightComponent}</div> : null}
                </div>
                {i.rightComponent ? <div className={s.rightContainer + (i.mobileWrapRightContainer ? ` ${s.mobileWrap}` : ``)}>{i.rightComponent}</div> : null}
                {i.path ? <FontAwesomeIcon name='chevron-right' /> : null}
            </ListGroup.Item>
            )}
            </ListGroup>
            : null}

            <Outlet context={{ group, groupId, trip, tripId, tripName, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath, ..._event, isNew, tripPlayerIds, eventsLastUpdated, addRsvpPlayers, onChange: handleEventChange }} />
        </div>
    )
}

export default TripEventEditor
