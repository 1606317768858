import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { isEqual, differenceBy } from 'lodash'
import TripValidationAlert from './TripValidationAlert'
import { getTripValidationAlerts } from '@severed-links/common.severedlinks-constants'
import { updateTripValidationAlerts } from '@severed-links/common.severedlinks-reducers/groups'
import * as s from './TripValidationAlertList.scss'
import { useDispatch } from 'react-redux'
import listify from 'listify'
import pluralize from 'pluralize'

const TripValidationAlertList = ({ group = {}, trip = {}, path = null, tab = 'basics' }) => {

    const dispatch = useDispatch()
    const settings = trip.settings || {}
    const settingsLoaded = trip.settingsLoaded || null
    const _courseNames = (trip.rounds || []).map(i => (i.courseName ? `${i.courseName}` : '') + (i.tees ? ` (${i.tees} tees)` : '')).filter(i => i).join(',')
    const _adminNoteWarningCount = (trip.adminNotes || []).filter(i => i.isReminderToday || i.isOverdue).length
    const arrAlerts = (trip.validationAlerts || []).map(x => ({ ...x, show: x.adminPath === path }))
    const hasAlerts = arrAlerts.some(i => i.show)

    useEffect(() => {
        if (group._id && trip._id && settingsLoaded) {
            doUpdate()
        }
    }, [group._id, trip._id, settingsLoaded, _courseNames, _adminNoteWarningCount])

    const doUpdate = () => {
        if (group._id && trip._id) {
            var arrAlerts = getTripValidationAlerts(trip)
            dispatch(updateTripValidationAlerts(group._id, trip._id, arrAlerts))
        }
    }

    return (
        <div className={s.container + ' ' + (hasAlerts ? s.hasAlerts : s.hasNoAlerts)}>
            {arrAlerts && arrAlerts.map((i, index) =>
                <TripValidationAlert type={i.type} show={i.show} message={i.message} 
                    key={`trip-${trip._id}-validation-alert-${i.adminPath}-${index}`} />
            )}
        </div>
    )
}

export default TripValidationAlertList