import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Image, FormGroup, FormLabel, FormControl, InputGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import { getBase64, img } from '@severed-links/common.severedlinks-constants'
import { setAvatarUrl } from '@severed-links/common.severedlinks-reducers/profile'
import { getMe } from '@severed-links/common.severedlinks-reducers/account'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '@severed-links/common.spinner'
import * as s from './NewImageUploadModal.scss'

const NewImageUploadModal = ({ show = false, playerId = null, onClose = () => void(0), onSuccess = null }) => {

    if (!playerId) return null

    const dispatch = useDispatch()
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState('')
    const [fileBase64, setFileBase64] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [isSaving, setSaving] = useState(false)
    const [formData, setFormData] = useState(null)

    const handleFileSelect = e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const imageUrl = uuidv4().toString()
            const publicId = img.CloudinaryPlayerAvatarPublicId(imageUrl)
            var formData = new FormData()
            formData.append('public_id', publicId)
            formData.append('upload_preset', img.CloudinaryPlayerAvatarUploadPreset)
            formData.append('file', file)
            setFileName(e.target.value)
            setFile(file)
            setImageUrl(imageUrl)
            setFormData(formData)
            getBase64(file).then(data => setFileBase64(data))
        }
    }

    const onUploadSuccess = e => {
        if (e.target.response && e.target.status === 200) {
            dispatch(setAvatarUrl({ playerId, imageUrl }))
            .then(action => { 
                dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Upload new avatar', timeout: 5000 }))
                setFileName('')
                setFileBase64('')
                setFormData(null)
                setFile(null)
                setImageUrl('')
                setSaving(false)
                if (action.payload.messageType === 'success') {
                    dispatch(getMe())
                    if (onSuccess) onSuccess({ playerId, imageUrl })
                    onClose()
                }
            })
        } else {
            setSaving(false)
            const error = (JSON.parse(e.target.response) || {}).error
            dispatch(createNotification({ message: error.message, type: 'error', headline: 'Upload Error', timeout: 5000 }))
        }

    }

    const onUploadProgress = e => {
    }

    const onUploadError = e => {
        setSaving(false)
        dispatch(createNotification({ message: 'Upload error: ' + JSON.stringify(e), type: 'error', headline: 'Upload Error', timeout: 6000 }))
    }

    const upload = () => {
        setSaving(true)
        img.CloudinaryImageUploader(formData, img.CloudinaryImageUploadUrl, onUploadSuccess, onUploadProgress, onUploadError)
    }

    return (
        <Modal show={show}
            heading='Upload new player avatar'
            actionButtonText='upload new image'
            actionButtonIcon='upload'
            actionButtonIconSpin={isSaving}
            actionButtonDisabled={!fileBase64 || isSaving}
            actionButtonOnClick={() => upload()}
            onClose={onClose}>
            <div className={s.container}>
                <FormGroup className={s.formGroup}>
                    <FormLabel>Select a new image...</FormLabel>
                    <FormControl type='file' value={fileName} 
                        onChange={e => handleFileSelect(e)} />
                </FormGroup>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {fileBase64 ? 
                        <div style={{ textAlign: 'center' }}>
                            {!isSaving ?
                            <Image src={fileBase64} style={{ width: '10rem' }} />
                            :
                            <Spinner size='3x' />}
                        </div>
                    : null}
                </div>
            </div>
        </Modal>
    )
}

export default NewImageUploadModal