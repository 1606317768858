import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation, useOutletContext, Outlet } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FormGroup from 'react-bootstrap/FormGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from '@severed-links/common.button'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TextInput from '@severed-links/common.text-input'
import Modal from '@severed-links/common.modal'
import Picker from '@severed-links/common.picker'
import Confirm from '@severed-links/common.confirm'
import PlayerListItem from '../../Player/PlayerListItem'
import * as s from './AddressBookEditEntry.scss'
import { reject, orderBy } from 'lodash'
import validator from 'validator'
import pluralize from 'pluralize'
import { coolTime } from '@severed-links/common.severedlinks-constants'
import { saveAddressBookEntry, deleteAddressBookEntry } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const _phoneTypeItems = [
    { title: 'Work', value: 'work', icon: 'building' },
    { title: 'Home', value: 'home', icon: 'home' },
    { title: 'Cell', value: 'cell', icon: 'mobile' },
    { title: 'Fax', value: 'fax', icon: 'fax' },
]
const _emailTypeItems = reject(_phoneTypeItems, i => i.value === 'cell' || i.value === 'fax')

const _getPhoneType = _type => _phoneTypeItems.find(i => i.value === _type)
const _getEmailType = _type => _emailTypeItems.find(i => i.value === _type)

const ContactAddressItem = ({ index = -1, isNew = false, isEmail = false, isPhone = false, type = 'work', value = '', onValueChange, onTypeChange, onDelete, onAdd }) => {
    
    const [showTypePicker, setShowTypePicker] = useState(false)
    const _selectedType = (isEmail ? _getEmailType : _getPhoneType)(type) || {}

    return (
        <>
        <ListGroup.Item className={s.item}>
            <div className={s.icon + ' ' + s.primary}><FontAwesomeIcon name={isEmail ? 'envelope' : isPhone ? 'phone' : 'question'} /></div>
            <Button className={s.typeButton} variant='primary' isOutline icon={_selectedType.icon} title={<div className={s.title}>{_selectedType.title}</div>} onClick={() => setShowTypePicker(true)} />
            <div className={s.content}>
                <TextInput className={s.input}
                    useFloatingLabels={false}
                    value={value} hasBottomMargin={false}
                    placeholder={isEmail ? 'Email address...' : 'Phone number...'}
                    onChange={_text => onValueChange(_text)}
                    buttonProps={{ onClick: isNew ? () => onAdd(isEmail ? 'email' : 'phone') : null }} />
            </div>
            {!isNew ?
            <Confirm variant='danger' onConfirm={() => onDelete(isEmail ? 'email' : 'phone', index)}
                title={`Delete ${isEmail ? 'Email Address' : 'Phone Number'}`} enforceFocus={false}
                confirmText={`delete ${isEmail ? 'email address' : 'phone number'}`}
                body={`Are you sure you want to delete this ${isEmail ? 'email address' : 'phone number'}?`}
                buttonClassName={s.deleteButton}
                buttonIcon='trash' />
            : null}
        </ListGroup.Item>
        <Picker show={showTypePicker} items={isEmail ? _emailTypeItems : _phoneTypeItems}
            onSelect={onTypeChange} defaultValue={type} onClose={() => setShowTypePicker(false)} />
        </>
    )    
}

const AddressBookEditEntry = ({ isNew = false }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const _groupContext = useOutletContext()
    const { group, groupId } = _groupContext
    const _newEntry = { firstName: '', lastName: '', company: '', notes: '', emailAddresses: [], phoneNumbers: [], createdBy: null }
    const playerId = useSelector(state => state.account._id)
    const entryId = params.entryId || null
    const addressBook = group?.addressBook || []
    const _editEntry = addressBook.find(i => i._id === entryId) || {}
    const [_entry, setEntry] = useState(_newEntry)
    const [_newEmail, setNewEmail] = useState({ type: 'work', value: '' })
    const [_newPhone, setNewPhone] = useState({ type: 'work', value: '' })
    const [newItemType, setNewItemType] = useState(null)
    const [showTripPicker, setShowTripPicker] = useState(false)
    const { firstName, lastName, company, notes, emailAddresses, phoneNumbers, updatedBy, trips, updatedAt } = _entry
    const _pickableTrips = (group?.trips || []).filter(i => !(trips || []).some(x => x._id === i._id))
        .map(i => ({ ...i, icon: 'briefcase', title: i.tripName, value: i._id }))
    const _pickedTrips = (group?.trips || []).filter(i => (trips || []).some(x => x._id === i._id))
    const _isValid = !validator.isEmpty(firstName || '') && !validator.isEmpty(lastName || '')

    useEffect(() => {
        setEntry({ groupId, ..._newEntry, ..._editEntry })
    }, [groupId, _editEntry._id])

    const handleBackButtonClick = () => {
        navigate(-1)
    }

    const handleFieldChange = (fieldName, value) => {
        setEntry({ ..._entry, [fieldName]: value })
    }

    const handleContactItemDelete = (type, index = -1) => {
        if (index > -1) {
            const _arrName = type === 'email' ? 'emailAddresses' : 'phoneNumbers'
            var _arr = [...(_entry[_arrName] || [])]
            _arr.splice(index, 1)
            setEntry({ ..._entry, [_arrName]: _arr })
        }
    }

    const handleContactItemAdd = _type => {
        if (_type === 'email') {
            setEntry({ ..._entry, emailAddresses: [...(_entry.emailAddresses || []), _newEmail] })
            setNewEmail({ type: 'work', value: '' })
        } else {
            setEntry({ ..._entry, phoneNumbers: [...(_entry.phoneNumbers || []), _newPhone] })
            setNewPhone({ type: 'work', value: '' })
        }
        setNewItemType(null)
    }

    const handleContactItemChange = (type, index, fieldName, value) => {
        if (index === -1) {
            if (type === 'email') {
                setNewEmail({ ..._newEmail, [fieldName]: value })
            } else {
                setNewPhone({ ..._newPhone, [fieldName]: value })
            }            
        } else if (index > -1) {
            const _arrName = type === 'email' ? 'emailAddresses' : 'phoneNumbers'
            var _arr = [...(_entry[_arrName] || [])]
            _arr[index] = { ...(_arr[index] || {}), [fieldName]: value || '' }
            setEntry({ ..._entry, [_arrName]: _arr })
        }
    }

    const handleContactDelete = () => {
        if (_entry._id) {
            dispatch(deleteAddressBookEntry(groupId, _entry._id))
            handleBackButtonClick()
        }
    }

    const handleTripSelect = _item => {
        setEntry({ ..._entry, trips: [...(trips || []), _item] })
    }

    const handleTripRemove = _item => {
        setEntry({ ..._entry, trips: reject(trips, i => i._id === _item._id) })
    }

    const handleSave = (_redirect = false) => {
        if (_isValid) {
            dispatch(saveAddressBookEntry({ ..._entry, trips: (_entry.trips || []).map(i => i._id) }))
            .then(action => {
                if (action.payload.messageType === 'success' && isNew && action.payload.entryId) {
                    navigate(`/group/${groupId}/admin/address-book/edit/${action.payload.entryId}`, { replace: true })
                } else {
                    dispatch(createNotification(action.payload))
                    if (!isNew && _redirect) {
                        handleBackButtonClick()
                    }
                }
            })
        }
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <div className={s.goBack}>
                    <Button variant='light' icon='chevron-left' onClick={handleBackButtonClick} />
                </div>
                <h4 className={s.title}>{isNew ? `Add New Address Book Entry` : `Edit Address Book Entry`}</h4>
            </div>
            <div className={s.smallHeading}>Contact information</div>
            <Row>
                <Col md={6}>
                    <TextInput label='First name' value={firstName} 
                        onChange={_text => handleFieldChange('firstName', _text)}
                        buttonProps={{ onClick: () => handleSave(true)}} />
                </Col>
                <Col md={6}>
                    <TextInput label='Last name' value={lastName}
                        onChange={_text => handleFieldChange('lastName', _text)}
                        buttonProps={{ onClick: () => handleSave(true)}} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextInput label='Company' value={company}
                        onChange={_text => handleFieldChange('company', _text)}
                        buttonProps={{ onClick: () => handleSave(true)}} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextInput label='Notes' value={notes}
                        onChange={_text => handleFieldChange('notes', _text)}
                        buttonProps={{ onClick: () => handleSave(true)}} />
                </Col>
            </Row>
            {!isNew ?
            <>
            <Row>
                <Col>
                    <div className={s.smallHeading}>
                        <div className={s.title}>Email {pluralize('address', emailAddresses?.length, false)}</div>
                        <Button className={s.button + ' ' + s.primary} clear icon='plus' onClick={() => setNewItemType('email')} />
                    </div>
                    <ListGroup className={s.list}>
                    {emailAddresses && emailAddresses.map((i, index) =>
                    <ContactAddressItem isEmail {...i} index={index} 
                        onValueChange={_text => handleContactItemChange('email', index, 'value', _text)} 
                        onTypeChange={_item => handleContactItemChange('email', index, 'type', _item.value)}
                        onDelete={(_type, _index) => handleContactItemDelete(_type, _index)} 
                        key={`entry-${entryId || ''}-email-address${i._id || index}`} />
                    )}
                    {!emailAddresses?.length ? <ListGroup.Item className={s.item}>No email addresses located.</ListGroup.Item> : null}
                    </ListGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={s.smallHeading}>
                        <div className={s.title}>Phone {pluralize('number', phoneNumbers?.length, false)}</div>
                        <Button className={s.button + ' ' + s.primary} clear icon='plus' onClick={() => setNewItemType('phone')} />
                    </div>
                    <ListGroup className={s.list}>
                    {phoneNumbers && phoneNumbers.map((i, index) =>
                    <ContactAddressItem isPhone {...i} index={index} 
                        onValueChange={_text => handleContactItemChange('phone', index, 'value', _text)} 
                        onTypeChange={_item => handleContactItemChange('phone', index, 'type', _item.value)}
                        onDelete={(_type, _index) => handleContactItemDelete(_type, _index)} 
                        key={`entry-${entryId || ''}-phone-number${i._id || index}`} />
                    )}
                    {!phoneNumbers?.length ? <ListGroup.Item className={s.item}>No phone numbers located.</ListGroup.Item> : null}
                    </ListGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={s.smallHeading}>
                        <div className={s.title}>Associated {pluralize('trip', _pickableTrips?.length, false)}</div>
                        {_pickableTrips.length ? <Button className={s.button + ' ' + s.primary} clear icon='plus' onClick={() => setShowTripPicker(true)} /> : null}
                    </div>
                    <ListGroup className={s.list}>
                    {_pickedTrips && orderBy(_pickedTrips, ['beginDate'], ['desc']).map(i =>
                    <ListGroup.Item className={s.item} key={`associated-trip-${i._id}`}>
                        <div className={s.icon + ' ' + s.primary}><FontAwesomeIcon name={'briefcase'} /></div>
                        <div className={s.content}>{i.tripName}</div>
                        <div className={s.controls}>
                            <Button className={s.deleteButton} icon='trash' onClick={() => handleTripRemove(i)} />
                        </div>
                    </ListGroup.Item>
                    )}
                    {!_pickedTrips?.length ? <ListGroup.Item className={s.item}>No associated trips located.</ListGroup.Item> : null}
                    </ListGroup>

                    <Picker show={showTripPicker} onClose={() => setShowTripPicker(false)}
                        heading={`Select a trip to add...`} items={_pickableTrips}
                        onSelect={_item => handleTripSelect(_item)} />
                </Col>
            </Row>

            <Modal show={!!newItemType} onClose={() => setNewItemType(null)}
                heading={`Enter new ${newItemType} item`}>
                <div className={s.container}>
                    <ListGroup className={s.list}>
                        <ContactAddressItem isEmail={newItemType === 'email'} isPhone={newItemType === 'phone'} 
                            isNew {...(newItemType === 'email' ? _newEmail : newItemType === 'phone' ? _newPhone : {}) }
                            onValueChange={_text => handleContactItemChange(newItemType, -1, 'value', _text)} 
                            onTypeChange={_item => handleContactItemChange(newItemType, -1, 'type', _item.value)} 
                            onAdd={_type => handleContactItemAdd(_type)} />
                    </ListGroup>
                </div>
            </Modal>

            {updatedBy && updatedAt ?
            <Row>
                <Col>
                <div className={s.smallHeading}>Updated</div>
                <ListGroup className={s.list}>
                <PlayerListItem clickDisabled player={updatedBy} rightDetail={<div className={s.updatedAt}>{coolTime(updatedAt)}</div>} />
                </ListGroup>
                </Col>
            </Row>
            : null}
            </>
            : <p>You will be prompted to enter the contact's email(s) and phone number(s) after you save the basic information above.</p>}


            <FormGroup className={s.formGroup + ' ' + s.buttons}>
                {!isNew ?
                <Confirm variant='danger' onConfirm={() => handleContactDelete()}
                    title={`Delete Contact`} enforceFocus={false}
                    confirmText={`delete contact`}
                    body={`Are you sure you want to delete this contact?`}
                    buttonClassName={s.button + ' ' + s.hideTitleOnSmall}
                    buttonText={<div className={s.title}>delete contact</div>}
                    buttonIcon='trash' />
                : null}

                <Button className={s.button + ' ' + s.hideTitleOnSmall} variant='light' icon='times' title={<div className={s.title}>cancel</div>} onClick={handleBackButtonClick} />
                <Button className={s.button + ' ' + s.saveButton} variant='primary' icon='check' title='save contact' onClick={() => handleSave(!isNew)} disabled={!_isValid} />
            </FormGroup>
        </div>
    )
}

export default AddressBookEditEntry
