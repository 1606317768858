import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventPollOption.scss'

const TripEventPollOption = ({ eventId, option, show, onVote, isMyVote }) => {

    return (
        <div className={s.container} onClick={() => onVote ? onVote({ _id: eventId }, option._id) : null}>
            <div className={s.fill} style={{ width: `${show ? option.pct : '0'}%`}} />
            <div className={s.pollOptionDetail}>
                <div className={s.pollOptionText}>{option.optionText} {isMyVote ? <FontAwesomeIcon name='check' title={'Your vote'} className={s.myVote} /> : null}</div>
                {show ? <div className={s.pollOptionPct} title={`Votes: ${option.count}`}>{option.pct}%</div> : null}
            </div>
        </div>
    )
}

export default TripEventPollOption
