import React, { useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import GroupRole from './GroupRole'
import TripPlayerPhotoCounts from './TripPlayerPhotoCounts'
import GroupInactiveAlert from './GroupInactiveAlert'
import * as s from './GroupListItem.scss'
import GroupRestorer from './GroupRestorer'

const GroupListItem = ({ group = {}, onClick, onRestore, isGlobalAdmin = false }) => {

    return (
        <ListGroup.Item className={s.container} key={group._id} action
            onClick={(group.role || {}).isActive || isGlobalAdmin ? () => onClick(group._id) : undefined}>
            <div className={s.heading}>
                <h4 className={s.title}>{group.name}</h4>
                <div className={s.roleContainer}>
                    <GroupRole role={group.role} />
                </div>
            </div>
            <div className={s.detailsContainer}>
                <div className={s.restoreContainer}>
                    <GroupRestorer group={group} onRestore={onRestore} />
                </div>
                <div className={s.inviteContainer}>
                    <GroupInactiveAlert role={group.role} 
                        groupId={group._id} 
                        isGlobalAdmin={isGlobalAdmin} />
                </div>
                <div className={s.countContainer}>
                    <TripPlayerPhotoCounts tripCount={group.tripCount}
                        playerCount={group.playerCount}
                        photoCount={group.photoCount} />
                </div>
            </div>
        </ListGroup.Item>
    )
}

export default GroupListItem