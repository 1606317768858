import React, { useRef } from 'react'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminPricingPlans.scss'
import Confirm from '@severed-links/common.confirm'
import { useDrag, useDrop } from 'react-dnd'
import { ITEM_TYPES } from '../Trips/Admin/DragAndDropItemTypes'

const GlobalAdminPricingPlanNoteItem = ({ _id, index, note, icon, updatedAt, onDelete, onEdit, onMove }) => {

    const _dragRef = useRef(null)
    const _previewRef = useRef(null)

    const [{ handlerId }, drop] = useDrop({
        accept: ITEM_TYPES.PRICING_PLAN_NOTE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!_previewRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = _previewRef.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            onMove(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        type: ITEM_TYPES.PRICING_PLAN_NOTE,
        item: () => ({ _id, index, note, icon, updatedAt }),
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(_dragRef)
    drop(preview(_previewRef))
    
    return (
        <ListGroup.Item ref={_previewRef} className={s.noteItem}>
            <div ref={_dragRef} className={s.handle} title={'Drag me to reorder notes...'}><FontAwesomeIcon name='bars' /></div>
            <div className={s.icon}><FontAwesomeIcon name={icon} /></div>
            <div className={s.note}>{note}</div>
            <div className={s.edit}>
                <Button className={s.editButton} onClick={() => onEdit(_id)}><FontAwesomeIcon name='pencil-alt' /></Button>
            </div>
            <div className={s.delete}>
                <Confirm title={'Delete Pricing Plan Note'}
                    onConfirm={() => onDelete(_id)} enforceFocus={false}
                    confirmText='delete note'
                    body={<div><p>Are you sure you want to delete this note?</p></div>}
                    variant='danger' buttonClassName={s.deleteButton} buttonIcon='times' />
            </div>
        </ListGroup.Item>
    )
}

export default GlobalAdminPricingPlanNoteItem
