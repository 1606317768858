import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Row, Col, FormGroup, FormControl, FormLabel, InputGroup, 
    Table, FormText, Alert, ListGroup, Nav, Navbar } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PaymentSetup.scss'
import { sortBy, sum, isEqual, uniqBy, uniq, difference, orderBy } from 'lodash'
import { savePaymentSetup, getPaymentSetup, getTripPayouts } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import CopyPaymentSetup from './CopyPaymentSetup'
import { getOrdinalForNumber } from '@severed-links/common.severedlinks-constants'
import pluralize from 'pluralize'
import numeral from 'numeral'
import Modal from '@severed-links/common.modal'
import Button from '@severed-links/common.button'
import TextInput from '@severed-links/common.text-input'
import SeveredLinksAlert from '@severed-links/common.alert'

const PaymentSummaryItem = ({ group = {}, trip = {}, title = '', subtitle = '', alert = '', value = '', topBorder = false, variant = null }) => {
    return (
        <ListGroup.Item className={s.item + (variant ? ` ${s[variant]}` : '') + (topBorder ? ` ${s.topBorder}` : ``)}>
            <div className={s.content}>
                <div className={s.title}>{title}</div>
                {subtitle ? <div className={s.subtitle}>{subtitle}</div> : null}
                {alert ? <div className={s.subtitleAlert}>{alert}</div> : null}
            </div>
            <div className={s.right}>{value}</div>
        </ListGroup.Item>
    )
}

const EntryFeePerPlayer = ({ 
    group = {}, trip = {}, entryFeePerPlayer = '0', proposedEntryFee = 0, 
    handleEntryFeeChange = null, onSave = null, handleCopy = null
}) => {
    const entryFeeErrors = [
        parseFloat(entryFeePerPlayer) !== parseInt(entryFeePerPlayer) ? 'Entry fee should be a whole number ($x.00).' : null,
        parseFloat(entryFeePerPlayer) < proposedEntryFee ? 'You should set your entry fee per player at or higher than the proposed entry fee above.' : null,
    ].filter(i => i)
    return (
        <FormGroup size='lg' style={{ marginTop: '30px', marginBottom: '30px' }}>
            <FormLabel>Entry Fee per Player</FormLabel>
            <InputGroup className={s.inputGroup}>
                <InputGroup.Text><FontAwesomeIcon name='dollar-sign' /></InputGroup.Text>
                <FormControl size='lg' value={entryFeePerPlayer} 
                    className={s.textBox} 
                    onChange={e => handleEntryFeeChange(e)} />
                <InputGroup.Text>.00</InputGroup.Text>
                <Button variant='primary' onClick={() => onSave()} icon='check'
                    title={<>
                        <span className={'d-none d-sm-inline d-md-none'}>save</span>
                        <span className={'d-none d-md-inline d-lg-none'}>save setup</span>
                        <span className={'d-none d-lg-inline'}>save payment setup</span>
                    </>} />
                <CopyPaymentSetup group={group} trip={trip} onCopy={handleCopy} />
            </InputGroup>
            {entryFeeErrors.length ? 
            <div>
                <SeveredLinksAlert small variant='danger' message={entryFeeErrors.join('. ')} icon='exclamation-triangle' />
            </div>
            : null}
        </FormGroup>
    )
}

const PaymentRoundItem = ({ medalistType = null, scoreType = null }) => (
    <InputGroup.Text className={s.roundItemContainer}>
        <div className={s.lowContainer}>Low</div>
        <div className={s.itemsContainer}>
            {medalistType ? <div className={s.medalistTypeContainer}>{medalistType.replace('low', '').replace('BestBall', 'Best Ball')}</div> : null}
            {scoreType ? <div className={s.scoreTypeContainer}>{scoreType.replace('low', '')}</div> : null}
        </div>
    </InputGroup.Text>
)

const PaymentAmountTextBox = ({ label = '', prefix = '', roundItem = null, prefixClass = null, icon = 'dollar-sign', value = '', onChange = null, suffix = '.00', controls = null }) => (
    <FormGroup>
        {label ? <FormLabel className={s.formLabel}>{label}</FormLabel> : null}
        <InputGroup className={s.inputGroup}>
            {prefix ? <InputGroup.Text>{prefix}</InputGroup.Text> : null}
            {roundItem ? <PaymentRoundItem {...(roundItem || {})} /> : null}
            {icon ? <InputGroup.Text><FontAwesomeIcon name={icon} /></InputGroup.Text> : null}
            <FormControl value={value || ''} className={s.textBox} 
                readOnly={!onChange} disabled={!onChange}
                onChange={onChange ? e => onChange(e) : null} />
            {suffix ? <InputGroup.Text>{suffix}</InputGroup.Text> : null}
            {controls}
        </InputGroup>
    </FormGroup>
)

const AddRoundMedalistItemSelector = ({ title = '', options = [], selectedValue = null, onSelect = null }) => {

    return (
        <ListGroup.Item className={s.item}>
            <div className={s.itemTitle}>{title}</div>
            <div className={s.controls}>
                <Nav className={s.selector} variant='pills' 
                    activeKey={`${selectedValue}`}
                    onSelect={onSelect ? e => onSelect(e) : null}>
                {options && options.map(i =>
                <Nav.Item className={s.navItem} key={`add-${title}-item-${i.value}`} >
                    <Nav.Link className={s.navLink + (selectedValue === i.value ? ` ${s.active}` : ``)} eventKey={`${i.value}`}>{i.text}</Nav.Link>
                </Nav.Item>
                )}
                </Nav>
            </div>
        </ListGroup.Item>
    )
}

const AddRoundMedalist = ({ existingMedalistRoundPayments = [], medalistRoundInfo = [], onAdd = null }) => {
    const [showAddModal, setShowAddModal] = useState(false)
    const [_newItem, setNewItem] = useState({ round: 1, amount: '0', scoreType: 'lowGross', medalistType: 'lowPlayer' })
    const _roundItems = medalistRoundInfo.map(i => ({ text: `${i.round}`, value: i.round }))
    const _medalistItems = (medalistRoundInfo.find(i => i.round === _newItem.round) || {}).playerTeamOptions || []
    const _scoreItems = (medalistRoundInfo.find(i => i.round === _newItem.round) || {}).grossNetOptions || []
    const _isValid = !isNaN(_newItem.amount) && _newItem.amount > 0
        && !existingMedalistRoundPayments.some(i => i.round === _newItem.round && i.medalistType === _newItem.medalistType && i.scoreType === _newItem.scoreType)
        && _medalistItems.some(i => i.value === _newItem.medalistType)
        && _scoreItems.some(i => i.value === _newItem.scoreType)

    
    const handleItemSelect = (fieldName, value) => {
        setNewItem({ ..._newItem, [fieldName]: value })
    }

    const handleItemSubmit = () => {
        if (_isValid && onAdd) {
            setShowAddModal(false)
            onAdd(_newItem)
        }
    }

    const handleAmountChange = _text => {
        var amount = ''
        if (_text && !isNaN(_text)) {
            amount = parseInt(_text)
        }
        setNewItem({ ..._newItem, amount })
    }

    return (
        <>
            <Button variant='light' onClick={() => setShowAddModal(true)} icon='plus' block title='add new award' />

            <Modal show={showAddModal} onClose={() => setShowAddModal(false)}
                heading={`Add Round Medalist Award`} showFooter
                actionButtonIcon='check'
                actionButtonText='add round award'
                actionButtonOnClick={handleItemSubmit}
                actionButtonDisabled={!_isValid}>
            <ListGroup className={s.addRoundMedalistContainer}>
                <AddRoundMedalistItemSelector title='Round'
                    selectedValue={_newItem.round}
                    options={_roundItems}
                    onSelect={e => handleItemSelect('round', parseInt(e))} />
                <AddRoundMedalistItemSelector title='Medalist Type'
                    selectedValue={_newItem.medalistType}
                    options={_medalistItems}
                    onSelect={e => handleItemSelect('medalistType', e)} />
                <AddRoundMedalistItemSelector title='Score Type'
                    selectedValue={_newItem.scoreType}
                    options={_scoreItems}
                    onSelect={e => handleItemSelect('scoreType', e)} />
                <ListGroup.Item className={s.item}>
                    <div className={s.title}>Amount</div>
                    <div className={s.controls}>
                        <TextInput value={`${_newItem.amount || ''}`}
                            inputGroupClassName={s.inputGroup}
                            onChange={handleAmountChange}
                            useFloatingLabels={false}
                            placeholder='0'
                            prefix={<FontAwesomeIcon name='dollar-sign' />}
                            suffix={'.00'} />
                    </div>
                </ListGroup.Item>
            </ListGroup>
            </Modal>
        </>
    )
}

const PaymentSetup = () => {

    const dispatch = useDispatch()
    const { groupId, group, tripId, trip, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [proxyAmount, setProxyAmount] = useState('0')
    const [skinAmounts, setSkinAmounts] = useState([])
    const [medalistAmounts, setMedalistAmounts] = useState([])
    const [medalistRoundPayments, setMedalistRoundPayments] = useState([])
    const [registrationFee, setRegistrationFee] = useState(0.00)
    const [merchantFeePercentage, setMerchantFeePercentage] = useState(0.00)
    const [ryderCupAwardPerPlayer, setRyderCupAwardPerPlayer] = useState(0.00)
    const [entryFeePerPlayer, setEntryFeePerPlayer] = useState(0.00)
    const [miscellaneousAdditionalFeePerPlayer, setMiscellaneousAdditionalFeePerPlayer] = useState(0.00)
    const medalistRoundInfo = trip?.paymentSetup?.medalistRoundInfo || []
    const tripPlayers = sortBy([...(trip.usaPlayers || []), ...(trip.eurPlayers || []), ...(trip.unassignedPlayers || []), ], ['sortOrder','lastName','firstName'], ['asc','asc','asc'])
    const numPlayers = Math.max(tripPlayers.length, trip.rsvpCapacityLimit)
    const skinFees = sum(skinAmounts.map(i => numPlayers * parseFloat(i.amount || '0.00')))
    const ryderCupFees = numPlayers * ryderCupAwardPerPlayer / 2.00
    const totalMiscellaneousAdditionalFeePerPlayer = numPlayers * (miscellaneousAdditionalFeePerPlayer && !isNaN(miscellaneousAdditionalFeePerPlayer) ? parseFloat(miscellaneousAdditionalFeePerPlayer) : 0.00)
    const proxies = (trip.scoreboard || {}).proxies || []
    const proxyFees = proxies.length * parseFloat(!isNaN(proxyAmount) ? proxyAmount : '0')
    const roundsWithoutProxies = difference((trip.rounds || []).map(i => i.round), proxies.map(i => i.round))
    const medalistFees = sum(medalistAmounts.map(i => parseFloat(i.amount || '0.00')))
    const medalistRoundFees = sum(medalistRoundPayments.map(i => i.amount || 0))
    const totalEntryFeesToAdmins = proxyFees + skinFees + medalistFees + medalistRoundFees + ryderCupFees + totalMiscellaneousAdditionalFeePerPlayer
    const estimatedEntryFeePerPlayer = numPlayers !== 0 ? totalEntryFeesToAdmins / parseFloat(numPlayers) : 0
    const proposedEntryFee = Math.ceil(estimatedEntryFeePerPlayer)
    const isMedalistOverloaded = medalistAmounts.length > numPlayers 
    const _items = [
        { title: `Trip players`, subtitle: null, value: `${numPlayers}`, onClick: null },
        { title: `Ryder Cup fees needed`, subtitle: `${pluralize('player', numPlayers, true)}, ${numeral(ryderCupAwardPerPlayer / 2).format('($0,0.00)')}/each`, value: `${numeral(ryderCupFees).format('($0,0.00)')}`, onClick: null },
        { title: `Proxy fees needed`, subtitle: `${pluralize('proxy', proxies.length, true)}`, value: `${numeral(proxyFees).format('($0,0.00)')}`, onClick: null, alert: `${roundsWithoutProxies.length ? `No proxies in ${pluralize('round', roundsWithoutProxies.length, false)} ${roundsWithoutProxies.map(i => `#${i}`).join(', ')}` : ``}` },
        { title: `Skin fees needed`, subtitle: `${pluralize('player', numPlayers, true)}, ${pluralize('round', skinAmounts.length, true)}`, value: `${numeral(skinFees).format('($0,0.00)')}`, onClick: null },
        { title: `Overall medalist payouts needed`, subtitle: `${pluralize('level', medalistAmounts.length, true)}:  ${medalistAmounts && medalistAmounts.length ? `${medalistAmounts.map(i => `${i.rankOrdinal} - ${numeral(i.amount).format('($0,0.00)')}`).join(', ')}` : `No levels entered.`}`, value: `${numeral(medalistFees).format('($0,0.00)')}`, onClick: null },
        { title: `Round medalist payouts needed`, subtitle: `${pluralize('award', medalistRoundPayments.length, true)}${medalistRoundPayments?.length ? `: ${medalistRoundPayments.map(i => `Round ${i.round} low ${(i.medalistType || '').replace('low', '').toLowerCase().replace('BestBall', 'best ball')} ${(i.scoreType || '').replace('low', '').toLowerCase()} ${numeral(i.amount || 0).format('($0,0.00)')}`).join(', ')}` : ``}`, value: `${numeral(medalistRoundFees).format('($0,0.00)')}`, onClick: null },
        { title: `Miscellaneous additional fees`, subtitle: `${pluralize('player', numPlayers, true)}, ${numeral(miscellaneousAdditionalFeePerPlayer).format('($0,0.00)')}/each`, value: `${numeral(totalMiscellaneousAdditionalFeePerPlayer).format('($0,0.00)')}`, onClick: null },
        { title: `Total entry fees`, subtitle: `Excluding Severed Links RSVP fee`, value: `${numeral(totalEntryFeesToAdmins).format('($0,0.00)')}`, onClick: null, topBorder: true, variant: 'info' },
        { title: `Estimated entry fee per player`, subtitle: `${numeral(totalEntryFeesToAdmins).format('($0.00)')}/${pluralize('player', numPlayers, true)}`, value: `${numeral(estimatedEntryFeePerPlayer).format('($0,0.00)')}`, onClick: null, topBorder: true, variant: 'info' },
    ].filter(i => i && i.title)

    useEffect(() => initializeSetup(), [groupId, tripId, trip.paymentSetup])

    useEffect(() => { 
        if (groupId && tripId) {
            dispatch(getPaymentSetup(groupId, tripId))
        }
    }, [groupId, tripId])

    const initializeSetup = () => {
        const paymentSetup = trip.paymentSetup || {}
        setProxyAmount((paymentSetup.proxyAmount || '0').toString())
        setMedalistAmounts(paymentSetup.medalistPayments || [])
        setMedalistRoundPayments(paymentSetup.medalistRoundPayments || [])
        setSkinAmounts((paymentSetup.skinPayments || []).map(x => ({ round: x.round, amount: (x.amount || '0').toString() })))
        setMerchantFeePercentage(paymentSetup.merchantFeePercentage || 0.00)
        setRegistrationFee(paymentSetup.registrationFee || 0.00)
        setRyderCupAwardPerPlayer((paymentSetup.ryderCupAwardPerPlayer || 0.00).toString())
        setEntryFeePerPlayer((paymentSetup.entryFeePerPlayer || 0.00).toString())
        setMiscellaneousAdditionalFeePerPlayer((paymentSetup.miscellaneousAdditionalFeePerPlayer || 0.00).toString())
    }

    const addNewMedalistLevel = () => {
        var _medalistAmounts = [...medalistAmounts]
        _medalistAmounts.push({ rank: _medalistAmounts.length + 1, rankOrdinal: getOrdinalForNumber(_medalistAmounts.length + 1), amount: '0' })
        setMedalistAmounts(_medalistAmounts)
    }

    const deleteMedalistLevel = rank => {
        var _medalistAmounts = [...medalistAmounts]
        _medalistAmounts.splice(rank - 1, 1)
        _medalistAmounts.forEach((i, index) => { 
            i.rank = index + 1
            i.rankOrdinal = getOrdinalForNumber(index + 1)
        })
        setMedalistAmounts(_medalistAmounts)
    }

    const deleteMedalistRoundLevel = _item => {
        var _medalistRoundPayments = [...medalistRoundPayments]
        const _roundItemIndex = _medalistRoundPayments.findIndex(i => i.round === _item.round && i.medalistType === _item.medalistType && i.scoreType === _item.scoreType)
        if (_roundItemIndex > -1) {
            _medalistRoundPayments.splice(_roundItemIndex, 1)
        }
        setMedalistRoundPayments(_medalistRoundPayments)
    }

    const handleMedalistLevelChange = (e, rank) => {
        var _medalistAmounts = [...medalistAmounts]
        _medalistAmounts[rank - 1] = { ..._medalistAmounts[rank - 1], amount: e.target.value }
        setMedalistAmounts(_medalistAmounts)
    }

    const handleAddRoundMedalistItem = (_item = null) => {
        if (_item?.round && _item?.medalistType && _item?.scoreType && _item?.amount) {
            var _medalistRoundPayments = [...medalistRoundPayments]
            _medalistRoundPayments.push(_item)
            setMedalistRoundPayments(orderBy(_medalistRoundPayments, ['round', 'medalistType', 'scoreType'], ['asc', 'asc', 'asc']))
        }
    }

    const handleSkinChange = (e, round) => {
        var _skinAmounts = [...skinAmounts]
        _skinAmounts[round - 1].amount = e.target.value
        setSkinAmounts(_skinAmounts)
    }

    const handleCopy = newPaymentSetup => {
        setEntryFeePerPlayer(newPaymentSetup.entryFeePerPlayer.toString())
        setProxyAmount((newPaymentSetup.proxyAmount || 0).toString())
        setMedalistAmounts([...newPaymentSetup.medalistAmounts])
        setMedalistRoundPayments([...(newPaymentSetup.medalistRoundPayments || [])])
        setSkinAmounts((skinAmounts || []).map(x => ({ ...x, amount: (newPaymentSetup.skinAmount || 0).toString() })))
        setRyderCupAwardPerPlayer((newPaymentSetup.ryderCupAwardPerPlayer || 0.00).toString())
    }

    const save = () => {
        const postData = {
            groupId: group._id,
            tripId: trip._id,
            entryFeePerPlayer: entryFeePerPlayer && !isNaN(entryFeePerPlayer) ? parseFloat(entryFeePerPlayer) : 0.00,
            ryderCupAwardPerPlayer: ryderCupAwardPerPlayer && !isNaN(ryderCupAwardPerPlayer) ? parseFloat(ryderCupAwardPerPlayer) : 0.00,
            proxyAmount: proxyAmount && !isNaN(proxyAmount) ? parseFloat(proxyAmount) : 0.00,
            skinAmounts: skinAmounts.map(i => ({ round: i.round, amount: i.amount && !isNaN(i.amount) ? parseFloat(i.amount) : 0.00 })),
            medalistAmounts: medalistAmounts.map(i => ({ rank: i.rank, amount: i.amount && !isNaN(i.amount) ? parseFloat(i.amount) : 0.00 })),
            medalistRoundPayments,
            miscellaneousAdditionalFeePerPlayer: miscellaneousAdditionalFeePerPlayer && !isNaN(miscellaneousAdditionalFeePerPlayer) ? parseFloat(miscellaneousAdditionalFeePerPlayer) : 0.00,
        }
        dispatch(savePaymentSetup(postData))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: "Save Payment Setup", timeout: 5000 }))
        })
    }



    return (
        <div className={s.container}>
            <h4>Player Entry Fee Worksheet</h4>
            <ListGroup className={s.list}>
            {_items && _items.map(i =>
            <PaymentSummaryItem {...i} key={`payment-summary-${i.title}`} />
            )}
            </ListGroup>
            <EntryFeePerPlayer entryFeePerPlayer={entryFeePerPlayer}
                proposedEntryFee={proposedEntryFee} group={group} trip={trip}
                handleEntryFeeChange={e => setEntryFeePerPlayer(e.target.value)}
                onSave={save} handleCopy={handleCopy} />
            <Row>
                <Col xl={4} lg={6} xs={12}>
                    <FormGroup className={s.formGroup}>
                        <PaymentAmountTextBox label={'Amount for each Ryder Cup winning players'} 
                            value={ryderCupAwardPerPlayer}
                            onChange={e => setRyderCupAwardPerPlayer(e.target.value)} />
                        <PaymentAmountTextBox label={'Amount per proxy won'} 
                            value={proxyAmount}
                            onChange={e => setProxyAmount(e.target.value)} />
                        <PaymentAmountTextBox label={'Miscellaneous additional amount per player'} 
                            value={miscellaneousAdditionalFeePerPlayer}
                            onChange={e => setMiscellaneousAdditionalFeePerPlayer(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col xl={4} lg={6} xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel className={s.formLabel}>Skin entry fee per round</FormLabel>
                        {skinAmounts && skinAmounts.map(sa => 
                        <PaymentAmountTextBox key={`skin-amount-${trip._id}-${sa.round}`} 
                            prefix={`Round ${sa.round}`}
                            value={sa.amount}
                            onChange={e => handleSkinChange(e, sa.round)} />
                        )}
                    </FormGroup>

                    <FormGroup className={s.formGroup + ' ' + s.topMargin}>
                        <FormLabel className={s.formLabel}>Medalist winners by round</FormLabel>
                        {medalistRoundPayments && medalistRoundPayments.map((m, index) => (
                            <PaymentAmountTextBox key={`trip-${trip._id}-round-${m.round}-medalist-${m.medalistType}-score-${m.scoreType}`} 
                                prefix={`Round ${m.round}`}
                                roundItem={m}
                                value={m.amount}
                                controls={<Button size='sm' tabIndex={-1} 
                                    className={s.deleteMedalistLevel} 
                                    icon='times'
                                    onClick={() => deleteMedalistRoundLevel(m)} />} />
                        ))}
                        {!medalistRoundPayments?.length ? <p>No round medalist awards located.</p> : null}
                        <AddRoundMedalist existingMedalistRoundPayments={medalistRoundPayments}
                            medalistRoundInfo={medalistRoundInfo}
                            onAdd={handleAddRoundMedalistItem} />
                    </FormGroup>
                </Col>
                <Col xl={4} lg={6} xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel className={s.formLabel}>Overall medalist payouts by rank</FormLabel>
                        {medalistAmounts && medalistAmounts.map((m, index) => [
                            <PaymentAmountTextBox key={`skin-amount-${trip._id}-${m.rank}`} 
                                prefix={`${m.rankOrdinal}`} prefixClass={s.rankOrdinal}
                                value={m.amount}
                                onChange={e => handleMedalistLevelChange(e, m.rank)}
                                controls={<Button size='sm' tabIndex={-1} className={s.deleteMedalistLevel} onClick={() => deleteMedalistLevel(m.rank)} icon='times' />} />,
                            isMedalistOverloaded && numPlayers == index + 1 ? <Alert variant='warning' key={`skin-amount-overload-alert-${trip._id}-${m.rank}`}>You have more medalist levels than players on your trip.  Consider removing a few levels.</Alert> : null
                        ])}
                    </FormGroup>
                    <FormGroup className={s.formGroup}>
                        <Button variant='light' onClick={() => addNewMedalistLevel()} icon='plus' block title='add new level' />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    )
}

export default PaymentSetup