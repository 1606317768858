import React, { useEffect } from 'react'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Alert from '@severed-links/common.alert'

const TripValidationAlert = ({ type = 'info', message = '', adminPath = 'basics', show = false }) => {

    return (
        <Alert show={show} small variant={type} message={message} icon={type === 'warning' ? 'circle-exclamation' : type === 'danger' ? 'triangle-exclamation' : 'info-circle'} />
    )
}

export default TripValidationAlert
