import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OverlayTrigger, Image, Tooltip } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventPlayerIconItem.scss'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'

const TripEventPlayerIconItem = ({ eventId, isTaskEvent, response, playerId, playerName, imageUrl, onTaskResponse }) => {

    return (
        <OverlayTrigger placement='top' overlay={<Tooltip id={`event-response-${response}-${playerId}`}>{playerName}</Tooltip>}>
            <div className={s.container + (onTaskResponse && typeof onTaskResponse === 'function' ? ' ' + s.clickable : '')}
                onClick={onTaskResponse && typeof onTaskResponse === 'function' ? () => onTaskResponse(eventId, playerId, response === 10 ? 11 : 10) : () => void(0)}>
                <Image roundedCircle src={playerAvatarUrl(imageUrl, true)} />
                {!isTaskEvent ? 
                <span className={s.goingCheck}><FontAwesomeIcon name={'check'} /></span>
                :
                <span className={s.goingCheck}><FontAwesomeIcon name={response === 10 ? 'check' : 'times'} /></span>
                }
            </div>
        </OverlayTrigger>
    )
}

export default TripEventPlayerIconItem
