import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Row, Col, Form, FormGroup, FormControl, FormLabel, 
    FormText, Button, InputGroup, ListGroup, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { resetSiteRegistration, siteRegistrationCheck, issueConfirmationCodes,
    setUserInfo, checkIfCellPhoneAvailable, checkIfEmailAvailable, setSignUpValue, 
    validateSignUpToken, createAccount, checkPasswordStrength } from '@severed-links/common.severedlinks-reducers/siteRegistration'
import validator from 'validator'
import './ProgressTracker.scss'
import * as s from './SignUp.scss'
import ReactCodeInput from 'react-code-input'
import Modal from '@severed-links/common.modal'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import PasswordFormControl from './PasswordFormControl'
import { PUBLIC_URL } from '@severed-links/common.severedlinks-constants'

const Required = () => (
    <span style={{ color: 'red' }}><sup>*</sup></span>
)

const SignUp = () => {

    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const { firstName, lastName, emailAddress, cellPhone, password,
        isChecking, isOpen, signUpCode,
        emailAddressVerificationCode, cellPhoneVerificationCode,
        emailIsValid, cellPhoneIsValid, emailIsAvailable, passwordIsValid, 
        cellPhoneIsAvailable } = useSelector(state => state.siteRegistration)
    const [showConfirm,setConfirm] = useState(false)
    const [isSaving,setSaving] = useState(false)
    const signUpCodeParam = (params || {}).signUpCode

    useEffect(() => {
        dispatch(siteRegistrationCheck())
        checkForSignupCodeAndReroute()
    }, [])
    useEffect(() => validateEmailAddress(emailAddress), [emailAddress])
    useEffect(() => validateCellPhone(cellPhone), [cellPhone])

    const checkForSignupCodeAndReroute = () => {
        if (signUpCodeParam) {
            dispatch(resetSiteRegistration())
            dispatch(setSignUpValue({ signUpCode: signUpCodeParam }))
            dispatch(validateSignUpToken(signUpCodeParam))
            navigate('/sign-up')
            setConfirm(false)
        }
    }

    const signupIsValid = () => !validator.isEmpty(firstName) && !validator.isEmpty(lastName) &&
        emailIsValid && cellPhoneIsValid && passwordIsValid &&
        emailIsAvailable && cellPhoneIsAvailable

    const validateEmailAddress = emailAddress => {
        const emailIsValid = !validator.isEmpty(emailAddress) && validator.isEmail(emailAddress)
        if (emailIsValid) {
            dispatch(checkIfEmailAvailable(emailAddress))
            .then(action => {
                dispatch(setSignUpValue({ emailIsValid, emailIsAvailable: action.payload.isAvailable }))
            })            
        } else {
            dispatch(setSignUpValue({ emailIsValid, emailIsAvailable: false }))
        }
    }

    const validateCellPhone = cellPhone => {
        const cellPhoneIsValid = !validator.isEmpty(cellPhone) && validator.isMobilePhone(cellPhone, 'en-US')
        if (cellPhoneIsValid) {
            dispatch(checkIfCellPhoneAvailable(cellPhone))
            .then(action => {
                dispatch(setSignUpValue({ cellPhoneIsValid, cellPhoneIsAvailable: action.payload.isAvailable }))
            })            
        } else {
            dispatch(setSignUpValue({ cellPhoneIsValid, cellPhoneIsAvailable: false }))
        }
    }

    const updateSignupField = (fieldName, value) => dispatch(setSignUpValue({ [fieldName]: value }))

    const handlePasswordChange = passwordData => dispatch(setSignUpValue({ password: passwordData.password, passwordIsValid: passwordData.passwordIsValid, passwordScore: passwordData.passwordStrength.score }))

    const showConfirmModal = () => {
        dispatch(issueConfirmationCodes({ firstName, lastName, emailAddress, cellPhone, emailAddressVerificationCode, cellPhoneVerificationCode, signUpCode }))
        .then(action => {
            dispatch(createNotification({ headline: 'Sign up confirmation codes', timeout: 4000, ...action.payload }))
            setConfirm(true)
        })
    }

    const changeCode = (e, type) => {
        dispatch(setSignUpValue({ [`${type}VerificationCode`]: e }))
    }

    const doSignup = () => {
        const newUserData = {
            firstName, lastName, emailAddress, cellPhone, password,
            emailAddressVerificationCode, cellPhoneVerificationCode, signUpCode
        }
        setSaving(true)
        dispatch(createAccount(newUserData))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
            setSaving(false)
            const _confirm = action.payload.messageType !== 'success'
            setConfirm(_confirm)
            if (!_confirm) {
                setTimeout(() => navigate('/login'), 2500)
            }
        })
    }

    return (
        <div className={s.signupContainer}>
        <Row>
            <Col xs={{ span: 12 }} sm={{ offset: 1, span: 10 }} md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
                <Row>
                    <Col xs={12}>
                        <h2>Sign Up for an Account</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={s.signupContainer}>
                        {!isChecking && isOpen ? 
                            <div>
                                <h4>Welcome to Severed Links Registration!</h4>
                                <p>We'll have you planning a trip or playing in one in no time.</p>
                                <div className='signup-form'>
                                    <FormGroup className={s.formGroup}>
                                        <Row>
                                            <Col sm={6} xs={12}>
                                                <FormLabel style={{ display: 'block'}}>First name</FormLabel>
                                                <FormControl value={firstName} size='lg'
                                                    required isValid={!validator.isEmpty(firstName)}
                                                    isInvalid={validator.isEmpty(firstName)}
                                                    onChange={e => updateSignupField('firstName', e.target.value)} />
                                            </Col>
                                            <Col sm={6} xs={12}>
                                                <FormLabel style={{ display: 'block'}}>Last name</FormLabel>
                                                <FormControl value={lastName} size='lg'
                                                    required isValid={!validator.isEmpty(lastName)}
                                                    isInvalid={validator.isEmpty(lastName)}
                                                    onChange={e => updateSignupField('lastName', e.target.value)} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className={s.formGroup}>
                                        <Row>
                                            <Col sm={6} xs={12}>
                                                <FormLabel style={{ display: 'block'}}>Email (and user name)</FormLabel>
                                                <InputGroup size='lg'>
                                                    <InputGroup.Text><FontAwesomeIcon name='envelope' /></InputGroup.Text>
                                                    <FormControl value={emailAddress} 
                                                        required isValid={emailIsValid && emailIsAvailable}
                                                        isInvalid={!emailIsValid || !emailIsAvailable}
                                                        onChange={e => updateSignupField('emailAddress', e.target.value)} />
                                                        {emailIsValid && !emailIsAvailable ? <FormControl.Feedback type='invalid'>This email is already registered to another account.</FormControl.Feedback> : null}
                                                </InputGroup>                                                    
                                            </Col>
                                            <Col sm={6} xs={12}>
                                                <FormLabel style={{ display: 'block'}}>Cell phone (10 digits only)</FormLabel>
                                                <InputGroup size='lg'>
                                                    <InputGroup.Text><FontAwesomeIcon name='mobile' /></InputGroup.Text>
                                                    <FormControl value={cellPhone} 
                                                        required isValid={cellPhoneIsValid && cellPhoneIsAvailable}
                                                        isInvalid={!cellPhoneIsValid || !cellPhoneIsAvailable}
                                                        onChange={e => updateSignupField('cellPhone', e.target.value)} />
                                                        {cellPhoneIsValid && !cellPhoneIsAvailable ? <FormControl.Feedback type='invalid'>This cell phone number is already registered to another account.</FormControl.Feedback> : null}
                                                </InputGroup>                                                    
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className={s.formGroup}>
                                        <Row>
                                            <Col sm={6} xs={12}>
                                                <PasswordFormControl size='lg'
                                                    onChange={handlePasswordChange}
                                                    labelText='Password' />
                                            </Col>
                                            <Col sm={6} xs={12} style={{ textAlign: 'right' }}>
                                                <FormLabel style={{ display: 'block'}}>&nbsp;</FormLabel>
                                                <Button variant='primary' size='lg' disabled={!signupIsValid()} onClick={() => showConfirmModal()}><FontAwesomeIcon name='check' /> sign up</Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </div>
                            </div>
                        : null}

                        {!isChecking && !isOpen ?
                        <div>
                            <h4>
                                This site is not accepting registrations for 
                                new users at this point.  
                            </h4>
                            <p>Please check back later.</p>
                        </div>
                        : null}

                        <p><small>{signUpCode ? `Sign up code: ${signUpCode}` : null}</small></p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <p>By signing up for an account with our site, you agree to the following policies:</p>
                        <ListGroup className={s.policyList}>
                        {[
                            { title: `Terms of Service`, href: `${PUBLIC_URL}/terms-of-service` },
                            { title: `Privacy Policy`, href: `${PUBLIC_URL}/privacy-policy` },
                        ].map((i, _index) =>
                        <ListGroup.Item key={`policy-link-${_index}`}
                            className={s.policyItem}
                            action onClick={() => window.open(i.href, '_blank')}>
                            <div className={s.title}>{i.title}</div>
                            <div className={s.link}>{i.href}</div>
                        </ListGroup.Item>
                        )}
                        </ListGroup>
                        <p><small>Click on each item to view the associated policy.</small></p>
                    </Col>
                </Row>
            </Col>
            <Modal show={showConfirm} heading='Enter confirmation codes'
                actionButtonOnClick={() => doSignup()}
                actionButtonText='sign up'
                actionButtonDisabled={isSaving || !validator.isLength(emailAddressVerificationCode, { min: 5, max: 5 }) || !validator.isLength(cellPhoneVerificationCode, { min: 5, max: 5 })}
                onClose={() => setConfirm(false)}>
                <div className={s.signUpCodeContainer}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel style={{ display: 'block' }}>Enter the confirmation code sent to your email...</FormLabel>
                        <ReactCodeInput type='text' pattern='^[0-9]*$' fields={5} 
                            value={emailAddressVerificationCode}
                            autoFocus onChange={e => changeCode(e, 'emailAddress')} />
                    </FormGroup>
                    <FormGroup className={s.formGroup}>
                        <FormLabel style={{ display: 'block' }}>...<u>and</u> enter the confirmation code texted to your cell phone</FormLabel>
                        <ReactCodeInput type='text' pattern='^[0-9]*$' fields={5} 
                            value={cellPhoneVerificationCode}
                            autoFocus={false} onChange={e => changeCode(e, 'cellPhone')} />
                    </FormGroup>
                    <Alert className={s.delayAlertContainer} variant='info'>Sometimes the email and text confirmation codes can take a few minutes.  Keep checking!</Alert>
                </div>
            </Modal>
        </Row>
        </div>
    )
}

export default SignUp
