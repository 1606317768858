import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Row, Col, FormGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TextInput from '@severed-links/common.text-input'
import Alert from '@severed-links/common.alert'
import Button from '@severed-links/common.button'
import * as s from './Login.scss'
import validator from 'validator'
import SpinnerOverlay from '@severed-links/common.spinner-overlay'
import { login, getTimeZone, getMe, setAccountState,
    broadcastLoginNotification } from '@severed-links/common.severedlinks-reducers/account'
import moment from 'moment'

const Login = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const { isAuthenticated, access_token, expirationDate, error, error_description, isLoggingIn, isGlobalAdminChecked, isGettingDetails } = useSelector(state => state.account)
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [userNameValid, setUserNameValid] = useState(false)
    const [passwordValid, setPasswordValid] = useState(false)
    const _isBeforeExpirationDate = expirationDate && moment(expirationDate).isValid() && moment().isBefore(moment(expirationDate))

    useEffect(() => {
        if (isAuthenticated && access_token && _isBeforeExpirationDate && !isLoggingIn && !isGettingDetails) {
            getProfileDetails()
        } 
    }, [isAuthenticated, isLoggingIn, _isBeforeExpirationDate, access_token, isGettingDetails])

    useEffect(() => {
        if (isAuthenticated && _isBeforeExpirationDate && (isGlobalAdminChecked || location.pathname.toLowerCase().indexOf('global-admin') === -1) && !isLoggingIn && !isGettingDetails) {
            redirectToView()
        }
    }, [isAuthenticated, isGettingDetails, _isBeforeExpirationDate, location.pathname, isLoggingIn, isGlobalAdminChecked])

    useEffect(() => updateValidations(), [userName, password])

    const handleChange = (_fieldName, _value) => {
        if (_fieldName === 'userName') setUserName(_value)
        else if (_fieldName === 'password') setPassword(_value)
    }

    const handleSubmit = () => {
        if (userNameValid && passwordValid) {
            dispatch(login(userName, password))
        }
        return false
    }

    const updateValidations = () => {
        setUserNameValid(!validator.isEmpty(userName) && validator.isLength(userName, { min: 3 }) && validator.isEmail(userName))
        setPasswordValid(!validator.isEmpty(password) && validator.isLength(password, { min: 3 }))
    }

    const redirectToView = () => {
        var _path = '/groups'
        if (location.state && location.state.from && location.state.from.pathname) {
            _path = `${location.state.from.pathname}${location.state.from.search || ''}`
        }
        navigate(_path, { replace: true })
    }

    const getProfileDetails = () => {
        dispatch(setAccountState({ isGettingDetails: true }))
        dispatch(getTimeZone()),
        dispatch(broadcastLoginNotification()),
        dispatch(getMe())
        .then(() => dispatch(setAccountState({ isGettingDetails: false })))
    }

    const showSpinner = isLoggingIn || isGettingDetails
    const spinnerMessage = isLoggingIn ? 'Logging in...' : isGettingDetails ? 'Loading your profile...' : ''
    return (
        <div className={s.container}>
            <Row>
                <Col xs={{ span: 10, offset: 1 }} sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }}>
                
                <div className={s.formContainer}>
                    
                    <SpinnerOverlay show={showSpinner} 
                        message={spinnerMessage} />

                    <h2 className={s.header}>Login</h2>

                    <form onSubmit={e => handleSubmit(e)}>
                        <FormGroup className={s.formGroup}>
                            <TextInput required value={userName} 
                                label='Email address'
                                isValid={userNameValid}
                                showValidState autoFocus autoCapitalize='none'
                                onChange={_text => handleChange('userName', _text)}
                                buttonProps={{ onClick: handleSubmit }} />
                        </FormGroup>
                        <FormGroup className={s.formGroup}>
                            <TextInput required value={password} 
                                label='Password' type='password'
                                isValid={passwordValid}
                                showValidState autoCapitalize='none'
                                onChange={_text => handleChange('password', _text)}
                                buttonProps={{ onClick: handleSubmit }} />
                        </FormGroup>
                        <FormGroup controlId='formSubmit' className={s.formGroup + ' ' + s.submitButtonContainer}>
                            <Button variant='primary' size='lg'
                                disabled={!userNameValid || !passwordValid || isLoggingIn}
                                isOutline={!userNameValid || !passwordValid || isLoggingIn}
                                brandColor='severedLinks' block
                                title='login' icon='sign-in-alt'
                                onClick={() => handleSubmit()} />
                        </FormGroup>
                    </form>

                    {error ? <Alert variant='danger' icon='exclamation-triangle' message={error_description} small /> : null}

                </div>
                </Col>
            </Row>
        </div>
    )
}

export default Login