import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import pluralize from 'pluralize'
import * as s from './TripRoundScorecardAdminSaveAlert.scss'

const TripRoundScorecardAdminSaveAlert = () => {

    const dispatch = useDispatch()
    const changesToSave = useSelector(state => state.scorecard.admin.changesToSave || [])
    const changesSaving = useSelector(state => state.scorecard.admin.changesSaving || [])

    var variant = 'success'
    var icon = 'check'
    var message = 'No changes.'
    if (changesToSave.length) {
        variant = 'danger'
        icon = 'exclamation-triangle'
        message = `${pluralize('unsaved change', changesToSave.length, true)}...`
    } else if (changesSaving.length) {
        variant = 'warning'
        icon = 'exclamation-circle'
        message = `Saving ${pluralize('change', changesSaving.length, true)}...`
    }
    return (
        <Alert variant={variant} className={s.headerAlert}>
            <div className={s.icon}><FontAwesomeIcon name={icon} /></div>
            <div className={s.message}>{message}</div>
        </Alert>
    )
}

export default TripRoundScorecardAdminSaveAlert