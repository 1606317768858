// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripEventLocation__container___yYk3W{font-weight:bold;margin-bottom:10px}.TripEventLocation__container___yYk3W.TripEventLocation__hasGeocodedAddress___FZ2Ij{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/TripEventLocation.scss"],"names":[],"mappings":"AAGA,sCACI,gBAAA,CACA,kBAAA,CACA,oFACI,cAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n@use '../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n    font-weight: bold;\n    margin-bottom: 10px;\n    &.hasGeocodedAddress {\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripEventLocation__container___yYk3W`;
export var hasGeocodedAddress = `TripEventLocation__hasGeocodedAddress___FZ2Ij`;
export default ___CSS_LOADER_EXPORT___;
