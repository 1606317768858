import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Row, Col, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripAddNew.scss'
import Modal from '@severed-links/common.modal'
import TripBasicsEntry from './TripBasicsEntry'
import { createNewTrip, getTrips } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { getMyUpcomingTrips } from '@severed-links/common.severedlinks-reducers/player'

const TripAddNew = ({ group = {}, showExpanded = false, isGlobalAdmin = false, isGlobalAdminPath = false }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const isGroupAdmin = (group.role || {}).isAdmin || isGlobalAdmin
 
    const saveNewTrip = _trip => {
        dispatch(createNewTrip(_trip))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
            dispatch(getMyUpcomingTrips())
            .then(() => {
                dispatch(getTrips(group._id))
                if (action.payload.messageType === 'success') {
                    cancelNewTrip()
                    if (action.payload.tripId) {
                        const path = isGlobalAdminPath && isGlobalAdmin ? `/global-admin/groups/${group.name.substring(0,1).toUpperCase()}/view/${group._id}/` : `/group/${group._id}/`
                        navigate(`${path}trip/${action.payload.tripId}`)
                    }
                }
            })
        })
    }

    const cancelNewTrip = () => setShow(false)

    return (
        isGroupAdmin ?
        <div className={s.container}>
            <Button variant='light' onClick={() => setShow(true)} variant={showExpanded ? 'primary' : 'light'}><FontAwesomeIcon name='plus' />{showExpanded ? ` add new trip` : null}</Button>
            <Modal show={show} showFooter={true}
                heading={`Add new trip`}
                showFooter={false}
                onClose={() => setShow(false)}>
                <div>
                    <TripBasicsEntry group={group}
                        trip={{ _id: null, tripName: '', isActive: false, canPlayersRsvp: false }} 
                        onSave={saveNewTrip} onCancel={cancelNewTrip} isGlobalAdmin={isGlobalAdmin} />
                </div>
            </Modal>
        </div>
        : null
    )
}

export default TripAddNew