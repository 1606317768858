import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../../Player/PlayerListItem'
import * as s from './MvpAwardWinner.scss'
import { getStewartAwardWinner, clearMvpAwardWinner } from '@severed-links/common.severedlinks-reducers/groups'
import Confirm from '@severed-links/common.confirm'

const MvpAwardWinner = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const mvpAwardName = (group || {}).mvpAwardName
    const stewartAwardWinner = (trip || {}).stewartAwardWinner

    useEffect(() => {
        dispatch(getStewartAwardWinner(group._id, trip._id))
    }, [])

    const resetWinner = () => dispatch(clearMvpAwardWinner(group._id, trip._id))

    if (!stewartAwardWinner) return null
    return (
        <div className={s.container}>
            {mvpAwardName ? <h4>{mvpAwardName}</h4> : null}
            <ListGroup>
                <PlayerListItem player={stewartAwardWinner} variant='info'
                    rightDetail={<div style={{ marginRight: '12px' }}>
                        <Confirm title={`Reset ${mvpAwardName} Winner`}
                            onConfirm={() => resetWinner()} enforceFocus={false}
                            confirmText='reset winner'
                            body={<div><p>Are you sure you want to reset this winner?</p></div>}
                            variant='danger' buttonIcon='times' clear />
                    </div>}
                    clickDisabled />
            </ListGroup>
        </div>
    )
}

export default MvpAwardWinner