import React, { useEffect } from 'react'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import GroupRole from './GroupRole'
import Button from 'react-bootstrap/Button'
import * as s from './LargeGroupHeader.scss'

const LargeGroupHeader = ({ groupName, role, onBack, show = false, showRole = false }) => {

    return (
        <h2 className={s.container + (show ? ' ' + s.show : '')}>
            <Button className={s.goBack} size='lg' variant='light' title='Back to groups' onClick={() => onBack()} ><FontAwesomeIcon name='chevron-left' /></Button>
            <div className={s.title}>{groupName}</div>
            
            {showRole ? <div className={s.role}><GroupRole role={role} /></div> : null}
        </h2>
    )
}

export default LargeGroupHeader