import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Tooltip, OverlayTrigger, Badge, Image, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './OnlineUsers.scss'
import { playerAvatarUrl, FRIENDLY_SHORT_TIME_FORMAT } from '@severed-links/common.severedlinks-constants'
import moment from 'moment-timezone'
import Modal from '@severed-links/common.modal'

const OnlineUser = ({ user }) => (
    <div className={s.onlineUser}>
        <OverlayTrigger placement='top' trigger={['hover','focus']} 
            overlay={<Tooltip className={s.onlineUserTooltip} id={`online-user-tooltip-${user.playerId}`}>
                {user.firstName} {user.lastName} {moment(user.connectedSince).format(FRIENDLY_SHORT_TIME_FORMAT)}
                {' '}
                {user.connectionCount > 1 ? <Badge bg='info'>{user.connectionCount}</Badge> : null}
                </Tooltip>}>
            <Image src={playerAvatarUrl(user.imageUrl, true)} className={s.avatar} />
        </OverlayTrigger>
    </div>
)

const OnlineUserList = ({ onlineUsers, block }) => (
    <div className={s.onlineUsersContainer + (block ? ' ' + s.block : '')}>
        <div className={s.onlineUsers}>
        {onlineUsers && onlineUsers.map(i => 
        <OnlineUser user={i} key={`online-user-${i.playerId}`} />
        )}
        </div>
    </div>
)

const OnlineUsers = ({ group = {}, isContained = false, isGlobalAdmin = false }) => {

    const onlineUsers = group.onlineUsers || []
    const groupId = group._id || null
    const [show, setShow] = useState(false)

    if (!onlineUsers || onlineUsers.length === 0) return null
    return (
        <>
        <div className={s.container + (isContained ? ' ' + s.isContained : '')}>
            {!isContained ? <FontAwesomeIcon title={`Online users: ${onlineUsers.length}`} name='bolt' className={s.icon} /> : null}
            <OnlineUserList onlineUsers={onlineUsers} block={false} />
            <Button variant='light' onClick={() => setShow(true)} className={s.onlineUserButton}>
                <span className={s.buttonTitle}>Users online:</span>
                {' '}
                {onlineUsers.length}
            </Button>
        </div>
        <Modal show={show} showFooter={false} size='sm' 
            heading={<span><FontAwesomeIcon name='bolt' /> Online Users: {onlineUsers.length}</span>}
            onClose={() => setShow(false)}>
            <OnlineUserList onlineUsers={onlineUsers} block />
        </Modal>
        </>
    )
}

export default OnlineUsers