import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, useMatch } from 'react-router-dom'
import { Button, Nav, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { first } from 'lodash'
import { getBullJobs } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import { FRIENDLY_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import * as s from './GlobalAdminJobs.scss'
import Confirm from '@severed-links/common.confirm'
import GlobalAdminJobQueueItem from './GlobalAdminJobQueueItem'

const GlobalAdminJobs = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { jobs } = useSelector(state => state.globalAdmin)

    useEffect(() => {
    }, [])

    useEffect(() => loadData(), [])

    const loadData = () => {
        dispatch(getBullJobs())
    }

    const handleClick = _queue => {
        navigate(`/global-admin/jobs/queue/${encodeURIComponent(_queue.name)}`)
    }


    return (
        <div className={s.container}>
            <h3 className={s.header}><FontAwesomeIcon name='list-check' /> Job Queues</h3>
            <ListGroup className={s.list}>
            {jobs && jobs.map(i =>
            <GlobalAdminJobQueueItem key={`global-admin-bull-job-${i.name}`}
                item={i} onClick={() => handleClick(i)} />
            )}
            {!jobs.length ? <ListGroup.Item>No jobs located.</ListGroup.Item> : null}
            </ListGroup>
        </div>
    )
}

export default GlobalAdminJobs