// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripEventEditorMiniBadge__container___UIaVH{margin-left:.5rem;display:flex;flex-flow:row nowrap;justify-content:center;align-items:center;font-size:.75rem;border-radius:.5rem}.TripEventEditorMiniBadge__container___UIaVH .TripEventEditorMiniBadge__icon___VqxkW{margin-right:.25rem}.TripEventEditorMiniBadge__container___UIaVH .TripEventEditorMiniBadge__count___dGxKj{white-space:nowrap}.TripEventEditorMiniBadge__container___UIaVH .TripEventEditorMiniBadge__suffix___v6TD4{margin-left:.15rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Admin/TripEventEditorMiniBadge.scss"],"names":[],"mappings":"AAGA,6CACI,iBAAA,CACA,YAAA,CACA,oBAAA,CACA,sBAAA,CACA,kBAAA,CACA,gBAAA,CACA,mBAAA,CAEA,qFACI,mBAAA,CAGJ,sFACI,kBAAA,CAGJ,uFACI,kBAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n@use '../../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n    margin-left: 0.5rem;\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: center;\n    align-items: center;\n    font-size: 0.75rem;\n    border-radius: 0.5rem;\n\n    .icon {\n        margin-right: 0.25rem;\n    }\n\n    .count {\n        white-space: nowrap;\n    }\n\n    .suffix {\n        margin-left: 0.15rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripEventEditorMiniBadge__container___UIaVH`;
export var icon = `TripEventEditorMiniBadge__icon___VqxkW`;
export var count = `TripEventEditorMiniBadge__count___dGxKj`;
export var suffix = `TripEventEditorMiniBadge__suffix___v6TD4`;
export default ___CSS_LOADER_EXPORT___;
