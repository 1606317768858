import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../../Player/PlayerListItem'
import { getLoginInfo } from '@severed-links/common.severedlinks-reducers/groups'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import * as s from './LoginInfo.scss'

const LoginDisplay = ({ lastLogin, logins = 0 }) => {
    const lastLoginDate = moment(lastLogin)
    return (
        <div className={s.loginInfo}>
            {logins ? <div>{pluralize('login', logins, true)}</div> : <div>Never logged in</div>}
            {lastLoginDate.isValid() ? <div className={s.lastLogin}>last active {lastLoginDate.fromNow()}</div> : null}
        </div>
    )
}

const LoginInfo = () => {

    const dispatch = useDispatch()
    const { group, groupId, isGroupAdmin, isGlobalAdmin } = useOutletContext()
    const logins = (group || {}).logins || { players: [], lastTripIndicators: [] }
    const players = [...(logins.players || []).map(i => ({ ...i, lastTripIndicator: logins.lastTripIndicators.find(x => x.playerID === i._id) }))]

    useEffect(() => {
        dispatch(getLoginInfo(groupId))
    }, [])

    if (!group || !groupId) return null
    return (
        <div className={s.container}>
            <ListGroup>
            {players && players.map(p =>
                <PlayerListItem key={`logins-${p._id}`} player={p} 
                    rightDetail={<LoginDisplay lastLogin={p.lastLogin} logins={p.logins} />} />
            )}
            {!players || players.length === 0 ? <ListGroup.Item>No player logins found for this group.</ListGroup.Item> : null}
            </ListGroup>
        </div>
    )
}

export default LoginInfo