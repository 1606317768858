import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useOutletContext } from 'react-router-dom'
import { Row, Col, ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from './PlayerListItem'
import PlayerFilter from './PlayerFilter'
import FloatHeaderWithButtons from '../../components/FloatHeaderWithButtons'
import PendingInvitesList from '../Groups/Admin/PendingInvitesList'
import ReactivationRequests from '../Groups/Admin/ReactivationRequests'
import { startsWith, first } from 'lodash'
import { updateGroupState, getPendingInvites, setPlayerFilterLetter } from '@severed-links/common.severedlinks-reducers/groups'

const Players = () => {

    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const { group, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const players = group.players || []
    const playerFilter = group.playerFilter || ''
    const filterLetter = ((params || {}).letter || '').toUpperCase()
    const filteredPlayers = filterLetter ? players.filter(p => startsWith(p.lastName.toString().toUpperCase(), filterLetter)) : players
    const pendingInvites = group.pendingInvites || []
    const reactivationRequests = group.reactivationRequests || []
    const hasNoPlayers = !players || players.length === 0
    const path = isGlobalAdminPath ? `/global-admin/groups/filter/${(group.name || '').substring(0,1).toUpperCase()}/view/${group._id}/` : `/group/${group._id}/`

    useEffect(() => {
        if (group._id && isGroupAdmin) {
            dispatch(getPendingInvites(group._id))
        }

        if (!filterLetter && players.length > 25) {
            const _letter = first(players).lastName.toUpperCase().substring(0,1)
            const _filterLetter = filterLetter || _letter
            dispatch(setPlayerFilterLetter(group._id, _filterLetter))
            navigate(`${path}players/filter/${_filterLetter}`, { replace: true })
        }
    }, [])

    if (!group || !group._id) return null

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <FloatHeaderWithButtons header='Players' headerTag='h3' buttons={isGroupAdmin || isGlobalAdmin ? [
                        { text: '', title: 'invite players', variant: 'light', icon: 'plus', onClick: () => navigate(`${path}players/invite-players`) },
                        pendingInvites && pendingInvites.length ? { text: `pending invites (${pendingInvites.length})`, title: 'pending group invites', variant: 'info', icon: 'user', onClick: () => dispatch(updateGroupState(group._id, { showPendingInvites: true })) } : null,
                        reactivationRequests && reactivationRequests.length ? { text: `reactivation requests (${reactivationRequests.length})`, title: 'reactivation requests', variant: 'warning', icon: 'sync', onClick: () => dispatch(updateGroupState(group._id, { showReactivationRequests: true })) } : null,
                    ] : []} />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <PlayerFilter players={players} playerFilter={playerFilter} 
                        isGlobalAdminPath={isGlobalAdminPath} group={group} />
                    <ListGroup>
                    {filteredPlayers && filteredPlayers.map(p => 
                        <PlayerListItem player={p} key={p._id} 
                            isGlobalAdminPath={isGlobalAdminPath} 
                            globalAdminLetter={((group || {}).name || ' ').substring(0,1).toUpperCase()} />
                    )}
                    {hasNoPlayers ? <ListGroup.Item>No players have joined this group.</ListGroup.Item> : null}
                    </ListGroup>
                </Col>
            </Row>
            {isGroupAdmin || isGlobalAdmin ? <PendingInvitesList group={group} /> : null}
            {isGroupAdmin || isGlobalAdmin ? <ReactivationRequests group={group} /> : null}
        </div>
    )
}

export default Players