// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HandicapTrackerAddRound__container___sUPjU{margin-bottom:20px}.HandicapTrackerAddRound__container___sUPjU .HandicapTrackerAddRound__typeahead___s1Q2k{flex:1 1 auto}.HandicapTrackerAddRound__container___sUPjU .HandicapTrackerAddRound__typeahead___s1Q2k .rbt-highlight-text{padding:0px}`, "",{"version":3,"sources":["webpack://./src/routes/Player/HandicapTrackerAddRound.scss"],"names":[],"mappings":"AAAA,4CACI,kBAAA,CAEA,wFACI,aAAA,CAEA,4GACI,WAAA","sourcesContent":[".container {\n    margin-bottom: 20px;\n\n    .typeahead {\n        flex: 1 1 auto;\n\n        :global(.rbt-highlight-text) {\n            padding: 0px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `HandicapTrackerAddRound__container___sUPjU`;
export var typeahead = `HandicapTrackerAddRound__typeahead___s1Q2k`;
export default ___CSS_LOADER_EXPORT___;
