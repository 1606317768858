// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripEventsAdmin__container___eCfMK{margin-bottom:1rem}.TripEventsAdmin__container___eCfMK .TripEventsAdmin__headingContainer___seLQ5{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center;margin-bottom:1rem}.TripEventsAdmin__container___eCfMK .TripEventsAdmin__headingContainer___seLQ5 .TripEventsAdmin__title___oUCLZ{margin:0rem;flex:1 1 auto}.TripEventsAdmin__container___eCfMK .TripEventsAdmin__headingContainer___seLQ5 .TripEventsAdmin__controls___pPrEL{margin-left:1rem;white-space:nowrap}.TripEventsAdmin__container___eCfMK .TripEventsAdmin__list___W1gcD{margin-bottom:1rem}.TripEventsAdmin__newEventContainer___aefAS .TripEventsAdmin__newEventButton___AqlEq{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Admin/TripEventsAdmin.scss"],"names":[],"mappings":"AAGA,oCACI,kBAAA,CAEA,+EACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAEA,+GACI,WAAA,CACA,aAAA,CAGJ,kHACI,gBAAA,CACA,kBAAA,CAIR,mEACI,kBAAA,CAKJ,qFACI,kBAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n@use '../../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n    margin-bottom: 1rem;\n\n    .headingContainer {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n        margin-bottom: 1rem;\n\n        .title {\n            margin: 0rem;\n            flex: 1 1 auto;\n        }\n\n        .controls {\n            margin-left: 1rem;\n            white-space: nowrap;\n        }\n    }\n\n    .list {\n        margin-bottom: 1rem;\n    }\n}\n\n.newEventContainer {\n    .newEventButton {\n        margin-bottom: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripEventsAdmin__container___eCfMK`;
export var headingContainer = `TripEventsAdmin__headingContainer___seLQ5`;
export var title = `TripEventsAdmin__title___oUCLZ`;
export var controls = `TripEventsAdmin__controls___pPrEL`;
export var list = `TripEventsAdmin__list___W1gcD`;
export var newEventContainer = `TripEventsAdmin__newEventContainer___aefAS`;
export var newEventButton = `TripEventsAdmin__newEventButton___AqlEq`;
export default ___CSS_LOADER_EXPORT___;
