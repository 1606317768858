// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripRecapMockAwardItem__container___pmSa_{margin-bottom:12px}.TripRecapMockAwardItem__container___pmSa_ .TripRecapMockAwardItem__heading___vreCr{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.TripRecapMockAwardItem__container___pmSa_ .TripRecapMockAwardItem__heading___vreCr .TripRecapMockAwardItem__title___X2STP{flex:1 1 auto}.TripRecapMockAwardItem__container___pmSa_ .TripRecapMockAwardItem__heading___vreCr .TripRecapMockAwardItem__controls___tgw5j,.TripRecapMockAwardItem__container___pmSa_ .TripRecapMockAwardItem__heading___vreCr .TripRecapMockAwardItem__isNotVisible___dwNTm{margin-left:1rem;flex:0 0 0;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/TripRecapMockAwardItem.scss"],"names":[],"mappings":"AAAA,2CACI,kBAAA,CAEA,oFACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,2HACI,aAAA,CAIJ,gQACI,gBAAA,CACA,UAAA,CACA,kBAAA","sourcesContent":[".container {\n    margin-bottom: 12px;\n\n    .heading {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n\n        .title {\n            flex: 1 1 auto;\n\n        }\n\n        .controls, .isNotVisible {\n            margin-left: 1rem;\n            flex: 0 0 0;\n            white-space: nowrap;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripRecapMockAwardItem__container___pmSa_`;
export var heading = `TripRecapMockAwardItem__heading___vreCr`;
export var title = `TripRecapMockAwardItem__title___X2STP`;
export var controls = `TripRecapMockAwardItem__controls___tgw5j`;
export var isNotVisible = `TripRecapMockAwardItem__isNotVisible___dwNTm`;
export default ___CSS_LOADER_EXPORT___;
