import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation, Outlet, useOutletContext, Link } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Button from '@severed-links/common.button'
import Modal from '@severed-links/common.modal'
import Picker from '@severed-links/common.picker'
import * as s from './TripContacts.scss'
import AddressBookContactListItem from '../../Groups/Admin/AddressBookContactListItem'
import { addGroupAddressBookEntryToTrip, removeGroupAddressBookEntryFromTrip } from '@severed-links/common.severedlinks-reducers/groups'

const TripContacts = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const { group, groupId, trip, tripId, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [showAddContact, setShowAddContact] = useState(false)
    const addressBook = group?.addressBook || []
    const _tripContacts = addressBook.filter(i => (i.trips || []).some(t => t._id === tripId))
    const _pickableContacts = addressBook.filter(i => !(i.trips || []).some(t => t._id === tripId))
        .map(i => ({ ...i, title: `${i.firstName} ${i.lastName}`, subtitle: i.company, icon: 'user', value: i._id }))

    useEffect(() => {
    }, [])

    const handleSelect = _item => {
        setShowAddContact(false)
        dispatch(addGroupAddressBookEntryToTrip(groupId, tripId, _item.value))
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h4 className={s.title}>Trip Contacts</h4>
                {addressBook.length ?
                <div className={s.controls}>
                    <Button variant='primary' icon='plus' title='add contact to trip' onClick={() => setShowAddContact(true)} />
                </div>
                : null}
            </div>

            <p>Contacts are loaded from your group address book <Link to={`/group/${groupId}/admin/address-book`}>here</Link>.</p>

            <ListGroup className={s.list}>
            {_tripContacts && _tripContacts.map(i =>
            <AddressBookContactListItem {...i} tripId={tripId}
                key={`group-${groupId}-trip-${tripId}-contact-${i._id}`} />
            )}
            </ListGroup>

            <Picker show={showAddContact} 
                onClose={() => setShowAddContact(false)} 
                items={_pickableContacts}
                defaultValue={null}
                onSelect={handleSelect} />

        </div>
    )
}

export default TripContacts
