import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

import SettingControl from './SettingControl'

const TripPhotoAdmin = () => {

    const { group, groupId, trip, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    if (!group || !trip || !trip.settings) return null
    const settings = trip.settings || {}

    return (
        <div>
            <h4>Photo Editing/Delete Settings</h4>
            <SettingControl variableType='bool' 
                groupId={group._id} tripId={trip._id}
                title={'Players can upload photos'} 
                variable='canPlayersUploadPhotos' 
                value={settings.canPlayersUploadPhotos} />
            <SettingControl variableType='bool' 
                groupId={group._id} tripId={trip._id}
                title={'Players can edit photo captions'} 
                variable='canPlayersEditPhotoCaptions' 
                value={settings.canPlayersEditPhotoCaptions} />
            <SettingControl variableType='bool' 
                groupId={group._id} tripId={trip._id}
                title={'Players can delete photos'} 
                variable='canPlayersDeletePhotos' 
                value={settings.canPlayersDeletePhotos} />
        </div>
    )
}

export default TripPhotoAdmin