import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Button, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from './PlayerListItem'
import Modal from '@severed-links/common.modal'
import * as s from './PlayerPickerModal.scss'
import { getPlayerInfoNoUpdate, getPlayerNotes } from '@severed-links/common.severedlinks-reducers/player'
import { playerAvatarUrl, getOrdinalForNumber } from '@severed-links/common.severedlinks-constants'
import ParScoreDisplay from '../Trips/ParScoreDisplay'
import moment from 'moment-timezone'

const PlayerInfoButton = ({ player, onClick }) => (
    <span className={s.playerInfoContainer}>
        <Button onClick={e => onClick(e, player)} className={s.button}>
            <FontAwesomeIcon name='info-circle' title={player.playerName} />
        </Button>
    </span>
)

const HidePlayerInfoButton = ({ onClick }) => (
    <span className={s.playerInfoContainer}>
        <Button size='sm' onClick={e => onClick(e)} className={s.hideButton}>
            <FontAwesomeIcon name='times' />
        </Button>
    </span>
)

const PlayerInfoPanelItem = ({ title = '', subtitle = '', isMiniHeader = false, value = '', imageUrl = null }) => (
    <ListGroup.Item className={s.playerInfoPanelListItem + (isMiniHeader ? ` ${s.miniHeader}` : ``)}>
        {imageUrl ? <div className={s.imageUrl}><Image src={playerAvatarUrl(imageUrl)} /></div> : null}
        <div className={s.playerInfoPanelListItemContent}>
            {title ? <div className={s.title}>{title}</div> : null}
            {subtitle ? <div className={s.subtitle}>{subtitle}</div> : null}
        </div>
        {value ? <div className={s.value}>{value}</div> : null}
    </ListGroup.Item>
)

const PlayerInfoPanel = ({ player, medalist }) => {
    const p = player || {}
    const summary = player.summary || {}
    const wonLossRecords = summary.wonLossRecords || []
    return (
        <div className={s.playerInfoPanel}>
            <ListGroup className={s.playerInfoPanelList}>
            {wonLossRecords.length ? 
            <>
            <PlayerInfoPanelItem title='Records' isMiniHeader />
            {summary.overallRecord ? <PlayerInfoPanelItem title='Overall' value={summary.overallRecord} /> : null}
            {wonLossRecords && wonLossRecords.map(i => 
            <PlayerInfoPanelItem title={i.formatName} value={i.recordString} key={`info-item-${i.formatName}`} />
            )}
            </>
            : null}
            {medalist ? 
            <>
                <PlayerInfoPanelItem title='Medalist Position' isMiniHeader />
                <PlayerInfoPanelItem title='Rank' value={medalist.medalistPosition?.rank ? getOrdinalForNumber(medalist.medalistPosition.rank) : '--'} />
                <PlayerInfoPanelItem title='Gross' value={medalist.medalistPosition?.gross || '--'} />
                <PlayerInfoPanelItem title='Score' value={medalist.medalistPosition?.diff || '--'} />
            </>
            : null}
            {p.notes && p.notes.length > 0 ? 
            <>
                <PlayerInfoPanelItem title='Notes' isMiniHeader />
                {p.notes.map(n => 
                    <PlayerInfoPanelItem title={n.notes} isNote 
                        subtitle={`Updated by ${n.updatedByPlayerName} ${moment(n.updatedAt).format('MMM D YYYY h:mm A')}`}
                        imageUrl={n.updatedByPlayerImageUrl} />
                )}
            </>
            : null}
            </ListGroup>
        </div>
    )
}

const PlayerPickerModal = ({ 
    groupId = null, tripId = null, header = 'Select a player...', players = [], onSelect, onClose,
    show = false, showHcp = false, showInfo = false, playerProps = {}, 
    showMedalistPosition = false, medalist = [], 
}) => {

    const dispatch = useDispatch()
    const [showInfoItem, setShowInfoItem] = useState(false)
    const [infoPlayer, setInfoPlayer] = useState(null)
    const [infoPlayerUpdate, setInfoPlayerUpdate] = useState(null)

    useEffect(() => {
        if (infoPlayerUpdate) {
            setInfoPlayer({ ...(infoPlayer || {}), ...(infoPlayerUpdate || {}) })
        }
    }, [infoPlayerUpdate])

    useEffect(() => {
        if (infoPlayer?.playerID) {
            dispatch(getPlayerInfoNoUpdate(groupId, infoPlayer.playerID))
            .then(action => setInfoPlayerUpdate(action.payload))
        }
    }, [infoPlayer?.playerID || null])

    useEffect(() => {
        if (infoPlayer?.playerID) {
            dispatch(getPlayerNotes(groupId, infoPlayer.playerID))
            .then(action => setInfoPlayerUpdate({ notes: action.payload }))
        }
    }, [infoPlayer?.playerID || null])

    const showInfoModal = (e, p = {}) => {
        e.stopPropagation()
        setInfoPlayer({ ...p })
        setShowInfoItem(true)
    }

    const hideInfo = e => {
        e.stopPropagation()
        setShowInfoItem(false)
        setInfoPlayer(null)
    }

    const getPlayerMedalistStanding = player => {
        return (medalist || []).find(p => p.playerID === player._id)
    }

    var position = {}
    const _players = showMedalistPosition ? [...players.map(p => ({ ...p, medalistPosition: getPlayerMedalistStanding(p) }))] : [...players]
    return (
        <Modal show={show} showFooter={false}
            heading={header}
            onClose={() => onClose()}>
        <div className={s.container}>
            <ListGroup>
            {showInfoItem ? 
                <PlayerListItem {...playerProps} clickDisabled
                    key={`player-picker-modal-info-item-${(infoPlayer || {}).playerId || 0}`}
                    player={infoPlayer} 
                    showRole={false}
                    showHcp rightDetail={<HidePlayerInfoButton onClick={e => hideInfo(e)} />}>
                    <PlayerInfoPanel player={infoPlayer} medalist={medalist} />
                </PlayerListItem>
            : _players && _players.map((p, index) =>
                <PlayerListItem {...playerProps} 
                key={`player-picker-modal-${p.team}-${p.playerId}-${index}`}
                player={p} 
                showRole={false}
                rightDetail={<div className={s.medalistStanding}>
                    {showMedalistPosition && p.medalistPosition ? <div className={s.medalistStandingItem}>{getOrdinalForNumber(p.medalistPosition.rank)}</div> : null}
                    {showMedalistPosition && p.medalistPosition ? <div className={s.medalistStandingItem}><ParScoreDisplay score={p.medalistPosition.diff} /></div> : null}
                    <PlayerInfoButton player={p} onClick={e => showInfoModal(e, p)} />
                </div>}
                showHcp={showHcp}
                onClick={() => onSelect(p)} />
            )}
            {!showInfoItem && (!_players || _players.length === 0) ? 
                <ListGroup.Item>No players available for selection.</ListGroup.Item>
            : null}
            </ListGroup>
        </div>
        </Modal>
    )
}

export default PlayerPickerModal