import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Row, Col, Card, ListGroup, Button, FormGroup, FormLabel, 
    InputGroup, FormControl, Table, Overlay, Popover, Nav, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { range, reject, orderBy, isEqual, uniq, maxBy, flatten } from 'lodash'
import { getMatchesScoreboard, saveTeeTimesAndFoursomes } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import moment from 'moment-timezone'
import { DEFAULT_TIME_ZONE, FRIENDLY_DATE_FORMAT, FRIENDLY_SHORT_DATE_FORMAT, 
    FRIENDLY_SHORT_TIME_FORMAT, playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import * as s from './TripFoursomesAdmin.scss'
import { DragSource, DropTarget } from 'react-dnd'
import MiniMatchItem from './FoursomesComponents/MiniMatchItem'
import UnassignedBlankMatchItem from './FoursomesComponents/UnassignedBlankMatchItem'
import FoursomeBlankMatchSlot from './FoursomesComponents/FoursomeBlankMatchSlot'
import Confirm from '@severed-links/common.confirm'
import Player from '../../Player/Player'
import Datetime from 'react-datetime'
import { useDrop } from 'react-dnd'
import { ITEM_TYPES } from './DragAndDropItemTypes'

const TeeTimeAdjuster = ({ show, target, foursome, teeTime, timeZoneId, onClick, onChange }) => (
    <div>
        {teeTime.isValid() ?
        <Button variant='light' size='sm' onClick={() => onClick(foursome)}>
            <FontAwesomeIcon name='clock' /> {teeTime.format(FRIENDLY_SHORT_TIME_FORMAT)}
        </Button>
        : null}
        <Overlay
          show={show}
          target={target}
          placement='bottom'>
          <Popover id={`popover-tee-time-${foursome}`} title={`Tee time - foursome #${foursome}`}>
            <div className={s.teeTimePopover}>
                <Datetime value={teeTime} 
                    onChange={dt => onChange(dt)}
                    input={false}
                    dateFormat={false} open
                    timeFormat={FRIENDLY_SHORT_TIME_FORMAT} />
            </div>
          </Popover>
        </Overlay>
    </div>
)

const StartingHoleAdjuster = ({ show, target, numHoles, foursome, startingHole, onClick, onChange }) => (
    <div className={s.startingHoleContainer}>
        <Button variant='light' 
            size='sm' onClick={() => onClick(foursome)}>
                <FontAwesomeIcon name='flag' /> Hole #{startingHole}
            </Button>
        <Overlay
          show={show}
          target={target}
          placement='bottom'>
          <Popover id={`popover-starting-hole-${foursome}`} title={`Starting hole - foursome #${foursome}`}>
              <div className={s.startingHolePopver}>
                <Nav className={s.nav} variant='pills' activeKey={startingHole} onSelect={e => onChange(parseInt(e))}>
                {numHoles && range(1, numHoles + 1, 1).map(hole =>
                <Nav.Item key={`starting-hole-${hole}`} className={s.item}>
                    <Nav.Link className={s.link} eventKey={hole} disabled={startingHole === hole}>{hole}</Nav.Link>
                </Nav.Item>
                )}
                </Nav>
            </div>
          </Popover>
        </Overlay>
    </div>
)

const FoursomeCard = ({ foursome, numHoles = 18, isShotgun, showTeeTimeFoursome, 
    showStartingHoleFoursome, onClickTeeTimeAdjusterFoursome,
    onClickStartingHoleFoursome, onChangeTeeTimeAdjusterFoursome,
    onChangeStartingHoleFoursome, playersPerMatch, matchesPerFoursome,
    handleAssignMatchToFoursome, _timeZoneId }) => {
    const i = foursome || {}
    const _teeTimeAdjusterRef = useRef(null)
    const _startingHoleAdjusterRef = useRef(null)
    return (
    <Card className={s.foursomePanel}>
        <Card.Header className={s.foursomeTitle}>
            <div>#{i.foursome}</div>
            {!isShotgun? 
                <div ref={_teeTimeAdjusterRef}>
                <TeeTimeAdjuster show={showTeeTimeFoursome === i.foursome}
                    foursome={i.foursome} timeZoneId={_timeZoneId}
                    teeTime={moment.tz(i.teeTime, _timeZoneId)}
                    target={_teeTimeAdjusterRef}
                    onClick={onClickTeeTimeAdjusterFoursome}
                    onChange={onChangeTeeTimeAdjusterFoursome} /> 
                </div>
            : null}
            {i.matches && i.matches.length > 0 && isShotgun ? 
                <div ref={_startingHoleAdjusterRef}>
                <StartingHoleAdjuster show={showStartingHoleFoursome === i.foursome}
                    numHoles={numHoles}
                    foursome={i.foursome}
                    target={_startingHoleAdjusterRef}
                    onClick={onClickStartingHoleFoursome}
                    onChange={onChangeStartingHoleFoursome}
                    startingHole={i.startingHole} /> 
                </div>
            : null}
        </Card.Header>
        <ListGroup className={s.foursomeListGroup + (playersPerMatch === 1 ? ' ' + s.singles : '')} variant='flush'>
            {i.matches && i.matches.map(m => 
                <MiniMatchItem match={m} foursome={i.foursome} key={`assigned-foursome-${m._id}`} />
            )}
            {range(1, matchesPerFoursome - i.matches.length + 1, 1).map(x =>
                <FoursomeBlankMatchSlot foursome={i.foursome} onDrop={handleAssignMatchToFoursome} key={`blank-unassigned-match-${x}`} />
            )}
        </ListGroup>
    </Card>

    )
}

const ConfirmedFoursomes = ({ tripId = null, round = 0, isShotgun = false, foursomes = [], _timeZoneId }) => (
    <div className={s.confirmedFoursomesTableContainer}>
        <Table striped>
            <tbody>
            {foursomes && orderBy(foursomes, [isShotgun ? 'startingHole' : 'teeTime', 'foursome'], ['asc','asc']).map(i => 
            <tr key={`trip-${tripId}-${round}-${i.foursome}`}>
                <td style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                {!isShotgun ? 
                moment.tz(i.teeTime, _timeZoneId).format(FRIENDLY_SHORT_TIME_FORMAT)
                : 
                `Hole #${i.startingHole}`
                }
                </td>
                <td>
                {i.matches && flatten(i.matches.map(x =>
                    [...(x.usaPlayerList || []), ...(x.eurPlayerList || [])].map(p => `${p.firstName} ${p.lastName}`)
                )).join(', ')}
                </td>
            </tr>)}
            </tbody>
        </Table>
    </div>
)

const TripFoursomesAdmin = () => {

    const dispatch = useDispatch()
    const { group, trip, groupId, tripId, round, roundSubTab, roundParam, roundNumber, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [unassignedMatches, setUnassignedMatches] = useState([])
    const [foursomes, setFoursomes] = useState([])
    const [showStartingHoleFoursome, setShowStartingHoleFoursome] = useState(false)
    const [showTeeTimeFoursome, setShowTeeTimeFoursome] = useState(null)
    const teeTimeInterval = round.teeTimeInterval || 10
    const isShotgun = round.isShotgun || false
    const _timeZoneId = (round.timezone || {}).timeZoneId || (trip.timezone || {}).timeZoneId || DEFAULT_TIME_ZONE
    const startTime = moment.tz(round.roundDate, _timeZoneId).format(FRIENDLY_SHORT_DATE_FORMAT) + ' ' + round.teeTime
    const playersPerMatch = round.playersPerMatch || 0
    const usaPlayers = trip.usaPlayers || []
    const eurPlayers = trip.eurPlayers || []
    const matches = orderBy(((trip.scoreboard || {}).matches || []).filter(i => i.round === round.round), [!round.isShotgun ? 'teeTime' : 'startingHole', 'match'], ['asc', 'asc'])
    const totalMatches = matches.length
    const matchesPerFoursome = 4 / ((round.playersPerMatch || 2) * 2)
    const numFoursomes = Math.ceil((usaPlayers.length + eurPlayers.length) / 4)
    const foursomeNumbers = range(1, numFoursomes + 1, 1)
    const originalFoursomes = foursomes || []
    const _startingHolesChanged = !isEqual(originalFoursomes.map(i => i.startingHole), foursomes.map(i => i.startingHole))
    const _teeTimesChanged = !isEqual(originalFoursomes.map(i => i.teeTime), foursomes.map(i => i.teeTime))
    const matchNumbers = uniq(matches.filter(i => i.match && (i.eurPlayerList.length || i.usaPlayerList.length)).map(i => i.match))
    const latestMatchUpdate = (maxBy(matches, 'updatedAt') || {}).updatedAt || null

    useEffect(() => {
        initializeState()
    }, [usaPlayers.length, eurPlayers.length, totalMatches, latestMatchUpdate])

    const mapFoursome = (foursomeNumber = 0, matches = []) => ({ 
        foursome: foursomeNumber, matches,
        teeTime: moment.tz(startTime, FRIENDLY_DATE_FORMAT, _timeZoneId).add((foursomeNumber - 1) * parseInt(teeTimeInterval), 'minutes'),
        startingHole: (matches.find(m => m.foursome === foursomeNumber) || {}).startingHole || 1,
    })

    const initializeState = () => {
        const _interval = !isShotgun ? round.teeTimeInterval : 0
        const _unassignedMatches = matches.filter(m => m.match && foursomeNumbers.indexOf(m.foursome) === -1)
        setInterval(_interval)
        setUnassignedMatches(_unassignedMatches)
        setFoursomes(foursomeNumbers.map(i => mapFoursome(i, matches.filter(m => m.foursome === i))))
    }

    const handleDropToUnassigned = item => {
        if (!item || !item.match) return null
        var _unassignedMatches = [...unassignedMatches]
        const _unassignedMatchIndex = _unassignedMatches.findIndex(i => i && i._id === item.match._id)
        if (_unassignedMatchIndex === -1) {
            _unassignedMatches.push(item.match)
        }

        var _foursomes = [...foursomes]
        const _foursomeIndex = _foursomes.findIndex(i => i && i.foursome === item.foursome)
        if (_foursomeIndex > -1) {
            const _matchIndex = (_foursomes[_foursomeIndex].matches || []).findIndex(i => i && i._id === item.match._id)
            _foursomes[_foursomeIndex].matches.splice(_matchIndex, 1)
        }
        setFoursomes(_foursomes)
        setUnassignedMatches(_unassignedMatches)
    }

    const handleAssignMatchToFoursome = (item, toFoursome) => {
        var _unassignedMatches = [...unassignedMatches]
        const _unassignedMatchIndex = _unassignedMatches.findIndex(i => i && i._id === item.match._id)
        if (_unassignedMatchIndex > -1) {
            _unassignedMatches.splice(_unassignedMatchIndex, 1)
        }

        var _foursomes = [...foursomes]
        const _checkExistingFoursomeIndex = _foursomes.findIndex(i => i && (i.matches || []).some(j => j._id === item.match._id))
        if (_checkExistingFoursomeIndex > -1) {
            _foursomes[_checkExistingFoursomeIndex].matches = reject(_foursomes[_checkExistingFoursomeIndex].matches, { _id: item.match._id })
        }
        const _toFoursomeIndex = _foursomes.findIndex(i => i && i.foursome === toFoursome)
        if (_toFoursomeIndex > -1) {
            _foursomes[_toFoursomeIndex].matches.push(item.match)
        }
        setFoursomes(_foursomes)
        setUnassignedMatches(_unassignedMatches)
    }

    const clearAllFoursomes = () => {
        setUnassignedMatches(matches)
        setFoursomes(foursomeNumbers.map(i => mapFoursome(i, [])))
    }

    const save = () => {
        var _postData = { 
            groupId, tripId, round: round.round, 
            foursomeAssignments: foursomes.map(f => ({
                ...f,
                teeTime: !isShotgun ? moment.tz(f.teeTime, _timeZoneId).toISOString(true) : moment.tz(startTime, FRIENDLY_DATE_FORMAT, _timeZoneId).toISOString(true),
                startingHole: isShotgun ? f.startingHole : 1,
                matches: (f || []).matches.map(m => m.match),
            })),
        }
        dispatch(saveTeeTimesAndFoursomes(_postData))
        .then(action => {
            dispatch(createNotification(action.payload))
        })
    }

    const onClickStartingHoleFoursome = foursome => setShowStartingHoleFoursome(showStartingHoleFoursome === foursome ? null : foursome)

    const onChangeStartingHoleFoursome = startingHole => {
        var _foursomes = [...foursomes]
        const _index = _foursomes.findIndex(i => i && i.foursome === showStartingHoleFoursome)
        if (_index > -1) {
            _foursomes[_index] = { ..._foursomes[_index], startingHole }
        }
        setShowStartingHoleFoursome(null)
        setFoursomes(_foursomes)
    }

    const onClickTeeTimeAdjusterFoursome = foursome => setShowTeeTimeFoursome(showTeeTimeFoursome === foursome ? null : foursome)

    const onChangeTeeTimeAdjusterFoursome = teeTime => {
        var _foursomes = [...foursomes]
        const _index = _foursomes.findIndex(i => i && i.foursome === showTeeTimeFoursome)
        if (_index > -1) {
            _foursomes[_index] = { ..._foursomes[_index], teeTime }
        }
        setShowStartingHoleFoursome(null)
        setFoursomes(_foursomes)
    }

    const [{ handlerId, isOver }, _dropRef] = useDrop({
        accept: ITEM_TYPES.MATCH,
        collect: (monitor, props) => ({ 
            handlerId: monitor.getHandlerId(),
            isOver: !!monitor.isOver(),
        }),
        drop: (item, monitor) => handleDropToUnassigned(item)
    })
    
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h4 className={s.title}>Arrange Foursomes and Assign {!isShotgun ? 'Tee Times' : 'Starting Holes'} ({!isShotgun ? 'beginning ' : ''}{startTime})</h4>
                <div className={s.controls}>
                    <Button variant='link' onClick={() => clearAllFoursomes()}><FontAwesomeIcon name='times' /> clear all</Button>
                </div>
                {matchNumbers.length && matches.length ?
                <div className={s.controls}>
                    <Confirm title={`Save Revised Foursome ${(isShotgun ? 'Tee Times' : 'Starting Holes')}`}
                        onConfirm={() => save()}
                        confirmText='save'
                        body={<div>
                            <p>Are you sure you want to save these foursomes?</p>
                            <ConfirmedFoursomes tripId={tripId} round={round.round} isShotgun={isShotgun} foursomes={foursomes} _timeZoneId={_timeZoneId} />
                        </div>}
                        variant='primary' buttonIcon='check' buttonText={`save ${!isShotgun ? 'tee times' : 'starting holes'}`} />
                </div>
                : null}
            </div>
            <p>Drag and drop the matches between the unassigned container and the foursomes to assign them.</p>
            {matchNumbers.length && matches.length ?
            <div className={s.foursomeAssignmentContainer}>
                <div className={s.foursomesContainer}>
                {foursomes && foursomes.map(i =>
                    <FoursomeCard foursome={i} isShotgun={isShotgun}
                        showTeeTimeFoursome={showTeeTimeFoursome}
                        playersPerMatch={playersPerMatch}
                        numHoles={round.numHoles}
                        matchesPerFoursome={matchesPerFoursome}
                        showStartingHoleFoursome={showStartingHoleFoursome}
                        onClickStartingHoleFoursome={onClickStartingHoleFoursome}
                        onChangeStartingHoleFoursome={onChangeStartingHoleFoursome}
                        onClickTeeTimeAdjusterFoursome={onClickTeeTimeAdjusterFoursome}
                        onChangeTeeTimeAdjusterFoursome={onChangeTeeTimeAdjusterFoursome}
                        handleAssignMatchToFoursome={handleAssignMatchToFoursome}
                        _timeZoneId={_timeZoneId}
                        key={`trip-${tripId}-${round.round}-${i.foursome}`} />
                )}
                </div>
                <Card ref={_dropRef} className={s.unassignedMatchesContainer + (isOver ? ` ${s.isOver}` : '')}>
                    <Card.Header className={s.unassignedMatchesHeader}>Unassigned Matches {unassignedMatches && unassignedMatches.length ? ` (${unassignedMatches.length})` : ``}</Card.Header>
                    <ListGroup variant='flush' className={s.unassignedListGroup + (playersPerMatch === 1 ? ' ' + s.singles : '')}
                        onDrop={handleDropToUnassigned}>
                    {unassignedMatches && unassignedMatches.map(m =>
                        <MiniMatchItem match={m} foursome={0} 
                            key={`unassigned-match-${m._id}`} />
                    )}
                    {range(1, totalMatches - (unassignedMatches || []).length + 1, 1).map(x =>
                        <UnassignedBlankMatchItem key={`blank-unassigned-match-${x}`} />
                    )}
                    </ListGroup>
                </Card>
            </div>
            : <Alert variant='danger'>You need to assign players to matches in the previous tab before arranging foursomes.</Alert>}
        </div>
    )
}

export default TripFoursomesAdmin