import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ListGroup, Badge, Image, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PlayerListItem.scss'
import GroupRole from '../Groups/GroupRole'
import { playerAvatarUrl, commonImage, commonImageUrl } from '@severed-links/common.severedlinks-constants'
import Modal from '@severed-links/common.modal'
import { getHandicapCheckerForPlayer, saveTeeBoxOverrides } from '@severed-links/common.severedlinks-reducers/groups'
import numeral from 'numeral'
import pluralize from 'pluralize'
import { create, reject } from 'lodash'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import PlayerHandicap from './PlayerHandicap'
import PlayerTeeBoxOverrides from './PlayerTeeBoxOverrides'
import ProxyDistanceDisplay from '../Trips/ProxyDistanceDisplay'

const PlayerListItem = ({ player = {}, leftDetail = null, rightDetail = null, onClick = null, variant = null,
    showRole = true, isUsaCaptain = false, isEurCaptain = false, isSkin = false, isProxy = false,
    showFlag = false, clickDisabled = false, showHcp = false, noWrap = false, active = false,
    disabled = false, inverse = false, isAdmin = false, strokes = 0, className = null, children = null, 
    round = 0, rank = 0, isMini = false, icon = null, flagUrl = '', style = {}, avatarStrokeBadgeStyle = {},
    isGlobalAdminPath = false, globalAdminGroupLetter = 'A', hideTeeBoxOverrides = false,
    topLabelContent = null, showPoints = false,
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const groupId = params.groupId || null
    const rounds = player && player.groupId && player.tripID ?
        ((((useSelector(state => state.groups.groups) || [])
            .find(g => g._id === player.groupId) || {}).trips || [])
            .find(t => t._id === player.tripID) || {}).rounds || [] : []
    const [showHandicapChecker, setShowHandicapChecker] = useState(false)
    const [handicapChecker, setHandicapChecker] = useState(null)
    const [showOverrideEditor, setShowOverrideEditor] = useState(false)
    const [overrides, setOverrides] = useState([])
    const isDeletedUser = player.isDeletedUser || false
    const { imageUrl, firstName, lastName, hcp, team, handicapChecker: _hc } = player || {}

    const _handicapVariant = isAdmin ? (_hc?.isOk ? 'success' :
            _hc?.isWarning ? 'warning' : 
            _hc?.isError ? 'danger' : 'secondary') : 'secondary'

    const loadHandicapChecker = (_player = {}) => {
        if (_player.groupId && (_player.tripId || _player.tripID)) {
            dispatch(getHandicapCheckerForPlayer(_player.groupId, _player.tripId || _player.tripID, _player.playerId || _player.playerID || _player._id))
            .then(action => {
                setShowHandicapChecker(true)
                setHandicapChecker(action.payload)
            })
        }
    }

    const selectPlayer = (player = {}) => {
        const _playerId = player.playerID || player.playerId || player._id || null
        if (clickDisabled || inverse) return null
        if (onClick) {
            onClick(player)
        } else if (_playerId) {
            const _path = isGlobalAdminPath ?
                `/global-admin/groups/filter/${globalAdminGroupLetter}/view/${groupId}/player/${_playerId}`
                : `/group/${groupId}/player/${_playerId}`
            navigate(_path)
        }
    }

    const onSelectTeeBox = (_override = {}) => {
        var _overrides = [...overrides]
        if (_override.round) {
            _overrides = reject(_overrides, r => r.round === _override.round)
        }
        if (!rounds.some(r => r.round === _override.round && r.tees === _override.tees)) {
            _overrides.push(_override)
        }
        setOverrides(_overrides)
    }

    const saveOverrides = () => {
        if (groupId && player.tripID && player.playerId) {
            dispatch(saveTeeBoxOverrides(groupId, player.tripID, player.playerId, overrides))
            .then(action => dispatch(createNotification(action.payload)))
            setShowOverrideEditor(false)
            setOverrides([])
        }
    }

    if (!player) return null
    return (
        <ListGroup.Item variant={variant} action={!clickDisabled} 
            active={active} disabled={disabled}
            className={s.playerListItem + (!variant ? ` ${s.noVariant}` : '') + ' ' + (className || '') + (inverse ? ' ' + s.inverse : '') + (isMini ? ' ' + s.isMini : '')} 
            onClick={!clickDisabled ? () => selectPlayer(player) : undefined}
            style={style}>
            <div className={s.contentContainer}>
            {isSkin || isProxy ? 
                <div className={s.miniHeader}>
                    <div className={s.holeDetails + (inverse ? ' ' + s.inverse : '')}> 
                        {isProxy && player ? <div><b>{player.proxyName}</b><br /></div> : null}
                        <FontAwesomeIcon name='flag' />
                        {' '}
                        #{player ? player.hole : null}
                        {' '}
                        Par {player ? player.par : null}
                        {' '}
                        {player ? player.yards : null} Yards 
                    </div> 
                    <Badge className={s.label} 
                        bg={player.result === "Eagle" || player.result === "Double Eagle" ? 'danger' : player.result === "Birdie" ? 'success' : player.result === 'Par' ? 'info' : 'light'}
                        text={player.result === "Eagle" || player.result === "Double Eagle" ? 'light' : player.result === "Birdie" ? 'light' : player.result === 'Par' ? 'light' : 'dark'}>
                        {player.result}
                        {isSkin ? <br /> : null}
                        {isSkin && !isNaN(player.amount) ? <span>{numeral(player.amount).format('($0.00)')}</span> : null}
                    </Badge> 
                </div>
            : null}
            <div className={s.mainContent}>
                {rank ? <div className={s.rank}>{rank}</div> : null}
                {leftDetail ? <div className={s.leftDetail}>{leftDetail}</div> : null}
                {icon && icon.icon ? <div className={s.icon}><FontAwesomeIcon {...icon} /></div> : null}
                {isMini && showFlag && flagUrl && team && (team || '').toLowerCase() !== 'none' ? <img className={s.flag} src={flagUrl} /> : null}
                <div className={s.avatar}>
                    <div className={s.img} style={{ backgroundImage: `url(${playerAvatarUrl(imageUrl, true)})` }}>
                        {!isMini && showFlag && flagUrl && team && (team || '').toLowerCase() !== 'none' ? <img className={s.flag} src={flagUrl} /> : null}
                        {strokes ? <Badge className={s.avatarStrokeBadge} style={avatarStrokeBadgeStyle} bg={null} text={null}>{strokes}</Badge> : null}
                    </div>
                </div>
                <div className={s.content}>
                    <div className={s.playerNameContainer} title={`${firstName} ${lastName}`}>
                        {!isMini ? <div className={s.firstName}>{firstName}</div> : null}
                        {!isMini ? <div className={s.lastName}>{lastName}</div> : null}
                        {!isMini && children ? <div className={s.detail}>{children}</div> : null}
                        {isMini ? <div className={s.lastName}>{firstName} {lastName}</div> : null}
                    </div>
                </div>
                {isDeletedUser ? 
                <Badge variant='danger'>Deleted</Badge>
                : null}

                {showHcp ?
                <>
                    {!hideTeeBoxOverrides ? <PlayerTeeBoxOverrides teeBoxOverrides={player.teeBoxOverrides} selectedRound={round} /> : null}
                </>
                : null}

                {showPoints && player.record && player.pointsDisplay ?
                <div className={s.pointsContainer}>
                    <div className={s.record}>{player.record}</div>
                    <div className={s.points}>{player.pointsDisplay}</div>
                </div>
                : null}

                {showHcp ?
                <>
                    <PlayerHandicap {...({ hcp: player.hcp, variant: _handicapVariant })} />
                </>
                : null}

                {rightDetail ? <div className={s.rightDetail}>{rightDetail}</div> : null}

                <div className={s.topLabelContainer}>
                    {isUsaCaptain ? <Badge className={s.label} bg='danger'>Captain</Badge> : null}
                    {isEurCaptain ? <Badge className={s.label} bg='info'>Captain</Badge> : null}
                    {showRole && player ? <GroupRole className={s.label} role={player.role} /> : null}
                    {topLabelContent}
                </div>
                </div>                    
            </div>
        </ListGroup.Item>
    )
}

export default PlayerListItem