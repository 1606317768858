import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Outlet } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getLocationCount } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import PlayerListItem from '../Player/PlayerListItem'

const GlobalAdminPlayerList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const letters = useSelector(state => state.globalAdmin.players.letters) || []
    const list = useSelector(state => state.globalAdmin.players.list) || []

    useEffect(() => {
        dispatch(getLocationCount('Players'))
    }, [])

    return (
        <div>
        <ListGroup>
        {list && list.map(p => <PlayerListItem key={`global-admin-${p._id}`} player={p} showRole={false} onClick={() => navigate(`/global-admin/players/edit/${p._id}`)} />)}
        </ListGroup>
        </div>
    )
}

export default GlobalAdminPlayerList