import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './HelpTicketModal.scss'
import Modal from '@severed-links/common.modal'
import EditTicket from './EditTicket'
import { setActiveTicket, loadActiveTicket } from '@severed-links/common.severedlinks-reducers/help'

const HelpTicketModal = () => {

    const dispatch = useDispatch()
    const { ticketId } = useParams()
    const navigate = useNavigate()
    const activeTicket = useSelector(state => state.help.activeTicket)

    useEffect(() => {
        if (ticketId && (!activeTicket?._id || activeTicket?._id !== ticketId)) {
            dispatch(loadActiveTicket(ticketId))
        }
    }, [ticketId, activeTicket?._id])

    const closeModal = () => {
        navigate('/profile/help-center')
    }

    return (
        <Modal heading={(!activeTicket || !activeTicket._id ? 'New ' : '') + ('Help ') + 'Ticket' + (activeTicket && activeTicket.ticketNumber ? ` #${activeTicket.ticketNumber}` : '')}
            show onClose={() => closeModal()}
            showFooter={false}>
            <EditTicket />
        </Modal>
    )
}

export default HelpTicketModal
