import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { isEmpty } from 'validator'
import * as s from './ForgotPassword.scss'
import { resetForgotPassword, setUserInfo,
    checkEmail, checkCellPhone, requestResetCode, verifyResetCode,
    requestPasswordChange } from '@severed-links/common.severedlinks-reducers/forgotPassword'

const SendByButton = ({ sendBy, isActive, title, handleClick }) => (
    <Button variant={isActive ? 'primary' : 'light'} size='lg'
        onClick={() => handleClick()} className={s.sendByButton}>
        <FontAwesomeIcon className={s.sendByIcon} name={sendBy === 'Email' ? 'envelope' : sendBy === 'Text' ? 'mobile' : 'question-mark'} />
        <span className={s.sendByTitle}>send via {title}</span>
        <span className={s.sendByTitleMobile}>{title}</span>
    </Button>
)

const ForgotPasswordStep1 = forwardRef(({ jumpToStep, ...props }, _ref) => {

    const dispatch = useDispatch()
    const fp = useSelector(state => state.forgotPassword)
    const [sendBy,setSendBy] = useState('')
    
    useEffect(() => {
        dispatch(resetForgotPassword())
    }, [])

    const handleClick = _sendBy => {
        setSendBy(_sendBy)
        dispatch(setUserInfo({ sendBy: _sendBy }))
        jumpToStep(1)
    }  
    
    useImperativeHandle(_ref, () => ({
        isValidated() { return ['Email','Text'].includes(fp.sendBy) }
    }))
    
    const _sendByButtons = [
        { title: 'email', sendBy: 'Email' },
        { title: 'SMS', sendBy: 'Text' },
    ]

    return (
        <div>
            <div className={s.stepHeading}>
                <p className={s.stepTitle}>We can send you a code to your email address or your cell phone number
                    that is associated with your Severed Links account.</p>
            </div>
            <div className={s.sendByButtons}>
            {_sendByButtons && _sendByButtons.map(i =>
            <SendByButton title={i.title} sendBy={i.sendBy}
                isActive={fp.sendBy === i.sendBy}
                handleClick={() => handleClick(i.sendBy)}
                key={`send-by-button-${i.sendBy}`} />
            )}
            </div>
        </div>
    )
})

export default ForgotPasswordStep1
