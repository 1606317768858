import React, { useEffect } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../Player/PlayerListItem'
import ProxyDistanceDisplay from './ProxyDistanceDisplay'
import * as s from './TripRoundSkinsProxies.scss'

const TripRoundSkinsProxies = ({ trip = {}, list = [], title = '', listType = '' }) => {

    const isSkin = listType === 'skins'
    const isProxy = listType === 'proxies'
    return (
        <Card className={s.container}>
            <Card.Header>{title}</Card.Header>
            <ListGroup variant='flush'>
            {list && list.map((i, index) =>
                <PlayerListItem player={i} 
                    showFlag
                    flagUrl={trip[`${(i.team || '').toLowerCase()}FullTeamImageUrl`]}
                    showRole={false}
                    isSkin={isSkin && (index === 0 || list[index].hole !== list[index - 1].hole)}
                    isProxy={isProxy}
                    rightDetail={isProxy && i.playerID && i.units ? <ProxyDistanceDisplay className={s.proxyDistance} {...i} /> : null}
                    key={`trip-${listType}-${i.tripID}-${i.playerID}-${i.hole}`} />
            )}
            {!list || list.length === 0 ? <ListGroup.Item>No {isSkin ? 'skins' : ''}{isProxy ? 'proxies' : ''} located.</ListGroup.Item> : null}
            </ListGroup>
        </Card>
    )
}

export default TripRoundSkinsProxies