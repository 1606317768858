import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { Row, Col, Button, FormGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import Confirm from '@severed-links/common.confirm'
import { setTripLodgingNotNeeded, getTripSettings, lodgingNameQuery, saveLodgingLocation } from '@severed-links/common.severedlinks-reducers/groups'
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import * as s from './TripLodgingAdminToolbar.scss'

const TripLodgingAdminToolbar = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [options, setOptions] = useState([])
    const [isLoading, setLoading] = useState(false)
    const tripLocation = trip.location || {}
    const isLodgingDefined = (trip.settings || {}).isLodgingDefined || false
    const isLodgingNeeded = (trip.settings || {}).isLodgingNeeded || false

    const confirmNoLodging = () => {
        dispatch(setTripLodgingNotNeeded(group._id, trip._id))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (action.payload.messageType === 'success') {
                dispatch(getTripSettings(group._id, trip._id))
            }
        })
    }

    const handleChange = e => {
        if (e && e.length > 0) {
            dispatch(saveLodgingLocation(group._id, trip._id, e[0]._id))
            .then(action => {
                setShow(false)
                setOptions([])
                dispatch(createNotification({ ...action.payload }))
                dispatch(getTripSettings(group._id, trip._id))
            })
        }
    }

    const runQuery = useCallback(query => {
        setLoading(true)
        dispatch(lodgingNameQuery(group._id, trip._id, query))
        .then(action => {
            setOptions(action.payload)
            setLoading(false)
        })
    }, [])

   return (
        <div className={s.container}>
            <div className={s.buttonsContainer}>
                <Button className={s.button} onClick={() => setShow(true)} variant={isLodgingNeeded || !isLodgingDefined ? 'primary' : undefined}><FontAwesomeIcon name='pencil-alt' /> edit lodging location</Button>
                {isLodgingNeeded ? 
                    <Confirm title={'No Lodging Needed'}
                        onConfirm={() => confirmNoLodging()}
                        confirmText='confirm no lodging needed'
                        body={<div><p>Are you sure you want to indicate that this trip does not have lodging defined?</p></div>}
                        variant='warning' buttonIcon='times' buttonText='trip does not need lodging' buttonClassName={s.button} />
                : null}
            </div>

            <Modal show={show} showFooter={false}
                heading={`Look up Lodging`}
                onClose={() => setShow(false)}>
                <div>
                    <FormGroup>
                        <AsyncTypeahead id={`trip-${trip._id}-lodging-admin-toolbar-lodging-typeahead`}
                        onSearch={runQuery}
                        isLoading={isLoading}
                        multiple={false} autoFocus
                        labelKey={o => `${o.locName} (${o.locCity}, ${o.locState})`}
                        renderMenu={(results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => (
                            <Menu {...menuProps}>
                                {results.map((o, index) => (
                                <MenuItem option={o} position={index} key={`tpyeahead-location-item-${o._id}`}>
                                    <b>{o.locName}</b>
                                    <br />
                                    <small>{o.locCity}, {o.locState}</small>
                                </MenuItem>
                                ))}
                            </Menu>
                        )}
                        placeholder={'[Look up location by name...]'}
                        options={options}
                        onChange={e => handleChange(e)} />
                    </FormGroup>
                </div>
            </Modal>

        </div>
    )
}

export default TripLodgingAdminToolbar