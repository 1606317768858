import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation, useOutletContext } from 'react-router-dom'
import { Button, Image, Overlay, Tooltip, Popover, Carousel,
    FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripPhotoGallery.scss'
import Gallery from 'react-photo-gallery'
import { getTripPhotos, photoSaveCaption, deleteTripPhoto, postTripPhotoLike, postTripPhotoComment, deleteTripPhotoComment, updateTrip } from '@severed-links/common.severedlinks-reducers/groups'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { getTripPhotoLikeTypes } from '@severed-links/common.severedlinks-reducers/trips'
import TripPhotoCaptionEditor from './TripPhotoCaptionEditor'
import TripPhotoUploader from './TripPhotoUploader'
import { playerAvatarUrl, FRIENDLY_DATE_FORMAT, img, tripPhotoUrl, routingPath } from '@severed-links/common.severedlinks-constants'
import moment from 'moment-timezone'
import { pick, trim, omit, orderBy } from 'lodash'
import TextareaAutosize from 'react-autosize-textarea'

const styleFn = styleObj => ({ ...styleObj, zIndex: 2040 })

const CustomGalleryHeader = ({ _id, groupName, tripName, locationName, player, onClose, 
    showUploadingPlayerInfo, onClickUploadingPlayer, canDeletePhoto, canEditCaption, 
    onEditCaption, onDeletePhoto, dateUploaded, src }) => {
    const tooltipTarget = useRef(null)
    return (
        <div className={s.customHeader}>
            <div className={s.title}>
                <div className={s.groupName}>{groupName}</div>
                <div className={s.tripName}>{tripName} <span className={s.locationName}>({locationName})</span></div>
            </div>
            <div className={s.controls}>

            {player ? 
                <div className={s.uploadPlayerContainer}>
                    <Image onClick={() => onClickUploadingPlayer(!showUploadingPlayerInfo)} 
                        src={playerAvatarUrl(player.imageUrl, true)} 
                        className={s.lightBoxUploadingUserImage}
                        ref={tooltipTarget} /> 
                    <Overlay show={showUploadingPlayerInfo} 
                        target={tooltipTarget.current}
                        placement='bottom'>
                        <Tooltip id={`trip-photo-tooltip-${_id}`} 
                            className={s.tripPhotoTooltip}>
                            Uploaded by {player.firstName} {player.lastName} at {moment(dateUploaded).format(FRIENDLY_DATE_FORMAT)}
                        </Tooltip>
                    </Overlay>
                </div>
            : null}

            {canEditCaption ? 
                <Button className={s.button} onClick={() => onEditCaption(_id)} 
                    title='edit photo caption'>
                    <FontAwesomeIcon name='pencil-alt' />
                </Button> 
            : null}
            {canDeletePhoto ? 
                <Confirm title={'Delete Photo'}
                    className={s.photoGalleryModal}
                    onConfirm={() => onDeletePhoto(_id)}
                    confirmText='delete photo'
                    body={<div><p>Are you sure you want to delete this photo?</p><p style={{ textAlign: 'center' }}>{src ? <Image src={src} style={{ maxHeight: '200px', maxWidth: '200px' }} /> : null}</p></div>}
                    variant='danger' buttonClassName={s.button} buttonIcon='trash' buttonText='' />
            : null}

                <Button className={s.button} onClick={() => onClose()}>
                    <FontAwesomeIcon name='times' />
                </Button>

            </div>
        </div>
    )
}

const CustomGalleryFooter = ({ caption }) => {
    return (
        <div className={s.customFooter}>{caption}</div>
    )
}

const CountIcon = ({ variant = 'info', icon = 'question-mark', count = 0 }) => (
    <div className={s.countIconContainer + (variant ? ` ${s[`${variant}`]}` : ``)}>
        <FontAwesomeIcon name={icon} className={s.icon} />
        <div className={s.count}>{count}</div>
    </div>
)

const CountButton = ({ className = null, isDarkColor = true, variant = 'info', icon = 'question-mark', count = 0, onClick, isMe = false }) => (
    <Button className={s.countIconContainer + (className ? ` ${className}` : '') + (isDarkColor ? ` ${s.darkColor}` : '') + ` ${s.isLarge}` + (variant ? ` ${s[`${variant}`]}` : ``)}
        onClick={() => onClick()}>
        <FontAwesomeIcon name={icon} isRegular={!isMe} className={s.icon} />
        <div className={s.count}>{count}</div>
    </Button>
)

const TripPhotoGalleryImageFooter = ({ likeCounts = [], commentCount = 0 }) => (
    commentCount || likeCounts.filter(i => i.count).length ?
    <div className={s.galleryImageFooter}>
        <div className={s.likeCountContainer}>
        {likeCounts && likeCounts.filter(i => i.count).map(i =>
            <CountIcon {...i} key={`count-icon-${i.type}`} />
        )}
        </div>
        {commentCount ? 
        <div className={s.commentCountContainer}>
            <CountIcon variant='info' icon='comment' count={commentCount} />
        </div>
        : null}
    </div>
    : null
)

const CustomGalleryImage = ({ index, photo, margin, direction, top, left, selected, onClick }) => (
      <div className={s.galleryImageContainer} 
        style={{ margin }}
        onClick={onClick ? e => onClick(e, { index }) : null}>
          <img {...omit(photo, ['commentCount','likeCounts','totalLikes','likes'])} />
          <TripPhotoGalleryImageFooter likeCounts={photo.likeCounts} commentCount={photo.commentCount} />
      </div>
)

const NewCommentEditor = ({ newComment = '', onChange, onPost }) => (
    <FormGroup className={s.newCommentEditorContainer}>
        <InputGroup className={s.inputGroup}>
            <TextareaAutosize rows={1} 
                value={newComment} 
                className={`form-control ${s.textBox}`}
                placeholder={'[Post a comment here...]'}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                        if (newComment && onPost) onPost()
                    }
                }}
                onChange={e => onChange(e.target.value)} />
            <Button disabled={!newComment} variant={newComment ? 'primary' : 'light'} onClick={() => onPost()}><FontAwesomeIcon name='comment' /></Button>
        </InputGroup>
    </FormGroup>
)

const ImageLikeButtonBar = ({ isDarkColor = true, tripPhotoId, likeCounts = [], commentCount = 0, onClick, onShowCommenter }) => (
    tripPhotoId ?
    <div className={s.likeButtonBarContainer}>
    {likeCounts && likeCounts.map(i =>
    <CountButton {...i} isDarkColor={isDarkColor} 
        key={`count-button-${i.type}`}
        onClick={() => onClick(tripPhotoId, i.type)} />
    )}
    <CountButton className={s.commenterButton} isMe={commentCount > 0} 
        icon='comment' variant='info' 
        count={commentCount} isDarkColor={isDarkColor}
        onClick={() => onShowCommenter()} />
    </div>
    : null
)

const ImageCommenter = ({ show = false, onClose, comments = [], newComment = '', onChange, onPost, onDelete }) => (
    <>
    <div className={s.imageCommenterContainer + (show ? ` ${s.showCommenter}` : '')}>
        <div className={s.closeButtonContainer}>
            <Button onClick={() => onClose()} className={s.closeButton}><FontAwesomeIcon name='times' /></Button>
        </div>
        <NewCommentEditor newComment={newComment} onChange={onChange} onPost={onPost} />
        <div className={s.commentsContainer}>
        {comments && comments.map(i =>
        <TripPhotoComment {...i} key={`trip-photo-comment-${i._id}`} onDelete={onDelete} />
        )}
        {!comments || !comments.length ? <div className={s.noComments}>No comments for this photo were located.</div> : null}
        </div>
    </div>
    <div className={s.imageCommenterContainerOverlay + (show ? ` ${s.showCommenterOverlay}` : '')} onClick={() => onClose()} />
    </>
)

const TripPhotoComment = ({ isFirst = false, _id, firstName, lastName, playerName, imageUrl, comment, updatedAt, isMyComment, canDelete, onDelete }) => (
    <div className={s.commentContainer}>
        <div className={s.commentContent}>
            <Image src={playerAvatarUrl(imageUrl)} 
                className={s.avatarContainer} />
            <div className={s.commentDetail}>
                <div className={s.commenterName}>{firstName} {lastName}</div>
                <div className={s.commentText}>{comment}</div>
                {canDelete ? 
                <div className={s.deleteContainer} onClick={e => e.stopPropagation()}>
                    <Confirm title={'Delete Photo'}
                        className={s.photoGalleryModal}
                        onConfirm={() => onDelete(_id)}
                        confirmText='delete comment'
                        body={<p>Are you sure you want to delete this comment?</p>}
                        variant='danger' buttonClassName={s.deleteCommentButton} buttonTitle='delete photo' buttonIcon='trash' />
                </div>
                : null}
            </div>
        </div>
        <div className={s.fromNowContainer}>
            <div className={s.fromNowText}>{moment(updatedAt).fromNow()}</div>
        </div>
    </div>
)

const TripPhotoGallery = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const { tripPhotoId } = params
    const isOpen = !!tripPhotoId
    const playerId = useSelector(state => state.account._id)
    const { group, trip, groupId, tripId, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const isAdmin = ((group || {}).role || {}).isAdmin || isGlobalAdmin
    const tripPhotoLikeTypes = useSelector(state => state.trips.tripPhotoLikeTypes)
    const arePhotosLoaded = trip?.arePhotosLoaded || false
    const [firstCarouselOpen, setFirstCarouselOpen] = useState(false)
    const [showEditCaptionModal, setShowEditCaptionModal] = useState(false)
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [showUploadingPlayerInfo, setShowUploadingPlayerInfo] = useState(false)
    const [editCaptionPhoto, setEditCaptionPhoto] = useState(null)
    const [newComment, setNewComment] = useState('')
    const [currentTime, setCurrentTime] = useState(moment().toISOString(true))
    const [showCommenter, setShowCommenter] = useState(false)

    const lightBoxImages = (trip.photos || [])
        .map(p => ({ 
            ...p, 
            src: tripPhotoUrl(p.tripID, p.imageUrl), 
            ...img.TripPhotoSrcSetGenerator(p.tripID, p.imageUrl), 
            key: `trip-${p.tripID}-photo-${p._id}-${p.imageUrl}`,
            aspectRatio: p.height ? ((p.width || 0) / p.height) : 0.00,
            totalPhotoCount: (trip.photos || []).length,
            likes: p.likes || [],
            likeCounts: (p.likeCounts || []).map(x => ({ ...x, isMe: (p.likes || []).some(l => l.playerId === playerId && l.likeType === x.type) })),
            comments: orderBy((p.comments || []).map(i => ({ ...i, isMyComment: (p.likes || []).filter(x => x.likeType === i.type).some(x => `${x.playerId}` === `${playerId}`) }))
                .map(i => ({ ...i, canDelete: isAdmin || i.isMyComment || false })), ['updatedAt'], ['desc'])
        }))

    if (!group || !trip) return null
    const canPlayersDeletePhotos = ((trip || {}).settings || {}).canPlayersDeletePhotos
    const canPlayersUploadPhotos = ((trip || {}).settings || {}).canPlayersUploadPhotos
    const canPlayersEditPhotoCaptions = ((trip || {}).settings || {}).canPlayersEditPhotoCaptions
    const canEditCaption = isAdmin || (canPlayersEditPhotoCaptions && playerId === (_currentPhoto || {}).uploadingPlayerID)
    const canDeletePhoto = isAdmin || (canPlayersDeletePhotos && playerId === (_currentPhoto || {}).uploadingPlayerID)
    const groupName = group.name
    const tripName = trip.tripName
    const locationName = (trip.location || {}).locName
    const basePath = `${routingPath(groupId, isGlobalAdminPath, (group.name || ' ').substring(0,1))}trip/${tripId}/photos`
    const _currentImageIndex = lightBoxImages.findIndex(i => i._id === tripPhotoId)
    const _currentPhoto = _currentImageIndex > -1 ? lightBoxImages[_currentImageIndex] : {}

    useEffect(() => {
        dispatch(getTripPhotoLikeTypes())
        dispatch(getTripPhotos(groupId, tripId))
        .then(() => {
            dispatch(updateTrip({ groupId, tripId, arePhotosLoaded: true }))
        })
    }, [])

    useEffect(() => {
        let _timerId = setInterval(() => setCurrentTime(moment().toISOString(true)), 1000)
        return () => {
            clearInterval(_timerId)
        }
    }, [])

    useEffect(() => {
        if (tripPhotoId) {
            setFirstCarouselOpen(true)
        } else {

        }
        setShowEditCaptionModal(false)
        setShowUploadingPlayerInfo(false)
    }, [tripPhotoId])

    const routeToImage = _id => {
        navigate(`${basePath}${_id ? `/view/${_id}` : ''}`)
    }

    const onCarouselSelect = _selectedIndex => routeToImage(lightBoxImages[_selectedIndex]._id)

    const editPhotoCaption = _id => {
        const photoToEdit = lightBoxImages.find(i => i._id === _id)
        setShowEditCaptionModal(true)
        setEditCaptionPhoto(photoToEdit)
    }

    const closeEditPhotoCaption = e => setShowEditCaptionModal(false)

    const deletePhoto = _id => {
        navigate(basePath)
        dispatch(deleteTripPhoto(groupId, tripId, _id))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Delete Trip Photo', timeout: 3000 }))
            if (action.payload.messageType === 'success') {
                dispatch(getTripPhotos(groupId, tripId))
            }
        })
    }

    const handleLikeButtonPress = (tripPhotoId, likeType) => {
        dispatch(postTripPhotoLike(groupId, tripId, tripPhotoId, likeType))
    }

    const handlePostComment = () => {
        if (newComment && tripPhotoId) {
            dispatch(postTripPhotoComment(groupId, tripId, tripPhotoId, newComment))
        }
        setNewComment('')
    }

    const handleDeleteComment = tripPhotoId => {
        dispatch(deleteTripPhotoComment(groupId, tripId, tripPhotoId))
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h3 className={s.title}>Photo Gallery {arePhotosLoaded && trip.photos && trip.photos.length > 0 ? `(${trip.photos.length})` : null}</h3>
                <div className={s.controls}>
                {isAdmin || canPlayersUploadPhotos ?
                    <Button onClick={() => setShowUploadModal(true)} variant='primary' disabled={!arePhotosLoaded}>
                        <FontAwesomeIcon name='camera' /> add <span className='d-none d-sm-inline-block'>photo(s)</span>
                    </Button>
                : null}
                {isAdmin ? 
                    <Button variant='light' onClick={() => navigate(basePath.replace('/photos', '/admin/photos'))} style={{ marginLeft: '20px' }}>
                        <FontAwesomeIcon name='cog' /> <span className='d-none d-sm-inline-block'>settings</span>
                    </Button>
                : null}
                </div>
            </div>
            {!arePhotosLoaded ?
            <div className={s.spinnerContainer}><FontAwesomeIcon name='circle-notch' size='3x' /></div>
            : null}
            {lightBoxImages && lightBoxImages.length > 0 ? 
            <div>
                <div className={s.galleryContainer}>
                    <Gallery photos={lightBoxImages.map(p => pick(p, ['width','height','src','commentCount','totalLikes', 'likes','likeCounts']))}
                        renderImage={props => <CustomGalleryImage {...props} />}
                        onClick={(e, { photo, index }) => routeToImage(lightBoxImages[index]._id)} />
                </div>
                <div className={s.carouselContainer + ' ' + (isOpen ? `${s.showCarousel}` : firstCarouselOpen ? `${s.hideCarousel}` : `${s.initialHideCarousel}`)}>
                    <CustomGalleryHeader _id={tripPhotoId} 
                        groupName={groupName} 
                        tripName={tripName} 
                        locationName={locationName} 
                        src={_currentPhoto.src}
                        onClose={() => navigate(basePath)} 
                        showUploadingPlayerInfo={showUploadingPlayerInfo} 
                        player={_currentPhoto.player || {}}
                        onClickUploadingPlayer={bool => setShowUploadingPlayerInfo(bool)}
                        canEditCaption={canEditCaption} onEditCaption={editPhotoCaption}
                        canDeletePhoto={canDeletePhoto} onDeletePhoto={deletePhoto}
                        dateUploaded={_currentPhoto.dateUploaded} />

                    <div className={s.carouselInnerContainer}>
                        <div className={s.carouselContent}>
                            {isOpen ?
                            <div className={s.carouselBlock}>
                                <Carousel className={s.carousel} 
                                    activeIndex={_currentImageIndex}
                                    onSelect={onCarouselSelect} 
                                    interval={null}>
                                {lightBoxImages && lightBoxImages.map(p =>
                                <Carousel.Item key={`${p.key}`}
                                    className={s.slideItem}>
                                    <div className={s.imageContainer}>
                                        <img src={p.src} alt={p.caption || `Photo ${tripPhotoId}`}
                                            className={s.image} />
                                    </div>
                                </Carousel.Item>
                                )}
                                </Carousel>
                            </div>
                            : null}
                        </div>
                        <div className={s.likesAndCommentsContainer}>
                            <ImageLikeButtonBar playerId={playerId}
                                tripPhotoId={tripPhotoId}
                                likeCounts={_currentPhoto.likeCounts}
                                commentCount={_currentPhoto.commentCount}
                                onClick={(_tripPhotoId, _likeType) => handleLikeButtonPress(_tripPhotoId, _likeType)}
                                onShowCommenter={() => setShowCommenter(true)} />
                            <ImageCommenter show={showCommenter}
                                newComment={newComment} 
                                comments={_currentPhoto.comments} 
                                onChange={_text => setNewComment(_text)} 
                                onPost={handlePostComment}
                                onDelete={handleDeleteComment}
                                onClose={() => setShowCommenter(false)} />
                        </div>
                    </div>
                    <CustomGalleryFooter caption={_currentImageIndex > -1 && _currentImageIndex < lightBoxImages.length ? (_currentPhoto.caption || `Photo ${_currentImageIndex + 1}/${lightBoxImages.length}`) : null} />
                </div>
            </div>
            : 
            <p>No photos located for this trip.</p>}

            <TripPhotoUploader groupId={groupId} tripId={tripId} 
                show={showUploadModal} onClose={() => setShowUploadModal(false)} />

            <TripPhotoCaptionEditor groupId={groupId} tripId={tripId}
                photo={editCaptionPhoto} show={showEditCaptionModal}
                onClose={closeEditPhotoCaption} />
        </div>
    )
}

export default TripPhotoGallery