import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormGroup, FormLabel, FormControl, FormCheck } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import * as s from './TripBasicsSendDetails.scss'
import { getTripRounds, sendTripDetails } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'

const TripBasicsSendDetails = ({ group = {}, trip = {} }) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [sendLodging, setSendLodging] = useState(true)
    const [sendRoomAssignments, setSendRoomAssignments] = useState(true)
    const [sendRounds, setSendRounds] = useState([])
    const [sendPlayerRoster, setSendPlayerRoster] = useState(false)
    const [sendScorecards, setSendScorecards] = useState(false)
    const [sendProxyStakes, setSendProxyStakes] = useState(false)
    const [sendGolfCartSigns, setSendGolfCartSigns] = useState(false)

    useEffect(() => {
        dispatch(getTripRounds(group._id, trip._id))
        .then(action => {
            setSendRounds([...action.payload.rounds].filter(i => i.golfCourseID).map(i => ({ round: i.round, sendProxies: true, sendFoursomes: true, courseName: `${i.courseName} (${i.tees} tees)` })))
        })
    }, [])

    const doSend = () => {
        const postData = { groupId: group._id, tripId: trip._id, sendLodging, rounds: sendRounds, sendPlayerRoster, sendRoomAssignments, sendScorecards, sendGolfCartSigns, sendProxyStakes }
        dispatch(sendTripDetails(postData))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
            if (['success','info'].includes(action.payload.messageType)) {
                setShow(false)
            }
        }) 
    }

    const updateRound = (round, fieldName, value) => {
        var _sendRounds = [...sendRounds]
        var roundIndex = _sendRounds.findIndex(i => i.round === round)
        if (roundIndex > -1) {
            _sendRounds[roundIndex][fieldName] = value
        } 
        setSendRounds(_sendRounds)
    }

    const updateRoundSelectAll = round => {
        var _sendRounds = [...sendRounds]
        var roundIndex = _sendRounds.findIndex(i => i.round === round)
        if (roundIndex > -1) {
            const value = !_sendRounds[roundIndex].sendFoursomes
            _sendRounds[roundIndex] = { ..._sendRounds[roundIndex], sendFoursomes: value, sendProxies: value }
        } 
        setSendRounds(_sendRounds)
    }

    return (
        <div className={s.container}>
            <Button variant='secondary'
                onClick={() => setShow(true)}>
                <FontAwesomeIcon name='envelope' />
                {' '}
                <span className={'d-none d-sm-inline d-md-none d-lg-inline'}>send details</span>
            </Button>
            <Modal show={show} showFooter={true}
                heading={`Send details for trip`}
                actionButtonOnClick={() => doSend()}
                actionButtonDisabled={false}
                actionButtonIcon='envelope' actionButtonText='send details'
                onClose={() => setShow(false)}>
                <div>
                    <p>This will send the checked items to your email address.  You can use the received email to forward to your contacts at the golf course or resort (if any).</p>
                    {trip.settings.isLodgingDefined ? 
                        <FormGroup>
                            <FormLabel>Lodging</FormLabel>
                            <FormCheck type='checkbox' label='Send lodging details' 
                                checked={sendLodging}
                                onChange={e => setSendLodging(e.target.checked)} />
                            <FormCheck type='checkbox' label='Send room assignments' 
                                checked={sendRoomAssignments}
                                onChange={e => setSendRoomAssignments(e.target.checked)} />
                        </FormGroup>
                    : null}
                    {sendRounds && sendRounds.map(i => 
                        <FormGroup key={`send-round-${i.round}`}>
                            <FormLabel onClick={() => updateRoundSelectAll(i.round)}>Round {i.round}: {i.courseName}</FormLabel>
                            <FormCheck type='checkbox' label='Send foursomes' 
                                checked={i.sendFoursomes}
                                onChange={e => updateRound(i.round, 'sendFoursomes', e.target.checked)} />
                            <FormCheck type='checkbox' label='Send proxies' 
                                checked={i.sendProxies}
                                onChange={e => updateRound(i.round, 'sendProxies', e.target.checked)} />
                        </FormGroup>
                )}
                        <FormGroup>
                            <FormLabel>Players</FormLabel>
                            <FormCheck type='checkbox' label='Send player roster (email, phone)' 
                                checked={sendPlayerRoster}
                                onChange={e => setSendPlayerRoster(e.target.checked)} />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Attachments (PDF)</FormLabel>
                            <FormCheck type='checkbox' label='Send backup scorecards' 
                                checked={sendScorecards}
                                onChange={e => setSendScorecards(e.target.checked)} />
                            <FormCheck type='checkbox' label='Send golf cart signs' 
                                checked={sendGolfCartSigns}
                                onChange={e => setSendGolfCartSigns(e.target.checked)} />
                            <FormCheck type='checkbox' label='Send proxy stake signs' 
                                checked={sendProxyStakes}
                                onChange={e => setSendProxyStakes(e.target.checked)} />
                        </FormGroup>
                </div>
            </Modal>
        </div>
    )
}

export default TripBasicsSendDetails