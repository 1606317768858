import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminPayments.scss'
import GlobalAdminPaymentsNavBar from './GlobalAdminPaymentsNavBar'
import GlobalAdminPaymentsList from './GlobalAdminPaymentsList'

const GlobalAdminPayments = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)

    useEffect(() => {
    }, [])

    return (
        <div className={s.container}>
            <h3><FontAwesomeIcon name='credit-card' /> Payments</h3>
            <GlobalAdminPaymentsNavBar />
            <GlobalAdminPaymentsList />
        </div>
    )
}

export default GlobalAdminPayments
