import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext, useNavigate, useLocation, useParams } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventsAdmin.scss'
import FloatHeaderWithButtons from '../../../components/FloatHeaderWithButtons'
import { getEvents, recordEventResponseByAdmin } from '@severed-links/common.severedlinks-reducers/groups'
import TripEventListItem from '../TripEventListItem'
import TripEventEditor from './TripEventEditor'
import moment from 'moment-timezone'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { isEqual } from 'lodash'
import Modal from '@severed-links/common.modal'
import Button from '@severed-links/common.button'
import Alert from '@severed-links/common.alert'

const TripEventsAdmin = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const { group, groupId, trip, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const playerId = useSelector(state => state.account._id)
    const eventsAreLoaded = trip?.eventsAreLoaded || false
    const [showNewEventModal, setShowNewEventModal] = useState(false)
    const editEventId = params.editEventId || null
    const newEventType = params.newEventType || null
    const events = trip.events || []

    useEffect(() => {
        if (groupId && tripId) {
            dispatch(getEvents(groupId, tripId))
        }
    }, [groupId, tripId])
    
    const doEditEvent = _editEventId => {
        navigate(`${location.pathname}/edit/${_editEventId}`)
    }
  
    const doNewEvent = _newEventType => {
        setShowNewEventModal(false)
        navigate(`${location.pathname}/new/${_newEventType}`)
    }
  
    const doRecordEventResponseByAdmin = (eventId, playerId, eventResponse) => {
        dispatch(recordEventResponseByAdmin(groupId, tripId, playerId, eventId, eventResponse))
    }
    const _eventButtons = [
        { eventType: 'event', text: 'new event', icon: 'calendar', variant: 'primary', isTaskEvent: false, isPollEvent: false },
        { eventType: 'task', text: 'new task', icon: 'clipboard', variant: 'primary', isTaskEvent: true, isPollEvent: false },
        { eventType: 'poll', text: 'new poll', icon: 'vote-yea', variant: 'primary', isTaskEvent: false, isPollEvent: true },
    ].map(i => ({ ...i, onClick: () => doNewEvent(i.eventType) }))
    return (
        <div className={s.container}>
            <div className={s.headingContainer}>
                <h4 className={s.title}>Trip Events and Task Deadlines</h4>
                {eventsAreLoaded ?
                <div className={s.controls}>
                    <Button clear={!!events?.length} icon='plus' variant={events?.length ? 'secondary' : 'primary'}
                        onClick={() => setShowNewEventModal(true)} />
                </div>
                : null}
            </div>
            <ListGroup className={s.list}>
            {events && events.map(i =>
                <TripEventListItem event={i} key={`trip-event-${i._id}`} isAdmin
                    isTaskEvent={i.isTaskEvent} 
                    playerId={playerId} onTaskResponse={doRecordEventResponseByAdmin}
                    onClickEditButton={() => doEditEvent(i._id)}
                    group={group} trip={trip} />
            )}
            {!events || events.length === 0 ? <ListGroup.Item>No events for this trip located.</ListGroup.Item> : null}
            </ListGroup>
            {events && events.some(i => !i.isVisible) ? <Alert small variant='secondary' icon='info-circle' message='Grayed out events are not visible to your users until you edit them and turn the "show" property on.' /> : null}
            <p style={{ marginTop: '30px' }}><strong>Note</strong>: Use events to notify your players of deadlines for the trip.  For example,
                payment of a trip deposit, a date and time for a player draft, or even the time 
                and location of a team dinner on the trip!
            </p>
            <Modal heading='New Event' show={showNewEventModal}
                onClose={() => setShowNewEventModal(false)} showFooter={false}>
                <div className={s.newEventContainer}>
                {_eventButtons && _eventButtons.map(i =>
                <Button block className={s.newEventButton} icon={i.icon}
                    title={i.text} variant={i.variant}
                    onClick={i.onClick ? () => i.onClick() : null} 
                    key={`new-event-modal-button-${i.eventType}`} />
                )}
                </div>
            </Modal>
        </div>
    )
}

export default TripEventsAdmin