import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'

const TripInactiveAlert = ({ trip = {} }) => {

    if (!trip || !trip.settings) return null
    const isTripActive = (trip.settings || {}).isActive || false
    const isBeforeTrip = trip.startDate && moment().isBefore(moment(trip.startDate), 'day')

    return (
        !isTripActive ?
        <Alert variant='info' style={{ marginTop: '20px', marginBottom: '20px' }}>
            This trip is not {isBeforeTrip ? 'yet' : null} active.  {isBeforeTrip ? 'Stay tuned for details!' : null}
        </Alert>
        : null
    )
}

export default TripInactiveAlert