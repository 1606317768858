import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate, useParams, useMatch, useLocation } from 'react-router-dom'
import { Row, Col, Nav, Navbar, Dropdown } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import GroupRole from './GroupRole'
import Players from '../Player/Players'
import Trips from '../Trips/Trips'
import GroupAdmin from './Admin/GroupAdmin'
import Top10Lists from './Top10Lists'
import StewartAwardWinners from './StewartAwardWinners'
import GroupPlayerInviter from '../Player/GroupPlayerInviter'
import { getTrips, getDeletedTrips, getPlayers, getPendingInvites, 
    getTop10Lists, getGroupSettings, getLoginInfo, getFaq, getTripSettings,
    getStewartAwardWinners, setOnlineUsers, getReactivationRequests,
    getAddressBook } from '@severed-links/common.severedlinks-reducers/groups'
import { sendSocketCommand } from '@severed-links/common.severedlinks-reducers/socket'
import { getReminderIntervals } from '@severed-links/common.severedlinks-reducers/trips'
import LargeGroupHeader from './LargeGroupHeader'
import GroupMenuBar from './GroupMenuBar'
import Faq from './Faq'
import MiniGroupHeader from './MiniGroupHeader'
import OnlineUsers from './OnlineUsers'
import * as s from './Group.scss'
import { startsWith } from 'lodash'

const Group = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const groupId = params.groupId || null
    const match = useMatch('/group/:groupId/:activeTab/*')
    const globalAdminMatch = useMatch('/global-admin/*')
    const activeTab = match && match.params.activeTab
    const group = useSelector(state => state.groups.groups || []).find(g => g._id === groupId)
    const isGlobalAdmin = useSelector(state => state.account.isGlobalAdmin)
    const isGroupAdmin = ((group || {}).role || {}).isAdmin || isGlobalAdmin || false
    const showBigGroupHeader = ['players','trips','top-10-lists','mvp-award', 'faq', 'admin'].some(i => i === activeTab)
    const isGlobalAdminPath = !!globalAdminMatch
    
    useEffect(() => {
        if (!group) navigate(`/groups${(groupId ? `?rdg=${groupId}&rd=${encodeURIComponent(location.pathname)}` : ``)}`, { replace: true })
        else if (!group.role?.isActive && !isGlobalAdmin) navigate('/groups', { replace: true })
        else initialGroupLoad()
        return () => closeGroup()
    }, [])

    useEffect(() => {
        if (group && group._id && location && location.pathname === `/group/${group._id}`)
            navigate(`/group/${group._id}/${group.tripCount > 0 && group.playerCount > 0 ? 'trips' : 'players'}`, { replace: true })
    }, [location.pathname])

    const closeGroup = () => {
        if (groupId) {
            dispatch(sendSocketCommand('leave-group', { groupId }))
            dispatch(setOnlineUsers({ groupId, users: [] }))
        }
    }

    const initialGroupLoad = () => {
        if (group && group._id) {
            dispatch(getTrips(group._id))
            dispatch(getPlayers(group._id))
            dispatch(getGroupSettings(group._id))
            dispatch(getStewartAwardWinners(group._id))
            dispatch(getTop10Lists(group._id))
            dispatch(getFaq(group._id))
            if (isGroupAdmin) {
                dispatch(getDeletedTrips(group._id))
                dispatch(getLoginInfo(group._id))
                dispatch(getPendingInvites(group._id))
                dispatch(getReactivationRequests(group._id))
                dispatch(getReminderIntervals())
                dispatch(getAddressBook(group._id))
            }
            dispatch(sendSocketCommand('enter-group', { groupId: group._id }))
        }
    }

    if (!group) return null
    return (
        <div className={s.container}>
            <LargeGroupHeader groupName={group.name} show={showBigGroupHeader} onBack={() => navigate(`/groups`)} />
            <MiniGroupHeader group={group} show={!showBigGroupHeader && activeTab} />
            <div className={s.headerContainer}>
                <GroupMenuBar group={group} activeTab={activeTab} show={showBigGroupHeader} />
                <OnlineUsers group={group} />
            </div>
            <Outlet context={{ group, groupId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath }} />
        </div>
    )
}

export default Group