import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ListGroup, Image, Tooltip, OverlayTrigger, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventListItem.scss'
import moment from 'moment-timezone'
import { FRIENDLY_SHORT_DATE_FORMAT, FRIENDLY_SHORT_TIME_FORMAT, FRIENDLY_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import TripEventResponseCounts from './TripEventResponseCounts'
import DrivingDirectionsModal from './DrivingDirectionsModal'
import * as ddm from './DrivingDirectionsModal.scss'
import TripEventIcon from './TripEventIcon'
import TripEventCalendarDate from './TripEventCalendarDate'
import TripEventTitle from './TripEventTitle'
import TripEventLocation from './TripEventLocation'
import TripAdminReminderIndicator from './TripAdminReminderIndicator'
import TripEventAuthorIndicator from './TripEventAuthorIndicator'
import TripEventPlayerIconItem from './TripEventPlayerIconItem'
import TripEventPollOption from './TripEventPollOption'
import TripEventDetails from './TripEventDetails'
import TripEventTaskResponder from './TripEventTaskResponder'
import Confirm from '@severed-links/common.confirm'
import { sumBy, orderBy, maxBy } from 'lodash'
import pluralize from 'pluralize'
import Modal from '@severed-links/common.modal'
import Badge from '@severed-links/common.badge'

const TripEventListItem = ({ group = {}, trip = {}, event = { _id: null }, onClick, onClickEditButton, isAdmin = false, playerId = null, onTaskResponse = () => void(0) }) => {

    const [showDrivingDirections, setShowDrivingDirections] = useState(false)

    const { 
        eventId, isVisible, title, locationAddress, geocodedAddress, description, timeZoneId, showRsvpControls, 
        startTime, endTime, isAllDayEvent, pollVoteCount, sendReminders, reminderMinutes, 
        externalUrl, pollOptions, enteredBy, limitRsvpToTripPlayers, 
        pollLeaderVoteCount, pollVoteLeaderMessage, 
        isTaskEvent, isPollEvent, isPublishedPoll, taskResponses, rsvpCounts, 
        differentStartAndEnd, isToday, isTomorrow, isEventPassed, responses, eventIcon, 
    } = event

    const tripPlayers = [...(trip.usaPlayers || []), ...(trip.eurPlayers || []), ...(trip.unassignedPlayers || [])]
    const isTripPlayer = tripPlayers.some(x => x.playerID === playerId)
    const _rsvpCounts = rsvpCounts.map(i => ({ ...i, isPicked: (i.responses || []).some(x => x.playerId === playerId) }))

    const start = moment.tz(startTime, timeZoneId)
    const end = moment.tz(endTime, timeZoneId)

    const myResponse = (responses || []).find(r => r.playerId === playerId)
    const isOverdue = isTaskEvent && moment().isAfter(start, 'day') && (myResponse || {}).response !== 10
    const variant = !isAdmin && !isTaskEvent && (isToday || isTomorrow) ? 'info' :
        !isAdmin && isTaskEvent && (myResponse || {}).response !== 10 && isTomorrow ? 'warning' :
        (!isAdmin && isTaskEvent && (myResponse || {}).response !== 10 && isToday) || isOverdue ? 'danger' : null
    const taskResponse = isTaskEvent ? ((myResponse || {}).response || null) : null
    const taskResponseCode = isTaskEvent ? ((myResponse || {}).responseCode || null) : null
    const _showTaskResponse = !isAdmin && isTaskEvent && myResponse && myResponse.response
    const hasVotedInPoll = isPollEvent && responses.some(i => i.playerId === playerId)
    const showPollResults = isPollEvent && (isAdmin || (isPublishedPoll && hasVotedInPoll))
    const myVote = responses.find(i => i.playerId == playerId)

    const onCloseDrivingDirections = () => setShowDrivingDirections(false)

    return (
        <ListGroup.Item className={s.container + (!isVisible ? ` ${s.notVisible}` : ``)} 
            variant={variant}
            action={isAdmin || typeof onClick === 'function'} 
            onClick={onClickEditButton ? () => onClickEditButton() : () => typeof onClick === 'function' ? onClick(event, false, isTaskEvent, isPollEvent) : null}>
            <div className={s.item}>
                <TripEventIcon icon={eventIcon} isTaskEvent={isTaskEvent} isPollEvent={isPollEvent} />
                <TripEventCalendarDate start={start} end={end} timeZoneId={timeZoneId} isAdmin={isAdmin} 
                    tripName={trip.tripName} eventTitle={title} eventDescription={description}
                    locationAddress={locationAddress} />
                <div className={s.content}>
                    <div className={s.details}>
                        <TripEventTitle start={start} end={end} 
                            {...event}
                            isOverdue={isOverdue}
                            showTaskResponse={_showTaskResponse} 
                            taskResponse={taskResponse}
                            taskResponseCode={taskResponseCode}
                            isAdmin={isAdmin} />
                        <TripEventLocation {...event} onShowDrivingDirections={() => setShowDrivingDirections(true)} />
                        <div className={s.detailContainer}>
                            <div className={s.mainDetail}>
                                <TripEventDetails {...event} isAdmin={isAdmin} />
                                {!isAdmin && !isTaskEvent && showRsvpControls ? 
                                <div className={s.goingContainer} onClick={e => e.stopPropagation()}>
                                {responses.filter(i => i.response === 1).map(i => 
                                    <TripEventPlayerIconItem {...event}
                                        response={false} playerId={i.playerId} playerName={i.playerName} 
                                        imageUrl={i.imageUrl}
                                        key={`event-${eventId}-response-0-${i.playerId}`}
                                        onTaskResponse={false} />
                                )}
                                </div>
                                : null}

                                {!isAdmin && _showTaskResponse ?
                                <TripEventTaskResponder eventId={eventId}
                                    taskResponses={taskResponses}
                                    myResponse={myResponse}
                                    onTaskResponse={onTaskResponse} />
                                : null}

                                {!isAdmin && isPollEvent ? 
                                <div className={s.pollContainer}>
                                {pollOptions && pollOptions.map(i =>
                                    <TripEventPollOption key={`event-${eventId}-poll-option-${i._id}`} 
                                        option={i} eventId={eventId} isMyVote={(i.responses || []).some(x => x.playerId === playerId)}
                                        show={showPollResults} onVote={!isAdmin ? onTaskResponse : false} />
                                )}
                                <div className={s.voteCount}>{pluralize('vote', pollVoteCount, true)}</div>
                                </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    {!isAdmin ?
                    <div className={s.bottomRow}>
                        <div className={s.item}>
                            <TripEventAuthorIndicator {...event} />
                        </div>
                        <div className={s.item}>
                        </div>
                        {!isTaskEvent && showRsvpControls ? 
                        <div className={s.item}>
                            <div className={s.responseCounts}>
                                <TripEventResponseCounts responses={responses} rsvpCounts={_rsvpCounts}
                                    group={group} trip={trip} event={event} allowPress={!limitRsvpToTripPlayers || isTripPlayer} />
                            </div>
                        </div>
                        : null}
                    </div>
                    : isAdmin && isPollEvent ? 
                    <div className={s.pollResultsSummary}>
                        <FontAwesomeIcon name='info-circle' className={s.icon} />
                        <div className={s.content}>{pollLeaderVoteCount ? <><b>Leader</b>:{' '}</> : null}{pollVoteLeaderMessage}</div>
                    </div>
                    :
                    null}
                </div>
                {isAdmin && onClickEditButton ?
                <div className={s.editClickIcon}>
                    <FontAwesomeIcon name='chevron-right' size='2x' />
                </div>
                : null}
            </div>
            {geocodedAddress ?
            <DrivingDirectionsModal tripId={trip._id} 
                show={showDrivingDirections} isAddress
                onClose={onCloseDrivingDirections} 
                destination={geocodedAddress} />
            : null}
        </ListGroup.Item>
    )
}

export default TripEventListItem
