import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventEditorMiniBadge.scss'
import Badge from '@severed-links/common.badge'

const TripEventEditorMiniBadge = ({ variant = 'info', icon = null, count = 0, suffix = null }) => {

    const dispatch = useDispatch()
    const userId = useSelector(state => state.account._id)

    useEffect(() => {
    }, [])

    return (
        <Badge className={s.container} variant={variant}>
            {icon ? <FontAwesomeIcon className={s.icon} name={icon} /> : null}
            <div className={s.count}>{count}</div>
            {suffix ? <div className={s.suffix}>{suffix}</div> : null}
        </Badge>
    )
}

export default TripEventEditorMiniBadge
