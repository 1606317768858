import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventEditorTaskResponses.scss'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from '@severed-links/common.button'
import Modal from '@severed-links/common.modal'
import Badge from '@severed-links/common.badge'
import Confirm from '@severed-links/common.confirm'
import PlayerListItem from '../../Player/PlayerListItem'
import { omit } from 'lodash'
import { recordEventResponseByAdmin, sendEventReminders, sendEventRemindersToNotComplete, deleteEventResponse } from '@severed-links/common.severedlinks-reducers/groups'

const TripEventEditorTaskResponses = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const reminderIntervals = useSelector(state => state.trips.reminderIntervals)
    const [activeTab, setActiveTab] = useState(null)
    const [taskPlayerDetail, setTaskPlayerDetail] = useState(null)
    const [showReminderManager, setShowReminderManager] = useState(false)
    const showPlayerDetail = !!taskPlayerDetail
    const { groupId, tripId, eventId, onChange, trip, eventType, isNew, reminderMinutes, taskCompletionStatus, taskVariant, reminderVariant, reminderIcon, reminderMessage, taskIcon, eventTypeCapital, isTaskEvent, taskResponses, eventsLastUpdated, hasIncompleteTasks } = useOutletContext()

    useEffect(() => {
    }, [])

    const doSendEventReminders = () => dispatch(sendEventReminders(groupId, tripId, eventId))

    const doSendEventRemindersToNotComplete = () => dispatch(sendEventRemindersToNotComplete(groupId, tripId, eventId))

    const selectReminderInterval = e => {
        const reminderInterval = reminderIntervalLookup(e)
        onChange({ reminderMinutes: reminderInterval.reminderMinutes, sendReminders: reminderInterval.sendReminders })
    }

    const reminderIntervalLookup = (_reminderMinutes = 0) => reminderIntervals.find(i => i.reminderMinutes === parseInt(_reminderMinutes)) || {}

    const handleTaskResponse = _code => {
        setTaskPlayerDetail(null)
        dispatch(recordEventResponseByAdmin(groupId, tripId, taskPlayerDetail.playerId, eventId, _code))
    }

    const doDeleteTaskResponse = () => {
        setTaskPlayerDetail(null)
        dispatch(deleteEventResponse(groupId, tripId, taskPlayerDetail._id ))
    }

    return (
        <div className={s.container}>

            {isTaskEvent && !isNew ?
            <div className={s.manageReminderContainer}><Button className={s.button} variant='light' title='Manage reminders' icon='clock' onClick={() => setShowReminderManager(true)} /></div>
            : null}

            <div className={s.responseContainer}>
            {taskResponses && taskResponses.map(i =>
            <div className={s.taskResponseContainer} key={`task-response-container-${eventId}-${i.title}`}>
                <div className={s.header + ' ' + s[i.variant]}>
                    <div className={s.icon}><FontAwesomeIcon name={i.icon} size='2x' /></div>
                    <h4 className={s.title}>{i.title}</h4>
                    <h4 className={s.count}>{i.count}</h4>
                </div>
                <ListGroup className={s.list}>
                {i.responses && i.responses.map(p =>
                <PlayerListItem player={p} showRole={false} showHcp={false} 
                    onClick={() => setTaskPlayerDetail(p)}
                    key={`player-task-response-${p.playerId}`} />
                )}
                {!i.responses?.length ?
                <ListGroup.Item>No players located.</ListGroup.Item>
                : null}
                </ListGroup>
            </div>
            )}
            </div>
            <Modal show={showPlayerDetail} heading={'Set Player Task Status'}
                right={<Confirm title='Confirm Player Task Status Delete' 
                    confirmText='delete task' 
                    body='Are you sure you want to delete this task for this user?' 
                    buttonClassName={s.deleteTaskResponseButton} 
                    variant='danger' 
                    buttonIcon='trash' 
                    onConfirm={doDeleteTaskResponse} />}
                showFooter={false} onClose={() => setTaskPlayerDetail(null)}>
                <div className={s.taskStatusModalContainer}>
                    {taskPlayerDetail ?
                    <>
                    <PlayerListItem player={taskPlayerDetail} showRole={false} showHcp={false} clickDisabled />
                    <div className={s.buttonsContainer}>
                    {taskResponses && taskResponses.map(i =>
                    <Button block className={s.button} {...i} 
                        onClick={() => handleTaskResponse(i.code)}
                        key={`task-response-button-${taskPlayerDetail.playerId}-${i.title}`} />
                    )}
                    </div>
                    </>
                    : null}
                </div>
            </Modal>
            <Modal heading={'Task Reminder Manager'} showFooter={false}
                show={showReminderManager}
                onClose={() => setShowReminderManager(false)}>
                <div className={s.reminderManagerContainer}>

                    <div className={s.controls + (taskVariant ? ` ${s[taskVariant]}` : '')}>
                        {taskIcon ? <div className={s.icon}><FontAwesomeIcon name={taskIcon} /></div> : null}
                        <div className={s.title}>Task responses: {taskCompletionStatus}</div>
                    </div>

                    {hasIncompleteTasks ?
                    <Confirm title='Send Task Reminders' 
                        confirmText='send reminders' 
                        body='Are you sure you want to send reminders now?' 
                        buttonClassName={s.button} 
                        variant={taskVariant} 
                        buttonIcon='sms' 
                        buttonText='remind not completed' 
                        buttonClass={s.button}
                        onConfirm={doSendEventRemindersToNotComplete} />
                    : null}

                    <div>
                        <Confirm title={`Confirm Send Reminders`} 
                            onConfirm={() => doSendEventReminders()} 
                            enforceFocus={false} 
                            confirmText='send reminders' 
                            body={<div><p>Are you sure you want to send reminders now?</p></div>} 
                            variant={'info'} 
                            buttonIcon='sms' 
                            buttonText='send now' 
                            buttonClassName={s.button} />
                    </div>

                    <p><b>Set Reminder Time</b></p>
                    <DropdownButton className={s.button}
                        variant='info' onSelect={e => selectReminderInterval(e)} 
                        title={reminderIntervalLookup(reminderMinutes).title || ''}>
                        {reminderIntervals && reminderIntervals.map(i => 
                        <Dropdown.Item key={`reminder-interval-${i.reminderMinutes}`} eventKey={i.reminderMinutes}>{i.title}</Dropdown.Item>
                        )}
                    </DropdownButton>
                </div>
            </Modal>
        </div>
    )
}

export default TripEventEditorTaskResponses
