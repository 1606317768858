import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation, Outlet, useOutletContext, Link } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Button from '@severed-links/common.button'
import TripNotePrivateListItem from './TripNotePrivateListItem'
import * as s from './TripNotesAdminPrivate.scss'
import { endsWith } from 'lodash'
import { arrayMoveImmutable } from 'array-move'
import { setTripValue, saveSortOrdersForTripAdminNotes } from '@severed-links/common.severedlinks-reducers/groups'

const TripNotesAdminPrivate = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const _tripContext = useOutletContext()
    const { group, groupId, trip, tripId, isGlobalAdmin, isGlobalAdminPath } = _tripContext
    const playerId = useSelector(state => state.account._id)
    const adminNotes = trip?.adminNotes || []
    const _isRoot = endsWith(location.pathname.toLowerCase(), '/admin/notes/private')

    useEffect(() => {
    }, [])

    const handleAddNewButtonClick = () => {
        navigate(`${location.pathname}${!endsWith(location.pathname, '/') ? '/' : ''}add-new`)
    }

    const handleItemClick = _note => {
        navigate(`${location.pathname.toLowerCase()}/edit/${_note._id}`)
    }

    const handleSortItem = (dragIndex, hoverIndex) => {
        var _adminNotes = arrayMoveImmutable(adminNotes, dragIndex, hoverIndex)
        dispatch(setTripValue(groupId, tripId, 'adminNotes', _adminNotes))
        const _newSortOrders = _adminNotes.map((i, index) => ({ _id: i._id, sortOrder: index + 1 }))
        dispatch(saveSortOrdersForTripAdminNotes(groupId, tripId, _newSortOrders))
    }

    return (
        <div className={s.container}>

            {_isRoot ?
            <>
            <div className={s.heading}>
                <p className={s.instructions}>Put notes about the trip here and set reminders on notes if needed.</p>
                <div className={s.controls}>
                    <Button className={s.addNewButton} variant='primary' icon='plus' title='add new note' onClick={handleAddNewButtonClick} />
                </div>
            </div>

            <ListGroup className={s.list}>
            {adminNotes && adminNotes.map((i, index) =>
            <TripNotePrivateListItem {...i} 
                index={index}
                canSort={adminNotes?.length > 0}
                onMove={handleSortItem}
                onClick={() => handleItemClick(i)}
                key={`trip-${tripId}-note-private-${i._id}`} />
            )}
            {!adminNotes.length ? <ListGroup.Item>No notes located.</ListGroup.Item> : null}
            </ListGroup>
            </>
            : null}

            <Outlet context={_tripContext} />
        </div>
    )
}

export default TripNotesAdminPrivate
