import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useMatch, useLocation, useOutletContext, Outlet } from 'react-router-dom'
import { Table, Image, Button, Alert, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripRoundScorecardAdminTable.scss'
import { getAdminScorecardForRound, doAdminScorecardUpdate, 
    updateAdminScorecardSkinsProxies, setAdminScorecardState } from '@severed-links/common.severedlinks-reducers/scorecard'
import { sumBy, reject } from 'lodash'
import { playerAvatarUrl, commonImageUrl } from '@severed-links/common.severedlinks-constants'
import TripRoundScorecardAdminHoleScore from './TripRoundScorecardAdminHoleScore'
import moment from 'moment-timezone'

const TripRoundScorecardAdminTable = () => {

    const dispatch = useDispatch()
    const match = useMatch(`/group/:groupId/trip/:tripId/admin/rounds/edit/round/:round/scorecard/show`)
    const groupId = match && match.params.groupId
    const tripId = match && match.params.tripId
    const round = parseInt(match && match.params.round || '0')
    const scorecard = useSelector(state => (state.scorecard.admin.scorecards || []).find(i => i.groupId === groupId && i.tripId === tripId && i.round === round) || {})
    const changesToSave = useSelector(state => state.scorecard.admin.changesToSave || [])
    const changesSaving = useSelector(state => state.scorecard.admin.changesSaving || [])
    const lastAdminScorecardUpdate = useSelector(state => state.scorecard.admin.lastAdminScorecardUpdate)
    const SAVE_INTERVAL = 5 * 1000
    const { 
        playerData, courseName, proxies, front9, hasFront9, back9, hasBack9, 
        teeBoxes, selectedTees, players, isPlayingOwnBall, teamSize, 
        isLoaded,  
    } = scorecard || {}

    const _isTeamFormat = !isPlayingOwnBall
    const _colSpan = _isTeamFormat ? 5 : 4

    useEffect(() => {
        let timerId = setInterval(() => dispatch(setAdminScorecardState({ lastAdminScorecardUpdate: moment().toISOString() })), SAVE_INTERVAL)
        return () => clearInterval(timerId)
    }, [])

    useEffect(() => {
        if (groupId && tripId && round) {
            dispatch(getAdminScorecardForRound(groupId, tripId, round))
            .then(action => {
                dispatch(setAdminScorecardState({ changesToSave: [], changesSaving: [], lastAdminScorecardUpdate: moment().toISOString() }))
            })
        }
    }, [groupId, tripId, round])

    useEffect(() => doSave(), [lastAdminScorecardUpdate])

    const doSave = () => {
        var _changesSaving = [...changesSaving]
        if (changesToSave && changesToSave.length > 0) {
            const postData = [...changesToSave].map(i => ({
                    activeTrip: i.tripId,
                    activeRound: i.round,
                    playerID: i.playerId,
                    tripMatchID: i.tripMatchId,
                    tripMatchHoleScoreID: i.tripMatchHoleScoreId,
                    hole: i.hole,
                    grossScore: i.grossScore || 0,
            }))
            _changesSaving = [...changesSaving, ...changesToSave]
            dispatch(setAdminScorecardState({ changesSaving: _changesSaving, changesToSave: [] }))
            dispatch(doAdminScorecardUpdate(groupId, postData))
            .then(action => {
                if (action.payload.confirmSaveData && action.payload.scores && action.payload.scores.length) {
                    dispatch(setAdminScorecardState({ changesSaving: reject(changesSaving, c => action.payload.scores.map(i => i.tripMatchHoleScoreID).indexOf(c.tripMatchHoleScoreId) > -1) }))
                    dispatch(updateAdminScorecardSkinsProxies(groupId, tripId, round))
                }
            })               
        }
    }

    const changeHoleScore = hole => {
        var _changesToSave = reject(changesToSave, { tripMatchHoleScoreId: hole.tripMatchHoleScoreId })
        _changesToSave.push(hole)
        if (_isTeamFormat) {
            const _playerDataItem = playerData.find(i => i._id === hole.tripMatchHoleScoreId)
            const _otherPlayerItems = playerData.filter(i => i.round === _playerDataItem.round && i.match === _playerDataItem.match && i.hole === _playerDataItem.hole && i.team === _playerDataItem.team && i._id !== _playerDataItem._id)
                .map(i => ({ 
                    ...hole,
                    playerId: i.playerID,
                    tripMatchHoleScoreId: i.tripMatchHoleScoreID,
                    tripMatchId: i.tripMatchID,
                    tripPlayerId: i.tripPlayerID,
                }))
            if (_otherPlayerItems && _otherPlayerItems.length) {
                _otherPlayerItems.forEach(_item => {
                    _changesToSave = reject(_changesToSave, { tripMatchHoleScoreId: _item.tripMatchHoleScoreId })
                    _changesToSave.push(_item)
                })
            }
        }
        dispatch(setAdminScorecardState({ changesToSave: _changesToSave }))
    }

    return (
        !isLoaded ? 
        <div className={s.spinnerContainer}><FontAwesomeIcon className={s.spinner} name='circle-notch' spin /></div>
        :
        <Table responsive striped className={s.scorecard}>
            <thead>
                <tr>
                <th className={s.left} colSpan={_colSpan}>{courseName}</th>
                {front9 && front9.map(h => <th key={`hole-${h}`}>{h}</th>)}
                {hasFront9 ? <th>Out</th> : null}
                {back9 && back9.map(h => <th key={`hole-${h}`}>{h}</th>)}
                {hasBack9 ? <th>In</th> : null}
                {hasFront9 || hasBack9 ? <th>Total</th> : null}
                </tr>
                {teeBoxes && teeBoxes.map(teeBox => 
                <tr key={`tee-box-${teeBox.teeBox}`}>
                    <th className={s.left} style={{ color: teeBox.fontColor, backgroundColor: teeBox.backgroundColor }} colSpan={_colSpan}>{teeBox.teeBox} Tees {teeBox.isSelectedTees ? <FontAwesomeIcon name='check' className={s.selectedTeeIcon} /> : null}</th>
                    {hasFront9 && teeBox.holes && teeBox.holes.filter(h => front9.indexOf(h.hole) > -1).map(h => 
                    <th style={{ color: teeBox.fontColor, backgroundColor: teeBox.backgroundColor }} key={`tee-box-${teeBox.teeBox}-yards-${h.hole}`}>{h.yards}</th>
                    )}
                    {hasFront9 ? <th style={{ color: teeBox.fontColor, backgroundColor: teeBox.backgroundColor }} key={`tee-box-${teeBox.teeBox}-yards-out`}>{sumBy(teeBox.holes.filter(h => front9.indexOf(h.hole) > -1), 'yards')}</th> : null}
                    {hasBack9 && teeBox.holes && teeBox.holes.filter(h => back9.indexOf(h.hole) > -1).map(h => 
                    <th style={{ color: teeBox.fontColor, backgroundColor: teeBox.backgroundColor }} key={`tee-box-${teeBox.teeBox}-yards-${h.hole}`}>{h.yards}</th>
                    )}
                    {hasBack9 ? <th style={{ color: teeBox.fontColor, backgroundColor: teeBox.backgroundColor }} key={`tee-box-${teeBox.teeBox}-yards-in`}>{sumBy(teeBox.holes.filter(h => back9.indexOf(h.hole) > -1), 'yards')}</th> : null}
                    {hasFront9 || hasBack9 ? <th style={{ color: teeBox.fontColor, backgroundColor: teeBox.backgroundColor }} key={`tee-box-${teeBox.teeBox}-yards-total`}>{sumBy(teeBox.holes, 'yards')}</th> : null}
                </tr>
                )}
                <tr key={`tee-box-par-row`} className={s.par}>
                    <th className={s.left} colSpan={_colSpan}>Par</th>
                    {hasFront9 && selectedTees.holes && selectedTees.holes.filter(h => front9.indexOf(h.hole) > -1).map(h => 
                    <th key={`tee-box-par-${h.hole}`}>{h.par}</th>
                    )}
                    {hasFront9 ? <th>{sumBy(selectedTees.holes.filter(h => front9.indexOf(h.hole) > -1), 'par')}</th> : null}
                    {hasBack9 && selectedTees.holes && selectedTees.holes.filter(h => back9.indexOf(h.hole) > -1).map(h => 
                    <th key={`tee-box-par-${h.hole}`}>{h.par}</th>
                    )}
                    {hasBack9 ? <th>{sumBy(selectedTees.holes.filter(h => back9.indexOf(h.hole) > -1), 'par')}</th> : null}
                    {hasFront9 || hasBack9 ? <th>{sumBy(selectedTees.holes, 'par')}</th> : null}
                </tr>
                <tr key={`tee-box-hcp-row`} className={s.hcp}>
                    <th className={s.left} colSpan={_colSpan}>Hcp</th>
                    {hasFront9 && selectedTees.holes && selectedTees.holes.filter(h => front9.indexOf(h.hole) > -1).map(h => 
                    <th key={`tee-box-hcp-${h.hole}`}>{h.hcp}</th>
                    )}
                    {hasFront9 ? <th>&nbsp;</th> : null}
                    {hasBack9 && selectedTees.holes && selectedTees.holes.filter(h => back9.indexOf(h.hole) > -1).map(h => 
                    <th key={`tee-box-hcp-${h.hole}`}>{h.hcp}</th>
                    )}
                    {hasBack9 ? <th>&nbsp;</th> : null}
                    {hasFront9 || hasBack9 ? <th>&nbsp;</th> : null}
                </tr>
                <tr key={`tee-box-proxy-row`} className={s.proxy}>
                    <th className={s.left} colSpan={_colSpan}>Proxies</th>
                    {hasFront9 && selectedTees.holes && selectedTees.holes.filter(h => front9.indexOf(h.hole) > -1).map(h => 
                    <th key={`tee-box-proxy-${h.hole}`} title={(proxies.find(p => p.hole === h.hole) || {}).playerName}>{proxies.some(p => p.hole === h.hole) ? <FontAwesomeIcon name='star' /> : null}</th>
                    )}
                    {hasFront9 ? <th>&nbsp;</th> : null}
                    {hasBack9 && selectedTees.holes && selectedTees.holes.filter(h => back9.indexOf(h.hole) > -1).map(h => 
                    <th key={`tee-box-proxy-${h.hole}`} title={(proxies.find(p => p.hole === h.hole) || {}).playerName}>{proxies.some(p => p.hole === h.hole) ? <FontAwesomeIcon name='star' /> : null}</th>
                    )}
                    {hasBack9 ? <th>&nbsp;</th> : null}
                    {hasFront9 || hasBack9 ? <th>&nbsp;</th> : null}
                </tr>
            </thead>
            <tbody>
            {players && players.map((p, index) =>
                <tr key={`scorecard-player-${p.playerID}`} className={s[(p.playerTeam || '').toLowerCase()] + (index === 0 || p.match !== players[index - 1].match ? ' ' + s.beginMatch : '') + (index === players.length - 1 || p.match !== players[index + 1].match ? ' ' + s.endMatch : '')}>
                    <td className={s.avatar}><Image src={playerAvatarUrl(p.imageUrl, true)} /></td>
                    <td>
                        <div className={s.playerNameContainer}>
                            <div className={s.playerName}>
                                <div className={s.firstName}>{p.firstName}</div>
                                <div className={s.lastName}>{p.lastName}</div>                                
                            </div>
                            {p.teeBoxOverride && (round || {}).round ? <Badge bg={null} text={null} style={{ margin: '0px 6px', backgroundColor: p.teeBoxOverride.backgroundColor, color: p.teeBoxOverride.fontColor }}>{(round || {}).round || 0}</Badge> : null}
                            {!p.isAssignedToMatch ? <Badge bg={'danger'} text={'light'} style={{ margin: '0px 6px' }}>Unassigned!</Badge> : null}
                        </div>
                    </td>
                    <td className={s.hcpContainer}>
                        <div className={s.hcp + ' ' + s[(p.playerTeam || '').toLowerCase()]}>{p.hcp}</div>
                    </td>
                    <td className={s.flag}>{(p.playerTeam || '').toLowerCase() && (p.playerTeam || '').toLowerCase() !== 'none' ? <Image src={commonImageUrl((p.playerTeam || '').toLowerCase() + 'Flag')} /> : null}</td>
                    {_isTeamFormat && p.isFirst ?
                    <td className={s.teamHcpContainer + ' ' + s[(p.playerTeam || '').toLowerCase()]} rowSpan={teamSize}>
                        <div className={s.teamHcpHeader}>Team Hcp</div>
                        <div className={s.teamHcp}>{p.teamHandicap}</div>
                    </td>
                    : null}
                    {hasFront9 && p.holes && p.holes.filter(h => front9.indexOf(h.hole) > -1).map(h => 
                    !_isTeamFormat || p.isFirst ?
                    <td className={s.score} 
                        rowSpan={_isTeamFormat ? teamSize : 1}
                        key={`player-score-${p.playerID}-gross-score-${h.hole}`}>
                        <TripRoundScorecardAdminHoleScore groupId={groupId} tripId={tripId} round={round} 
                            playerId={p.playerID} hole={h} playerTeam={p.playerTeam} 
                            isSaving={changesSaving.some(cs => cs.tripMatchHoleScoreId === h.tripMatchHoleScoreID)}
                            onChange={changeHoleScore} isTeam={_isTeamFormat} />
                    </td>
                    : null
                    )}
                    {hasFront9 && (!_isTeamFormat || p.isFirst) ? <td className={s.bold} rowSpan={_isTeamFormat ? teamSize : 1}>{sumBy(p.holes.filter(h => front9.indexOf(h.hole) > -1), 'grossScore')}</td> : null}
                    {hasBack9 && p.holes && p.holes.filter(h => back9.indexOf(h.hole) > -1).map(h => 
                    !_isTeamFormat || p.isFirst ?
                    <td className={s.score} 
                        rowSpan={_isTeamFormat ? teamSize : 1}
                        key={`player-score-${p.playerID}-gross-score-${h.hole}`}>
                        <TripRoundScorecardAdminHoleScore groupId={groupId} tripId={tripId} round={round} 
                            playerId={p.playerID} hole={h} playerTeam={p.playerTeam} 
                            isSaving={changesSaving.some(cs => cs.tripMatchHoleScoreId === h.tripMatchHoleScoreID)}
                            onChange={changeHoleScore} isTeam={_isTeamFormat} />
                    </td>
                    : null
                    )}
                    {hasBack9 && (!_isTeamFormat || p.isFirst) ? <td className={s.bold} rowSpan={_isTeamFormat ? teamSize : 1}>{sumBy(p.holes.filter(h => back9.indexOf(h.hole) > -1), 'grossScore')}</td> : null}
                    {(hasFront9 || hasBack9) && (!_isTeamFormat || p.isFirst) ? <td className={s.bold} rowSpan={_isTeamFormat ? teamSize : 1}>{sumBy(p.holes, 'grossScore')}</td> : null}
                </tr>
            )}
            </tbody>
        </Table>
    )
}

export default TripRoundScorecardAdminTable