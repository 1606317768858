import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { } from 'react-router'
import { Row, Col, Card, ListGroup, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripMatch.scss'
import PlayerListItem from '../Player/PlayerListItem'

const TripMatchPanel = ({ trip = {}, matchItem = {}, inverse = false, showHcp = true, isShotgun = false, isMini = false, showNotStartedHeader = true }) => {

    if (!matchItem) return null

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const isMyMatch = matchItem.usaPlayerList.some(p => p._id === playerId) || matchItem.eurPlayerList.some(p => p._id === playerId)
    const _headerStyle = matchItem.winningTeam === 'USA' ? {
        color: trip.usaTextColor, backgroundColor: trip.usaBackgroundColor,
        backgroundImage: `url(${trip.usaFullTeamImageUrl})`,
    } : matchItem.winningTeam === 'EUR' ? {
        color: trip.eurTextColor, backgroundColor: trip.eurBackgroundColor,
        backgroundImage: `url(${trip.eurFullTeamImageUrl})`,
    } : null
    return (
        <Card className={s.tripMatchPanel + (inverse ? ` ${s.inverse}` : '') + (isMini ? ` ${s.isMini}` : '')} 
            variant={matchItem.isNotStarted && isMyMatch ? 'warning' : matchItem.winningTeam === 'USA' ? 'danger' : matchItem.winningTeam === 'EUR' ? 'info' : 'light'}>
            {matchItem.isInProgress || matchItem.isFinal ? 
            <Card.Header className={s.header + (matchItem.winningTeam === 'USA' || matchItem.winningTeam === 'EUR' || (matchItem.winningTeam === 'None' && matchItem.status === 'Tie') ? ' ' + s[`${matchItem.winningTeam.toLowerCase()}Win`] : '')}
                style={_headerStyle}>
                <div className={s.result + (matchItem.winningTeam === 'USA' ? ' ' + s.usa : '') + (matchItem.winningTeam === 'EUR' ? ' ' + s.eur : '')}>
                    {matchItem.status}
                </div> 
                {matchItem.isInProgress ? 
                <div className={s.lastHole}>
                    <Badge bg='secondary'>{matchItem.lastHole}</Badge>
                </div> 
                : null}
            </Card.Header>
            : showNotStartedHeader && matchItem.isNotStarted ? 
            <Card.Header className={s.header}>
                <div className={s.teeTimeIcon}><FontAwesomeIcon name='clock' /></div>
                <div className={s.teeTime}>{matchItem.teeTimeDisplay}</div>
                {isShotgun ? <div className={s.startingHole}>Starting on #{matchItem.startingHole}</div> : null}
            </Card.Header>
            : null}
            <ListGroup variant='flush'>
                <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                    <Col md={6} sm={12} xs={12} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        {matchItem && matchItem.usaPlayerList && matchItem.usaPlayerList.map(i =>
                            <PlayerListItem showFlag flagUrl={trip.usaFullTeamImageUrl} showHcp={showHcp} round={matchItem.round}
                                variant={matchItem.isNotStarted && isMyMatch ? 'warning' : undefined} 
                                style={matchItem.winningTeam === 'USA' ? { color: trip.usaTextColor, backgroundColor: trip.usaBackgroundColor } : null}
                                player={i} showRole={false} key={`usa-match-avatar-${i.match}-${i.playerID}`} inverse={inverse} isMini={isMini} />
                        )}
                    </Col>
                    <Col md={6} sm={12} xs={12} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        {matchItem && matchItem.eurPlayerList && matchItem.eurPlayerList.map(i =>
                            <PlayerListItem showFlag flagUrl={trip.eurFullTeamImageUrl} showHcp={showHcp} round={matchItem.round}
                                variant={matchItem.isNotStarted && isMyMatch ? 'warning' : undefined} 
                                style={matchItem.winningTeam === 'EUR' ? { color: trip.eurTextColor, backgroundColor: trip.eurBackgroundColor } : null}
                                player={i} showRole={false} key={`eur-match-avatar-${i.match}-${i.playerID}`} inverse={inverse} isMini={isMini} />
                        )}
                    </Col>
                </Row>
            </ListGroup>
        </Card>
    )
}

export default TripMatchPanel