import React, { useRef } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { useDrag } from 'react-dnd'
import { ITEM_TYPES } from '../DragAndDropItemTypes'
import * as s from '../TripLodgingAdmin.scss'
import PlayerListItem from '../../../Player/PlayerListItem'

const PlayerSlot = ({ player = {} }) => {

    const [{ isDragging }, _dragRef] = useDrag({
        type: ITEM_TYPES.ROOM,
        item: () => ({ player }),
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })


    return (
        <div ref={_dragRef}>
            <PlayerListItem player={player} noWrap clickDisabled 
                showRole={false} className={s.draggablePlayer} />
        </div>
    )
}

export default PlayerSlot
