import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PlayerHandicap.scss'

const PlayerHandicap = ({ hcp = 0, variant = 'secondary', size = 24 }) => {

    return (
        <div className={s.container + ' ' + s[variant]} style={{ width: `${size / 16}rem`, height: `${size / 16}rem` }}>{hcp}</div>
    )
}

export default PlayerHandicap
