import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Row, Col, Button, ListGroup, FormGroup, FormControl, FormLabel } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import CommentItem from '../../Comment/CommentItem'
import TripRecapMockAwardItem from '../TripRecapMockAwardItem'
import { deleteMockAwardComment, deleteMockAward, postMockAwardEdit, getMockAwardsForAdmin } from '@severed-links/common.severedlinks-reducers/groups'
import TextareaAutosize from 'react-autosize-textarea'
import Confirm from '@severed-links/common.confirm'
import { reject, orderBy } from 'lodash'
import PlayerListItem from '../../Player/PlayerListItem'
import FormCheck from 'react-bootstrap/FormCheck'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './TripMockAwardsAdmin.scss'

const TripMockAwardsAdmin = () => {

    const dispatch = useDispatch()
    const { group, trip, groupId, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [showComments, setShowComments] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [showAddRecipients, setShowAddRecipients] = useState(false)
    const [awardId, setAwardId] = useState(null)
    const [awardName, setAwardName] = useState('')
    const [awardText, setAwardText] = useState('')
    const [isVisible, setVisible] = useState(false)
    const [recipients, setRecipients] = useState([])
    const [tripPlayers, setTripPlayers] = useState([])

    useEffect(() => updateTripPlayerState(), [recipients.length])

    const deleteComment = commentId => dispatch(deleteMockAwardComment(group._id, trip._id, commentId))

    const deleteRecipient = playerId => {
        var _recipients = [...recipients]
        _recipients = reject(_recipients, r => r.playerID === playerId)
        setRecipients(_recipients)
    }

    const addRecipient = player => {
        var _recipients = [...recipients]
        _recipients.push(player) 
        setRecipients(_recipients)
        setShowAddRecipients(false)
    }

    const saveAward = () => {
        setEditMode(false)
        setShowAddRecipients(false)
        const postData = { groupId: group._id, tripId: trip._id, awardId, awardName, awardText, isVisible, recipients: recipients.map(p => p.playerID) }
        dispatch(postMockAwardEdit(postData))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: `Edit ${postData.awardName}`, timeout: 3000 }))
        })
    }

    const deleteAward = mockAwardId => {
        setEditMode(false)
        setShowAddRecipients(false)
        dispatch(deleteMockAward(group._id, trip._id, mockAwardId))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: `Delete ${group.mockAwardName}`, timeout: 3000 }))
        })
    }

    const updateTripPlayerState = () => setTripPlayers(orderBy([...(trip.usaPlayers || []), ...(trip.eurPlayers || []), ...(trip.unassignedPlayers || [])], ['lastName', 'firstName'], ['asc', 'asc']).filter(p => !recipients.some(p1 => p1.playerID === p.playerId)))

    const editAward = o => {
        setEditMode(true)
        setShowAddRecipients(false)
        setAwardId(o.awardId || null)
        setAwardName(o.awardName || '')
        setAwardText(o.awardText || '')
        setRecipients(o.recipients || [])
        setVisible(o.isVisible || false)
    }

    const mockAwards = trip.mockAwardsForAdmin || []
    const mockAwardComments = trip.mockAwardComments || []
    const headerButtons = [
        mockAwardComments && mockAwardComments.length > 0 ? { variant: 'info', icon: 'comment', text: 'show comments', onClick: () => setShowComments(true) } : null,
        { variant: 'primary', icon: 'plus-circle', text: 'add an award', onClick: () => editAward({ awardId: null, awardName: '', awardText: '', recipients: [] }) },
    ].filter(i => i)
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h4><FontAwesomeIcon name='smile-wink' /> {group.mockAwardName}s</h4>
                <div className={s.controls}>
                {headerButtons.map(i => 
                <Button variant={i.variant} onClick={i.onClick} 
                    key={`mock-award-header-button-${i.text}`}>
                    <FontAwesomeIcon name={i.icon} />
                    {' '}
                    <span className={s.buttonTitle}>{i.text}</span>
                </Button>
                )}

                </div>
            </div>
            {!mockAwards || mockAwards.length === 0 ? 
                <p>No {group.mockAwardName}s have been awarded for this trip yet.</p> 
            : null}
            <Row>
                {mockAwards && mockAwards.map((i, index) =>
                <Col sm={6} xs={12} key={`mock-award-${i._id}`} >
                    <TripRecapMockAwardItem mockAward={i} isAdmin group={group} trip={trip} onEdit={editAward} />
                </Col>
                )}
            </Row>
            <Modal show={showComments} showFooter={false}
                heading={`${group.mockAwardName} Comments`}
                onClose={() => setShowComments(false)}>
                <ListGroup>
                {mockAwardComments && mockAwardComments.map(c =>
                <CommentItem canDelete id={c._id} text={c.comment} author={c}
                    key={`mock-award-comment-fader-${c._id}`}
                    date={c.dateEntered} onDelete={deleteComment} />
                )}
                </ListGroup>
            </Modal>
            <div>
                <Modal show={editMode} showFooter={false}
                    heading={`Edit ${group.mockAwardName}`}
                    onClose={() => setEditMode(false)}>
                    <div className={s.mockAwardEditorContainer}>
                        <FormGroup className={s.formGroup}>
                            <FormLabel>Title</FormLabel>
                            <FormControl value={awardName} placeholder='[Enter this award name...]' onChange={e => setAwardName(e.target.value)} />
                        </FormGroup>
                        <FormGroup className={s.formGroup}>
                            <FormLabel>Description</FormLabel>
                            <TextareaAutosize className='form-control'
                                placeholder="[Describe this award...]" 
                                value={awardText} rows={3}
                                onChange={e => setAwardText(e.target.value)}
                                onResize={e => {}} />
                        </FormGroup>
                        <FormGroup className={s.formGroup}>
                            <FormLabel>Visible?</FormLabel>
                            <div className={s.controls}>
                                <FormCheck type='switch' checked={isVisible}
                                    onChange={e => setVisible(e.target.checked)} />
                            </div>
                        </FormGroup>
                        <h4 className={s.heading}>Award Recipients</h4>
                        <ListGroup>
                        {recipients && recipients.map(p =>
                            <PlayerListItem player={{ ...p, _id: p.playerID }} 
                                rightDetail={<Button size='sm' variant='light' 
                                style={{ backgroundColor: 'transparent' }} 
                                onClick={() => deleteRecipient(p.playerID)}><FontAwesomeIcon name='times' /></Button>}
                                showHcp={false} clickDisabled showRole={false}
                                key={`mock-award-${awardId}-recipient-${p.playerID}`} />
                        )}
                        </ListGroup>
                        {!recipients || recipients.length === 0 ? <p>No recipients for this award have been designated.</p> : null}
                        <div className={s.buttonsContainer}>
                            {awardId ?
                            <span style={{ marginLeft: '10px' }}>
                                <Confirm title={`Delete ${group.mockAwardName}`}
                                    onConfirm={() => deleteAward(awardId)} enforceFocus={false}
                                    confirmText='delete award'
                                    body={<div><p>Are you sure you want to delete this award?</p></div>}
                                    variant='danger' buttonClassName={s.button} buttonIcon='trash' buttonText={<span className='d-none d-sm-inline-block'>delete</span>} />
                            </span>
                            : null}
                            <Button className={s.button} variant='light' onClick={() => setShowAddRecipients(true)}><FontAwesomeIcon name='plus-circle' /> <span className='d-none d-sm-inline-block'>add a recipient</span></Button>
                            <Button className={s.button + ' ' + s.cancel} variant='light' onClick={() => setEditMode(false)}><FontAwesomeIcon name='times' /> <span className='d-none d-sm-inline-block'>cancel</span></Button>
                            <Button className={s.button} disabled={!awardName || !awardText} variant='primary' onClick={() => saveAward()}><FontAwesomeIcon name='check' /> <span className='d-none d-sm-inline-block'>save</span></Button>
                        </div>
                    </div>
                </Modal>
                <Modal show={showAddRecipients} showFooter={false}
                    heading={`Add Award Recipient`}
                    onClose={() => setShowAddRecipients(false)}>
                    <div>
                    <ListGroup style={{ marginBottom: '0px' }}>
                        {tripPlayers && tripPlayers.map(p =>
                            <PlayerListItem player={p} 
                                onClick={() => addRecipient(p)}
                                showHcp={false} showRole={false}
                                key={`mock-award-add-player-${p.playerId}`} />
                        )}
                        </ListGroup>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default TripMockAwardsAdmin