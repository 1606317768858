import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useNavigate, useOutletContext } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import Image from 'react-bootstrap/Image'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Button from '@severed-links/common.button'
import Modal from '@severed-links/common.modal'
import * as s from './TripPlayerAdminPlayerEditor.scss'
import PlayerListItem from '../../Player/PlayerListItem'
import { commonImageUrl, playerAvatarUrl, routingPath } from '@severed-links/common.severedlinks-constants'
import { sumBy, flatten, has, uniq, keysIn } from 'lodash'
import { getPlayerLists, getMatchesScoreboard, getPlayers, getHandicapChecker, 
    getHandicapCheckerForPlayer, getPlayerWonLossRecordsForTrip, sendWonLossRecordReport } from '@severed-links/common.severedlinks-reducers/groups'
import pluralize from 'pluralize'
import numeral from 'numeral'
import TripPlayerTeamAndHcpAssignment from './TripPlayerTeamAndHcpAssignment'
import TripPlayerAdminPlayerWonLossRecords from './TripPlayerAdminPlayerWonLossRecords'
import TripPlayerAdminPlayerHandicapCalculator from './TripPlayerAdminPlayerHandicapCalculator'
import TripPlayerAdminTeeBoxOverrideEditor from './TripPlayerAdminTeeBoxOverrideEditor'

const TripPlayerAdminPlayerEditor = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()
    const userId = useSelector(state => state.account._id)
    const { group, groupId, trip, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const playerId = (params || {}).playerId || null

    const mapPlayerData = (arr = []) => {
        const handicapChecker = trip.handicapChecker || []
        return arr.map(i => ({ 
            ...i, 
            handicapChecker: handicapChecker.find(hc => hc.playerId === i.playerID),
            wonLossRecords: ((trip.wonLossRecords || []).find(wl => wl.playerId === i.playerID) || {}).records || [], 
        })).map(i => ({ 
            ...i, 
            wonLossPct: i.wonLossRecords.length && i.wonLossRecords.some(i => i.isOverall) ? i.wonLossRecords.find(i => i.isOverall).pct : 0,
        })).map(i => ({ 
            ...i, 
            wonLossPctDisplay: i.wonLossPct === 1 ? '1.000' : numeral(i.wonLossPct).format('.000'),
        }))
    }

    const matchplayerIds = flatten(((trip.scoreboard || {}).matches || []).map(m => { { return [...m.usaPlayerList, ...m.eurPlayerList] } })).map(m => m.playerID)

    const _players = mapPlayerData([
        ...(trip.usaPlayers || []),
        ...(trip.eurPlayers || []),
        ...(trip.unassignedPlayers || []),
        ...(trip.notAttendingPlayers || []),
        ...((group.players || []).filter(p => ![...(trip.usaPlayers || []), ...(trip.eurPlayers || []), ...(trip.unassignedPlayers || []), ...(trip.notAttendingPlayers || [])].some(p2 => p2.playerID === p.playerID))),
    ]).map(p => ({ ...p, isAssignedToMatch: matchplayerIds.some(m => m === p.playerID) }))
    const player = _players.find(p => p.playerId === playerId) || {}
    const isUsa = (trip.usaPlayers || []).some(i => i.playerID === player.playerID)
    const isEur = (trip.eurPlayers || []).some(i => i.playerID === player.playerID)
    const isUnassigned = (trip.unassignedPlayers || []).some(i => i.playerID === player.playerID)
    const isAssignable = isUsa || isEur || isUnassigned
    const isNotAttending = (trip.notAttendingPlayers || []).some(i => i.playerID === player.playerID)
    const isNotListed = !isUsa && !isEur && !isUnassigned && !isNotAttending

    const { wonLossRecords, wonLossPct, wonLossPctDisplay } = player

    const handleGoBack = () => {
        const _path = `${routingPath(groupId, isGlobalAdminPath, ((group || {}).name || ' ').substring(0,1).toUpperCase())}trip/${tripId}/admin/players`
        navigate(_path)
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <div className={s.goBack}>
                    <Button variant='light' icon='chevron-left' onClick={handleGoBack} />
                </div>
                <h4 className={s.title}>Edit Trip Player Tee boxes, Handicap</h4>
                <div className={s.controls}>

                </div>
            </div>
            <div className={s.playerHeader}>
                <div className={s.avatar} style={{ backgroundImage: `url(${playerAvatarUrl(player.imageUrl)})` }} />
                <div className={s.firstName}>{player.firstName}</div>
                <div className={s.lastName}>{player.lastName}</div>
            </div>
            <ListGroup className={s.list}>

                {isUsa || isEur || isUnassigned ?
                <>
                <TripPlayerAdminTeeBoxOverrideEditor group={group} 
                    trip={trip} player={player} />

                <TripPlayerAdminPlayerHandicapCalculator group={group} 
                    trip={trip} player={player} />
                </>
                : null}
                
                <TripPlayerTeamAndHcpAssignment group={group} 
                    trip={trip} player={player}
                    isGlobalAdmin={isGlobalAdmin} />

                <TripPlayerAdminPlayerWonLossRecords wonLossRecords={wonLossRecords} />

            </ListGroup>
        </div>
    )
}

export default TripPlayerAdminPlayerEditor

/*
    return (
        <View style={s.container}>

            <View style={s.headingContainer}>
                <PlayerAvatar imageUrl={player.imageUrl} rounded size={84} />
                <Text style={s.firstName}>{player.firstName}</Text>
                <Text style={s.lastName}>{player.lastName}</Text>
            </View>

            <ScrollView style={s.container}>

                <TripPlayerAdminTeeBoxOverrideEditor group={group} 
                    trip={trip} player={player} />
                
                <TripPlayerAdminPlayerHandicapCalculator group={group} 
                    trip={trip} player={player} />

                <TripPlayerTeamAndHcpAssignment group={group} 
                    trip={trip} player={player}
                    isGlobalAdmin={isGlobalAdmin} />

                <TripPlayerAdminPlayerWonLossRecords wonLossRecords={wonLossRecords} />
                
            </ScrollView>
        </View>
    )
}

export default TripPlayerAdminPlayerEditor

const s = StyleSheet.create({
    container: {
        flex: 1,
        paddingBottom: 30,
    },
    headingContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        marginBottom: 10,
    },
    firstName: {
        fontSize: 22,
    },
    lastName: {
        fontSize: 16,
    }
})
*/