import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { } from 'react-router'
import { Card, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripRounds.scss'
import TripRoundListItem from './TripRoundListItem'

const TripRounds = ({ groupId, tripId, tripName, rounds = [], myMatches = [] }) => {

    if (!Array.isArray(rounds)) return null
    return (
        <Card className={s.tripRoundPanel}>
            <Card.Header className={s.header}>
                <div className={s.icon}><FontAwesomeIcon name={'circle'} isRegular /></div>
                <div className={s.title}>Golf course rounds</div>                
            </Card.Header>
            <ListGroup className={s.roundList} variant='flush'>
            {rounds && rounds.map(i =>
                <TripRoundListItem groupId={groupId} tripId={tripId} tripName={tripName} round={i} showScorecardLaunchButton
                    key={`round-${i.tripID}-${i.round}`} myMatch={myMatches.find(m => m.tripID == i.tripID && m.round == i.round)} />
            )}
            </ListGroup>
            {!rounds || rounds.length === 0 ? <Card.Body>No rounds for this trip have been created yet.</Card.Body> : null}
        </Card>
    )
}

export default TripRounds