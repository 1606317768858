// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripRoundSkinsProxies__container___adU5L{margin-bottom:1rem}.TripRoundSkinsProxies__container___adU5L .TripRoundSkinsProxies__proxyDistance___jyOsL{margin-right:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/TripRoundSkinsProxies.scss"],"names":[],"mappings":"AAAA,0CACI,kBAAA,CAEA,wFACI,iBAAA","sourcesContent":[".container {\n    margin-bottom: 1rem;\n    \n    .proxyDistance {\n        margin-right: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TripRoundSkinsProxies__container___adU5L`;
export var proxyDistance = `TripRoundSkinsProxies__proxyDistance___jyOsL`;
export default ___CSS_LOADER_EXPORT___;
