// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeletedTrips__container___s1rk5{margin-bottom:10px}.DeletedTrips__container___s1rk5 .DeletedTrips__deletedTripsButtonContainer___b4ML5{margin-top:20px}.DeletedTrips__list___d2okC .DeletedTrips__item___PPMi2{position:relative}.DeletedTrips__list___d2okC .DeletedTrips__item___PPMi2 .DeletedTrips__recoverButton___emxhX{position:absolute;right:5px;top:5px;margin-left:15px}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Admin/DeletedTrips.scss"],"names":[],"mappings":"AAAA,iCACI,kBAAA,CAEA,oFACI,eAAA,CAKJ,wDACI,iBAAA,CACA,6FACI,iBAAA,CACA,SAAA,CACA,OAAA,CACA,gBAAA","sourcesContent":[".container {\n    margin-bottom: 10px;\n\n    .deletedTripsButtonContainer {\n        margin-top: 20px;\n    }\n}\n\n.list {\n    .item {\n        position: relative;\n        .recoverButton {\n            position: absolute;\n            right: 5px;\n            top: 5px;\n            margin-left: 15px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var container = `DeletedTrips__container___s1rk5`;
export var deletedTripsButtonContainer = `DeletedTrips__deletedTripsButtonContainer___b4ML5`;
export var list = `DeletedTrips__list___d2okC`;
export var item = `DeletedTrips__item___PPMi2`;
export var recoverButton = `DeletedTrips__recoverButton___emxhX`;
export default ___CSS_LOADER_EXPORT___;
