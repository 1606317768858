import React, { useRef } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { useDrop } from 'react-dnd'
import { ITEM_TYPES } from '../DragAndDropItemTypes'
import * as s from './FoursomeBlankMatchSlot.scss'

const UnassignedBlankMatchItem = () => {
  
      return (
        <div className={s.container}>
            <ListGroup.Item className={s.blankItem}>
                <div>[Drop a match here...]</div>
            </ListGroup.Item>
        </div>
      )
}

export default UnassignedBlankMatchItem
