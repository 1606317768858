import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom'
import { ListGroup, FormGroup, FormControl, FormLabel } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { FRIENDLY_SHORT_DATE_FORMAT, FRIENDLY_SHORT_TIME_FORMAT } from '@severed-links/common.severedlinks-constants'
import * as s from './TripEventEditorDate.scss'
import Datetime from 'react-datetime'
import FormCheck from 'react-bootstrap/FormCheck'
import Button from '@severed-links/common.button'
import validator from 'validator'
import moment from 'moment-timezone'

const TripEventEditorDate = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { onChange, isAllDayEvent, startDay, startTime, endTime, timeZoneId,
        isTaskEvent, isPollEvent, eventType } = useOutletContext()
    const [_isAllDayEvent, setAllDayEvent] = useState(false)
    const [_startDay, setStartDay] = useState(null)
    const [_startTime, setStartTime] = useState(null)
    const [_endTime, setEndTime] = useState(null)
    const _isValid = _startDay && moment(_startDay).isValid() &&
        _startTime && moment(_startTime).isValid() &&
        _endTime && moment(_endTime).isValid()

    useEffect(() => {
        setAllDayEvent(isAllDayEvent || false)
        if (startDay && moment.tz(startDay, timeZoneId).isValid()) {
            setStartDay(startDay)
        }
        if (startTime && moment.tz(startTime, timeZoneId).isValid()) {
            setStartTime(startTime)
        }
        if (endTime && moment.tz(endTime, timeZoneId).isValid()) {
            setEndTime(endTime)
        }
    }, [isAllDayEvent, startDay, startTime, endTime, timeZoneId])

    const handleUpdate = () => {
        if (onChange) {
            onChange({ startDay: _startDay, startTime: _startTime, endTime: _endTime, isAllDayEvent: _isAllDayEvent })
        }
        handleCancel()
    }
    
    const handleCancel = () => {
        const _path = location.pathname.replace(/\/date$/gi, '')
        navigate(_path)
    }

    const allDayEventUpdateCallback = () => {
        const _newStartDay = moment.tz(_startDay, timeZoneId)
        const _isDayValid = _newStartDay.isValid()
        if (_isAllDayEvent && _isDayValid) {
            setStartTime(_newStartDay.startOf('day').toISOString(true))
            setEndTime(_newStartDay.endOf('day').toISOString(true))
        }
    }

    const eventDateChange = _dt => {
        const _startDayUpdate = moment.tz(_dt, timeZoneId).startOf('day')
        const _originalStartTime = moment.tz(_startDay, timeZoneId).startOf('day')
        const _changeDays = _startDayUpdate.diff(_originalStartTime, 'day')
        setStartDay(_startDayUpdate.toISOString(true))
        setStartTime(moment.tz(_startTime, timeZoneId).add(_changeDays, 'day').toISOString(true))
        setEndTime(moment.tz(_endTime, timeZoneId).add(_changeDays, 'day').toISOString(true))
    }

    const startTimeChange = _dt => {
        const _newStartTime = moment.tz(_dt, timeZoneId)
        setStartTime(_newStartTime.toISOString(true))
        if (_newStartTime.isSameOrAfter(_endTime, 'minute')) {
            setEndTime(_newStartTime.clone().add(1, 'hour').toISOString(true))
        }
    }

    const endTimeChange = _dt => {
        const _newEndTime = moment.tz(_dt, timeZoneId)
        setEndTime(_newEndTime.toISOString(true))
    }


    if (!_startDay || !_startTime || !_endTime || !moment(_startDay).isValid() || !moment(_startTime).isValid() || !moment(_endTime).isValid()) return null
    return (
        <div className={s.container}>

            {!isTaskEvent && !isPollEvent ?
            <FormGroup className={s.formGroup + ' ' + s.isAllDayEventContainer}>
                <FormLabel className={s.label}>All day event?</FormLabel>
                <div className={s.switch}>
                    <FormCheck type='switch' checked={_isAllDayEvent} name='isAllDayEvent' 
                        onChange={e => setAllDayEvent(e.target.checked)} />
                </div>
            </FormGroup>
            : null}

            <div className={s.dateTimeContainer}>

                <FormGroup className={s.dateContainer}>
                    <FormLabel className={s.dateLabel}>{isTaskEvent ? 'Task deadline' : isPollEvent ? 'Response deadline' : 'Event date'}</FormLabel>
                    <Datetime value={moment.tz(_startDay, timeZoneId).toDate()} 
                        open viewMode='days'
                        dateFormat={FRIENDLY_SHORT_DATE_FORMAT}
                        timeFormat={false}
                        input={false}
                        onChange={_dt => eventDateChange(_dt)} />
                </FormGroup>

                {!isTaskEvent && !isPollEvent ?
                <div className={s.timeContainer}>
                    {!_isAllDayEvent ? 
                    <div className={s.startTime}>
                        <FormLabel className={s.dateLabel}>Start time</FormLabel>
                        <Datetime value={moment.tz(_startTime, timeZoneId).toDate()} 
                            open viewMode='time'
                            dateFormat={false} 
                            timeFormat={FRIENDLY_SHORT_TIME_FORMAT}
                            input={false}
                            onChange={e => startTimeChange(e)} />
                    </div>
                    : null}
                    {!_isAllDayEvent ? 
                    <div className={s.endTime}>
                        <FormLabel className={s.dateLabel}>End time</FormLabel>
                        <Datetime value={moment.tz(_endTime, timeZoneId).toDate()} 
                            open viewMode='time'
                            dateFormat={false} 
                            timeFormat={FRIENDLY_SHORT_TIME_FORMAT}
                            input={false}
                            onChange={e => endTimeChange(e)} />
                    </div>
                    : null}
                </div>
                : null}
            </div>

            <div className={s.timeZone}>
                <FontAwesomeIcon className={s.icon} name='globe' />
                <div className={s.title}>Time zone</div>
                <div className={s.value}>{timeZoneId}</div>
            </div>

            <div className={s.buttonsContainer}>
                <Button variant='light' title='cancel' icon='times' onClick={() => handleCancel()} />
                <Button className={s.saveButton} variant='primary' title={`update ${eventType}`} icon='check' onClick={() => handleUpdate()} disabled={!_isValid} />
            </div>
        </div>
    )
}

export default TripEventEditorDate
