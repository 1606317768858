// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GlobalAdminGroupSearcher__container___vh47r{width:300px;display:inline-block;margin:0px 1rem}.GlobalAdminGroupSearcher__container___vh47r.GlobalAdminGroupSearcher__full___eSiYb{width:100%;display:block;margin-bottom:20px;margin-right:10px}.GlobalAdminGroupSearcher__container___vh47r .GlobalAdminGroupSearcher__formGroup___wY1yD{margin:0px}.GlobalAdminGroupSearcher__container___vh47r .GlobalAdminGroupSearcher__formGroup___wY1yD .GlobalAdminGroupSearcher__inputGroup___dPFPx{flex-flow:row nowrap;min-width:250px}.GlobalAdminGroupSearcher__container___vh47r .GlobalAdminGroupSearcher__formGroup___wY1yD .GlobalAdminGroupSearcher__menuItem___Nxl9s a{white-space:nowrap;overflow-x:hidden;text-overflow:ellipsis}.GlobalAdminGroupSearcher__container___vh47r .GlobalAdminGroupSearcher__formGroup___wY1yD .GlobalAdminGroupSearcher__menuItem___Nxl9s a:hover{color:#337ab7}`, "",{"version":3,"sources":["webpack://./src/routes/GlobalAdmin/GlobalAdminGroupSearcher.scss"],"names":[],"mappings":"AAAA,6CACI,WAAA,CACA,oBAAA,CACA,eAAA,CACA,oFACI,UAAA,CACA,aAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,0FACI,UAAA,CAEA,wIACI,oBAAA,CACA,eAAA,CAIA,wIACI,kBAAA,CACA,iBAAA,CACA,sBAAA,CACA,8IACI,aAAA","sourcesContent":[".container {\n    width: 300px;\n    display: inline-block;\n    margin: 0px 1rem;\n    &.full {\n        width: 100%;\n        display: block;\n        margin-bottom: 20px;\n        margin-right: 10px;\n    }\n\n    .formGroup {\n        margin: 0px;\n\n        .inputGroup { \n            flex-flow: row nowrap;\n            min-width: 250px;\n        }\n\n        .menuItem {\n            a {\n                white-space: nowrap;\n                overflow-x: hidden;\n                text-overflow: ellipsis;\n                &:hover {\n                    color: #337ab7;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `GlobalAdminGroupSearcher__container___vh47r`;
export var full = `GlobalAdminGroupSearcher__full___eSiYb`;
export var formGroup = `GlobalAdminGroupSearcher__formGroup___wY1yD`;
export var inputGroup = `GlobalAdminGroupSearcher__inputGroup___dPFPx`;
export var menuItem = `GlobalAdminGroupSearcher__menuItem___Nxl9s`;
export default ___CSS_LOADER_EXPORT___;
