import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { ListGroup, NavDropdown, Image, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminNewPlayers.scss'
import { setGlobalAdminState, getNewPlayers } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import { first } from 'lodash'
import PlayerListItem from '../Player/PlayerListItem'
import pluralize from 'pluralize'
import moment from 'moment-timezone'
import Modal from '@severed-links/common.modal'
import phoneFormatter from 'phone-formatter'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'

const NewPlayerListItem = ({ timeZone, logins, lastLogin, groupsCreatedCount, groupsJoinedCount, onClick, ...p }) => (
    <PlayerListItem player={p} className={s.item} onClick={onClick} 
        rightDetail={<div className={s.rightDetail}>
            {groupsCreatedCount ?
            <div className={s.groupInfo + ' ' + s.created}>
                <div className={s.groupInfoHeading}><span className={s.largeText}>Groups</span> Created</div>
                <div className={s.groupInfoValue}>{groupsCreatedCount}</div>
            </div>
            : null}
            {groupsJoinedCount ?
            <div className={s.groupInfo + ' ' + s.joined}>
                <div className={s.groupInfoHeading}><span className={s.largeText}>Groups</span> Joined</div>
                <div className={s.groupInfoValue}>{groupsJoinedCount}</div>
            </div>
            : null}
            <div className={s.loginInfo}>
                <div>{pluralize('login', logins, true)}</div>
                <div>{lastLogin && moment(lastLogin).isValid() ? moment.tz(lastLogin, timeZone).fromNow() : 'Never logged in'}</div>
            </div>
        </div>} />
)

const NewPlayerGroupItem = ({ timeZone, groupId, playerId, name, createdAt, isGroupCreator, ...props }) => (
    <div className={s.groupItem}>
        <div className={s.name}>{name}</div>
        <div className={s.badge}>
            <Badge bg={isGroupCreator ? 'success' : 'info'} text='light'>
                {isGroupCreator ? 'Created' : 'Joined'}
                {' '}
                {moment.tz(createdAt, timeZone).format('M/D/YY')}
            </Badge>
        </div>
    </div>
)

const GlobalAdminNewPlayers = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const timeZone = useSelector(state => state.account.time_zone)
    const newPlayers = useSelector(state => state.globalAdmin.players.newPlayers)
    const newPlayerDays = useSelector(state => state.globalAdmin.players.newPlayerDays)
    const numNewPlayers = useSelector(state => state.globalAdmin.players.numNewPlayers)
    const newPlayerId = params.newPlayerId || null
    const _showNewPlayerDetails = !!newPlayerId && newPlayers.some(i => i.playerId === newPlayerId)
    const _newPlayerDetails = newPlayers.find(i => i.playerId === newPlayerId) || {}
    const _timePeriods = [
        { label: `Last week`, days: 7 },
        { label: `Last month`, days: 30 },
        { label: `Last 90 days`, days: 90 },
        { label: `Last 6 months`, days: 180 },
        { label: `Last year`, days: 365 },
    ]
    const _selectedTimePeriod = _timePeriods.find(i => i.days === newPlayerDays) || {}

    useEffect(() => {
        dispatch(getNewPlayers(newPlayerDays))
    }, [newPlayerDays])

    const handleSelect = _days => { 
        dispatch(setGlobalAdminState('players', { newPlayerDays: parseInt(_days) }))
    }

    const handleShowDetails = _player => {
        navigate(`/global-admin/players/new-players/${_player.playerId}/details`)
    }

    const handleHideDetails = () => {
        navigate(`/global-admin/players/new-players`)
    }

    const _detailItems = _newPlayerDetails && _newPlayerDetails.playerId ? [
        { icon: `phone`, title: `Cell phone`, value: `${phoneFormatter.format(_newPlayerDetails.cellPhone, '(NNN) NNN-NNNN')}` },
        { icon: `envelope`, title: `Email address`, value: `${_newPlayerDetails.emailAddress}` },
        { icon: `users`, title: `Groups`, value: _newPlayerDetails.groupsCount ? _newPlayerDetails.groups : 'None' },
        { icon: `sign-in-alt`, title: `Logins`, value: `${_newPlayerDetails.logins}` },
        { icon: `clock`, title: `Last Login`, value: `${_newPlayerDetails.lastLogin && moment(_newPlayerDetails.lastLogin).isValid() ? moment.tz(_newPlayerDetails.lastLogin, timeZone).format('ddd MMM D YYYY hh:mm:ss A') : 'Never logged in'}` },
    ].filter(i => i.title) : []

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h3 className={s.title}><FontAwesomeIcon name='plus-circle' /> New Players</h3>
                <div className={s.controls}>
                    <NavDropdown title={_selectedTimePeriod.label || ''}
                        onSelect={e => handleSelect(e)}>
                    {_timePeriods && _timePeriods.map(i => 
                    <NavDropdown.Item key={`time-period-${i.days}-days`} eventKey={`${i.days}`}>{i.label}</NavDropdown.Item>
                    )}
                    </NavDropdown>
                </div>
            </div>
            <ListGroup className={s.list}>
            {!newPlayers || !newPlayers.length ? <ListGroup.Item className={s.item}>No new players located for this time period.</ListGroup.Item> : null}
            {newPlayers && newPlayers.map(p =>
            <NewPlayerListItem {...p} timeZone={timeZone} 
                onClick={() => handleShowDetails(p)}
                key={`new-player-item-${p._id}`} />
            )}
            </ListGroup>

            <Modal show={_showNewPlayerDetails} 
                onClose={() => handleHideDetails()}
                showFooter={false}
                heading={`New Player Info: ${_newPlayerDetails.firstName || ''} ${_newPlayerDetails.lastName || ''}`}>
                <div className={s.newPlayerReportContainer}>
                    <div className={s.avatarContainer}>
                        <Image src={playerAvatarUrl(_newPlayerDetails.imageUrl)} />
                    </div>
                    <ListGroup className={s.list}>
                    {_detailItems && _detailItems.map(i =>
                    <ListGroup.Item className={s.item} key={`detail-item-${i.title}`}>
                        <div className={s.icon}>
                            {i.icon ? <FontAwesomeIcon name={i.icon} /> : null}
                        </div>
                        <div className={s.detailsContainer}>
                            <div className={s.title}>{i.title}</div>
                            {i.title !== 'Groups' || (i.title === 'Groups' && !Array.isArray(i.value)) ? 
                            <div className={s.value}>{i.value}</div>
                            : 
                            <div className={s.groupsContainer}>
                            {i.value && i.value.map(g => 
                            <NewPlayerGroupItem {...g} timeZone={timeZone} 
                                key={`player-${i.playerId}-group-${g.groupId}`} />
                            )}
                            </div>}
                        </div>
                    </ListGroup.Item>
                    )}
                    </ListGroup>
                </div>
            </Modal>
        </div>
    )
}

export default GlobalAdminNewPlayers
