import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import InputGroup from 'react-bootstrap/InputGroup'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TextInput from '@severed-links/common.text-input'
import * as s from './ProxyDistanceEditor.scss'

const ProxyDistanceItem = ({ value = '0', onChange = null, icon, suffix,  }) => (
    <TextInput className={s.unitEditorContainer} type='number' text='center' value={value} icon={icon} suffix={suffix} onChange={onChange ? _text => onChange(_text) : () => void(0)} />
)

const ProxyDistanceEditor = ({ showLabel = true, units = 'yds', distance = 0, inches = 0, onDistanceChange = null, onInchesChange = null }) => {

    useEffect(() => {
    }, [])

    const _handleUpdate = (_fieldName, _text) => {
        if (_fieldName === 'distance' && onDistanceChange && !isNaN(_text) && parseInt(_text) >= 0) {
            onDistanceChange(_text)
        } else if (_fieldName === 'inches' && onInchesChange && !isNaN(_text) && parseInt(_text) >= 0 && parseInt(_text) < 12) {
            onInchesChange(_text)
        }
    }

    const _mainUnits = units === 'yds' ? units : 'ft'
    const _showInches = _mainUnits === 'ft'
    return (
        <div className={s.container}>
            {showLabel ? <div className={s.label}>Shot distance:</div> : null}
            <ProxyDistanceItem value={`${distance || 0}`} suffix={_mainUnits} onChange={_text => _handleUpdate('distance', _text)} />
            {_showInches ? <ProxyDistanceItem value={`${inches || 0}`} suffix={'in'} onChange={_text => _handleUpdate('inches', _text)} /> : null}
        </div>
    )
}

export default ProxyDistanceEditor
