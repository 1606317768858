import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Row, Col, Button, FormGroup, FormLabel } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import SettingControl from './SettingControl'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { launchCommentWindow, deleteAllComments } from '@severed-links/common.severedlinks-reducers/groups'
import Confirm from '@severed-links/common.confirm'

const TripAdminComments = () => {

    const dispatch = useDispatch()
    const { group, groupId, trip, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    if (!trip) return null

    const settings = trip.settings || {}

    const doLaunchCommentWindow = () => dispatch(launchCommentWindow(groupId, tripId))
        
    const deleteAllComments = () => {
        dispatch(deleteAllComments(groupId, tripId))
        .then(action => dispatch(createNotification({ ...action.payload })))
    }

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h4>Comment Settings</h4>
                    <SettingControl variableType='bool' 
                        groupId={groupId} tripId={tripId}
                        title={'Commenting is active'} 
                        variable='canPlayersComment' 
                        value={settings.canPlayersComment} />
                    <FormGroup>
                        <FormLabel style={{ display: 'block' }}>Launch comment box for online users</FormLabel>
                        <Button variant='light' onClick={() => doLaunchCommentWindow()}><FontAwesomeIcon name='rocket' /> launch</Button>
                    </FormGroup>
                    {trip.comments && trip.comments.length > 0 ?
                    <FormGroup>
                        <FormLabel style={{ display: 'block' }}>Delete all comments</FormLabel>
                        <Confirm variant='danger' onConfirm={() => deleteAllComments()}
                            title='delete comment' enforceFocus={false}
                            confirmText='delete all comments'
                            body={`Are you sure you want to delete all comments (${(trip.comments || []).length})?`}
                            buttonIcon='trash' buttonText='delete all comments' />
                    </FormGroup>
                    : null}
                </Col>
            </Row>
        </div>
    )
}

export default TripAdminComments