import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams, Outlet } from 'react-router-dom'
import { Alert, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TicketListItem from './TicketListItem'
import { orderBy } from 'lodash'
import { getMyTickets, getOthersTickets, setActiveTicket, 
    updateActiveTicket, getHelpTicketRetentionInfo, setForceTicketReload } from '@severed-links/common.severedlinks-reducers/help'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './HelpCenter.scss'
import FloatHeaderWithButtons from '../../components/FloatHeaderWithButtons'

const HelpCenter = ({ showAllTickets = false }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tickets = orderBy(useSelector(state => state.help.tickets), ['sortOrder','updatedAt'], ['asc','desc'])
    const otherTickets = orderBy(useSelector(state => state.help.otherTickets), ['sortOrder','updatedAt'], ['asc','desc'])
    const { _id: playerId, isGlobalAdmin } = useSelector(state => state.account)
    const { completedRetentionMinutes, deletedRetentionMinutes, forceTicketReload } = useSelector(state => state.help)

    useEffect(() => {
        dispatch(getHelpTicketRetentionInfo())
        loadTickets()
    }, [])

    useEffect(() => {
        if (forceTicketReload) {
            dispatch(setForceTicketReload(false))
            loadTickets()
        }
    }, [forceTicketReload])

    useEffect(() => {
        if (isGlobalAdmin) {
            loadTickets()
        }
    }, [isGlobalAdmin])

    const loadTickets = () => {
        dispatch(getMyTickets())
        if (isGlobalAdmin) dispatch(getOthersTickets())
    }

    const newTicket = () => {
        navigate('/profile/help-center/new-issue')
    }

    return (
        <div className={s.container}>
            <FloatHeaderWithButtons headerTag='h2' header='Help Center' buttons={[
                { text: 'new issue', icon: 'plus', variant: 'primary', onClick: () => newTicket() }
            ]} />
            <p style={{ marginTop: '20px' }}>Submit problems with the site, feature requests, or feedback here.  We cannot help you with relationship issues or your golf swing.</p>
            {!tickets || tickets.length === 0 ?
                <Alert>You have no open issues.</Alert>
            :
                <div>
                    <h4>Your Open Issues</h4>
                    <ListGroup className={s.ticketList}>
                    {tickets && orderBy(tickets, ['sortOrder','updateDate'], ['asc','desc']).map(t => <TicketListItem key={t._id} t={t} />)}
                    </ListGroup>
                </div>
            }
            {isGlobalAdmin ?
            <div>
                <h4>Issues From Others</h4>
                <ul className={s.ticketList}>
                {otherTickets && orderBy(otherTickets, ['sortOrder','updateDate'], ['asc','desc']).map(t => <TicketListItem key={t._id} t={t} />)}
                </ul>
                {!otherTickets || otherTickets.length === 0 ?
                    <Alert>You have no open issues to help others with.</Alert>
                : null}

            </div>
            : null}

            <Outlet />
            
        </div>
    )
}

export default HelpCenter