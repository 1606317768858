import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useOutletContext, Outlet } from 'react-router-dom'
import { Row, Col, Card, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TripRoundSkinsProxies from './TripRoundSkinsProxies'
import TripMatchScoreboard from './TripMatchScoreboard'
import TripRoundLowPlayerScores from './TripRoundLowPlayerScores'
import TripRoundLowTeamScores from './TripRoundLowTeamScores'

const TripRoundScoreboard = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const playerId = useSelector(state => state.account._id)
    const round = parseInt(params.round || '1')
    const { group, trip, groupId, tripId, isGroupAdmin, isGlobalAdmin } = useOutletContext()
    if (!group || !trip) return null
    const _round = (trip.rounds || []).find(r => r.round === round) || {}
    const isOnlineScorecardEnabled = _round.isOnlineScorecardEnabled || false
    const isAdmin = isGroupAdmin || isGlobalAdmin
    const matches = ((trip.scoreboard || {}).matches || []).filter(r => r.round === round)
    const skins = ((trip.scoreboard || {}).skins || []).filter(r => r.round === round)
    const proxies = ((trip.scoreboard || {}).proxies || []).filter(r => r.round === round)
    const myFoursomeNumbers = matches.filter(i => (i.usaPlayerList || []).some(x => x.playerID === playerId) || (i.eurPlayerList || []).some(x => x.playerID === playerId)).map(i => i.foursome)
    return (
        <div>
            <Row>
                <Col md={8} sm={7} xs={12}>
                    <TripMatchScoreboard groupId={groupId} tripId={tripId} trip={trip} round={round} matches={matches} 
                        isShotgun={_round.isShotgun} title={`${_round.roundName || ''} Matches`} />
                </Col>
                <Col md={4} sm={5} xs={12}>
                    <TripRoundLowPlayerScores trip={trip} round={round} />
                    <TripRoundLowTeamScores trip={trip} round={round} />
                    {_round.skinType !== 'none' ? <TripRoundSkinsProxies trip={trip} list={skins} listType='skins' title={`${_round.roundName || ''} Skins${_round.skinType === 'net' ? ` (Net Score)` : ''}`} /> : null}
                    <TripRoundSkinsProxies trip={trip} list={proxies} listType='proxies' title={`${_round.roundName || ''} Proxies`} />
                </Col>
            </Row>
            <Outlet context={{ group, trip, groupId, tripId, round: _round, isGroupAdmin, isGlobalAdmin, myFoursomeNumbers }} />
        </div>
    )
}

export default TripRoundScoreboard