import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { ListGroup, Image, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import * as s from './NearbyPlayers.scss'
import GeolocationUpdater from '../GeoLocation/GeolocationUpdater'
import { getNearbyPlayers } from '@severed-links/common.severedlinks-reducers/geoLocation'
import { compose, withProps } from 'recompose'
import { GoogleMap, Marker, OverlayView, useJsApiLoader } from '@react-google-maps/api'
import Spinner from '@severed-links/common.spinner'
import PlayerListItem from '../Player/PlayerListItem'
import moment from 'moment-timezone'
import { playerAvatarUrl, GOOGLE_MAPS_STATIC_MAP_API_KEY } from '@severed-links/common.severedlinks-constants'
import numeral from 'numeral'
import { reject, omit, pick, sum, first } from 'lodash'

const PlayerMapMarker = ({ tripId, show = true, playerId, firstName = '', lastName = '', imageUrl = null, latitude = 0, longitude = 0 }) => {
    return (
        <OverlayView key={`map-marker-${tripId}-${playerId}`}
            clickable={false} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{ lat: latitude, lng: longitude }}>
            <OverlayTrigger placement={'top'} overlay={<Tooltip id={`nearby-player-tooltip-${playerId}`}>{firstName} {lastName}</Tooltip>}>
                <div className={s.playerMarker + ' ' + (show ? s.show : s.hide)} title={`${firstName} ${lastName}`}>
                    <div className={s.avatar}><Image src={playerAvatarUrl(imageUrl)} /></div>
                </div>
            </OverlayTrigger>
        </OverlayView>
    )
}

const NearbyPlayers = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const _mapViewRef = useRef()
    const groupId = params.groupId || null
    const tripId = params.tripId || null
    const geo = useSelector(state => state.geoLocation)
    const firstName = useSelector(state => state.account.firstName)
    const lastName = useSelector(state => state.account.lastName)
    const imageUrl = useSelector(state => state.account.imageUrl)
    const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: GOOGLE_MAPS_STATIC_MAP_API_KEY })
    const [map, setMap] = useState(null)
    const [playersToHide, setPlayersToHide] = useState([])
    const nearbyPlayers = (geo.nearbyPlayers || []).map(p => ({
        ...p, 
        show: !playersToHide.includes(p.playerId),
    })).map(p => ({
        ...p, 
        icon: { icon: `${p.show ? 'check-circle' : 'circle'}`, className: s[`${p.show ? 'show': 'hide'}`] },
    }))
    const _visiblePlayerCoords = nearbyPlayers.filter(p => !playersToHide.includes(p.playerId)).map(p => ({ lat: p.latitude, lng: p.longitude }))

    useEffect(() => {
        if (groupId && tripId) {
            dispatch(getNearbyPlayers(groupId, tripId))
        }
    }, [groupId, tripId])

    const onLoad = useCallback(_map => {
        if (isLoaded) {
            setMap(_map)
        }
    }, [isLoaded])
    
    const onUnmount = useCallback(_map => {
        setMap(null)
    }, [])

    useEffect(() => {
        const _coordsToFit = _visiblePlayerCoords.length ? _visiblePlayerCoords : geo.latitude !== 0.00 || geo.longitude !== 0.00 ? [{ lat: geo.latitude, lng: geo.longitude }] : []
        if (isLoaded && map && _coordsToFit.length) {
            if (_coordsToFit.length === 1) {
                const { lat, lng } = _coordsToFit[0]
                map.setCenter({ lat, lng })
                map.setZoom(17)
            } else if (_coordsToFit.length > 1) {
                const _bounds = new window.google.maps.LatLngBounds()
                _coordsToFit.map(_coord => _bounds.extend(_coord))
                map.fitBounds(_bounds)
            }
        }
      }, [isLoaded, map, _visiblePlayerCoords.length])

    const showDistance = (p = {}) => {
        if (p.distanceInMiles > 0.5)
            return `${numeral(p.distanceInMiles).format('0.0')} mi ${p.directionDisplay}`
        else if (p.distanceInYards >= 100)
            return `${Math.round(p.distanceInYards)} yds ${p.directionDisplay}`
        else 
        return `${Math.round(p.distanceInFeet)} feet ${p.directionDisplay}`
    }

    const showTimeElapsed = lastUpdate => moment(lastUpdate).fromNow()

    // add comment to force commit
    const handlePlayerClick = _playerId => {
        var _playersToHide = [...playersToHide]
        if (!_playersToHide.includes(_playerId)) {
            _playersToHide.push(_playerId)
        } else {
            _playersToHide = reject(_playersToHide, playerId => playerId === _playerId)
        }
        setPlayersToHide(_playersToHide)
    }

    const handleSelectAll = () => {
        setPlayersToHide(playersToHide.length === 0 ? nearbyPlayers.map(p => p.playerId) : [])
    }

    if (!groupId || !tripId) return null
    const _pathToDetails = `/group/${groupId}/trip/${tripId}/details`
    return (
        <div className={s.container}>
            <div className={s.header}>
                <div className={s.backButton}>
                    <Button variant='light' onClick={() => navigate(_pathToDetails)}><FontAwesomeIcon name='chevron-left' /></Button>
                </div>
                <h3 className={s.title}>Nearby Players</h3>
            </div>

            {isLoaded ? <GoogleMap mapContainerStyle={{ height: '50vh' }}
                onLoad={onLoad} onUnmount={onUnmount}>
                {nearbyPlayers && nearbyPlayers.map(p =>
                <PlayerMapMarker tripId={tripId} {...p} key={`map-marker-${tripId}-${p.playerId}`} />
                )}
            </GoogleMap>
            : 
            <div className={s.spinnerContainer}><Spinner size='3x' /></div>}

            <ListGroup className={s.playerList}>
                <ListGroup.Item className={s.miniNearbyPlayerHeader}
                    action onClick={() => handleSelectAll()}>
                    <FontAwesomeIcon className={s.icon} name={playersToHide.length === 0 ? 'check-circle' : 'circle'} />
                    <div className={s.content}></div>
                </ListGroup.Item>
            {nearbyPlayers && nearbyPlayers.map(p =>
                <PlayerListItem showRole={false} 
                    player={p} onClick={() => handlePlayerClick(p.playerId)}
                    icon={p.icon}
                    rightDetail={<div className={s.distanceContainer}>
                        <div className={s.distance}>{showDistance(p)}</div>
                        <div className={s.timeElapsed}>{showTimeElapsed(p.lastUpdate)}</div>
                    </div>}
                    key={`nearby-player-${p.playerId}`} />
            )}
            </ListGroup>
            <GeolocationUpdater show={false} />
        </div>
    )
}

export default NearbyPlayers
