import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import Image from 'react-bootstrap/Image'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Badge from '@severed-links/common.badge'
import Button from '@severed-links/common.button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import PlayerListItem from '../../Player/PlayerListItem'
import * as s from './TripNotePrivateListItem.scss'
import moment from 'moment-timezone'
import { playerAvatarUrl, coolTime } from '@severed-links/common.severedlinks-constants'
import { ITEM_TYPES } from './DragAndDropItemTypes'
import { useDrag, useDrop } from 'react-dnd'

const TripNotePrivateListItem = ({ canSort = false, _id, index, variant = null, note = '', hasReminder = false, reminderDate = null, sortOrder = 1, updatedAt, updatedBy, playerId, onClick = null, onMove = null, timeZoneId, isReminderToday = false, isOverdue = false }) => {

    const _dragRef = useRef(null)
    const _previewRef = useRef(null)

    useEffect(() => {
    }, [])

    const handleClick = () => {
        if (onClick) {
            onClick()
        }
    }

    const [{ handlerId }, drop] = useDrop({
        accept: ITEM_TYPES.PRICING_PLAN_NOTE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!_previewRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = _previewRef.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            if (onMove) {
                onMove(dragIndex, hoverIndex)
            }

            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        type: ITEM_TYPES.PRICING_PLAN_NOTE,
        item: () => ({ _id, index, note, hasReminder, reminderDate, updatedBy, updatedAt }),
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(_dragRef)
    drop(preview(_previewRef))
    
    return (
        <ListGroup.Item ref={_previewRef} className={s.container + (variant ? ` ${s[variant]}` : ``)} action={!!onClick} onClick={handleClick}>
            {canSort ?
            <div ref={_dragRef} className={s.sortHandle} onClick={e => e.stopPropagation()}>
                <FontAwesomeIcon name='sort' />
            </div>
            : null}
            <div className={s.icon}>
                <FontAwesomeIcon name={hasReminder ? (isOverdue ? 'exclamation-triangle' : isReminderToday ? 'info-circle' : 'flag') : 'sticky-note'} size={!hasReminder ? '2x' : null} />
                {hasReminder && reminderDate && moment(reminderDate).isValid() ? <div className={s.reminderDate}>{moment(reminderDate).format(`MMM D${moment().year() !== moment(reminderDate).year() ? ` YYYY` : ``}`)}</div> : null}
            </div>
            <div className={s.avatar}>
                {updatedBy?.imageUrl ? 
                <OverlayTrigger placement='top' overlay={<Tooltip id={`updated-by-author-tooltip-${updatedBy._id}`}>Updated by {updatedBy.firstName} {updatedBy.lastName} {coolTime(updatedAt)}</Tooltip>}>
                    {({ ref, ...triggerHandler }) => (
                    <Image ref={ref} {...triggerHandler} src={playerAvatarUrl(updatedBy.imageUrl)} />
                    )}
                </OverlayTrigger>
                : null}
            </div>
            <div className={s.content}>
                <div className={s.note}>{note}</div>
            </div>
            {isReminderToday ? <Badge variant={variant}>Due today</Badge> : null}
            {isOverdue ? <Badge variant={variant}>Overdue!</Badge> : null}
            {onClick ? 
            <FontAwesomeIcon name='chevron-right' className={s.clickIcon} />
            : null}
        </ListGroup.Item>
    )
}

export default TripNotePrivateListItem
