import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import FormCheck from 'react-bootstrap/FormCheck'
import { saveSetting, getTripSettings, getGroupSettings } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './SettingControl.scss'

const SettingControl = ({ groupId, tripId, title, variable, variableType, value, items = [], onChange, formGroupClassName, formLabelClassName }) => {

    const dispatch = useDispatch()
    const [settingValue, setSettingValue] = useState(false)

    useEffect(() => setSettingValue(value), [value])

    const handleChange = e => {
        const _settingValue = e.target.value
        setSettingValue(_settingValue)
        if (onChange) onChange(_settingValue)
    }

   const handleSwitchChange = e => {
       setSettingValue(e)
       save(e)
       if (onChange) onChange(e)
    }

    const save = _settingValue => {
        dispatch(saveSetting(groupId, tripId, variable, _settingValue))
        .then(action => {
            if (!tripId && groupId) {
                dispatch(getGroupSettings(groupId))
            } else {
                dispatch(getTripSettings(groupId, tripId))
            }
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Save Setting', timeout: 3000 }))
        })
    }

    if (settingValue === null) return null
    return (
        <div className={s.container}>
            {variableType === 'bool' ? 
            <FormGroup className={formGroupClassName}>
                <FormLabel style={{ display: 'block' }} className={formLabelClassName}>{title}</FormLabel>
                <FormCheck type='switch' checked={settingValue} onChange={e => handleSwitchChange(e.target.checked)} />
            </FormGroup>
            : null}                
        </div>
    )
}

export default SettingControl