import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { Row, Col, Badge, ProgressBar, ListGroup,
    OverlayTrigger, Popover, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../../Player/PlayerListItem'
import { getMvpAwardResults, markMvpAwardWinner } from '@severed-links/common.severedlinks-reducers/groups'
import { orderBy } from 'lodash'
import * as s from './TripMvpAwardVoting.scss'
import Confirm from '@severed-links/common.confirm'
import Modal from '@severed-links/common.modal'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import numeral from 'numeral'
import MvpAwardWinner from './MvpAwardWinner'

const PopoverVotingButton = ({ voted = 'Yes', list = [] }) => (
    <OverlayTrigger trigger={['click']} placement='left' 
        overlay={<Popover className={s.popover}>
            <Popover.Header>{voted === 'Yes' ? 'Players who voted' : 'Players who did not vote'}</Popover.Header>
            <Popover.Body className={s.popoverListGroupContainer}>
            <ListGroup className={s.popoverListGroup} variant='flush'>
            {list && list.map(p =>
                <PlayerListItem player={p} showRole={false} 
                    clickDisabled key={`popover-item-${voted}-${p._id}`} />
            )}
            {!list || list.length === 0 ? <ListGroup.Item>None.</ListGroup.Item> : null}
            </ListGroup>
            </Popover.Body>
        </Popover>}>
        <Button className={s.votingLabel} variant={voted === 'Yes' ? 'success' : 'danger'}>
            <span>{voted} {list.length}</span>
        </Button>
    </OverlayTrigger>
)

const TripMvpAwardVoting = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { group, trip, groupId, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [winningPlayer, setWinningPlayer] = useState(null)
    const [showWinnerConfirm, setShowWinnerConfirm] = useState(false)
    const playersWhoVoted = (trip.mvpAwardVoting || {}).playersWhoVoted || []
    const results = (trip.mvpAwardVoting || {}).results || []
    const _yesRef = useRef(null)
    const _noRef = useRef(null)

    useEffect(() => {
        tick()
        let timerId = setInterval(() => tick(), 30 * 1000)
        return () => clearInterval(timerId)
    }, [])

    const tick = () => dispatch(getMvpAwardResults(groupId, tripId))

    const markAsWinner = () => {
        setShowWinnerConfirm(false)
        dispatch(markMvpAwardWinner(groupId, tripId, winningPlayer.playerId))
        .then(action => {
            dispatch(createNotification(action.payload))
        })
    }

    const handleClickVotedPlayer = _player => {
        setWinningPlayer(_player)
        setShowWinnerConfirm(true)
    }

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <div className={s.heading}>
                        <h4 className={s.title}>Trip {group.mvpAwardName || 'MVP Award'} Voting</h4>
                        <div className={s.controls}>
                            <Button variant='info' onClick={() => navigate(`/group/${groupId}/trip/${tripId}/admin/mvp-award-kiosk`)}><FontAwesomeIcon name='person-booth' /> voting kiosk</Button>
                            <PopoverVotingButton voted='Yes' list={playersWhoVoted.filter(i => i.voted === 'Yes')} />
                            <PopoverVotingButton voted='No' list={playersWhoVoted.filter(i => i.voted === 'No')} />
                        </div>
                    </div>
                    <p>Tap on a player to mark that player as the winner for this trip.</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <MvpAwardWinner group={group} trip={trip} />
                    <ListGroup>
                    {results && results.map(p =>
                    <PlayerListItem player={p} showRole={false} 
                        rightDetail={<div className={s.voteSummary}>{p.votes} ({numeral(p.percentage).format('0.0')}%)</div>}
                        onClick={() => handleClickVotedPlayer(p)}
                        key={`mvp-award-voting-${p._id}`}>
                        <ProgressBar now={p.percentage} className={s.progressBar} />
                    </PlayerListItem>
                    )}
                    {!results || results.length === 0 ? <ListGroup.Item>No votes located for this trip.</ListGroup.Item> : null}
                    </ListGroup>

                    <Modal show={showWinnerConfirm} onClose={() => setShowWinnerConfirm(false)}
                        showFooter heading={`Mark ${group.mvpAwardName} Winner`}
                        actionButtonIcon='trophy'
                        actionButtonText='mark winner'
                        actionButtonStyle='primary'
                        actionButtonOnClick={markAsWinner}>
                    <div>
                        <p>Mark {winningPlayer?.playerName} as the {group.mvpAwardName} winner for {trip.tripName}?</p>
                    </div>
                    </Modal>
                </Col>
            </Row>
        </div>
    )
}

export default TripMvpAwardVoting