import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Image, Badge, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminPushStatus.scss'
import { getPushData, refreshPushReceipts } from '@severed-links/common.severedlinks-reducers/globalAdmin'
import moment from 'moment-timezone'
import Modal from '@severed-links/common.modal'
import { prettyPrintJson } from 'pretty-print-json'
import { commonImageUrl, playerAvatarUrl } from '@severed-links/common.severedlinks-constants'

const PushInfoItem = ({ heading, value, variant = null }) => (
    <div className={s.info + (variant ? ` ${s[`${variant}`]}` : '')}>
        <div className={s.heading}>{heading}</div>
        <div className={s.value}>{value}</div>
    </div>
)

const GlobalAdminPushStatus = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const timeZoneId = useSelector(state => state.account.time_zone)
    const pushData = useSelector(state => state.globalAdmin.pushData)
    const [showDetailIndex, setShowDetailIndex] = useState(-1)
    const [currentTime, setCurrentTime] = useState(moment().toISOString())

    useEffect(() => {
        let _timerId = setInterval(() => setCurrentTime(moment().toISOString()), 30 * 1000)
        return () => clearInterval(_timerId)
    }, [])

    useEffect(() => {
        dispatch(getPushData())
    }, [currentTime])

    const doRefresh = () => {
        dispatch(refreshPushReceipts())
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h3 className={s.title}><FontAwesomeIcon name='mobile' /> Push Data</h3>
                <div className={s.controls}>
                    <Button onClick={() => doRefresh()} variant='light'><FontAwesomeIcon name='sync-alt' /></Button>
                </div>
            </div>
            <ListGroup className={s.list}>
            {pushData && pushData.map((i, _index) => 
            <ListGroup.Item className={s.item}
                action onClick={() => setShowDetailIndex(_index)}
                key={`push-data-item-${i.batchId}`}>
                <div className={s.icon + (i.isSuccess ? ` ${s.success}` : i.isError ? ` ${s.danger}` : i.isPending ? ` ${s.warning}` : ` ${s.light}`)}>
                    <FontAwesomeIcon name={i.isSuccess ? 'check-circle' : i.isError ? 'exclamation-triangle' : i.isPending ? 'clock' : 'question-circle'} />
                </div>
                <div className={s.avatar}>
                    <Image src={i.sentBy && i.sentByUser && i.sentByUser.imageUrl ? playerAvatarUrl(i.sentByUser.imageUrl, true) : commonImageUrl('apple-touch-icon')} />
                </div>
                <div className={s.content}>
                    <div className={s.title}>Sent {moment.tz(i.sendTime, timeZoneId).format('MMMM D YYYY h:mm A')}</div>
                    <div className={s.subtitle}><Badge bg={'light'} text={'dark'}>{i.title}</Badge><div>{i.body}</div></div>
                </div>
                <PushInfoItem heading={'Pushes'} value={i.batchCount} variant={null} />
                {i.successCount ? <PushInfoItem heading={'Success'} value={i.successCount} variant={'success'} /> : null}
                {i.pendingCount ? <PushInfoItem heading={'Pending'} value={i.pendingCount} variant={'warning'} /> : null}
                {i.errorCount ? <PushInfoItem heading={'Error'} value={i.errorCount} variant={'danger'} /> : null}
            </ListGroup.Item>
            )}
            </ListGroup>
            <Modal show={showDetailIndex > -1} showFooter={false}
                dialogClassName={s.pushDetailsModalContainer}
                heading={'Push Details'} onClose={() => setShowDetailIndex(-1)}>
                <ListGroup className={s.pushDetailsContainer}>
                {showDetailIndex > -1 && pushData[showDetailIndex].items && pushData[showDetailIndex].items.map(i =>
                <ListGroup.Item key={`push-data-detail-${i._id}`}>
                    <pre className='json-container' dangerouslySetInnerHTML={{ __html: prettyPrintJson.toHtml(i, { linkUrls: true, linksNewTab: true }) }} />
                </ListGroup.Item>
                )}
                </ListGroup>
            </Modal>
        </div>
    )
}

export default GlobalAdminPushStatus
