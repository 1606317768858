import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Scorecard.scss'
import { commonImageUrl, playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import moment from 'moment-timezone'

const ScorecardMiniMatchSummary = ({ match = {}, trip = {} }) => {

    const status = (match.status || '')
        .replace(/through \d+ hole(s)?/g, '')
        .replace(/(EUR|USA)\,\s+/g, '')
    const _hasWinner = ['USA','EUR'].includes(match.winningTeam)
    const winningPlayers = match.winningTeam === 'EUR' ? match.eurPlayerList : match.winningTeam === 'USA' ? match.usaPlayerList : [...match.usaPlayerList, ...match.eurPlayerList]
    return (
        <div className={s.miniMatchSummary} style={_hasWinner ? { color: trip[`${match.winningTeam.toLowerCase()}TextColor`], backgroundColor: trip[`${match.winningTeam.toLowerCase()}BackgroundColor`], borderColor: trip[`${match.winningTeam.toLowerCase()}BorderColor`] } : null}>

            {_hasWinner ? 
            <Image className={s.flag} src={trip[`${match.winningTeam.toLowerCase()}FullTeamImageUrl`]} />
            : null}
            <div className={s.playerAvatarContainer}>
            {winningPlayers && winningPlayers.map(p =>
                <Image src={playerAvatarUrl(p.imageUrl, true)} 
                    key={`mini-match-summary-${p._id}`} />
            )}
            </div>
            {match.isNotStarted ? <div className={s.date}>{moment(match.teeTime).local().format('MMM D')}</div> : null}
            <div className={s.status}>{status}</div>
        </div>
    )
}

export default ScorecardMiniMatchSummary