import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import * as s from './TripRoundTeeTimeAdjuster.scss'
import { updateTeeTimeAdjusterForRound, saveTeeTimesAndFoursomes, getMatchesScoreboard } from '@severed-links/common.severedlinks-reducers/groups'
import { playerAvatarUrl, DEFAULT_TIME_ZONE } from '@severed-links/common.severedlinks-constants'
import moment from 'moment-timezone'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import { flatten } from 'lodash'

const TripRoundTeeTimeAdjuster = ({ teeTimeAdjuster = {} }) => {

    const dispatch = useDispatch()

    const save = () => {
        const { groupId, tripId, round } = teeTimeAdjuster || {}
        const foursomes = teeTimeAdjuster.foursomes || []
        var _postData = { 
            groupId, tripId, round, 
            matches: flatten(foursomes.map(i => i.matches.map(x => ({ 
                match: x.match, 
                foursome: i.foursome,
                startingHole: i.startingHole,
                teeTime: moment(i.newTeeTime).toISOString(true),
            })))), 
        }
        dispatch(updateTeeTimeAdjusterForRound({ ...teeTimeAdjuster, show: false }))
        dispatch(saveTeeTimesAndFoursomes(_postData))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Save Tee Times and Foursomes', timeout: 3000 }))
            if (action.payload.messageType === 'success') {
                dispatch(getMatchesScoreboard(groupId, tripId))
            }
        })

    }

    const show = teeTimeAdjuster.show || false
    const foursomes = teeTimeAdjuster.foursomes || []
    return (
        <Modal show={show} showFooter={true}
            dialogClassName={s.dialogClass}
            heading={`Adjust Tee Times from Round Change`}
            actionButtonOnClick={() => save()} 
            actionButtonIcon='clock' actionButtonText='save updated tee times'
            onClose={() => dispatch(updateTeeTimeAdjusterForRound({ ...teeTimeAdjuster, show: false }))}>
        <div className={s.container}>
            <ListGroup className={s.list}>
            {foursomes && foursomes.map(i =>
            <ListGroup.Item className={s.item} key={`foursome-${i.foursome}`}>
                <div className={s.foursomeContainer}>
                    <div className={s.heading}>Foursome</div>
                    <div className={s.foursome}>{i.foursome}</div>
                </div>
                <div className={s.content}>
                {i.players && i.players.map(p =>
                <div className={s.playerContainer} key={`foursome-${i.foursome}-player-${p._id}`}>
                    <div className={s.avatar}>
                        <img src={playerAvatarUrl(p.imageUrl, true)} />
                    </div>
                    <div className={s.playerName + ' ' + s[`${(p.team || '').toLowerCase()}`]}>{p.firstName} {p.lastName}</div>
                </div>
                )}
                </div>
                <div className={s.teeTimeContainer + ' ' + s.old}>
                    <div className={s.heading}>From</div>
                    {i.isDayChanged ?
                    <div className={s.teeTime}>{moment(i.oldTeeTime).format('MMM D')}</div>
                    : null}
                    <div className={s.teeTime}>{i.oldTeeTimeDisplay}</div>
                </div>
                <FontAwesomeIcon name='arrow-right' />
                <div className={s.teeTimeContainer + ' ' + s.new}>
                    <div className={s.heading}>To</div>
                    {i.isDayChanged ?
                    <div className={s.teeTime}>{moment(i.newTeeTime).format('MMM D')}</div>
                    : null}
                    <div className={s.teeTime}>{i.newTeeTimeDisplay}</div>
                </div>
            </ListGroup.Item>
            )}
            </ListGroup>
        </div>
        </Modal>
    )
}

export default TripRoundTeeTimeAdjuster