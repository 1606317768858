import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import { updateGroupState, getReactivationRequests, setPlayerInactive, reactivatePlayer } from '@severed-links/common.severedlinks-reducers/groups'
import PlayerListItem from '../../Player/PlayerListItem'
import moment from 'moment-timezone'
import { FRIENDLY_DATE_FORMAT } from '@severed-links/common.severedlinks-constants'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './ReactivationRequests.scss'

const ReactivationRequests = ({ group = {} }) => {

    const dispatch = useDispatch()
    const groupId = group._id || null

    useEffect(() => {
        if (groupId) dispatch(getReactivationRequests(groupId))
    }, [groupId])


    const doSetInactive = playerId => {
        dispatch(setPlayerInactive(groupId, playerId))
        .then(() => controlActionCallback())
    }

    const doReactivate = playerId => {
        dispatch(reactivatePlayer(groupId, playerId))
        .then(() => controlActionCallback())
    }

    const controlActionCallback = () => {
        dispatch(getReactivationRequests(groupId))
        .then(action => {
            if (!action.payload.reactivationRequests.length) {
                onCloseModal()
            }
        })
    }

    const onCloseModal = () => {
        dispatch(updateGroupState(groupId, { showReactivationRequests: false }))
    }

    return (
        <Modal show={group.showReactivationRequests} showFooter={false}
            heading={`Reactivation Requests`}
            onClose={() => onCloseModal()}>
            <div className={s.container}>
                <ListGroup className={s.list}>
                {group.reactivationRequests && group.reactivationRequests.map(i =>
                    <PlayerListItem key={`reactivation-request-${group._id}-${i.playerId}`} player={{ ...i, playerName: `${i.firstName} ${i.lastName}` }}
                        clickDisabled
                        rightDetail={<div className={s.controls}>
                            <Confirm title={'Set Player to Inactive'} 
                                onConfirm={() => doSetInactive(i.playerId)}
                                confirmText='set player inactive'
                                body={<div><p>Are you sure you want to set this player as inactive for this group?</p></div>}
                                variant='danger' buttonClassName={s.controlButton} size='sm' buttonIcon='thumbs-down' buttonText='decline' />
                            <Confirm title={'Reactivate Player'} 
                                onConfirm={() => doReactivate(i.playerId)}
                                confirmText='set player active'
                                cancelText='close'
                                body={<div><p>Are you sure you want to reactivate this player as inactive for this group?</p></div>}
                                variant='success' buttonClassName={s.controlButton} size='sm' buttonIcon='thumbs-up' buttonText='accept' />
                        </div>} />
                )}    
                </ListGroup>
            </div>
        </Modal>
    )
}

export default ReactivationRequests
