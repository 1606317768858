import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Image, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { take, shuffle, range, first } from 'lodash'
import * as s from './TripEventResponseCounts.scss'
import { recordEventResponse } from '@severed-links/common.severedlinks-reducers/groups'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import { isMobile } from 'react-device-detect'
import pluralize from 'pluralize'
import Modal from '@severed-links/common.modal'
import PlayerListItem from '../Player/PlayerListItem'

const TripEventTaskResponseButton = ({ title, variant, code, icon, responses, onClick, isSaving = false, isPicked = false }) => (
    <Button className={s.item + ' ' + s[variant || 'primary'] + (isPicked ? ` ${s.isPicked}` : ``)} 
        size='sm' title={title}
        onClick={() => onClick(code)}>
        <FontAwesomeIcon name={icon} />
        {!isSaving ?
        <>
        <div className={s.labelTitle}>{title}</div>
        <div className={s.labelCount}>{responses.length}</div>
        </>
        : <div className={s.spinner}><FontAwesomeIcon name='circle-notch' spin /></div>}
    </Button>
)

const TripEventResponsePlayerList = ({ rsvpCounts = [], onSelect, selectedItem = null }) => {
    const _selectedItem = rsvpCounts.find(i => selectedItem && i.title === selectedItem)
    return (
        <div>
            <div className={s.playerListItemSelectorContainer}>
            {rsvpCounts && rsvpCounts.filter(i => i.responses && i.responses.length).map(i =>
                <div className={s.playerListItemSelectorItem} key={`player-list-item-selector-item-${i.title}`}>
                    <Button size='lg' className={s.itemSelectorButton}
                        variant={`${selectedItem !== i.title ? 'outline-' : ''}${i.variant}`}
                        onClick={() => onSelect(i.title)}>
                        <FontAwesomeIcon name={i.icon} />
                        <div className={s.count}>{i.count}</div>
                    </Button>
                </div>
            )}
            </div>
            {_selectedItem ?
            <ListGroup className={s.list}>
            {_selectedItem.responses && _selectedItem.responses.map(p => 
            <PlayerListItem player={p} clickDisabled key={`player-list-${p.playerId}`} />
            )}
            </ListGroup>
            : null}
        </div>
    )
}

const TripEventResponseCounts = ({ group = {}, trip = {}, event = {}, rsvpCounts = [], responses = [], allowPress = false }) => {

    if (!group._id || !trip._id) return null

    const dispatch = useDispatch()
    const [showCountModal, setShowCountModal] = useState(false)
    const [selectedResponseType, setSelectedResponseType] = useState(null)
    const [savingResponseCode, setSavingResponseCode] = useState(null)
    const [showRsvpNotAllowed, setShowRsvpNotAllowed] = useState(false)
    
    const sendResponse = eventResponse => {
        setSavingResponseCode(eventResponse)
        dispatch(recordEventResponse(group._id, trip._id, event._id, eventResponse))
        .then(() => setSavingResponseCode(null))
    }

    useEffect(() => {
        if (!selectedResponseType && responses.length) {
            setSelectedResponseType(first(rsvpCounts.filter(i => i.responses.length)).title || null)
        }
    }, [selectedResponseType, responses.length])

    return (
        <div className={s.container} onClick={e => e.stopPropagation()}>
            {rsvpCounts && rsvpCounts.map(i => 
            <div className={s.itemContainer} key={`response-count-${trip._id}-event-${event._id}-${i.code}`}>
                <div onClick={e => e.stopPropagation()}>
                    <TripEventTaskResponseButton {...i} 
                        onClick={allowPress ? sendResponse : () => setShowRsvpNotAllowed(true)}
                        isSaving={savingResponseCode === i.code}
                        key={`trip-${trip._id}-task-response-${i.code}`} />
                </div>
            </div>
            )}

            {responses?.length ? 
            <>
            <div className={s.itemContainer} onClick={e => e.stopPropagation()}>
                <Button className={s.item + ' ' + s.countButton} 
                    onClick={() => setShowCountModal(true)}
                    size='sm' variant='light'>
                    <FontAwesomeIcon name='list' />
                </Button>
            </div>
            <Modal show={showCountModal} onClose={() => setShowCountModal(false)}
                heading={`Responses`} showFooter={false}>
            <div className={s.countModalContainer}>
                <TripEventResponsePlayerList rsvpCounts={rsvpCounts} selectedItem={selectedResponseType} onSelect={_type => setSelectedResponseType(_type)} />
            </div>
            </Modal>
            </>
            : null}
            <Modal show={showRsvpNotAllowed} heading='RSVP Not Permitted' onClose={() => setShowRsvpNotAllowed()}>
                <div>You are not allowed to RSVP for this event because you are not registered for this trip.</div>
            </Modal>
        </div>
    )
}

export default TripEventResponseCounts
