import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, FormGroup, FormLabel, FormControl, InputGroup, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { InputMask } from '@react-input/mask'
import { trim } from 'lodash'
import * as s from './ForgotPassword.scss'
import { resetForgotPassword, setUserInfo,
    checkEmail, checkCellPhone, requestResetCode, verifyResetCode,
    requestPasswordChange } from '@severed-links/common.severedlinks-reducers/forgotPassword'

const ForgotPasswordStep2 = forwardRef(({ jumpToStep, ...props }, _ref) => {

    const dispatch = useDispatch()
    const fp = useSelector(state => state.forgotPassword)
    const [email, setEmail] = useState('')
    const [cellPhone, setCellPhone] = useState('')

    useEffect(() => {
        setEmail(fp.email || '')
        setCellPhone(fp.cellPhone || '')
    }, [])

    useEffect(() => setEmail(fp.email), [fp.email])
    useEffect(() => setCellPhone(fp.cellPhone), [fp.cellPhone])

    const handleEmailChange = e => {
        const _value = trim(e.target.value)
        setEmail(_value)
        dispatch(checkEmail(_value))
    }

    const handleCellPhoneChange = e => {
        const _value = trim(e.target.value)
        setCellPhone(_value)
        dispatch(checkCellPhone(_value))
    }

    const _isValid = (fp.sendBy === 'Email' && fp.emailIsValid) ||
        (fp.sendBy === 'Text' && fp.cellPhoneIsValid)

    useImperativeHandle(_ref, () => ({
        isValidated() { return _isValid }
    }))
    
    return (
        <div>
            <Row>
                <Col xs={12}>
                    {fp.sendBy == 'Email' ? 
                    <FormGroup>
                        <FormLabel>Email address (you must have updated your Severed Links profile with this email)</FormLabel>
                        <InputGroup size='lg'>
                            <InputGroup.Text><FontAwesomeIcon name='envelope' /></InputGroup.Text>                                
                            <FormControl autoComplete='off' type='text' 
                                autoFocus={fp.sendBy == 'Email'}
                                name='email' value={email}
                                required isValid={fp.emailIsValid}
                                isInvalid={!fp.emailIsValid}
                                onChange={e => handleEmailChange(e)} />
                            <FormControl.Feedback />
                        </InputGroup>
                        <div className={s.alertContainer}>
                            {_isValid ?
                            <Alert variant='success'>Press 'Next' to send your code to your selected location.</Alert>
                            : null}
                        </div>
                    </FormGroup>
                    : null}
                    {fp.sendBy == 'Text' ? 
                    <FormGroup>
                        <FormLabel>Cell phone (you must have updated your Severed Links profile with this cell phone number)</FormLabel>
                        <InputGroup size='lg'>
                            <InputGroup.Text><FontAwesomeIcon name='mobile' /> +1</InputGroup.Text>
                            <InputMask mask='(___) ___-____' 
                                replacement={{ _: /\d/ }}
                                className='form-control'
                                name='cellPhone' value={cellPhone || ''} 
                                autoFocus={fp.sendBy == 'Text'}
                                required isValid={fp.cellPhoneIsValid}
                                isInvalid={!fp.cellPhoneIsValid}
                                onChange={e => handleCellPhoneChange(e)} />
                            <FormControl.Feedback />
                        </InputGroup>
                        <div className={s.alertContainer}>
                            {_isValid ?
                            <Alert variant='success'>Press 'Next' to send your code to your selected location.</Alert>
                            : null}
                        </div>
                    </FormGroup>
                    : null}
                </Col>
            </Row>
        </div>
    )
})

export default ForgotPasswordStep2
