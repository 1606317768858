import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FormControl from 'react-bootstrap/FormControl'
import Modal from '@severed-links/common.modal'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../../Player/PlayerListItem'
import numeral from 'numeral'
import pluralize from 'pluralize'
import * as s from './TripPlayerAdminPlayerHandicapCalculator.scss'
import { getHandicapCheckerForPlayer } from '@severed-links/common.severedlinks-reducers/groups'

const TripPlayerAdminPlayerHandicapCalculator = ({ group, trip, player }) => {

    const dispatch = useDispatch()
    const { height } = useSelector(state => state.layout)
    const [show, setShow] = useState(false)
    const [h, setHandicapChecker] = useState({})
    const [currentIndex, setCurrentIndex] = useState(`0.0`)

    useEffect(() => {
        if (player?.handicapChecker) {
            setHandicapChecker(player?.handicapChecker)
        }
    }, [player?.handicapChecker?.updatedAt])

    useEffect(() => {
        if (h.currentIndex && !isNaN(h.currentIndex)) {
            setCurrentIndex(parseFloat(h.currentIndex).toFixed(1))
        }
    }, [h.currentIndex])

    const _handleCurrentIndexChange = e => {
        const regex = /^\d*\.?\d?$/
        var strIndex = e.target.value || ''

        if (!regex.test(strIndex)) {
            strIndex = strIndex.slice(0, -1)
        }
        setCurrentIndex(strIndex)
    }

    const _handleCurrentIndexBlur = e => {
        if (group?._id && trip?._id && player?.playerId && currentIndex && !isNaN(currentIndex)) {
            dispatch(getHandicapCheckerForPlayer(group._id, trip._id, player.playerId, parseFloat(currentIndex)))
            .then(action => {
                setHandicapChecker(action.payload)
            })
        }
    }

    if (!player) return null
    const _items = [
        { title: `Player index`, subtitle: ``, value: `${numeral(h.currentIndex).format('0.0')}`, showFormControl: true },
        { title: `Calculated trip handicap`, subtitle: !h.hasTeeBoxOverrides ? `Handicap on selected tees: ${h.calculatedTripHcpOnSelectedCourses}`: '', value: `${h.calculatedTripHcp}` },
        { title: `Assigned trip handicap`, subtitle: ``, value: `${h.tripHcp}`, variant: h.calculatedTripHcp === h.tripHcp ? 'success' : Math.abs(h.calculatedTripHcp - h.tripHcp) === 1 ? 'warning' : 'danger' },
    ]
    return (
        <>
        <ListGroup.Item className={s.container} action onClick={() => setShow(true)}>
            <div className={s.content}>
                <div className={s.title}>Handicap calculation</div>
                <div className={s.subtitle}>Estimated trip handicap: {player.handicapChecker?.calculatedTripHcp || 0}</div>
            </div>
            <div className={s.clickIcon}><FontAwesomeIcon name='chevron-right' /></div>
        </ListGroup.Item>

        <Modal show={show} heading='Handicap calculation'
            showFooter={false} onClose={() => setShow(false)}>
        <div className={s.modalContainer}>
            <PlayerListItem player={player} showHcp={false} showRole={false} clickDisabled />
            <ListGroup className={s.list}>
                {_items && _items.map(i => 
                <ListGroup.Item className={s.item + (i.variant ? ` ${s[i.variant]}` : '')} key={`handicap-calculator-${i.title}`}>
                    <div className={s.content}>
                        <div className={s.title}>{i.title}</div>
                        {i.subtitle ? <div className={s.subtitle}>{i.subtitle}</div> : null}
                    </div>
                    {i.showFormControl ? <FormControl value={currentIndex} style={{ fontSize: 24, textAlign: 'right', maxWidth: '8rem' }} type='number' onChange={e => _handleCurrentIndexChange(e)} onBlur={_handleCurrentIndexBlur} />
                    :
                    i.value ? 
                    <div style={{ fontSize: 24 }} variant={i.variant || 'info'}>{i.value}</div>
                    :
                    null}
                </ListGroup.Item>
                )}
                {h.courseHcps && h.courseHcps.map(i => [
                <ListGroup.Item className={s.item + ' ' + s.miniHeader} key={`hcp-checker-course-${i._id}`}>
                    <div className={s.content}>
                        <div className={s.title}>{i.courseName}</div>
                    </div>
                </ListGroup.Item>,
                i.tees && i.tees.map(x =>
                    <ListGroup.Item className={s.item} style={{ backgroundColor: x.backgroundColor || 'white' }}
                        key={`hcp-checker-course-${i._id}-tee-${x._id}`}>
                        <div className={s.content}>
                            <div className={s.title} style={{ color: x.fontColor || 'black' }}>{x.tees} Tees</div>
                            <div className={s.subtitle} style={{ fontSize: 14, color: x.fontColor || 'black' }}>
                                {[x.par ? `Par ${x.par}` : ``, x.yards ? `${x.yards} yards` : ``, x.slope ? `Slope ${x.slope}` : ``, x.rating ? `Rating ${numeral(x.rating).format('0.0')}` : ``].filter(x => x).join(', ')}
                            </div>
                        </div>
                        <div className={s.value} style={{ color: x.fontColor || 'black' }}>{x.hcp}</div>
                    </ListGroup.Item>
                )])}

            </ListGroup>
        </div>
        </Modal>
    {/* 
        <SLModal >
        <View style={{ ...s.container, height: height * 0.7 }}>





        <ListGroup.Item className={s.item}>
            <div className={s.content}>
                <div className={s.title}></div>
                <div className={s.subtitle}></div>
            </div>
        </ListGroup.Item>


        */}
        </> 
    )
}

export default TripPlayerAdminPlayerHandicapCalculator
