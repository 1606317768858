import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventAuthorIndicator.scss'

const TripEventAuthorIndicator = ({ enteredBy }) => {

    if (!enteredBy || !enteredBy._id) return null
    return (
        <Badge className={s.container}>
            <FontAwesomeIcon name='pencil-alt' /> 
            {' '}
            {' '}
            Entered by {enteredBy.firstName}
        </Badge> 
    )
}

export default TripEventAuthorIndicator
