import React, { useEffect } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../Player/PlayerListItem'
import Badge from '@severed-links/common.badge'
import * as s from './TripRoundLowPlayerScores.scss'
import ParScoreDisplay from './ParScoreDisplay'
import pluralize from 'pluralize'

const TripRoundLowTeamScores = ({ trip = {}, round = 0 }) => {

    const list = ((trip.scoreboard || {}).lowTeams || []).filter(r => r.round === round)
    const _round = (trip.rounds || []).find(r => r.round === round) || {}
    if (!list.length) return null
    return (
        <Card className={s.container}>
            <Card.Header>{_round.roundName || ''} Low {pluralize('Team', list.length, false)}</Card.Header>
            <ListGroup className={s.list} variant='flush'>
            {list && list.map((i, _listIndex) => [
                i.isRoundMedalist ? <ListGroup.Item className={s.item + ' ' + s.roundMedalistItem} key={`trip-low-scores-trip-${trip._id}-round-${round}-${i.playerId}${i.isBestBall ? `-best-ball` : ``}-round-medalist-container`}><FontAwesomeIcon name='trophy' className={s.icon} /><div className={s.heading}>Round {round} Medalists</div></ListGroup.Item> : null,
                <div className={s.teamContainer + (i.isRoundMedalist ? ` ${s.isRoundMedalist}` : ``) + (_listIndex ? ` ${s.notFirstList}` : ``)} key={`trip-low-scores-trip-${trip._id}-round-${round}-${i.playerId}${i.isBestBall ? `-best-ball` : ``}`}>
                    <div className={s.playersContainer}>
                    {i.players && i.players.map((p, _playerIndex) =>
                        <PlayerListItem player={p} showFlag className={s.item + (_playerIndex ? ` ${s.notFirstPlayer}` : ``)}
                            flagUrl={trip[`${(p.team || '').toLowerCase()}FullTeamImageUrl`]} 
                            showRole={false}
                            key={`trip-low-scores-trip-${trip._id}-round-${round}-${i.playerId}-${p.playerId}${i.isBestBall ? `-best-ball` : ``}`} />
                    )}
                    </div>
                    <div className={s.rightDetail}>
                        {i.isLowGross ?
                        <div className={s.infoContainer}>
                            <div className={s.heading}>{i.isBestBall ? 'Best Ball' : 'Total'}</div>
                            <div className={s.heading}>Gross ({i.grossScore})</div>
                            <div className={s.value}><ParScoreDisplay score={i.grossDiff} /></div>
                        </div>
                        : null}
                        {i.isLowNet ?
                        <div className={s.infoContainer}>
                            <div className={s.heading}>{i.isBestBall ? 'Best Ball' : 'Total'}</div>
                            <div className={s.heading}>Net ({i.netScore})</div>
                            <div className={s.value}><ParScoreDisplay score={i.netDiff} /></div>
                        </div>
                        : null}
                    </div>
                </div>
            ])}
            {!list || list.length === 0 ? <ListGroup.Item>No players located.</ListGroup.Item> : null}
            </ListGroup>
        </Card>
    )
}

export default TripRoundLowTeamScores