import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ListGroup, Card } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import * as s from './GroupPlayerRounds.scss'

const GroupPlayerRounds = ({ rounds = [], isLoading = false }) => {

    const navigate = useNavigate()
    const params = useParams()
    const { groupId, playerId } = params
    return (
        <div className={s.container}>
            <Card>
                <Card.Header>Own-Ball Round History (highlighted scores used in index calculation)</Card.Header>
                <ListGroup className={s.list} variant='flush'>
                {rounds && rounds.map((i, index) =>
                    <ListGroup.Item className={s.item} variant={i.scoreUsedInIndex ? 'info' : 'light'}
                        action onClick={() => navigate(`/group/${groupId}/trip/${i.tripID}/player/${playerId}/round-${i.round}/scorecard`)}
                        key={`group-player-round-${i._id}`}>
                        {index === 0 || i.tripID !== rounds[index - 1].tripID ? <h4 className={s.tripTitle}><FontAwesomeIcon name='suitcase' /> {i.tripName}</h4> : null}
                        <div className={s.courseDetails}>
                            <p>{moment(i.roundDate).format('MMM D, YYYY')} {i.courseName} {i.tees ? `(${i.tees} tees)` : ''}</p>
                            <p>Par {i.par}, {i.yards} yards (slope {i.slope}, rating {i.rating})</p>
                        </div>
                        <div className={s.grossScore} title={`handicap: ${i.handicap}, differential: ${i.differential}, calculated index: ${i.currentIndex}`}>{i.grossScore}</div>
                    </ListGroup.Item>
                )}
                {!isLoading && !rounds || rounds.length === 0 ? <ListGroup.Item>No rounds located for this group.</ListGroup.Item> : null}
                </ListGroup>
            </Card>
        </div>
    )
}

export default GroupPlayerRounds