import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { ListGroup, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { orderBy } from 'lodash'
import PlayerListItem from '../../../Player/PlayerListItem'
import * as s from './PlayerPayments.scss'
import numeral from 'numeral'
import { getPlayerEntryFeePayments, updatePlayerEntryFee, saveSkinParticipation } from '@severed-links/common.severedlinks-reducers/groups'

const PlayerPayments = () => {

    const dispatch = useDispatch()
    const { groupId, group, tripId, trip, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const tripPlayers = [
        ...(trip.usaPlayers || []), 
        ...(trip.eurPlayers || []), 
        ...(trip.unassignedPlayers || [])
    ]
    const playerEntryFeePayments = trip.playerEntryFeePayments || []
    const _playerList = orderBy(tripPlayers.map(i => ({ ...i, isPaid: (playerEntryFeePayments.find(p => p.playerId === i.playerID) || {}).isPaid || false })), ['lastName', 'firstName'], ['asc','asc'])
    const paymentSetup = trip.paymentSetup || {}
    const entryFeePerPlayer = paymentSetup.entryFeePerPlayer || 0.00
    const _totalPaid = _playerList.filter(i => i.isPaid).length
    const _totalUnpaid = _playerList.filter(i => !i.isPaid).length

    const updatePaidStatus = (playerId, isPaid) => {
        const _skinParticipation = (trip.rounds || []).map(i => i.round)
            .map(round => ({ tripId: trip._id, playerId, inRound: isPaid, round }))
        dispatch(updatePlayerEntryFee(group._id, trip._id, playerId, isPaid))
        dispatch(saveSkinParticipation(group._id, trip._id, _skinParticipation))
    }

    
    return (
        <div className={s.container}>
            <h4>Entry Fee Payments</h4>
            <ListGroup className={s.list}>
                <ListGroup.Item className={s.item} variant='info'>
                    <div className={s.content}>
                        <div className={s.title + ' ' + s.bold}>Entry fee per player</div>
                        <div className={s.right + ' ' + s.bold}>{numeral(entryFeePerPlayer).format('($0.00)')}</div>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className={s.item} style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                    <div className={s.content}>
                        <div className={s.title}>Tap a player to mark paid/unpaid.</div>
                    </div>
                    {_totalPaid && _totalUnpaid ?
                    <div className={s.paidTotals}>
                    <div className={s.paidItem + ' ' + s.isPaid}>{_totalPaid} paid</div>
                    <div className={s.paidItem + ' ' + s.isUnpaid}>{_totalUnpaid} unpaid</div>
                    </div>
                    : null}
                </ListGroup.Item>
            {_playerList && _playerList.map(p =>
                <PlayerListItem player={p} showRole={false} 
                    rightDetail={<Badge bg={p.isPaid ? 'success' : 'danger'} className={s.paidBadge}>{p.isPaid ? 'Paid' : 'Unpaid'}</Badge>}
                    onClick={() => updatePaidStatus(p.playerID, !p.isPaid)}
                    key={`payin-${p._id}`} showFlag={false} showHcp={false} />
            )}
            </ListGroup>
        </div>
    )
}

export default PlayerPayments