import React, { useEffect } from 'react'
import { } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { Card, ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripMatch.scss'
import TripMatchPanel from './TripMatchPanel'
import { orderBy, uniqBy, uniq, keysIn, first, has } from 'lodash'
import moment from 'moment-timezone'
import TripRoundMiniNotes from './TripRoundMiniNotes'

const TripMatchScoreboard = ({ groupId = null, tripId = null, round = 0, matches = [], isShotgun = false, trip = {} }) => {

    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const _round = (trip.rounds || []).find(i => i.round === round)
    const { courseName, tees, par, slope, rating, yards, notes, formatName, formatDescription, hasHandicaps } = _round || {}

    const foursomes = uniq(matches.map(i => i.foursome || 0))
        .map(_foursome => ({ 
            foursome: _foursome,
            isShotgun,
            matches: matches.map(x => ({ ...x, foursome: x.foursome || 0 })).filter(x => x.foursome === _foursome),
        })).map(i => ({
            ...i,
            matchCount: (i.matches || []).length,
        })).map(i => ({
            ...i,
            ...(i.matchCount ? {
                teeTime: first(i.matches).teeTime,
                teeTimeDisplay: first(i.matches).teeTimeDisplay,
                startingHole: first(i.matches).startingHole,
                timeZoneId: (first(i.matches).timezone || {}).timeZoneId || 'America/New_York',
            } : {
                teeTime: null,
                teeTimeDisplay: '',
                startingHole: 1,
                timeZoneId: 'America/New_York',    
            }),
        }))

    return (
        <div className={s.container}>
            {_round ?
            <div className={s.roundHeader}>
                <div className={s.courseName}>{courseName}{tees ? ` (${tees} tees)` : ``}</div>
                <div className={s.courseDetails} title={formatDescription || ''}>Par {par} {yards} Yards Slope {slope} Rating {rating}{formatName ? ` ${formatName}` : ``}</div>
            </div>
            : null}
            <TripRoundMiniNotes notes={notes} />
            {groupId && tripId && round && foursomes && foursomes.map(i =>
            <Card className={s.foursomeCard} key={`trip-${tripId}-round-${round}-foursome-${i.foursome}`}>
                <Card.Header className={s.foursomeHeader}>
                    <div className={s.teeTimeIcon}><FontAwesomeIcon name='clock' /></div>
                    <div className={s.teeTime}>{i.teeTime && moment(i.teeTime).isValid() ? moment.tz(i.teeTime, i.timeZoneId).format('h:mm A') : 'TBD'}</div>
                    {i.isShotgun ? <div className={s.startingHole}>Starting on #{i.startingHole}</div> : null}
                    <div className={s.scorecardButton}><Button variant='light' onClick={() => navigate(`${location.pathname}/scorecard/foursome/${i.foursome}`)}><FontAwesomeIcon name='clipboard-list' /></Button></div>
                </Card.Header>
                <Card.Body>
                {i.matches && i.matches.map(m =>
                    <TripMatchPanel matchItem={m} trip={trip}
                        isShotgun={isShotgun} showHcp={hasHandicaps}
                        showNotStartedHeader={m.matchCount === 1}
                        key={`trip-match-${m._id}`} />
                )}
                </Card.Body>
            </Card>
            )}
            {!groupId || !tripId || !round || !foursomes || !foursomes.length || !matches || !matches.length ? 
            <p>No matches located.</p>
            : null}
        </div>
    )
}

export default TripMatchScoreboard