// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripRoundScorecardAdminSaveAlert__headerAlert____p_MI{flex:0 0 0;padding:2px 8px;margin:0px 1rem;min-width:25%;display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.TripRoundScorecardAdminSaveAlert__headerAlert____p_MI .TripRoundScorecardAdminSaveAlert__icon___tbAwv{flex:0 0 0;margin-right:1rem;white-space:nowrap}.TripRoundScorecardAdminSaveAlert__headerAlert____p_MI .TripRoundScorecardAdminSaveAlert__message___v0pKl{flex:1 1 auto;text-align:left;font-weight:normal;font-size:18px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/Admin/TripRoundScorecardAdminSaveAlert.scss"],"names":[],"mappings":"AAEA,uDACI,UAAA,CACA,eAAA,CACA,eAAA,CACA,aAAA,CAEA,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,uGACI,UAAA,CACA,iBAAA,CACA,kBAAA,CAGJ,0GACI,aAAA,CACA,eAAA,CACA,kBAAA,CACA,cAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n\n.headerAlert {\n    flex: 0 0 0;\n    padding: 2px 8px;\n    margin: 0px 1rem;\n    min-width: 25%;\n\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: space-between;\n    align-items: center;\n\n    .icon {\n        flex: 0 0 0;\n        margin-right: 1rem;\n        white-space: nowrap;\n    }\n\n    .message {\n        flex: 1 1 auto;\n        text-align: left;\n        font-weight: normal;\n        font-size: 18px;\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var headerAlert = `TripRoundScorecardAdminSaveAlert__headerAlert____p_MI`;
export var icon = `TripRoundScorecardAdminSaveAlert__icon___tbAwv`;
export var message = `TripRoundScorecardAdminSaveAlert__message___v0pKl`;
export default ___CSS_LOADER_EXPORT___;
