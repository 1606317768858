import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useMatch, useLocation, useOutletContext, Outlet } from 'react-router-dom'
import * as s from './TripRoundScorecardAdminModal.scss'
import TripRoundScorecardAdminSaveAlert from './TripRoundScorecardAdminSaveAlert'
import TripRoundScorecardAdminTable from './TripRoundScorecardAdminTable'
import Modal from '@severed-links/common.modal'
import { getSkinsAndProxiesScoreboard } from '@severed-links/common.severedlinks-reducers/groups'

const TripRoundScorecardAdminModal = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const match = useMatch(`/group/:groupId/trip/:tripId/admin/rounds/edit/round/:round/scorecard/:showScorecardParam`)
    const _roundContext = useOutletContext()
    const { group, trip, groupId, tripId, round, roundSubTab, roundParam, roundNumber, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = _roundContext
    const showScorecard = match && match.params.showScorecardParam === 'show'

    const closeScoreboard = () => {
        dispatch(getSkinsAndProxiesScoreboard(groupId, tripId))
        navigate(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'), { replace: true })}`)
    }

    return (
        <Modal show={showScorecard} showFooter={false} dialogClassName={s.scorecardModal}
            heading={<div className={s.scorecardHeader}>
                <h4 className={s.scorecardTitle}>{round.roundName} Scorecard</h4>
                <TripRoundScorecardAdminSaveAlert />
            </div>}
            onClose={() => closeScoreboard()}>
            <div>
                <TripRoundScorecardAdminTable groupId={groupId} 
                    tripId={tripId} round={roundNumber} />
            </div>
        </Modal>
    )
}

export default TripRoundScorecardAdminModal