import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { setPlayerFilterLetter } from '@severed-links/common.severedlinks-reducers/groups'
import { routingPath } from '@severed-links/common.severedlinks-constants'
import * as s from './PlayerFilter.scss'

const PlayerFilter = ({ group, players = [], playerFilter, isGlobalAdminPath = false }) => {

    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const groupId = params.groupId || null
    const filterLetter = (params.letter || '').toUpperCase()

    const handleSelect = e => {
        dispatch(setPlayerFilterLetter(groupId, e))
        navigate(`${routingPath(groupId, isGlobalAdminPath, group.name.substring(0,1).toUpperCase())}players${(e ? `/filter/${e}` : ``)}`)
    }

    if (!groupId || !players || players.length <= 25 || !filterLetter) return null
    return (
        <Nav className={s.container} variant="pills" activeKey={filterLetter} 
            onSelect={e => handleSelect(e)}>
        {playerFilter && playerFilter.map(i =>
            <Nav.Item className={s.item} key={`group-player-filter-${i.letter}`}>
                <Nav.Link className={s.link + (i.letter === filterLetter ? ` ${s.active}` : ``) + (i.disabled ? ` ${s.disabled}` : ``)} eventKey={i.letter} disabled={i.disabled} style={{ cursor: !i.disabled ? 'pointer' : 'not-allowed' }}>{i.letter}</Nav.Link>
            </Nav.Item>
        )}
        </Nav>
    )
}

export default PlayerFilter