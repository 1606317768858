import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useMatch, Outlet, useLocation } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from '../Groups/Group.scss'
import { globalAdminPutGroupIntoList, getTrips, getPlayers, getPendingInvites, getGroupSettings, 
    getLoginInfo, getDeletedTrips, getFaq } from '@severed-links/common.severedlinks-reducers/groups'
import LargeGroupHeader from '../Groups/LargeGroupHeader'
import GroupMenuBar from '../Groups/GroupMenuBar'
import Players from '../Player/Players'
import Trips from '../Trips/Trips'
import Trip from '../Trips/Trip'
import GroupAdmin from '../Groups/Admin/GroupAdmin'
import Top10Lists from '../Groups/Top10Lists'
import StewartAwardWinners from '../Groups/StewartAwardWinners'
import GroupPlayerInviter from '../Player/GroupPlayerInviter'
import { routingPath } from '@severed-links/common.severedlinks-constants'
import OnlineUsers from '../Groups/OnlineUsers'
import { endsWith } from 'lodash'
import { sendSocketCommand } from '@severed-links/common.severedlinks-reducers/socket'

const GlobalAdminGroupViewer = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const groupId = params.groupId || null
    const group = useSelector(state => (state.groups.groups || []).find(g => g._id === groupId))
    const globalAdminGroup = useSelector(state => (state.globalAdmin.groups.list || []).find(i => i._id === groupId))
    const isGroupInList = useSelector(state => (state.groups.groups || []).findIndex(g => g._id === groupId) > -1)
    const id = (group || globalAdminGroup || {})._id || null
    const isRootPath = endsWith(location.pathname, `view/${groupId}`)
    const tripCount = ((group || {}).trips || []).length
    const playerCount = ((group || {}).players || []).length

    useEffect(() => {
        if (id) {
            dispatch(getTrips(id))
            dispatch(getDeletedTrips(id))
            dispatch(getPlayers(id))
            dispatch(getGroupSettings(id))
            dispatch(getFaq(id))
            dispatch(getLoginInfo(id))
            dispatch(getPendingInvites(id))
        }
    }, [id])

    useEffect(() => {
        if (groupId) {
            dispatch(sendSocketCommand('global-admin-enter-group', { groupId }))
            if (__DEV__) console.log('global-admin-enter-group', groupId)

        }
       return () => {
            if (groupId) {
                dispatch(sendSocketCommand('global-admin-leave-group', { groupId }))
                if (__DEV__) console.log('global-admin-leave-group', groupId)
            }
       }
    }, [groupId])

    useEffect(() => {
        if (!isGroupInList && globalAdminGroup && globalAdminGroup._id) {
            dispatch(globalAdminPutGroupIntoList(globalAdminGroup))
        }
    }, [isGroupInList, (globalAdminGroup || {})._id || null])

    useEffect(() => {
        if (isRootPath) {
            if (tripCount && !playerCount) {
                navigate(location.pathname + '/trips')
            } else if (!tripCount && playerCount) {
                navigate(location.pathname + '/players')
            }
        }
    }, [isRootPath, tripCount, playerCount])

    if (!isGroupInList || !group) return null
    return (
        <div className={s.container}>
            <LargeGroupHeader groupName={group.name} show onBack={() => navigate(`/global-admin/groups/filter/${((group || {}).name || ' ').substring(0,1).toUpperCase()}`)} />
            <div className={s.headerContainer}>
                <GroupMenuBar group={group} show playerFilter={group.playerFilter || []} playerFilterLetter={group.playerFilterLetter || 'A'} isGlobalAdmin />
                <OnlineUsers group={group} isGlobalAdmin />
            </div>
            <Outlet context={{ group, groupId, isGlobalAdmin: true, isGlobalAdminPath: true }} />
        </div>
    )
}

export default GlobalAdminGroupViewer