// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupRestorer__container___NvT5l{margin:0px 5px 0px 0px}.GroupRestorer__container___NvT5l .GroupRestorer__deletedButton___GgSpa{font-size:75%;padding:.25em .4em;line-height:1;font-weight:700}`, "",{"version":3,"sources":["webpack://./src/routes/Groups/GroupRestorer.scss"],"names":[],"mappings":"AAAA,kCACI,sBAAA,CAEA,wEACI,aAAA,CACA,kBAAA,CACA,aAAA,CACA,eAAA","sourcesContent":[".container {\n    margin: 0px 5px 0px 0px;\n    \n    .deletedButton {\n        font-size: 75%;\n        padding: 0.25em 0.4em;\n        line-height: 1;\n        font-weight: 700;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `GroupRestorer__container___NvT5l`;
export var deletedButton = `GroupRestorer__deletedButton___GgSpa`;
export default ___CSS_LOADER_EXPORT___;
