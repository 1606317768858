import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminJobQueueItem.scss'
import { BULL_QUEUE_STATUS_FIELDS } from '@severed-links/common.severedlinks-constants'
import moment from 'moment-timezone'

const GlobalAdminJobQueueItem = ({ item = {}, onClick = null }) => {

    const { time_zone } = useSelector(state => state.account)

    const i = item
    return (
        <ListGroup.Item className={s.container} {...({ action: !!onClick, onClick })}>
            <div className={s.content}>
                <div className={s.titleBar}>
                    <div className={s.name}>{i.displayName}{i.isRepeatable ? <FontAwesomeIcon name='sync' className={s.repeatableIcon} /> : null}</div>                    
                </div>
                <div className={s.statusContainer}>
                {BULL_QUEUE_STATUS_FIELDS.filter(x => !!i[x.fieldName]).map(x => 
                <div className={s.statusItem + (x.variant ? ` ${s[x.variant]}` : ``)} key={`global-admin-bull-job-${i.name}-field-${x.fieldName}`}>
                    <FontAwesomeIcon name={x.icon} className={s.statusIcon} />
                    <div>{i[x.fieldName]}</div>
                </div>
                )}
                </div>
            </div>
            {i.isRepeatable && i.nextScheduledJob && moment(i.nextScheduledJob).isValid() ? 
            <>
            <div className={s.calendar}>
                <div className={s.month}>{moment.tz(i.nextScheduledJob, time_zone).format('MMM')}</div>
                <div className={s.date}>{moment.tz(i.nextScheduledJob, time_zone).format('D')}</div>
            </div>
            <div className={s.calendar + ' ' + s.clock}>
                <FontAwesomeIcon name='clock' />
                <div>{moment.tz(i.nextScheduledJob, time_zone).format('h:mm A')}</div>
            </div>
            </>
            : null}
            {onClick ?
            <div className={s.clickIcon}>
                <FontAwesomeIcon name='chevron-right' size='2x' />
            </div>
            : null}
        </ListGroup.Item>
    )
}

export default GlobalAdminJobQueueItem
