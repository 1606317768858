// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProxyDistanceEditor__container___lXMEb{margin:0px;display:flex;flex-flow:row wrap;justify-content:center;align-items:center}.ProxyDistanceEditor__container___lXMEb .ProxyDistanceEditor__label___qeUOY{display:block;width:100%;margin-bottom:.25rem;font-weight:bold}.ProxyDistanceEditor__container___lXMEb .ProxyDistanceEditor__unitEditorContainer___ZOLea{margin:.5rem .5rem;flex:1 0 12rem}`, "",{"version":3,"sources":["webpack://./src/routes/Trips/ProxyDistanceEditor.scss"],"names":[],"mappings":"AAEA,wCACI,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,4EACI,aAAA,CACA,UAAA,CACA,oBAAA,CACA,gBAAA,CAGJ,0FACI,kBAAA,CACA,cAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n\n.container {\n    margin: 0px;\n    display: flex;\n    flex-flow: row wrap;\n    justify-content: center;\n    align-items: center;\n\n    .label {\n        display: block;\n        width: 100%;\n        margin-bottom: 0.25rem;\n        font-weight: bold;\n    }\n\n    .unitEditorContainer {\n        margin: 0.5rem 0.5rem;\n        flex: 1 0 12rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `ProxyDistanceEditor__container___lXMEb`;
export var label = `ProxyDistanceEditor__label___qeUOY`;
export var unitEditorContainer = `ProxyDistanceEditor__unitEditorContainer___ZOLea`;
export default ___CSS_LOADER_EXPORT___;
