import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams, useMatch } from 'react-router-dom'
import { FormGroup, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import { golfCourseNameSearch } from '@severed-links/common.severedlinks-reducers/player'
import * as s from './GlobalAdminGolfCourseSearcher.scss'

const GlobalAdminGolfCourseSearcher = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const _typeahead = useRef(null)

    const handleUsGolfLookupChange = e => navigate(`/global-admin/courses/edit/${e[0]._id}`)

    const runQuery = useCallback(query => {
        setLoading(true)
        dispatch(golfCourseNameSearch(query))
        .then(action => {
            setLoading(false)
            setOptions(action.payload)
        })
    }, [])

    return (
        <div className={s.container}>
            <FormGroup className={s.formGroup}>
            <InputGroup>
                <InputGroup.Text><FontAwesomeIcon name='search' /></InputGroup.Text>
                <AsyncTypeahead ref={_typeahead}
                    id={`global-admin-golf-course-searcher-typeahead`}
                    onSearch={runQuery}
                    isLoading={isLoading}
                    multiple={false} autoFocus
                    labelKey={o => `${o.courseName} (${o.city} ${o.state})`}
                    renderMenu={(results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => (
                        <Menu {...menuProps}>
                            {results.map((o, index) => (
                            <MenuItem className={s.menuItem} option={o} position={index} key={`tpyeahead-course-search-item-${o._id}`}>
                                {o.courseName} ({o.city} {o.state})
                            </MenuItem>
                            ))}
                        </Menu>
                    )}
                    placeholder={'[Lookup course by name...]'}
                    options={options}
                    onChange={e => handleUsGolfLookupChange(e)} />
                </InputGroup>
            </FormGroup>
        </div>
    )
}

export default GlobalAdminGolfCourseSearcher