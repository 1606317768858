import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Outlet } from 'react-router-dom'
import { Row, Col, Image, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import * as s from './GroupPlayerProfile.scss'
import { getPlayerInfo } from '@severed-links/common.severedlinks-reducers/player'
import GroupPlayerSummary from './GroupPlayerSummary'
import GroupProfileMainHeader from './GroupProfileMainHeader'
import GroupPlayerRounds from './GroupPlayerRounds'
import Spinner from '@severed-links/common.spinner'

const GroupPlayerProfile = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const groupId = params.groupId || null
    const playerId = params.playerId || null
    const group = useSelector(state => state.groups.groups).find(g => g._id === groupId) || {}
    const player = (group.players || []).find(p => p._id === playerId) || {}
    const info = useSelector(state => state.player.loadedProfiles)[`${groupId}-${playerId}`] || {}
    const { _id: myPlayerId, isGlobalAdmin } = useSelector(state => state.account)
    const [isLoading, setLoading] = useState(false)
    const isMe = playerId === myPlayerId
    const isGroupAdmin = ((group || {}).role || {}).isAdmin || isGlobalAdmin
    const showSpinner = isLoading && (!info || !info._id)

    useEffect(() => loadPlayer(), [groupId, playerId])

    const loadPlayer = () => {
        if (groupId && playerId) {
            setLoading(true)
            dispatch(getPlayerInfo(groupId, playerId))
            .then(action => setLoading(false))
        }
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}><FontAwesomeIcon name='user' /> {player.playerName}</h2>
                <div className={s.controls}>
                {isGroupAdmin || isGlobalAdmin ? <Button onClick={() => navigate(`/group/${groupId}/player/${playerId}/edit/player-details`, { replace: true })}><FontAwesomeIcon name='pencil-alt' /> edit details</Button> : null}
                </div>
            </div>
            <Row>
                <Col sm={4} xs={12} className={s.avatarContainer}>
                    <Image className={s.avatar} rounded src={playerAvatarUrl(player.imageUrl)} />
                    {isMe ? <Button onClick={() => navigate('/profile/edit')} variant='info' size='sm' className={s.editMyProfile}><FontAwesomeIcon name='pencil-alt' /></Button> : null}
                </Col>
                <Col sm={8} xs={12}>
                    {showSpinner ? 
                    <div style={{ textAlign: 'center' }}><Spinner size='3x' /></div>
                    :
                    <GroupProfileMainHeader isLoading={showSpinner}
                        skins={(info.summary || {}).skins}
                        proxies={(info.summary || {}).proxies}
                        overallRecord={(info.summary || {}).overallRecord || '0-0'}
                        wonLossRecords={(info.summary || {}).wonLossRecords || []}
                        index={(info.summary || {}).lastIndex || 0}
                        city={(info.player || {}).city} 
                        state={(info.player || {}).state} />}
                </Col>
            </Row>
            <Row>
                <Col sm={4} xs={12}>
                    <GroupPlayerSummary isLoading={showSpinner} summary={(info || {}).summary} player={(info || {}).player} />
                </Col>
                <Col sm={8} xs={12}>
                    <GroupPlayerRounds isLoading={showSpinner} rounds={info.rounds || []} />
                </Col>
            </Row>
            <Outlet context={{ groupId, playerId }} />
        </div>
    )
}

export default GroupPlayerProfile