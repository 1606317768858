import React, { useRef } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { useDrop } from 'react-dnd'
import { ITEM_TYPES } from '../DragAndDropItemTypes'
import * as s from '../TripLodgingAdmin.scss'
 
const UnassignedRoomPanelDropZone = ({ player = {}, room = {}, onDrop = () => void(0) }) => {

    const [{ handlerId, isOver }, _dropRef] = useDrop({
        accept: ITEM_TYPES.ROOM,
        collect: (monitor, props) => ({ 
            handlerId: monitor.getHandlerId(),
            isOver: !!monitor.isOver(),
        }),
        drop: (item, monitor) => onDrop(item.player, room)
    })

    return (
        <div ref={_dropRef}>
            <ListGroup.Item className={s.blankItem + (isOver ? ' ' + s.dropActive : '')}>[Drop...]</ListGroup.Item>
        </div>
    )
}

export default UnassignedRoomPanelDropZone
