import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@severed-links/common.modal'
import Badge from '@severed-links/common.badge'
import Button from '@severed-links/common.button'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import ListGroup from 'react-bootstrap/ListGroup'
import PlayerListItem from '../../Player/PlayerListItem'
import PlayerTeeBoxOverrides from '../../Player/PlayerTeeBoxOverrides'
import pluralize from 'pluralize'
import { create, reject } from 'lodash'
import { getHandicapCheckerForPlayer, saveTeeBoxOverrides } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import numeral from 'numeral'
import * as s from './TripPlayerAdminTeeBoxOverrideEditor.scss'

const TripPlayerAdminTeeBoxOverrideEditor = ({ group, trip, player, selectedRound = 0 }) => {

    const dispatch = useDispatch()
    const groupId = group?._id || null
    const tripId = trip?._id || null
    const playerId = player?._id || null
    const rounds = useSelector(state => player && groupId && tripId ? (((((state.groups.groups || []).find(g => g._id === groupId) || {}).trips || []).find(t => t._id === tripId) || {}).rounds || []) || [] : [])
    const { height } = useSelector(state => state.layout)
    const { _id: userId } = useSelector(state => state.account)
    const [show, setShow] = useState(false)
    const [isSaving, setSaving] = useState(false)
    const [overrides, setOverrides] = useState([])
    const teeBoxOverrides = player?.teeBoxOverrides || []

    useEffect(() => {
        if (show) {
            setOverrides(teeBoxOverrides)
        }
    }, [show, teeBoxOverrides, teeBoxOverrides.length])

    const handleSelectTeeBox = (_override = {}) => {
        var _overrides = [...overrides]
        if (_override.round) {
            _overrides = reject(_overrides, r => r.round === _override.round)
        }
        if (!rounds.some(r => r.round === _override.round && r.tees === _override.tees)) {
            _overrides.push(_override)
        }
        setOverrides(_overrides)
    }

    const doSaveOverrides = () => {
        const { groupId, tripID, playerID } = player
        if (groupId && tripID && playerID) {
            setSaving(true)
            dispatch(saveTeeBoxOverrides(groupId, tripID, playerID, overrides))
            .then(action => {
                dispatch(createNotification(action.payload))
                setTimeout(() => setSaving(false), 1000)
            })
            setShow(false)
            setOverrides([])
        }
    }

    const { playerName } = player

    const _isSelected = (_round, _tees) => {
        const r = rounds.find(rnd => rnd.round === _round)
        if (r) {
            const o = overrides.find(rnd => rnd.round === _round)
            if (o) {
                return o.tees === _tees
            } else {
                return r.tees === _tees
            }
        }
        return false
    }

    const _isDefault = (_round, _tees) => {
        const r = rounds.find(rnd => rnd.round === _round)
        if (r) {
            return r.tees === _tees
        }
        return false
    }

    if (!groupId || !tripId || !playerId) return null
    const _overrideBadges = selectedRound ? teeBoxOverrides.filter(i => i.round === selectedRound) : teeBoxOverrides
    return (
        <>
        <ListGroup.Item className={s.item} action onClick={() => setShow(true)}>
            <div className={s.content}>
                <div className={s.title}>Tee box overrides</div>
                <div className={s.subtitle}>Allow a player to use a different tee box</div>
            </div>
            <div className={s.overrideContainer}>
            {!isSaving ?
                <PlayerTeeBoxOverrides teeBoxOverrides={teeBoxOverrides} showNone />
                :
                <FontAwesomeIcon spin name='circle-notch' className={s.spinner} />
            }
            </div>
            <div>
                <FontAwesomeIcon name='chevron-right' />
            </div>
        </ListGroup.Item>

        <Modal show={show} heading='Tee box overrides'
            onClose={() => setShow(false)}
            actionButtonText={`save ${pluralize('override', overrides.length, false)}`}
            actionButtonIcon='check'
            actionButtonOnClick={() => doSaveOverrides()}>
        <div className={s.overrideModalContainer}>

            <ListGroup className={s.list}>
                <PlayerListItem player={player} showHcp clickDisabled />
            </ListGroup>

            <ListGroup className={s.list}>
            {rounds && rounds.map(r =>
                <ListGroup.Item className={s.item} key={`tee-box-override-round-${r.round}`}>
                    <div className={s.roundNumberContainer}>
                        <div className={s.roundNumberTitle}>Round</div>
                        <div className={s.roundNumber}>{r.round}</div>
                    </div>
                    <div className={s.content}>
                        <div className={s.title}>{r.courseName}</div>
                        <div className={s.subtitle}>{r.geocodedAddress}</div>
                        <ListGroup className={s.teeBoxesContainer}>
                        {r.teeBoxes && r.teeBoxes.map((t, _teeIndex) => 
                            <ListGroup.Item className={s.teeBoxItem}
                                action onClick={() => handleSelectTeeBox({ ...t, round: r.round })}
                                key={`tee-box-override-round-${r.round}-tees-${t.tees}`}>
                                <div className={s.iconContainer + (_isSelected(r.round, t.tees) ? ` ${s.isSelected}` : ``)}>
                                    <FontAwesomeIcon name={_isSelected(r.round, t.tees) ? 'check-circle' : 'circle'} isRegular={!_isSelected(r.round, t.tees)} />
                                </div>
                                <div className={s.teeBoxContent} style={{ backgroundColor: t.backgroundColor }}>
                                    <div style={{ color: t.fontColor }}>{t.tees} tees</div>
                                    <div style={{ color: t.fontColor }}>{[t.par ? `Par ${t.par}` : ``, t.yards ? `${t.yards} yards` : ``, t.slope ? `Slope ${t.slope}` : ``, t.rating ? `Rating ${numeral(t.rating).format('0.0')}` : ``].filter(x => x).join(', ')}</div>
                                </div>
                                {_isDefault(r.round, t.tees) ? <Badge variant='secondary'>Playing</Badge> : null}
                            </ListGroup.Item>
                            )}

                        </ListGroup>
                    </div>
                </ListGroup.Item>
            )}
            </ListGroup>

        </div>
        </Modal>
        </>
    )
}



export default TripPlayerAdminTeeBoxOverrideEditor
