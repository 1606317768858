import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Row, Col, FormGroup, FormControl, FormLabel, Button, ButtonGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { Editor, EditorState, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import * as s from './TripMessaging.scss'
import { saveTripNotes } from '@severed-links/common.severedlinks-reducers/groups'

const TripNotesAdminPublic = () => {

    const dispatch = useDispatch()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const { group, groupId, trip, tripId, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [isSaving, setSaving] = useState(false)

    useEffect(() => initializeEditor(), [])

    const initializeEditor = () => setEditorState(trip.notes ? EditorState.createWithContent(stateFromHTML(trip.notes)) : EditorState.createEmpty())

    const handleEditorChange = _editorState => setEditorState(_editorState)

    const handleEditorButton = command => handleEditorChange(RichUtils.toggleInlineStyle(editorState, command))

    const saveNotes = () => {
        const options = {
            blockStyleFn: block => {
                return {
                    style: {
                        marginBottom: '12px'
                    }
                }
            }        
        }
        setSaving(true)
        dispatch(saveTripNotes(group._id, trip._id, editorState.getCurrentContent().hasText() ? stateToHTML(editorState.getCurrentContent(), options) : ''))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Save Trip Notes', timeout: 4000 }))
            setSaving(false)
        })
    }

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <p> 
                        Any notes entered below will show up on the 'Details' tab for this trip.  
                        It is best to keep these notes short so they do not occupy too much space 
                        on the front page.
                    </p>

                    <div className={s.editorContainer} style={{ marginBottom: '20px' }}>
                        <div className={s.editorTools}>
                        <ButtonGroup tabIndex={-1}>
                            <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('BOLD')}><FontAwesomeIcon name='bold' /></Button>
                            <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('UNDERLINE')}><FontAwesomeIcon name='underline' /></Button>
                            <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('ITALIC')}><FontAwesomeIcon name='italic' /></Button>
                        </ButtonGroup>
                        </div>
                        <Editor editorState={editorState} 
                            placeholder='[Enter your trip notes here...]' autoFocus 
                            blockStyleFn={contentBlock => s.block}
                            onChange={e => handleEditorChange(e)} />
                    </div>

                    <FormGroup>
                        <Button variant='primary' disabled={isSaving} 
                            onClick={() => saveNotes()}><FontAwesomeIcon name={!isSaving ? 'check' : 'circle-notch'} spin={isSaving} /> save notes</Button>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    )
}

export default TripNotesAdminPublic