import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext, Link } from 'react-router-dom'
import { Image, Card } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { commonImageUrl, playerAvatarBigThumb } from '@severed-links/common.severedlinks-constants'
import { getStewartAwardWinners } from '@severed-links/common.severedlinks-reducers/groups'
import * as s from './StewartAwardWinners.scss'

const StewartAwardWinner = ({ groupId, winner = {} }) => {
    const i = winner
    return (
    <Card className={s.card} key={`stewart-award-${i.year}-${i.playerID}`}>
        <Card.Header className={s.header}>{i.tripName}</Card.Header>
        <Card.Img className={s.avatar} variant='top' src={playerAvatarBigThumb(i.imageUrl)} />
        <Card.Footer as='h5' className={s.footer}>
            <Link to={`/group/${groupId}/player/${i.playerID}`} style={{ textDecoration: 'none' }}>
                <div className={s.firstName}>{i.firstName}</div>                                 
                <div className={s.lastName}>{i.lastName}</div>
            </Link>
        </Card.Footer>
    </Card>
    )
}

const StewartAwardWinners = () => {

    const dispatch = useDispatch()
    const { group, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const winners = group.stewartAwardWinners || []
    const groupId = group._id || null

    useEffect(() => {
        dispatch(getStewartAwardWinners(group._id))
    }, [])

    return (
        <div className={s.container}>
            <h3>{group.mvpAwardName || 'MVP Award'}</h3>

            <div dangerouslySetInnerHTML={{ __html: group.mvpAwardDescription || null }} />

            <p><i>You can vote for the {group.mvpAwardName} winner for that trip during any trip that you attend.</i></p>

            <div className={s.cards}>
            {winners && winners.map(i =>
                <StewartAwardWinner winner={i} groupId={groupId}
                    key={`stewart-award-${i.year}-${i.playerID}`} />
            )}
            </div>
        </div>
    )
}

export default StewartAwardWinners