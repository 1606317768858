import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './GlobalAdminTodos.scss'
import { getTodos } from '@severed-links/common.severedlinks-reducers/globalAdmin'

const GlobalAdminTodos = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const _todos = useSelector(state => state.globalAdmin.todos)

    useEffect(() => {
        dispatch(getTodos())
    }, [])

    return (
        <div className={s.container}>
            <h4>TODO List</h4>
            <ListGroup>
            {_todos.map(i => 
            <ListGroup.Item key={i}>{i}</ListGroup.Item>
            )}
            </ListGroup>
        </div>
    )
}

export default GlobalAdminTodos
