import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripEventLocation.scss'

const TripEventLocation = ({ locationAddress, geocodedAddress, onShowDrivingDirections }) => {

    if (!locationAddress) return null
    return (
        <div className={s.container + (geocodedAddress ? ' ' + s.hasGeocodedAddress : '')} title={geocodedAddress}
            onClick={e => {
                if (geocodedAddress) { 
                    onShowDrivingDirections()
                    e.stopPropagation()
                }
            }}>
            <FontAwesomeIcon name='map-marker' />
            {' '}
            {locationAddress}
        </div>
    )
}

export default TripEventLocation
