import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './AccountDeletionPortal.scss'
import { getPlayerInfoSummary, setAccountDeletionState } from '@severed-links/common.severedlinks-reducers/accountDeletion'
import StepZilla from 'react-stepzilla'
import AccountDeletionStep1 from './AccountDeletionStep1'
import DeleteGroupsStep2 from './DeleteGroupsStep2'
import PersonalInfoStep3 from './PersonalInfoStep3'
import ConfirmationStep4 from './ConfirmationStep4'
import EndStep5 from './EndStep5'
import LogoutStep6 from './LogoutStep6'

const AccountDeletionPortal = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const playerId = useSelector(state => state.account._id)
    const _accountDeletion = useSelector(state => state.accountDeletion)

    useEffect(() => {
        dispatch(getPlayerInfoSummary())
        const _initializeState = {
            groupsDeletionConfirmed: false,
            playerInfoSelection: '',
            accountDeletionConfirmed: false,
        }
        dispatch(setAccountDeletionState(_initializeState))
    }, [])

    useEffect(() => {
    }, [])

    const handleStepChange = _currentStep => {
    }

    const _steps = [
        { name: 'Instructions', component: <AccountDeletionStep1 /> },
        { name: 'Delete Groups', component: <DeleteGroupsStep2 /> },
        { name: 'Personal Information', component: <PersonalInfoStep3 /> },
        { name: 'Confirmation', component: <ConfirmationStep4 /> },
        { name: 'Complete', component: <EndStep5 /> },
        { name: 'Logout', component: <LogoutStep6 /> },
    ]

    return (
        <div className={s.container}>

            <div className={s.heading}>
                <div className={s.icon}>
                    <FontAwesomeIcon name='exclamation-triangle' size='2x' />
                </div>
                <h2 className={s.title}>Account Deletion Portal</h2>
            </div>
            <div className={s.stepContainer}>
                <StepZilla steps={_steps}
                    preventEnterSubmission
                    nextTextOnFinalActionStep={'Delete Account'}
                    onStepChange={handleStepChange}
                    nextButtonText={<FontAwesomeIcon name='chevron-right' />}
                    backButtonText={<FontAwesomeIcon name='chevron-left' />}
                    nextButtonCls={'btn btn-next btn-outline-danger btn-lg ' + s.navButton + ' ' + s.nextNavButton}
                    backButtonCls={'btn btn-prev btn-outline-secondary btn-lg ' + s.navButton + ' ' + s.prevNavButton}
                />
            </div>
        </div>
    )
}

export default AccountDeletionPortal
