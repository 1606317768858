import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripRoundScorecardAdminHoleScore.scss'
import { range } from 'lodash'
import { doAdminScorecardUpdate } from '@severed-links/common.severedlinks-reducers/scorecard'

const TripRoundScorecardAdminHoleScore = ({ groupId, tripId, round, playerId, playerTeam, hole, isTeam = false, isSaving = false, onChange = () => void(0) }) => {

    const [grossScore, setGrossScore] = useState('')
    const [isScoreSet, setIsScoreSet] = useState(false)

    useEffect(() => {
        setInitialHoleScore()
        setIsScoreSet(true)
    }, [])

    useEffect(() => {
        if (!isSaving) {
            setInitialHoleScore()
        }
    }, [isSaving])
    
    useEffect(() => { onGrossScoreChange() }, [grossScore, isScoreSet])

    const setInitialHoleScore = () => {
        setGrossScore(hole && hole.grossScore && hole.grossScore > 0 ? hole.grossScore.toString() : '')
    }

    const onGrossScoreChange = () => {
        if (isScoreSet && `${grossScore || ''}` !== `${hole.grossScore || ''}`) {
            const holeData = {
                groupId, tripId, round, playerId, tripPlayerId: hole.tripPlayerID, 
                hole: hole.hole, grossScore: grossScore && !isNaN(grossScore) ? parseInt(grossScore) : null,
                tripMatchId: hole._id, tripMatchHoleScoreId: hole.tripMatchHoleScoreID,
            }
            onChange(holeData)
        }
    }

    const onTextBoxChange = e => {
        const _newGrossScore = e.target.value
        if (_newGrossScore !== grossScore) {
            setGrossScore(_newGrossScore)
        }
    }

    var _strokes = (hole || {}).strokes || 0
    if (isTeam) {
        _strokes = (hole || {}).teamStrokes || 0
    }

    return (
        <div className={s.holeContainer}>
            <FormControl disabled={isSaving} 
                value={grossScore} 
                className={s.textbox + ' ' + s[(playerTeam || '').toLowerCase()]} 
                onChange={e => onTextBoxChange(e)} />
            {_strokes ? 
                <div className={s.strokeIcon+ ' ' + s[(playerTeam || '').toLowerCase()] + (_strokes === 1 ? ' ' + s.singleStroke : '')}>
                    {range(1, _strokes + 1, 1).map(i => <FontAwesomeIcon name={isTeam ? 'square' : 'circle'} key={`stroke-${hole._id}-${hole.hole}-${playerId}-${i}`} />)}
                </div>
            : null}
            {hole && hole.isSkin ? 
                <div className={s.skin} title='Skin!'><FontAwesomeIcon name='flag' /></div>
            : null}
            {hole && hole.isProxy ? 
                <div className={s.proxy} title={'Proxy!'}><FontAwesomeIcon name='star' /></div>
            : null}
            {isSaving ? 
                <div className={s.spinner + ' ' + s[(playerTeam || '').toLowerCase()]}>
                    <FontAwesomeIcon name='circle-notch' spin />
                </div>
            : null}
        </div>
    )
}

export default TripRoundScorecardAdminHoleScore