import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation, useMatch } from 'react-router-dom'
import { FormLabel, Button, FormGroup, FormControl, 
    ListGroup, Image, ButtonGroup, ButtonToolbar, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import { editGroupPlayer, updatePlayerNote, deletePlayerNote, 
    savePlayerProfileByAdmin, getPlayerInfo } from '@severed-links/common.severedlinks-reducers/player'
import moment from 'moment-timezone'
import { getPlayers, setPlayerRoleByRoleId, updatePlayerIndex } from '@severed-links/common.severedlinks-reducers/groups'
import { getMe } from '@severed-links/common.severedlinks-reducers/account'
import { FRIENDLY_DATE_FORMAT, playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import GroupRole from '../Groups/GroupRole'
import TextareaAutosize from 'react-autosize-textarea'
import * as s from './GroupPlayerEditor.scss'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import GroupPlayerEditorAvatarUpdate from './GroupPlayerEditorAvatarUpdate'
import { setAvatarUrl } from '@severed-links/common.severedlinks-reducers/profile'

const GroupPlayerFormGroup = ({ field, onChange }) => {
    const i = field || {}
    return (
        <>
            <FormGroup className={s.formGroup + (i.spaceBefore ? ` ${s.spaceBefore}` : '')}>
                <FormLabel>{i.label}</FormLabel>
                <FormControl value={i.value || ''}
                    readOnly={i.isReadOnly}
                    disabled={i.isReadOnly}
                    onChange={e => onChange(e)} />
            </FormGroup>
            {i.lineBreakAfter ? <div className={s.break} /> : null}
        </>
    )
}

const GroupRoleEditor = ({ editRole = false, role, roles = [], onShowEditor, onClickUserRole }) => (
    <>
    <h4>Group Role</h4>
    {!editRole ?
        <div className={s.groupRoleContainer}>
            <div>
                <GroupRole role={role} showJoined size='md' />
            </div>
            <div>
                <Button onClick={() => onShowEditor(true)} variant='link'>change</Button>
            </div>
        </div>
    :
        <div>
            <ButtonGroup className={s.groupRoleButtonGroup}>
            {roles && roles.map(r =>
                <Button onClick={() => onClickUserRole(r._id)} 
                    variant={r.variant || 'light'} 
                    key={`edit-player-role-${r._id}`}>
                    {r.adminDescription}
                </Button>
            )}
                <Button variant='light' onClick={() => onShowEditor(false)}><FontAwesomeIcon name='times' /></Button>
            </ButtonGroup>
        </div>
    }
    </>
)

const GroupPlayerEditorNote = ({ note, editNote, onEditNote, onEditNoteChange, onUpdateNote, onCancelEditNote, onDeleteNote, editNoteId = null }) => {
    const i = note || {}
    return (
        <ListGroup.Item className={s.noteItem}>
            <Image src={playerAvatarUrl(i.updatedByPlayerImageUrl, true)}
                roundedCircle className={s.avatar} />
                <div className={s.noteBody}>
                    <div className={s.headerContainer}>
                        <div className={s.nameContainer}>
                            <div className={s.name}>{i.updatedByPlayerName}</div>
                            <div className={s.date}>{moment(i.updatedAt).fromNow()}</div>
                        </div>
                        <span className={s.edit + (i._id === editNoteId ? ' ' + s.hide: '')}><Button className={s.editButton} onClick={() => onEditNote(i)}><FontAwesomeIcon name='pencil-alt' /></Button></span>
                        <span className={s.delete + (i._id === editNoteId ? ' ' + s.hide: '')}>
                            <Confirm title={'Delete Player Note'}
                                onConfirm={() => onDeleteNote(i)} enforceFocus={false}
                                confirmText='delete note'
                                body={<div><p>Are you sure you want to delete this note?</p></div>}
                                variant='danger' buttonClassName={s.deleteButton} buttonIcon='times' />
                        </span>
                    </div>
                    <div className={s.noteContainer}>
                        <div className={s.note + (i._id === editNoteId ? ' ' + s.hide : '')}>
                            {i.notes}
                        </div>
                        <div className={s.note + ' ' + s.noteEditorContainer + (i._id !== editNoteId ? ' ' + s.hide : '')}>
                            <TextareaAutosize className='form-control' rows={2} 
                                placeholder={'[Add a note here...]'} value={editNote} 
                                onChange={e => onEditNoteChange(e.target.value)} />
                            <div className={s.buttonContainer}>
                                <Button className={s.noteEditButton + ' ' + s.cancelButton} onClick={() => onCancelEditNote()} variant='light'><FontAwesomeIcon name='times' /> cancel</Button>
                                <Button className={s.noteEditButton + ' ' + s.saveButton} onClick={() => onUpdateNote()} variant='primary'><FontAwesomeIcon name='pencil-alt' /> save note</Button>
                            </div>
                        </div>
                </div>
            </div>
        </ListGroup.Item>

    )
}

const NewNoteEditor = ({ newNote, handleKeyPress, handleNoteChange }) => (
    <FormGroup style={{ marginTop: '10px' }}>
        <TextareaAutosize className='form-control' rows={2} 
            placeholder={'[Add a note here...]'} value={newNote} 
            onKeyPress={e => handleKeyPress(e)}
            onChange={e => handleNoteChange(e)} />
    </FormGroup>
)

const GroupPlayerEditor = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const groupId = params.groupId || '0'
    const playerId = params.playerId || '0'
    const groups = useSelector(state => state.groups.groups)
    const roles = useSelector(state => state.groups.roles)
    const group = groups.find(g => g._id === groupId) || {}
    const groupRole = group.role || {}
    const updatedByPlayerId = useSelector(state => state.account._id)
    const [isLoading, setLoading] = useState(false)
    const [editProfile, setEditProfile] = useState({ firstName: '', lastName: '' })
    const [newNote, setNewNote] = useState('')
    const [editNoteId, setEditNoteId] = useState(null)
    const [editNote, setEditNote] = useState('')
    const [editRole, setEditRole] = useState(false)

    const { imageUrl, firstName, lastName, address, city, state, zip, cellPhone, 
        emailAddress, notes, logins, lastLogin, lastMatch, role, canUpdate,
    } = editProfile

    useEffect(() => {
        setLoading(true)
        dispatch(editGroupPlayer(groupId, playerId))
        .then(action => {
            setEditProfile(action.payload)
            setNewNote('')
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (!group && groupId && playerId) {
            navigate(`/groups?rdg=${groupId}&rd=${encodeURIComponent(location.pathname)}}`)
            return
        }
    }, [group, groupId, playerId])

    const close = () => {
        navigate(`/group/${groupId}/player/${playerId}`, { replace: true })
    }

    const deleteNote = note => {
        dispatch(deletePlayerNote(groupId, note._id))
        .then(action => setEditProfile({ ...editProfile, notes: action.payload.data }))
    }

    const handleNoteKeyPress = e => {
        if (e.key === 'Enter' && newNote) {            
            addNewNote()
            e.preventDefault()
        }
    }

    const updateNote = () => {
        dispatch(updatePlayerNote(groupId, playerId, editNoteId, updatedByPlayerId, editNote))
        .then(action => {
            setEditProfile({ ...editProfile, notes: action.payload.data })
            setEditNoteId(null)
            setNewNote('')
        })
    }

    const addNewNote = () => {
        dispatch(updatePlayerNote(groupId, playerId, null, updatedByPlayerId, newNote))
        .then(action => {
            setEditProfile({ ...editProfile, notes: action.payload.data })
            setNewNote('')
        })
    }

    const save = () => {
        dispatch(savePlayerProfileByAdmin(groupId, playerId, { ...editProfile }))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: "Save User Profile", timeout: 4000 }))
            dispatch(getPlayers(groupId))
            dispatch(getPlayerInfo(groupId, playerId))
            close()
        })
    }

    const handleImageUpdate = _response => {
        setEditProfile({ ...editProfile, imageUrl: _response?.imageUrl || null })
        dispatch(setAvatarUrl(_response))
        if (_response.playerId === playerId) {
            dispatch(getMe())
        }
        dispatch(getPlayerInfo(groupId, playerId))
    }

    const setUserRole = roleId => {
        dispatch(setPlayerRoleByRoleId(groupId, playerId, roleId))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: "Save User Profile", timeout: 4000 }))
            dispatch(getPlayers(groupId))
            dispatch(getPlayerInfo(groupId, playerId))
            setEditRole(false)
            setEditProfile({ ...editProfile, role: action.payload.data })
        })
    }

    const updateIndex = () => {
        dispatch(updatePlayerIndex(groupId, lastMatch.tripID, playerId))
        .then(action => {
            dispatch(createNotification(action.payload))
            dispatch(getPlayers(groupId))
            dispatch(getPlayerInfo(groupId, playerId))
        })
    }

    const _fields = [
        { label: 'First name', fieldName: 'firstName', value: firstName, lineBreakAfter: false, isReadOnly: !canUpdate },
        { label: 'Last name', fieldName: 'lastName', value: lastName, lineBreakAfter: true, isReadOnly: !canUpdate, spaceBefore: true },
        { label: 'Address', fieldName: 'address', value: address, lineBreakAfter: true, isReadOnly: !canUpdate },
        { label: 'City', fieldName: 'city', value: city, lineBreakAfter: false, isReadOnly: !canUpdate },
        { label: 'State', fieldName: 'state', value: state, lineBreakAfter: false, isReadOnly: !canUpdate, spaceBefore: true },
        { label: 'Zip', fieldName: 'zip', value: zip, lineBreakAfter: true, isReadOnly: !canUpdate, spaceBefore: true },
        { label: 'Cell phone', fieldName: 'cellPhone', value: cellPhone, lineBreakAfter: false, isReadOnly: !canUpdate },
        { label: 'Email address', fieldName: 'emailAddress', value: emailAddress, lineBreakAfter: true, isReadOnly: !canUpdate, spaceBefore: true },
        { label: 'Logins', fieldName: 'logins', value: logins, lineBreakAfter: false, isReadOnly: true },
        { label: 'Last login', fieldName: 'lastLogin', value: lastLogin ? moment(lastLogin).format(FRIENDLY_DATE_FORMAT) : '[Never logged in]', lineBreakAfter: true, isReadOnly: true, spaceBefore: true },
    ]
    return (
        <Modal show
            heading={`Edit Player Information: ${firstName} ${lastName}`}
            enforceFocus={false} showFooter={false}
            onClose={() => close()}>
            <div className={s.container}>
                {!isLoading ?
                <>
                <GroupPlayerEditorAvatarUpdate imageUrl={imageUrl} playerId={playerId !== '0' ? playerId : null}
                    canUpdate={canUpdate} onUpdate={handleImageUpdate} />

                <div className={s.fieldsContainer}>
                {_fields && _fields.map(i => 
                <GroupPlayerFormGroup field={i}
                    onChange={e => setEditProfile({ ...editProfile, [i.fieldName]: e.target.value })}
                    key={`group-player-editor-${i.fieldName}`} />
                )}
                </div>
                <div className={s.buttonsContainer}>
                    {lastMatch ? <Button className={s.button} variant='info' onClick={() => updateIndex()}><FontAwesomeIcon name='list' /> update index</Button> : null}
                    <Button className={s.button} variant='light' onClick={() => close()}><FontAwesomeIcon name='times' /> cancel</Button>
                    <Button className={s.save} variant='primary' onClick={() => save()}><FontAwesomeIcon name='check' /> save profile</Button>
                </div>
                <GroupRoleEditor role={role} roles={roles} editRole={editRole}
                    onShowEditor={_showEditRole => setEditRole(_showEditRole)}
                    onClickUserRole={_roleId => setUserRole(_roleId)} />
                <h4 style={{ marginTop: '20px' }}>Notes</h4>
                <ListGroup>
                {notes && notes.map(i =>
                <GroupPlayerEditorNote note={i} editNote={editNote} editNoteId={editNoteId}
                    onEditNote={_note => { setEditNoteId(_note._id); setEditNote(_note.notes || '') }} 
                    onEditNoteChange={_noteText => setEditNote(_noteText)}
                    onUpdateNote={updateNote}
                    onCancelEditNote={() => { setEditNote(''); setEditNoteId(null) }}
                    onDeleteNote={deleteNote}
                    key={`group-player-${playerId}-editor-note-${i._id}`} />
                )}
                </ListGroup>
                <NewNoteEditor newNote={newNote}
                    handleKeyPress={e => handleNoteKeyPress(e)}
                    handleNoteChange={e => setNewNote(e.target.value)} />
                </>
                : null}
            </div>
        </Modal>
    )
}

export default GroupPlayerEditor