// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-4a834c920e/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CellPhoneNumberConfirmAlert__container___XHXTh{margin:0px 0px 20px 0px}.CellPhoneNumberConfirmAlert__container___XHXTh .CellPhoneNumberConfirmAlert__alert___dvN5d .CellPhoneNumberConfirmAlert__content___kdauo{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center;margin:0px}.CellPhoneNumberConfirmAlert__container___XHXTh .CellPhoneNumberConfirmAlert__alert___dvN5d .CellPhoneNumberConfirmAlert__content___kdauo .CellPhoneNumberConfirmAlert__icon___HFKnL{flex:0 0 0;margin-right:8px}.CellPhoneNumberConfirmAlert__container___XHXTh .CellPhoneNumberConfirmAlert__alert___dvN5d .CellPhoneNumberConfirmAlert__content___kdauo .CellPhoneNumberConfirmAlert__text___scBuz{flex:1 1 auto}.CellPhoneNumberConfirmAlert__container___XHXTh .CellPhoneNumberConfirmAlert__alert___dvN5d .CellPhoneNumberConfirmAlert__content___kdauo .CellPhoneNumberConfirmAlert__button___p8y0R{flex:0 0 0;margin-left:20px;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/routes/Groups/CellPhoneNumberConfirmAlert.scss"],"names":[],"mappings":"AAAA,gDACI,uBAAA,CAGI,0IACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CACA,qLACI,UAAA,CACA,gBAAA,CAEJ,qLACI,aAAA,CAEJ,uLACI,UAAA,CACA,gBAAA,CACA,kBAAA","sourcesContent":[".container {\n    margin: 0px 0px 20px 0px;\n\n    .alert {\n        .content {\n            display: flex;\n            flex-flow: row nowrap;\n            justify-content: space-between;\n            align-items: center;\n            margin: 0px;\n            .icon {\n                flex: 0 0 0;\n                margin-right: 8px;\n            }\n            .text {\n                flex: 1 1 auto;\n            }\n            .button {\n                flex: 0 0 0;\n                margin-left: 20px;\n                white-space: nowrap;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `CellPhoneNumberConfirmAlert__container___XHXTh`;
export var alert = `CellPhoneNumberConfirmAlert__alert___dvN5d`;
export var content = `CellPhoneNumberConfirmAlert__content___kdauo`;
export var icon = `CellPhoneNumberConfirmAlert__icon___HFKnL`;
export var text = `CellPhoneNumberConfirmAlert__text___scBuz`;
export var button = `CellPhoneNumberConfirmAlert__button___p8y0R`;
export default ___CSS_LOADER_EXPORT___;
