import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormGroup, FormLabel, FormControl, Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { isEmpty } from 'validator'
import * as s from './ForgotPassword.scss'
import { resetForgotPassword, setUserInfo,
    checkEmail, checkCellPhone, requestResetCode, verifyResetCode,
    requestPasswordChange } from '@severed-links/common.severedlinks-reducers/forgotPassword'

const ForgotPasswordConfirmation = forwardRef(({ jumpToStep, ...props }, _ref) => {
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const fp = useSelector(state => state.forgotPassword)

    useEffect(() => {
        const { passwordIsValid, passwordIsReset, verificationCode, password } = fp
        if (passwordIsValid && !passwordIsReset) {
            dispatch(requestPasswordChange({ verificationCode, newPassword: password }))
        }
    }, [])

    useImperativeHandle(_ref, () => ({
        isValidated() { return fp.passwordIsReset }
    }))

    return (
        <div>
            {fp.passwordIsReset ?
            <Alert variant='success'>
                <div>
                    <h4>Your password has been reset.</h4>
                    <p>Click <a style={{ cursor: 'pointer' }} onClick={() => navigate('/login')}>here</a> to log in.</p>
                </div>
            </Alert>
            :null}
            {!fp.passwordIsReset && fp.passwordError ?
            <Alert variant='danger'>
                <div>
                    <h4>There was an error resetting your password.</h4>
                    <p>{fp.passwordErrorMessage}</p>
                </div>
            </Alert>
            :null}
        </div>
    )
})

export default ForgotPasswordConfirmation
