import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Row, Col, FormGroup, FormControl, FormLabel, ListGroup,
    FormCheck, Badge, FormText, Button, ButtonGroup, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TextareaAutosize from 'react-autosize-textarea'
import * as s from './TripMessaging.scss'
import { Editor, EditorState, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import 'draft-js/dist/Draft.css'
import validator from 'validator'
import { sortBy, reject, countBy } from 'lodash'
import PlayerListItem from '../../Player/PlayerListItem'
import { sendTripMessage, setTripValue } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import pluralize from 'pluralize'
import MessageTypeSelector from './MessageTypeSelector'
import PushTypeSelector from './PushTypeSelector'

const TripMessaging = () => {

    const dispatch = useDispatch()
    const { group, groupId, trip, tripId, isGroupAdmin, isGlobalAdmin, isGlobalAdminPath } = useOutletContext()
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [messageType, setMessageType] = useState('email')
    const [smsLimit, setSmsLimit] = useState(160)
    const [selectedPlayers, setSelectedPlayers] = useState([])
    const [pushType, setPushType] = useState('info')
    const [isSending, setIsSending] = useState(false)
    const isSMS = messageType === 'sms'
    const recipients = sortBy([...(trip.usaPlayers || []), ...(trip.eurPlayers || []), ...(trip.unassignedPlayers || []), ].map(p => ({ ...p, emailValid: validator.isEmail(p.emailAddress), sortOrder: p.role && p.role.isAdmin ? 1 : isSMS && p.cellPhoneConfirmed ? 2 : 3 })), ['sortOrder','lastName','firstName'], ['asc','asc','asc'])
    const tripAdminMessagingPreload = trip.tripAdminMessagingPreload || {}

    useEffect(() => {
        reset()
        // handle tripAdminMessagingPreload
        setSubject(tripAdminMessagingPreload.subject || '')
        //setBody(tripAdminMessagingPreload.body || '')
        setSelectedPlayers(tripAdminMessagingPreload.recipients || [])
        setPushType('info')
    }, [(tripAdminMessagingPreload || {}).updatedAt || null])


    useEffect(() => {
        if (!recipients.length) {
            selectAll()
        }
    }, [recipients.length])

    const reset = () => {
        setSubject('')
        setBody('')
        setEditorState(EditorState.createEmpty())
    }

    const send = () => {
        const options = {
            blockStyleFn: block => {
                return {
                    style: {
                        marginBottom: '12px'
                    }
                }
            }        
        }
        const postData = { tripId, subject, messageType, body: messageType === 'email' ? stateToHTML(editorState.getCurrentContent(), options) : body, recipients: [...selectedPlayers], pushType }
        setIsSending(true)
        dispatch(sendTripMessage(groupId, tripId, postData))
        .then(action => { 
            if (action.payload.data !== 'push') {
                dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: 'Send Message', timeout: 5000 }) )
            }
            setIsSending(false)

            if (tripAdminMessagingPreload.length) {
                dispatch(setTripValue(groupId, tripId, 'tripAdminMessagingPreload', {}))
            }

        })
    }

    const handleEditorChange = _editorState => setEditorState(_editorState)
    const handleEditorButton = command => handleEditorChange(RichUtils.toggleInlineStyle(editorState, command))
    const handlePushTypeChange = _pushType => setPushType(_pushType)

    const okToSend = () => ((messageType === 'email' && !validator.isEmpty(subject) && editorState.getCurrentContent().hasText() && selectedPlayers.length > 0) ||
        (messageType === 'push' && !validator.isEmpty(body) && !validator.isEmpty(subject)) ||
        (messageType === 'sms' && !validator.isEmpty(body) && selectedPlayers.length > 0)) ||
        (messageType === 'mobile' && !validator.isEmpty(body))

    const togglePlayerSelection = player => {
        var _selectedPlayers = [...selectedPlayers]
        if (_selectedPlayers.some(_id => _id === player.playerId)) {
            _selectedPlayers = reject(_selectedPlayers, _id => _id === player.playerId)
        } else {
            _selectedPlayers.push(player.playerId)
        }
        setSelectedPlayers(_selectedPlayers)
    }

    const selectAll = () => setSelectedPlayers(selectedPlayers.length !== recipients.length ? recipients.map(p => p.playerId) : [] )
    const selectTeam = _team => setSelectedPlayers(recipients.filter(p => p.playerId && p.team === _team).map(p => p.playerId))

    const handleMessageTypeChange = _index => setMessageType(MESSAGE_TYPES[_index].value)
    const _recipientSelectorButtons = [
        { listKey: 'All', label: 'All', variant: 'secondary', onClick: () => selectAll(), count: recipients.filter(i => i.playerId).length },
        { listKey: 'USA', label: trip.usaTeamName, variant: 'danger', onClick: () => selectTeam('USA'), count: recipients.filter(i => i.playerId && i.team === 'USA').length },
        { listKey: 'Europe', label: trip.eurTeamName, variant: 'info', onClick: () => selectTeam('EUR'), count: recipients.filter(i => i.playerId && i.team === 'EUR').length },
    ].map(i => ({ ...i, isDisabled: !i.count }))

    const fallbackWarningRecipients = messageType === 'sms' ? recipients.filter(r => selectedPlayers.indexOf(r.playerId) > -1 && !r.cellPhoneConfirmed) : []
    const onlineUserCount = (group.onlineUsers || []).length
    return (
        <div className={s.container}>
            <h4>Messaging</h4>

            <MessageTypeSelector selectedMessageType={messageType} 
                onlineUserCount={onlineUserCount}
                onSelect={_value => {
                    setMessageType(_value)
                }} />

            <Row>
                <Col sm={6} xs={12}>

                    {messageType === 'email' || messageType === 'push' ?
                    <FormGroup className={s.formGroup}>
                        <FormLabel>{messageType === 'email' ? 'Subject' : 'Headline'}</FormLabel>
                        <FormControl autoFocus placeholder={messageType === 'email' ? 'Subject' : 'Headline'} value={subject} onChange={e => setSubject(e.target.value)} />
                    </FormGroup>
                    : null}
                    
                    {messageType === 'push' ?
                    <PushTypeSelector selectedPushType={pushType} onSelect={_value => handlePushTypeChange(_value)} />
                    : null}

                    <FormGroup className={s.formGroup}>
                        <FormLabel className={s.messageLabel}>
                            <div className={s.labelText}>Message</div>
                            {messageType === 'sms' ? <Badge className={s.badge} size='sm' bg={body.length <= smsLimit ? 'success' : 'warning'} text={body.length <= smsLimit ? 'light' : 'dark'}>{body.length}</Badge> : null}
                        </FormLabel>

                        {messageType === 'email' ? 
                        <div className={s.editorContainer}>
                            <div className={s.editorTools}>
                            <ButtonGroup tabIndex={-1}>
                                <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('BOLD')}><FontAwesomeIcon name='bold' /></Button>
                                <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('UNDERLINE')}><FontAwesomeIcon name='underline' /></Button>
                                <Button tabIndex={-1} size='sm' variant='light' onClick={() => handleEditorButton('ITALIC')}><FontAwesomeIcon name='italic' /></Button>
                            </ButtonGroup>
                            </div>
                            <Editor editorState={editorState} 
                                placeholder='[Email body...]' autoFocus 
                                blockStyleFn={contentBlock => s.block}
                                onChange={e => handleEditorChange(e)} />
                        </div>
                        : null}
                        {messageType === 'sms' ? <TextareaAutosize autoFocus placeholder='[Text message text...]' className='form-control' rows={2} value={body} onChange={e => setBody(e.target.value)} /> : null}
                        {messageType === 'push' ? <FormControl autoFocus placeholder='[Push notification text...]' value={body} onChange={e => setBody(e.target.value)} /> : null}
                        {messageType === 'mobile' ? <TextareaAutosize autoFocus placeholder='[Notification text...]' className='form-control' rows={2} value={body} onChange={e => setBody(e.target.value)} /> : null}
                        {messageType === 'sms' && body.length > smsLimit ? <FormText className={s.smsWarning}>SMS messages longer than {smsLimit} characters may be split into multiple portions based on provider criteria.</FormText> : null}
                    </FormGroup>

                    <FormGroup className={s.formGroup + ' ' + s.buttonsContainer}>
                        <Button variant='light' onClick={() => reset()}><FontAwesomeIcon name='sync-alt' /> reset</Button>
                        <Button className={s.sendButton} variant='primary' onClick={() => send()} disabled={isSending || !okToSend()}><FontAwesomeIcon spin={isSending} name={isSending ? 'circle-notch' : messageType === 'email' ? 'envelope' : messageType === 'sms' ? 'mobile' : 'bolt'} /> send</Button>
                    </FormGroup>

                    {fallbackWarningRecipients && fallbackWarningRecipients.length > 0 ?
                        <Alert variant='warning'><b>Warning</b>:  {pluralize('recipient', fallbackWarningRecipients.length, true)} {fallbackWarningRecipients.length !== 1 ? 'have' : 'has'} an unconfirmed cell phone number so the message will fall back to an email for {fallbackWarningRecipients.length !== 1 ? 'those recipients' : 'that recipient'}.</Alert>
                    : null}
                </Col>
                {messageType === 'email' || messageType === 'sms' ?
                <Col sm={6} xs={12}>
                    <div className={s.recipientHeading}>
                        <h4 className={s.recipientTitle}>Recipients {selectedPlayers.length > 0 ? <span>({selectedPlayers.length})</span> : null}</h4>
                        {_recipientSelectorButtons && _recipientSelectorButtons.filter(i => i.count).map(i =>
                        <Button onClick={() => i.onClick()} size='sm'
                            className={s.selectorButton + ' ' + s[`${(i.label || '').toLowerCase()}`]}
                            key={`trip-messaging-selector-${i.label}`}>{i.label} ({i.count})</Button>
                        )}
                    </div>
                    <ListGroup className={s.recipientList}>
                    {recipients && recipients.map(p =>
                        <PlayerListItem className={s.playerItem + (selectedPlayers.some(_id => _id === p.playerId) ? ` ${s.selected}` : '')} 
                            player={p} showRole={false} noWrap 
                            onClick={() => togglePlayerSelection(p)}
                            key={`trip-messaging-receipient-${p.playerId}`}
                            rightDetail={<div className={s.rightDetail}>
                                <div className={s.unconfirmedIcons}>
                                {messageType === 'email' && !p.emailValid ? <div title='Invalid email'><FontAwesomeIcon name='envelope' /> <FontAwesomeIcon name='exclamation' /></div> : null}
                                {messageType === 'sms' && !p.cellPhoneConfirmed ? <div title='Unconfirmed cell phone'><FontAwesomeIcon name='mobile' /> <FontAwesomeIcon name='exclamation' /></div> : null}
                                </div>
                                <div className={s.selectedIcon}>
                                    <FontAwesomeIcon name={selectedPlayers.some(_id => _id === p.playerId) ? `check-circle` : 'circle'} />
                                </div>
                            </div>} />
                    )}
                    {!recipients || recipients.length === 0 ? <ListGroup.Item>There are no players on this trip to send messages to.</ListGroup.Item> : null}
                    </ListGroup>
                </Col>
                : null}
            </Row>
        </div>
    )
}

export default TripMessaging