import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Card, Table, ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TripMedalistScoreboard.scss'
import { take, drop, range, update, first, maxBy } from 'lodash'
import Player from '../Player/Player'
import PlayerListItem from '../Player/PlayerListItem'
import ParScoreDisplay from './ParScoreDisplay'
import { commonImageUrl } from '@severed-links/common.severedlinks-constants'
import { setMedalistPageState } from '@severed-links/common.severedlinks-reducers/groups'

const MedalistRounds = ({ showRounds, isSmallScreen, numRounds, scores = [], gross = 0 }) => {
    if (!showRounds || !scores.length || isSmallScreen) return null
    return (
        <div className={s.roundScores}>
        {range(1, scores.length + 1, 1).map(r => 
            <div className={`${s.roundScore}${(!gross || (scores && scores.length >= r && !scores[r - 1]) ? ` ${s.isZero}` : '')}`}
                key={`medalist-round-${r}`}>
                {scores && scores.length >= r ? scores[r - 1].toString() : '--'}
            </div>
        )}
        </div>
    )
}

const TripMedalistHeadingItem = ({ isSmallScreen = false, hasHandicaps = true, showRounds = false, medalistRoundsToShowOnScoreboard = [] }) => (
    <ListGroup.Item className={s.miniMedalistHeaderItem}>
        <div className={s.header + ' ' + s.player}>&nbsp;</div>
        {showRounds && !isSmallScreen && medalistRoundsToShowOnScoreboard.filter(r => r.show).length > 0 && <div className={s.header + ' ' + s.roundScoreHeader}>Round Scores</div>}
        {showRounds && !isSmallScreen && <div className={s.header}>Gross</div>}
        {showRounds && !isSmallScreen && hasHandicaps && <div className={s.header}>Net</div>}
        <div className={s.header}>+/-</div>
    </ListGroup.Item>
)

const TripMedalistScoreboard = ({
    paginate = false, isMini = false,
    pageLength = 10, showRounds = true, showHcp = true, 
    showPager = true, inverse = false,
}) => {

    const dispatch = useDispatch()
    const medalistState = useSelector(state => state.groups.medalistState)
    const [isSmallScreen, setSmallScreen] = useState(false)
    const { group, trip, groupId, tripId } = useOutletContext()
    if (!group || !trip) return null
    const _mediaMatch = '(max-width: 767px)'
    const medalist = (trip.scoreboard || {}).medalist || []
    const hasHandicaps = trip.hasHandicaps
    const medalistRoundsToShowOnScoreboard = (trip.scoreboard || {}).medalistRoundsToShowOnScoreboard || []
    const numRounds = trip.numberOfRounds || 0

    useEffect(() => {
        var x = window.matchMedia(_mediaMatch)
        checkScreenSize(x) 
        x.addEventListener('change', checkScreenSize)
    }, [])

    useEffect(() => { updateStateOnNewProps() }, [medalist.length, paginate, pageLength])

    const checkScreenSize = x => setSmallScreen(x.matches)

    const updateStateOnNewProps = () => {
        const _pageLength = paginate ? (pageLength || medalistState.pageLength) : medalist.length
        const _numPages = paginate ? Math.ceil(medalist.length / (pageLength || medalistState.pageLength)) : 1
        const _pageNumber = medalistState.pageNumber <= _numPages ? medalistState.pageNumber : 1
        dispatch(setMedalistPageState(_pageNumber, _pageLength, _numPages))
    }

    const filterRows = () => {
        if (!paginate) return [...medalist]
        else return take(drop([...medalist], (medalistState.pageNumber - 1) * medalistState.pageLength), medalistState.pageLength)
    }

    const changePage = offset => {
        var pageNumber = medalistState.pageNumber + offset
        if (pageNumber < 1) pageNumber = 1
        else if (pageNumber > medalistState.numPages) pageNumber = medalistState.numPages
        dispatch(setMedalistPageState(pageNumber, medalistState.pageLength, medalistState.numPages))
    }

    const rows = filterRows()
    return (
        <Card className={s.container + (inverse ? ` ${s.inverse}` : '') + (isMini ? ` ${s.isMini}` : '')}>
            <Card.Header className={s.headingContainer}>
                {!isMini ? <div className={s.title}>Medalist Standings</div> : null}
                {paginate && showPager && medalistState.numPages ? 
                <div className={s.paginationContainer}>
                    <Button variant='light' disabled={medalistState.pageNumber === 1} 
                        className={s.pagerButton} onClick={() => changePage(-1)} size='sm'>
                        <FontAwesomeIcon name='chevron-left' />
                    </Button>
                    <div className={s.pagerInfo}>{`${medalistState.pageNumber} / ${medalistState.numPages}`}</div>
                    <Button variant='light' disabled={medalistState.pageNumber === medalistState.numPages}
                        className={s.pagerButton} onClick={() => changePage(1)} size='sm'>
                        <FontAwesomeIcon name='chevron-right' />
                    </Button>
                </div>
                : null}
            </Card.Header>
            <ListGroup className={s.medalistList} variant='flush'>
            {!isMini ? <TripMedalistHeadingItem isSmallScreen={isSmallScreen} showRounds={showRounds} medalistRoundsToShowOnScoreboard={medalistRoundsToShowOnScoreboard} hasHandicaps={hasHandicaps} /> : null}
            {rows && rows.map((i, index) =>
            <PlayerListItem player={i} showFlag flagUrl={trip[`${(i.team || '').toLowerCase()}FullTeamImageUrl`]} 
                showHcp={hasHandicaps && showHcp} inverse={inverse} isMini={isMini}
                rank={i.rank} clickDisabled={isMini} rightDetail={<div className={s.rightDetail}>
                    <MedalistRounds isSmallScreen={isSmallScreen} showRounds={showRounds} 
                        scores={i.scores} gross={i.gross} />
                    {showRounds && !isSmallScreen && <div className={s.scoreItem + ' ' + s.gross}>{i.gross}</div>}
                    {showRounds && !isSmallScreen && hasHandicaps && <div className={s.scoreItem + ' ' + s.net} title={`Par: ${i.par}`}>{i.net}</div>}
                    <div className={s.scoreItem + ' ' + s.plusMinus}><ParScoreDisplay score={i.diff} inverse={inverse} /></div>
                </div>}
                key={`medalist-player-${i.playerID}`} />
            )}
            {paginate && rows && rows.length < pageLength && range(1, pageLength - rows.length + 1, 1).map((i, index) => 
            <ListGroup.Item className={s.blankMedalistItem} 
                key={`blank-medalist-item-${index}`} />
            )}
            </ListGroup>
        </Card>
    )
}

export default TripMedalistScoreboard